import React from 'react';
import {Link, withRouter} from 'react-router';
import PropTypes from 'prop-types';

class Bio extends React.Component {
  static propTypes = {
    bio: PropTypes.string.isRequired
  };

  state = {
    showFullBio: false
  };

  maxDefaultLength = 235;

  getBioText = () => {
    const {bio = ''} = this.props;
    if (bio.length < this.maxDefaultLength) {
      return bio;
    } else {
      const shortenedBio = bio.substring(0, this.maxDefaultLength);
      let index = shortenedBio.length - 1;
      while (index > 0) {
        if (shortenedBio.substring(index, index + 1) === ' ') {
          return `${shortenedBio.substring(0, index)}...`;
        }
        index--;
      }
      return `${shortenedBio}...`;
    }
  };

  handleToggleFullBio = () => {
    this.setState({
      showFullBio: !this.state.showFullBio
    });
  };

  render() {
    const {bio: originalBio = ''} = this.props;
    const {showFullBio = false} = this.state;
    const reducedBio = this.getBioText();
    return (
      <div className="data-wrapper bio">
        <div className="data-header">Bio</div>
        <Choose>
          <When condition={originalBio === ''}>
            <div className="data-content text empty">No data</div>
          </When>
          <Otherwise>
            <div className="data-content text bio">
              <Choose>
                <When condition={!showFullBio}>{`${reducedBio} `}</When>
                <Otherwise>{`${originalBio} `}</Otherwise>
              </Choose>
            </div>
            <If condition={originalBio.length > reducedBio.length}>
              <Link
                style={{cursor: 'pointer'}}
                onClick={this.handleToggleFullBio}
                className="show-more-text-link"
              >
                {!showFullBio ? 'Show more' : 'Show less'}
              </Link>
            </If>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(Bio);
