import PropTypes from 'prop-types';
import React from 'react';

class TitleCell extends React.Component {
  static propTypes = {
    person: PropTypes.object.isRequired
  };

  render() {
    var title = this.props.person.title || '';
    return (
      <div>
        <span className="text-justify trunc-cell-text">{title}</span>
      </div>
    );
  }
}

export default TitleCell;
