import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Link, withRouter} from 'react-router';
import _ from 'underscore';

import {ListItem} from 'material-ui/List';

class InboxMenuItem extends React.Component {
  static propTypes = {
    active: PropTypes.bool,
    count: PropTypes.number,
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    reason: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  };

  static defaultProps = {
    active: false,
    count: 0,
    filters: [],
    experiments: [],
    ignore_experiments: []
  };

  render() {
    const {
      active,
      count,
      title,
      reason,
      filters,
      experiments,
      ignore_experiments,
      icon,
      onClick,
      location
    } = this.props;

    const classNames = cx('inbox-nav-item', {'inbox-item-active': active});

    const inactiveStyle = {
      borderLeft: '0px solid transparent',
      paddingLeft: '6px'
    };

    const activeStyle = {
      color: '#5dab49',
      borderLeft: '6px solid #5dab49'
    };

    const to = {
      ...location,
      query:
        reason === 'inbox'
          ? _.omit(location.query, ['reason', 'experiments', 'experiments_not'])
          : _.pick(
              {
                ...location.query,
                reason: filters.join(','),
                experiments: experiments.join(',') || '',
                experiments_not: ignore_experiments.join(',') || ''
              },
              _.identity
            )
    };

    return (
      <ListItem
        style={active ? activeStyle : inactiveStyle}
        containerElement={<Link className={classNames} to={to} />}
        leftIcon={icon}
        primaryText={title}
        rightIcon={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {count}
          </div>
        }
        onClick={onClick}
      />
    );
  }
}

export default withRouter(InboxMenuItem);
