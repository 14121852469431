import roberto from '../../images/people/roberto.jpg';
import karolina from '../../images/people/karolina.jpg';
import marina from '../../images/people/marina.jpg';
import ben from '../../images/people/ben.jpg';
import john from '../../images/people/john.jpg';
import ghyslain from '../../images/people/ghyslain.jpg';
import kass from '../../images/people/kass.png';
import luca from '../../images/people/luca.jpg';
import arthur from '../../images/people/arthur.jpg';
import sean from '../../images/people/sean.jpg';
import kjoy from '../../images/people/kjoy.jpeg';
import namita from '../../images/people/namita.jpeg';
import edward from '../../images/people/ed.jpeg';
import ml from '../../images/people/ml.jpg';
import placeholder from '../../images/person-placeholder.jpg';

export default [
  {
    name: 'Roberto',
    email: 'roberto@inreachventures.com',
    imageUrl: roberto,
    role: 'partner',
    hasOutreach: true
  },
  {
    name: 'Amanda',
    email: 'amanda@inreachventures.com',
    imageUrl: placeholder,
    role: 'analyst',
    hasOutreach: true
  },
  {
    name: 'Karolina',
    email: 'karolina@inreachventures.com',
    imageUrl: karolina,
    role: 'analyst',
    hasOutreach: true
  },
  {
    name: 'Marina',
    email: 'marina@inreachventures.com',
    imageUrl: marina,
    role: 'analyst',
    hasOutreach: true
  },
  {
    name: 'Namita',
    email: 'namita@inreachventures.com',
    imageUrl: namita,
    role: 'analyst',
    hasOutreach: true
  },
  {
    name: 'Edward',
    email: 'edward@inreachventures.com',
    imageUrl: edward,
    role: 'analyst',
    exclude: true
  },
  {
    name: 'K. Joy.',
    email: 'kjoy@inreachventures.com',
    imageUrl: kjoy,
    role: 'analyst',
    exclude: true
  },
  {
    name: 'DIG',
    email: 'ml@inreachventures.com',
    imageUrl: ml,
    role: 'analyst',
    exclude: true
  },
  {
    name: 'Ben',
    email: 'ben@inreachventures.com',
    imageUrl: ben,
    roles: ['analyst', 'tech']
  },
  {
    name: 'Ben',
    email: 'ben.thesmith@gmail.com',
    imageUrl: ben,
    roles: ['analyst', 'tech'],
    exclude: true
  },
  {
    name: 'Ghyslain',
    email: 'ghyslain@inreachventures.com',
    imageUrl: ghyslain,
    roles: ['analyst', 'tech'],
    exclude: true
  },
  {
    name: 'Luca',
    email: 'luca@inreachventures.com',
    imageUrl: luca,
    roles: ['analyst', 'tech'],
    exclude: true
  },
  {
    name: 'Arthur',
    email: 'arthur@inreachventures.com',
    imageUrl: arthur,
    roles: ['analyst', 'tech'],
    exclude: true
  },
  {
    name: 'Sean',
    email: 'sean@inreachventures.com',
    imageUrl: sean,
    roles: ['analyst', 'tech'],
    exclude: true
  },
  {
    name: 'John',
    email: 'john@inreachventures.com',
    imageUrl: john,
    roles: ['analyst', 'tech'],
    exclude: true
  },
  {
    name: 'Kass',
    email: 'kass@inreachventures.com',
    imageUrl: kass,
    roles: ['analyst', 'tech']
  },
  {
    name: 'Moreno',
    email: 'moreno@inreachventures.com',
    imageUrl: placeholder,
    roles: ['analyst', 'tech']
  },
  {
    name: 'Giuseppe',
    email: 'giuseppe@inreachventures.com',
    imageUrl: placeholder,
    roles: ['analyst', 'tech']
  },
  {
    name: 'Natasha',
    email: 'natasha@inreachventures.com',
    role: 'analyst',
    exclude: true
  },
  {
    name: 'DIG',
    email: 'dig@inreachventures.com',
    exclude: true
  },

  ...(process.env.NODE_ENV === 'production'
    ? []
    : [
        {
          name: 'Test Partner',
          email: 'test.partner@inreachventures.com',
          imageUrl: placeholder,
          hasOutreach: true
        },
        {
          name: 'Test Trusted Partner',
          email: 'test.trustedpartner@inreachventures.com',
          imageUrl: placeholder,
          hasOutreach: true
        },
        {
          name: 'Test Analyst',
          email: 'test.analyst@inreachventures.com',
          imageUrl: placeholder,
          hasOutreach: true
        }
      ])
];
