import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import AttachMoney from 'material-ui/svg-icons/editor/attach-money';

import NewTag from './NewTag';
import Tooltip from '../../Tooltip';

import {getRoundedUpValue} from '../../../lib/helpers';
import {getDataWrapperClassNames} from '../../../lib/helpers';

class FundingAmount extends React.Component {
  static propTypes = {
    fundingAmount: PropTypes.object.isRequired,
    useSnapshot: PropTypes.bool,
    previous: PropTypes.bool,
    compressed: PropTypes.bool.isRequired
  };

  static defaultProps = {
    compressed: false
  };

  state = {
    showTooltip: false
  };

  getHasChanged = () => {
    const {
      fundingAmount,
      fundingAmount: {
        amount,
        stage = '',
        highlighted,
        hasFundingRounds,
        snapshot,
        snapshot: {
          amount: snapshotAmount,
          stage: snapshotStage = '',
          highlighted: snapshotHighlighted,
          hasFundingRounds: snapshotHasFundingRounds
        } = {}
      },
      useSnapshot = false,
      previous = false
    } = this.props;
    return useSnapshot &&
      !previous &&
      this.getObjectFundingAmountString(fundingAmount) !==
        this.getObjectFundingAmountString(snapshot)
      ? getRoundedUpValue(amount) !== getRoundedUpValue(snapshotAmount) ||
          ((stage === 'acquired' || stage === 'ipo') &&
            stage !== snapshotStage) ||
          highlighted !== snapshotHighlighted ||
          hasFundingRounds !== snapshotHasFundingRounds
      : false;
  };

  hasError = () => {
    const {
      fundingAmount: {amount, stage}
    } = this.props;
    return (
      (amount && amount >= 5000000) ||
      ((stage && stage === 'acquired') || stage === 'ico' || stage === 'ipo')
    );
  };

  getError = () => {
    const {
      fundingAmount: {amount, stage}
    } = this.props;
    if (!this.hasError()) {
      return null;
    } else {
      return amount >= 5000000 ? 'amount' : 'stage';
    }
  };

  getTooltipText = () => {
    const {compressed = false} = this.props;
    const error = this.getError();
    const text = [];
    if (compressed) {
      text.push('Funding Amount');
    }
    if (error) {
      text.push(
        error === 'amount'
          ? 'The company has received more than $5m in funding'
          : 'The company has exited (acquired or IPO)'
      );
    }
    return text.join('<br/>');
  };

  getFundingAmountClasses = () => {
    const {
      fundingAmount: {amount, highlighted = false}
    } = this.props;
    const classNames = ['data-content', 'text'];
    if (this.hasError()) {
      classNames.push('red-flag');
    } else if (highlighted) {
      classNames.push('highlighted-text');
    } else if (!amount) {
      classNames.push('de-emphasised-text');
    }
    return classNames.join(' ');
  };

  getObjectFundingAmountString = (fundingAmount) => {
    const {amount, stage, hasFundingRounds = false} = fundingAmount;
    return `${
      hasFundingRounds && (!amount || amount === 0)
        ? `Undisclosed amount`
        : !amount
          ? 'No data'
          : `$${getRoundedUpValue(amount)}`
    }${
      stage === 'acquired'
        ? ' - Acquired'
        : stage === 'ico'
          ? ' - ICO'
          : stage === 'ipo'
            ? ' - IPO'
            : ''
    }`;
  };

  getFundingAmountString = () => {
    const {
      fundingAmount: {amount, stage, hasFundingRounds = false}
    } = this.props;
    return `${
      hasFundingRounds && (!amount || amount === 0)
        ? `Undisclosed amount`
        : !amount
          ? 'No data'
          : `$${getRoundedUpValue(amount)}`
    }${
      stage === 'acquired'
        ? ' - Acquired'
        : stage === 'ico'
          ? ' - ICO'
          : stage === 'ipo'
            ? ' - IPO'
            : ''
    }`;
  };

  render() {
    const {
      fundingAmount: {snapshot = {}} = {},
      previous,
      compressed = false
    } = this.props;
    const {showTooltip} = this.state;
    const hasChanged = this.getHasChanged();
    return (
      <div
        className={getDataWrapperClassNames(compressed, ['funding-amount'])}
        onMouseLeave={() => {
          if (this.hasError() || compressed) {
            this.setState({showTooltip: false});
          }
        }}
        onMouseEnter={() => {
          if (this.hasError() || compressed) {
            this.setState({showTooltip: true});
          }
        }}
        rel={(el) => (this._parent = el)}
      >
        <If condition={hasChanged}>
          <NewTag source="funding-amount" snapshot={snapshot} />
        </If>
        <div className="data-header">
          <If condition={previous}>Previous&nbsp;</If>
          Funding Amount
        </div>
        <Tooltip
          parent={this._parent}
          showTooltip={showTooltip}
          text={this.getTooltipText()}
        />
        <div className="data-icon">
          <AttachMoney className="data-icon-svg" />
        </div>
        <div className={this.getFundingAmountClasses()}>
          {this.getFundingAmountString()}
        </div>
      </div>
    );
  }
}

export default withRouter(FundingAmount);
