export default {
  people: {
    founders: {
      form_title: 'Founders',
      format: 'short'
    },
    inspiration: {
      form_subtitle: 'What inspired you to start this company?',
      format: 'long'
    },
    link_with_business: {
      form_subtitle:
        "Is there a link between the founders' background and what the company does?",
      format: 'long'
    },
    key_employee_details: {
      form_title: 'Key employees',
      format: 'short'
    },
    employees: {
      form_title: 'Employees',
      format: 'short',
      number_engineering_product: {
        form_subtitle: 'Engineering product'
      },
      number_sales_marketing: {
        form_subtitle: 'Sales marketing'
      },
      number_other: {
        form_subtitle: 'Other'
      },
      number_total_employees: {
        form_subtitle: 'Total'
      }
    }
  },
  product: {
    product_info: {
      format: 'short',
      vision: {
        form_subtitle: 'Product vision'
      },
      owner: {
        form_subtitle: 'Product owner'
      }
    },
    problem: {
      form_subtitle: 'What is the exact problem you are trying to solve?',
      format: 'long'
    },
    solution: {
      form_subtitle: 'How are you solving this problem?',
      format: 'long'
    },
    customer: {
      form_subtitle: 'Who are you building the product for?',
      format: 'long'
    },
    priorities: {
      form_subtitle:
        'What are the key product priorities for the next six months?',
      format: 'long'
    },
    other_kpis: {
      form_subtitle: 'What other relevant KPIs do you use to measure growth?',
      format: 'long'
    },
    revenue: {
      form_title: 'Revenue',
      format: 'short',
      revenue_last_month: {
        form_subtitle: 'Last month'
      },
      revenue_two_months_ago: {
        form_subtitle: 'Two months ago'
      },
      revenue_three_months_ago: {
        form_subtitle: 'Three months ago'
      }
    },
    users: {
      form_title: 'Users',
      format: 'short',
      users_last_month: {
        form_subtitle: 'Last month'
      },
      users_two_months_ago: {
        form_subtitle: 'Two months ago'
      },
      users_three_months_ago: {
        form_subtitle: 'Three months ago'
      }
    }
  },
  market: {
    sales_model: {
      form_subtitle: 'What is the sales model?',
      format: 'long'
    },
    approach: {
      form_subtitle: 'What is the marketing approach?',
      format: 'long'
    },
    opportunity: {
      form_subtitle: 'What is the total addressable social?',
      format: 'long'
    },
    competitors: {
      form_subtitle: 'Who are your key competitors and how large are they?',
      format: 'long'
    },
    position: {
      form_subtitle:
        "How does the company position itself in relation to it's competitors?",
      format: 'long'
    },
    marketing_approach: {
      form_subtitle: 'What is the marketing approach?',
      format: 'long'
    },
    target_segment: {
      form_subtitle: 'What is the exact target segment of your product?',
      format: 'long'
    }
  },
  funding: {
    external_funding: {
      form_title: 'External funding',
      format: 'short',
      received: {
        form_subtitle: 'Amount received'
      },
      months_left_current_capital: {
        form_subtitle: 'Months left'
      }
    },
    fresh_capital: {
      form_title: 'Fresh capital',
      format: 'short',
      looking_for_amount: {
        form_subtitle: 'Looking for'
      },
      months_duration_next_capital: {
        form_subtitle: 'Months duration'
      }
    }
  }
};
