import auth from './Auth';
import Config from '../config';
import request from 'superagent';

export default class SocialMessagesStore {
  constructor(organizationId) {
    this.url = `${
      Config.api.host
    }/organizations/${organizationId}/social_messages`;
  }

  sendSocialMessageType(personId, medium, type) {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .post(`${this.url}/${personId}/${medium}/${type}`)
          .send({})
          .query({
            access_token: authData.token,
            client: authData.client
          })
      )
      .then((response) => response.body);
  }

  getSocialMessages() {
    return auth
      .getAuthData()
      .then((authData) =>
        request.get(this.url).query({
          access_token: authData.token,
          client: authData.client
        })
      )
      .then((response) => response.body)
      .catch((error) => {
        if (error.status !== 404) {
          console.log('Error getting Social Messages');
          console.log(error);
        }
        return {social_messages: []};
      });
  }

  resetSocialMessagesReminders() {
    return auth
      .getAuthData()
      .then((authData) =>
        request.delete(this.url).query({
          access_token: authData.token,
          client: authData.client
        })
      )
      .then((response) => response.body);
  }
}
