import auth from './Auth';
import Config from '../config';
import request from 'superagent';

export default class TechnologyStore {
  constructor(ids) {
    const host = Config.api.host;
    this.url = `${host}/technologies/${ids.join(',')}`;
  }

  technologies() {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(this.url)
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .set('Accept', 'application/json')
      )
      .then(
        (response) =>
          response.body.models && Array.isArray(response.body.models)
            ? response.body.models
            : [response.body]
      );
  }
}
