import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';

import Avatar from 'material-ui/Avatar';
import Paper from 'material-ui/Paper';
import {List, ListItem} from 'material-ui/List';

import PersonMenuItem from './PersonMenuItem';

import {activeInReachTeam} from '../../lib/helpers';
const ACTIVE_TEAM = activeInReachTeam();

export default class PersonMenu extends React.Component {
  static propTypes = {
    person: PropTypes.string.isRequired
  };

  static defaultProps = {
    person: ''
  };

  state = {open: false};

  toggleMenu = () => {
    this.setState(({open}) => ({open: !open}));
  };

  handleClick = () => {
    this.setState({open: false});
  };

  render() {
    const {person} = this.props;
    const {open} = this.state;

    const [[current = {email: person, name: person}], filtered] = _.partition(
      ACTIVE_TEAM,
      (p) => p.email === person
    );

    return (
      <Paper className="personal-inbox-people-menu">
        <List>
          <ListItem
            open={open}
            autoGenerateNestedIndicator={false}
            primaryText={current.name}
            leftIcon={<Avatar src={current.imageUrl} />}
            onClick={this.toggleMenu}
            nestedItems={filtered.map((p) => (
              <PersonMenuItem
                key={p.email}
                name={p.name}
                email={p.email}
                active={p.email === person}
                icon={<Avatar src={p.imageUrl} />}
                onClick={this.handleClick}
              />
            ))}
          />
        </List>
      </Paper>
    );
  }
}
