import auth from '../storage/Auth';
import Config from '../config';
import React, {Component} from 'react';
import request from 'superagent';

export default class PreQualificationStore {
  constructor(id) {
    this.url = `${Config.api.host}/organizations/${id}/prequalification`;
  }

  getPreQualification = () => {
    return auth
      .getAuthData()
      .then((authData) =>
        request.get(this.url).query({
          access_token: authData.token,
          client: authData.client
        })
      )
      .then((response) => response.body);
  };

  goToReconnect = (reconnectData, createdStructuredReasons) => {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .put(this.url)
          .send({
            decision: 'reconnect',
            reconnect: {
              ...reconnectData,
              reasons: createdStructuredReasons
            },
            reasons: createdStructuredReasons
          })
          .query({
            access_token: authData.token,
            client: authData.client
          })
      )
      .then((response) => response.body);
  };

  goToPass = (reason, createdStructuredReasons) => {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .put(this.url)
          .send({
            decision: 'pass',
            notes: reason,
            reasons: createdStructuredReasons
          })
          .query({
            access_token: authData.token,
            client: authData.client
          })
      )
      .then((response) => response.body);
  };

  goToQualification = (investmentType, investmentStage) => {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .put(this.url)
          .send({
            decision: 'qualify',
            investment_type: investmentType,
            investment_stage: investmentStage
          })
          .query({
            access_token: authData.token,
            client: authData.client
          })
      )
      .then((response) => response.body);
  };
}
