import React from 'react';
import PropTypes from 'prop-types';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import inreachTeam from '../../config/inreach_team';
import {activeInReachTeam} from '../../lib/helpers';

const ACTIVE_TEAM = activeInReachTeam();

export default class Organizer extends React.Component {
  static propTypes = {
    organizer: PropTypes.string,
    onOrganizerChange: PropTypes.func.isRequired
  };

  static defaultProps = {
    onOrganizerChange: () => {}
  };

  onOrganizerChange = (e, i, organizer) => {
    this.props.onOrganizerChange(organizer);
  };

  team = (organizer) => {
    if (ACTIVE_TEAM.some(({email}) => organizer === email)) {
      return ACTIVE_TEAM;
    }
    return inreachTeam;
  };

  render() {
    const {organizer = null} = this.props;

    return (
      <SelectField
        value={organizer}
        onChange={this.onOrganizerChange}
        floatingLabelText="Organizer"
        floatingLabelFixed={true}
        fullWidth={true}
        className="top-bar-selector top-bar-organizer"
        menuStyle={{
          backgroundColor: '#ffffff',
          height: '70%',
          paddingLeft: '8px'
        }}
        floatingLabelStyle={{paddingLeft: '8px'}}
      >
        <MenuItem value={null} primaryText="No organizer" />
        {this.team(organizer).map((person) => {
          return (
            <MenuItem
              key={person.email}
              value={person.email}
              primaryText={person.name}
            />
          );
        })}
      </SelectField>
    );
  }
}
