export default [
  'name',
  'description',
  'homepage_url',
  'locations',
  'founded_on',
  'total_funding_usd',
  'person_ids',
  'linkedin'
];
