import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import AttachMoney from 'material-ui/svg-icons/editor/attach-money';

import Insights from '../Insights';
import {getDataWrapperClassNames} from '../../../../lib/helpers';
import Tooltip from '../../../Tooltip';

class FundingPerformance extends React.Component {
  static propTypes = {
    funding: PropTypes.object.isRequired,
    useSnapshot: PropTypes.bool,
    previous: PropTypes.bool,
    compressed: PropTypes.bool.isRequired
  };

  state = {
    showTooltip: false
  };

  getTooltipText = () => {
    const {compressed = false} = this.props;
    const text = [];
    if (compressed) {
      text.push('Funding Performance');
    }
    return text.join('<br/>');
  };

  render() {
    const {
      funding: {
        highlights = [],
        risks = [],
        redFlags = [],
        mlUpdates = [],
        snapshot = {}
      },
      useSnapshot = false,
      previous = false,
      compressed = false
    } = this.props;
    const {showTooltip = false} = this.state;
    return (
      <div
        className="funding"
        onMouseLeave={() => {
          if (compressed) {
            this.setState({showTooltip: false});
          }
        }}
        onMouseEnter={() => {
          if (compressed) {
            this.setState({showTooltip: true});
          }
        }}
        ref={(el) => (this._parent = el)}
      >
        <If condition={!compressed}>
          <div className="section-title">{`${
            previous ? 'Previous ' : ''
          }Funding${window.innerWidth < 768 ? ' Performance' : ''}`}</div>
        </If>
        <Tooltip
          parent={this._parent}
          showTooltip={showTooltip}
          text={this.getTooltipText()}
          customClasses={['performance-tooltip']}
        />
        <div className={getDataWrapperClassNames(compressed)}>
          <div className="data-icon">
            <AttachMoney className="data-icon-svg" />
          </div>
          <Insights
            highlights={highlights}
            risks={risks}
            redFlags={redFlags}
            mlUpdates={mlUpdates}
            source="funding"
            compressed={compressed}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(FundingPerformance);
