import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import Card from '../../card/Card';
import CardHeader from '../../card/CardHeader';
import CardBody from '../../card/CardBody';
import ScorecardHeader from './ScorecardHeader';
import ScorecardList from './scorecard-list/ScorecardList';
import ScorecardModal from './scorecard-modal/ScorecardModal';
import Loading from '../../loading/Loading';
import ConfirmDeleteScorecardCallDialog from './ConfirmDeleteScorecardCallDialog';
import ConfirmCallNoShowDialog from './ConfirmCallNoShowDialog';
import Organizations from '../../../lib/Organizations';

import scorecardSections from '../../../content/scorecardSections';

import _ from 'underscore';
import {pickFormDate} from '../../../lib/scorecards';

const investmentTypesList = ['saas', 'consumer', 'marketplace', 'other'];

const revenueModelsList = [
  'recurring',
  'commission',
  'one_off_sales',
  'advertising',
  'service',
  'other'
];

const filterProductQuestions = (questions, investmentType, revenueModels) => {
  return questions.filter(
    ({investment_types = [], revenue_models = [], format = ''}) =>
      (investment_types.includes(investmentType) &&
        revenue_models.some((item) => revenueModels.includes(item))) ||
      (revenue_models.length === revenueModelsList.length &&
        investmentTypesList.length === investment_types.length)
  );
};

const generateStructuredContentId = (key) => `${key}_1`;

class ScorecardTab extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    client: PropTypes.string.isRequired,
    organizationDecision: PropTypes.object.isRequired,
    forms: PropTypes.array.isRequired,
    organizationName: PropTypes.string.isRequired,
    showScorecardModal: PropTypes.bool.isRequired,
    scorecardModalType: PropTypes.string.isRequired,
    defaultOpenId: PropTypes.string.isRequired,
    confirmCloseWithoutSavingRecommendationDecisionDialog:
      PropTypes.bool.isRequired,
    handleCloseWithoutSavingRecommendationDecision: PropTypes.func.isRequired,
    modalRecommendationDecisionSaved: PropTypes.bool.isRequired,
    scorecard: PropTypes.object.isRequired,
    templates: PropTypes.array.isRequired,
    sections: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    showErrorDialog: PropTypes.func.isRequired,
    createNewCall: PropTypes.func.isRequired,
    deleteCall: PropTypes.func.isRequired,
    userRoles: PropTypes.array.isRequired,
    currentUser: PropTypes.string.isRequired,
    writeRecommendationOrDecision: PropTypes.func.isRequired,
    investmentValues: PropTypes.object.isRequired,
    getLatestCall: PropTypes.func.isRequired,
    submitNoShow: PropTypes.func.isRequired,
    handleChangeSection: PropTypes.func.isRequired,
    updateScorecardCall: PropTypes.func.isRequired,
    saveFullScorecard: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
    revenueModelsChanged: PropTypes.bool.isRequired,
    setRevenueModelsChanged: PropTypes.func.isRequired,
    handleCallDetailsChange: PropTypes.func.isRequired,
    handleCallWithChange: PropTypes.func.isRequired,
    hasMadeChanges: PropTypes.bool.isRequired,
    setScorecardHasMadeChanges: PropTypes.func.isRequired,
    setModalRecommendationDecisionSaved: PropTypes.func.isRequired,
    closeScorecardModal: PropTypes.func.isRequired,
    openScorecardModal: PropTypes.func.isRequired,
    editScorecardModal: PropTypes.func.isRequired,
    structuredReasons: PropTypes.object.isRequired,
    reloadOrganization: PropTypes.func.isRequired,
    filesFolder: PropTypes.string,
    scorecardModalEditIndex: PropTypes.string.isRequired,
    setScorecardModalEditIndex: PropTypes.func.isRequired,
    emptyScorecardCall: PropTypes.func.isRequired
  };

  state = {
    deleteCallId: null,
    confirmCallNoShowDialogOpen: false,
    confirmDeleteCallDialogOpen: false,
    callType: '',
    commentOnly: false
  };

  componentWillMount() {
    window.addEventListener('beforeunload', (e) => {
      if (!this.props.modalRecommendationDecisionSaved) {
        e.preventDefault();
        e.returnValue = '';
      }
    });
  }

  getFailedToLoadTemplates = () => this.props.templates.length === 0;

  getFormInteresting = () => {
    const {
      organization: {source_refs: sourceRefs = {}},
      organizationDecision: {interesting = null}
    } = this.props;
    if (!!sourceRefs.FUNDING || !!sourceRefs.V2FUNDING) {
      return interesting !== null
        ? interesting
          ? 'interesting'
          : 'not-interesting'
        : 'no-decision';
    }
    return null;
  };

  getFormType = () => {
    const {organization, client} = this.props;
    const {form: isOutbound = false} = Organizations.getOrganizationState(
      organization,
      client
    );
    return isOutbound ? 'outbound' : 'inbound';
  };

  doesCallHaveNotes = (notes) => {
    notes = _.pick(notes, scorecardSections);
    return (
      Object.entries(notes).filter(
        ([section, content]) =>
          Object.entries(content).filter(
            ([key, note]) =>
              typeof note === 'string'
                ? note !== ' ' && note !== '' && note !== '<p><br></p>'
                : true /*note.value !== '' && note.value !== ' '*/
          ).length > 0
      ).length > 0
    );
  };

  getLatestCallNoShow = () => {
    const {scorecard: {calls = []} = {}, getLatestCall} = this.props;
    const {scorecardModalEditIndex} = this.state;
    return (
      (getLatestCall(calls, scorecardModalEditIndex) || {}).no_show || false
    );
  };

  getHasRecommendationOrDecision = () => {
    const {scorecard: {calls = []} = {}, getLatestCall} = this.props;
    const {scorecardModalEditIndex} = this.state;
    const latestCall = getLatestCall(calls, scorecardModalEditIndex) || {};
    return !!latestCall.decision || !!latestCall.recommendations || false;
  };

  getRequiredCallDetailsSelected = (failedToLoadTemplates) => {
    const {
      organization,
      mlInvestmentStage = false,
      mlInvestmentType = false,
      mlRevenueModel = false,
      scorecard: {calls = []} = {},
      getLatestCall,
      scorecardModalEditIndex
    } = this.props;
    const {
      investment_stage: orgInvestmentStage = null,
      investment_type: orgInvestmentType = null,
      revenue_models: orgRevenueModels = []
    } = organization;
    const {
      investment_stage: callInvestmentStage = null,
      investment_type: callInvestmentType = null,
      revenue_models: callRevenueModels = [],
      template_id: templateId = null,
      with: callWith = '',
      title: callTitle = ''
    } = getLatestCall(calls, scorecardModalEditIndex) || {};
    const availableTemplates = !!templateId || failedToLoadTemplates;

    let requiredCallDetailsSelected;

    switch (templateId) {
      case 'adhoc':
        requiredCallDetailsSelected =
          availableTemplates && callWith !== '' && callTitle !== '';
        break;
      case 'customerreferences':
      case 'productworkshop':
      case 'enterpreneurreferences':
      case 'investorreferences':
      case 'peopleworkshop':
      case 'technologyworkshop':
      case 'commercialworkshop':
        requiredCallDetailsSelected = availableTemplates && callWith !== '';
        break;
      default:
        requiredCallDetailsSelected =
          availableTemplates &&
          (!!callInvestmentStage ||
            (!mlInvestmentStage && !!orgInvestmentStage)) &&
          (!!callInvestmentType ||
            (!mlInvestmentType && !!orgInvestmentType)) &&
          (callRevenueModels.length > 0 ||
            (!mlRevenueModel && orgRevenueModels.length > 0)) &&
          callWith !== '';
        break;
    }

    return {
      availableTemplates,
      requiredCallDetailsSelected
    };
  };

  extractCallType = (title) => {
    const label = title
      .substring(0, title.indexOf(' ') > -1 ? title.indexOf(' ') : title.length)
      .replace(/[-_]/g, ' ');
    const id = label.replace(/[ ]/g, '').toLowerCase();
    return {label, id};
  };

  buildScorecard = () => {
    const failedToLoadTemplates = this.getFailedToLoadTemplates();
    return {
      scorecardList: {
        id: this.props.scorecard.id,
        forms: this.buildForms(),
        calls: this.buildCalls()
      },
      scorecardModalContent: this.buildScorecardModalContent(),
      failedToLoadTemplates: failedToLoadTemplates,
      latestCallNoShow: this.getLatestCallNoShow(),
      hasRecommendationOrDecision: this.getHasRecommendationOrDecision(),
      ...this.getRequiredCallDetailsSelected(failedToLoadTemplates)
    };
  };

  buildForms = () => {
    const {forms = []} = this.props;
    return forms
      .sort((a, b) => pickFormDate(b) - pickFormDate(b))
      .map((form, i) => {
        const status = (form.status || '').toLowerCase();
        return {
          decision: i === 0 ? this.getFormInteresting() : null,
          formType: this.getFormType(),
          submitted: pickFormDate(form),
          created: form.created || form.date_submitted,
          status: status,
          id: form.id,
          email: form.email,
          submittedBy: form.submitted_by
        };
      });
  };

  buildCalls = () => {
    const {scorecard: {calls = []} = {}} = this.props;
    return calls.sort((a, b) => b.created_at - a.created_at).map((call) => {
      const {
        id = '',
        template_id: templateId = '',
        user_id: user = '',
        recommendations = [],
        decision = {},
        created_at: date = '',
        notes = {},
        with: callWith = '',
        title: callTitle = '',
        no_show: noShow = false
      } = call;
      return {
        id,
        templateId,
        user,
        recommendations,
        decision,
        date,
        hasNotes: this.doesCallHaveNotes(notes),
        callWith,
        callTitle,
        noShow
      };
    });
  };

  buildScorecardModalContent = () => {
    const {
      forms = [],
      scorecard: {calls = []} = {},
      investmentValues: {
        investmentType: {type: orgInvestmentType, ml: mlInvestmentType},
        investmentStage: {stage: orgInvestmentStage, ml: mlInvestmentStage},
        revenueModels: {model: orgRevenueModels, ml: mlRevenueModel}
      },
      getLatestCall,
      templates,
      scorecardModalEditIndex
    } = this.props;
    const {
      recommendations = [],
      decision = {},
      investment_type: callInvestmentType = null,
      investment_stage: callInvestmentStage = null,
      revenue_models: callRevenueModels = null,
      with: callWith = '',
      title: callTitle = '',
      template_id: templateId = ''
    } = getLatestCall(calls, scorecardModalEditIndex) || {};
    const investmentType = callInvestmentType || orgInvestmentType || '';
    const investmentStage = callInvestmentStage || orgInvestmentStage || '';
    const revenueModels =
      callRevenueModels === null ? orgRevenueModels : callRevenueModels;

    const mlType = !callInvestmentType ? mlInvestmentType : false;
    const mlStage = !callInvestmentStage ? mlInvestmentStage : false;
    const mlModel = !callRevenueModels ? mlRevenueModel : false;
    return {
      ...{
        organizationInfo: {
          callType: {
            types: this.getCallTypes(templates),
            selected: templateId
          },
          investmentStage: {
            stage: investmentStage,
            ml: mlStage
          },
          callWith: callWith,
          callTitle: callTitle
        }
      },
      ...scorecardSections.reduce((sections, section) => {
        sections[section] = this.getSectionContents(
          section,
          forms,
          calls,
          (this.getTemplate(templates, templateId)[section] || []).sort(
            (a, b) => a.order - b.order
          ),
          investmentType,
          revenueModels
        );
        if (section === 'product' && sections.product.length > 0) {
          sections.product[0].investmentType = {
            type: investmentType,
            ml: mlType
          };
          sections.product[0].revenueModels = {
            model: revenueModels,
            ml: mlModel
          };
          if (sections.product[0].relevantQuestions) {
            sections.product[0].relevantQuestions = filterProductQuestions(
              sections.product[0].relevantQuestions,
              investmentType,
              revenueModels
            );
          }
        }
        return sections;
      }, {}),
      ...{
        recommendationsDecision: {
          recommendations: recommendations.sort(
            (a, b) => b.created_at - a.created_at
          ),
          decision
        }
      }
    };
  };

  getCallTypes = (templates) =>
    templates.reduce((callTypes, template) => {
      const callType = this.extractCallType(template.title);
      if (!callTypes.find((value) => value.id === callType.id)) {
        callTypes.push(callType);
      }
      return callTypes;
    }, []);

  getSectionContents = (
    section,
    forms,
    calls,
    template,
    investmentType,
    revenueModels
  ) => {
    return [
      ...forms.map((form) => this.extractSectionFromForm(section, form)),
      ...calls.map((call, i) =>
        this.extractSectionFromCall(
          section,
          call,
          i === 0 ? template : [],
          investmentType,
          revenueModels
        )
      )
    ].sort(
      (a, b) => (b.createdAt || b.submitted) - (a.createdAt || a.submitted)
    );
  };

  getTemplate = (templates, templateId) =>
    templates.find(({id}) => templateId === id) || {};

  extractSectionFromForm = (section, form) => {
    const {
      id,
      created: createdAt,
      date_submitted: dateSubmitted,
      status = '',
      version,
      submitted_by: {
        first_name: firstName = '',
        last_name: lastName = '',
        title = ''
      } = {},
      email,
      [section]: formContent = {}
    } = form;
    return {
      id,
      createdAt: pickFormDate(form),
      status: status.toLowerCase(),
      version,
      firstName,
      lastName,
      title,
      email,
      formContent,
      type: 'form',
      formType: this.getFormType(),
      submitted: createdAt || dateSubmitted
    };
  };

  extractSectionFromCall = (
    section,
    call,
    template,
    investmentType,
    revenueModels
  ) => {
    const {
      id,
      created_at: createdAt,
      notes: {[section]: sectionNotes = {}} = {},
      user_id: user,
      no_show: noShow = false,
      template_id: templateId = '',
      with: callWith = '',
      title: callTitle = ''
    } = call;
    const relevantQuestions =
      section !== 'product'
        ? template
        : filterProductQuestions(template, investmentType, revenueModels);
    return {
      id,
      createdAt,
      notes: this.getNotes(
        sectionNotes,
        templateId,
        relevantQuestions,
        section
      ),
      user,
      type: 'call',
      noShow,
      relevantQuestions,
      allQuestions: template,
      templateId,
      callWith,
      callTitle
    };
  };

  getNotes = (
    {
      formatted_content: formattedContent = ' ',
      structured_content: structuredContent = {}
    },
    templateId,
    relevantQuestions,
    section
  ) => {
    const {revenueModelsChanged = false} = this.props;
    return {
      formatted_content:
        templateId === 'formcall'
          ? formattedContent === '' || formattedContent === ' '
            ? this.generateDefaultNotes(relevantQuestions, section)
            : revenueModelsChanged
              ? this.addStructuredContentToNotes(
                  formattedContent,
                  relevantQuestions,
                  section
                )
              : formattedContent
          : formattedContent,
      structured_content: structuredContent
    };
  };

  generateDefaultNotes = (relevantQuestions, section) => {
    let formattedContent = '';
    relevantQuestions.forEach(({key, format, question}) => {
      switch (format) {
        case 'header':
          break;
        case 'integer':
        case 'integer-growth':
        case 'money':
        case 'money-growth':
          if (section === 'product') {
            const id = generateStructuredContentId(key);
            formattedContent += `<p><span data-id="${id}" data-key="${key}" data-value="" class="structured-content-span">#${id}:</span>&nbsp;</p><p><br></p><p><br></p>`;
          } else {
            formattedContent += `<p><strong>${question}</strong></p><p><br></p><p><br></p>`;
          }
          break;
        default:
          if (section !== 'risks') {
            formattedContent += `<p><strong>${question}</strong></p><p><br></p><p><br></p>`;
          }
          break;
      }
    });
    return formattedContent;
  };

  addStructuredContentToNotes = (
    formattedContent,
    relevantQuestions,
    section
  ) => {
    const {setRevenueModelsChanged} = this.props;
    relevantQuestions.forEach(({key, format, question}, index) => {
      const id = generateStructuredContentId(key);
      if (
        (format === 'integer' ||
          format === 'integer-growth' ||
          format === 'money' ||
          format === 'money-growth') &&
        section === 'product' &&
        formattedContent.indexOf(id) === -1
      ) {
        formattedContent += `<p><br></p><p><br></p><p><span data-id="${id}" data-key="${key}" data-value="" class="structured-content-span">#${id}:</span>&nbsp;</p>`;
        if (index === relevantQuestions.length) {
          formattedContent += `<p><br></p><p><br></p>`;
        }
      }
    });
    //setRevenueModelsChanged(false);
    return formattedContent;
  };

  submitRecommendationOrDecision = (type, content) => {
    const {
      writeRecommendationOrDecision,
      showErrorDialog,
      setModalRecommendationDecisionSaved,
      closeScorecardModal
    } = this.props;
    return writeRecommendationOrDecision(type, content)
      .then(() => {
        setModalRecommendationDecisionSaved(true);
        closeScorecardModal();
      })
      .catch((error) => {
        showErrorDialog(error);
        console.log(error);
      });
  };

  newCallClick = () => {
    const {createNewCall, openScorecardModal} = this.props;
    createNewCall().then((scorecard) => {
      openScorecardModal('new', scorecard.calls[0].id);
    });
  };

  toggleConfirmDeleteCallDialog = (callId = null) => {
    const {confirmDeleteCallDialogOpen} = this.state;
    this.setState({
      confirmDeleteCallDialogOpen: !confirmDeleteCallDialogOpen,
      deleteCallId: callId
    });
  };

  handleDeleteCall = () => {
    this.toggleConfirmDeleteCallDialog();
    this.props.deleteCall(this.state.deleteCallId);
  };

  toggleConfirmNoShowDialog = () => {
    const {confirmCallNoShowDialogOpen} = this.state;
    this.setState({
      confirmCallNoShowDialogOpen: !confirmCallNoShowDialogOpen
    });
  };

  noShowClick = () => {
    const {
      scorecard: {calls = []} = {},
      getLatestCall,
      closeScorecardModal,
      scorecardModalEditIndex
    } = this.props;
    const callId = (getLatestCall(calls, scorecardModalEditIndex) || {}).id;
    this.toggleConfirmNoShowDialog();
    this.props.submitNoShow(callId).then(() => {
      closeScorecardModal();
    });
  };

  setCommentOnly = (value) => {
    this.setState({commentOnly: value});
  };

  selectCallType = (currentCallType) => {
    this.setState({callType: currentCallType});
  };

  render() {
    const {
      organization,
      organizationName,
      showScorecardModal,
      scorecardModalType,
      defaultOpenId,
      loading,
      userRoles,
      currentUser,
      getLatestCall,
      showErrorDialog,
      handleChangeSection,
      updateScorecardCall,
      saveFullScorecard,
      saving,
      handleCallDetailsChange,
      handleCallWithChange,
      handleCallTitleChange,
      hasMadeChanges,
      confirmCloseWithoutSavingRecommendationDecisionDialog,
      toggleCloseWithoutSavingRecommendationDecisionDialog,
      handleCloseWithoutSavingRecommendationDecision,
      setModalRecommendationDecisionSaved,
      closeScorecardModal,
      openScorecardModal,
      editScorecardModal,
      structuredReasons = {},
      reloadOrganization,
      filesFolder = '',
      sections = {},
      scorecardModalEditIndex,
      setScorecardModalEditIndex,
      emptyScorecardCall
    } = this.props;
    const {
      confirmDeleteCallDialogOpen,
      confirmCallNoShowDialogOpen,
      commentOnly,
      callType
    } = this.state;
    const {
      scorecardList: {calls, forms},
      scorecardModalContent = {},
      failedToLoadTemplates = true,
      latestCallNoShow = false,
      hasRecommendationOrDecision = false,
      availableTemplates = false,
      requiredCallDetailsSelected = false
    } = this.buildScorecard();

    return (
      <div className="scorecard">
        <Card>
          <CardHeader title="Scorecard">
            <ScorecardHeader
              openScorecard={openScorecardModal}
              showViewScorecardButton={forms.length > 0 || calls.length > 0}
              newCallClickHandler={this.newCallClick}
              lastCall={getLatestCall(calls) || {}}
            />
          </CardHeader>
          <CardBody>
            <Choose>
              <When condition={!loading}>
                <ScorecardList
                  calls={calls}
                  forms={forms}
                  deleteCallClick={this.toggleConfirmDeleteCallDialog}
                  openScorecard={openScorecardModal}
                  selectCallType={this.selectCallType}
                />
              </When>
              <Otherwise>
                <Loading align="top" />
              </Otherwise>
            </Choose>
          </CardBody>
        </Card>
        <Choose>
          <When condition={!loading}>
            <ScorecardModal
              scorecard={scorecardModalContent}
              calls={calls}
              sections={sections}
              organization={organization}
              organizationName={organizationName}
              show={showScorecardModal}
              type={scorecardModalType}
              saveStructuredDataField={() => {}}
              close={closeScorecardModal}
              edit={editScorecardModal}
              hasConductedCall={calls.length > 0}
              newCall={this.newCallClick}
              userRoles={userRoles}
              currentUser={currentUser}
              submitRecommendationOrDecision={
                this.submitRecommendationOrDecision
              }
              getLatestCall={getLatestCall}
              noShowClick={this.noShowClick}
              failedToLoadTemplates={failedToLoadTemplates}
              latestCallNoShow={latestCallNoShow}
              hasRecommendationOrDecision={hasRecommendationOrDecision}
              toggleConfirmNoShowDialog={this.toggleConfirmNoShowDialog}
              availableTemplates={availableTemplates}
              requiredCallDetailsSelected={requiredCallDetailsSelected}
              defaultOpenId={defaultOpenId}
              showErrorDialog={showErrorDialog}
              setModalRecommendationDecisionSaved={
                setModalRecommendationDecisionSaved
              }
              confirmCloseWithoutSavingRecommendationDecisionDialog={
                confirmCloseWithoutSavingRecommendationDecisionDialog
              }
              toggleCloseWithoutSavingRecommendationDecisionDialog={
                toggleCloseWithoutSavingRecommendationDecisionDialog
              }
              handleCloseWithoutSavingRecommendationDecision={
                handleCloseWithoutSavingRecommendationDecision
              }
              handleChangeSection={handleChangeSection}
              updateScorecardCall={updateScorecardCall}
              saveFullScorecard={saveFullScorecard}
              saving={saving}
              handleCallDetailsChange={handleCallDetailsChange}
              handleCallWithChange={handleCallWithChange}
              handleCallTitleChange={handleCallTitleChange}
              hasMadeChanges={hasMadeChanges}
              structuredReasons={structuredReasons}
              reloadOrganization={reloadOrganization}
              filesFolder={filesFolder}
              commentOnly={commentOnly}
              setCommentOnly={this.setCommentOnly}
              scorecardModalEditIndex={scorecardModalEditIndex}
              setScorecardModalEditIndex={setScorecardModalEditIndex}
              selectCallType={this.selectCallType}
              callType={callType}
              openScorecardModal={openScorecardModal}
              emptyScorecardCall={emptyScorecardCall}
            />
          </When>
        </Choose>
        <ConfirmDeleteScorecardCallDialog
          open={confirmDeleteCallDialogOpen}
          onCancel={this.toggleConfirmDeleteCallDialog}
          onConfirm={this.handleDeleteCall}
        />
        <ConfirmCallNoShowDialog
          open={confirmCallNoShowDialogOpen}
          onCancel={this.toggleConfirmNoShowDialog}
          onConfirm={this.noShowClick}
        />
      </div>
    );
  }
}

export default withRouter(ScorecardTab);
