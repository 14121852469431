import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import InvestorsSearch from './investor_search/InvestorsSearch';
import auth from '../../storage/Auth';

class InvestorsPage extends React.Component {
  static propTypes = {};

  state = {
    userHasFullAccess: false,
    client: null
  };

  componentWillMount() {
    this.initComponent();
  }

  initComponent = () => {
    this.setState({
      loading: true
    });
    auth
      .getAuthData()
      .then(({client, profile, accessType, roles}) => {
        this.setState({
          userHasFullAccess: accessType === 'full',
          userRoles: roles,
          client,
          profile,
          showAsPublicProfile: false
        });
      })
      .catch((err) => {
        this.setState({
          userHasFullAccess: false,
          userRoles: ['browse_share']
        });
        console.error(err);
      });
  };

  render() {
    const {client, userHasFullAccess, userRoles} = this.state;
    return (
      <InvestorsSearch
        initSearchParams={{
          order_by: 'ml_investor_score',
          based_in_continent: 'Europe',
          is_good_investor: true,
          limit: 10
        }}
        filtersType={'investors'}
        route={{path: 'investors'}}
        client={client}
        userHasFullAccess={userHasFullAccess}
        userRoles={userRoles}
      />
    );
  }
}

export default withRouter(InvestorsPage);
