export function scrollToBottom() {
  setTimeout(() => {
    window.scroll({top: document.body.scrollHeight, behavior: 'smooth'});
  }, 100);
}

export function scrollTo(height) {
  setTimeout(() => {
    window.scroll({top: height, behavior: 'smooth'});
  }, 100);
}

export default function scrollToId(id) {
  setTimeout(() => {
    const el = document.getElementById(id);
    const top = el.getBoundingClientRect().top + window.scrollY;
    window.scroll({top: top - 64, behavior: 'smooth'});
  }, 100);
}
