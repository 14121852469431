import request from 'superagent';
import auth from './Auth';
import Config from '../config';
import Q from 'q';

class InboxStore {
  constructor(organizationId) {
    this.organizationId = organizationId;
    this.url = Config.api.host + '/organizations/' + organizationId + '/inbox';
  }

  addToInbox(assigned_to, notes, reason, priority) {
    const inbox = {
      assigned_to: assigned_to || 'roberto@inreachventures.com'
    };
    if (notes) {
      inbox.notes = notes;
    }
    if (reason) {
      inbox.reason = reason;
    }
    if (priority) {
      inbox.priority = priority;
    }

    return auth
      .getAuthData()
      .then((authData) =>
        request
          .put(this.url)
          .send(inbox)
          .query({
            access_token: authData.token,
            client: authData.client
          })
      )
      .then((response) => {
        if (typeof ga !== 'undefined') {
          ga('send', {
            hitType: 'event',
            eventCategory: 'Inbox',
            eventAction: 'add'
          });
        }
        return response.body;
      });
  }

  removeFromInbox(assignedTo) {
    return auth
      .getAuthData()
      .then((authData) =>
        request.delete(this.url).query({
          access_token: authData.token,
          client: authData.client,
          assigned_to: assignedTo
        })
      )
      .then((response) => {
        if (typeof ga !== 'undefined') {
          ga('send', {
            hitType: 'event',
            eventCategory: 'Inbox',
            eventAction: 'remove'
          });
        }
        return response.body;
      });
  }
}

export default InboxStore;
