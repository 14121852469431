import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import {capitalizeString} from '../../../../lib/helpers';

import Tooltip from '../../../Tooltip';
import Loading from '../../../loading/Loading';

class CompressedInsights extends React.Component {
  static propTypes = {
    companyInfo: PropTypes.object.isRequired,
    investorsLoading: PropTypes.bool.isRequired,
    getInvestors: PropTypes.func.isRequired,
    people: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    market: PropTypes.object.isRequired,
    funding: PropTypes.object.isRequired
  };

  state = {
    showTooltip: false
  };

  componentDidMount() {
    this.props.getInvestors();
  }

  getTooltipText = () => {
    /*const text = ['Investment Type / Stage'];
    if (this.hasError()) {
      text.push(
        'Investment Type and / or Investment Stage does not match our investment strategy'
      );
    }
    return text.join('<br/>');*/
    return 'BOO';
  };

  getCompressedInsightsString = () => {
    const {
      companyInfo: {investors: {investorIds: {topTier = []} = {}} = {}},
      people,
      product,
      market,
      funding
    } = this.props;
    const highlightsLength = [
      ...topTier,
      ...(people.highlights || []),
      ...(product.highlights || []),
      ...(market.highlights || []),
      ...(funding.highlights || [])
    ].length;
    const risksLength = [
      ...(people.risks || []),
      ...(product.risks || []),
      ...(market.risks || []),
      ...(funding.risks || [])
    ].length;
    const redFlagsLength = [
      ...(people.redFlags || []),
      ...(product.redFlags || []),
      ...(market.redFlags || []),
      ...(funding.redFlags || [])
    ].length;
    if (!highlightsLength && !risksLength && !redFlagsLength) {
      return 'No insights found';
    }
    return (
      <React.Fragment>
        <If condition={highlightsLength}>
          <span className="highlighted-text">
            {highlightsLength}
            &nbsp;highlight
            <If condition={highlightsLength !== 1}>s</If>
          </span>
          <Choose>
            <When condition={risksLength && redFlagsLength}>,&nbsp;</When>
            <When condition={risksLength || redFlagsLength}>
              &nbsp;and&nbsp;
            </When>
          </Choose>
        </If>
        <If condition={risksLength}>
          <span className="risk-text">
            {risksLength}
            &nbsp;risk
            <If condition={risksLength !== 1}>s</If>
          </span>
          <If condition={redFlagsLength}>&nbsp;and&nbsp;</If>
        </If>
        <If condition={redFlagsLength}>
          <span className="red-flag">
            {redFlagsLength}
            &nbsp;red flag
            <If condition={redFlagsLength !== 1}>s</If>
          </span>
        </If>
      </React.Fragment>
    );
  };

  render() {
    const {investorsLoading = true} = this.props;
    const {showTooltip} = this.state;
    return (
      <div
        className="data-wrapper compressed-insights compressed"
        onMouseLeave={() => {
          //this.setState({showTooltip: false});
        }}
        onMouseEnter={() => {
          //this.setState({showTooltip: true});
        }}
        rel={(el) => (this._parent = el)}
      >
        <Tooltip
          parent={this._parent}
          showTooltip={showTooltip}
          text={this.getTooltipText()}
        />
        <Choose>
          <When condition={investorsLoading}>
            <Loading small={true} />
          </When>
          <Otherwise>
            <div className="data-content text">
              {this.getCompressedInsightsString()}
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(CompressedInsights);
