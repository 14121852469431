import React from 'react';
import PropTypes from 'prop-types';

import SendEmails from '../../SendEmails';
import MLExperiments from '../MLExperiments';
import NoPrimaryContact from '../NoPrimaryContact';
import DecisionStatus from '../../../DecisionStatus';
import OrganizationStage from '../../../components/OrganizationStage';

import FlatButton from 'material-ui/FlatButton';
import Warning from 'material-ui/svg-icons/alert/warning';

export default class WorkflowInformationBody extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    organizationDecision: PropTypes.object.isRequired,
    userHasFullAccess: PropTypes.bool.isRequired,
    onStatusChange: PropTypes.func.isRequired,
    handleStopAllCampaigns: PropTypes.func.isRequired,
    handleStopEmails: PropTypes.func.isRequired,
    hasPrimaryContact: PropTypes.bool.isRequired,
    recentMessages: PropTypes.object,
    primarySocialEngaged: PropTypes.bool,
    socialEngagedIds: PropTypes.array
  };

  clearChecklist = () => {
    const {
      handleMakeContactChecklistChange,
      organizationDecision: {stage: stage}
    } = this.props;
    if (!!stage && stage === 'make_contact') {
      return handleMakeContactChecklistChange({});
    }
    return null;
  };

  render() {
    const {
      organization,
      organizationDecision,
      userHasFullAccess,
      onStatusChange,
      handleStopAllCampaigns,
      handleStopEmails,
      hasPrimaryContact
    } = this.props;

    return (
      <div className="workflow-information-body">
        <If condition={!!organizationDecision.stage}>
          <OrganizationStage
            decision={organizationDecision}
            showStage={false}
            compressed={false}
          />
        </If>

        <If condition={!!organization.experiments}>
          <MLExperiments experiments={organization.experiments} />
        </If>

        <If condition={!hasPrimaryContact}>
          <NoPrimaryContact />
        </If>
        <DecisionStatus
          organizationDecision={organizationDecision}
          onStatusChange={onStatusChange}
        />
        <If
          condition={
            ((organizationDecision.state &&
              organizationDecision.state === 'contact') ||
              organizationDecision.stage === 'make_contact' ||
              organizationDecision.stop) &&
            userHasFullAccess
          }
        >
          <div style={{display: 'flex', marginTop: '16px'}}>
            <If
              condition={
                ((organizationDecision.state &&
                  organizationDecision.state === 'contact') ||
                  organizationDecision.stage === 'make_contact') &&
                userHasFullAccess
              }
            >
              <FlatButton
                onClick={() => this.clearChecklist()}
                label={'Clear Checklist'}
                style={{
                  backgroundColor: '#337ab7',
                  height: '35px'
                }}
                labelStyle={{
                  color: '#ffffff',
                  padding: '5px',
                  fontSize: '11px'
                }}
              />
            </If>
            <div className="spacer" />
            <If
              condition={
                ((organizationDecision.state &&
                  organizationDecision.state === 'contact') ||
                  organizationDecision.stop) &&
                userHasFullAccess
              }
            >
              <SendEmails
                stop={organizationDecision.stop}
                handleStopAllCampaigns={handleStopAllCampaigns}
                handleStopEmails={handleStopEmails}
              />
            </If>
          </div>
        </If>
      </div>
    );
  }
}
