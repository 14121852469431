import React from 'react';
import {Link, withRouter} from 'react-router';
import PropTypes from 'prop-types';

import LocationOn from 'material-ui/svg-icons/communication/location-on';

import Tooltip from '../../../Tooltip';
import NewTag from '../NewTag';

import _ from 'underscore';

import {getDataWrapperClassNames} from '../../../../lib/helpers';

class Locations extends React.Component {
  static propTypes = {
    locations: PropTypes.object.isRequired,
    useSnapshot: PropTypes.bool,
    previous: PropTypes.bool,
    compressed: PropTypes.bool.isRequired,
    profileType: PropTypes.string
  };

  state = {
    showTooltip: false,
    showFull: false
  };

  getNewLocations = () => {
    const {
      locations: {
        locations = [],
        snapshot: {locations: snapshotLocations = []} = {}
      }
    } = this.props;
    return locations.filter((location) => {
      let notInSnapshot = true;
      for (const snapshotLocation of snapshotLocations) {
        if (
          _.isEqual(
            this.getLocationPropertiesUsed(_.omit(location, ['is_hq'])),
            this.getLocationPropertiesUsed(_.omit(snapshotLocation, ['is_hq']))
          )
        ) {
          notInSnapshot = false;
          break;
        }
      }
      return notInSnapshot;
    });
  };

  getLocationPropertiesUsed = (location) =>
    _.pick(location, 'city', 'country', 'continent', 'is_hq');

  sortLocations = (a, b) =>
    a.is_hq || b.is_hq
      ? a.is_hq && !b.is_hq
        ? -1
        : 1
      : a.continent === 'europe' || b.continent === 'europe'
        ? a.continent === 'europe' && b.continent !== 'europe'
          ? -1
          : 1
        : 0;

  getTooltipText = () => {
    const {
      compressed = false,
      locations: {error = false}
    } = this.props;
    const text = [];
    if (compressed) {
      text.push('Locations');
    }
    if (error) {
      text.push('None of the headquarters are in Europe');
    }
    return text.join('<br/>');
  };

  getCompressedLocationClassNames = () => {
    const {
      locations: {error}
    } = this.props;
    const classNames = ['data-content', 'text'];
    if (error) {
      classNames.push('red-flag');
    }
    return classNames.join(' ');
  };

  getLocationClassNames = () => {
    const {
      locations: {error}
    } = this.props;
    const classNames = ['data-content-list-item', 'text'];
    if (error) {
      classNames.push('red-flag');
    }
    return classNames.join(' ');
  };

  getCompressedLocationsString = () => {
    const {
      locations: {locations = []}
    } = this.props;
    const hasHQ = locations.filter(({is_hq = false}) => is_hq).length > 0;
    return locations
      .sort(this.sortLocations)
      .filter(({is_hq = false}, index) => index <= 1)
      .map((location) => this.getLocationString(location))
      .join('\n');
  };

  getLocationString = (location) => {
    const {
      city = '',
      country = '',
      continent = '',
      is_hq: isHQ = false
    } = location;
    const locationString = [];
    if (isHQ) {
      locationString.push('(HQ) - ');
    }
    if (city !== '') {
      locationString.push(`${city}`);
    }
    if (country !== '') {
      locationString.push(`${city !== '' ? ', ' : ''}${country}`);
    }
    if (country === '' && continent !== '') {
      locationString.push(
        `${city !== '' || country !== '' ? ', ' : ''}${continent}`
      );
    }
    return locationString.join('');
  };

  render() {
    const {
      locations: {locations = [], error, snapshot = {}} = {},
      previous = false,
      compressed = false,
      profileType = '',
      useSnapshot
    } = this.props;
    const {showTooltip, showFull} = this.state;
    const newLocations = this.getNewLocations();
    return (
      <div
        className={getDataWrapperClassNames(compressed, ['locations'])}
        onMouseLeave={() => {
          if (error || compressed) {
            this.setState({showTooltip: false});
          }
        }}
        onMouseEnter={() => {
          if (error || compressed) {
            this.setState({showTooltip: true});
          }
        }}
        rel={(el) => (this._parent = el)}
      >
        <div className="data-header">
          <If condition={previous}>Previous&nbsp;</If>
          Locations
        </div>
        <Tooltip
          parent={this._parent}
          showTooltip={showTooltip}
          text={this.getTooltipText()}
        />
        <div className="data-icon">
          <LocationOn className="data-icon-svg" />
        </div>
        <Choose>
          <When condition={!locations.length}>
            <div className="data-content text empty">No data</div>
          </When>
          <When condition={compressed}>
            <div className={this.getCompressedLocationClassNames()}>
              {this.getCompressedLocationsString()}
            </div>
          </When>
          <Otherwise>
            <div className="data-content list">
              {locations.sort(this.sortLocations).map((location, index) => (
                <If
                  condition={
                    profileType !== 'investor' ||
                    showFull ||
                    (!showFull && index < 2)
                  }
                >
                  <div key={index} style={{display: 'flex'}}>
                    <div
                      className={this.getLocationClassNames()}
                      key={
                        location.city || location.country || location.continent
                      }
                    >
                      {this.getLocationString(location)}
                    </div>
                    <If
                      condition={
                        useSnapshot &&
                        newLocations.some((newLocation) =>
                          _.isEqual(
                            this.getLocationPropertiesUsed(
                              _.omit(location, ['is_hq'])
                            ),
                            this.getLocationPropertiesUsed(
                              _.omit(newLocation, ['is_hq'])
                            )
                          )
                        )
                      }
                    >
                      <div className="spacer" />
                      <div className="new-tag-wrapper">
                        <div className={`new-tag`}>New</div>
                      </div>
                    </If>
                  </div>
                </If>
              ))}
            </div>
          </Otherwise>
        </Choose>
        <If condition={profileType === 'investor'}>
          <div className="person-grid-item toggle-full-profile-grid-item">
            <If condition={locations.length > 2}>
              <div
                className={`data-content text ${showFull ? 'component' : ''}`}
              >
                <Link
                  style={{cursor: 'pointer'}}
                  onClick={() => this.setState({showFull: !showFull})}
                >
                  {showFull ? `Hide All` : `Show All`}
                </Link>
              </div>
            </If>
          </div>
        </If>
      </div>
    );
  }
}

export default withRouter(Locations);
