import React from 'react';
import PropTypes from 'prop-types';
import diff from 'deep-diff';

import ScorecardModalSectionContent from './ScorecardModalSectionContent';
import ScorecardTemplateStore from '../../../../storage/ScorecardTemplateStore';

export default class ScorecardModalSectionContents extends React.Component {
  state = {
    sections: []
  };

  static propTypes = {
    contents: PropTypes.array.isRequired,
    sections: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    section: PropTypes.string.isRequired,
    failedToLoadTemplates: PropTypes.bool.isRequired,
    availableTemplates: PropTypes.bool.isRequired,
    defaultOpenId: PropTypes.string.isRequired,
    handleChangeSection: PropTypes.func.isRequired,
    callType: PropTypes.string.isRequired,
    scorecardModalEditIndex: PropTypes.string.isRequired,
    setScorecardModalEditIndex: PropTypes.func.isRequired,
    selectCallType: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    openScorecardModal: PropTypes.func.isRequired,
    emptyScorecardCall: PropTypes.func.isRequired
  };

  getClassName = (section) =>
    ['scorecard-modal-section-content-wrapper', section].join(' ');

  getExpandedByDefault = (index, type, id) => {
    const {defaultOpenId} = this.props;
    return defaultOpenId !== ''
      ? defaultOpenId === id
      : index === 0 || (index === 1 && type === 'form');
  };

  render() {
    const {
      contents,
      sections,
      type,
      section,
      failedToLoadTemplates,
      availableTemplates,
      handleChangeSection,
      scorecardModalEditIndex,
      setScorecardModalEditIndex,
      selectCallType,
      show,
      openScorecardModal
    } = this.props;

    return (
      <div className={this.getClassName(section)}>
        {contents.map((content, i) => {
          const key = content.type === 'form' ? 'form' : content.templateId;
          return (
            <If condition={sections[key].includes(section)}>
              <ScorecardModalSectionContent
                key={content.id}
                content={content}
                scorecardType={
                  content.id === scorecardModalEditIndex ? type : 'view'
                }
                expandable={
                  content.id !== scorecardModalEditIndex || type === 'view'
                }
                expanded={this.getExpandedByDefault(
                  i,
                  content.type,
                  content.id
                )}
                deEmphasised={content.id === scorecardModalEditIndex}
                section={section}
                sectionIndex={i}
                failedToLoadTemplates={failedToLoadTemplates}
                availableTemplates={availableTemplates}
                handleChangeSection={handleChangeSection}
                setScorecardModalEditIndex={setScorecardModalEditIndex}
                selectCallType={selectCallType}
                show={show}
                openScorecardModal={openScorecardModal}
              />
            </If>
          );
        })}
      </div>
    );
  }
}
