import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

export default class Ago extends React.Component {
  static propTypes = {
    timestamp: PropTypes.number,
    seconds: PropTypes.number,
    string: PropTypes.string,
    explain: PropTypes.bool,
    to: PropTypes.instanceOf(Date)
  };

  render() {
    let ago, m;
    const explain =
        this.props.explain !== undefined ? this.props.explain : false,
      to = this.props.to || new Date();
    if (this.props.timestamp) {
      m = moment(this.props.timestamp, 'x');
      ago = m.from(to, !explain);
    } else if (this.props.seconds) {
      m = moment(this.props.seconds * 1000, 'x');
      ago = m.from(to, !explain);
    } else if (this.props.string) {
      m = moment(this.props.string);
      ago = m.from(to, !explain);
    } else if (this.props.year && this.props.month && this.props.day) {
      const date =
          this.props.month + '/' + this.props.day + '/' + this.props.year,
        timestamp = new Date(date).getTime();
      m = moment(timestamp, 'x');
      ago = m.from(to, !explain);
    }

    if (!ago) {
      return <span />;
    }
    return (
      <span className="ago" title={m.toString()}>
        {ago}
      </span>
    );
  }
}
