import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import InvestorStore from '../../../storage/InvestorStore';
import diff from 'deep-diff';
import auth from '../../../storage/Auth';
import _ from 'underscore';
import EditInvestorHeader from './EditInvestorHeader';
import Loading from '../../loading/Loading';
import {Dialog, FlatButton, TextField} from 'material-ui';
import Card from '../../card/Card';
import CardBody from '../../card/CardBody';
import PatchHelper from '../../../lib/PatchHelper';
import EditBasicInfo from './EditBasicInfo';
import EditOrganizationLocations from '../../organization/edit/EditOrganizationLocations';
import CreateOrganizationHelper from '../../../lib/CreateOrganizationHelper';
import EditInvestorFields from './EditInvestorFields';

class EditInvestor extends React.Component {
  static propTypes = {
    params: PropTypes.object.isRequired
  };

  investorStore = new InvestorStore();
  createInvestorHelper = new CreateOrganizationHelper();

  state = {
    initialInvestor: null,
    investor: null,
    isLoading: false,
    isSaving: false,
    saveSuccess: false,
    userHasFullAccess: false,
    errors: {},
    modifiedFields: [],
    showErrorDialog: false,
    errorMessage: null
  };

  componentWillMount() {
    this.investorStore
      .getInvestor(this.props.params.id)
      .then((investor) => {
        this.setState({
          initialInvestor: JSON.parse(JSON.stringify(investor)),
          investor
        });
      })
      .catch((err) => console.log('Unable to retrieve Investor', err));

    auth
      .getAuthData()
      .then(({accessType, roles, client}) => {
        this.setState({
          client
        });
      })
      .catch((err) => console.error(err));
  }

  handleErrorDialogClose = () => {
    this.setState({
      errorMessage: null,
      showErrorDialog: false
    });
  };

  cancelEdit = () => {
    const {router, location} = this.props;
    const {investor: {id} = {}} = this.state;
    router.push({
      ...location,
      ...{
        pathname: `/investors/${id}`
      }
    });
  };

  saveEdit = () => {
    const {
      initialInvestor,
      investor,
      isSaving = false,
      saveSuccess = false
    } = this.state;
    if (!initialInvestor || !investor || isSaving || saveSuccess) {
      return;
    }
    this.setState({isSaving: true});
    return this.investorStore
      .updateInvestor(
        investor.id,
        PatchHelper.getPatchDataFromDiffData(initialInvestor, investor)
      )
      .then((investor) => {
        this.setState({
          isSaving: false,
          saveSuccess: true,
          initialInvestor: JSON.parse(JSON.stringify(investor)),
          investor: JSON.parse(JSON.stringify(investor)),
          modifiedFields: []
        });
        setTimeout(() => {
          this.setState({
            saveSuccess: false
          });
        }, 3000);
      })
      .catch((err) => {
        console.log('err', err);
        this.setState({
          saveSuccess: false,
          isSaving: false,
          showErrorDialog: true,
          errorMessage: err.toString()
        });
      });
  };

  render() {
    const {
      isSaving,
      saveSuccess,
      investor,
      showErrorDialog,
      errorMessage,
      client
    } = this.state;

    if (!investor || !client) {
      return <Loading />;
    }

    return (
      <EditInvestorFields
        initialInvestor={investor}
        cancel={this.cancelEdit}
        save={this.saveEdit}
        errorMessage={errorMessage}
        showErrorDialog={showErrorDialog}
        saveSuccess={saveSuccess}
        isSaving={isSaving}
        handleErrorDialogClose={this.handleErrorDialogClose}
      />
    );
  }
}

export default withRouter(EditInvestor);
