import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';

class SelectOperator extends React.Component {
  render() {
    const operator = this.props.operator;

    return (
      <div className={'select-operator'}>
        <label> Operator </label>
        <div>
          <RaisedButton
            onClick={() => this.props.handleOperatorChange('or')}
            label="OR"
            className={`operator or ${operator === 'or' ? 'active' : ''}`}
          />
          <RaisedButton
            onClick={() => this.props.handleOperatorChange('and')}
            label="AND"
            className={`operator and ${operator === 'and' ? 'active' : ''}`}
          />
        </div>
      </div>
    );
  }
}

export default SelectOperator;
