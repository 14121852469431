import auth from './Auth';
import Config from '../config';
import request from 'superagent';

export default class CalendarEventsStore {
  constructor(organizationId) {
    const host = Config.api.host;
    this.url = `${host}/organizations/${organizationId}/calendar/events`;
  }

  calendarEvents() {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(this.url)
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .set('Accept', 'application/json')
      )
      .then((response) => response.body.events || [])
      .catch((error) => {
        if (error.response && error.response.notFound) {
          return [];
        }
        throw error;
      });
  }
}
