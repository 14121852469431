import React from 'react';
import PropTypes from 'prop-types';
import IncompleteStore from '../../storage/IncompleteStore';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import Loading from '../loading/Loading';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from 'material-ui/Table';

export class BatchStats extends React.Component {
  static propTypes = {
    batchName: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired
  };

  state = {
    batch: null,
    loading: true
  };

  incompleteStore = new IncompleteStore();

  componentDidMount() {
    this.incompleteStore
      .compare(this.props.batchName)
      .then((batch) => this.setState({batch, loading: false}));
  }

  render() {
    const {loading, batch} = this.state;
    const {
      batch_name: batchName,
      field_completion_counts: fieldCompletionCounts = {},
      updated_organizations: updatedOrganizations = 0
    } = batch || {};

    return (
      <Dialog
        title={`Batch ${batchName} Stats`}
        actions={[
          <RaisedButton
            key="ok"
            label="OK"
            primary={true}
            onClick={this.props.close}
            style={{marginRight: '12px'}}
            disabled={false}
          />
        ]}
        modal={true}
        className="quick-add"
        autoDetectWindowHeight={true}
        autoScrollBodyContent={true}
        repositionOnUpdate={true}
        open={true}
      >
        <Choose>
          <When condition={loading}>
            <div className="loading-spinner">
              <Loading fixed={true} />
            </div>
          </When>
          <Otherwise>
            <div>
              <p>Total Updated Companies: {updatedOrganizations}</p>
              <Table selectable={false}>
                <TableHeader
                  adjustForCheckbox={false}
                  displaySelectAll={false}
                  enableSelectAll={false}
                >
                  <TableRow>
                    <TableHeaderColumn>Field</TableHeaderColumn>
                    <TableHeaderColumn>Number of Updates</TableHeaderColumn>
                  </TableRow>
                </TableHeader>
                <TableBody
                  displayRowCheckbox={false}
                  selectable={false}
                  stripedRows={false}
                  showRowHover={true}
                >
                  {Object.entries(fieldCompletionCounts).map(
                    ([field, updatedCount]) => {
                      return (
                        <TableRow key={field} selectable={false}>
                          <TableRowColumn>{field}</TableRowColumn>
                          <TableRowColumn>{updatedCount}</TableRowColumn>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </div>
          </Otherwise>
        </Choose>
      </Dialog>
    );
  }
}
