import React from 'react';
import PropTypes from 'prop-types';

import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import FUNDING_STAGES from '../../../content/investmentTypes';

export default class EditInvestmentType extends React.Component {
  static propTypes = {
    investmentType: PropTypes.string,
    onChangeInvestmentType: PropTypes.func.isRequired
  };

  render() {
    return (
      <div className="edit-funding-stage">
        <div className="selectors-container">
          <SelectField
            className="select-funding-stage"
            value={this.props.investmentType}
            onChange={(event, index, value) =>
              this.props.onChangeInvestmentType(value)
            }
            autoWidth={true}
          >
            <MenuItem
              className="funding-stage-entry"
              key="empty"
              value=""
              primaryText=""
            />
            {Object.entries(FUNDING_STAGES).map(([key, {name}]) => (
              <MenuItem
                className="funding-stage-entry"
                key={key}
                value={key}
                primaryText={name}
              />
            ))}
          </SelectField>
        </div>
      </div>
    );
  }
}
