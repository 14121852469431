import React, {Component} from 'react';
import TaskSummary from './TaskSummary';
import TasksStore from '../../storage/TasksStore';
import Loading from '../loading/Loading';
import TextField from 'material-ui/TextField';
import {
  Table,
  TableHeader,
  TableRow,
  TableHeaderColumn,
  TableBody
} from 'material-ui/Table';
import Paper from 'material-ui/Paper';
import {
  Toolbar,
  ToolbarGroup,
  ToolbarSeparator,
  ToolbarTitle
} from 'material-ui/Toolbar';
import NavigationRefresh from 'material-ui/svg-icons/navigation/refresh';
import RaisedButton from 'material-ui/RaisedButton';
import IconButton from 'material-ui/IconButton';
import {grey50, greenA400, red500} from 'material-ui/styles/colors';
import _ from 'underscore';
import {withRouter} from 'react-router';

const TASKS_FILTER = (tasks, filter) =>
  filter ? tasks.filter((t) => t.job_name.includes(filter)) : tasks;

const RUN_STATE_ORDER = (task) => {
  const progress = task.progress || {};
  if (progress.stopped === true) {
    return 0;
  }
  if (progress.timestamp && !progress.finished) {
    return 1;
  }
  if (progress.finished && !progress.timestamp) {
    return 2;
  }
  if (task.schedule) {
    return 3;
  }
  return 4;
};

class Tasks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tasks: [],
      loading: true
    };

    this.taskStore = new TasksStore();
  }

  compareTasks(a, b) {
    let comparison = RUN_STATE_ORDER(a) - RUN_STATE_ORDER(b);
    if (comparison === 0) {
      comparison =
        ((a.schedule || {}).priority || 3) - ((b.schedule || {}).priority || 3);
      if (comparison === 0) {
        comparison = (a.next_fire_time || 0) - (b.next_fire_time || 0);
        if (comparison === 0) {
          comparison = a.job_name < b.job_name ? -1 : 1;
        }
      }
    }
    return comparison;
  }

  getTasks = () => {
    this.setState({
      tasks: [],
      running: 0,
      scheduled: 0,
      loading: true
    });
    this.taskStore.getTasks().then((tasks) => {
      this.setState({
        tasks: [...tasks.jobs].sort(this.compareTasks),
        running: tasks.running || 0,
        scheduled: tasks.scheduled || 0,
        loading: false
      });
    }, console.error);
  };

  filterTasks = _.debounce((_event, filter) => {
    this.props.router.replace(filter ? `/tasks?filter=${filter}` : `/tasks`);
  }, 500);

  pauseScheduler = () => {
    this.taskStore.pauseScheduler().then(this.getTasks, console.error);
  };

  resumeScheduler = () => {
    this.taskStore.resumeScheduler().then(this.getTasks, console.error);
  };

  componentDidMount() {
    this.getTasks();
  }

  render() {
    const {loading, tasks} = this.state;
    const {
      location: {
        query: {filter}
      }
    } = this.props;

    return (
      <div className="tasks">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <Paper zDepth={2}>
                <Toolbar>
                  <ToolbarGroup>
                    <ToolbarTitle text="Tasks" />
                    <IconButton onClick={this.getTasks} disabled={loading}>
                      <NavigationRefresh />
                    </IconButton>
                    <TextField
                      hintText="filter by name"
                      onChange={this.filterTasks}
                      defaultValue={filter}
                    />
                  </ToolbarGroup>
                  <ToolbarGroup>
                    <ToolbarTitle text={`Running ${this.state.running}`} />
                    <ToolbarTitle text={`Scheduled ${this.state.scheduled}`} />
                  </ToolbarGroup>
                  <ToolbarGroup>
                    <ToolbarTitle text="Scheduler" />
                    <RaisedButton
                      label="Stop"
                      backgroundColor={red500}
                      labelColor={grey50}
                      onClick={this.pauseScheduler}
                      disabled={loading}
                    />
                    <RaisedButton
                      label="Start"
                      backgroundColor={greenA400}
                      labelColor={grey50}
                      onClick={this.resumeScheduler}
                      disabled={loading}
                    />
                  </ToolbarGroup>
                </Toolbar>

                <Choose>
                  <When condition={loading}>
                    <Loading />
                  </When>

                  <When condition={!loading}>
                    <Table selectable={false} fixedHeader={false}>
                      <TableHeader
                        adjustForCheckbox={false}
                        displaySelectAll={false}
                      >
                        <TableRow>
                          <TableHeaderColumn style={{width: '30%'}}>
                            Name
                          </TableHeaderColumn>
                          <TableHeaderColumn>Run State</TableHeaderColumn>
                          <TableHeaderColumn>Priority</TableHeaderColumn>
                          <TableHeaderColumn>Start</TableHeaderColumn>
                          <TableHeaderColumn>Progress</TableHeaderColumn>
                          <TableHeaderColumn>End</TableHeaderColumn>
                          <TableHeaderColumn>Next fires</TableHeaderColumn>
                        </TableRow>
                      </TableHeader>
                      <TableBody displayRowCheckbox={false}>
                        {TASKS_FILTER(tasks, filter).map((task) => (
                          <TaskSummary key={task.id} task={task} />
                        ))}
                      </TableBody>
                    </Table>
                  </When>
                </Choose>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Tasks);
