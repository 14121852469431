import FolderAdd from 'material-ui/svg-icons/av/library-add';
import ComputerAdd from 'material-ui/svg-icons/file/folder-open';
import ContentCopy from 'material-ui/svg-icons/content/content-copy';
import ContentAddBox from 'material-ui/svg-icons/content/add-box';
import SocialPeople from 'material-ui/svg-icons/social/people';
import PositionIcon from 'material-ui/svg-icons/maps/add-location';
import ProductIcon from 'material-ui/svg-icons/av/web';
import FundingIcon from 'material-ui/svg-icons/editor/monetization-on';
import StrategyIcon from 'material-ui/svg-icons/maps/my-location';
import React from 'react';

export const categoryNames = {
  people: {title: 'People', icon: <SocialPeople color="#5dab49" />},
  product: {title: 'Product', icon: <ProductIcon color="#5dab49" />},
  market_strategy: {
    title: 'Sales and Marketing Strategy',
    icon: <StrategyIcon color="#5dab49" />
  },
  market_position: {
    title: 'Market Position',
    icon: <PositionIcon color="#5dab49" />
  },
  funding: {title: 'Funding', icon: <FundingIcon color="#5dab49" />}
};

export const uploadModes = {
  add_folder: {title: 'Add from folder', icon: <FolderAdd />},
  copy_computer: {title: 'Copy from computer', icon: <ComputerAdd />},
  copy_drive: {title: 'Copy from Drive Url', icon: <ContentCopy />},
  add_drive: {title: 'Add from Drive Url', icon: <ContentAddBox />}
};
