import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import _ from 'underscore';

import Card from '../../../card/Card';
import CardHeader from '../../../card/CardHeader';
import CardBody from '../../../card/CardBody';
import Loading from '../../../loading/Loading';

import KeyProducts from './KeyProducts';
import OtherProducts from './OtherProducts';
import CustomRankIcon from '../CustomRankIcon';

import convertedLegacyInsights from '../../../../content/convertedLegacyInsights';
import Organizations from '../../../../lib/Organizations';

class ProductSection extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    snapshot: PropTypes.object.isRequired,
    snapshotLoading: PropTypes.bool.isRequired,
    useSnapshot: PropTypes.bool.isRequired,
    signalsLoading: PropTypes.bool.isRequired,
    getProductType: PropTypes.func.isRequired,
    getProductRatings: PropTypes.func.isRequired,
    getProductAnalytics: PropTypes.func.isRequired,
    getProductRevenue: PropTypes.func.isRequired,
    getProductTrend: PropTypes.func.isRequired,
    getPrimaryAppId: PropTypes.func.isRequired,
    mlUpdates: PropTypes.array
  };

  buildProducts = () => {
    const highlighted = this.getHighlightedProducts();
    return {
      highlighted,
      other: this.getOtherProducts(highlighted.map(({id}) => id))
    };
  };

  getHighlightedProducts = () => {
    const {organization: {products = []} = {}, getPrimaryAppId} = this.props;
    const primaryAppId = getPrimaryAppId();
    const highlighted = [this.getWebProduct()];
    if (products.length === 0) {
      return highlighted;
    }
    const [product1 = {}, product2 = {}] = products
      .slice(0)
      .sort(this.sortProducts);
    if (!_.isEmpty(product1)) {
      highlighted.push(
        this.getAppProduct(product1, product1.id === primaryAppId)
      );
    }
    if (!_.isEmpty(product2)) {
      highlighted.push(
        this.getAppProduct(product2, product2.id === primaryAppId)
      );
    }
    return highlighted.sort((a, b) => {
      if (a.id === primaryAppId) {
        return -1;
      } else if (b.id === primaryAppId) {
        return 1;
      } else {
        return this.sortProducts(a, b);
      }
    });
  };

  getWebProduct = () => {
    const {
      organization: {
        traffic_sources: sources = [],
        traffic_top_counties: countries = [],
        website_info: {
          is_localized: isLocalized = null,
          is_english: isEnglish = null,
          timeout = null
        } = {}
      } = {},
      snapshot: {
        organization: {
          traffic_sources: snapshotSources = [],
          traffic_top_counties: snapshotCountries = [],
          website_info: {
            is_localized: snapshotIsLocalized = null,
            is_english: snapshotIsEnglish = null,
            snapshotTimeout = null
          } = {}
        } = {},
        decision: {created_at: decisionCreatedAt} = {}
      },
      getProductRatings,
      getProductAnalytics
    } = this.props;
    const ranking = this.getProductRanking('SIMILAR_WEB-TOTAL_VISIT');
    const snapshotRanking = this.getProductRanking(
      'SIMILAR_WEB-TOTAL_VISIT',
      true
    );
    const webProduct = {
      id: 'web-analytics',
      analytics: getProductAnalytics('web', null, false, false, false),
      ratings: getProductRatings('web'),
      sources,
      countries,
      type: 'web',
      snapshot: {
        id: 'web-analytics',
        analytics: getProductAnalytics('web', null, false, false, true),
        ratings: getProductRatings('web', true),
        sources: snapshotSources,
        countries: snapshotCountries,
        type: 'web',
        decisionCreatedAt
      }
    };
    if (ranking === null) {
      if (isLocalized === false && isEnglish === false && timeout === false) {
        webProduct.ranking = {
          matched_red_flags: [convertedLegacyInsights.website_not_localized]
        };
      }
      if (isLocalized === true) {
        webProduct.ranking = {
          matched_highlights: [convertedLegacyInsights.website_is_localized]
        };
      }
    } else {
      webProduct.ranking = ranking;
    }
    if (snapshotRanking === null) {
      if (
        snapshotIsLocalized === false &&
        snapshotIsEnglish === false &&
        snapshotTimeout === false
      ) {
        webProduct.snapshot.ranking = {
          matched_red_flags: [convertedLegacyInsights.website_not_localized]
        };
      }
      if (snapshotIsLocalized === true) {
        webProduct.ranking = {
          matched_highlights: [convertedLegacyInsights.website_is_localized]
        };
      }
    } else {
      webProduct.snapshot.ranking = ranking;
    }
    return webProduct;
  };

  sortProducts = (a, b) =>
    this.getProductMagnitudePercentile(b) -
    this.getProductMagnitudePercentile(a);

  getProductMagnitudePercentile = ({id, type = 'web'}) => {
    const {organization = {}} = this.props;
    const {magnitude_percentile = 0} =
      Organizations.getTrend(organization, type, id) || {};
    return magnitude_percentile;
  };

  getAppProduct = (product, isPrimaryApp = false) => {
    const {
      getProductAnalytics,
      getProductRevenue,
      snapshot: {decision: {created_at: decisionCreatedAt} = {}} = {}
    } = this.props;
    const ranking = this.getProductRanking(product.id);
    const appProduct = {
      ...product,
      analytics: getProductAnalytics(
        'app',
        product.id,
        isPrimaryApp,
        false,
        false
      ),
      revenue: getProductRevenue(product.id),
      businessModel: this.getProductBusinessModel(product.id),
      snapshot: {
        ...this.getSnapshotProduct(product.id),
        analytics: getProductAnalytics(
          'app',
          product.id,
          isPrimaryApp,
          true,
          false
        ),
        revenue: getProductRevenue(product.id, true),
        businessModel: this.getProductBusinessModel(product.id, true),
        decisionCreatedAt
      }
    };
    if (ranking !== null) {
      appProduct.ranking = ranking;
    }
    return appProduct;
  };

  getProductRanking = (id, useSnapshot = false) => {
    const {
      organization: {ml_features: {ui_ranking: {product = []} = {}} = {}} = {},
      snapshot: {
        organization: {
          ml_features: {ui_ranking: {snapshotProduct = []} = {}} = {}
        } = {}
      }
    } = this.props;
    return (
      (!useSnapshot ? product : snapshotProduct).find(
        ({product_id}) => product_id === id
      ) || null
    );
  };

  getProductBusinessModel = (id, useSnapshot = false) => {
    return '';
  };

  getSnapshotProduct = (id) => {
    const {snapshot: {organization: {products = []} = {}} = {}} = this.props;
    return products.find(({id: productId}) => id === productId) || {};
  };

  getOtherProducts = (highlightedIds) => {
    const {organization: {products = []} = {}} = this.props;
    const otherProducts = [];
    products.forEach((product) => {
      if (highlightedIds.indexOf(product.id) === -1) {
        otherProducts.push(this.getAppProduct(product));
      }
    });
    return otherProducts.sort((a, b) => {
      if (a.type === 'web' && b.type === 'web') {
        return 0;
      } else if (a.type === 'web' && b.type !== 'web') {
        return -1;
      } else if (a.type !== 'web' && b.type === 'web') {
        return 1;
      } else {
        return (b.rating_count || 0) - (a.rating_count || 0);
      }
    });
  };

  getCardRankIcon = () => {
    const {
      organization: {ml_features: {ui_ranking: {product = []} = {}} = {}} = {}
    } = this.props;
    if (product.length === 0) {
      return null;
    }
    const rank = product.sort((a, b) => b.importance - a.importance)[0].rank;
    return <CustomRankIcon rank={rank} />;
    //return <ActionBookmark className={`rank-icon ${rank}`} />;
  };

  render() {
    const {
      organization = {},
      signalsLoading,
      useSnapshot = false,
      mlUpdates
    } = this.props;
    const {highlighted, other} = this.buildProducts();
    return (
      <div className="product-section">
        <Card>
          <CardHeader title="Product" icon={this.getCardRankIcon()} />
          <CardBody>
            <KeyProducts
              organization={organization}
              products={highlighted}
              signalsLoading={signalsLoading}
              useSnapshot={useSnapshot}
              mlUpdates={mlUpdates}
            />
            <hr />
            <OtherProducts
              organization={organization}
              products={other}
              signalsLoading={signalsLoading}
              useSnapshot={useSnapshot}
              mlUpdates={mlUpdates}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withRouter(ProductSection);
