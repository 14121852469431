import React from 'react';
import {Link, withRouter} from 'react-router';
import PropTypes from 'prop-types';

import Subject from 'material-ui/svg-icons/action/subject';

import {getDataWrapperClassNames} from '../../../../lib/helpers';
import Tooltip from '../../../Tooltip';

class Description extends React.Component {
  static propTypes = {
    description: PropTypes.object.isRequired,
    compressed: PropTypes.bool.isRequired
  };

  state = {
    showFull: false,
    showTooltip: false
  };

  maxShortDescription = 125;

  getTooltipText = () => {
    const {compressed = false} = this.props;
    const text = [];
    if (compressed) {
      text.push('Description');
    }
    return text.join('<br/>');
  };

  getDescriptionText = () => {
    const {
      compressed = false,
      description: {description, shortDescription} = {}
    } = this.props;
    const {showFull = false} = this.state;
    if (!compressed || showFull) {
      return description;
    } else {
      const useDescription = shortDescription || description;
      return useDescription.length > this.maxShortDescription
        ? `${useDescription.substring(0, this.maxShortDescription)}...`
        : useDescription;
    }
  };

  shouldShowShowMore = () => {
    const {
      compressed = false,
      description: {description, shortDescription} = {}
    } = this.props;
    const {showFull = false} = this.state;
    if (!compressed || showFull) {
      return false;
    } else {
      return (
        !!shortDescription ||
        (!!description && description.length > this.maxShortDescription)
      );
    }
  };

  handleToggleFullDescription = (showFull = !this.state.showFull) => {
    this.setState({
      showFull
    });
  };

  render() {
    const {
      description: {description, shortDescription} = {},
      compressed = false
    } = this.props;
    const {showTooltip = false} = this.state;
    return (
      <div className="description">
        <div
          className={getDataWrapperClassNames(compressed, ['description'])}
          onMouseLeave={() => {
            if (compressed) {
              this.setState({showTooltip: false});
            }
          }}
          onMouseEnter={() => {
            if (compressed) {
              this.setState({showTooltip: true});
            }
          }}
          ref={(el) => (this._parent = el)}
        >
          <div className="data-header">Description</div>
          <Tooltip
            parent={this._parent}
            showTooltip={showTooltip}
            text={this.getTooltipText()}
          />
          <Choose>
            <When condition={!description && !shortDescription}>
              <div className="data-content text empty">No data</div>
            </When>
            <Otherwise>
              <div className="data-icon">
                <Subject className="data-icon-svg" />
              </div>
              <div className="data-content text description">
                {this.getDescriptionText()}
              </div>
              <If condition={this.shouldShowShowMore()}>
                <Link
                  style={{cursor: 'pointer'}}
                  onClick={this.handleToggleFullDescription}
                  className="show-more-text-link"
                >
                  Show more
                </Link>
              </If>
            </Otherwise>
          </Choose>
        </div>
      </div>
    );
  }
}

export default withRouter(Description);
