import React from 'react';
import {withRouter} from 'react-router';

class PortfolioLink extends React.Component {
  parseFilters = (filters) => {
    let filtersText = '';
    for (let key in filters) {
      if (filtersText !== '') {
        filtersText += '&';
      }
      filtersText += key + '=' + filters[key];
    }
    return filtersText;
  };

  onCompaniesSelection = (filters) => {
    const {
      params: {id},
      updateSelectedTab,
      investorId
    } = this.props;
    const investorMergedId = id ? id : investorId;
    const filtersText = this.parseFilters(filters);
    if (updateSelectedTab) {
      updateSelectedTab('portfolio');
    }
    this.props.router.push({
      pathname: `/investors/${investorMergedId}/portfolio`,
      search: `?${filtersText}`
    });
  };

  render() {
    const {text, seriesKey = '', filters, date = false} = this.props;
    return (
      <a
        style={{
          cursor: 'pointer',
          color: '#337AB7',
          lineHeight: '1.8',
          fontSize: date ? '12px' : '14px'
        }}
        className="investor-profile-div portfolio-link"
        key={seriesKey + 'link'}
        onClick={() => this.onCompaniesSelection(filters)}
      >
        {text}
      </a>
    );
  }
}
export default withRouter(PortfolioLink);
