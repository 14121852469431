import React from 'react';
import IncompleteStore from '../../storage/IncompleteStore';
import {Toolbar, ToolbarGroup} from 'material-ui/Toolbar';
import LinearProgress from 'material-ui/LinearProgress';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from 'material-ui/Table';
import Visibility from 'material-ui/svg-icons/action/visibility';
import Download from 'material-ui/svg-icons/file/cloud-download';
import Upload from 'material-ui/svg-icons/file/cloud-upload';
import Import from 'material-ui/svg-icons/navigation/refresh';
import Check from 'material-ui/svg-icons/navigation/check';
import IconButton from 'material-ui/IconButton';
import RaisedButton from 'material-ui/RaisedButton';
import moment from 'moment';
import {CompleteBatch} from './CompleteBatch';
import {ExportNewBatch} from './ExportNewBatch';
import {BatchStats} from './BatchStats';
import ImportBatch from './ImportBatch';

export default class IncompleteBatchRoute extends React.Component {
  state = {
    batches: [],
    loading: true,
    showBatchStats: false,
    showExportNewBatch: false,
    showUploadCompletedBatch: false,
    showImportCompletedBatch: false,
    imported: [],
    tooltip: false
  };

  incompleteStore = new IncompleteStore();

  componentDidMount() {
    if (window.mixpanel) {
      mixpanel.track('Visited incomplete batches');
    }
    this.fetchBatches();
  }

  fetchBatches = () => {
    this.setState({loading: true});
    this.incompleteStore
      .incompleteBatches()
      .then((batches) => this.setState({batches, loading: false}));
  };

  downloadBatch = (batchName, hasBeenCompleted = false) => {
    this.setState({loading: true});
    this.incompleteStore
      .download(batchName)
      .then((csvBlob) => {
        const url = window.URL.createObjectURL(
          new Blob([csvBlob], {type: 'text/csv'})
        );
        const hiddenElement = document.createElement('a');
        hiddenElement.href = url;
        hiddenElement.target = '_blank';
        hiddenElement.download = `${batchName}-${
          hasBeenCompleted ? 'completed' : 'exported'
        }.csv`;
        hiddenElement.click();
        hiddenElement.remove();

        this.setState({loading: false});
      })
      .catch((error) => {
        console.error(error);
        this.setState({loading: false});
      });
  };

  renderDate = (date) => {
    if (!date) {
      return false;
    }
    const exportedMoment = moment(date);
    const startOfToday = moment().startOf('day');
    if (exportedMoment.diff(startOfToday) > -1) {
      return '0 days ago';
    }
    return exportedMoment.from(startOfToday);
  };

  render() {
    const {
      loading,
      batches,
      showBatchStats,
      showExportNewBatch,
      showUploadCompletedBatch,
      showImportCompletedBatch,
      imported,
      tooltip
    } = this.state;

    return (
      <div className="overview">
        <div className="overview-stage">
          <Toolbar>
            <ToolbarGroup>Incomplete Batches</ToolbarGroup>
            <ToolbarGroup>
              <RaisedButton
                label="Export New Batch"
                primary={true}
                onClick={() => this.setState({showExportNewBatch: true})}
              />
            </ToolbarGroup>
          </Toolbar>
          <Choose>
            <When condition={loading}>
              <LinearProgress />
            </When>

            <When condition={batches.length === 0}>
              <p className="overview-no-organizations">
                No batches have been exported
              </p>
            </When>

            <Otherwise>
              <div>
                <Table selectable={false}>
                  <TableHeader
                    adjustForCheckbox={false}
                    displaySelectAll={false}
                    enableSelectAll={false}
                  >
                    <TableRow>
                      ><TableHeaderColumn>Name</TableHeaderColumn>
                      <TableHeaderColumn>Created</TableHeaderColumn>
                      <TableHeaderColumn>Completed</TableHeaderColumn>
                      <TableHeaderColumn>{tooltip}</TableHeaderColumn>
                    </TableRow>
                  </TableHeader>
                  <TableBody
                    displayRowCheckbox={false}
                    selectable={false}
                    stripedRows={false}
                    showRowHover={true}
                  >
                    {batches.map(
                      ({
                        batch_name: batchName,
                        exported,
                        completed = '',
                        has_been_completed: hasBeenCompleted = false
                      }) => {
                        return (
                          <TableRow key={batchName} selectable={false}>
                            <TableRowColumn>{batchName}</TableRowColumn>
                            <TableRowColumn>
                              {this.renderDate(exported)}
                            </TableRowColumn>
                            <TableRowColumn>
                              {this.renderDate(completed)}
                            </TableRowColumn>
                            <TableRowColumn>
                              <IconButton
                                onClick={() =>
                                  this.downloadBatch(
                                    batchName,
                                    hasBeenCompleted
                                  )
                                }
                                onMouseEnter={() =>
                                  this.setState({tooltip: 'Download CSV'})
                                }
                                onMouseLeave={() =>
                                  this.setState({tooltip: false})
                                }
                              >
                                <Download />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  this.setState({
                                    showUploadCompletedBatch: batchName
                                  });
                                }}
                                onMouseEnter={() =>
                                  this.setState({
                                    tooltip: 'Upload Completed CSV'
                                  })
                                }
                                onMouseLeave={() =>
                                  this.setState({tooltip: false})
                                }
                              >
                                <Upload />
                              </IconButton>
                              <Choose>
                                <When condition={hasBeenCompleted}>
                                  <IconButton
                                    onClick={() => {
                                      this.setState({
                                        showBatchStats: batchName
                                      });
                                    }}
                                    onMouseEnter={() =>
                                      this.setState({
                                        tooltip:
                                          'Check Exported vs Completed Stats'
                                      })
                                    }
                                    onMouseLeave={() =>
                                      this.setState({tooltip: false})
                                    }
                                  >
                                    <Visibility />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => {
                                      this.setState({
                                        showImportCompletedBatch: batchName
                                      });
                                    }}
                                    onMouseEnter={() =>
                                      this.setState({
                                        tooltip:
                                          'Import Completed Organizations to DIG'
                                      })
                                    }
                                    onMouseLeave={() =>
                                      this.setState({tooltip: false})
                                    }
                                  >
                                    <Import />
                                  </IconButton>
                                  <If
                                    condition={imported.indexOf(batchName) > -1}
                                  >
                                    <Check />
                                  </If>
                                </When>
                              </Choose>
                            </TableRowColumn>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </div>
            </Otherwise>
          </Choose>
        </div>
        <If condition={showBatchStats}>
          <BatchStats
            batchName={showBatchStats}
            close={() => this.setState({showBatchStats: false})}
          />
        </If>
        <If condition={showExportNewBatch}>
          <ExportNewBatch
            existingBatchNames={batches.map(
              ({batch_name: batchName}) => batchName
            )}
            close={() => {
              this.setState({showExportNewBatch: false});
              this.fetchBatches();
            }}
          />
        </If>
        <If condition={showUploadCompletedBatch}>
          <CompleteBatch
            close={() => {
              this.setState({showUploadCompletedBatch: false});
              this.fetchBatches();
            }}
            batchName={showUploadCompletedBatch}
          />
        </If>
        <If condition={showImportCompletedBatch}>
          <ImportBatch
            batchName={showImportCompletedBatch}
            close={() => this.setState({showImportCompletedBatch: false})}
            imported={() => {
              imported.push(showImportCompletedBatch);
              this.setState({
                imported,
                showImportCompletedBatch: false
              });
            }}
          />
        </If>
      </div>
    );
  }
}
