import request from 'superagent';
import auth from './Auth';
import Config from '../config';
import Q from 'q';

export default class OrganizationSuggestStore {
  constructor() {
    this.url = Config.api.host + '/organizations';
  }

  suggestByName(name) {
    return auth
      .getAuthData()
      .then((authData) =>
        request.get(this.url).query({
          access_token: authData.token,
          client: authData.client,
          name: name,
          classification_not: 'never,missed_opportunity',
          based_in_continent: 'europe',
          limit: 10,
          order_by: 'score'
        })
      )
      .then((response) => response.body.organizations);
  }
}
