import React from 'react';
import PropTypes from 'prop-types';
import {FlatButton, TextField} from 'material-ui';
import AddCircleOutline from 'material-ui/svg-icons/content/add-circle';
import ActionDelete from 'material-ui/svg-icons/action/delete';

export default class EditPhoneNumbers extends React.Component {
  static propTypes = {
    phoneNumbers: PropTypes.array,
    handlePhoneNumbersChange: PropTypes.func.isRequired
  };

  state = {
    newPhoneNumber: ''
  };

  render() {
    const {phoneNumbers = []} = this.props;

    return (
      <div className="edit-phone-numbers">
        {phoneNumbers.map((number, i) => (
          <div key={i}>
            <TextField
              value={number}
              onChange={(event) => {
                phoneNumbers[i] = event.target.value;
                this.props.handlePhoneNumbersChange(phoneNumbers);
              }}
              name="edit-phone-number"
            />
            <FlatButton
              className="remove-phone-number"
              label="Remove"
              labelStyle={{color: '#C30000'}}
              icon={<ActionDelete color={'#C30000'} />}
              onClick={() => {
                phoneNumbers.splice(i);
                this.props.handlePhoneNumbersChange(phoneNumbers);
              }}
            />
          </div>
        ))}
        <div>
          <TextField
            value={this.state.newPhoneNumber}
            onChange={(event) => {
              this.setState({
                newPhoneNumber: event.target.value
              });
            }}
            name="add-phone-number"
          />
          <FlatButton
            label="Add Phone Number"
            onClick={() => {
              phoneNumbers.push(this.state.newPhoneNumber);
              this.props.handlePhoneNumbersChange(phoneNumbers);
              this.setState({
                newPhoneNumber: ''
              });
            }}
            icon={<AddCircleOutline color={'#5DAB49'} />}
          />
        </div>
      </div>
    );
  }
}
