export default {
  send_invite: {
    text: 'Send Date / Time',
    checklist: 'make_contact',
    order: 0
  },
  connect_on_linkedin: {
    text: 'Connect on LinkedIn with all the founders',
    checklist: 'make_contact',
    order: 1
  },
  send_reminder: {
    text: 'Send an email reminder',
    checklist: 'make_contact',
    order: 2
  },
  send_inmail: {
    text: 'Send LinkedIn message',
    checklist: 'make_contact',
    order: 3
  },
  confirm_unengaged: {
    text: 'Confirm Primary Contact is not engaging',
    checklist: 'make_contact',
    order: 4
  },
  add_founder: {
    text: 'Add new founder on DIG',
    checklist: 'make_contact',
    order: 5
  },
  restart: {
    text: 'Restart with new Primary Contact',
    checklist: 'make_contact',
    order: 6
  },
  restart_all: {
    text: 'Restarted the process with two founders',
    checklist: 'make_contact',
    order: 7
  },
  send_website_messages: {
    text: 'Send message on website',
    checklist: 'unable_to_contact',
    order: 0
  },
  connect_management_linkedin: {
    text: 'Connect on LinkedIn with management team',
    checklist: 'unable_to_contact',
    order: 1
  },
  send_social: {
    text: 'Send message on Facebook, Twitter, ...',
    checklist: 'unable_to_contact',
    order: 2
  },
  send_management_linkedin: {
    text: 'Send LinkedIn message to management team',
    checklist: 'unable_to_contact',
    order: 3
  },
  final_go: {
    text: 'Send to Roberto',
    checklist: 'unable_to_contact',
    order: 4
  },
  connect_network: {
    text: 'Connect through network',
    checklist: 'unable_to_contact',
    order: 5
  }
};
