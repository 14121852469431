import React from 'react';
import PropTypes from 'prop-types';

import _ from 'underscore';

import scorecardFormFormat from '../../../../content/scorecardFormFormat';

function FormObject(props) {
  const {formSectionFormat, answer} = props;
  const {format = 'short'} = formSectionFormat;
  return (
    <div className={`form-aspect-answer-wrapper ${format}`}>
      {Object.entries(answer).map(([subQuestion, subAnswer]) => {
        const {form_subtitle: subtitle = null} =
          formSectionFormat[subQuestion] || {};
        return (
          <div className="form-aspect-answer" key={subQuestion}>
            <If condition={subtitle}>
              <div className="form-subtitle">{subtitle}</div>
            </If>
            <div className="form-aspect-answer-value">{subAnswer}</div>
          </div>
        );
      })}
    </div>
  );
}

function FormArrayOrString(props) {
  const {
    formSectionFormat: {form_subtitle: subtitle = null, format = 'long'},
    answer
  } = props;
  return (
    <React.Fragment>
      <If condition={subtitle}>
        <div className="form-subtitle">{subtitle}</div>
      </If>
      <div className={`form-aspect-answer-wrapper ${format}`}>
        <Choose>
          <When condition={typeof answer === 'string'}>
            <div className="form-aspect-answer">{answer}</div>
          </When>
          <When condition={Array.isArray(answer)}>
            {answer.map((subAnswer) => (
              <div
                className="form-aspect-answer"
                key={`${subAnswer.name || subAnswer.first_name}-${
                  subAnswer.last_name
                }-${subAnswer.title}`}
              >
                <FormFounderOrKeyEmployee person={subAnswer} />
              </div>
            ))}
          </When>
        </Choose>
      </div>
    </React.Fragment>
  );
}

function FormFounderOrKeyEmployee(props) {
  const {
    person: {
      name,
      first_name = '',
      last_name = '',
      title = '',
      linkedin = '',
      percentage = ''
    }
  } = props;
  return (
    <div className="founder-key-employee">
      <div className="form-subtitle">{`${name ||
        first_name} ${last_name}`}</div>
      <div className="founder-key-employee-title-ownership">
        <If condition={title !== ''}>
          <span className="title">{`${title}`}</span>
        </If>
        <If condition={percentage !== ''}>
          <span className="ownership">{` - ${percentage}%`}</span>
        </If>
      </div>
    </div>
  );
}

export default class ScorecardModalSectionContentForm extends React.Component {
  static propTypes = {
    content: PropTypes.object.isRequired
  };

  getAnswerType = (answer) =>
    Array.isArray(answer)
      ? 'array'
      : typeof answer === 'object'
        ? 'object'
        : 'string';

  removeUndefinedAnswers = (answer, type) => {
    if (type === 'object') {
      answer = _.omit(answer, _.isUndefined);
    }
    return answer;
  };

  questionHasAnswer = (answer, type) =>
    (type === 'array' && answer.length > 0) ||
    (type === 'object' && Object.keys(answer).length !== 0) ||
    (type === 'string' && typeof answer !== 'undefined' && answer !== '');

  render() {
    const {section, content} = this.props;
    const {[section]: formSectionFormat = {}} = scorecardFormFormat;
    return (
      <div className="scorecard-modal-section-content-form">
        {Object.entries(content).map(([question, answer]) => {
          const answerType = this.getAnswerType(answer);
          answer = this.removeUndefinedAnswers(answer, answerType);
          if (!this.questionHasAnswer(answer, answerType)) {
            return null;
          }
          const {form_title: title = null} = formSectionFormat[question] || {};
          return (
            <div className="form-aspect" key={question}>
              <If condition={title}>
                <div className="form-title">{title}</div>
              </If>
              <Choose>
                <When
                  condition={
                    typeof answer === 'object' && answer.constructor === Object
                  }
                >
                  <FormObject
                    formSectionFormat={formSectionFormat[question] || {}}
                    answer={answer}
                  />
                </When>
                <Otherwise>
                  <FormArrayOrString
                    formSectionFormat={formSectionFormat[question] || {}}
                    answer={answer}
                  />
                </Otherwise>
              </Choose>
            </div>
          );
        })}
      </div>
    );
  }
}
