import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import EditorPublish from 'material-ui/svg-icons/editor/publish';
import TextField from 'material-ui/TextField';
import Dialog from 'material-ui/Dialog';

const MAX_EXPORT_SIZE = 5000;

class ExportToCSV extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      filename: `DIG ${new Date().toDateString()}`,
      limit: 1000,
      limitError: null
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleFilenameChange = this.handleFilenameChange.bind(this);
    this.handleLimitChange = this.handleLimitChange.bind(this);
  }

  handleOpen() {
    this.setState({open: true});
  }

  handleClose() {
    this.setState({open: false});
  }

  handleFilenameChange(event) {
    this.setState({
      filename: event.target.value
    });
  }

  handleLimitChange(event) {
    const value = event.target.value;

    return value <= MAX_EXPORT_SIZE
      ? this.setState({limit: value})
      : this.setState({limitError: `maximum allowed is ${MAX_EXPORT_SIZE}`});
  }

  render() {
    const actions = [
      <RaisedButton
        className="assign-action cancel"
        label="Cancel"
        style={{
          marginRight: '10px'
        }}
        onClick={this.handleClose}
      />,
      <RaisedButton
        label={this.props.CTALabel ? this.props.CTALabel : 'Download CSV'}
        className="assign-action submit"
        backgroundColor="#5DAB49"
        labelStyle={{
          color: 'white'
        }}
        onClick={() => {
          this.props.exportToCSV(this.state.filename, this.state.limit);
          this.handleClose();
        }}
      />
    ];
    return (
      <div className="export-to-csv">
        <RaisedButton
          backgroundColor="white"
          labelColor="#5DAB49"
          label="Export to CSV file"
          onClick={this.handleOpen}
          icon={<EditorPublish />}
        />
        <Dialog
          title="Export to CSV"
          actions={actions}
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
        >
          <div className="filename">
            <label> Filename </label>
            <TextField
              id="text-field-controlled"
              value={this.state.filename}
              onChange={this.handleFilenameChange}
            />
          </div>
          <div className="limit">
            <label> Limit </label>
            <TextField
              id="text-field-controlled"
              type="number"
              value={this.state.limit}
              errorText={this.state.limitError}
              onChange={this.handleLimitChange}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

export default ExportToCSV;
