import _import from '../../images/import100x100.png';
import a360capital from '../../images/a360capital100x100.png';
import a3ts_capital from '../../images/a3ts_capital100x100.png';
import absl_startup_challenge from '../../images/absl_startup_challenge100x100.png';
import accel from '../../images/accel100x100.png';
import accelerace from '../../images/accelerace100x100.png';
import acme from '../../images/acme100x100.png';
import ada_ventures from '../../images/ada_ventures100x100.png';
import agoranov from '../../images/agoranov100x100.png';
import albion from '../../images/albion100x100.png';
import all_startups from '../../images/all_startups100x100.png';
import almi_invest from '../../images/almi_invest100x100.png';
import am_ventures from '../../images/am_ventures100x100.png';
import angel_academe from '../../images/angel_academe100x100.png';
import angellist from '../../images/angellist100x100.png';
import anthemis from '../../images/anthemis100x100.png';
import antler from '../../images/antler100x100.png';
import apollo_io from '../../images/apollo_io100x100.png';
import apx from '../../images/apx100x100.png';
import armilar from '../../images/armilar100x100.png';
import atlanticlabs from '../../images/atlanticlabs100x100.png';
import atomico from '../../images/atomico100x100.png';
import axa_vp from '../../images/axa_vp100x100.png';
import azertyjobs from '../../images/azertyjobs100x100.png';
import b10 from '../../images/b10100x100.png';
import balderton from '../../images/balderton100x100.png';
import barcelonatechcity from '../../images/barcelonatechcity100x100.png';
import bcn_startup_map from '../../images/bcn_startup_map100x100.png';
import bcnstartupmap from '../../images/bcnstartupmap100x100.png';
import berlin_startup_jobs from '../../images/berlin_startup_jobs100x100.png';
import besthorizon from '../../images/besthorizon100x100.png';
import beta_list from '../../images/beta_list100x100.png';
import black_pearls from '../../images/black_pearls100x100.png';
import blockchainstartups from '../../images/blockchainstartups100x100.png';
import born2grow from '../../images/born2grow100x100.png';
import breega from '../../images/breega100x100.png';
import brightcap_vc from '../../images/brightcap_vc100x100.png';
import bvp from '../../images/bvp100x100.png';
import caixa_capital_risc from '../../images/caixa_capital_risc100x100.png';
import caphub_io from '../../images/caphub_io100x100.png';
import capnamicventures from '../../images/capnamicventures100x100.png';
import capterra from '../../images/capterra100x100.png';
import chalmers_ventures from '../../images/chalmers_ventures100x100.png';
import cherry_vc from '../../images/cherry_vc100x100.png';
import clearbit from '../../images/clearbit100x100.png';
import collision from '../../images/collision100x100.png';
import conorfund from '../../images/conorfund100x100.png';
import coparion from '../../images/coparion100x100.png';
import creandum from '../../images/creandum100x100.png';
import creathor from '../../images/creathor100x100.png';
import credoventures from '../../images/credoventures100x100.png';
import crowdsurfer from '../../images/crowdsurfer100x100.png';
import crunchbase from '../../images/crunchbase100x100.png';
import csv from '../../images/csv100x100.png';
import cube_five from '../../images/cube_five100x100.png';
import cylonlab from '../../images/cylonlab100x100.png';
import czechstartups from '../../images/czechstartups100x100.png';
import daphni from '../../images/daphni100x100.png';
import dealroom from '../../images/dealroom100x100.png';
import dealroom_transactions from '../../images/dealroom_transactions100x100.png';
import deeptechforgood from '../../images/deeptechforgood100x100.png';
import deltapartners from '../../images/deltapartners100x100.png';
import der_brutkasten from '../../images/der_brutkasten100x100.png';
import digital_grenoble from '../../images/digital_grenoble100x100.png';
import digital_health_london from '../../images/digital_health_london100x100.png';
import djakne from '../../images/djakne100x100.png';
import dncapital from '../../images/dncapital100x100.png';
import downing from '../../images/downing100x100.png';
import draperesprit from '../../images/draperesprit100x100.png';
import dublin_tech_summit from '../../images/dublin_tech_summit100x100.png';
import dutchstartups from '../../images/dutchstartups100x100.png';
import e27 from '../../images/e27100x100.png';
import earlybird from '../../images/earlybird100x100.png';
import eipp from '../../images/eipp100x100.png';
import elevator_ventures from '../../images/elevator_ventures100x100.png';
import eleven from '../../images/eleven100x100.png';
import email_hunter from '../../images/email_hunter100x100.png';
import entrepreneur_first from '../../images/entrepreneur_first100x100.png';
import episode1 from '../../images/episode1100x100.png';
import erasmus_centre from '../../images/erasmus_centre100x100.png';
import estonianmafia from '../../images/estonianmafia100x100.png';
import eu_startups from '../../images/eu_startups100x100.png';
import eventmanagerblog from '../../images/eventmanagerblog100x100.png';
import eventures from '../../images/eventures100x100.png';
import experior_venture_fund from '../../images/experior_venture_fund100x100.png';
import f10 from '../../images/f10100x100.png';
import f6s from '../../images/f6s100x100.png';
import fasttrackmalmo from '../../images/fasttrackmalmo100x100.png';
import female_founders_grow_f from '../../images/female_founders_grow_f100x100.png';
import finsmes from '../../images/finsmes100x100.png';
import fintastico from '../../images/fintastico100x100.png';
import firstminute_capital from '../../images/firstminute_capital100x100.png';
import fomcap from '../../images/fomcap100x100.png';
import forwardpartners from '../../images/forwardpartners100x100.png';
import frontline_ventures from '../../images/frontline_ventures100x100.png';
import ft_1000 from '../../images/ft_1000100x100.png';
import funderbeam from '../../images/funderbeam100x100.png';
import funding from '../../images/funding100x100.png';
import get_latka from '../../images/get_latka100x100.png';
import getinthering from '../../images/getinthering100x100.png';
import github from '../../images/github100x100.png';
import github_trending from '../../images/github_trending100x100.png';
import googlecse from '../../images/googlecse100x100.png';
import gruenderszene from '../../images/gruenderszene100x100.png';
import hamburg_startups from '../../images/hamburg_startups100x100.png';
import high_tech_gruenderfonds from '../../images/high_tech_gruenderfonds100x100.png';
import highland_europe from '../../images/highland_europe100x100.png';
import hired from '../../images/hired100x100.png';
import hiventures from '../../images/hiventures100x100.png';
import hoxton_ventures from '../../images/hoxton_ventures100x100.png';
import hv_capital from '../../images/hv_capital100x100.png';
import ibb_bet from '../../images/ibb_bet100x100.png';
import imperialenterpriselab from '../../images/imperialenterpriselab100x100.png';
import inc_5000_eu from '../../images/inc_5000_eu100x100.png';
import index_ventures from '../../images/index_ventures100x100.png';
import industrifonden from '../../images/industrifonden100x100.png';
import industryinvestment from '../../images/industryinvestment100x100.png';
import initial_capital from '../../images/initial_capital100x100.png';
import inreach from '../../images/inreach100x100.png';
import inveready from '../../images/inveready100x100.png';
import investiere from '../../images/investiere100x100.png';
import iq_capital from '../../images/iq_capital100x100.png';
import isomer_capital from '../../images/isomer_capital100x100.png';
import j_and_t_ventures from '../../images/j_and_t_ventures100x100.png';
import jaimelesstartups from '../../images/jaimelesstartups100x100.png';
import jme_ventures from '../../images/jme_ventures100x100.png';
import jobfluent from '../../images/jobfluent100x100.png';
import jobs_stationf from '../../images/jobs_stationf100x100.png';
import kaya from '../../images/kaya100x100.png';
import kfund from '../../images/kfund100x100.png';
import kima_ventures from '../../images/kima_ventures100x100.png';
import labs_of_latvia from '../../images/labs_of_latvia100x100.png';
import lafrenchtech_visas from '../../images/lafrenchtech_visas100x100.png';
import landingfolio from '../../images/landingfolio100x100.png';
import lanzame from '../../images/lanzame100x100.png';
import launched from '../../images/launched100x100.png';
import launchingnext from '../../images/launchingnext100x100.png';
import launchub from '../../images/launchub100x100.png';
import les_pepites_tech from '../../images/les_pepites_tech100x100.png';
import lhv from '../../images/lhv100x100.png';
import linkedin from '../../images/linkedin100x100.png';
import linkedinweb from '../../images/linkedinweb100x100.png';
import lisbon_challenge from '../../images/lisbon_challenge100x100.png';
import lupa from '../../images/lupa100x100.png';
import lventure from '../../images/lventure100x100.png';
import mam_startup from '../../images/mam_startup100x100.png';
import manta_ray from '../../images/manta_ray100x100.png';
import maze_x from '../../images/maze_x100x100.png';
import merian_ventures from '../../images/merian_ventures100x100.png';
import mmc_ventures from '../../images/mmc_ventures100x100.png';
import monacotech from '../../images/monacotech100x100.png';
import mountain_partners from '../../images/mountain_partners100x100.png';
import munich_startup from '../../images/munich_startup100x100.png';
import national_business_awards_uk from '../../images/national_business_awards_uk100x100.png';
import nautacapital from '../../images/nautacapital100x100.png';
import neulogy from '../../images/neulogy100x100.png';
import neveq from '../../images/neveq100x100.png';
import newfund from '../../images/newfund100x100.png';
import ngp_capital from '../../images/ngp_capital100x100.png';
import no_fluff_jobs from '../../images/no_fluff_jobs100x100.png';
import nordictechlist from '../../images/nordictechlist100x100.png';
import northzone from '../../images/northzone100x100.png';
import numa_hub from '../../images/numa_hub100x100.png';
import oktogon_vc from '../../images/oktogon_vc100x100.png';
import oltreventure from '../../images/oltreventure100x100.png';
import omnescapital from '../../images/omnescapital100x100.png';
import open_ocean from '../../images/open_ocean100x100.png';
import openfuture from '../../images/openfuture100x100.png';
import opengraph from '../../images/opengraph100x100.png';
import otb_ventures from '../../images/otb_ventures100x100.png';
import oxford_capital from '../../images/oxford_capital100x100.png';
import oxyleads from '../../images/oxyleads100x100.png';
import p101 from '../../images/p101100x100.png';
import parkwalk_advisors from '../../images/parkwalk_advisors100x100.png';
import partech from '../../images/partech100x100.png';
import passion from '../../images/passion100x100.png';
import pilabs from '../../images/pilabs100x100.png';
import playfair from '../../images/playfair100x100.png';
import plug_and_play from '../../images/plug_and_play100x100.png';
import portugalventures from '../../images/portugalventures100x100.png';
import postmake from '../../images/postmake100x100.png';
import predictleads from '../../images/predictleads100x100.png';
import predictleads_post from '../../images/predictleads_post100x100.png';
import presto_ventures from '../../images/presto_ventures100x100.png';
import producthunt from '../../images/producthunt100x100.png';
import project_a from '../../images/project_a100x100.png';
import rbvc from '../../images/rbvc100x100.png';
import reaktorx from '../../images/reaktorx100x100.png';
import rockstart from '../../images/rockstart100x100.png';
import romanianstartups from '../../images/romanianstartups100x100.png';
import s_ubg_group from '../../images/s_ubg_group100x100.png';
import saas1000 from '../../images/saas1000100x100.png';
import samaipata from '../../images/samaipata100x100.png';
import sap_io from '../../images/sap_io100x100.png';
import schibsted_growth from '../../images/schibsted_growth100x100.png';
import seayaventures from '../../images/seayaventures100x100.png';
import seed_db from '../../images/seed_db100x100.png';
import seedcamp from '../../images/seedcamp100x100.png';
import seedcaptial_dk from '../../images/seedcaptial_dk100x100.png';
import sensortower from '../../images/sensortower100x100.png';
import seventure from '../../images/seventure100x100.png';
import signals_vc from '../../images/signals_vc100x100.png';
import smith_and_crown from '../../images/smith_and_crown100x100.png';
import smithandcrown from '../../images/smithandcrown100x100.png';
import sosv from '../../images/sosv100x100.png';
import speedinvest from '../../images/speedinvest100x100.png';
import start_green from '../../images/start_green100x100.png';
import startbase from '../../images/startbase100x100.png';
import starterstory from '../../images/starterstory100x100.png';
import startup88 from '../../images/startup88100x100.png';
import startup_brett from '../../images/startup_brett100x100.png';
import startup_chile from '../../images/startup_chile100x100.png';
import startup_estonia from '../../images/startup_estonia100x100.png';
import startup_hubs from '../../images/startup_hubs100x100.png';
import startup_inspire from '../../images/startup_inspire100x100.png';
import startup_lab from '../../images/startup_lab100x100.png';
import startup_navigator from '../../images/startup_navigator100x100.png';
import startup_ole from '../../images/startup_ole100x100.png';
import startup_only from '../../images/startup_only100x100.png';
import startup_poland from '../../images/startup_poland100x100.png';
import startup_tartu from '../../images/startup_tartu100x100.png';
import startup_wiseguys from '../../images/startup_wiseguys100x100.png';
import startup_xplore from '../../images/startup_xplore100x100.png';
import startupbase from '../../images/startupbase100x100.png';
import startupbenchmarks from '../../images/startupbenchmarks100x100.png';
import startupberlin from '../../images/startupberlin100x100.png';
import startupblink from '../../images/startupblink100x100.png';
import startupbuffer from '../../images/startupbuffer100x100.png';
import startupbutton from '../../images/startupbutton100x100.png';
import startupcollections from '../../images/startupcollections100x100.png';
import startupexplore from '../../images/startupexplore100x100.png';
import startupjobs_cz from '../../images/startupjobs_cz100x100.png';
import startuplift from '../../images/startuplift100x100.png';
import startuplisboa from '../../images/startuplisboa100x100.png';
import startupmatcher from '../../images/startupmatcher100x100.png';
import startupnorway from '../../images/startupnorway100x100.png';
import startuppoland from '../../images/startuppoland100x100.png';
import startupranking from '../../images/startupranking100x100.png';
import startuproulette from '../../images/startuproulette100x100.png';
import startups_be from '../../images/startups_be100x100.png';
import startups_co_uk from '../../images/startups_co_uk100x100.png';
import startups_hu from '../../images/startups_hu100x100.png';
import startups_list from '../../images/startups_list100x100.png';
import startupsbe from '../../images/startupsbe100x100.png';
import startupslist from '../../images/startupslist100x100.png';
import startus from '../../images/startus100x100.png';
import sting from '../../images/sting100x100.png';
import streak from '../../images/streak100x100.png';
import sup46 from '../../images/sup46100x100.png';
import tech_ireland from '../../images/tech_ireland100x100.png';
import tech_london from '../../images/tech_london100x100.png';
import tech_pitch_4pt5 from '../../images/tech_pitch_4pt5100x100.png';
import techlondon from '../../images/techlondon100x100.png';
import technopark_liechtenstein from '../../images/technopark_liechtenstein100x100.png';
import techstars_airtable from '../../images/techstars_airtable100x100.png';
import techstars_demoday_airtable from '../../images/techstars_demoday_airtable100x100.png';
import tera_vc from '../../images/tera_vc100x100.png';
import the_factory from '../../images/the_factory100x100.png';
import the_fse_group from '../../images/the_fse_group100x100.png';
import the_hub from '../../images/the_hub100x100.png';
import the_startup_pitch from '../../images/the_startup_pitch100x100.png';
import the_venture_city from '../../images/the_venture_city100x100.png';
import thehub from '../../images/thehub100x100.png';
import token_data from '../../images/token_data100x100.png';
import tokendata from '../../images/tokendata100x100.png';
import tomorrow_today from '../../images/tomorrow_today100x100.png';
import top100startup from '../../images/top100startup100x100.png';
import twitter from '../../images/twitter100x100.png';
import unicorn_hunt from '../../images/unicorn_hunt100x100.png';
import unicorns_lt from '../../images/unicorns_lt100x100.png';
import v2funding from '../../images/v2funding100x100.png';
import vcee_startups from '../../images/vcee_startups100x100.png';
import venturefriends from '../../images/venturefriends100x100.png';
import wayra_uk from '../../images/wayra_uk100x100.png';
import web_summit from '../../images/web_summit100x100.png';
import welcome_to_the_jungle from '../../images/welcome_to_the_jungle100x100.png';
import white_star_capital from '../../images/white_star_capital100x100.png';
import workinstartups from '../../images/workinstartups100x100.png';
import xange from '../../images/xange100x100.png';
import y_combinator from '../../images/y_combinator100x100.png';
import ycombinator from '../../images/ycombinator100x100.png';

const sources = {
  _import,
  a360capital,
  a3ts_capital,
  absl_startup_challenge,
  accel,
  accelerace,
  acme,
  ada_ventures,
  agoranov,
  albion,
  all_startups,
  almi_invest,
  am_ventures,
  angel_academe,
  angellist,
  anthemis,
  antler,
  apollo_io,
  apx,
  armilar,
  atlanticlabs,
  atomico,
  axa_vp,
  azertyjobs,
  b10,
  balderton,
  barcelonatechcity,
  bcn_startup_map,
  bcnstartupmap,
  berlin_startup_jobs,
  besthorizon,
  beta_list,
  black_pearls,
  blockchainstartups,
  born2grow,
  breega,
  brightcap_vc,
  bvp,
  caixa_capital_risc,
  caphub_io,
  capnamicventures,
  capterra,
  chalmers_ventures,
  cherry_vc,
  clearbit,
  collision,
  conorfund,
  coparion,
  creandum,
  creathor,
  credoventures,
  crowdsurfer,
  crunchbase,
  csv,
  cube_five,
  cylonlab,
  czechstartups,
  daphni,
  dealroom,
  dealroom_transactions,
  deeptechforgood,
  deltapartners,
  der_brutkasten,
  digital_grenoble,
  digital_health_london,
  djakne,
  dncapital,
  downing,
  draperesprit,
  dublin_tech_summit,
  dutchstartups,
  e27,
  earlybird,
  eipp,
  elevator_ventures,
  eleven,
  email_hunter,
  entrepreneur_first,
  episode1,
  erasmus_centre,
  estonianmafia,
  eu_startups,
  eventmanagerblog,
  eventures,
  experior_venture_fund,
  f10,
  f6s,
  fasttrackmalmo,
  female_founders_grow_f,
  finsmes,
  fintastico,
  firstminute_capital,
  fomcap,
  forwardpartners,
  frontline_ventures,
  ft_1000,
  funderbeam,
  funding,
  get_latka,
  getinthering,
  github,
  github_trending,
  googlecse,
  gruenderszene,
  hamburg_startups,
  high_tech_gruenderfonds,
  highland_europe,
  hired,
  hiventures,
  hoxton_ventures,
  hv_capital,
  ibb_bet,
  imperialenterpriselab,
  inc_5000_eu,
  index_ventures,
  industrifonden,
  industryinvestment,
  initial_capital,
  inreach,
  inveready,
  investiere,
  iq_capital,
  isomer_capital,
  j_and_t_ventures,
  jaimelesstartups,
  jme_ventures,
  jobfluent,
  jobs_stationf,
  kaya,
  kfund,
  kima_ventures,
  labs_of_latvia,
  lafrenchtech_visas,
  landingfolio,
  lanzame,
  launched,
  launchingnext,
  launchub,
  les_pepites_tech,
  lhv,
  linkedin,
  linkedinweb,
  lisbon_challenge,
  lupa,
  lventure,
  mam_startup,
  manta_ray,
  maze_x,
  merian_ventures,
  mmc_ventures,
  monacotech,
  mountain_partners,
  munich_startup,
  national_business_awards_uk,
  nautacapital,
  neulogy,
  neveq,
  newfund,
  ngp_capital,
  no_fluff_jobs,
  nordictechlist,
  northzone,
  numa_hub,
  oktogon_vc,
  oltreventure,
  omnescapital,
  open_ocean,
  openfuture,
  opengraph,
  otb_ventures,
  oxford_capital,
  oxyleads,
  p101,
  parkwalk_advisors,
  partech,
  passion,
  pilabs,
  playfair,
  plug_and_play,
  portugalventures,
  postmake,
  predictleads,
  predictleads_post,
  presto_ventures,
  producthunt,
  project_a,
  rbvc,
  reaktorx,
  rockstart,
  romanianstartups,
  s_ubg_group,
  saas1000,
  samaipata,
  sap_io,
  schibsted_growth,
  seayaventures,
  seed_db,
  seedcamp,
  seedcaptial_dk,
  sensortower,
  seventure,
  signals_vc,
  smith_and_crown,
  smithandcrown,
  sosv,
  speedinvest,
  start_green,
  startbase,
  starterstory,
  startup88,
  startup_brett,
  startup_chile,
  startup_estonia,
  startup_hubs,
  startup_inspire,
  startup_lab,
  startup_navigator,
  startup_ole,
  startup_only,
  startup_poland,
  startup_tartu,
  startup_wiseguys,
  startup_xplore,
  startupbase,
  startupbenchmarks,
  startupberlin,
  startupblink,
  startupbuffer,
  startupbutton,
  startupcollections,
  startupexplore,
  startupjobs_cz,
  startuplift,
  startuplisboa,
  startupmatcher,
  startupnorway,
  startuppoland,
  startupranking,
  startuproulette,
  startups_be,
  startups_co_uk,
  startups_hu,
  startups_list,
  startupsbe,
  startupslist,
  startus,
  sting,
  streak,
  sup46,
  tech_ireland,
  tech_london,
  tech_pitch_4pt5,
  techlondon,
  technopark_liechtenstein,
  techstars_airtable,
  techstars_demoday_airtable,
  tera_vc,
  the_factory,
  the_fse_group,
  the_hub,
  the_startup_pitch,
  the_venture_city,
  thehub,
  token_data,
  tokendata,
  tomorrow_today,
  top100startup,
  twitter,
  unicorn_hunt,
  unicorns_lt,
  v2funding,
  vcee_startups,
  venturefriends,
  wayra_uk,
  web_summit,
  welcome_to_the_jungle,
  white_star_capital,
  workinstartups,
  xange,
  y_combinator,
  ycombinator
};

export default (source) => {
  const name = source.toLowerCase();
  return name === 'import' ? sources._import : sources[name];
};
