import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../../../Tooltip';

import IconMenu from 'material-ui/IconMenu';
import IconButton from 'material-ui/IconButton/IconButton';
import MenuItem from 'material-ui/MenuItem';
import Checkbox from 'material-ui/Checkbox';

import AddCircleOutline from 'material-ui/svg-icons/content/add-circle-outline';

import NaivgationRefresh from 'material-ui/svg-icons/navigation/refresh';

import timelineFilterPriorties from '../../../../content/timelineFilterPriorities.js';

export default class TimelineHeader extends React.Component {
  static propTypes = {
    eventTypes: PropTypes.object.isRequired,
    usedEventTypes: PropTypes.array.isRequired,
    stage: PropTypes.string.isRequired,
    selectedFilters: PropTypes.array.isRequired,
    filterClick: PropTypes.func.isRequired,
    mobileFiltersToggleClick: PropTypes.func.isRequired,
    openAddEventDialog: PropTypes.func.isRequired,
    openBackupMessageModal: PropTypes.func.isRequired,
    refreshTimeline: PropTypes.func.isRequired
  };

  addEventRef = React.createRef();
  refreshTimelineRef = React.createRef();

  buildFilters = () => {
    if (window.innerWidth >= 768) {
      const filters = this.props.usedEventTypes
        .reduce((result, usedEvent) => {
          if (this.props.eventTypes[usedEvent]) {
            result.push({
              eventType: usedEvent,
              display: this.props.eventTypes[usedEvent].filtersDisplay,
              selected: this.props.selectedFilters.includes(usedEvent)
            });
          }
          return result;
        }, [])
        .sort(
          (a, b) =>
            timelineFilterPriorties[a.eventType] -
            timelineFilterPriorties[b.eventType]
        );
      filters.unshift({
        eventType: 'all',
        display: 'All',
        selected: this.props.selectedFilters.indexOf('all') !== -1
      });
      return filters;
    } else {
      return [];
    }
  };

  render() {
    const filters = this.buildFilters();
    const {
      stage,
      selectedFilters,
      filterClick,
      mobileFiltersToggleClick,
      openAddEventDialog,
      openBackupMessageModal,
      refreshTimeline
    } = this.props;
    return (
      <div className="timeline-header-content">
        <div className="filter-by">
          <div className="filter-by-text">Filter by:</div>
          <div className="filters-wrapper">
            {filters.map((filter) => {
              return (
                <div
                  key={filter.display}
                  className={
                    filter.selected
                      ? 'timeline-filter selected'
                      : 'timeline-filter'
                  }
                  onClick={() => filterClick(filter.eventType)}
                >
                  {filter.display}
                </div>
              );
            })}
          </div>
        </div>
        <div className="spacer" />
        <div className="filter-toggle-mobile">
          <div
            className={
              selectedFilters[0] !== 'all'
                ? 'timeline-filter selected'
                : 'timeline-filter'
            }
            onClick={() => mobileFiltersToggleClick(selectedFilters)}
          >
            Filtered
          </div>
        </div>
        <If condition={stage === 'unable_to_contact'}>
          <div className="add-timeline-event">
            <div ref={this.addEventRef}>
              <IconMenu
                className="add-event-menu"
                iconButtonElement={
                  <IconButton
                    style={{
                      width: '34px',
                      height: '34px',
                      padding: '3px'
                    }}
                  >
                    <AddCircleOutline color="#5DAB49" />
                  </IconButton>
                }
                iconStyle={{
                  width: '28px',
                  height: '28px'
                }}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                targetOrigin={{horizontal: 'right', vertical: 'top'}}
              >
                {/*<MenuItem style={{color: '#7f7f7f'}} primaryText="Add note" onClick={() => openAddEventDialog('note')} />*/}
                <MenuItem
                  style={{color: '#7f7f7f'}}
                  primaryText="Send backup message"
                  onClick={openBackupMessageModal}
                />
              </IconMenu>
            </div>
            <Tooltip
              parent={this.addEventRef.current}
              text={'Add event'}
              showOnHover={true}
              position="bottom"
              customStyles={{
                top: '30px'
              }}
            />
          </div>
        </If>
        <div className="refresh-timeline" ref={this.refreshTimelineRef}>
          <NaivgationRefresh
            onClick={refreshTimeline}
            color="#5DAB49"
            style={{
              width: '28px',
              height: '28px'
            }}
          />
          <Tooltip
            parent={this.refreshTimelineRef.current}
            text={'Refresh timeline'}
            showOnHover={true}
            position="bottom"
            customStyles={{
              right: '0px',
              top: '30px'
            }}
          />
        </div>
      </div>
    );
  }
}
