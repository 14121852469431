import React from 'react';
import {Link, withRouter} from 'react-router';
import PropTypes from 'prop-types';
import Tooltip from '../../Tooltip';
import _ from 'underscore';

class Contacts extends React.Component {
  static propTypes = {
    companyInfo: PropTypes.object,
    portfolio: PropTypes.object,
    showMaxLength: PropTypes.number.isRequired,
    client: PropTypes.string.isRequired,
    updateSelectedTab: PropTypes.func.isRequired
  };

  state = {
    showTooltip: false,
    showFull: false,
    numberHiddenInfo: 0
  };

  toggleFullProfileClickHandler = () => {
    this.setState({
      showFull: !this.state.showFull
    });
  };

  hasError = () => {
    return true;
  };

  render() {
    const {
      tabsContent: {contacts = [1]} = {},
      contactsLoading = false,
      showMaxLength
    } = this.props;
    const {showTooltip, showFull, numberHiddenInfo} = this.state;
    return (
      <div
        className="data-wrapper founded"
        onMouseLeave={() => {
          if (this.hasError()) {
            this.setState({showTooltip: false});
          }
        }}
        onMouseEnter={() => {
          if (this.hasError()) {
            this.setState({showTooltip: true});
          }
        }}
        rel={(el) => (this._parent = el)}
      >
        <div className="data-header">Contacts</div>
        <Choose>
          <When condition={contactsLoading}>
            <div className="data-content text empty">Loading Data</div>
          </When>
          <When condition={!contacts.length}>
            <div className="data-content text empty">No data</div>
          </When>
          <Otherwise>
            <Tooltip
              parent={this._parent}
              showTooltip={showTooltip}
              text="Funding Rounds Counts"
            />
            <div className={'data-content text empty'}>{'Coming soon...'}</div>
          </Otherwise>
        </Choose>
        <div className="person-grid-item toggle-full-profile-grid-item" />
      </div>
    );
  }
}

export default withRouter(Contacts);
