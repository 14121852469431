import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import _ from 'underscore';

import BasicInfo from './BasicInfo';
import Email from './Email';
import Insights from '../../Insights';
import ToggleShowMoreButton from '../../ToggleShowMoreButton';
import KeyInformation from './KeyInformation';
import FullProfile from './FullProfile';
import ReducedProfile from './ReducedProfile';
import NewTag from '../../NewTag';

import personInsightReasons from '../../../../../content/personInsightReasons';

class Person extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    person: PropTypes.object.isRequired,
    source: PropTypes.string.isRequired,
    editPerson: PropTypes.func,
    useSnapshot: PropTypes.bool,
    previous: PropTypes.bool
  };

  state = {
    showFullProfile: false
  };

  componentDidMount() {
    if (this.shouldShowFullProfileByDefault()) {
      this.setState({
        showFullProfile: true
      });
    }
  }

  buildPerson = () => {
    const {
      organization: {primary_contact_id: primaryContactId = ''},
      person: {
        id,
        name = '',
        email = '',
        image_url: imageUrl = '',
        linkedin_url: linkedInUrl = '',
        url: websiteUrl,
        twitter_url: twitterUrl,
        angellist_url: angellistUrl,
        facebook_url: facebookUrl,
        github_url: githubUrl,
        role = '',
        title = '',
        bio = '',
        ranking: {
          rank = null,
          matched_highlights: highlights = [],
          matched_risks: risks = [],
          matched_red_flags: redFlags = []
        } = {},
        isHighlighted
      } = {}
    } = this.props;
    const keyInformation = this.getKeyInformation();
    const isPrimaryContact = id === primaryContactId;
    return {
      basicInfo: {
        isPrimaryContact,
        id,
        rank,
        name,
        imageUrl,
        linkedInUrl,
        websiteUrl,
        twitterUrl,
        angellistUrl,
        facebookUrl,
        githubUrl,
        role,
        title,
        isHighlighted
      },
      isPrimaryContact,
      email,
      highlights,
      risks,
      redFlags,
      bio,
      education: this.getEducation(),
      experience: this.getExperience(),
      keyInformation: keyInformation,
      isHighlighted,
      numberHiddenInfo: this.getNumberOfHiddenInfo(keyInformation)
    };
  };

  getEducation = () => {
    const {person: {education = []} = {}} = this.props;
    const highlightedEducation = this.getHighlightedEducationOrExperience(
      'education'
    );
    return education.map((educationItem) => {
      return (
        highlightedEducation.find((highlighted) =>
          _.isEqual(educationItem, _.omit(highlighted, 'reason'))
        ) || educationItem
      );
    });
  };

  getExperience = () => {
    const {person: {experience = []} = {}} = this.props;
    const highlightedExperience = this.getHighlightedEducationOrExperience(
      'experience'
    );
    return experience.map((experienceItem) => {
      return (
        highlightedExperience.find((highlighted) =>
          _.isEqual(experienceItem, _.omit(highlighted, 'reason'))
        ) || experienceItem
      );
    });
  };

  getHighlightedEducationOrExperience = (type) => {
    const {
      person: {ranking: {matched_highlights: matchedHighlights = []} = {}} = {}
    } = this.props;
    if (matchedHighlights.length === 0) {
      return [];
    }
    return matchedHighlights
      .filter(({type: highlightType}) => highlightType === type)
      .reduce((newHighlights, highlight) => {
        const reason = personInsightReasons[highlight.insight_id];
        highlight.key_information.forEach((info) => {
          info.reason = reason;
          newHighlights.push(info);
        });
        return newHighlights;
      }, []);
  };

  getKeyInformation = () => {
    const {
      person: {ranking: {matched_highlights: highlights = []} = {}} = {}
    } = this.props;
    return highlights.reduce(
      (newKeyInformation, {type, key_information: keyInformation = []}) => {
        if (!(type in newKeyInformation)) {
          newKeyInformation[type] = [];
        }
        keyInformation.forEach((information) => {
          newKeyInformation[type].push({
            ...information,
            highlighted: true
          });
        });
        return newKeyInformation;
      },
      {}
    );
  };

  getNumberOfHiddenInfo = (keyInformation) => {
    const {person = {}} = this.props;
    const {isHighlighted = false} = person;
    if (isHighlighted) {
      let hiddenNumber = 0;
      if (!('bio' in keyInformation)) {
        hiddenNumber++;
      }
      if (!('education' in keyInformation)) {
        hiddenNumber += (person.education || []).length;
      }
      if (!('experience' in keyInformation)) {
        hiddenNumber += (person.experience || []).length;
      }
      Object.entries(keyInformation).forEach(([type, content]) => {
        if (type !== 'bio') {
          hiddenNumber += (person[type] || []).length - (content || []).length;
        }
      });
      return hiddenNumber;
    } else {
      const {education = [], experience = []} = person;
      return (
        (education.length > 0 ? education.length - 1 : 0) +
        (experience.length > 1 ? experience.length - 2 : 0)
      );
    }
  };

  shouldShowFullProfileByDefault = () => {
    const {
      person: {bio = '', education = [], experience = [], isHighlighted} = {}
    } = this.props;
    return education.length <= 1 && experience.length <= 2 && !isHighlighted;
  };

  hasChanged = () => {
    const {person = {}, useSnapshot = false, previous = false} = this.props;
    const {snapshot: snapshotPerson = {}} = person;
    if (useSnapshot && !previous && _.isEmpty(snapshotPerson)) {
      return !_.isEqual(
        this.getPersonPropertiesUsed(person),
        this.getPersonPropertiesUsed(snapshotPerson)
      );
    } else {
      return false;
    }
  };

  getPersonPropertiesUsed = (person) =>
    _.pick(
      person,
      'bio',
      'education',
      'experience',
      'ranking',
      'role',
      'title'
    );

  getPersonProfileClassNames = () => {
    const {
      person: {isHighlighted},
      source
    } = this.props;
    const {showFullProfile} = this.state;
    const classNames = ['profile-person'];
    if (isHighlighted) {
      classNames.push('key-person');
    }
    if (showFullProfile) {
      classNames.push('full-profile');
    }
    if (source === 'new') {
      classNames.push('previous');
    }
    return classNames.join(' ');
  };

  toggleFullProfileClickHandler = () => {
    this.setState({
      showFullProfile: !this.state.showFullProfile
    });
  };

  render() {
    const {
      organization = {},
      person: {snapshot = {}} = {},
      editPerson = null
    } = this.props;
    const {showFullProfile} = this.state;
    const {
      basicInfo,
      isPrimaryContact,
      email,
      highlights,
      risks,
      redFlags,
      bio,
      education,
      experience,
      keyInformation,
      isHighlighted,
      numberHiddenInfo
    } = this.buildPerson();
    const showFullProfileByDefault = this.shouldShowFullProfileByDefault();
    const hasChanged = this.hasChanged();
    return (
      <div className={this.getPersonProfileClassNames()}>
        <If condition={hasChanged}>
          <NewTag
            source="person"
            snapshot={snapshot}
            organization={organization}
          />
        </If>
        <div className="person-section">
          <BasicInfo info={basicInfo} editPerson={editPerson} />
        </div>
        <If condition={isPrimaryContact}>
          <div className="person-section">
            <div className="primary-contact-text highlighted-text">
              <span>Primary Contact:</span>
              &nbsp;
              {email}
            </div>
          </div>
        </If>
        <div className="person-section">
          <Insights highlights={highlights} risks={risks} redFlags={redFlags} />
        </div>
        <div className="person-section">
          <Choose>
            <When condition={showFullProfile}>
              <div className="person-grid-item full-profile-grid-item">
                <FullProfile
                  bio={bio}
                  education={education}
                  experience={experience}
                  showFullProfileByDefault={showFullProfileByDefault}
                  numberHiddenInfo={numberHiddenInfo}
                  toggleFullProfileClickHandler={
                    this.toggleFullProfileClickHandler
                  }
                />
              </div>
            </When>
            <Otherwise>
              <Choose>
                <When condition={isHighlighted}>
                  <div className="person-grid-item key-information-grid-item">
                    <KeyInformation info={keyInformation} />
                  </div>
                </When>
                <Otherwise>
                  <div className="person-grid-item reduced-profile-grid-item">
                    <ReducedProfile
                      bio={bio}
                      education={education}
                      experience={experience}
                    />
                  </div>
                </Otherwise>
              </Choose>
            </Otherwise>
          </Choose>
        </div>
        <If condition={!showFullProfileByDefault}>
          <div className="person-grid-item toggle-full-profile-grid-item">
            <ToggleShowMoreButton
              text={
                showFullProfile
                  ? `Hide Full Profile (${numberHiddenInfo})`
                  : `Show Full Profile (${numberHiddenInfo})`
              }
              clickHandler={this.toggleFullProfileClickHandler}
              show={!showFullProfile}
            />
          </div>
        </If>
      </div>
    );
  }
}

export default withRouter(Person);
