import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import moment from 'moment';
import Tooltip from '../../Tooltip';
import Ago from '../../Ago';
import _ from 'underscore';
import PortfolioLink from './PortfolioLink';
import {
  getFirstInvestment,
  getLastInvestment,
  getInvestmentsLast12Months,
  getAllOrganizationsIds
} from '../InvestorHelper';

class InvestmentsInfo extends React.Component {
  static propTypes = {
    tabsContent: PropTypes.object.isRequired,
    updateSelectedTab: PropTypes.func.isRequired
  };
  state = {
    showTooltip: false
  };

  hasError = () => {
    return true;
  };

  getFoundedOnString = () => {
    const {founded: {on = 0} = {}} = this.props;
    return `${this.isFirstSeenCloseToFoundedOn(5) ? 'At least ' : ''}${moment(
      on
    ).fromNow()} (${new Date(on).getFullYear()})`;
  };

  isFirstSeenCloseToFoundedOn = (daysDiff) => {
    const {
      founded: {on = 0, firstSeen = 0}
    } = this.props;
    const negativeFoundedDate = new Date(
      new Date(on).setDate(new Date(on).getDate() - daysDiff)
    );
    const positiveFoundedDate = new Date(
      new Date(on).setDate(new Date(on).getDate() + daysDiff)
    );
    const firstSeenDate = new Date(firstSeen);
    return (
      firstSeenDate >= negativeFoundedDate &&
      firstSeenDate <= positiveFoundedDate
    );
  };

  getFoundedOnClasses = () => {
    const {founded: {on = 0, highlighted = false} = {}} = this.props;
    const classNames = ['data-content', 'text'];
    if (this.hasError()) {
      classNames.push('red-flag');
    } else if (highlighted) {
      classNames.push('highlighted-text');
    }
    return classNames.join(' ');
  };

  render() {
    const {
      tabsContent: {portfolioAggregation: portfolioAggregation = []} = {},
      portfolioAggregationLoading,
      updateSelectedTab
    } = this.props;
    const allOrganizationsIds = getAllOrganizationsIds(portfolioAggregation);
    const firstInvestment = getFirstInvestment(portfolioAggregation);
    const lastInvestment = getLastInvestment(portfolioAggregation);
    const last12MonthsInvestments = getInvestmentsLast12Months(
      portfolioAggregation
    );
    const {showTooltip} = this.state;
    return (
      <div
        className="data-wrapper founded"
        onMouseLeave={() => {
          if (this.hasError()) {
            this.setState({showTooltip: false});
          }
        }}
        onMouseEnter={() => {
          if (this.hasError()) {
            this.setState({showTooltip: true});
          }
        }}
        rel={(el) => (this._parent = el)}
      >
        <div className="data-header">Investments</div>
        <Choose>
          <When condition={portfolioAggregationLoading}>
            <div className="data-content text empty">Loading Data</div>
          </When>
          <When condition={!portfolioAggregation.length}>
            <div className="data-content text empty">No data</div>
          </When>
          <Otherwise>
            <Tooltip
              parent={this._parent}
              showTooltip={showTooltip}
              text="12 Months Investments => Funding Rounds"
            />
            <div className="invesor-profile-row">
              <div className="data-content text invesor-profile-div">
                Companies:
              </div>
              <div className="spacer" />
              <PortfolioLink
                text={allOrganizationsIds.length}
                filters={{
                  ids:
                    allOrganizationsIds.length > 200
                      ? [...new Set(allOrganizationsIds.slice(0, 200))]
                      : [...new Set(allOrganizationsIds)]
                }} //TODO fix slice 200 to limit
                updateSelectedTab={updateSelectedTab}
              />
            </div>
            <div className="invesor-profile-row">
              <div className="invesor-profile-div">First Investment:</div>
              <div className="spacer" />
              <Choose>
                <When condition={firstInvestment.timestamp}>
                  <PortfolioLink
                    text={
                      <React.Fragment>
                        <Ago timestamp={firstInvestment.timestamp} /> ago
                      </React.Fragment>
                    }
                    date={true}
                    filters={{ids: firstInvestment.organizationId}}
                    updateSelectedTab={updateSelectedTab}
                  />
                </When>
                <When condition={firstInvestment.timestamp}>
                  <div className={this.getFoundedOnClasses()}>
                    {this.getFoundedOnString()}
                  </div>
                </When>
                <Otherwise>
                  <div className="invesor-profile-div">Not Available</div>
                </Otherwise>
              </Choose>
            </div>
            <div className="invesor-profile-row">
              <div className="invesor-profile-div">Last Investment:</div>
              <div className="spacer" />
              <Choose>
                <When condition={lastInvestment.timestamp}>
                  <PortfolioLink
                    text={
                      <React.Fragment>
                        <Ago timestamp={lastInvestment.timestamp} /> ago
                      </React.Fragment>
                    }
                    date={true}
                    filters={{ids: lastInvestment.organizationId}}
                    updateSelectedTab={updateSelectedTab}
                  />
                </When>
                <Otherwise>
                  <div className="invesor-profile-div">Not Available</div>
                </Otherwise>
              </Choose>
            </div>
            <div className="invesor-profile-row">
              <div className="invesor-profile-div">Last 12m investments:</div>
              <div className="spacer" />
              <Choose>
                <When condition={last12MonthsInvestments.count}>
                  <PortfolioLink
                    text={last12MonthsInvestments.count}
                    filters={{
                      ids:
                        last12MonthsInvestments.count > 200
                          ? [
                              ...new Set(
                                last12MonthsInvestments.organizationIds.slice(
                                  0,
                                  200
                                )
                              )
                            ]
                          : [
                              ...new Set(
                                last12MonthsInvestments.organizationIds
                              )
                            ]
                    }}
                    updateSelectedTab={updateSelectedTab}
                  />
                </When>
                <Otherwise>
                  <div className="invesor-profile-div">0</div>
                </Otherwise>
              </Choose>
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(InvestmentsInfo);
