import Config from '../config';
import auth from './Auth';
import request from 'superagent';

export default class FundingRoundsStore {
  constructor(id) {
    this.url = `${Config.api.host}/organizations/${id}/funding_rounds`;
  }

  funding_rounds() {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(this.url)
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .set('Accept', 'application/json')
      )
      .then((response) => response.body.funding_rounds);
  }
}
