import React from 'react';
import PropTypes from 'prop-types';

import DatePicker from 'material-ui/DatePicker';

import {getDateAndTime} from '../../../lib/helpers';

export default class EditOrganizationFoundedOn extends React.Component {
  static propTypes = {
    founded: PropTypes.number.isRequired,
    createdAt: PropTypes.number.isRequired,
    updateOrganizationFields: PropTypes.func.isRequired,
    source: PropTypes.string
  };

  state = {
    founded: 0,
    error: false
  };

  componentWillMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.founded !== this.props.founded) {
      this.initialize();
    }
  }

  initialize = () => {
    const {founded = 0} = this.props;
    this.setState({
      founded,
      error: this.getFoundedError(founded)
    });
  };

  buildClassName = () => {
    const {error} = this.state;
    const className = ['edit-organization-founded'];
    if (error) className.push('error');
    return className.join(' ');
  };

  handleChange = (defaultParam, date) => {
    const founded = new Date(date).getTime();
    this.setState({
      founded,
      error: this.getFoundedError(founded)
    });
    this.props.updateOrganizationFields([
      {field: 'founded_on', value: founded}
    ]);
  };

  getFoundedError = (founded) => {
    const {createdAt = 0} = this.props;
    return (
      founded === 0 ||
      new Date(founded).toDateString() === new Date(createdAt).toDateString()
    );
  };

  render() {
    const {createdAt = 0, source = 'edit-incomplete'} = this.props;
    const {founded = 0} = this.state;
    return (
      <div className={this.buildClassName()}>
        <If condition={source === 'edit-incomplete'}>
          <div className="edit-section-header">FOUNDED ON</div>
        </If>
        <DatePicker
          className="edit-organization-founded-date-picker edit-organization-input-field medium"
          openToYearSelection={true}
          value={new Date(founded)}
          onChange={this.handleChange}
          autoOk={true}
          formatDate={(date) => getDateAndTime(date).date}
          textFieldStyle={{
            width: '250px'
          }}
        />
        <div className="edit-organization-founded-first-seen">
          First seen: {getDateAndTime(createdAt).date}
        </div>
      </div>
    );
  }
}
