import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import Card from '../../../card/Card';
import CardHeader from '../../../card/CardHeader';
import CardBody from '../../../card/CardBody';
import Loading from '../../../loading/Loading';
import OtherInfoCompany from './OtherInfoCompany';
import OtherInfoPeople from './OtherInfoPeople';
import OtherInfoProduct from './OtherInfoProduct';

import _ from 'underscore';

class OtherInfoSection extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired
  };

  render() {
    const {organization = {}} = this.props;
    return (
      <div className="other-info-section">
        <Card>
          <CardHeader title="Other Info" />
          <CardBody>
            <div className="grid-container other-info-grid-container">
              <div className="other-info-grid-item company-grid-item">
                <OtherInfoCompany organization={organization} />
              </div>
              <div className="other-info-grid-item border-1-grid-item" />
              <div className="other-info-grid-item people-grid-item">
                <OtherInfoPeople organization={organization} />
              </div>
              <div className="other-info-grid-item border-2-grid-item" />
              <div className="other-info-grid-item product-grid-item">
                <OtherInfoProduct organization={organization} />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withRouter(OtherInfoSection);
