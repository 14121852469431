import React from 'react';
import moment from 'moment';

import AppBar from 'material-ui/AppBar';
import Paper from 'material-ui/Paper';
import DatePicker from 'material-ui/DatePicker';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import Snackbar from 'material-ui/Snackbar';
import TextField from 'material-ui/TextField';
import {Toolbar, ToolbarGroup, ToolbarTitle} from 'material-ui/Toolbar';

import Loading from '../../loading/Loading';

import ImportStore from '../../../storage/ImportStore';
import locations from '../../../content/locations';

const {countries, continents} = locations;

const validations = {
  name: 'You must provide a name',
  homepage_url: 'Must be a URL beginning with http:// or https://',
  ceo_email: 'Must be an email address'
};

const defaultPayload = {
  name: '',
  homepage_url: '',
  image_url: '',
  description: '',
  short_description: '',
  founded_on: '',
  total_funding_usd: '',
  introduced_by: '',
  source: 'direct',
  notes: '',
  continent: 'Europe',
  country: '',
  city: '',
  ceo_name: '',
  ceo_email: ''
};

export default class CreateOrganization extends React.Component {
  state = {
    isSubmitting: false,
    submitError: '',
    errors: {},
    payload: {...defaultPayload}
  };

  importStore = new ImportStore();

  handleInvalid = (event) => {
    event.preventDefault();

    const {
      target: {name}
    } = event;

    this.setState(({errors}) => ({
      errors: {
        ...errors,
        [name]: validations[name]
      }
    }));
  };

  handleChange = (event, value) => {
    const {
      target: {name}
    } = event;

    this.setState(({payload, errors}) => ({
      payload: {
        ...payload,
        [name]: value
      },
      errors: {
        ...errors,
        [name]: null
      }
    }));
  };

  handleFoundedOnChange = (event, date) => {
    this.setState(({payload}) => ({
      payload: {
        ...payload,
        founded_on: moment(date)
          .format('YYYY-MM-DD')
          .toString()
      }
    }));
  };

  handleSelectChange = (name) => (event, index, value) => {
    this.setState(({payload, errors}) => ({
      payload: {
        ...payload,
        [name]: value
      },
      errors: {
        ...errors,
        [name]: null
      }
    }));
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({isSubmitting: true, submitError: ''}, () => {
      this.importStore
        .importEntry(this.state.payload)
        .then((entry) => {
          if (entry.organization_id) {
            return entry;
          }
          return this.importStore.waitForEntryToHaveOrganizationId(entry.id);
        })
        .then(({organization_id}) => {
          this.props.router.push(`/organizations/${organization_id}`);
        })
        .catch((error) => {
          console.error(error);

          this.setState({
            submitError: error.message,
            isSubmitting: false
          });
        });
    });
  };

  render() {
    const {errors, payload, isSubmitting, submitError} = this.state;

    return (
      <div className="page-width" id="organization-create">
        <AppBar
          className="appBar light"
          title="Create organization"
          showMenuIconButton={false}
        />

        <Snackbar
          open={!!submitError}
          message={submitError}
          autoHideDuration={2000}
        />

        <Choose>
          <When condition={isSubmitting}>
            <Loading fixed={true} />
          </When>

          <When condition={!isSubmitting}>
            <form
              autoComplete="off"
              onInvalid={this.handleInvalid}
              onSubmit={this.handleSubmit}
            >
              <Paper
                zDepth={1}
                style={{
                  marginTop: '8px',
                  marginBottom: '8px'
                }}
              >
                <Toolbar
                  style={{
                    backgroundColor: '#5DAB49'
                  }}
                >
                  <ToolbarGroup>
                    <ToolbarTitle
                      text="Organization"
                      style={{color: 'white'}}
                    />
                  </ToolbarGroup>
                </Toolbar>

                <div className="organization-create-fields">
                  <TextField
                    id="name"
                    name="name"
                    value={this.state.payload.name}
                    floatingLabelText="Name (required)"
                    floatingLabelFixed={true}
                    onChange={this.handleChange}
                    errorText={this.state.errors.name}
                    required={true}
                    fullWidth={true}
                    autoComplete="off"
                  />

                  <TextField
                    id="homepage_url"
                    name="homepage_url"
                    type="url"
                    value={this.state.payload.homepage_url}
                    floatingLabelText="Website URL"
                    floatingLabelFixed={true}
                    onChange={this.handleChange}
                    errorText={this.state.errors.homepage_url}
                    title="Website URL, starting with http:// or https://"
                    pattern="(http|https)://.+"
                    fullWidth={true}
                  />

                  <TextField
                    id="image_url"
                    name="image_url"
                    type="url"
                    value={this.state.payload.image_url}
                    floatingLabelText="Image URL"
                    floatingLabelFixed={true}
                    onChange={this.handleChange}
                    errorText={this.state.errors.image_url}
                    title="Image URL, starting with http:// or https://"
                    pattern="(http|https)://.+"
                    fullWidth={true}
                  />

                  <TextField
                    id="description"
                    name="description"
                    value={this.state.payload.description}
                    floatingLabelText="Description"
                    floatingLabelFixed={true}
                    onChange={this.handleChange}
                    errorText={this.state.errors.description}
                    fullWidth={true}
                    multiLine={true}
                    rows={5}
                  />

                  <TextField
                    id="short_description"
                    name="short_description"
                    value={this.state.payload.short_description}
                    floatingLabelText="Short Description"
                    floatingLabelFixed={true}
                    onChange={this.handleChange}
                    errorText={this.state.errors.short_description}
                    fullWidth={true}
                    multiLine={true}
                    rows={5}
                  />

                  <DatePicker
                    id="founded_on"
                    name="founded_on"
                    value={
                      this.state.payload.founded_on
                        ? moment(this.state.payload.founded_on).toDate()
                        : null
                    }
                    floatingLabelText="Founded on"
                    floatingLabelFixed={true}
                    onChange={this.handleFoundedOnChange}
                    fullWidth={true}
                    autoOk={true}
                    maxDate={new Date()}
                  />

                  <TextField
                    id="total_funding_usd"
                    name="total_funding_usd"
                    type="number"
                    value={this.state.payload.total_funding_usd}
                    floatingLabelText="Total Funding ($)"
                    floatingLabelFixed={true}
                    onChange={this.handleChange}
                    errorText={this.state.errors.short_description}
                    title="Total funding in USD"
                    fullWidth={true}
                    min={0}
                    step={1}
                  />

                  <TextField
                    id="introduced_by"
                    name="introduced_by"
                    value={this.state.payload.introduced_by}
                    floatingLabelText="Introduced By"
                    floatingLabelFixed={true}
                    onChange={this.handleChange}
                    errorText={this.state.errors.introduced_by}
                    fullWidth={true}
                  />

                  <TextField
                    id="source"
                    name="source"
                    value={this.state.payload.source}
                    floatingLabelText="Source"
                    floatingLabelFixed={true}
                    onChange={this.handleChange}
                    errorText={this.state.errors.source}
                    fullWidth={true}
                  />

                  <TextField
                    id="notes"
                    name="notes"
                    value={this.state.payload.notes}
                    floatingLabelText="Notes"
                    floatingLabelFixed={true}
                    onChange={this.handleChange}
                    errorText={this.state.errors.notes}
                    fullWidth={true}
                    multiLine={true}
                    rows={5}
                  />
                </div>
              </Paper>

              <Paper
                zDepth={1}
                style={{
                  marginTop: '8px',
                  marginBottom: '8px'
                }}
              >
                <Toolbar
                  style={{
                    backgroundColor: '#5DAB49'
                  }}
                >
                  <ToolbarGroup>
                    <ToolbarTitle text="Location" style={{color: 'white'}} />
                  </ToolbarGroup>
                </Toolbar>

                <div className="organization-create-fields">
                  <SelectField
                    id="continent"
                    name="continent"
                    value={this.state.payload.continent}
                    floatingLabelText="Continent"
                    floatingLabelFixed={true}
                    onChange={this.handleSelectChange('continent')}
                    fullWidth={true}
                  >
                    <MenuItem value={''} primaryText="Any continent" />
                    {continents.map((continent) => (
                      <MenuItem
                        key={continent}
                        value={continent}
                        primaryText={continent}
                      />
                    ))}
                  </SelectField>

                  <If condition={!!this.state.payload.continent}>
                    <SelectField
                      id="country"
                      name="country"
                      value={this.state.payload.country}
                      floatingLabelText="Country"
                      floatingLabelFixed={true}
                      onChange={this.handleSelectChange('country')}
                      fullWidth={true}
                    >
                      <MenuItem value={''} primaryText="Any country" />
                      {countries[this.state.payload.continent].map(
                        (country) => (
                          <MenuItem
                            key={country}
                            value={country}
                            primaryText={country}
                          />
                        )
                      )}
                    </SelectField>
                  </If>

                  <If condition={!!this.state.payload.country}>
                    <TextField
                      id="city"
                      name="city"
                      value={this.state.payload.city}
                      floatingLabelText="City"
                      floatingLabelFixed={true}
                      onChange={this.handleChange}
                      errorText={this.state.errors.city}
                      fullWidth={true}
                    />
                  </If>
                </div>
              </Paper>

              <Paper
                zDepth={1}
                style={{
                  marginTop: '8px',
                  marginBottom: '8px'
                }}
              >
                <Toolbar
                  style={{
                    backgroundColor: '#5DAB49'
                  }}
                >
                  <ToolbarGroup>
                    <ToolbarTitle text="CEO" style={{color: 'white'}} />
                  </ToolbarGroup>
                </Toolbar>

                <div className="organization-create-fields">
                  <TextField
                    id="ceo_name"
                    name="ceo_name"
                    value={this.state.payload.ceo_name}
                    floatingLabelText="CEO name"
                    floatingLabelFixed={true}
                    onChange={this.handleChange}
                    errorText={this.state.errors.ceo_name}
                    fullWidth={true}
                  />

                  <TextField
                    id="ceo_email"
                    name="ceo_email"
                    type="email"
                    value={this.state.payload.ceo_email}
                    floatingLabelText="CEO Email"
                    floatingLabelFixed={true}
                    onChange={this.handleChange}
                    errorText={this.state.errors.ceo_email}
                    fullWidth={true}
                  />
                </div>
              </Paper>

              <div className="organization-create-submit">
                <RaisedButton type="submit" label="Create" primary={true} />
              </div>
            </form>
          </When>
        </Choose>
      </div>
    );
  }
}
