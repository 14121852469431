import PropTypes from 'prop-types';
import React from 'react';
import FilterSummaryTag from './FilterSummaryTag';
import DIG_STATES from '../../content/digStates';
import STREAK_STAGES from '../../config/streak_stages';
import insights from '../../content/insights';
import investmentTypesMap from '../../content/investmentTypes';
import investmentStagesMap from '../../content/investmentStages';

import INREACH_TEAM from '../../config/inreach_team';

import moment from 'moment';
import _ from 'underscore';
import investorScores from '../../content/investorScores';
import {titleCase} from '../../lib/helpers';
import investorsCategories from '../../content/investorsCategories';

class FilterSummary extends React.Component {
  capitalize(str) {
    return str.replace(/(?:^|\s)\S/g, function(word) {
      return word.toUpperCase();
    });
  }

  basedIn() {
    const params = this.props.params;
    const text = [];
    if (params.based_in_city) {
      text.push(params.based_in_city);
    }
    if (params.based_in_country) {
      text.push(params.based_in_country);
    }
    if (params.based_in_continent) {
      text.push(this.capitalize(params.based_in_continent));
    }
    return text;
  }

  joinArray(array) {
    let result = '';
    for (let i = 0; i < array.length; i++) {
      if (i === 0) {
        result = array[i];
      } else if (i === array.length - 1) {
        result += ' and ' + array[i];
      } else {
        result += ', ' + array[i];
      }
    }
    return result;
  }

  deleteParams(paramsToDelete) {
    let params = _.clone(this.props.params);
    paramsToDelete.map((param) => {
      params = _.omit(params, param);
    });
    this.props.onChange(params);
  }

  render() {
    let params = this.props.params,
      basedIn = this.basedIn();

    const renderOrderText = (order) => {
        return order === 'asc' ? 'ascending' : 'descending';
      },
      renderOrderByText = (orderBy) => {
        switch (orderBy) {
          case 'predictions.never':
            return 'Never %';
          case 'predictions.missed_opportunity':
            return 'Missed Opportunity %';
          case 'predictions.not_now':
            return 'Not Now %';
          case 'predictions.contact':
            return 'In Progress %';
          case 'primary_trend.magnitude':
            return 'Org. Trend Size %';
          case 'primary_trend.beta':
            return 'Org. Trend Traction %';
          case 'ml_features.founded_on_smoothed_manual.score':
            return 'Founded On Score %';
          case 'ml_features.total_funding_smoothed_manual.score':
            return 'Total Funding Score %';
          case 'ml_features.highlights_manual.score':
            return 'Highlights Score %';
          case 'aggregated_followon_percentage':
            return 'Investor Score';
          default:
            const orderBySplit = orderBy.split('.'),
              text = orderBySplit[orderBySplit.length - 1].split('_').join(' ');
            return text;
        }
        return `'${orderBy}'`;
      },
      renderFoundedOnText = (after, before) => {
        if (after) {
          if (before) {
            return `founded ${moment(after, 'x').fromNow(true)} - ${moment(
              before,
              'x'
            ).fromNow()}`;
          } else {
            return `founded after ${moment(after, 'x').fromNow()}`;
          }
        } else if (before) {
          return `founded before ${moment(before, 'x').fromNow()}`;
        }
      },
      renderInvestmentText = (greaterThan, lessThan) => {
        if (greaterThan) {
          if (lessThan) {
            return `investment $${greaterThan} - $${lessThan}`;
          } else {
            return `investment greater than $${greaterThan}`;
          }
        } else if (lessThan) {
          return `investment less than $${lessThan}`;
        }
      },
      renderUserDecisionRangeText = (after, before) => {
        if (after) {
          if (before) {
            return `evaluated from ${moment(after, 'x').format(
              'DD/MM/YY'
            )} to ${moment(before, 'x').format('DD/MM/YY')}`;
          } else {
            return `evaluated from ${moment(after, 'x').format('DD/MM/YY')}`;
          }
        } else if (before) {
          return `evaluated to ${moment(before, 'x').format('DD/MM/YY')}`;
        }
      },
      renderCreatedAtText = (after, before) => {
        if (after) {
          if (before) {
            return `first seen ${moment(after, 'x').fromNow(true)} - ${moment(
              before,
              'x'
            ).fromNow()}`;
          } else {
            return `first seen after ${moment(after, 'x').fromNow()}`;
          }
        } else if (before) {
          return `first seen before ${moment(before, 'x').fromNow()}`;
        }
      },
      renderLastInvestmentText = (greaterThan, lessThan) => {
        if (greaterThan) {
          if (lessThan) {
            return `Last between ${greaterThan} - ${lessThan}`;
          } else {
            return `Last After ${greaterThan}`;
          }
        } else if (lessThan) {
          return `Last Before ${lessThan}`;
        }
      },
      renderFirstInvestmentText = (greaterThan, lessThan) => {
        if (greaterThan) {
          if (lessThan) {
            return `First between ${greaterThan} - ${lessThan}`;
          } else {
            return `First After ${greaterThan}`;
          }
        } else if (lessThan) {
          return `First Before ${lessThan}`;
        }
      },
      renderExitsText = (greaterThan, lessThan) => {
        if (greaterThan) {
          if (lessThan) {
            return `#Exits ${greaterThan} - ${lessThan}`;
          } else {
            return `#Exits Over ${greaterThan}`;
          }
        } else if (lessThan) {
          return `#Exits under ${lessThan}`;
        }
      },
      renderNumberInvestmentsText = (greaterThan, lessThan) => {
        if (greaterThan) {
          if (lessThan) {
            return `#Investments ${greaterThan} - ${lessThan}`;
          } else {
            return `#Investments Over ${greaterThan}`;
          }
        } else if (lessThan) {
          return `#Investments under ${lessThan}`;
        }
      },
      renderPercentRangeText = (type, greaterThan, lessThan) => {
        if (greaterThan) {
          if (lessThan) {
            return `${type} ${parseFloat(greaterThan) * 100}% - ${parseFloat(
              lessThan
            ) * 100}%`;
          } else {
            return `${type} greater than ${parseFloat(greaterThan) * 100}%`;
          }
        } else if (lessThan) {
          return `${type} less than ${parseFloat(lessThan) * 100}%`;
        }
      },
      renderInvestorScore = (lowerThan, greaterThan) => {
        const investorScoreLowerCategory = Object.values(investorScores).filter(
          (score) => score.max === parseFloat(lowerThan)
        );
        const investorScoreHigherCategory = Object.values(
          investorScores
        ).filter((score) => score.min === parseFloat(greaterThan));
        const investorHigherScore = investorScoreHigherCategory.length
          ? investorScoreHigherCategory[0]
          : null;
        const investorLowerScore = investorScoreLowerCategory.length
          ? investorScoreLowerCategory[0]
          : null;
        if (
          investorLowerScore ||
          investorLowerScore ||
          lowerThan ||
          greaterThan
        ) {
          const lower = investorLowerScore
            ? ' <= ' + investorLowerScore.name
            : lowerThan
              ? ' <= ' + parseFloat(lowerThan)
              : '';
          const higher = investorHigherScore
            ? investorHigherScore.name + ' <= '
            : greaterThan
              ? parseFloat(greaterThan) + ' <= '
              : '';

          return `${higher}Score${lower}`;
        }
      },
      renderHighlightsText = (greaterThan, lessThan) => {
        if (greaterThan && lessThan) {
          return `Highlights ${greaterThan} - ${lessThan}`;
        }

        if (greaterThan) {
          return `Highlights >= ${greaterThan}`;
        }

        if (lessThan) {
          return `Highlights <= ${lessThan}`;
        }
      };

    const showHideSpam =
      (params.classification_not || '').indexOf('never') > -1;

    return (
      <div>
        <div
          className={`filter-summary ${this.props.vertical ? 'vertical' : ''}`}
        >
          <If condition={this.props.orgsNumber}>
            <FilterSummaryTag
              text={this.props.orgsNumber}
              type="number"
              noDelete
            />
          </If>
          <If condition={this.props.params.ids}>
            <FilterSummaryTag
              handleDelete={() => this.deleteParams(['ids'])}
              text={this.props.params.ids}
              type="organization"
              noDelete={this.props.disableEditing}
            />
          </If>
          <If condition={basedIn.length}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="location"
              text={basedIn.join(', ')}
              handleDelete={() => {
                this.deleteParams(['based_in_continent', 'based_in_country']);
              }}
            />
          </If>
          <If condition={params.investor}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="investor"
              text={params.investor}
              handleDelete={() => {
                this.deleteParams(['investor']);
              }}
            />
          </If>

          <If condition={params.name}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="name"
              text={params.name}
              handleDelete={() => {
                this.deleteParams(['name']);
              }}
            />
          </If>

          <If condition={params.terms}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="terms"
              text={params.terms.split(',').join(', ')}
              handleDelete={() => {
                this.deleteParams(['terms']);
              }}
            />
          </If>

          <If condition={params.query}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="query"
              text={params.query}
              handleDelete={() => {
                this.deleteParams(['query']);
              }}
            />
          </If>

          <If condition={params.state}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="state"
              text={DIG_STATES[params.state]}
              handleDelete={() => {
                this.deleteParams(['state']);
              }}
            />
          </If>

          <If condition={params.user_state}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="state"
              text={`transitional: ${DIG_STATES[params.user_state]}`}
              handleDelete={() => {
                this.deleteParams([
                  'user_state',
                  'by_user',
                  'has_user_decisions'
                ]);
              }}
            />
          </If>

          <If condition={params.has_user_decisions === 'false'}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="state"
              text={`transitional: unseen`}
              handleDelete={() => {
                this.deleteParams([
                  'user_state',
                  'by_user',
                  'has_user_decisions'
                ]);
              }}
            />
          </If>
          <If condition={params.stage}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="stage"
              text={
                STREAK_STAGES.filter((stage) => stage.key === params.stage).map(
                  (stage) => stage.name
                ) || params.stage
              }
              handleDelete={() => {
                this.deleteParams(['stage']);
              }}
            />
          </If>

          <If condition={params.order_by}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="order_by"
              text={`${renderOrderByText(params.order_by)} ${renderOrderText(
                params.order
              )} `}
              handleDelete={() => {
                this.deleteParams(['order_by']);
              }}
            />
          </If>

          <If condition={params.founded_on_after || params.founded_on_before}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="calendar"
              text={renderFoundedOnText(
                params.founded_on_after,
                params.founded_on_before
              )}
              handleDelete={() => {
                this.deleteParams(['founded_on_after', 'founded_on_before']);
              }}
            />
          </If>

          <If
            condition={
              params.last_funding_round_announced_on_lt ||
              params.last_funding_round_announced_on_gt
            }
          >
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="calendar"
              text={renderLastInvestmentText(
                params.last_funding_round_announced_on_gt,
                params.last_funding_round_announced_on_lt
              )}
              handleDelete={() => {
                this.deleteParams([
                  'last_funding_round_announced_on_gt',
                  'last_funding_round_announced_on_lt'
                ]);
              }}
            />
          </If>

          <If
            condition={
              params.first_funding_round_announced_on_lt ||
              params.first_funding_round_announced_on_gt
            }
          >
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="calendar"
              text={renderFirstInvestmentText(
                params.first_funding_round_announced_on_gt,
                params.first_funding_round_announced_on_lt
              )}
              handleDelete={() => {
                this.deleteParams([
                  'first_funding_round_announced_on_gt',
                  'first_funding_round_announced_on_lt'
                ]);
              }}
            />
          </If>

          <If
            condition={params.organization_ids_lt || params.organization_ids_gt}
          >
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="investor"
              text={renderNumberInvestmentsText(
                params.organization_ids_gt,
                params.organization_ids_lt
              )}
              handleDelete={() => {
                this.deleteParams([
                  'organization_ids_gt',
                  'organization_ids_lt'
                ]);
              }}
            />
          </If>

          <If condition={params.exits_lt || params.exits_gt}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="investor"
              text={renderExitsText(params.exits_gt, params.exits_lt)}
              handleDelete={() => {
                this.deleteParams(['exits_gt', 'exits_lt']);
              }}
            />
          </If>

          <If
            condition={
              params.user_decision_made_gt || params.user_decision_made_lt
            }
          >
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="calendar"
              text={renderUserDecisionRangeText(
                params.user_decision_made_gt,
                params.user_decision_made_lt
              )}
              handleDelete={() => {
                this.deleteParams([
                  'user_decision_made_gt',
                  'user_decision_made_lt'
                ]);
              }}
            />
          </If>

          <If condition={params.created_at_after || params.created_at_before}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="calendar"
              text={renderCreatedAtText(
                params.created_at_after,
                params.created_at_before
              )}
              handleDelete={() => {
                this.deleteParams(['created_at_after', 'created_at_before']);
              }}
            />
          </If>

          <If condition={params.investment_gt || params.investment_lt}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="money"
              text={renderInvestmentText(
                params.investment_gt,
                params.investment_lt
              )}
              handleDelete={() => {
                this.deleteParams(['investment_gt', 'investment_lt']);
              }}
            />
          </If>

          <If
            condition={params.never_prediction_gt || params.never_prediction_lt}
          >
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="cogs"
              text={renderPercentRangeText(
                'Prob. Never',
                params.never_prediction_gt,
                params.never_prediction_lt
              )}
              handleDelete={() => {
                this.deleteParams([
                  'never_prediction_gt',
                  'never_prediction_lt'
                ]);
              }}
            />
          </If>

          <If
            condition={
              params.not_now_prediction_gt || params.not_now_prediction_lt
            }
          >
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="cogs"
              text={renderPercentRangeText(
                'Prob. Not Now',
                params.not_now_prediction_gt,
                params.not_now_prediction_lt
              )}
              handleDelete={() => {
                this.deleteParams([
                  'not_now_prediction_gt',
                  'not_now_prediction_lt'
                ]);
              }}
            />
          </If>

          <If
            condition={
              params.contact_prediction_gt || params.contact_prediction_lt
            }
          >
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="cogs"
              text={renderPercentRangeText(
                'Prob. In Progress',
                params.contact_prediction_gt,
                params.contact_prediction_lt
              )}
              handleDelete={() => {
                this.deleteParams([
                  'contact_prediction_gt',
                  'contact_prediction_lt'
                ]);
              }}
            />
          </If>

          <If condition={params.source}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="source"
              text={params.source}
              handleDelete={() => {
                this.deleteParams(['source']);
              }}
            />
          </If>

          <If condition={params.tag}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="tag"
              text={params.tag}
              handleDelete={() => {
                this.deleteParams(['tag']);
              }}
            />
          </If>

          <If condition={params.most_invested_tags}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="tag"
              text={params.most_invested_tags}
              handleDelete={() => {
                this.deleteParams(['most_invested_tags']);
              }}
            />
          </If>

          <If condition={params.investor_ids}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="number"
              text={'Investor id:' + params.investor_ids}
              handleDelete={() => {
                this.deleteParams(['investor_ids']);
              }}
            />
          </If>

          <If condition={params.event}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="event"
              text={params.event}
              handleDelete={() => {
                this.deleteParams(['event']);
              }}
            />
          </If>

          <If condition={params.show_predictions}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="cogs"
              text="Show predictions"
              handleDelete={() => {
                this.deleteParams(['show_predictions']);
              }}
            />
          </If>

          <If condition={params.top_tier}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="investor"
              text="Top tier"
              handleDelete={() => {
                this.deleteParams(['top_tier']);
              }}
            />
          </If>

          <If condition={params.is_good_investor}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="investor"
              text="Good investors"
              handleDelete={() => {
                this.deleteParams(['is_good_investor']);
              }}
            />
          </If>

          <If condition={params.show_classifications}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="cogs"
              text="Show classifications"
              handleDelete={() => {
                this.deleteParams(['show_classifications']);
              }}
            />
          </If>

          <If condition={params.inbox}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="cogs"
              text="Show inbox"
              handleDelete={() => {
                this.deleteParams(['inbox']);
              }}
            />
          </If>

          <If condition={showHideSpam}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="hidespam"
              text="Hide Spam"
              handleDelete={() => {
                this.deleteParams(['classification_not']);
              }}
            />
          </If>

          <If condition={params.classification}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="classification"
              text={DIG_STATES[params.classification]}
              handleDelete={() => {
                this.deleteParams(['classification']);
              }}
            />
          </If>

          <If condition={params.requires_more_info === 'true'}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="cogs"
              text="Requires More Info"
              handleDelete={() => {
                this.deleteParams(['requires_more_info']);
              }}
            />
          </If>

          <If condition={params.is_complete === 'false'}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="cogs"
              text="Show Incomplete"
              handleDelete={() => {
                this.deleteParams(['is_complete']);
              }}
            />
          </If>
          <If condition={params.most_invested_investment_type}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="investment-type"
              text={params.most_invested_investment_type
                .split(',')
                .map(
                  (investmentType) =>
                    investmentTypesMap[investmentType]
                      ? investmentTypesMap[investmentType].name
                      : ''
                )
                .join(', ')}
              handleDelete={() => {
                this.deleteParams(['most_invested_investment_type']);
              }}
            />
          </If>

          <If condition={params.investor_type}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="investment-type"
              text={params.investor_type
                .split(',')
                .map(
                  (investorType) =>
                    investorsCategories[investorType]
                      ? investorsCategories[investorType].name
                      : ''
                )
                .join(', ')}
              handleDelete={() => {
                this.deleteParams(['investor_type']);
              }}
            />
          </If>
          <If condition={params.ml_investor_type}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="investment-type"
              text={params.ml_investor_type
                .split(',')
                .map(
                  (investorMLType) =>
                    investorsCategories[investorMLType]
                      ? investorsCategories[investorMLType].name
                      : ''
                )
                .join(', ')}
              handleDelete={() => {
                this.deleteParams(['ml_investor_type']);
              }}
            />
          </If>

          <If condition={params.investment_types}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="investment-type"
              text={params.investment_types
                .split(',')
                .map(
                  (investmentType) =>
                    investmentTypesMap[investmentType]
                      ? investmentTypesMap[investmentType].name
                      : ''
                )
                .join(', ')}
              handleDelete={() => {
                this.deleteParams(['investment_types']);
              }}
            />
          </If>

          <If condition={params.investment_stages}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="investment-type"
              text={params.investment_stages
                .split(',')
                .map(
                  (investmentStage) =>
                    investmentStagesMap[investmentStage]
                      ? investmentStagesMap[investmentStage].name
                      : ''
                )
                .join(', ')}
              handleDelete={() => {
                this.deleteParams(['investment_stages']);
              }}
            />
          </If>

          <If condition={params.highlights}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="highlights"
              text={params.highlights
                .split(',')
                .map(
                  (highlight) =>
                    insights[highlight] ? insights[highlight].name : ''
                )
                .join(', ')}
              handleDelete={() => {
                this.deleteParams(['highlights']);
              }}
            />
          </If>

          <If
            condition={
              params.ml_features_score_gt || params.ml_features_score_lt
            }
          >
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="fire"
              text={renderPercentRangeText(
                'ML Score',
                params.ml_features_score_gt,
                params.ml_features_score_lt
              )}
              handleDelete={() => {
                this.deleteParams([
                  'ml_features_score_gt',
                  'ml_features_score_lt'
                ]);
              }}
            />
          </If>

          <If condition={params.highlights_gt || params.highlights_lt}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="highlights"
              text={renderHighlightsText(
                params.highlights_gt,
                params.highlights_lt
              )}
              handleDelete={() => {
                this.deleteParams(['highlights_gt', 'highlights_lt']);
              }}
            />
          </If>

          <If
            condition={params.highlights_score_gt || params.highlights_score_lt}
          >
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="cogs"
              text={renderPercentRangeText(
                'Highlights Score',
                params.highlights_score_gt,
                params.highlights_score_lt
              )}
              handleDelete={() => {
                this.deleteParams([
                  'highlights_score_gt',
                  'highlights_score_lt'
                ]);
              }}
            />
          </If>

          <If
            condition={params.founded_on_score_gt || params.founded_on_score_lt}
          >
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="cogs"
              text={renderPercentRangeText(
                'Founded On Score',
                params.founded_on_score_gt,
                params.founded_on_score_lt
              )}
              handleDelete={() => {
                this.deleteParams([
                  'founded_on_score_gt',
                  'founded_on_score_lt'
                ]);
              }}
            />
          </If>

          <If
            condition={params.investment_score_gt || params.investment_score_lt}
          >
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="cogs"
              text={renderPercentRangeText(
                'Investment Score',
                params.investment_score_gt,
                params.investment_score_lt
              )}
              handleDelete={() => {
                this.deleteParams([
                  'investment_score_gt',
                  'investment_score_lt'
                ]);
              }}
            />
          </If>

          <If
            condition={
              params.trend_organization_magnitude_gt ||
              params.trend_organization_magnitude_lt
            }
          >
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="trends-percentile"
              text={renderPercentRangeText(
                'Org. Trend Size',
                params.trend_organization_magnitude_gt,
                params.trend_organization_magnitude_lt
              )}
              handleDelete={() => {
                this.deleteParams([
                  'trend_organization_magnitude_gt',
                  'trend_organization_magnitude_lt'
                ]);
              }}
            />
          </If>

          <If
            condition={
              params.ml_investor_score_gt || params.ml_investor_score_lt
            }
          >
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="trends-percentile"
              text={renderInvestorScore(
                params.ml_investor_score_lt,
                params.ml_investor_score_gt
              )}
              handleDelete={() => {
                this.deleteParams([
                  'ml_investor_score_gt',
                  'ml_investor_score_lt'
                ]);
              }}
            />
          </If>

          <If
            condition={
              params.trend_organization_beta_gt ||
              params.trend_organization_beta_lt
            }
          >
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="trends-percentile"
              text={renderPercentRangeText(
                'Org. Trend Traction',
                params.trend_organization_beta_gt,
                params.trend_organization_beta_lt
              )}
              handleDelete={() => {
                this.deleteParams([
                  'trend_organization_beta_gt',
                  'trend_organization_beta_lt'
                ]);
              }}
            />
          </If>

          <If condition={params.assigned_to}>
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="person"
              text={
                'assigned to ' +
                  INREACH_TEAM.filter(
                    (person) => person.email === params.assigned_to
                  ).map((person) => person.name) || params.assigned_to
              }
              handleDelete={() => {
                this.deleteParams(['assigned_to']);
              }}
            />
          </If>
        </div>

        <If condition={this.props.selectedFiltersSet}>
          <div className="filters-sets-summary">
            <FilterSummaryTag
              noDelete={this.props.disableEditing}
              type="filterset"
              text={this.props.selectedFiltersSet}
              handleDelete={this.props.handleDeselectFiltersSet}
            />
          </div>
        </If>
      </div>
    );
  }
}

FilterSummary.propTypes = {
  params: PropTypes.object.isRequired
};

export default FilterSummary;
