import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import team from '../../../config/inreach_team';

import Tooltip from '../../Tooltip';

export default class PreviewOrganizationInboxStatus extends React.Component {
  static propTypes = {data: PropTypes.object.isRequired};

  state = {showTooltip: false};

  getStatusClassNames = () => {
    const {data: {activeUserInbox: {reason} = {}} = {}} = this.props;
    const classNames = ['preview-organization--header-inbox-status'];
    switch (reason) {
      case 'RECONNECT':
        classNames.push('reconnect');
        break;
      case 'REVIEW_FORM':
        classNames.push('review');
        break;
      case 'IS_THIS_INTERESTING':
        classNames.push('interesting');
        break;
      case 'DIG_ML_UPDATED':
        classNames.push('ml-updated');
    }
    return classNames.join(' ');
  };

  getNotesString = () => {
    const {
      data: {activeUserInbox: {notes = '', reason} = {}} = {}
    } = this.props;
    switch (reason) {
      case 'RECONNECT':
        return notes.substring(0, notes.indexOf(')') + 1);
      default:
        return notes;
    }
  };

  render() {
    const {
      data: {
        activeUserInbox: {notes, user_id, reason, created_at} = {},
        form: {hasForm = false, inbound = false} = {}
      } = {}
    } = this.props;
    const {showTooltip = false} = this.state;
    const useTooltip =
      (reason === 'REVIEW_FORM' ||
        reason === 'RECONNECT' ||
        reason === 'IS_THIS_INTERESTING' ||
        reason === 'DIG_ML_UPDATED') &&
      notes;
    return (
      <div
        className={this.getStatusClassNames()}
        onMouseLeave={() => {
          if (useTooltip) {
            this.setState({showTooltip: false});
          }
        }}
        onMouseEnter={() => {
          if (useTooltip) {
            this.setState({showTooltip: true});
          }
        }}
        ref={(el) => (this._parent = el)}
      >
        <If condition={reason === 'FORM' || reason === 'FORM_PARTIAL'}>
          <div className="preview-organization--header-inbox-status-form">
            <Choose>
              <When condition={inbound}>Inbound</When>
              <Otherwise>Outbound</Otherwise>
            </Choose>
            &nbsp;-&nbsp;
            <Choose>
              <When condition={reason === 'FORM_PARTIAL'}>Partial Form</When>
              <Otherwise>Complete Form</Otherwise>
            </Choose>
            <If condition={user_id}>&nbsp;-&nbsp;</If>
          </div>
        </If>
        <If condition={user_id}>
          <div className="preview-organization--header-inbox-status-user">
            {(team.find(({email}) => email === user_id) || {}).name}
            <If condition={notes}>:&nbsp;</If>
          </div>
        </If>
        <If condition={notes}>
          <div className="preview-organization--header-inbox-status-note">
            {this.getNotesString()}
          </div>
          <If condition={useTooltip}>
            <Tooltip
              parent={this._parent}
              showTooltip={showTooltip}
              text={notes}
            />
          </If>
        </If>
        <div className="preview-organization--header-inbox-status-added">
          &nbsp;(
          {moment(created_at).fromNow()})
        </div>
      </div>
    );
  }
}
