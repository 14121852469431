import React from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router';

import ImageNavigateNext from 'material-ui/svg-icons/image/navigate-next';
import ImageNavigateBefore from 'material-ui/svg-icons/image/navigate-before';
import Tooltip from '../Tooltip';

class OrganizationsNavigator extends React.Component {
  static propTypes = {
    organizationsNavigatorFetcher: PropTypes.object.isRequired,
    currentId: PropTypes.string.isRequired
  };

  state = {
    next: {},
    previous: {},
    nextArrowContainer: null,
    prevArrowContainer: null,
    showPrevComapny: false,
    showNextCompany: false
  };

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentId !== this.props.currentId) {
      this.initialize();
    }
  }

  initialize = () => {
    const {currentId = '', organizationsNavigatorFetcher} = this.props;
    this.setState(organizationsNavigatorFetcher.getNextAndPrevious(currentId));
  };

  getUrl = (id) => {
    const {
      location: {pathname = ''},
      currentId
    } = this.props;
    return `${pathname.replace(currentId, id)}`;
  };

  goToPage = (direction) => {
    const {router, location} = this.props;
    const {[direction]: {id = ''} = {}} = this.state;
    router.push({
      ...location,
      pathname: this.getUrl(id)
    });
  };

  render() {
    const {
      previous: {name: previousName = ''} = {},
      next: {name: nextName = ''} = {}
    } = this.state;
    if (previousName !== '' || nextName !== '') {
      return (
        <div className="organizations-navigator">
          <div className="organizations-navigator-button prev-organization">
            <If condition={previousName !== ''}>
              <Link
                onClick={() => {
                  this.goToPage('previous');
                }}
              >
                <div
                  onMouseLeave={() => this.setState({showPrevCompany: false})}
                  onMouseEnter={() => this.setState({showPrevCompany: true})}
                  className="arrow-container"
                >
                  <ImageNavigateBefore color={'white'} />
                  <If condition={this.state.showPrevCompany}>
                    <p>{previousName}</p>
                  </If>
                </div>
              </Link>
            </If>
          </div>
          <div className="organizations-navigator-button next-organization">
            <If condition={nextName !== ''}>
              <Link
                onClick={() => {
                  this.goToPage('next');
                }}
              >
                <div
                  onMouseLeave={() => this.setState({showNextCompany: false})}
                  onMouseEnter={() => this.setState({showNextCompany: true})}
                  className="arrow-container"
                >
                  <If condition={this.state.showNextCompany}>
                    <p>{nextName}</p>
                  </If>
                  <ImageNavigateNext color={'white'} />
                </div>
              </Link>
            </If>
          </div>
        </div>
      );
    }
    return null;
  }
}

export default withRouter(OrganizationsNavigator);
