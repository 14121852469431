import _ from 'underscore';

export default class SearchParamsHelper {
  static normalizeSearchParams(params = {}) {
    if (params.offset === 0 || typeof params.offset === 'undefined') {
      params.offset = '0';
    }
    return params;
  }

  static arePropsEqual(prevProps, nextProps) {
    const cloneInputParams = SearchParamsHelper.normalizeSearchParams(
        _.clone(prevProps)
      ),
      cloneSearchParams = SearchParamsHelper.normalizeSearchParams(
        _.clone(nextProps)
      );
    return _.isEqual(cloneInputParams, cloneSearchParams);
  }
}
