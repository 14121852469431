function replaceUrlParts(url) {
  return url
    .replace(/^\/\/s3/, 'https://s3')
    .replace(
      '//res.cloudinary.com/crunchbase-production/http://public.crunchbase.com',
      '//public.crunchbase.com'
    );
}

export default function imageUrl({url, height, width = 100, center = 'thumb'}) {
  if (!url) {
    return undefined;
  }

  const options = [`w_${width}`, `c_${center}`].concat(
    height ? [`h_${height}`] : []
  );

  return `https://res.cloudinary.com/inreach-ventures/image/fetch/${options.join()}/${encodeURIComponent(
    replaceUrlParts(url)
  )}`;
}
