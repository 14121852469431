import React from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import {ListItem} from 'material-ui/List';
import ActionInfoOutline from 'material-ui/svg-icons/action/info-outline';

class DIGDownDialog extends React.Component {
  render() {
    const actions = [
      <FlatButton
        label="Contact support"
        primary={true}
        onClick={() => {
          window.location.href = `mailto:ben@inreachventures.com?subject=DIG%20is%20not%20responding`;
        }}
      />,
      <FlatButton
        label="Reload page"
        primary={true}
        onClick={() => {
          location.reload();
        }}
      />
    ];

    return (
      <Dialog
        title="DIG is not responding"
        actions={actions}
        modal={false}
        open={this.props.open}
        onRequestClose={this.props.handleClose}
      >
        <div className="dig-down-dialog">
          <p>
            DIG is not temporarily responding, please try to reload the page. If
            this has been repeatedly happening, please contact support.
          </p>
          <If condition={this.props.errorMessage}>
            <ListItem
              primaryText="Info about the error"
              leftIcon={<ActionInfoOutline />}
              primaryTogglesNestedList={true}
              nestedItems={[
                <p style={{wordBreak: 'break-all'}}>
                  {this.props.errorMessage}
                </p>
              ]}
            />
          </If>
        </div>
      </Dialog>
    );
  }
}

export default DIGDownDialog;
