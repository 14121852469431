import Config from '../config';
import auth from './Auth';
import request from 'superagent';

export default class InvestorFundingRoundsStore {
  constructor() {
    this.url = `${Config.api.host}/funding_rounds/`;
  }
  getAggregationsURL = (investor) => {
    return `${
      Config.api.host
    }/investors/${investor}/funding_round_aggregations`;
  };

  fundingRounds = (ids) => {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(this.url + ids.join(','))
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .set('Accept', 'application/json')
      )
      .then(({body: {models = []} = {}}) => {
        return models;
      });
  };
  fundingRoundsAggregations = (investorId) => {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(this.getAggregationsURL(investorId))
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .set('Accept', 'application/json')
      )
      .then(({body: {funding_round_aggregations = []} = {}}) => {
        return funding_round_aggregations;
      });
  };
}
