export default [
  {value: 'ANGELLIST', label: 'AngelList'},
  {value: 'CRUNCHBASE', label: 'CrunchBase'},
  {value: 'PRODUCTHUNT', label: 'ProductHunt'},
  {value: 'DEALROOM', label: 'DealRoom'},
  {value: 'CROWDSURFER', label: 'CrowdSurfer'},
  {value: 'DUTCHSTARTUPS', label: 'DutchStartups'},
  {value: 'OPENGRAPH', label: 'OpenGraph'},
  {value: 'IMPORT', label: 'Import'},
  {value: 'FUNDING', label: 'Funding Form'},
  {value: 'V2FUNDING', label: 'Funding Form V2'},
  {value: 'THEHUB', label: 'The Hub'},
  {value: 'NORDICTECHLIST', label: 'Nordic Tech List'},
  {value: 'STARTUPEXPLORE', label: 'Startup Explore'},
  {value: 'TECH_LONDON', label: 'Tech.London'},
  {value: 'ESTONIANMAFIA', label: 'Estonian Mafia'},
  {value: 'STARTUPSBE', label: 'Startups.be'},
  {value: 'STARTUPS_LIST', label: 'Startups-list.com'},
  {value: 'BCNSTARTUPMAP', label: 'Barcelona Startups'},
  {value: 'STARTUPNORWAY', label: 'Startupnorway.com'},
  {value: 'STARTUP_POLAND', label: 'Startuppoland.org'},
  {value: 'BLOCKCHAINSTARTUPS', label: 'Blockchain Startups'},
  {value: 'F6S', label: 'F6S'},
  {value: 'SMITHANDCROWN', label: 'Smith And Crown (ICOs)'},
  {value: 'TOKENDATA', label: 'TokenData (ICOs)'},
  {value: 'OXYLEADS', label: 'Oxyleads'},
  {value: 'YCOMBINATOR', label: 'YCombinator'},
  {value: 'PREDICTLEADS', label: 'PredictLeads'},
  {value: 'NUMA_HUB', label: 'Numa Hub'},
  {value: 'JAIMELESSTARTUPS', label: 'Jaime Les Startups'},
  {value: 'BETA_LIST', label: 'Beta List'},
  {value: 'KIMA_VENTURES', label: 'Kima Ventures'},
  {value: 'ROCKSTART', label: 'Rockstart'},
  {value: 'HAMBURG_STARTUPS', label: 'Hamburg Startup Monitor'},
  {value: 'WAYRA_UK', label: 'Wayra UK'},
  {value: 'OPENFUTURE', label: 'Openfuture'},
  {value: 'STARTUP_OLE', label: 'Startup Ole'},
  {value: 'LES_PEPITES_TECH', label: 'Les Pepites Tech'},
  {value: 'EU_STARTUPS', label: 'EU Startups'},
  {value: 'WELCOME_TO_THE_JUNGLE', label: 'Welcome to the Jungle'},
  {value: 'GRUENDERSZENE', label: 'Gruenderszene'},
  {value: 'AZERTYJOBS', label: 'Azerty Jobs'},
  {value: 'DIGITAL_GRENOBLE', label: 'Digital Grenoble'},
  {value: 'DUBLIN_TECH_SUMMIT', label: 'Dublin Tech Summit'},
  {value: 'MUNICH_STARTUP', label: 'Munich Startup'},
  {value: 'SEEDCAMP', label: 'Seedcamp'},
  {value: 'TECH_PITCH_4PT5', label: 'Tech Pitch 4pt5'},
  {value: 'UNICORN_HUNT', label: 'Unicorn Hunt'},
  {value: 'FINSMES', label: 'FinSMEs'},
  {value: 'INVESTIERE', label: 'Investiere'},
  {value: 'STARTUP_HUBS', label: 'Startup Hubs'},
  {value: 'STARTUP_BRETT', label: 'Startup Brett'},
  {value: 'ROMANIANSTARTUPS', label: 'Romanian Startups'},
  {value: 'INC_5000_EU', label: 'Inc. 5000 Europe'},
  {value: 'SUP46', label: 'sup46 (Start-up People of Sweden)'},
  {value: 'CZECHSTARTUPS', label: 'Czech Startups'},
  {value: 'STARTUPJOBS_CZ', label: 'StartupJobs (CZ)'},
  {value: 'STARTUP_ONLY', label: 'Startup Only'},
  {value: 'MAM_STARTUP', label: 'Mam Startup'},
  {value: 'FINTASTICO', label: 'Fintastico'},
  {value: 'STARTUPS_HU', label: 'Startups HU'},
  {value: 'LABS_OF_LATVIA', label: 'Labs of Latvia'},
  {value: 'VCEE_STARTUPS', label: 'Vcee Startups'},
  {value: 'THE_FACTORY', label: 'The Factory'},
  {value: 'STARTUPLISBOA', label: 'Startup Lisboa'},
  {value: 'STARTUPBLINK', label: 'Startup Blink'},
  {value: 'HIRED', label: 'Hired'},
  {value: 'STARTUPRANKING', label: 'Startup Ranking'},
  {value: 'BERLIN_STARTUP_JOBS', label: 'Berlin Startup Jobs'},
  {value: 'STARTUS', label: 'StartUs'},
  {value: 'STARTUP_NAVIGATOR', label: 'Startup Navigator'},
  {value: 'CAPTERRA', label: 'Capterra'},
  {value: 'EIPP', label: 'European Investment Project Portal (EIPP)'},
  {value: 'FT_1000', label: 'FT 1000'},
  {value: 'SEED_DB', label: 'Seed DB'},
  {value: 'FUNDERBEAM', label: 'Funderbeam'},
  {value: 'STARTUP_INSPIRE', label: 'Startup Inspire'},
  {value: 'NATIONAL_BUSINESS_AWARDS_UK', label: 'National Business Awards UK'},
  {value: 'LAUNCHED', label: 'Launched'},
  {value: 'NO_FLUFF_JOBS', label: 'No Fluff Jobs'},
  {value: 'DEALROOM_TRANSACTIONS', label: 'Dealroom Transactions'},
  {value: 'PREDICTLEADS_POST', label: 'PredictLeads HackerNews Posts'},
  {value: 'SENSORTOWER', label: 'SensorTower'},
  {value: 'STARTUPMATCHER', label: 'StartupMatcher'},
  {value: 'ALL_STARTUPS', label: 'All Startups'},
  {value: 'NEULOGY', label: 'Neulogy'},
  {value: 'STARTUPBERLIN', label: 'StartupBerlin'},
  {value: 'THE_HUB', label: 'The Hub'},
  {value: 'STARTUP_XPLORE', label: 'Startup Xplore'},
  {value: 'Y_COMBINATOR', label: 'YCombinator'},
  {value: 'BCN_STARTUP_MAP', label: 'BCN Startup Map'},
  {value: 'TOKEN_DATA', label: 'Token Data'},
  {value: 'SMITH_AND_CROWN', label: 'Smith and Crown'},
  {value: 'STARTUPS_BE', label: 'Startups BE'},
  {value: 'DER_BRUTKASTEN', label: 'Der Brutkasten'},
  {value: 'ERASMUS_CENTRE', label: 'Erasmus Centre'},
  {value: 'DEEPTECHFORGOOD', label: 'Deep Tech for Good'},
  {value: 'LAFRENCHTECH_VISAS', label: 'La French Tech Visas'},
  {value: 'LUPA', label: 'Lupa CZ'},
  {value: 'SAAS1000', label: 'Saas 1000'},
  {value: 'LISBON_CHALLENGE', label: 'Lisbon Challenge'},
  {value: 'GETINTHERING', label: 'Get in the Ring'},
  {value: 'JOBS_STATIONF', label: 'Jobs StationF'},
  {value: 'IMPERIALENTERPRISELAB', label: 'Imperial Enterprise Lab'},
  {value: 'B10', label: 'B10'},
  {value: 'TOP100STARTUP', label: 'Top 100 Startup'},
  {value: 'SPEEDINVEST', label: 'Speedinvest'},
  {value: 'STARTUPS_CO_UK', label: 'Startups.co.uk'},
  {value: 'ELEVEN', label: 'Eleven'},
  {value: 'LAUNCHUB', label: 'Launchub'},
  {value: 'CREDOVENTURES', label: 'Credo'},
  {value: 'SEEDCAPITAL_DK', label: 'Seedcapital DK'},
  {value: 'ACCEL', label: 'Accel'},
  {value: 'FOMCAP', label: 'Force Over Mass Capital'},
  {value: 'BALDERTON', label: 'Balderton'},
  {value: 'ALBION', label: 'Albion VC'},
  {value: 'ATOMICO', label: 'Atomico'},
  {value: 'DNCAPITAL', label: 'DN Capital'},
  {value: 'DOWNING', label: 'Downing'},
  {value: 'FIRSTMINUTE_CAPITAL', label: 'Firstminute Capital'},
  {value: 'FORWARDPARTNERS', label: 'Forward Partners'},
  {value: 'HIGHLAND_EUROPE', label: 'Highland Europe'},
  {value: 'EPISODE1', label: 'Episode 1'},
  {value: 'BARCELONATECHCITY', label: 'Barcelona Tech City'},
  {value: 'INDEX_VENTURES', label: 'Index Ventures'},
  {value: 'INITIAL_CAPITAL', label: 'Initial Capital'},
  {value: 'IQ_CAPITAL', label: 'IQ Capital'},
  {value: 'ENTREPRENEUR_FIRST', label: 'Entrepreneur First'},
  {value: 'ISOMER_CAPITAL', label: 'Isomer Capital'},
  {value: 'MMC_VENTURES', label: 'MMC Ventures'},
  {value: 'OXFORD_CAPITAL', label: 'Oxford Capital'},
  {value: 'PARKWALK_ADVISORS', label: 'Parkwalk Advisors'},
  {value: 'PASSION', label: 'Passion'},
  {value: 'PLAYFAIR', label: 'Playfair Capital'},
  {value: 'NORTHZONE', label: 'Northzone'},
  {value: 'ANTHEMIS', label: 'Anthemis'},
  {value: 'WORKINSTARTUPS', label: 'Work in Startups'},
  {value: 'INDUSTRYINVESTMENT', label: 'Industry Investment (Tesi)'},
  {value: 'OMNESCAPITAL', label: 'Omnes Capital'},
  {value: 'THE_FSE_GROUP', label: 'The FSE Group'},
  {value: 'SEVENTURE', label: 'Seventure'},
  {value: 'XANGE', label: 'Xange'},
  {value: 'PARTECH', label: 'Partech Partners'},
  {value: 'A360CAPITAL', label: '360 Capital'},
  {value: 'NEWFUND', label: 'Newfund'},
  {value: 'ATLANTICLABS', label: 'Atlantic Labs'},
  {value: 'CAPNAMICVENTURES', label: 'Capnamic Ventures'},
  {value: 'COPARION', label: 'Coparion'},
  {value: 'EVENTURES', label: 'Eventures'},
  {value: 'HIGH_TECH_GRUENDERFONDS', label: 'High Tech Gruenderfonds'},
  {value: 'IBB_BET', label: 'IBB Beteiligungsgesellschaft'},
  {value: 'PROJECT_A', label: 'Project A'},
  {value: 'S_UBG_GROUP', label: 'S-UBG Group'},
  {value: 'TECH_IRELAND', label: 'TechIreland'},
  {value: 'CUBE_FIVE', label: 'Cube Five'},
  {value: 'CYLONLAB', label: 'Cylon Lab'},
  {value: 'FASTTRACKMALMO', label: 'Fast Track Malmo'},
  {value: 'STARTBASE', label: 'Startbase'},
  {value: 'CREATHOR', label: 'Creathor'},
  {value: 'EARLYBIRD', label: 'Earlybird'},
  {value: 'SAP_IO', label: 'SAP.io'},
  {value: 'CONORFUND', label: 'Conor Fund'},
  {value: 'HIVENTURES', label: 'Hiventures'},
  {value: 'GITHUB_TRENDING', label: 'Github Trending'},
  {value: 'VENTUREFRIENDS', label: 'VentureFriends'},
  {value: 'BVP', label: 'Business Venture Partners'},
  {value: 'DRAPERESPRIT', label: 'Draper Esprit'},
  {value: 'SOSV', label: 'SOSV'},
  {value: 'DELTAPARTNERS', label: 'Delta Partners'},
  {value: 'FRONTLINE_VENTURES', label: 'Frontline Ventures'},
  {value: 'LVENTURE', label: 'LVenture'},
  {value: 'OLTREVENTURE', label: 'Oltre Venture'},
  {value: 'P101', label: 'P101'},
  {value: 'STING', label: 'Sting'},
  {value: 'CAPHUB_IO', label: 'CapHub'},
  {value: 'MAZE_X', label: 'Maze X'},
  {value: 'APOLLO_IO', label: 'Apollo'},
  {value: 'ARMILAR', label: 'Armilar'},
  {value: 'BESTHORIZON', label: 'Best Horizon'},
  {value: 'PORTUGALVENTURES', label: 'Portugal Ventures'},
  {value: 'CHALMERS_VENTURES', label: 'Chalmers Ventures'},
  {value: 'ALMI_INVEST', label: 'Almi Invest'},
  {value: 'INVEREADY', label: 'Inveready'},
  {value: 'SEAYAVENTURES', label: 'Seaya Ventures'},
  {value: 'SAMAIPATA', label: 'Samaipata'},
  {value: 'NAUTACAPITAL', label: 'Nauta Capital'},
  {value: 'LANZAME', label: 'Lanzame'},
  {value: 'KFUND', label: 'Kfund'},
  {value: 'JME_VENTURES', label: 'JME Ventures'},
  {value: 'CAIXA_CAPITAL_RISC', label: 'Caixa Capital Risc'},
  {value: 'TECHSTARS_DEMODAY_AIRTABLE', label: 'Techstars Demoday Airtable'},
  {value: 'TECHSTARS_AIRTABLE', label: 'Techstars Airtable'},
  {value: 'INDUSTRIFONDEN', label: 'Industrifonden'},
  {value: 'DJAKNE', label: 'Djakne'},
  {value: 'SCHIBSTED_GROWTH', label: 'Schibsted Growth'},
  {value: 'MOUNTAIN_PARTNERS', label: 'Mountain Partners'},
  {value: 'CREANDUM', label: 'Creandum'},
  {value: 'EVENTMANAGERBLOG', label: 'Eventmanager Blog'},
  {value: 'ANTLER', label: 'Antler'},
  {value: 'STARTUPBUFFER', label: 'Startup Buffer'},
  {value: 'STARTUPBUTTON', label: 'Startup Button'},
  {value: 'STARTUPROULETTE', label: 'Startup Roulette'},
  {value: 'LAUNCHINGNEXT', label: 'Launching Next'},
  {value: 'STARTUPLIFT', label: 'Startup Lift'},
  {value: 'STARTUP88', label: 'Startup88'},
  {value: 'STARTUPBENCHMARKS', label: 'Startup Benchmarks'},
  {value: 'STARTUPBASE', label: 'Startupbase'},
  {value: 'STARTUPCOLLECTIONS', label: 'Startup Collections'},
  {value: 'LANDINGFOLIO', label: 'Landingfolio'},
  {value: 'THE_STARTUP_PITCH', label: 'The Startup Pitch'},
  {value: 'NGP_CAPITAL', label: 'NGP Capital'},
  {value: 'CHERRY_VC', label: 'Cherry VC'},
  {value: 'HV_CAPITAL', label: 'HV Capital'},
  {value: 'COLLISION', label: 'Collision Conf'},
  {value: 'MONACOTECH', label: 'Monacotech'},
  {value: 'TECHNOPARK_LIECHTENSTEIN', label: 'Technopark Liechtenstein'},
  {value: 'DAPHNI', label: 'Daphni'},
  {value: 'PILABS', label: 'Pilabs'},
  {value: 'STARTERSTORY', label: 'Starter Story'},
  {value: 'JOBFLUENT', label: 'Job Fluent'},
  {value: 'POSTMAKE', label: 'postmake'},
  {value: 'ACME', label: 'acme'},
  {value: 'TOMORROW_TODAY', label: 'Tommorrow Today'},
  {value: 'STARTUP_LAB', label: 'Startup Lab'},
  {value: 'START_GREEN', label: 'Start Green'},
  {value: 'WEB_SUMMIT', label: 'Web Summit'},
  {value: 'GET_LATKA', label: 'Get Latka'},
  {value: 'ACCELERACE', label: 'accelerace'},
  {value: 'E27', label: 'e27'},
  {value: 'AGORANOV', label: 'agoranov'},
  {value: 'THE_VENTURE_CITY', label: 'The Venture City'},
  {value: 'DIGITAL_HEALTH_LONDON', label: 'Digital Health London'},
  {value: 'REAKTORX', label: 'Reaktor X'},
  {value: 'PLUG_AND_PLAY', label: 'Plug and Play'},
  {value: 'MERIAN_VENTURES', label: 'Merian Ventures'},
  {value: 'ADA_VENTURES', label: 'Ada Ventures'},
  {value: 'FEMALE_FOUNDERS_GROW_F', label: 'Female Founders Grow F'},
  {value: 'ABSL_STARTUP_CHALLENGE', label: 'ABSL Startup Challenge'},
  {value: 'STARTUP_TARTU', label: 'Startup Tartu'},
  {value: 'STARTUP_ESTONIA', label: 'Startup Estonia'},
  {value: 'EXPERIOR_VENTURE_FUND', label: 'Experior Venture Fund'},
  {value: 'OKTOGON_VC', label: 'Oktogon Ventures'},
  {value: 'OTB_VENTURES', label: 'OTB Ventures'},
  {value: 'RBVC', label: 'Robert Bosch VC'},
  {value: 'ELEVATOR_VENTURES', label: 'Elevator Ventures'},
  {value: 'AXA_VP', label: 'AXA Venture Partners'},
  {value: 'UNICORNS_LT', label: 'Lithuanian Unicorns'},
  {value: 'APX', label: 'APX'},
  {value: 'OPEN_OCEAN', label: 'Open Ocean'},
  {value: 'STARTUP_CHILE', label: 'Startup Chile'},
  {value: 'STARTUP_WISEGUYS', label: 'Startup Wiseguys'},
  {value: 'SIGNALS_VC', label: 'Signals Venture Capital'},
  {value: 'WHITE_STAR_CAPITAL', label: 'White Star Capital'},
  {value: 'LHV', label: 'Lighthouse Ventures'},
  {value: 'ANGEL_ACADEME', label: 'Angel Academe'},
  {value: 'NEVEQ', label: 'NEVEQ'},
  {value: 'BRIGHTCAP_VC', label: 'Brightcap Ventures'},
  {value: 'AM_VENTURES', label: 'AM Ventures'},
  {value: 'A3TS_CAPITAL', label: '3TS Capital'},
  {value: 'TERA_VC', label: 'Tera Ventures'},
  {value: 'PRESTO_VENTURES', label: 'Presto Ventures'},
  {value: 'J_AND_T_VENTURES', label: 'J&T Ventures'},
  {value: 'BORN2GROW', label: 'BORN2GROW'},
  {value: 'HOXTON_VENTURES', label: 'Hoxton Ventures'},
  {value: 'KAYA', label: 'Kaya'},
  {value: 'BREEGA', label: 'Breega'},
  {value: 'F10', label: 'F10'},
  {value: 'MANTA_RAY', label: 'Manta Ray'},
  {value: 'BLACK_PEARLS', label: 'Black Pearls'}
].sort((a, b) => (b.label < a.label ? 1 : -1));
