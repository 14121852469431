import auth from './Auth';
import Config from '../config';
import request from 'superagent';

export default class AggregationsStore {
  constructor(type) {
    this.url = `${Config.api.host}/similar_${type}`;
  }

  aggregations(field, size) {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(this.url)
          .query({
            access_token: authData.token,
            client: authData.client,
            field,
            size
          })
          .set('Accept', 'application/json')
      )
      .then((response) => response.body);
  }
}
