import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

class BusinessModel extends React.Component {
  static propTypes = {
    businessModel: PropTypes.string.isRequired
  };

  render() {
    const {businessModel} = this.props;
    return (
      <div className="data-wrapper price">
        <div className="data-header">Business Model</div>
        <Choose>
          <When condition={businessModel === ''}>
            <div className="data-content text empty">No data</div>
          </When>
          <Otherwise>
            <div className="data-content text">{businessModel}</div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(BusinessModel);
