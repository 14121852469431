import React from 'react';
import {Link, withRouter} from 'react-router';
import PropTypes from 'prop-types';

import countries from '../../../../../lib/countries';

function TrafficCountry({country: {country, value}}) {
  return (
    <div className="data-content-list-item text traffic-country">
      <div className="traffic-amount">{`(${value}%)`}</div>
      <div
        className="traffic-country-flag"
        style={{
          backgroundImage: `url('https://www.countryflags.io/${countries(
            country
          )}/flat/64.png')`
        }}
      />
      <div className="traffic-country-text">{country}</div>
    </div>
  );
}

class TrafficCountries extends React.Component {
  static propTypes = {
    countries: PropTypes.array.isRequired
  };

  render() {
    const {countries} = this.props;
    return (
      <div className="data-wrapper traffic-countries">
        <div className="data-header">Top Countries</div>
        <Choose>
          <When condition={countries.length === 0}>
            <div className="data-content text empty">No data</div>
          </When>
          <Otherwise>
            <div className="data-content list">
              {countries.sort((a, b) => b.value - a.value).map((country) => (
                <TrafficCountry country={country} key={country.country} />
              ))}
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(TrafficCountries);
