import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import WebProduct from './product/WebProduct';
import AppProduct from './product/AppProduct';

class KeyProducts extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    products: PropTypes.array.isRequired,
    signalsLoading: PropTypes.bool.isRequired,
    useSnapshot: PropTypes.bool.isRequired,
    mlUpdates: PropTypes.array
  };

  render() {
    const {
      organization,
      products = [],
      signalsLoading,
      useSnapshot = false,
      mlUpdates
    } = this.props;
    return (
      <div className="key-products">
        <div className="section-title main">Key Products</div>
        <Choose>
          <When condition={products.length === 0}>
            <div className="section-no-content">No Key Products</div>
          </When>
          <Otherwise>
            <div className="products-container">
              {products.map((product) => (
                <Choose>
                  <When condition={product.type === 'web'}>
                    <WebProduct
                      organization={organization}
                      product={product}
                      source="other"
                      key={product.id}
                      signalsLoading={signalsLoading}
                      useSnapshot={useSnapshot}
                      mlUpdates={mlUpdates}
                    />
                  </When>
                  <Otherwise>
                    <AppProduct
                      organization={organization}
                      product={product}
                      source="other"
                      key={product.id}
                      signalsLoading={signalsLoading}
                      useSnapshot={useSnapshot}
                      mlUpdates={mlUpdates}
                    />
                  </Otherwise>
                </Choose>
              ))}
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(KeyProducts);
