import React from 'react';
import Overview from './Overview';
import Organizations from '../../lib/Organizations';
import moment from 'moment/moment';

const RECONNECT_DATE_FORMAT = 'Do MMMM YYYY';

export default class OperationalOverview extends React.Component {
  organizationStage = (organization, client, now) => {
    const {
      stage,
      reconnect: {when, type} = {}
    } = Organizations.latestFinalOrInvestorDecision(organization, client);
    if (stage === 'in_tracking' && !!when && !!type) {
      if (
        moment(when, 'x')
          .add(1, 'weeks')
          .isBefore(now)
      ) {
        if (type === 'INVESTOR') {
          return 'investor_unable_to_reconnect';
        }
        return 'operational_unable_to_reconnect';
      }
    }
    return stage;
  };

  render() {
    return (
      <Overview
        stages={[
          'in_tracking',
          'operational_unable_to_reconnect',
          'investor_unable_to_reconnect'
        ]}
        nextPathname="/overview"
        organizationStage={this.organizationStage}
        statusFieldTitle="Reconnect"
        statusField={(organization, client, now) => {
          const {status, stage, reconnect: {when: reconnectWhen} = {}} =
            Organizations.getOrganizationState(organization, client) || {};
          if (stage === 'in_tracking' && !!reconnectWhen) {
            const reconnectMoment = moment(reconnectWhen, 'x');

            return `${reconnectMoment.format(RECONNECT_DATE_FORMAT)}`;
          }
          return status;
        }}
        orderBy="decisions.reconnect.when"
        order="ASC"
        {...this.props}
      />
    );
  }
}
