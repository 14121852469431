import React from 'react';
import PropTypes from 'prop-types';

import Card from '../../../card/Card';
import CardHeader from '../../../card/CardHeader';
import CardBody from '../../../card/CardBody';
import OrganizationDetailsHeader from './OrganizationDetailsHeader';
import OrganizationDetailsBody from './OrganizationDetailsBody';

export default class OrganizationDetails extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired
  };

  getOrganizationDetails = (organization = this.props.organization) => {
    const details = {};
    if (organization.email_address) {
      details.email_address = organization.email_address;
    }
    if (organization.phone_numbers) {
      details.phone_numbers = organization.phone_numbers;
    }
    if (organization.homepage_url) {
      details.homepage_url = organization.homepage_url;
    }
    if (organization.crunchbase) {
      details.crunchbase = organization.crunchbase;
    }
    if (organization.angellist) {
      details.angellist = organization.angellist;
    }
    if (organization.twitter) {
      details.twitter = organization.twitter;
    }
    if (organization.facebook) {
      details.facebook = organization.facebook;
    }
    if (organization.linkedin) {
      details.linkedin = organization.linkedin;
    }
    return details;
  };

  render() {
    const {organization} = this.props;
    const details = this.getOrganizationDetails(organization);
    if (Object.keys(details).length === 0) {
      return false;
    }
    return (
      <div className="organization-details">
        <Card>
          <CardHeader title="Organization Details">
            <OrganizationDetailsHeader organizationId={organization.id} />
          </CardHeader>
          <CardBody>
            <OrganizationDetailsBody details={details} />
          </CardBody>
        </Card>
      </div>
    );
  }
}
