export default {
  palette: {
    primary1Color: '#5DAB49',
    primary2Color: '#337ab7',
    selectedTextColor: '#5DAB49',
    accent1Color: '#eee',
    secondary2color: '#337ab7',
    secondary1color: '#337ab7'
  },
  menuItem: {
    selectedTextColor: '#5DAB49'
  }
};
