import React from 'react';
import PropTypes from 'prop-types';
import AddPerson from './edit/AddPerson';
import Dialog from 'material-ui/Dialog';

const dialogStyles = {
  dialogRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 0
  },
  dialogContent: {
    position: 'relative',
    width: '80vw',
    maxWidth: '1024px',
    transform: ''
  },
  dialogBody: {
    paddingBottom: 0
  }
};

export default class AddPersonDialog extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onCloseDialog: PropTypes.func.isRequired,
    organizationId: PropTypes.string.isRequired,
    onAddPerson: PropTypes.func.isRequired
  };

  render() {
    const {show, onCloseDialog, organizationId, onAddPerson} = this.props;

    return (
      <Dialog
        title=""
        bodyClassName="edit-person-dialog-root"
        actionsContainerClassName="actions-container"
        modal={false}
        open={show}
        onRequestClose={onCloseDialog}
        contentStyle={dialogStyles.dialogContent}
        bodyStyle={dialogStyles.dialogBody}
        style={dialogStyles.dialogRoot}
        repositionOnUpdate={false}
      >
        <AddPerson
          isInsideDialog={true}
          organizationId={organizationId}
          onCancelAdd={onCloseDialog}
          onAddPerson={onAddPerson}
        />
      </Dialog>
    );
  }
}
