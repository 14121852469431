import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import Store from 'material-ui/svg-icons/action/store';

import Insights from '../Insights';
import {getDataWrapperClassNames} from '../../../../lib/helpers';
import Tooltip from '../../../Tooltip';

class MarketPerformance extends React.Component {
  static propTypes = {
    market: PropTypes.object.isRequired,
    useSnapshot: PropTypes.bool,
    previous: PropTypes.bool,
    compressed: PropTypes.bool.isRequired
  };

  state = {
    showTooltip: false
  };

  getTooltipText = () => {
    const {compressed = false} = this.props;
    const text = [];
    if (compressed) {
      text.push('Market Performance');
    }
    return text.join('<br/>');
  };

  render() {
    const {
      market: {highlights = [], risks = [], redFlags = [], mlUpdates = []},
      previous = false,
      compressed = false
    } = this.props;
    const {showTooltip = false} = this.state;
    return (
      <div
        className="market"
        onMouseLeave={() => {
          if (compressed) {
            this.setState({showTooltip: false});
          }
        }}
        onMouseEnter={() => {
          if (compressed) {
            this.setState({showTooltip: true});
          }
        }}
        ref={(el) => (this._parent = el)}
      >
        <If condition={!compressed}>
          <div className="section-title">{`${
            previous ? 'Previous ' : ''
          }Market${window.innerWidth < 768 ? ' Performance' : ''}`}</div>
        </If>
        <Tooltip
          parent={this._parent}
          showTooltip={showTooltip}
          text={this.getTooltipText()}
          customClasses={['performance-tooltip']}
        />
        <div className={getDataWrapperClassNames(compressed)}>
          <div className="data-icon">
            <Store className="data-icon-svg" />
          </div>
          <Insights
            highlights={highlights}
            risks={risks}
            redFlags={redFlags}
            mlUpdates={mlUpdates}
            compressed={compressed}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(MarketPerformance);
