import React from 'react';
import PropTypes from 'prop-types';

import xss from 'xss';
import _ from 'underscore';

import TextField from 'material-ui/TextField';

export default class EditOrganizationWebsite extends React.Component {
  static propTypes = {
    website: PropTypes.string.isRequired,
    updateOrganizationFields: PropTypes.func.isRequired,
    source: PropTypes.string
  };

  state = {
    website: '',
    error: false
  };

  componentWillMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.website !== this.props.website) {
      this.initialize();
    }
  }

  initialize = () => {
    const {website = ''} = this.props;
    this.setState({
      website,
      error: this.getWebsiteError(website)
    });
  };

  getWebsiteError = (website) => {
    return (
      website === '' ||
      !/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?((\/\w{2})|(\/))?$/.test(
        website
      )
    );
  };

  handleChange = (event) => {
    const website = xss(event.target.value);
    this.setState({
      website,
      error: this.getWebsiteError(website)
    });
    this.debounceChange(website);
  };

  debounceChange = _.debounce((website) => {
    const trimmedWebsite = website.trim();
    this.setState({
      website: trimmedWebsite
    });
    this.props.updateOrganizationFields([
      {field: 'homepage_url', value: trimmedWebsite}
    ]);
  }, 1500);

  render() {
    const {source = 'edit-incomplete'} = this.props;
    const {website = '', error = false} = this.state;
    return (
      <div className="edit-organization-website">
        <If condition={source === 'edit-incomplete'}>
          <div className="edit-section-header">WEBSITE</div>
        </If>
        <TextField
          className="edit-organization-website-field edit-organization-input-field medium"
          value={website}
          onChange={this.handleChange}
          errorText={error ? 'Required' : false}
          name="edit-organization-website"
        />
      </div>
    );
  }
}
