import React from 'react';
import PropTypes from 'prop-types';

import xss from 'xss';
import _ from 'underscore';

import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import numberMultipliers from '../../../content/numberMultipliers';

export default class EditOrganizationTotalFunding extends React.Component {
  static propTypes = {
    totalFunding: PropTypes.number.isRequired,
    updateOrganizationFields: PropTypes.func.isRequired,
    source: PropTypes.string
  };

  state = {
    funding: {
      amount: '',
      multiplier: ''
    },
    error: false
  };

  componentWillMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.totalFunding !== this.props.totalFunding) {
      this.initialize();
    }
  }

  initialize = () => {
    const {amount, multiplier} = this.getDisplayFunding();
    this.setState({
      funding: {
        amount,
        multiplier
      },
      error: this.getAmountError(amount)
    });
  };

  getDisplayFunding = () => {
    const {totalFunding} = this.props;
    const fundingString = String(totalFunding);
    return {
      amount: this.getDeMultipliedFunding(fundingString),
      multiplier: this.getMultiplier(fundingString.length)
    };
  };

  getDeMultipliedFunding = (funding = '') => {
    const length = funding.length % 3;
    return funding.substring(0, length === 0 ? 3 : length);
  };

  getMultiplier = (length) =>
    length < 4 ? '' : length < 7 ? 'k' : length < 10 ? 'm' : 'b';

  getAmountError = (amount) => amount === '';

  handleChange = (field, value = '') => {
    const {funding, error} = this.state;
    funding[field] = xss(
      field === 'amount' ? value.replace(/[^0-9\.]/g, '') : value.toLowerCase()
    );
    this.setState({
      funding,
      error: field === 'amount' ? this.getAmountError(value) : error
    });
    this.debounceChange(this.getMultipliedFunding());
  };

  debounceChange = _.debounce((funding) => {
    this.props.updateOrganizationFields([
      {field: 'total_funding_usd', value: funding}
    ]);
  }, 1500);

  getMultipliedFunding = () => {
    const {funding: {amount = ''} = {}} = this.state;
    return parseFloat(amount) * this.getMultiplyAmount();
  };

  getMultiplyAmount = () => {
    const {funding: {multiplier = ''} = {}} = this.state;
    return multiplier === 'k'
      ? 1000
      : multiplier === 'm'
        ? 1000000
        : multiplier === 'b'
          ? 1000000000
          : 1;
  };

  render() {
    const {source = 'edit-incomplete'} = this.props;
    const {
      funding: {amount = '', multiplier = ''} = {},
      error = false
    } = this.state;
    return (
      <div className="edit-organization-funding">
        <If condition={source === 'edit-incomplete'}>
          <div className="edit-section-header">FUNDING (USD)</div>
        </If>
        <div className="edit-organization-funding-content">
          <TextField
            className="edit-organization-funding-amount-field edit-organization-input-field small"
            value={amount}
            onChange={(event) =>
              this.handleChange('amount', event.target.value)
            }
            errorText={error ? 'Required' : false}
            floatingLabelText="AMOUNT"
            name="edit-organization-funding-amount"
          />
          <SelectField
            className="edit-organization-funding-multiplier edit-organization-input-field small"
            floatingLabelText="X"
            value={multiplier.toUpperCase()}
            onChange={(event, index, value) => {
              this.handleChange('multiplier', value);
            }}
          >
            {numberMultipliers.map((multiplierCharacter) => (
              <MenuItem
                className="country-entry"
                key={multiplierCharacter}
                value={multiplierCharacter}
                primaryText={multiplierCharacter}
              />
            ))}
          </SelectField>
        </div>
      </div>
    );
  }
}
