import React from 'react';
import PropTypes from 'prop-types';

import DisplayReason from '../../../components/DisplayReason';

import {
  getUserName,
  getDateAndTime,
  capitalizeString
} from '../../../../lib/helpers';

export default class DisplayRecommendationDecision extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    content: PropTypes.object.isRequired
  };

  getChoice = (type, recommendation, decision) => {
    const choice = type === 'recommendation' ? recommendation : decision;
    return choice === 'WRONG_CALL'
      ? 'Should not have had this call'
      : capitalizeString(choice);
  };

  render() {
    const {
      type,
      content: {
        notes,
        created_at: createdAt = 0,
        recommendation = '',
        decision = '',
        user_id: userId = 'roberto@inreachventures.com',
        reasons = {}
      }
    } = this.props;
    const choice = this.getChoice(type, recommendation, decision);
    const {date, time} = getDateAndTime(createdAt);
    return (
      <div className="scorecard-decision-recommendation">
        <div className="scorecard-decision-recommendation-header">
          <div className="scorecard-decision-recommendation-user">{`${capitalizeString(
            type
          )}: ${getUserName(userId)}`}</div>
          <div className="spacer" />
          <If condition={createdAt !== 0}>
            <div className="scorecard-decision-recommendation-date">
              {`${date} - ${time}`}
            </div>
          </If>
        </div>
        <div className="scorecard-decision-recommendation-choice">{choice}</div>
        <div className="scorecard-decision-recommendation-notes">
          <DisplayReason reason={notes} structuredReasons={reasons} />
        </div>
      </div>
    );
  }
}
