import {
  titleCase,
  getRoundedUpValue,
  capitalizeString
} from '../../lib/helpers';
import React from 'react';
import {withRouter} from 'react-router';
import PortfolioLink from './investor_info/PortfolioLink';
import PortfolioTagLink from './investor_info/PorfolioTagLink';

export function getAverage(total, count) {
  return total === null || !count
    ? 'Undisclosed amount'
    : `$${getRoundedUpValue(Math.round(total / count))}`;
}

export function sortLocations(a, b) {
  return a.is_hq || b.is_hq
    ? a.is_hq && !b.is_hq
      ? -1
      : 1
    : a.continent === 'europe' || b.continent === 'europe'
      ? a.continent === 'europe' && b.continent !== 'europe'
        ? -1
        : 1
      : 0;
}

export function dateToTimesptamp(fundingRounds) {
  return new Date(fundingRounds).getTime();
}

export function getInvestmentsLast12Months(portfolioAggregation) {
  if (!portfolioAggregation.length)
    return {
      count: 0,
      organizationIds: []
    };
  var oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() - 1);
  const last12MonthsInvestments = portfolioAggregation.filter(
    (fundingRound) =>
      dateToTimesptamp(fundingRound['announced_on']) >= oneYearFromNow.getTime()
  );
  const las12MonthsOrganizationIds = last12MonthsInvestments.reduce(
    (idsList, fundingRound) => {
      idsList.push(fundingRound['organization_id']);
      return idsList;
    },
    []
  );
  return {
    count: last12MonthsInvestments.length,
    organizationIds: las12MonthsOrganizationIds
  };
}

export function getFirstInvestment(portfolioAggregation) {
  if (!portfolioAggregation.length)
    return {
      timestamp: 0,
      organizationId: 0
    };
  const minTimestamp = dateToTimesptamp(
    portfolioAggregation[0]['announced_on']
  );
  const minDateOrganizationId = portfolioAggregation[0]['organization_id'];

  return {
    timestamp: minTimestamp,
    organizationId: minDateOrganizationId
  };
}

export function getLastInvestment(portfolioAggregation) {
  if (!portfolioAggregation.length)
    return {
      timestamp: 0,
      organizationId: 0
    };
  const minTimestamp = dateToTimesptamp(
    portfolioAggregation[portfolioAggregation.length - 1]['announced_on']
  );
  const minDateOrganizationId =
    portfolioAggregation[portfolioAggregation.length - 1]['organization_id'];

  return {
    timestamp: minTimestamp,
    organizationId: minDateOrganizationId
  };
}

export function getAllOrganizationsIds(portfolioAggregation) {
  return portfolioAggregation.reduce((investmentIds, investment) => {
    if (!investmentIds.includes(investment.organization_id))
      investmentIds.push(investment.organization_id);
    return investmentIds;
  }, []);
}

function Tag(props) {
  const {tag, confidence, highlighted} = props;

  function getTagClassNames(confidence) {
    const classNames = ['tag'];
    if (confidence > 0.6) {
      classNames.push('high');
    }
    if (highlighted) {
      classNames.push('highlighted');
    }
    return classNames.join(' ');
  }

  return (
    <div className={getTagClassNames(confidence)}>{capitalizeString(tag)}</div>
  );
}

export function getUniqueIds(organizationSet) {
  const organizationIdsSet = [...new Set(organizationSet.organizationIds)];
  return [
    organizationIdsSet,
    organizationIdsSet.length > 200
      ? organizationIdsSet.slice(0, 200)
      : organizationIdsSet
  ]; //TODO fix slice 200 to limit
}

export function buildSelectionTag(
  organizationSet,
  seriesKey,
  section,
  updateSelectedTab
) {
  const [organizationIdsSet, subIdsSet] = getUniqueIds(organizationSet);
  return (
    <PortfolioTagLink
      text={
        titleCase(seriesKey.replace(/_/g, ' ')) +
        ' ' +
        organizationIdsSet.length
      }
      seriesKey={seriesKey}
      confidence={0.1}
      highlighted={false}
      filters={{ids: subIdsSet}}
      updateSelectedTab={updateSelectedTab}
    />
  );
}

export function buildSelectionType(
  organizationSet,
  seriesKey,
  section,
  updateSelectedTab
) {
  const [organizationIdsSet, subIdsSet] = getUniqueIds(organizationSet);
  return (
    <React.Fragment>
      <div
        className="data-content text invesor-profile-div"
        key={seriesKey + 'series'}
      >
        {' '}
        {titleCase(seriesKey.replace(/_/g, ' ')) + ': '}
      </div>
      <Choose>
        <When condition={organizationSet.count === 0}>
          <a>0</a>
        </When>
        <Otherwise>
          <PortfolioLink
            text={
              section === 'workflow'
                ? organizationIdsSet.length
                : organizationSet.count
            }
            seriesKey={seriesKey}
            filters={{ids: subIdsSet}}
            updateSelectedTab={updateSelectedTab}
          />
        </Otherwise>
      </Choose>
    </React.Fragment>
  );
}

export function getYearNewCount(
  organizationSet,
  seriesKey,
  investmentCategories
) {
  let newYearIds = [];
  let followOnIds = [];
  let invested = [];

  if (organizationSet) {
    organizationSet.organizationIds.forEach((organizationId) => {
      return Object.keys(investmentCategories).forEach((year) => {
        if (
          year - seriesKey < 0 &&
          !followOnIds.includes(organizationId) &&
          investmentCategories[year] &&
          investmentCategories[year].organizationIds.includes(organizationId)
        ) {
          followOnIds.push(organizationId);
        }
        if (year - seriesKey === 0 && !followOnIds.includes(organizationId)) {
          newYearIds.push(organizationId);
        }
      });
    });

    return [newYearIds, followOnIds];
  } else return [[], []];
}

export function buildSelectionLine(
  organizationSet,
  seriesKey,
  section,
  updateSelectedTab,
  investmentCategories
) {
  const [organizationIdsSet, subIdsSet] = getUniqueIds(organizationSet);
  const [yearNewIds, followOnIds] =
    section === 'investmentYears'
      ? getYearNewCount(organizationSet, seriesKey, investmentCategories)
      : [[], []];

  return (
    <div className="invesor-profile-row" key={seriesKey}>
      <div
        className={`data-content text invesor-profile-div ${
          section === 'fundingType' ? 'fundingType' : ''
        }`}
        key={seriesKey + 'series'}
      >
        {' '}
        {titleCase(seriesKey.replace(/_/g, ' '))}
      </div>
      <div className="spacer" key={seriesKey + 'spacer1'} />
      <If condition={section === 'fundingType'}>
        <div
          className="data-content text invesor-profile-div"
          key={seriesKey + 'average'}
          style={{flexGrow: 1}}
        >
          {' ' + getAverage(organizationSet.moneyRaised, organizationSet.count)}
        </div>
      </If>
      <If condition={section === 'topInvestment'}>
        <PortfolioLink
          text={getAverage(organizationSet.totalRaised, 1)}
          seriesKey={seriesKey}
          filters={{ids: subIdsSet}}
          updateSelectedTab={updateSelectedTab}
        />
      </If>
      <If condition={section === 'investmentYears'}>
        <PortfolioLink
          text={yearNewIds.length}
          seriesKey={seriesKey}
          filters={{ids: yearNewIds}}
          updateSelectedTab={updateSelectedTab}
        />
        <div className="spacer" key={seriesKey + 'spacer2'} />
        <PortfolioLink
          text={followOnIds.length}
          seriesKey={seriesKey}
          filters={{ids: followOnIds}}
          updateSelectedTab={updateSelectedTab}
        />
      </If>
      <If condition={section !== 'topInvestment'}>
        <div className="spacer" key={seriesKey + 'spacer2'} />
        <Choose>
          <When condition={organizationSet.count === 0}>
            <a>0</a>
          </When>
          <Otherwise>
            <PortfolioLink
              text={
                [
                  'workflow',
                  'decisions',
                  'investmentTypes',
                  'locations'
                ].includes(section)
                  ? organizationIdsSet.length
                  : organizationSet.count
              }
              seriesKey={seriesKey}
              filters={{ids: subIdsSet}}
              updateSelectedTab={updateSelectedTab}
            />
          </Otherwise>
        </Choose>
      </If>
    </div>
  );
}

export function buildInvestmentCategory(
  investment,
  investmentCategoriesList,
  categoryType
) {
  const {investment_type: investmentType = ''} = investment;
  const {location: {country: investmentLocation = ''} = {}} = investment;
  const {ml_tags: tags = {}} = investment;
  const {funding_type: fundingType = ''} = investment;
  const {announced_on: date = ''} = investment;
  const year = date.length > 3 ? date.substring(0, 4) : 'Undisclosed';
  const {organization_name: organizationName = ''} = investment;

  switch (categoryType) {
    case 'fundingType':
      return addInvestmentCategory(
        investment,
        investmentCategoriesList,
        fundingType
      );
    case 'locations':
      return addInvestmentCategory(
        investment,
        investmentCategoriesList,
        investmentLocation
      );
    case 'tags':
      Object.entries(tags).forEach(([tagKey, tagWeight]) => {
        if (tagWeight > 0.6) {
          addInvestmentCategory(investment, investmentCategoriesList, tagKey);
        }
      });
      return investmentCategoriesList;
    case 'investmentTypes':
      return addInvestmentCategory(
        investment,
        investmentCategoriesList,
        investmentType
      );
    case 'topInvestment':
      return addInvestmentCategory(
        investment,
        investmentCategoriesList,
        organizationName
      );
    case 'investmentYears':
      return addInvestmentCategory(investment, investmentCategoriesList, year);
  }
}

export function addInvestmentCategory(
  investment,
  investmentCategoriesList,
  investmentType
) {
  const {organization_name = ''} = investment;
  if (investmentType !== '') {
    if (investmentType.toLowerCase() === organization_name.toLowerCase()) {
      if (!(investmentType.toLowerCase() in investmentCategoriesList)) {
        investmentCategoriesList[investmentType.toLowerCase()] = {
          totalRaised: investment['total_raised_usd']
            ? investment['total_raised_usd']
            : 0,
          moneyRaised: investment['money_raised_usd']
            ? investment['money_raised_usd']
            : 0,
          count: 1,
          organizationIds: [investment['organization_id']]
        };
      } else {
        investmentCategoriesList[investmentType.toLowerCase()][
          'moneyRaised'
        ] += investment['money_raised_usd']
          ? investment['money_raised_usd']
          : 0;
      }
    } else if (investmentType in investmentCategoriesList) {
      investmentCategoriesList[investmentType]['totalRaised'] += investment[
        'total_raised_usd'
      ]
        ? investment['total_raised_usd']
        : 0;
      investmentCategoriesList[investmentType]['moneyRaised'] += investment[
        'money_raised_usd'
      ]
        ? investment['money_raised_usd']
        : 0;
      investmentCategoriesList[investmentType]['count'] += 1;
      if (
        !investmentCategoriesList[investmentType]['organizationIds'].includes(
          investment['organization_id']
        )
      )
        investmentCategoriesList[investmentType]['organizationIds'].push(
          investment['organization_id']
        );
    } else {
      investmentCategoriesList[investmentType] = {
        totalRaised: investment['total_raised_usd']
          ? investment['total_raised_usd']
          : 0,
        moneyRaised: investment['money_raised_usd']
          ? investment['money_raised_usd']
          : 0,
        count: 1,
        organizationIds: [investment['organization_id']]
      };
    }
  }
  return investmentCategoriesList;
}
