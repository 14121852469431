export default {
  OUTBOUND_SEND_FORM: 'Outbound Send Form',
  OUTBOUND_REMINDER: 'Outbound Reminder',
  SCHEDULE_CALL: 'Schedule Call',
  OUTBOUND_CLOSE_LOOP_WITH_PARTIAL: 'Outbound Close Loop With Partial',
  OUTBOUND_SEND_FORM_WITH_PARTIAL: 'Outbound Send Form With Partial',
  OUTBOUND_FALLBACK_TO_CALL: 'Outbound Fallback to Call',
  OUTBOUND_FEEDBACK: 'Outbound Feedback',
  INBOUND_FEEDBACK: 'Inbound Feedback',
  OUTBOUND_JUMP_TO_CALL: 'Outbound Jump to Call',
  INBOUND_JUMP_TO_CALL: 'Inbound Jump to Call',
  OUTBOUND_CLOSE_LOOP: 'Outbound Close Loop',
  OUTBOUND_MAKE_CONTACT: 'Outbound Make Contact',
  RECONNECT: 'Reconnect',
  BACKUP_MESSAGE: 'Backup Message'
};
