import React from 'react';
import InvestorHeader from './InvestorHeader';
import InvestorTabs from './InvestorTabs';
import PropTypes from 'prop-types';

export default class InvestorTopBar extends React.Component {
  render() {
    return (
      <React.Fragment>
        <InvestorHeader {...this.props} />
        <InvestorTabs {...this.props} />
      </React.Fragment>
    );
  }
}
