import React from 'react';
import PropTypes from 'prop-types';
import {Component} from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import inreachTeam from '../../config/inreach_team';
import {activeInReachTeam} from '../../lib/helpers';

const ACTIVE_TEAM = activeInReachTeam();

export default class AssignedTo extends Component {
  static propTypes = {
    assignedTo: PropTypes.string,
    handleAssignedToChange: PropTypes.func.isRequired
  };

  team = (assignedTo) => {
    if (ACTIVE_TEAM.some(({email}) => assignedTo === email)) {
      return ACTIVE_TEAM;
    }
    return inreachTeam;
  };

  render() {
    const {assignedTo = null, handleAssignedToChange} = this.props;

    return (
      <SelectField
        value={assignedTo}
        onChange={(e, i, value) => handleAssignedToChange(value)}
        floatingLabelText="Assigned to"
        floatingLabelFixed={true}
        fullWidth={true}
        className="top-bar-selector"
        menuStyle={{
          backgroundColor: '#ffffff',
          height: '70%',
          paddingLeft: '8px'
        }}
        floatingLabelStyle={{paddingLeft: '8px'}}
      >
        {this.team(assignedTo).map((person) => {
          return (
            <MenuItem
              key={person.email}
              value={person.email}
              primaryText={person.name}
            />
          );
        })}
      </SelectField>
    );
  }
}
