import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import {capitalizeString} from '../../../../lib/helpers';
import Tooltip from '../../../Tooltip';

class InvestmentType extends React.Component {
  static propTypes = {
    investmentType: PropTypes.string.isRequired
  };

  state = {
    showTooltip: false
  };

  hasError = () => {
    const {investmentType} = this.props;
    return (
      investmentType !== 'saas' &&
      investmentType !== 'marketplace' &&
      investmentType !== 'consumer'
    );
  };

  getInvestmentTypeClasses = () => {
    const classNames = ['data-content', 'text'];
    if (this.hasError()) {
      classNames.push('red-flag');
    }
    return classNames.join(' ');
  };

  getInvestmentTypeString = () => {
    const {investmentType} = this.props;
    return `${capitalizeString(investmentType.replace(/[-_]/g, ' '))}`;
  };

  render() {
    const {investmentType = ''} = this.props;
    const {showTooltip} = this.state;
    return (
      <div
        className="data-wrapper investment-type"
        onMouseLeave={() => {
          if (this.hasError()) {
            this.setState({showTooltip: false});
          }
        }}
        onMouseEnter={() => {
          if (this.hasError()) {
            this.setState({showTooltip: true});
          }
        }}
        rel={(el) => (this._parent = el)}
      >
        <div className="data-header">Investment Type</div>
        <Choose>
          <When condition={investmentType === ''}>
            <div className="data-content text empty">Unknown</div>
          </When>
          <Otherwise>
            <Tooltip
              parent={this._parent}
              showTooltip={showTooltip}
              text="The investment type is not SaaS, Marketplace or Consumer Internet"
            />
            <div className={this.getInvestmentTypeClasses()}>
              {this.getInvestmentTypeString()}
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(InvestmentType);
