import React from 'react';
import {render} from 'react-dom';
import _ from 'underscore';

import Main from './ui/Main';

render(<Main />, document.getElementById('app'));

// Hot Module Replacement
if (module.hot) {
  module.hot.accept();
}

const setVHCSSProperty = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

setVHCSSProperty();
window.addEventListener('resize', () => {
  // debounce to avoid triggering to many events
  //_.debounce(() => {
  setVHCSSProperty();
  //}, 100);
});
