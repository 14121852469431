import React from 'react';
import TextField from 'material-ui/TextField';
import OrganizationsSuggestStore from '../../../storage/OrganizationsSuggestStore';

export default class OrganizationPicker extends React.Component {
  state = {
    searchTerm: '',
    loadingSuggestions: false,
    organizations: []
  };

  store = new OrganizationsSuggestStore();

  handleChangeInput = (event, searchTerm) => {
    this.setState(() => ({searchTerm}));

    if (!this.state.loadSuggestions) {
      this.loadSuggestions(searchTerm);
    }
  };

  loadSuggestions = (searchTerm) => {
    this.setState(() => ({organzations: []}));

    if (!searchTerm) {
      return;
    }

    this.setState(
      () => ({
        loadingSuggestions: true
      }),
      () =>
        this.store
          .suggestByName(searchTerm)
          .then((organizations = []) =>
            this.setState(() => ({loadingSuggestions: false, organizations}))
          )
          .catch(console.error)
    );
  };

  render() {
    return (
      <div>
        <TextField
          id="merge-organzations-search"
          onChange={this.handleChangeInput}
          fullWidth={true}
          hintText="Organization name"
        />
        {this.props.children ? this.props.children(this.state) : null}
      </div>
    );
  }
}
