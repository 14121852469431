import React from 'react';
import PropTypes from 'prop-types';
import IncompleteStore from '../../storage/IncompleteStore';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import LinearProgress from 'material-ui/LinearProgress';

export default class ImportBatch extends React.Component {
  static propTypes = {
    batchName: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    imported: PropTypes.func.isRequired
  };

  incompleteStore = new IncompleteStore();

  state = {
    importing: false,
    error: null
  };

  importCompleted = () => {
    this.setState({importing: true});
    this.incompleteStore
      .importCompleted(this.props.batchName)
      .then(() => {
        this.setState({importing: false});
        this.props.imported();
      })
      .catch((error) => {
        console.error('Error', error);
        this.setState({importing: false, error: JSON.stringify(error)});
      });
  };

  render() {
    const {batchName, close} = this.props,
      {importing, error} = this.state;

    return (
      <Dialog
        title={`Import the completed CSV for ${batchName}`}
        actions={[
          <RaisedButton
            key="cancel"
            label="Cancel"
            primary={false}
            onClick={close}
            style={{marginRight: '12px'}}
            disabled={false}
          />,
          <RaisedButton
            key="import"
            label="Import"
            primary={true}
            onClick={this.importCompleted}
            style={{marginRight: '12px'}}
            disabled={false}
          />
        ]}
        modal={true}
        className="quick-add"
        autoDetectWindowHeight={true}
        autoScrollBodyContent={true}
        repositionOnUpdate={true}
        open={true}
      >
        <Choose>
          <When condition={error}>
            <p>Error: {JSON.stringify(error)}</p>
          </When>

          <When condition={importing}>
            <p>This will take a long time...</p>
            <LinearProgress />
          </When>
        </Choose>
      </Dialog>
    );
  }
}
