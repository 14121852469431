import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import ActionDelete from 'material-ui/svg-icons/action/delete';
import Dialog from 'material-ui/Dialog';

class FiltersSetSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteDialog: false
    };
    this.handleDeleteDialogOpen = this.handleDeleteDialogOpen.bind(this);
    this.handleDeleteDialogClose = this.handleDeleteDialogClose.bind(this);
  }

  handleDeleteDialogOpen() {
    this.setState({showDeleteDialog: true});
  }

  handleDeleteDialogClose() {
    this.setState({showDeleteDialog: false});
  }

  render() {
    const actions = [
      <FlatButton label="Cancel" onClick={this.handleClose} />,
      <FlatButton
        label="Confirm"
        primary={true}
        onClick={() => this.props.handleDeleteFiltersSet(this.props.name)}
      />
    ];

    return (
      <div
        className={`filters-set-selector ${
          this.props.isSelected ? 'selected' : ''
        }`}
      >
        <RaisedButton
          onClick={() => {
            return this.props.isSelected
              ? this.props.handleDeselectFiltersSet(this.props.name)
              : this.props.handleSelectFiltersSet(this.props.name);
          }}
          label={this.props.name}
        />
        <FlatButton
          label="Delete"
          labelStyle={{color: '#C30000'}}
          icon={<ActionDelete color={'#C30000'} />}
          onClick={this.handleDeleteDialogOpen}
        />
        <Dialog
          actions={actions}
          modal={false}
          open={this.state.showDeleteDialog}
          onRequestClose={this.handleDeleteDialogClose}
        >
          Are you sure you want to delete <strong>{this.props.name}</strong>?
        </Dialog>
      </div>
    );
  }
}

export default FiltersSetSelector;
