import ModelStore from './ModelStore';

const OxyleadsMemberStore = function(id) {
  this.id = id;
  this.path = '/providers/oxyleads/members/{id}';

  return this;
};
OxyleadsMemberStore.prototype = new ModelStore();

export default OxyleadsMemberStore;
