import PropTypes from 'prop-types';
import React from 'react';

import Close from 'material-ui/svg-icons/navigation/close';

import Loading from './Loading';

class FullScreenLoading extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired
  };

  state = {
    show: false
  };

  componentDidMount() {
    this.setState({
      show: this.props.show
    });
  }

  componentDidUpdate(prevProps) {
    const {show = false} = this.props;
    if (prevProps.show !== show) {
      this.setState({
        show
      });
    }
  }

  getClassNames = () => {
    const {show = false} = this.state;
    const classNames = ['full-screen-loading'];
    if (show) {
      classNames.push('show');
    }
    return classNames.join(' ');
  };

  render() {
    return (
      <div className={this.getClassNames()}>
        <Close
          className="close-button"
          onClick={() => {
            this.setState({show: false});
          }}
        />
        <Loading align="center" />
      </div>
    );
  }
}

export default FullScreenLoading;
