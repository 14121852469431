import INBOX_REASONS from '../content/inboxReasons';
import inboxHelper from './InboxHelper';
import _ from 'underscore';

const ONE_YEAR_MILLIS = 1000 * 60 * 60 * 24 * 365;

export default class Organizations {
  static finalOrInvestorDecisions(organization, client) {
    const clientDecisions = Organizations.getClientDecisions(
      organization,
      client
    );
    return Object.keys(clientDecisions)
      .map((key) => {
        return clientDecisions[key];
      })
      .filter((decision) => {
        return (
          (decision.is_final || decision.user_role === 'investor') &&
          decision.state !== 'unseen'
        );
      });
  }

  static transitionalDecisions(organization, client) {
    const clientDecisions = Organizations.getClientDecisions(
      organization,
      client
    );
    return Object.keys(clientDecisions)
      .map((key) => clientDecisions[key])
      .filter((decision) => !decision.is_final && decision.state !== 'unseen');
  }

  static getOldestTransitionalInProgressDecision(organization, client) {
    const inProgressTransitionalDecisions = Organizations.transitionalDecisions(
      organization,
      client
    ).filter((decision) => decision.state && decision.state === 'contact');
    if (
      inProgressTransitionalDecisions &&
      inProgressTransitionalDecisions.length > 0
    ) {
      return inProgressTransitionalDecisions.sort((a, b) => {
        if (a.created_at && b.created_at) {
          return a.created_at <= b.created_at ? -1 : 1;
        }
        return 0;
      })[0];
    }
    return {};
  }

  static latestFinalOrInvestorDecision(organization, client) {
    const finalDecisions = this.finalOrInvestorDecisions(organization, client);
    if (finalDecisions.length > 0) {
      return (
        finalDecisions
          .filter(
            (d) =>
              d.is_final ||
              (d.user_role === 'investor' && d.state === 'contact')
          )
          .sort((a, b) => b.created_at - a.created_at)[0] ||
        finalDecisions
          .filter((d) => d.user_role === 'investor')
          .sort((a, b) => b.created_at - a.created_at)[0]
      );
    }
    return {};
  }

  static getClientDecisions(organization, client) {
    return ((organization || {}).user_decisions || {})[client] || {};
  }

  static getMostRecentDecision(organization, client) {
    const clientDecisions = Organizations.getClientDecisions(
        organization,
        client
      ),
      clientDecisionsKeys = Object.keys(clientDecisions);

    if (clientDecisions && clientDecisionsKeys.length > 0) {
      let maxTimestamp = 0,
        mostRecentUserKey;
      for (let i = 0; i < clientDecisionsKeys.length; i++) {
        const userKey = clientDecisionsKeys[i],
          userDecision = clientDecisions[userKey];
        if (userDecision.created_at && userDecision.created_at > maxTimestamp) {
          maxTimestamp = userDecision.created_at;
          mostRecentUserKey = userKey;
        }
      }

      return mostRecentUserKey ? clientDecisions[mostRecentUserKey] : null;
    }

    return;
  }

  static getUserRole(decision, decisionClient, originClient) {
    if (decision) {
      if (!originClient || decisionClient === originClient) {
        return decision.is_final
          ? 'partners'
          : decision.user_role === 'investor'
            ? 'investors'
            : 'analysts';
      } else {
        return 'trusted_partners';
      }
    }

    return;
  }

  static getOrganizationState(organization, client) {
    const clientDecisions = (organization || {}).user_decisions || {} || {},
      finalDecision = this.latestFinalOrInvestorDecision(organization, client),
      resObject = Object.assign(finalDecision, {
        state: finalDecision ? finalDecision.state : null,
        fallback_to_call: finalDecision ? finalDecision.fallback_to_call : null,
        activity: {
          contact: {
            analysts: [],
            investors: [],
            partners: [],
            trusted_partners: []
          },
          missed_opportunity: {
            analysts: [],
            investors: [],
            partners: [],
            trusted_partners: []
          },
          not_now: {
            analysts: [],
            investors: [],
            partners: [],
            trusted_partners: []
          },
          never: {
            analysts: [],
            investors: [],
            partners: [],
            trusted_partners: []
          }
        }
      });

    Object.keys(clientDecisions).forEach((currentClient) => {
      const userDecisions = clientDecisions[currentClient];
      Object.keys(userDecisions).map((key) => {
        const decision = userDecisions[key],
          state =
            decision && decision.state !== 'unseen' ? decision.state : null,
          userObject = {
            user: decision.user_id,
            timestamp: decision.created_at,
            note: decision.notes,
            user_role: decision.user_role,
            form: decision.form
          };
        if (state) {
          const userRole = Organizations.getUserRole(
            decision,
            currentClient,
            client
          );
          resObject.activity[state][userRole].push(userObject);
        }
      });
    });
    return resObject;
  }

  static decisionContainsFormRequest(decisions) {
    if (
      decisions &&
      decisions.activity &&
      decisions.activity.contact &&
      decisions.activity.contact.analysts &&
      decisions.activity.contact.analysts.length > 0
    ) {
      return (
        decisions.activity.contact.analysts.filter(
          (analystContactActivity) => analystContactActivity.form === true
        ).length > 0
      );
    } else {
      return false;
    }
  }

  static getPersonState(organization, client, userId) {
    const organizationState = Organizations.getClientDecisions(
      organization,
      client
    );
    if (organizationState[userId]) {
      return organizationState[userId].state;
    }
    return;
  }

  static getOrganizationStatePerPerson(organization, client) {
    const clientDecisions = (organization || {}).user_decisions || {} || {},
      peopleActivity = [];

    Object.keys(clientDecisions).forEach((currentClient) => {
      const userDecisions = clientDecisions[currentClient],
        sameClient = client === currentClient;
      Object.keys(userDecisions).map((key) => {
        const decision = userDecisions[key],
          state =
            decision && decision.state !== 'unseen' ? decision.state : null,
          personActivity = {
            user: decision.user_id,
            timestamp: decision.created_at,
            note: decision.notes,
            type: 'evaluation',
            state,
            stage: decision.stage,
            status: decision.status
          };
        if (state) {
          personActivity.state = state;
          if (sameClient) {
            if (decision.is_final) {
              personActivity.role = 'partner';
            } else {
              personActivity.role = 'analyst';
            }
          } else {
            personActivity.role = 'trusted_partner';
          }
        }
        peopleActivity.push(personActivity);
      });
    });
    if (organization.inboxes) {
      const inbox = inboxHelper.getInbox(
        organization,
        client,
        'roberto@inreachventures.com'
      );
      if (inbox) {
        peopleActivity.push({
          user: inbox.user_id,
          timestamp: inbox.created_at,
          note: inbox.notes,
          type: 'inbox',
          reason: INBOX_REASONS[inbox.reason]
        });
      }
    }
    peopleActivity.sort((a, b) => {
      return b.timestamp - a.timestamp;
    });
    return peopleActivity;
  }

  static getInvestmentValues = (organization) => {
    return {
      investmentType: Organizations.organizationInvestmentType(organization),
      investmentStage: Organizations.organizationInvestmentStage(organization),
      revenueModels: Organizations.organizationRevenueModel(organization)
    };
  };

  static organizationInvestmentType = (organization) => {
    const {
      investment_type: investmentType = '',
      ml_features: {
        investment_type_text_clf: {transformed: mlInvestmentType = ''} = {}
      } = {}
    } = organization || {};
    return {
      type: investmentType !== '' ? investmentType : mlInvestmentType,
      ml: investmentType !== '' ? false : mlInvestmentType !== ''
    };
  };

  static organizationInvestmentStage = (organization) => {
    const {
      investment_stage: investmentStage = '',
      ml_features: {
        investment_stage: {transformed: mlInvestmentStage = ''} = {}
      } = {}
    } = organization || {};
    return {
      stage: investmentStage !== '' ? investmentStage : mlInvestmentStage,
      ml: investmentStage !== '' ? false : mlInvestmentStage !== ''
    };
  };

  static organizationRevenueModel = (organization) => {
    const {
      revenue_models: revenueModels = [],
      ml_features: {
        revenue_models: {transformed: mlRevenueModels = []} = {}
      } = {}
    } = organization || {};
    return {
      model: revenueModels !== [] ? revenueModels : mlRevenueModels,
      ml: revenueModels !== [] ? false : mlRevenueModels !== []
    };
  };

  static signalTrends = (organization) => {
    const {
      ml_features: {signal_trends: {transformed} = {}} = {}
    } = organization;
    if (!transformed) {
      return null;
    }

    return JSON.parse(transformed) || {};
  };

  static getTrend = (organization, type = 'web', id = false) => {
    const {trends: signalTrends = []} =
      Organizations.signalTrends(organization) || {};

    if (!signalTrends) {
      return null;
    }
    const trend = signalTrends.find(({key = ''}) => {
      if (type === 'web') {
        return key === 'similar_web-total_visit';
      }
      return key.split('-').pop() === id;
    });
    if (trend && trend.magnitude_percentile) {
      return trend;
    }

    const {trends} = organization;
    if (!trends) {
      return null;
    }

    const magnitudePercentile =
        trends[
          type === 'web'
            ? 'similar_web-total_visit-max_no_spike'
            : `sensortower-sensortower_downloads-max_no_spike-${id}`
        ],
      betaPercentile =
        trends[
          type === 'web'
            ? 'similar_web-total_visit-slope'
            : `sensortower-sensortower_downloads-slope-${id}`
        ];
    if (betaPercentile === undefined && magnitudePercentile === undefined) {
      return null;
    }

    return {
      beta_percentile: betaPercentile,
      magnitude_percentile: magnitudePercentile,
      key:
        type === 'web'
          ? 'similar_web-total_visit'
          : `sensortower-sensortower_downloads-${id}`
    };
  };

  static primaryTrend = (organization) => {
    const {trends: signalTrends = []} =
      Organizations.signalTrends(organization) || {};

    if (!signalTrends) {
      return null;
    }
    const primaryTrend = signalTrends.find((trend) => trend.primary);
    if (primaryTrend && primaryTrend.magnitude_percentile) {
      return primaryTrend;
    }

    const {trends} = organization;
    if (!trends) {
      return null;
    }

    const betaPercentile = trends['v2-trend-organization-beta'],
      magnitudePercentile = trends['v2-trend-organization-magnitude'];
    if (betaPercentile === undefined && magnitudePercentile === undefined) {
      return null;
    }

    let [magnitudeKey, value] = Object.entries(trends)
      .filter(([trend, value]) => !trend.startsWith('v2'))
      .find(
        ([trend, value]) => value === trends['v2-trend-organization-magnitude']
      ) || [null, null];

    if (magnitudeKey) {
      magnitudeKey = magnitudeKey
        .replace('-slope', '')
        .replace('-max_no_spike', '');
    }

    return {
      beta_percentile: betaPercentile,
      magnitude_percentile: magnitudePercentile,
      key: magnitudeKey
    };
  };

  static primaryTrendDataFromOrganizationOrSnapshot = (
    type,
    useSnapshot = false,
    organizationAndSnapshot
  ) => {
    const {
      [`${type}_percentile`]: trendType,
      covid_filtered: covidFiltered
    } = Organizations.primaryTrendFromOrganizationOrSnapshot(
      useSnapshot,
      organizationAndSnapshot
    );
    return {
      value: trendType,
      covidFiltered: covidFiltered || false,
      highlighted:
        Math.round(Math.max(Math.min(trendType, 1), 0) * 100) >=
        (type === 'beta' ? 66 : 91)
    };
  };

  static primaryTrendFromOrganizationOrSnapshot = (
    useSnapshot = false,
    {
      organization = {},
      snapshot: {organization: snapshotOrganization = {}} = {}
    }
  ) => {
    return useSnapshot
      ? Organizations.primaryTrend(snapshotOrganization) || {}
      : Organizations.primaryTrend(organization) || {};
  };

  static keyIsMobile(key) {
    if (key) {
      return key.includes('priori') || key.includes('sensortower');
    }
    return false;
  }

  static primaryTrendTypeFromOrganizationOrSnapshot = (
    useSnapshot = false,
    organizationAndSnapshot
  ) => {
    const {key} =
      Organizations.primaryTrendFromOrganizationOrSnapshot(
        useSnapshot,
        organizationAndSnapshot
      ) || {};

    if (Organizations.keyIsMobile(key)) {
      return 'app';
    }
    return 'web';
  };

  static primaryTrendAppIdFromOrganizationOrSnapshot = (
    useSnapshot = false,
    organizationAndSnapshot
  ) => {
    const {key} =
      Organizations.primaryTrendFromOrganizationOrSnapshot(
        useSnapshot,
        organizationAndSnapshot
      ) || {};
    if (key && Organizations.keyIsMobile(key)) {
      return key.split('-').pop();
    }
    return null;
  };

  static primarySignals = (organization, client, signals, startAt) => {
    const {
      ml_features: {
        organization_trend: {transformed: mlSignals = '[]'} = {}
      } = {}
    } = organization;
    const allSignals = signals
        ? signals
        : _.sortBy(JSON.parse(mlSignals), 'timestamp'),
      {created_at: decisionMadeAt} =
        Organizations.latestFinalOrInvestorDecision(organization, client) || {},
      now = new Date().getTime();

    let {window_start: windowStart, window_end: windowEnd} =
      Organizations.primaryTrend(organization) || {};

    if (!windowEnd) {
      const {created_at: mostRecentTimestamp = now} = _.last(mlSignals) || {};
      windowEnd = mostRecentTimestamp;
    }

    if (!windowStart) {
      windowStart = windowEnd - ONE_YEAR_MILLIS;
    }

    if (startAt) {
      const beforeStart = startAt - ONE_YEAR_MILLIS;
      if (beforeStart < windowStart) {
        windowStart = beforeStart;
      }
    } else if (decisionMadeAt) {
      const beforeDecision = decisionMadeAt - ONE_YEAR_MILLIS;
      if (beforeDecision < windowStart) {
        windowStart = beforeDecision;
      }
    }

    return allSignals.filter(
      ({timestamp = 0}) => timestamp >= windowStart && timestamp <= windowEnd
    );
  };
}
