import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import Bio from './Bio';
import EducationExperience from './EducationExperience';
import ToggleShowMoreButton from '../../ToggleShowMoreButton';

class FullProfile extends React.Component {
  static propTypes = {
    bio: PropTypes.string.isRequired,
    education: PropTypes.array.isRequired,
    experience: PropTypes.array.isRequired,
    showFullProfileByDefault: PropTypes.bool.isRequired,
    numberHiddenInfo: PropTypes.number.isRequired,
    toggleFullProfileClickHandler: PropTypes.func.isRequired
  };

  render() {
    const {
      bio = '',
      education = [],
      experience = [],
      showFullProfileByDefault,
      numberHiddenInfo,
      toggleFullProfileClickHandler
    } = this.props;
    return (
      <div className="full-profile-container">
        <div className="section-title">Full Profile</div>
        <If condition={!showFullProfileByDefault}>
          <div className="full-profile-hide-button">
            <ToggleShowMoreButton
              text={`Hide Full Profile (${numberHiddenInfo})`}
              clickHandler={toggleFullProfileClickHandler}
              show={false}
            />
          </div>
        </If>
        <Bio bio={bio} />
        <EducationExperience content={experience} type="experience" />
        <EducationExperience content={education} type="education" />
      </div>
    );
  }
}

export default withRouter(FullProfile);
