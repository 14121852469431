export function getCurrentCaretPosition(targetClass) {
  const target = document.getElementsByClassName(targetClass)[0];
  // for contentedit field
  if (target.contentEditable) {
    target.focus();
    const _range = document.getSelection().getRangeAt(0);
    const range = _range.cloneRange();
    range.selectNodeContents(target);
    range.setEnd(_range.endContainer, _range.endOffset);
    return range.toString().length;
  }
  // for texterea/input element
  return target.selectionStart;
}

export function setCaretPosition(node) {
  if (typeof node === 'undefined') {
    return 0;
  }
  const selection = window.getSelection();
  const range = document.createRange();
  range.setStart(node, 1);
  range.collapse(true);
  selection.removeAllRanges();
  selection.addRange(range);
}
