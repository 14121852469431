import PropTypes from 'prop-types';
import React from 'react';

class ExternalLink extends React.Component {
  render() {
    const to = this.props.to;
    if (!to) {
      return <span />;
    }

    return (
      <a href={to} target="new_page">
        {to}
      </a>
    );
  }
}

ExternalLink.propTypes = {
  to: PropTypes.string
};

export default ExternalLink;
