export default [
  '3d printing',
  '3d',
  'accounting',
  'advertising',
  'aerospace',
  'agriculture',
  'analytics',
  'architecture',
  'art',
  'artificial intelligence',
  'augmented reality',
  'automotive',
  'b2b',
  'b2c',
  'beauty',
  'biotechnology',
  'business intelligence',
  'charity',
  'chemicals',
  'clean technology',
  'cloud',
  'collaboration',
  'commercial',
  'communities',
  'computer',
  'construction',
  'consulting',
  'consumer',
  'consumer electronics',
  'content',
  'coworking',
  'crm',
  'crowdfunding',
  'crowdsourcing',
  'cryptocurrency',
  'customer service',
  'cyber security',
  'data visualization',
  'dating',
  'deep tech',
  'design',
  'drones',
  'e-commerce',
  'education',
  'energy',
  'entreprise',
  'events',
  'fashion',
  'finance',
  'fintech',
  'fitness',
  'food and beverages',
  'freelancing',
  'gambling',
  'gaming',
  'geolocalization',
  'hardware',
  'health',
  'home',
  'hospitality',
  'human resources',
  'infrastructure',
  'innovation',
  'insurance',
  'internet of things',
  'kids',
  'language',
  'legal',
  'leisure',
  'lifestyle',
  'logistics',
  'luxury',
  'manufacturing',
  'marketing',
  'marketplaces',
  'media and entertainment',
  'messaging',
  'mining',
  'mobile apps',
  'music',
  'news',
  'non profit',
  'open source',
  'packaging',
  'payments',
  'peer to peer',
  'pharmaceuticals',
  'photography',
  'printing',
  'productivity',
  'professional services',
  'project management',
  'public relations',
  'publishing',
  'real estate',
  'restaurants',
  'retail',
  'robotics',
  'saas',
  'sales',
  'search',
  'security',
  'seo',
  'service industry',
  'sharing economy',
  'shipping',
  'shopping',
  'smart city',
  'social network',
  'software development',
  'sports',
  'streaming',
  'subscriptions',
  'sustainability',
  'telecommunications',
  'transportation',
  'travel',
  'user experience',
  'venture capital',
  'video',
  'virtual reality',
  'waste management',
  'wearables',
  'web hosting',
  'wireless'
];
