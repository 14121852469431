import React from 'react';
import PropTypes from 'prop-types';

import EditOrganizationLocation from './EditOrganizationLocation';

import AddCircleOutline from 'material-ui/svg-icons/content/add-circle';

import _ from 'underscore';

export default class EditOrganizationLocations extends React.Component {
  static propTypes = {
    locations: PropTypes.array.isRequired,
    updateOrganizationFields: PropTypes.func.isRequired,
    source: PropTypes.string
  };

  state = {
    locations: []
  };

  componentWillMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.locations, this.props.locations)) {
      this.initialize();
    }
  }

  initialize = () => {
    this.setState({
      locations: this.props.locations
    });
  };

  handleAddLocation = () => {
    const {locations = []} = this.state;
    locations.push({
      city: '',
      country: '',
      continent: '',
      is_hq: false
    });
    this.setState({
      locations
    });
  };

  handleLocationChange = (index, location) => {
    const {locations = []} = this.state;
    const newLocations = [...locations];
    newLocations[index] = location;
    this.props.updateOrganizationFields([
      {field: 'locations', value: newLocations}
    ]);
  };

  handleDeleteLocation = (index) => {
    const {locations = []} = this.state;
    const locationsDuplicate = [...locations];
    locationsDuplicate.splice(index, 1);
    this.props.updateOrganizationFields([
      {field: 'locations', value: locationsDuplicate}
    ]);
  };

  checkAtLeastOneHQ = () => {
    const {locations} = this.state;
    return locations.some((location) => location.is_hq);
  };

  render() {
    const {source = 'edit-incomplete'} = this.props;
    const {locations = []} = this.state;
    return (
      <div className="edit-organization-locations">
        <If condition={source === 'edit-incomplete'}>
          <div className="edit-section-header">LOCATIONS</div>
        </If>
        <If condition={locations.length > 1 && !this.checkAtLeastOneHQ()}>
          <p className="edit-organization-locations-at-least-one-hq">
            At least one location should be set as HQ.
          </p>
        </If>
        <If condition={locations.length > 0}>
          {locations.map((location, index) => (
            <EditOrganizationLocation
              key={index}
              location={location}
              index={index}
              handleLocationChange={this.handleLocationChange}
              handleDeleteLocation={this.handleDeleteLocation}
              source={source}
            />
          ))}
        </If>
        <div
          className="add-location edit-organization-add-button"
          onClick={this.handleAddLocation}
        >
          <AddCircleOutline color={'#5DAB49'} className="add-icon" />
          <div className="add-text">ADD LOCATION</div>
        </div>
      </div>
    );
  }
}
