import React from 'react';
import PropTypes from 'prop-types';

import WorkflowInformationBody from './WorkflowInformationBody';
import Card from '../../../card/Card';
import CardBody from '../../../card/CardBody';
import CardHeader from '../../../card/CardHeader';

export default class WorkflowInformation extends React.Component {
  render() {
    return (
      <div className="workflow-information">
        <Card>
          <CardHeader title="Information" />
          <CardBody>
            <WorkflowInformationBody {...this.props} />
          </CardBody>
        </Card>
      </div>
    );
  }
}
