import React from 'react';
import {Card, CardActions, CardHeader, CardText} from 'material-ui/Card';
import {Link} from 'react-router';

import Highlights from '../Highlights';
import MergeDecision from './MergeDecision';
import SocialLink from '../SocialLink';
import SourcesCell from '../SourcesCell';

export default class OrganizationSummary extends React.Component {
  render() {
    const {organization} = this.props;
    const {
      actions,
      children,
      ml_features: {highlights: {highlights} = {}} = {},
      id,
      image_url,
      name,
      short_description,
      homepage_url,
      source_refs = {}
    } = organization;

    return (
      <Card>
        <Link to={`/organizations/${id}`}>
          <CardHeader
            title={
              <h4>
                {name}
                <br />
                <small>id: {id}</small>
              </h4>
            }
            avatar={image_url}
            subtitle={short_description}
          />
        </Link>
        <CardText>
          <div style={{float: 'left'}}>
            <If condition={homepage_url}>
              <SocialLink source="website" link={homepage_url} />
            </If>
          </div>
          <div style={{float: 'right'}}>
            <If condition={source_refs}>
              <SourcesCell organization={organization} horizontal={true} />
            </If>
          </div>
        </CardText>
        <CardText style={{padding: 0}}>
          <MergeDecision organization={organization} />
        </CardText>
        <CardText>
          <Highlights data={highlights} />
        </CardText>
        {children}
      </Card>
    );
  }
}
