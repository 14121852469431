import React from 'react';

import ContentInbox from 'material-ui/svg-icons/content/inbox';
import CommunicationComment from 'material-ui/svg-icons/communication/comment';
import ActionThumbsUpDown from 'material-ui/svg-icons/action/thumbs-up-down';
import ActionAutoRenew from 'material-ui/svg-icons/action/autorenew';
import ToggleStar from 'material-ui/svg-icons/toggle/star';
import AVFiberNew from 'material-ui/svg-icons/av/fiber-new';
import Euro from 'material-ui/svg-icons/action/euro-symbol';
import ContentDeleteSweep from 'material-ui/svg-icons/content/delete-sweep';
import EditorHighlight from 'material-ui/svg-icons/editor/highlight';
import SocialWhatshot from 'material-ui/svg-icons/social/whatshot';
import SocialSentimentVeryDissatisfied from 'material-ui/svg-icons/social/sentiment-very-dissatisfied';
import CommunicationImportExport from 'material-ui/svg-icons/communication/import-export';
import SMSFailed from 'material-ui/svg-icons/notification/sms-failed';
import MapsMap from 'material-ui/svg-icons/maps/map';
import Phone from 'material-ui/svg-icons/communication/phone';
import Alarm from 'material-ui/svg-icons/action/alarm';
import Assignement from 'material-ui/svg-icons/action/assignment';
import ScheduleIcon from 'material-ui/svg-icons/communication/contact-phone';
import InvestorsIcon from 'material-ui/svg-icons/action/perm-identity';
import Account from 'material-ui/svg-icons/action/account-balance';
import AlertAddAlert from 'material-ui/svg-icons/alert/add-alert';

export const reasons = [
  {
    reason: 'inbox',
    title: 'Inbox',
    icon: <ContentInbox />,
    filters: [],
    experiments: [],
    ignore_experiments: []
  },
  {
    reason: 'starred',
    title: 'Review',
    icon: <ToggleStar />,
    filters: [
      'is_this_interesting',
      'discover',
      'review',
      'review_form',
      'create'
    ],
    experiments: [],
    ignore_experiments: []
  },
  {
    reason: 'form',
    title: 'Form',
    icon: <CommunicationComment />,
    filters: ['form_partial', 'form_updated', 'form'],
    experiments: [],
    ignore_experiments: []
  },
  {
    reason: 'reconnect',
    title: 'Reconnect',
    icon: <Alarm />,
    filters: ['reconnect'],
    experiments: [],
    ignore_experiments: []
  },
  {
    reason: 'call_feedback',
    title: 'Recommendation Call',
    icon: <Phone />,
    filters: ['call_feedback'],
    experiments: [],
    ignore_experiments: []
  },
  {
    reason: 'spam',
    title: 'Quality Check',
    icon: <ActionThumbsUpDown />,
    filters: ['is_this_spam'],
    experiments: [],
    ignore_experiments: []
  },
  {
    reason: 'dig_machine_learning',
    title: 'DIG Machine Learning',
    icon: <SocialWhatshot />,
    filters: ['dig_ml'],
    experiments: [],
    ignore_experiments: []
  },
  {
    reason: 'interesting_investors',
    title: 'Interesting Investors',
    icon: <InvestorsIcon />,
    filters: ['interesting_investors'],
    experiments: [],
    ignore_experiments: []
  },
  {
    reason: 'dig_machine_learning_updated',
    title: 'DIG ML Updated',
    icon: <ActionAutoRenew />,
    filters: ['dig_ml_updated'],
    experiments: [],
    ignore_experiments: []
  },
  {
    reason: 'ml_experiments',
    title: 'ML Experiments',
    icon: <Assignement />,
    filters: ['ml_experiments'],
    experiments: [],
    ignore_experiments: []
  },
  {
    reason: 'alert',
    title: 'Alerts',
    icon: <AlertAddAlert />,
    filters: ['alert'],
    experiments: [],
    ignore_experiments: []
  },
  {
    reason: 'pre_qualification',
    title: 'Pre Qualification',
    icon: <CommunicationImportExport />,
    filters: ['pre_qualification'],
    experiments: [],
    ignore_experiments: []
  },
  {
    reason: 'qualification',
    title: 'Qualification',
    icon: <CommunicationImportExport />,
    filters: ['qualification'],
    experiments: [],
    ignore_experiments: []
  },
  {
    reason: 'unable_to_contact',
    title: 'Unable to Contact',
    icon: <SMSFailed />,
    filters: ['unable_to_contact'],
    experiments: [],
    ignore_experiments: []
  },
  {
    reason: 'unable_to_reconnect',
    title: 'Unable to Reconnect',
    icon: <SMSFailed />,
    filters: ['unable_to_reconnect'],
    experiments: [],
    ignore_experiments: []
  },
  {
    reason: 'schedule_call',
    title: 'Schedule Call',
    icon: <ScheduleIcon />,
    filters: ['schedule_call'],
    experiments: [],
    ignore_experiments: []
  },
  {
    reason: 'social_messages',
    title: 'Social Messages',
    icon: <div className="social-link linkedin" style={{fontSize: '24px'}} />,
    filters: ['social_messages'],
    experiments: [],
    ignore_experiments: []
  },
  {
    reason: 'funded',
    title: 'Funded',
    icon: <Euro />,
    filters: ['funded'],
    experiments: [],
    ignore_experiments: []
  },
  {
    reason: 'missed_opportunity',
    title: 'Missed Opportunity',
    icon: <SocialSentimentVeryDissatisfied />,
    filters: ['missed_opportunity'],
    experiments: [],
    ignore_experiments: []
  },
  {
    reason: 'disagreement',
    title: 'Feedback',
    icon: <CommunicationImportExport />,
    filters: ['disagreement', 'feedback'],
    experiments: [],
    ignore_experiments: []
  },
  {
    reason: 'no_show',
    title: 'No Show',
    icon: <ContentDeleteSweep />,
    filters: ['no_show'],
    experiments: [],
    ignore_experiments: []
  }
];
