import React from 'react';
import PropTypes from 'prop-types';

import DisplayReason from './DisplayReason';

import INREACH_TEAM from '../../config/inreach_team';
import STREAK_STAGES from '../../config/streak_stages';

import ActionSearch from 'material-ui/svg-icons/action/search';

const getUserName = (user_id) =>
  INREACH_TEAM.find(({email}) => email === user_id).name;

const getStageName = (stage) =>
  STREAK_STAGES.find(({key}) => key === stage).name;

export default class OrganizationStage extends React.Component {
  static propTypes = {
    decision: PropTypes.object.isRequired,
    showStage: PropTypes.bool,
    compressed: PropTypes.bool
  };

  state = {
    showStageReason: false
  };

  toggleShowStageReason = (show = !this.state.show) => {
    this.setState({
      showStageReason: show
    });
  };

  getStageContainerClassName = () => {
    const {decision: {stage} = {}, compressed = false} = this.props;
    const {showStageReason = false} = this.state;
    const classNames = ['stage-container'];
    if (stage === 'passed' || stage === 'in_tracking') {
      classNames.push('reason-available');
    }
    if (showStageReason) {
      classNames.push('show-reason');
    }
    if (compressed) {
      classNames.push('compressed');
    }
    return classNames.join(' ');
  };

  shouldShowReason = () => {
    const {decision: {stage, notes} = {}} = this.props;
    return (stage === 'passed' || stage === 'in_tracking') && notes;
  };

  getOrganizationStageClassNames = () => {
    const {showStage = true} = this.props;
    const classNames = ['organization-stage'];
    if (this.shouldShowReason()) {
      classNames.push('reason-showing');
    }
    if (showStage) {
      classNames.push('stage-showing');
    }
    return classNames.join(' ');
  };

  render() {
    const {
      decision: {notes, reasons = {}, stage, user_id} = {},
      showStage = true,
      compressed = false
    } = this.props;
    return (
      <div
        className={this.getStageContainerClassName()}
        onMouseEnter={() => {
          if (stage === 'passed' || stage === 'in_tracking') {
            this.toggleShowStageReason(true);
          }
        }}
        onMouseLeave={() => {
          if (stage === 'passed' || stage === 'in_tracking') {
            this.toggleShowStageReason(false);
          }
        }}
      >
        <If condition={!compressed || window.innerWidth >= 1280}>
          <div className="stage-title">Stage</div>
        </If>
        <div className="stage-text">
          {STREAK_STAGES.find(({key}) => key === stage).name}
        </div>
        <If condition={stage === 'passed' || stage === 'in_tracking'}>
          <ActionSearch className="stage-reason-indicator" />
          <div className="stage-reason-container">
            <div className={this.getOrganizationStageClassNames()}>
              <If condition={showStage}>
                <div className="user-stage">
                  {/*{getUserName(user_id)}: */}
                  {getStageName(stage)}
                </div>
              </If>
              <If condition={this.shouldShowReason()}>
                <div className="reason">
                  <DisplayReason reason={notes} structuredReasons={reasons} />
                </div>
              </If>
            </div>
          </div>
        </If>
      </div>
    );
  }
}
