import React from 'react';
import PropTypes from 'prop-types';

import imageUrl from '../../../lib/imageUrl';

export default class PreviewOrganizationAvatar extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired
  };

  render() {
    const {organization: {image_url: avatar} = {}} = this.props;
    return (
      <div
        className="preview-organization--header-avatar"
        style={{backgroundImage: `url(${imageUrl({url: avatar})})`}}
      />
    );
  }
}
