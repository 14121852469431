import SearchParamsHelper from '../lib/SearchParamsHelper';

import request from 'superagent';
import moment from 'moment';
import auth from './Auth';
import Config from '../config';
import _ from 'underscore';

const convertParamsObjToString = (params) => {
  if (typeof params !== 'object') {
    return '';
  }
  return Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
};

export const OrganizationsStore = function() {
  this.cache = [];
  this.size = null;
  this.loading = false;
  this.nextOffset = 0;
  this.searchParams = {};

  this.abortController = null;
  this.abortSignal = null;

  this.initialize = function(options = {}) {
    if (
      !SearchParamsHelper.arePropsEqual(
        options.searchParams,
        this.searchParams
      ) ||
      this.nextOffset < options.offset
    ) {
      this.cache = options.cache || [];
      this.size = this.cache.length > 0 ? this.cache.length : null;
      this.searchParams = options.searchParams || {};
      this.nextOffset = options.offset || 0;
      this.loading = false;
    }
  }.bind(this);

  this.generateParams = (idToken, client, offset, limit) => {
    const orderBy =
      this.searchParams.order_by ||
      (this.searchParams.query ||
        this.searchParams.name ||
        this.searchParams.terms)
        ? 'score'
        : 'founded_on';
    const newParams = _.extend(
      {
        access_token: idToken,
        client: client,
        limit: limit,
        offset: offset,
        order_by: orderBy
      },
      _.omit(this.searchParams, 'offset', 'filter_set_name')
    );

    if (newParams.order_by === 'founded_on' && !newParams.founded_on_before) {
      newParams.founded_on_before = moment().valueOf();
    }

    return newParams;
  };

  this.fetch = function(offset) {
    let limit = 30;
    if (this.abortController) {
      this.abortController.abort();
    }
    if (this.cache[offset] && this.cache[this.nextOffset - 1]) {
      return Promise.resolve(this.nextOffset);
    }
    if (this.nextOffset > offset) {
      limit = this.nextOffset + limit;
    }
    this.nextOffset = offset + limit;
    if (this.cache[this.nextOffset - 1]) {
      return Promise.resolve(this.nextOffset);
    }
    if (this.size && this.size <= offset) {
      return Promise.resolve(offset);
    }
    this.loading = true;
    if ('AbortController' in window) {
      this.abortController = new AbortController();
      this.abortSignal = this.abortController.signal;
    }
    return auth.getAuthData().then((authData) =>
      fetch(
        `${Config.api.host}/organizations?${convertParamsObjToString(
          this.generateParams(authData.token, authData.client, offset, limit)
        )}`,
        {signal: this.abortSignal}
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Bad network response. Status: ${response.status}`);
          }
          return response.json();
        })
        .then(({organizations = [], total}) => {
          organizations.forEach((organization, index) => {
            this.cache[offset + index] = organization;
          });
          this.size = total;
          this.loading = false;
          return this.nextOffset;
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
          return error;
        })
    );
  }.bind(this);

  this.fetchAll = (offset = 0) => {
    return this.fetch(offset).then((nextOffset) => {
      if (nextOffset < this.size) {
        return this.fetchAll(nextOffset);
      }
      return Promise.resolve(nextOffset);
    });
  };

  this.isLoading = function isLoading() {
    return this.loading;
  }.bind(this);

  this.replaceModel = function replaceModel(model) {
    for (let i = 0; i < this.cache.length; i++) {
      if (this.cache[i].id === model.id) {
        this.cache[i] = model;
        break;
      }
    }

    return this.cache;
  }.bind(this);

  this.removeModel = function removeModel(id) {
    this.cache = this.cache.filter((o) => o.id !== id);
    this.size = this.cache.length;

    return this.cache;
  }.bind(this);

  this.getAll = function getAll() {
    return this.cache;
  }.bind(this);

  this.getSize = function getSize() {
    return this.size === undefined ? 0 : this.size;
  }.bind(this);

  this.getInboxSize = () => {
    return new Promise((resolve, reject) => {
      auth.getAuthData().then(
        function(authData) {
          request
            .get(Config.api.host + '/organizations')
            .query({
              access_token: authData.token,
              client: authData.client,
              user_inbox: authData.profile.email,
              inbox: true,
              limit: 0
            })
            .end(
              function(error, response) {
                if (response && response.ok) {
                  resolve(response.body.total);
                } else {
                  reject();
                }
              }.bind(this)
            );
        }.bind(this)
      );
    });
  };

  this.getInboxSizePerReason = (params = {}) => {
    return auth.getAuthData().then((authData) =>
      request
        .get(Config.api.host + '/inbox/reasons')
        .query({
          access_token: authData.token,
          client: authData.client,
          ...params
        })
        .then((response) => (response.ok ? response.body.reasons : {}))
    );
  };

  this.exportToCSV = (limit) => {
    return auth.getAuthData().then((authData) =>
      request
        .get(Config.api.host + '/organizations')
        .set('Accept', 'text/csv')
        .query(this.generateParams(authData.token, authData.client, 0, limit))
    );
  };

  return this;
};

export default new OrganizationsStore();
