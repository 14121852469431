import React from 'react';
import PropTypes from 'prop-types';

import NavigationArrowDownward from 'material-ui/svg-icons/navigation/arrow-downward';
import Computer from 'material-ui/svg-icons/hardware/computer';
import PhoneIPhone from 'material-ui/svg-icons/hardware/phone-iphone';
import Tooltip from '../../Tooltip';

export default class TrendSummary extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    trend: PropTypes.object.isRequired
  };

  state = {
    showTooltip: false
  };

  getTooltipText = () => {
    const {type} = this.props;
    if (type === 'web') {
      return 'Website trend';
    } else if (type === 'app') {
      return 'Mobile app trend';
    }
  };

  getTrendTextClassNames = () => {
    const {trend: {magnitude: {highlighted = false} = {}} = {}} = this.props;
    const classNames = ['data-content', 'text-component'];
    if (highlighted) {
      classNames.push('highlighted-text');
    }
    return classNames.join(' ');
  };

  getTrendText = () => {
    const {trend: {magnitude: {value} = {}} = {}} = this.props;
    const newMagnitude = Math.round(value * 100) || null;
    if (!newMagnitude) {
      return 'No data';
    } else if (newMagnitude < 72) {
      return 'Small';
    } else if (newMagnitude < 91) {
      return 'Medium';
    } else if (newMagnitude < 98) {
      return 'Large';
    } else {
      return 'Very Large';
    }
  };

  getIconClassNames = () => {
    const {trend: {beta: {highlighted = false} = {}} = {}} = this.props;
    const classNames = ['icon trend'];
    if (highlighted) {
      classNames.push('high');
    }
    return classNames.join(' ');
  };

  getTrendArrowRotation = () => {
    const {trend: {beta: {value: beta} = {}} = {}} = this.props;
    const multiplier = 1.6;
    return Math.round(beta * 100) * multiplier;
  };

  render() {
    const {type, trend: {magnitude: {value} = {}} = {}} = this.props;
    const {showTooltip = false} = this.state;
    return (
      <div
        className="data-wrapper compressed"
        onMouseLeave={() => {
          this.setState({showTooltip: false});
        }}
        onMouseEnter={() => {
          this.setState({showTooltip: true});
        }}
        ref={(el) => (this._parent = el)}
      >
        <Tooltip
          parent={this._parent}
          showTooltip={showTooltip}
          text={this.getTooltipText()}
        />
        <div className="data-icon">
          <Choose>
            <When condition={type === 'web'}>
              <Computer className="data-icon-svg" />
            </When>
            <Otherwise>
              {/* <PhoneIPhone className="data-icon-svg" /> */}
            </Otherwise>
          </Choose>
        </div>
        <Choose>
          <When condition={!(Math.round(value * 100) || null)}>
            <div className="data-content text-component de-emphasised-text">
              No data
            </div>
          </When>
          <Otherwise>
            <div className={this.getTrendTextClassNames()}>
              {this.getTrendText()}
              <div className="icon-component trend">
                <div className={this.getIconClassNames()}>
                  <NavigationArrowDownward
                    style={{
                      transform: `rotateZ(-${this.getTrendArrowRotation()}deg)`
                    }}
                  />
                </div>
              </div>
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}
