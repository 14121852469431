import request from 'superagent';
import auth from './Auth';
import Config from '../config';

function handleResponse(response, isPublic) {
  if (response && response.ok) {
    return (isPublic ? [response.body] : response.body.notes)
      .map((clientNote) =>
        Object.assign(
          {},
          {[clientNote.client]: clientNote.notes},
          {created_at: clientNote.created_at, updated_at: clientNote.updated_at}
        )
      )
      .reduce((acc, current) => Object.assign(acc, current), {});
  }

  throw new Error('Error getting notes', response.body);
}

export default class NotesStore {
  constructor(id) {
    this.url = `${Config.api.host}/organizations/${id}/notes`;
  }

  getNotes(expiration, signature, showNotes) {
    return auth
      .getAuthData()
      .then(({token, client}) =>
        request.get(`${this.url}/all`).query({
          access_token: token,
          client: client
        })
      )
      .then((response) => handleResponse(response))
      .catch((error) => {
        console.error(error);

        return request
          .get(this.url)
          .query({
            e: expiration,
            s: signature,
            n: !!showNotes
          })
          .then((response) => handleResponse(response, true));
      });
  }
}
