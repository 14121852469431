import request from 'superagent';
import auth from './Auth';
import Config from '../config';

function handleResponse(response) {
  if (response && response.ok) {
    return response.body.scores;
  }

  throw new Error('Error getting scores', response.body);
}

export default class ScoresStore {
  constructor(id) {
    this.url = `${Config.api.host}/organizations/${id}/scores`;
  }

  getScores(expiration, signature, showNotes) {
    return auth
      .getAuthData()
      .then(({token, client}) =>
        request.get(`${this.url}/all`).query({
          access_token: token,
          client: client
        })
      )
      .then(handleResponse)
      .catch((error) => {
        console.error(error);

        return request
          .get(this.url)
          .query({
            e: expiration,
            s: signature,
            n: !!showNotes
          })
          .then(handleResponse);
      });
  }

  setScores(notes) {
    return auth.getAuthData().then(({token, client}) =>
      request
        .put(this.url + '/nope')
        .send(notes)
        .query({
          access_token: token,
          client: client
        })
        .then((response) => {
          if (response && response.ok) {
            return response;
          }

          throw new Error('Error setting scores', response.body);
        })
    );
  }
}
