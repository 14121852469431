import React from 'react';
import PropTypes from 'prop-types';

import auth from '../storage/Auth';
import TemporaryUserHelper from '../lib/TemporaryUserHelper';

import routesRequiringFullAccess from '../content/routesRequiringFullAccess';

function checkURLAuthData(pathname, query) {
  if (query.e && query.s) {
    const [, resourceName, resourceId] = pathname.split('/');
    return TemporaryUserHelper.authDataValid(
      query.e,
      query.s,
      query.n,
      resourceName,
      resourceId
    );
  } else {
    return Promise.reject();
  }
}

export default class Gatekeeper extends React.Component {
  static propTypes = {
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired
    }).isRequired
  };

  state = {
    isAuthenticated: false
  };

  authenticateRoute(location) {
    return auth
      .getAuthData()
      .then(({token, accessType}) => {
        if (token) {
          if (
            routesRequiringFullAccess.includes(location.pathname) &&
            accessType !== 'full'
          ) {
            throw new Error('Insufficient accessType for this route');
          }
        } else {
          throw new Error('No access token');
        }
        return true;
      })
      .catch((error) => {
        if (location.query.e && location.query.s) {
          return checkURLAuthData(location.pathname, location.query);
        }
        throw error;
      })
      .then((isAuthenticated) => {
        this.setState({isAuthenticated});
      })
      .catch((error) => {
        console.error('Not allowing access', location, error);
        this.props.router.push('/login');
      });
  }

  componentDidMount() {
    this.authenticateRoute(this.props.location, this.props.router);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.authenticateRoute(nextProps.location);
    }
  }

  render() {
    return this.state.isAuthenticated ? this.props.children : null;
  }
}
