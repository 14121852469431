import React from 'react';
import {List, ListItem} from 'material-ui/List';
import Subheader from 'material-ui/Subheader';

import auth from '../../../storage/Auth';
import Organizations from '../../../lib/Organizations';
import digStates from '../../../content/digStates';
import streakStages from '../../../config/streak_stages';

const streakStage = (stage) => streakStages.find((s) => s.key == stage);

export default class MergeDecision extends React.Component {
  state = {client: null};

  componentDidMount() {
    auth.getAuthData().then(({client}) => this.setState({client}));
  }

  render() {
    const {client} = this.state;

    if (!client) {
      return null;
    }

    const finalDecision = Organizations.latestFinalOrInvestorDecision(
      this.props.organization,
      client
    );

    if (!finalDecision) {
      return null;
    }

    const stage = streakStage(finalDecision.stage);

    return (
      <List>
        <ListItem
          className={`MergeOrganizations--decision ${finalDecision.state}`}
          primaryText={digStates[finalDecision.state]}
          secondaryText={stage ? stage.name : null}
          disabled={true}
        />
      </List>
    );
  }
}
