import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import moment from 'moment';

import {capitalizeString} from '../../../../lib/helpers';

import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from 'material-ui/Table';

import Loading from '../../../loading/Loading';
import SocialLink from '../../SocialLink';

class InvestorsTable extends React.Component {
  static propTypes = {
    fundingRounds: PropTypes.array.isRequired,
    fundingRoundsLoading: PropTypes.bool.isRequired,
    investors: PropTypes.array.isRequired,
    investorsLoading: PropTypes.bool.isRequired
  };

  isInvestorLeadInvestor = (investorId) => {
    const {fundingRounds = []} = this.props;
    for (const {participations = []} of fundingRounds) {
      if (participations.length > 0) {
        for (const participation of participations) {
          if (participation.investor_id === investorId) {
            return participation.is_lead || false;
          }
        }
      }
    }
    return false;
  };

  getFundingRounds = (investorId) => {
    const {fundingRounds} = this.props;
    const fundingRoundStrings = [];
    for (const {participations = [], funding_type, series} of fundingRounds) {
      if (
        participations.find(
          ({investor_id: participationInvestorId}) =>
            participationInvestorId === investorId
        )
      ) {
        fundingRoundStrings.push(
          funding_type === 'venture' && !!series
            ? `Series ${capitalizeString(series)}`
            : capitalizeString(funding_type.replace(/_/g, ' '))
        );
      }
    }
    if (fundingRoundStrings.length === 0) {
      return '-';
    }
    return fundingRoundStrings.join(', ');
  };

  getFundingRoundsString = (fundingRoundNames = []) => {
    if (fundingRoundNames.length === 0) {
      return '-';
    }
    return fundingRoundNames
      .map((fundingRound) => fundingRound.replace('_', ' '))
      .join(', ');
  };

  render() {
    const {investors, investorsLoading} = this.props;
    return (
      <div className="data-wrapper investors-table-component">
        <div className="data-header">Investors</div>
        <Choose>
          <When condition={investorsLoading}>
            <Loading small={true} align="left" />
          </When>
          <Otherwise>
            <Choose>
              <When condition={!investorsLoading && investors.length === 0}>
                <div className="data-content text empty">No investors data</div>
              </When>
              <Otherwise>
                <div className="data-content component">
                  <Table
                    className="funding-table investors-table"
                    selectable={false}
                  >
                    <TableHeader
                      adjustForCheckbox={false}
                      displaySelectAll={false}
                      enableSelectAll={false}
                      className="funding-table-header investors-table-header"
                    >
                      <TableRow className="funding-table-row investors-table-row">
                        <TableHeaderColumn className="funding-table-column investors-table-column">
                          Investor Name
                        </TableHeaderColumn>
                        <TableHeaderColumn className="funding-table-column investors-table-column">
                          Lead Investor
                        </TableHeaderColumn>
                        <TableHeaderColumn className="funding-table-column investors-table-column funding-rounds">
                          Funding Round
                        </TableHeaderColumn>
                        <TableHeaderColumn className="funding-table-column investors-table-column">
                          Announced on
                        </TableHeaderColumn>
                        <TableHeaderColumn className="funding-table-column investors-table-column">
                          Links
                        </TableHeaderColumn>
                      </TableRow>
                    </TableHeader>
                    <TableBody
                      displayRowCheckbox={false}
                      selectable={false}
                      stripedRows={false}
                      showRowHover={false}
                      className="funding-table-body investors-table-body"
                    >
                      {investors.map((investor) => (
                        <TableRow
                          key={`${investor.id}${investor.investedDate}`}
                          className="funding-table-row investors-table-row"
                        >
                          <TableRowColumn className="funding-table-column investors-table-column">
                            {investor.name}
                          </TableRowColumn>
                          <TableRowColumn className="funding-table-column investors-table-column">
                            {this.isInvestorLeadInvestor(investor.id)
                              ? 'Yes'
                              : 'No'}
                          </TableRowColumn>
                          <TableRowColumn className="funding-table-column investors-table-column funding-rounds">
                            {investor.fundingRound.replace('_', ' ')}
                            {/*this.getFundingRoundsString(
                              investor.fundingRoundNames
                            )*/}
                          </TableRowColumn>
                          <TableRowColumn className="funding-table-column investors-table-column">
                            {moment(investor.investedDate).format(
                              'dddd Do MMMM YYYY'
                            )}
                            {/*this.getFundingRoundsString(
                              investor.fundingRoundNames
                            )*/}
                          </TableRowColumn>
                          <TableRowColumn className="funding-table-column investors-table-column">
                            <SocialLink
                              source="crunchbase"
                              link={investor.crunchbase_url || ''}
                            />
                          </TableRowColumn>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </Otherwise>
            </Choose>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(InvestorsTable);
