import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'underscore';

import DatePicker from 'material-ui/DatePicker';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

class PersonFilters extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired
  };

  handleQueryChange = _.debounce((event, value) => {
    this.handleChange('query', value);
  }, 500);

  handleSkillsChange = _.debounce((event, value) => {
    this.handleChange('skills', value);
  }, 500);

  handleOrderByChange = (event, key, value) => {
    this.handleChange('order_by', value);
  };

  handleOrderChange = (event, key, value) => {
    this.handleChange('order', value);
  };

  handleCreatedAtAfterChange = (event, date) => {
    this.handleChange('created_at_after', moment(date).valueOf());
  };

  handleCreatedAtBeforeChange = (event, date) => {
    this.handleChange('created_at_before', moment(date).valueOf());
  };

  handleChange = (key, value) => {
    var searchParams = _.omit(this.props.searchParams, key);
    if (value) {
      searchParams[key] = value;
    }
    this.props.onChange(searchParams);
  };

  formatDate = (date) => moment(date).format('DD/MM/YYYY');

  render() {
    const created_at_after = this.props.searchParams.created_at_after
      ? new Date(parseInt(this.props.searchParams.created_at_after, 10))
      : null;
    const created_at_before = this.props.searchParams.created_at_before
      ? new Date(parseInt(this.props.searchParams.created_at_before, 10))
      : null;

    return (
      <div>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-3">
            <TextField
              id="query"
              name="query"
              floatingLabelText="Search"
              floatingLabelFixed={true}
              onChange={this.handleQueryChange}
              defaultValue={this.props.searchParams.query || ''}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <TextField
              id="skills"
              name="skills"
              floatingLabelText="Skills"
              floatingLabelFixed={true}
              onChange={this.handleSkillsChange}
              defaultValue={this.props.searchParams.skills}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <SelectField
              floatingLabelText="Order by"
              floatingLabelFixed={true}
              value={this.props.searchParams.order_by || 'created_at'}
              onChange={this.handleOrderByChange}
            >
              <MenuItem primaryText="Created At" value="created_at" />
              <MenuItem primaryText="Updated At" value="updated_at" />
              <MenuItem primaryText="Trend" value="trends.trend" />
            </SelectField>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <SelectField
              floatingLabelText="Order"
              floatingLabelFixed={true}
              value={this.props.searchParams.order || 'desc'}
              onChange={this.handleOrderChange}
            >
              <MenuItem primaryText="Descending" value="desc" />
              <MenuItem primaryText="Ascending" value="asc" />
            </SelectField>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-3">
            <DatePicker
              id="created-at-after"
              name="created-at-after"
              floatingLabelText="From"
              floatingLabelFixed={true}
              autoOk={true}
              onChange={this.handleCreatedAtAfterChange}
              formatDate={this.formatDate}
              value={created_at_after}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <DatePicker
              id="created-at-before"
              name="created-at-before"
              floatingLabelText="To"
              floatingLabelFixed={true}
              autoOk={true}
              onChange={this.handleCreatedAtBeforeChange}
              formatDate={this.formatDate}
              value={created_at_before}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default PersonFilters;
