import auth from './Auth';
import Config from '../config';
import request from 'superagent';

const CACHE = {};

export default class StructuredReasonsStore {
  constructor() {
    this.baseUrl = `${Config.api.host}/reasons`;
  }

  getAllReasons() {
    const {reasons: cachedReasons = null} = CACHE;
    if (cachedReasons !== null) {
      return Promise.resolve(cachedReasons);
    }
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(this.baseUrl)
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .set('Accept', 'application/json')
      )
      .then((response) => {
        CACHE.reasons = response.body.reasons;
        return response.body.reasons;
      });
  }

  getReasons(type) {
    const {reasons: {[type]: cachedReasons = null} = {}} = CACHE;
    if (cachedReasons !== null) {
      return Promise.resolve(cachedReasons);
    }
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(`${this.baseUrl}/${type}`)
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .set('Accept', 'application/json')
      )
      .then((response) => {
        const {reasons: cachedReasons = null} = CACHE;
        if (cachedReasons === null) {
          CACHE.reasons = {};
        }
        CACHE.reasons[type] = response.body.reasons;
        return response.body.reasons;
      });
  }
}
