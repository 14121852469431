import React from 'react';

class Photos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      url: null,
      imageStatus: null
    };

    this.handleImageLoaded = this.handleImageLoaded.bind(this);
  }

  handleImageLoaded() {
    this.setState({imageStatus: 'loaded'});
  }

  componentDidMount() {
    this.setState({
      url: 'https://unsplash.it/800/800/?blur&random=1'
    });
  }

  render() {
    if (this.state.url) {
      const getClassName = () => {
        const classes = [];

        if (this.state.imageStatus === 'loaded') {
          classes.push('loaded');
        } else {
          classes.push('loading');
        }

        return classes.join(' ');
      };

      return (
        <div className="photos">
          <img
            src={this.state.url}
            className={getClassName()}
            onLoad={this.handleImageLoaded.bind(this)}
          />

          <If condition={this.state.imageStatus === 'loaded'}>
            <p>
              Well done <br /> you cleared them all!
            </p>
          </If>
        </div>
      );
    }

    return null;
  }
}

export default Photos;
