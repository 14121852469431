import React from 'react';
import PropTypes from 'prop-types';

import CustomizeCampaign from './CustomizeCampaign';
import Organizations from '../../lib/Organizations';
import CampaignsStore from '../../storage/CampaignsStore';
import AssignCampaigns from '../../lib/AssignCampaigns';
import auth from '../../storage/Auth';

export default class BackupMessageCustomizeCampaign extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    client: PropTypes.string.isRequired,
    reloadOrganization: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    toggleBackupMessageModal: PropTypes.func.isRequired,
    showErrorDialog: PropTypes.func.isRequired,
    recentMessages: PropTypes.object,
    structuredReasons: PropTypes.object.isRequired
  };

  campaignsStore = new CampaignsStore(this.props.organization.id);

  handleError = (error) => {
    const {showErrorDialog} = this.props;
    showErrorDialog(AssignCampaigns.processError(error));
    this.props.toggleBackupMessageModal(false);
  };

  submitCampaign = (
    campaignSelected,
    campaignConfiguration,
    evaluationStage
  ) => {
    auth.getAuthData().then(({profile: {email}}) => {
      const {organization, reloadOrganization} = this.props;
      const assignCampaign = AssignCampaigns.processCampaignWithNoDecision(
        campaignSelected,
        campaignConfiguration,
        email
      );

      assignCampaign.person_id = assignCampaign.primary_contact_id;
      assignCampaign.primary_contact_id = undefined;

      return this.campaignsStore
        .sendCampaign(assignCampaign)
        .then(() => reloadOrganization(organization.id))
        .catch(this.handleError);
    });
  };

  render() {
    const {
      organization,
      client,
      open,
      toggleBackupMessageModal,
      recentMessages,
      structuredReasons = {}
    } = this.props;
    const decision = Organizations.latestFinalOrInvestorDecision(
      organization,
      client
    );

    return (
      <React.Fragment>
        <If condition={open}>
          <CustomizeCampaign
            selectAssigned={false}
            scheduleCall={false}
            organization={organization}
            isEditingCampaign={open}
            campaignSelected={'backup_message'}
            onDone={toggleBackupMessageModal}
            submitCampaign={this.submitCampaign}
            editingEnabled={true}
            client={client}
            decision={decision}
            disablePeople={
              organization.primary_contact_id
                ? [organization.primary_contact_id]
                : []
            }
            requireCompleteCampaignTo={true}
            recentMessages={recentMessages}
            structuredReasons={structuredReasons}
          />
        </If>
      </React.Fragment>
    );
  }
}
