import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../../../Tooltip';
import SocialLink from '../../SocialLink';

import {getLinkedInUrl} from '../../../../lib/helpers';

import ActionAssignment from 'material-ui/svg-icons/action/assignment';

export default class OrganizationDetailsBody extends React.Component {
  static propTypes = {
    details: PropTypes.object.isRequired
  };

  emailCopyRef = React.createRef();

  render() {
    const {details} = this.props;
    return (
      <div className="organization-details-body">
        <If condition={details.email_address || details.phone_numbers}>
          <table className="workflow-table">
            <tbody>
              <If condition={details.email_address}>
                <tr className="workflow-row email-address">
                  <td className="workflow-row-key">Email:</td>
                  <td className="workflow-row-value">
                    {details.email_address}
                  </td>
                  <td className="workflow-row-actions" align="right">
                    <div className="workflow-row-actions-wrapper">
                      <div ref={this.emailCopyRef}>
                        <ActionAssignment
                          color="#7F7F7F"
                          className="copy-to-clipboard copy-buttons"
                          data-clipboard-text={details.email_address}
                        />
                      </div>
                    </div>
                    <Tooltip
                      parent={this.emailCopyRef.current}
                      text={'Copy to clipboard'}
                      showOnHover={true}
                      customStyles={{
                        right: '0px'
                        //top: '0px'
                      }}
                    />
                  </td>
                </tr>
              </If>
              <If condition={details.phone_numbers}>
                <tr className="workflow-row phone-numbers">
                  <td className="workflow-row-key">Phone:</td>
                  <td className="workflow-row-value">
                    {details.phone_numbers.join(', ')}
                  </td>
                </tr>
              </If>
            </tbody>
          </table>
        </If>
        <If
          condition={
            details.homepage_url ||
            details.crunchbase ||
            details.angellist ||
            details.twitter ||
            details.facebook ||
            details.linkedin
          }
        >
          <div className="socials-wrapper">
            <If condition={details.homepage_url}>
              <SocialLink source="website" link={details.homepage_url} />
            </If>
            <If condition={details.crunchbase}>
              <SocialLink source="crunchbase" link={details.crunchbase} />
            </If>
            <If condition={details.angellist}>
              <SocialLink source="angellist" link={details.angellist} />
            </If>
            <If condition={details.twitter}>
              <SocialLink source="twitter" link={details.twitter} />
            </If>
            <If condition={details.facebook}>
              <SocialLink source="facebook" link={details.facebook} />
            </If>
            <If condition={details.linkedin}>
              <SocialLink source="linkedin" link={details.linkedin} />
            </If>
          </div>
        </If>
      </div>
    );
  }
}
