import ModelStore from './ModelStore';

export default class ClearbitPersonStore extends ModelStore {
  constructor(id) {
    super();

    this.id = id;
    this.path = '/providers/clearbit/people/{id}';
  }
}
