import request from 'superagent';
import auth from './Auth';
import Config from '../config';

export default class TimelineStore {
  constructor(id) {
    this.url = `${Config.api.host}/organizations/${id}/workflow/events`;
  }

  getTimeline(flush = false, order_events = false) {
    return auth
      .getAuthData()
      .then((authData) =>
        request.get(this.url).query({
          access_token: authData.token,
          client: authData.client,
          flush,
          order_events
        })
      )
      .then((response) => response.body);
  }
}
