import investorScores from './investorScores';

function investorScoreCategory(investor) {
  const {ml_features: {investor_score: {score = -1} = {}} = {}} = investor;
  return scoreCategory(score);
}

function scoreCategory(score) {
  return (
    Object.values(investorScores).filter(
      (category) => score > category.min && score <= category.max
    )[0] || investorScores.none
  );
}

export {investorScoreCategory, scoreCategory};
