import React from 'react';
import PropTypes from 'prop-types';

import TimelineSectionContent from './TimelineSectionContent.js';

import moment from 'moment';

export default class TimelineSectionPast extends React.Component {
  static propTypes = {
    past: PropTypes.array.isRequired
  };

  render() {
    const {past} = this.props;
    return (
      <div className="timeline-section past-section">
        {/*<h3>Past actions</h3>*/}
        <Choose>
          <When condition={past.length === 0}>
            <p className="no-content">No past actions</p>
          </When>
          <Otherwise>
            {past.map((dateSection) => (
              <div
                key={dateSection.date}
                className="timeline-past-date-section"
              >
                <div className="timeline-past-date-section-dates">
                  <h6 className="timeline-past-date-section-date">
                    {moment(dateSection.date).format('dddd Do MMMM YYYY')}
                  </h6>
                </div>
                <TimelineSectionContent
                  section="past"
                  content={dateSection.content}
                  {...this.props}
                />
              </div>
            ))}
          </Otherwise>
        </Choose>
      </div>
    );
  }
}
