import auth from './Auth';
import Config from '../config';
import request from 'superagent';

const CACHE = {};

export default class WorkflowTemplateStore {
  constructor() {
    this.baseUrl = `${Config.api.host}/workflow/templates`;
  }

  template(templateName) {
    if (templateName in CACHE) {
      return Promise.resolve(CACHE[templateName]);
    }
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(`${this.baseUrl}/${templateName}`)
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .set('Accept', 'application/json')
      )
      .then(({body}) => {
        CACHE[templateName] = body;
        return body;
      });
  }
}
