import auth from './Auth';
import Config from '../config';
import request from 'superagent';

export default class DoNotContactStore {
  constructor(personId) {
    this.url = `${Config.api.host}/people/${personId}/donotcontact`;
  }

  doNotContact(shouldDelete = false) {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .post(this.url)
          .query({
            access_token: authData.token,
            client: authData.client,
            delete: shouldDelete
          })
          .set('Accept', 'application/json')
      )
      .then((response) => response.body);
  }
}
