import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import xss from 'xss';

import callTitles from '../../../../../content/callTitles';
import callFeedbackReasons from '../../../../../content/callFeedbackReasons';

import {getUserName} from '../../../../../lib/helpers';

export default class TimelineEventScorecard extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired
  };

  buildItem = () => {
    const {item: {scorecard: current = {}, previous = {}} = {}} = this.props;
    const changed = this.getChanged(current, previous);
    return this.getLabels(changed, current.calls[0]);
  };

  getChanged = (current, previous) => {
    if (previous === null) {
      return 'newCall';
    }
    const {calls: currentCalls = []} = current;
    const {calls: previousCalls = []} = previous;
    const currentCall = currentCalls[0] || null;
    const previousCall = previousCalls[0] || null;
    if (currentCalls.length !== previousCalls.length) {
      return currentCalls.length > previousCalls.length
        ? 'newCall'
        : 'callDeleted';
    } else if ('decision' in currentCall && !('decision' in previousCall)) {
      return 'newDecision';
    } else if (
      'recommendation' in currentCall &&
      !('recommendation' in previousCall)
    ) {
      return 'newRecommendation';
    } else if (
      ('template_id' in currentCall && !('template_id' in previousCall)) ||
      currentCall.template_id !== previousCall.template_id
    ) {
      return 'template';
    } else if (!_.isEqual(currentCall.decision, previousCall.decision)) {
      return 'decisionEdited';
    } else if (
      !_.isEqual(currentCall.recommendation, previousCall.recommendation)
    ) {
      return 'recommendationEdited';
    }
    return null;
  };

  getLabels = (changed, call) => {
    const {
      user_id: userId = '',
      template_id: templateId = '',
      call: callWith = '',
      decision = {},
      recommendation = {}
    } = call;
    switch (changed) {
      case 'newCall':
        const template = callTitles[templateId] || 'Call';
        return {
          label: `${getUserName(userId)}: New <b>${template}</b>`,
          subLabel: callWith
        };
      case 'callDeleted':
        return {
          label: `Call deleted`,
          subLabel: null
        };
      case 'newDecision':
        return {
          label: `${getUserName(decision.user_id)}: Decided <b>${
            callFeedbackReasons[decision.decision]
          }</b>`,
          subLabel: decision.notes
        };
      case 'newRecommendation':
        return {
          label: `${getUserName(recommendation.user_id)}: Recommended <b>${
            callFeedbackReasons[recommendation.recommendation]
          }</b>`,
          subLabel: recommendation.notes
        };
      case 'decisionEdited':
        return {
          label: `${getUserName(decision.user_id)}: Edited Decision to <b>${
            callFeedbackReasons[decision.decision]
          }</b>`,
          subLabel: decision.notes
        };
      case 'recommendationEdited':
        return {
          label: `${getUserName(
            recommendation.user_id
          )}: Edited Recommendation to <b>${
            callFeedbackReasons[recommendation.recommendation]
          }</b>`,
          subLabel: recommendation.notes
        };
      default:
        return {
          label: 'Scorecard event',
          subLabel: 'Unknown'
        };
    }
  };

  render() {
    const {label, subLabel} = this.buildItem();
    return (
      <div className="timeline-event-inner-wrapper">
        <h5
          className="timeline-event-label"
          dangerouslySetInnerHTML={{__html: xss(label)}}
        />
        <If condition={subLabel !== null}>
          <p className="timeline-event-sub-label">{subLabel}</p>
        </If>
        <div className="spacer" />
      </div>
    );
  }
}
