import React from 'react';
import PropTypes from 'prop-types';
import AutoComplete from 'material-ui/AutoComplete';
import Avatar from 'material-ui/Avatar';
import Chip from 'material-ui/Chip';

import tags from '../../config/tags';

export default class MLTagsFilter extends React.Component {
  static propTypes = {
    selected: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    onSelectTag: PropTypes.func.isRequired,
    removeTag: PropTypes.func.isRequired
  };

  static defaultProps = {
    selected: [],
    onSelectTag: () => {},
    removeTag: () => {}
  };

  state = {searchText: ''};

  handleSearchTextChange = (searchText) => {
    this.setState({searchText});
  };

  handleNewRequest = (tag) => {
    this.props.onSelectTag(tag);
    this.setState({searchText: ''});
  };

  render() {
    const {selected} = this.props;

    return (
      <React.Fragment>
        <div className="filter-data ">
          <label>Tags</label>

          <AutoComplete
            name="ml-tag"
            filter={AutoComplete.fuzzyFilter}
            dataSource={tags.filter((t) => !selected.includes(t))}
            maxSearchResults={5}
            onNewRequest={this.handleNewRequest}
            onUpdateInput={this.handleSearchTextChange}
            searchText={this.state.searchText}
          />
        </div>

        <div className="ml-tags">
          {selected.map((tag) => (
            <span key={tag} className="ml-tag">
              <Chip onRequestDelete={() => this.props.removeTag(tag)}>
                <Avatar
                  style={{backgroundColor: 'black'}}
                  icon={<div className="tag-icon" />}
                />
                {tag}
              </Chip>
            </span>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
