import React from 'react';
import OrganizationsSearch from './organization/OrganizationsSearch';
import Loading from './loading/Loading';
import auth from '../storage/Auth';

export default class IncompleteRoute extends React.Component {
  state = {
    client: null
  };

  componentDidMount() {
    if (window.mixpanel) {
      mixpanel.track('Visited incomplete list');
    }
    auth
      .getAuthData()
      .then(({client}) => {
        this.setState({client});
      })
      .catch((err) => {
        console.log('Unable to retrieve client', err);
      });
  }

  render() {
    const {client} = this.state;
    if (!client) {
      return <Loading />;
    }
    return (
      <OrganizationsSearch
        initSearchParams={{
          based_in_continent: 'Europe',
          classification_not: 'never,missed_opportunity',
          is_closed: false,
          has_missing_data: true,
          order_by: 'ml_features_score',
          ml_features_score_gt: '0.4',
          state: 'unseen',
          user_state_not: 'never'
        }}
        client={client}
        location={this.props.location}
        context={this.props.context}
        route={this.props.route}
        source="incomplete"
      />
    );
  }
}
