import React from 'react';
import PropTypes from 'prop-types';

import CommunicationCall from 'material-ui/svg-icons/communication/call';
import ActionAssignmentTurnedIn from 'material-ui/svg-icons/action/assignment-turned-in';
import ActionDelete from 'material-ui/svg-icons/action/delete';
import ActionEdit from 'material-ui/svg-icons/editor/mode-edit';
import NavigationExpandMore from 'material-ui/svg-icons/navigation/expand-more';
import NavigationExpandLess from 'material-ui/svg-icons/navigation/expand-less';

import {getUserName, getDateAndTime} from '../../../lib/helpers';

export default class ScorecardCallOrFormHeader extends React.Component {
  static propTypes = {
    contentType: PropTypes.string.isRequired,
    scorecardType: PropTypes.string,
    user: PropTypes.string,
    callWith: PropTypes.string,
    date: PropTypes.number,
    expandable: PropTypes.bool,
    expanded: PropTypes.bool,
    toggleExpanded: PropTypes.func,
    showDate: PropTypes.bool,
    showTime: PropTypes.bool,
    deEmphasised: PropTypes.bool,
    title: PropTypes.string,
    deleteCallClick: PropTypes.func,
    id: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    openScorecard: PropTypes.func.isRequired,
    setScorecardModalEditIndex: PropTypes.func,
    selectCallType: PropTypes.func.isRequired,
    templateId: PropTypes.string,
    type: PropTypes.string
  };

  buildClassName = () => {
    const {contentType, expandable, deEmphasised = false, source} = this.props;
    const classNames = ['scorecard-call-or-form-header', contentType, source];
    if (expandable) classNames.push('expandable');
    if (deEmphasised) classNames.push('de-emphasised');
    return classNames.join(' ');
  };

  headerClicked = () => {
    const {expandable, toggleExpanded} = this.props;
    if (expandable) {
      toggleExpanded();
    }
  };

  render() {
    const {
      contentType,
      scorecardType = '',
      user,
      callWith = '',
      date,
      expandable,
      expanded,
      showDate,
      showTime,
      title,
      deleteCallClick,
      id,
      openScorecard,
      setScorecardModalEditIndex,
      selectCallType,
      templateId
    } = this.props;

    const {date: contentDate, time: contentTime} = getDateAndTime(date);
    const showDelete =
      contentType === 'call' && typeof deleteCallClick === 'function';
    return (
      <div className={this.buildClassName()}>
        <If condition={scorecardType === '' || scorecardType === 'view'}>
          <div className="scorecard-call-or-form-icon">
            <Choose>
              <When condition={contentType === 'call'}>
                <CommunicationCall
                  style={{
                    fill: '#5dab49'
                  }}
                />
              </When>
              <Otherwise>
                <ActionAssignmentTurnedIn style={{fill: '#5dab49'}} />
              </Otherwise>
            </Choose>
          </div>
        </If>
        <div
          className="scorecard-call-or-form-template"
          onClick={() => {
            openScorecard('view', id);
          }}
        >
          {title}
        </div>
        <If condition={user}>
          <div
            className="scorecard-call-or-form-conductor"
            onClick={() => {
              openScorecard('view', id);
            }}
          >{`- ${getUserName(user)}`}</div>
        </If>
        <If condition={callWith !== ''}>
          <div
            className="scorecard-call-or-form-with"
            onClick={() =>
              scorecardType === '' ? openScorecard('view', id) : null
            }
          >{`- ${callWith}`}</div>
        </If>
        <div className="spacer" />
        <If condition={showDate}>
          <Choose>
            <When condition={showTime}>
              <If condition={contentDate && contentTime}>
                <div className="scorecard-call-or-form-date-time">{`${contentDate} - ${contentTime}`}</div>
              </If>
            </When>
            <Otherwise>
              <If condition={contentDate}>
                <div className="scorecard-call-or-form-date-time">{`${contentDate}`}</div>
              </If>
            </Otherwise>
          </Choose>
        </If>
        <If condition={scorecardType !== 'edit' && contentType !== 'form'}>
          <div
            className="scorecard-call-edit-call-button-wrapper"
            onClick={() => {
              if (this.props.setScorecardModalEditIndex) {
                setScorecardModalEditIndex(id);
                selectCallType(templateId);
              } else {
                openScorecard('edit', id);
                selectCallType(templateId);
              }
            }}
          >
            <ActionEdit color="#7f7f7f" />
          </div>
        </If>
        <If condition={showDelete}>
          <div
            className="scorecard-call-delete-call-button-wrapper"
            onClick={() => deleteCallClick(id)}
          >
            <ActionDelete color="#7f7f7f" />
          </div>
        </If>
        <If condition={expandable}>
          <div className="scorecard-call-or-form-expand-buttons-wrapper">
            <Choose>
              <When condition={expanded}>
                <NavigationExpandLess
                  style={{
                    fill: '#7F7F7F'
                  }}
                  onClick={this.headerClicked}
                />
              </When>
              <Otherwise>
                <NavigationExpandMore
                  style={{
                    fill: '#7F7F7F'
                  }}
                  onClick={this.headerClicked}
                />
              </Otherwise>
            </Choose>
          </div>
        </If>
      </div>
    );
  }
}
