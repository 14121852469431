import React from 'react';
import PropTypes from 'prop-types';

import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

export default class EditOrganizationPeopleEmployeesRange extends React.Component {
  static propTypes = {
    minEmployees: PropTypes.number.isRequired,
    updateOrganizationFields: PropTypes.func.isRequired,
    source: PropTypes.string
  };

  state = {
    min: 0,
    error: false
  };

  componentWillMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.minEmployees !== this.props.minEmployees) {
      this.initialize();
    }
  }

  initialize = () => {
    const {minEmployees = 0} = this.props;
    this.setState({
      min: minEmployees,
      error: this.getError(minEmployees)
    });
  };

  handleChange = (min) => {
    this.setState({
      min,
      error: this.getError(min)
    });
    this.props.updateOrganizationFields([
      {field: 'total_employees_min', value: min},
      {field: 'total_employees_max', value: this.getMax(min)}
    ]);
  };

  getError = (min) => {
    const {source = 'edit-incomplete'} = this.props;
    return source === 'edit-incomplete' && min === 0;
  };

  getMax = (min) => {
    switch (min) {
      case 1:
        return 10;
      case 11:
        return 50;
      case 51:
        return 100;
      case 101:
        return 250;
      case 251:
      default:
        return null;
    }
  };

  render() {
    const {min, error} = this.state;
    return (
      <SelectField
        className="edit-organization-employees-field edit-organization-input-field medium"
        floatingLabelText="RANGE"
        value={min}
        onChange={(event, index, value) => {
          this.handleChange(value);
        }}
        errorText={error ? 'Required' : false}
      >
        <MenuItem value={1} primaryText={'1 - 10'} />
        <MenuItem value={11} primaryText={'11 - 50'} />
        <MenuItem value={51} primaryText={'51 - 100'} />
        <MenuItem value={101} primaryText={'101 - 250'} />
        <MenuItem value={251} primaryText={'251+'} />
      </SelectField>
    );
  }
}
