import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import Card from '../../../card/Card';
import CardHeader from '../../../card/CardHeader';
import CardBody from '../../../card/CardBody';
import Insights from '../Insights';
import CustomRankIcon from '../CustomRankIcon';
import FundingRounds from './FundingRounds';
import Investors from './Investors';

import convertedLegacyInsights from '../../../../content/convertedLegacyInsights';

class FundingSection extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    snapshot: PropTypes.object.isRequired,
    snapshotLoading: PropTypes.bool.isRequired,
    useSnapshot: PropTypes.bool.isRequired,
    investors: PropTypes.array.isRequired,
    investorsLoading: PropTypes.bool.isRequired,
    fundingRounds: PropTypes.array.isRequired,
    fundingRoundsLoading: PropTypes.bool.isRequired
  };

  buildFunding = () => {
    return {
      highlights: this.getInsights('highlights'),
      risks: this.getInsights('risks'),
      redFlags: this.getInsights('red_flags'),
      fundingRounds: this.getFundingRounds(),
      investors: this.getInvestors()
    };
  };

  getInsights = (type) => {
    const {
      organization: {
        ml_features: {
          ui_ranking: {funding = []} = {},
          highlights: {highlights: legacyHighlights = {}} = {},
          is_not_ico: {score: icoScore = 1} = {},
          is_zombie: {score: zombieScore = 0} = {}
        } = {}
      } = {}
    } = this.props;
    if (funding.length > 0) {
      return funding[0][`matched_${type}`];
    } else {
      const insights = [];
      switch (type) {
        case 'highlights':
          Object.entries(legacyHighlights).forEach(([highlight]) => {
            if (highlight === 'investors') {
              insights.push(convertedLegacyInsights[highlight]);
            }
          });
          break;
        case 'risks':
          if (zombieScore === 1) {
            insights.push(convertedLegacyInsights['is_zombie']);
          }
          break;
        case 'red_flags':
          if (icoScore === 0) {
            insights.push(convertedLegacyInsights['is_ico']);
          }
          break;
      }
      return insights;
    }
  };

  getFundingRounds = () => {
    const {
      organization: {funding_rounds: organizationFundingRounds = []} = {},
      fundingRounds = []
    } = this.props;
    return {
      numberOfFundingRounds: organizationFundingRounds.length,
      fundingAmount: this.getFundingAmount(),
      fundingRounds
    };
  };

  getFundingAmount = () => {
    const {
      organization: {
        total_funding_usd: fundingAmount = 0,
        funding_stage: fundingStage = '',
        ml_features: {total_funding_smoothed_manual: {score = 0} = {}} = {}
      } = {},
      fundingRounds = [],
      snapshot: {
        organization: {
          total_funding_usd: snapshotFundingAmount = 0,
          funding_stage: snapshotFundingStage = '',
          ml_features: {
            total_funding_smoothed_manual: {snapshotScore = 0} = {}
          } = {}
        } = {},
        fundingRounds: snapshotFundingRounds = []
      } = {}
    } = this.props;
    return {
      amount: fundingAmount,
      stage: fundingStage,
      highlighted: Math.min(Math.max(score, 1), 0) >= 0.8,
      hasFundingRounds: fundingRounds.length > 0,
      snapshot: {
        amount: snapshotFundingAmount,
        stage: snapshotFundingStage,
        highlighted: Math.min(Math.max(snapshotScore, 1), 0) >= 0.8,
        hasFundingRounds: snapshotFundingRounds.length > 0
      }
    };
  };

  getInvestors = () => {
    const {investors = [], fundingRounds = []} = this.props;
    const unGroupedInvestors = [];
    for (const investor of investors) {
      const {funding_rounds: investorFundingRounds = []} = investor;
      for (const investorFundingRoundId of investorFundingRounds.filter(
        (investorFundingRoundId) =>
          typeof fundingRounds.find(({id}) => id === investorFundingRoundId) !==
          'undefined'
      )) {
        const {
          funding_type: fundingRound = null,
          announced_on: announcedOn = null
        } = fundingRounds.find(({id}) => id === investorFundingRoundId) || {};
        unGroupedInvestors.push({
          ...investor,
          fundingRound,
          investedDate: new Date(announcedOn).getTime()
        });
      }
    }
    return unGroupedInvestors.sort((a, b) => a.investedDate - b.investedDate);
  };

  getCardRankIcon = () => {
    const {
      organization: {ml_features: {ui_ranking: {funding = []} = {}} = {}} = {}
    } = this.props;
    if (funding.length === 0) {
      return null;
    }
    const rank = funding.sort((a, b) => b.importance - a.importance)[0].rank;
    return <CustomRankIcon rank={rank} />;
    //return <ActionBookmark className={`rank-icon ${rank}`} />;
  };

  filterMlUpdates = (mlUpdates) => {
    return mlUpdates
      .filter((object) => object.name.includes('funding'))
      .map((update) => update.notes[0]);
  };

  render() {
    const {
      useSnapshot = false,
      fundingRoundsLoading,
      investorsLoading,
      mlUpdates
    } = this.props;
    const {
      highlights,
      risks,
      redFlags,
      fundingRounds,
      investors
    } = this.buildFunding();
    return (
      <div className="funding-section">
        <Card>
          <CardHeader title="Funding" icon={this.getCardRankIcon()} />
          <CardBody>
            <div className="grid-container funding-grid-container">
              <div className="funding-grid-item insights-grid-item">
                <Insights
                  highlights={highlights}
                  risks={risks}
                  redFlags={redFlags}
                  mlUpdates={this.filterMlUpdates(mlUpdates)}
                />
              </div>
              <div className="funding-grid-item funding-rounds-grid-item">
                <FundingRounds
                  fundingRounds={fundingRounds}
                  fundingRoundsLoading={fundingRoundsLoading}
                  investors={investors}
                  investorsLoading={investorsLoading}
                  useSnapshot={useSnapshot}
                />
              </div>
              <div className="funding-grid-item investors-grid-item">
                <Investors
                  fundingRounds={fundingRounds.fundingRounds}
                  fundingRoundsLoading={fundingRoundsLoading}
                  investors={investors}
                  investorsLoading={investorsLoading}
                  useSnapshot={useSnapshot}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withRouter(FundingSection);
