import ModelStore from './ModelStore';
import organizationsStore from './OrganizationsStore';
import request from 'superagent';
import auth from './Auth';
import Config from '../config';
import Q from 'q';
import DecisionStore from './DecisionStore';
import InboxStore from './InboxStore';
import NotesStore from './NotesStore';
import ScoresStore from './ScoresStore';
import IsInterestingStore from './IsInterestingStore';
import Organizations from '../lib/Organizations';

const DEFAULT_STAGE = 'make_contact';
const OrganizationStore = function(id) {
  this.id = id;
  this.path = '/organizations/{id}';
  this.decisionStore = new DecisionStore(this.id);
  this.inboxStore = new InboxStore(this.id);
  this.notesStore = new NotesStore(this.id);
  this.scoresStore = new ScoresStore(this.id);
  this.isInterestingStore = new IsInterestingStore(this.id);

  return this;
};
OrganizationStore.prototype = new ModelStore();

OrganizationStore.prototype.updateModel = function(patchData) {
  return auth
    .getAuthData()
    .then((authData) =>
      request
        .patch(this.url())
        .send(patchData)
        .query({
          access_token: authData.token,
          client: authData.client
        })
    )
    .then((response) => response.body)
    .then((updated) => {
      organizationsStore.replaceModel(updated);
      return updated;
    });
};

OrganizationStore.prototype.waitForNewPerson = function(personId) {
  const checkOrganizationForPerson = (attempt, defer) => {
    this.getModel().then((organization) => {
      if (
        (organization.person_ids || []).some((id) => {
          return id === personId;
        })
      ) {
        organizationsStore.replaceModel(organization);
        defer.resolve(personId);
      } else if (attempt >= 20) {
        defer.reject();
      } else {
        setTimeout(() => {
          checkOrganizationForPerson(attempt + 1, defer);
        }, 400);
      }
    });
  };

  const q = Q.defer();
  checkOrganizationForPerson(0, q);
  return q.promise;
};

OrganizationStore.prototype.removePerson = function(personId) {
  return auth.getAuthData().then((authData) =>
    request
      .del(`${this.url()}/people/${personId}`)
      .query({
        access_token: authData.token,
        client: authData.client
      })
      .send({})
  );
};

OrganizationStore.prototype.deleteModel = function(fn) {
  return auth.getAuthData().then((authData) => {
    request
      .del(this.url())
      .query({
        access_token: authData.token,
        client: authData.client
      })
      .end(
        function(error, response) {
          if (response && response.ok) {
            organizationsStore.removeModel(this.id);
            fn();
          }
        }.bind(this)
      );
  });
};

OrganizationStore.prototype.deleteSignals = function(fn) {
  auth.getAuthData().then((authData) => {
    request
      .del(this.url() + '/signals')
      .query({
        access_token: authData.token,
        client: authData.client
      })
      .end(
        function() {
          fn();
        }.bind(this)
      );
  });
};

OrganizationStore.prototype.setDecision = function(assignCampaign) {
  return auth.getAuthData().then(({roles = [], accessType, client}) =>
    this.getDecisions().then((decisions) => {
      const {decision = {}} = assignCampaign;
      const role = roles.includes('partner')
        ? accessType === 'full'
          ? 'partner'
          : 'trustedPartner'
        : accessType === 'full'
          ? 'analyst'
          : 'trustedAnalyst';

      if (decision.state === 'contact' && role === 'partner') {
        decision.stage = decision.stage || DEFAULT_STAGE;
        const oldestTransitionalInProgressDecision = Organizations.getOldestTransitionalInProgressDecision(
          {user_decisions: decisions},
          client
        );
        decision.assigned_to =
          decision.assigned_to || oldestTransitionalInProgressDecision.user_id;
      }
      assignCampaign.decision = decision;
      return this.decisionStore
        .decide(assignCampaign)
        .then((created) => created.decision)
        .then((decision) => this.replaceOrganizationDecision(decision))
        .catch((error) => console.error(error));
    })
  );
};

OrganizationStore.prototype.updateDecisionStage = function(
  stage,
  notes = undefined
) {
  return this.decisionStore
    .updateStage(stage, notes)
    .then((decision) => this.replaceOrganizationDecision(decision));
};

OrganizationStore.prototype.updateDecisionStop = function(stop) {
  return this.decisionStore
    .updateStop(stop)
    .then((decision) => this.replaceOrganizationDecision(decision));
};

OrganizationStore.prototype.updateDecisionFollowUpContact = function(
  followUpContact
) {
  return this.decisionStore
    .updateFollowUpContact(followUpContact)
    .then((decision) => this.replaceOrganizationDecision(decision));
};

OrganizationStore.prototype.updateDecisionStatus = function(status) {
  return this.decisionStore
    .updateStatus(status)
    .then((decision) => this.replaceOrganizationDecision(decision));
};

OrganizationStore.prototype.updateDecisionAssignedTo = function(assignedTo) {
  return this.decisionStore
    .updateAssignedTo(assignedTo)
    .then((decision) => this.replaceOrganizationDecision(decision));
};

OrganizationStore.prototype.updateDecisionOrganizer = function(organizer) {
  return this.decisionStore
    .updateOrganizer(organizer)
    .then((decision) => this.replaceOrganizationDecision(decision));
};

OrganizationStore.prototype.updateDecisionMakeContactChecklist = function(
  makeContactChecklist
) {
  return this.decisionStore
    .updateMakeContactChecklist(makeContactChecklist)
    .then((decision) => this.replaceOrganizationDecision(decision));
};

OrganizationStore.prototype.toggleRequiresManualHelp = function(
  requiresManualHelp
) {
  return this.decisionStore
    .toggleRequiresManualHelp(requiresManualHelp)
    .then((decision) => this.replaceOrganizationDecision(decision));
};

OrganizationStore.prototype.handlePriorityChange = function(priority) {
  return this.decisionStore
    .handlePriorityChange(priority)
    .then((decision) => this.replaceOrganizationDecision(decision));
};

OrganizationStore.prototype.updateDecision = function(updates) {
  return this.decisionStore
    .updateDecision(updates)
    .then((decision) => this.replaceOrganizationDecision(decision));
};

OrganizationStore.prototype.replaceOrganizationDecision = function(decision) {
  return this.getModel().then((organization) => {
    const userDecisions = organization.user_decisions || {};
    userDecisions[decision.client] = userDecisions[decision.client] || {};
    userDecisions[decision.client][decision.user_id] = decision;

    organization.user_decisions = userDecisions;
    organizationsStore.replaceModel(organization);
    return organization;
  });
};

OrganizationStore.prototype.updateIsNotSpam = function(isNotSpam) {
  return this.updateModel([
    {
      op: 'replace',
      path: '/is_not_spam',
      value: !!isNotSpam
    }
  ]);
};

OrganizationStore.prototype.updateInvestmentType = function(investmentType) {
  return this.updateModel([
    {
      op: 'replace',
      path: '/investment_type',
      value: investmentType
    }
  ]);
};

OrganizationStore.prototype.updateInvestmentStage = function(investmentStage) {
  return this.updateModel([
    {
      op: 'replace',
      path: '/investment_stage',
      value: investmentStage
    }
  ]);
};

OrganizationStore.prototype.setIsInteresting = function(assignCampaign) {
  return this.isInterestingStore
    .decide(assignCampaign)
    .then((decisionWithMessage) => decisionWithMessage.decision)
    .then((decision) => this.replaceOrganizationDecision(decision));
};

OrganizationStore.prototype.addToInbox = function(
  assigned_to,
  notes,
  reason,
  priority
) {
  return this.inboxStore
    .addToInbox(assigned_to, notes, reason, priority)
    .then((inbox) =>
      auth.getAuthData().then(({client}) =>
        this.getModel().then((organization) => {
          organization.inboxes[client].push(inbox);
          organizationsStore.replaceModel(organization);
          return organization;
        })
      )
    );
};

OrganizationStore.prototype.removeFromInbox = function(
  organization,
  assignedTo
) {
  return this.inboxStore.removeFromInbox(assignedTo).then((inbox) => {
    organization.inboxes[inbox.client] = organization.inboxes[inbox.client].map(
      (i) => (i.assigned_to === assignedTo ? inbox : i)
    );
    organizationsStore.replaceModel(organization);

    return organization;
  });
};

OrganizationStore.prototype.getNotes = function(
  expirationDate,
  signature,
  showNotes
) {
  return new Promise((resolve, reject) => {
    this.notesStore
      .getNotes(expirationDate, signature, showNotes)
      .then((notes) => {
        resolve(notes);
      })
      .catch(reject);
  });
};

OrganizationStore.prototype.getScores = function(
  expirationDate,
  signature,
  showNotes
) {
  return new Promise((resolve, reject) => {
    this.scoresStore
      .getScores(expirationDate, signature, showNotes)
      .then((scores) => {
        resolve(scores);
      })
      .catch(reject);
  });
};

OrganizationStore.prototype.getCommunications = function() {
  return new Promise((resolve, reject) => {
    auth.getAuthData().then((authData) => {
      request
        .get(this.url() + '/communications')
        .query({
          access_token: authData.token,
          client: authData.client
        })
        .end((error, response) => {
          if (
            response &&
            response.ok &&
            response.body &&
            response.body.communications
          ) {
            resolve(response.body.communications);
          } else {
            reject(error);
          }
        });
    });
  });
};

OrganizationStore.prototype.getDecisions = function() {
  return this.decisionStore.decisions().then((decisions) => {
    const users_decisions = {};
    if (decisions) {
      decisions.forEach((decision) => {
        const client = decision.client;
        if (client) {
          if (!users_decisions[client]) {
            users_decisions[client] = {};
          }
          users_decisions[client][decision.id + decision.created_at] = decision;
        }
      });
    }

    return users_decisions;
  });
};

OrganizationStore.prototype.mergeOrganizations = function(
  firstOrganizationId,
  secondOrganizationId
) {
  const defer = Q.defer();

  auth.getAuthData().then((authData) => {
    request
      .post(Config.api.host + '/combinations/organizations')
      .query({
        access_token: authData.token,
        client: authData.client,
        organization_one_id: firstOrganizationId,
        organization_two_id: secondOrganizationId
      })
      .end(
        function(error, response) {
          if (response && response.ok) {
            defer.resolve(response.body);
          } else {
            defer.reject(error);
          }
        }.bind(this)
      );
  });

  return defer.promise;
};

OrganizationStore.prototype.sendFeedback = function(feedbackData = {}) {
  return auth
    .getAuthData()
    .then((authData) =>
      request
        .post(`${this.url()}/feedback`)
        .send(feedbackData)
        .query({
          access_token: authData.token,
          client: authData.client
        })
    )
    .then((response) => response.body);
};

export default OrganizationStore;
