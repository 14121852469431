import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';

export default class PreviewOrganizationName extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    organizationsStoreParams: PropTypes.object.isRequired,
    filterSource: PropTypes.object.isRequired,
    returnUrl: PropTypes.string.isRequired
  };

  render() {
    const {
      organization: {name, id} = {},
      organizationsStoreParams,
      returnUrl,
      filterSource
    } = this.props;
    return (
      <div className="preview-organization--header-name">
        <Link
          to={{
            pathname: `/organizations/${id}`,
            state: {organizationsStoreParams, returnUrl, filterSource}
          }}
        >
          {name}
        </Link>
      </div>
    );
  }
}
