import request from 'superagent';
import auth from './Auth';
import Config from '../config';

export default class OxyleadStore {
  constructor() {}

  getPersonFromLinkedinURL(linkedinURL) {
    return new Promise((resolve, reject) => {
      auth.getAuthData().then((authData) => {
        request
          .get(Config.api.host + '/providers/oxyleads/members')
          .query({
            url: linkedinURL,
            access_token: authData.token,
            client: authData.client
          })
          .end((error, response) => {
            if (response && response.ok) {
              resolve(response.body.members);
              return;
            } else if (error) {
              reject(error);
            }
          });
      });
    });
  }
}
