import React from 'react';
import PropTypes from 'prop-types';
import {FlatButton, TextField} from 'material-ui';
import AddCircleOutline from 'material-ui/svg-icons/content/add-circle';
import ActionDelete from 'material-ui/svg-icons/action/delete';

export default class EditAlsoKnownAs extends React.Component {
  static propTypes = {
    alsoKnownAs: PropTypes.array,
    handleAlsoKnownAsChange: PropTypes.func.isRequired
  };

  state = {
    newAlsoKnownAs: ''
  };

  render() {
    const {alsoKnownAs = []} = this.props;

    return (
      <div className="edit-also-known-as">
        {alsoKnownAs.map((name, i) => (
          <div key={i}>
            <TextField
              value={name}
              onChange={(event) => {
                alsoKnownAs[i] = event.target.value;
                this.props.handleAlsoKnownAsChange(alsoKnownAs);
              }}
              name="alias"
            />
            <FlatButton
              className="remove-also-known-as"
              label="Remove"
              labelStyle={{color: '#C30000'}}
              icon={<ActionDelete color={'#C30000'} />}
              onClick={() => {
                alsoKnownAs.splice(i);
                this.props.handleAlsoKnownAsChange(alsoKnownAs);
              }}
            />
          </div>
        ))}
        <div>
          <TextField
            value={this.state.newAlsoKnownAs}
            onChange={(event) => {
              this.setState({
                newAlsoKnownAs: event.target.value
              });
            }}
            name="add-alias"
          />
          <FlatButton
            label="Add Alias"
            onClick={() => {
              alsoKnownAs.push(this.state.newAlsoKnownAs);
              this.props.handleAlsoKnownAsChange(alsoKnownAs);
              this.setState({
                newAlsoKnownAs: ''
              });
            }}
            icon={<AddCircleOutline color={'#5DAB49'} />}
          />
        </div>
      </div>
    );
  }
}
