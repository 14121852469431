import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

class NumberOfFundingRounds extends React.Component {
  static propTypes = {
    numberOfFundingRounds: PropTypes.number.isRequired
  };

  render() {
    const {numberOfFundingRounds} = this.props;
    return (
      <div className="data-wrapper number-of-funding-rounds">
        <div className="data-header">No. of Funding Rounds</div>
        <div className="data-content text">{numberOfFundingRounds}</div>
      </div>
    );
  }
}

export default withRouter(NumberOfFundingRounds);
