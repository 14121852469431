import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import Person from './person/Person';

import _ from 'underscore';

class KeyPeople extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    people: PropTypes.array.isRequired,
    editPerson: PropTypes.func.isRequired,
    useSnapshot: PropTypes.bool.isRequired
  };

  render() {
    const {
      organization = {},
      people = [],
      editPerson,
      useSnapshot = false
    } = this.props;
    return (
      <div className="key-people">
        <div className="section-title main">Key People</div>
        <Choose>
          <When condition={people.length === 0}>
            <div className="section-no-content">No Key People</div>
          </When>
          <Otherwise>
            <div className="people-container">
              {people.map((person) => (
                <Person
                  organization={organization}
                  person={person}
                  source="highlighted"
                  key={person.id}
                  editPerson={editPerson}
                  useSnapshot={useSnapshot}
                />
              ))}
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(KeyPeople);
