import React from 'react';
import {Link, withRouter} from 'react-router';
import PropTypes from 'prop-types';

function TrafficSource({source: {type, value}}) {
  return (
    <div className="data-content-list-item text traffic-source" key={type}>
      <div className="traffic-amount">{`(${value}%)`}</div>
      <div className="traffic-source-text">{type}</div>
    </div>
  );
}

class TrafficSources extends React.Component {
  static propTypes = {
    sources: PropTypes.array.isRequired
  };

  render() {
    const {sources} = this.props;
    return (
      <div className="data-wrapper traffic-sources">
        <div className="data-header">Traffic Countries</div>
        <Choose>
          <When condition={sources.length === 0}>
            <div className="data-content text empty">No data</div>
          </When>
          <Otherwise>
            <div className="data-content list">
              {sources.sort((a, b) => b.value - a.value).map((source) => (
                <TrafficSource source={source} key={source.type} />
              ))}
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(TrafficSources);
