import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../../../Tooltip';

import {Link} from 'react-router';

import ImageEdit from 'material-ui/svg-icons/image/edit';

export default class OrganizationDetailsHeader extends React.Component {
  static propTypes = {
    organizationId: PropTypes.string.isRequired
  };

  editOrganizationRef = React.createRef();

  render() {
    const {organizationId} = this.props;
    return (
      <div className="organization-details-header-content">
        <div className="spacer" />
        <div className="organization-details-actions-wrapper">
          <div ref={this.editOrganizationRef}>
            <Link to={`/organizations/${organizationId}/edit`}>
              <ImageEdit
                className="organization-details-edit-organization"
                color="#5DAB49"
              />
            </Link>
          </div>
          <Tooltip
            parent={this.editOrganizationRef.current}
            text={'Edit organization'}
            showOnHover={true}
            position="top"
          />
        </div>
      </div>
    );
  }
}
