import React from 'react';
import AppBar from 'material-ui/AppBar';
import Paper from 'material-ui/Paper';
import Moment from 'moment';

import ReactHighcharts from 'react-highcharts';

export default class MarketSharePieChart extends React.Component {
  constructor(props) {
    super(props);

    this.buildConfig = this.buildConfig.bind(this);
  }

  buildConfig() {
    if (this.props.marketShareStats) {
      const market_share_series = [];
      const marketShareStats = this.props.marketShareStats;
      const totalFundingRounds = marketShareStats[2] + marketShareStats[3];

      market_share_series.push({
        colorByPoint: true,
        data: [
          {
            name: 'Decision',
            y: marketShareStats[2],
            color: this.props.color
          },
          {
            name: 'No Decision',
            y: marketShareStats[3],
            color: '#f3f3f3ff'
          }
        ]
      });

      return {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">\u25CF</span> {point.name}: <b>{point.percentage:.0f} %</b> ({point.y})<br/>'
        },
        credits: {
          enabled: false
        },
        title: {
          text: this.props.title
        },
        subtitle: {
          text:
            this.props.subtitle +
            '<br />' +
            'From October 2018. Funding Rounds: ' +
            totalFundingRounds.toString()
        },
        series: market_share_series,
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format:
                '<b>{point.name}</b>: {point.percentage:.0f} % ({point.y})'
            }
          }
        }
      };
    }
  }

  render() {
    const config = this.buildConfig();
    return (
      <div className="box-container full-width">
        <Paper zDepth={1}>
          <div style={{padding: '30px', textAlign: 'center'}}>
            <ReactHighcharts config={config} />
          </div>
        </Paper>
      </div>
    );
  }
}
