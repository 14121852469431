import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../../../Tooltip';

import NavigationChevronLeft from 'material-ui/svg-icons/navigation/chevron-left';
import NavigationChevronRight from 'material-ui/svg-icons/navigation/chevron-right';
import ImageEdit from 'material-ui/svg-icons/image/edit';
import AddCircleOutline from 'material-ui/svg-icons/content/add-circle-outline';
import IconButton from 'material-ui/IconButton/IconButton';

export default class ContactsHeader extends React.Component {
  static propTypes = {
    selectedPerson: PropTypes.number.isRequired,
    selectedPersonId: PropTypes.string.isRequired,
    peopleLength: PropTypes.number.isRequired,
    changeSelectedPerson: PropTypes.func.isRequired,
    editPerson: PropTypes.func.isRequired,
    addPerson: PropTypes.func.isRequired
  };

  editContactRef = React.createRef();
  addContactRef = React.createRef();

  getSvgStyles = () => {
    return {
      width: '34px',
      height: '34px'
    };
  };

  getChangeSelectedPersonArrowClass = (direction, selected, length) => {
    switch (direction) {
      case 'left':
        return selected > 0 ? 'enabled' : 'disabled';
      case 'right':
        return selected < length - 1 ? 'enabled' : 'disabled';
    }
  };

  getSelectedPersonString = (selectedPerson, peopleLength) => {
    return peopleLength > 0
      ? `${selectedPerson + 1} / ${peopleLength}`
      : `0 / 0`;
  };

  render() {
    const {
      selectedPerson,
      selectedPersonId,
      peopleLength,
      changeSelectedPerson,
      editPerson,
      addPerson
    } = this.props;
    return (
      <div className="contacts-header-content">
        <If condition={peopleLength > 0}>
          <div className="change-selected-person-buttons">
            <div className="change-selected-person-button-wrapper">
              <NavigationChevronLeft
                style={this.getSvgStyles()}
                className={this.getChangeSelectedPersonArrowClass(
                  'left',
                  selectedPerson,
                  peopleLength
                )}
                onClick={() =>
                  changeSelectedPerson(-1, selectedPerson, peopleLength)
                }
              />
            </div>
            <div className="selected-person-text">
              {this.getSelectedPersonString(selectedPerson, peopleLength)}
            </div>
            <div className="change-selected-person-button-wrapper">
              <NavigationChevronRight
                style={this.getSvgStyles()}
                className={this.getChangeSelectedPersonArrowClass(
                  'right',
                  selectedPerson,
                  peopleLength
                )}
                onClick={() =>
                  changeSelectedPerson(1, selectedPerson, peopleLength)
                }
              />
            </div>
          </div>
        </If>
        <div className="spacer" />
        <div className="contacts-actions-wrapper">
          <If condition={peopleLength > 0}>
            <div ref={this.editContactRef}>
              <ImageEdit
                className="contacts-edit-contact"
                onClick={() => editPerson(selectedPersonId)}
                color="#5DAB49"
              />
            </div>
            <Tooltip
              parent={this.editContactRef.current}
              text={'Edit contact'}
              showOnHover={true}
              position="top"
            />
          </If>
          <div ref={this.addContactRef}>
            <AddCircleOutline
              className="contacts-add-contact"
              color="#5DAB49"
              onClick={() => addPerson()}
            />
            <Tooltip
              parent={this.addContactRef.current}
              text={'Add contact'}
              showOnHover={true}
              position="top"
            />
          </div>
        </div>
      </div>
    );
  }
}
