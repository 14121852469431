import React from 'react';
import {Link, withRouter} from 'react-router';
import PropTypes from 'prop-types';
import _ from 'underscore';
import {investorScoreCategory} from '../../../content/investorScore';

class InvestorInsights extends React.Component {
  static propTypes = {
    investor: PropTypes.object.isRequired,
    showMaxLength: PropTypes.number.isRequired
  };

  state = {
    showTooltip: false,
    showFull: false,
    numberHiddenInfo: 0
  };

  toggleFullProfileClickHandler = () => {
    this.setState({
      showFull: !this.state.showFull
    });
  };

  hasError = () => {
    return true;
  };

  render() {
    const {investor = {}} = this.props;
    const {showTooltip, showFull, numberHiddenInfo} = this.state;
    const {
      ml_features: {
        investor_score: {score = 0} = {},
        is_good_investor: {score: isGoodInvestor = 0} = {},
        is_top_tier: {score: isTopTier = 0} = {},
        number_qualifying_investments: {score: numberInvestments = 0} = {},
        number_top_tier_investments: {score: numberTopTierInvestments = 0} = {}
      } = {}
    } = investor;

    return (
      <div
        className="data-wrapper founded"
        onMouseLeave={() => {
          if (this.hasError()) {
            this.setState({showTooltip: false});
          }
        }}
        onMouseEnter={() => {
          if (this.hasError()) {
            this.setState({showTooltip: true});
          }
        }}
        rel={(el) => (this._parent = el)}
      >
        <div className="data-header">Investor Insights</div>
        <Choose>
          <When condition={_.isEmpty(investor)}>
            <div className="data-content text empty">No data</div>
          </When>
          <Otherwise>
            <div className="preview-box">
              <div className="invesor-profile-row">
                <Choose>
                  <When condition={isTopTier}>
                    <div className="data-content text invesor-profile-div">
                      <span className="highlighted-text">Top tier</span>
                    </div>
                  </When>
                  <Otherwise>
                    <div className="data-content text invesor-profile-div">
                      Prob of follow on:{' '}
                      <span
                        className={isGoodInvestor ? 'highlighted-text' : ''}
                      >
                        {Math.round(score)}%
                      </span>
                      <div className="spacer" />({numberTopTierInvestments} /{' '}
                      {numberInvestments} relevant investments)
                    </div>
                  </Otherwise>
                </Choose>
              </div>
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(InvestorInsights);
