import React from 'react';
import InvestorProfileTab from './InvestorProfileTab';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import _ from 'underscore';
import PersonStore from '../../storage/PersonStore';
import InvestorFundingRoundsStore from '../../storage/InvestorFundingRoundsStore';
import PortfolioTab from './PortfolioTab';
import WorkflowTab from './WorkflowTab';
import Loading from '../loading/Loading';

class InvestorTabsContent extends React.Component {
  static propTypes = {
    organization: PropTypes.object,
    investor: PropTypes.object.isRequired,
    client: PropTypes.string.isRequired,
    userHasFullAccess: PropTypes.bool.isRequired,
    userRoles: PropTypes.array.isRequired,
    selectedTab: PropTypes.string.isRequired,
    setTabContent: PropTypes.func.isRequired
  };
  state = {
    isFirst: true,
    peopleLoading: false,
    portfolioAggregationLoading: false
  };

  componentWillMount() {
    const {selectedTab} = this.props;
    this.getTabData(selectedTab);
  }

  componentDidUpdate(previousProps) {
    const {
      organization: {person_ids: currentPersonIds = []} = {},
      setTabContent
    } = this.props;
    const {
      organization: {person_ids: previousPersonIds = []} = {}
    } = previousProps;

    if (!_.isEqual(currentPersonIds.sort(), previousPersonIds.sort())) {
      return this.getPeopleData().then((people) => {
        setTabContent('workflow', people);
      });
    }
  }

  getTabData = (tabName = this.props.selectedTab, forceUpdate) => {
    const {
      setTabContent,
      tabsContent = {},
      selectedTab,
      organization
    } = this.props;
    const {peopleLoading, portfolioAggregationLoading, isFirst} = this.state;
    if (isFirst) {
      this.setState({
        isFirst: false,
        peopleLoading: true,
        portfolioAggregationLoading: true
      });
    } else {
      if (
        peopleLoading &&
        portfolioAggregationLoading &&
        !_.isEmpty(tabsContent)
      )
        return null;
    }
    if (
      (!_.isEmpty(organization) && !('workflow' in tabsContent)) ||
      forceUpdate
    ) {
      this.getPeopleData().then((people) => {
        setTabContent(tabName, people);
      });
    }
    if (!('portfolioAggregation' in tabsContent) || forceUpdate) {
      this.getPortfolioAggregation().then((portfolioAggregation) => {
        setTabContent('portfolioAggregation', portfolioAggregation);
        this.setState({portfolioAggregationLoading: false});
      });
    }
  };

  getPeopleData = (organization = this.props.organization) => {
    return this.getPeople().then((people) => {
      const uniquePeople = [];
      for (let i = 0; i < people.length; i++) {
        let isFirstInstance = true;
        for (let j = 0; j < uniquePeople.length; j++) {
          if (_.isEqual(uniquePeople[j], people[i])) {
            isFirstInstance = false;
          }
        }
        if (isFirstInstance) uniquePeople.push(people[i]);
      }
      this.setState({
        peopleLoading: false
      });
      return uniquePeople;
    });
  };

  getPeople = (organization = this.props.organization) => {
    const {person_ids: personIds = [], id} = organization;
    if (personIds.length > 0) {
      return new PersonStore(personIds || []).getModels(id);
    }
    return Promise.resolve([]);
  };

  getPortfolioAggregation = () => {
    this.investorFundingRoundsStore = new InvestorFundingRoundsStore();
    const {investor} = this.props;
    if (investor.funding_rounds && investor.funding_rounds.length) {
      return this.investorFundingRoundsStore.fundingRoundsAggregations(
        investor.id
      );
    }
    return Promise.resolve([]);
  };

  render() {
    const {selectedTab, tabsContent} = this.props;
    const {peopleLoading, portfolioAggregationLoading} = this.state;

    if (_.isEmpty(tabsContent)) {
      return <Loading />;
    } else {
      return (
        <div className="investor-component">
          <If condition={selectedTab === 'profile'}>
            <InvestorProfileTab
              peopleLoading={peopleLoading}
              portfolioAggregationLoading={portfolioAggregationLoading}
              {...this.props}
            />
          </If>
          <If condition={selectedTab === 'workflow'}>
            <WorkflowTab peopleLoading={peopleLoading} {...this.props} />
          </If>
          <If condition={selectedTab === 'portfolio'}>
            <PortfolioTab
              portfolioAggregationLoading={portfolioAggregationLoading}
              {...this.props}
            />
          </If>
        </div>
      );
    }
  }
}

export default withRouter(InvestorTabsContent);
