import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Avatar from 'material-ui/Avatar';
import Dialog from 'material-ui/Dialog';
import IconMenu from 'material-ui/IconMenu';
import ExpandMore from 'material-ui/svg-icons/navigation/expand-more';
import AVReplay from 'material-ui/svg-icons/av/replay';
import SwapHoriz from 'material-ui/svg-icons/action/swap-horiz';
import LinearProgress from 'material-ui/LinearProgress';
import MenuItem from 'material-ui/MenuItem';
import IconButton from 'material-ui/IconButton';
import RaisedButton from 'material-ui/RaisedButton';
import {List, ListItem} from 'material-ui/List';
import Paper from 'material-ui/Paper';
import {Toolbar, ToolbarGroup, ToolbarTitle} from 'material-ui/Toolbar';
import {Link} from 'react-router';
import OrganizationSummary from './OrganizationSummary';
import OrganizationPicker from './OrganizationPicker';
import MergeArrow from './MergeArrow';
import OrganizationStore from '../../../storage/OrganizationStore';
import Organizations from '../../../lib/Organizations';
import auth from '../../../storage/Auth';

import xss from 'xss';

function makeErrorUrl(source, target) {
  const subject = encodeURIComponent(
    `DIG - Dangerous merge ${source.name} and ${target.name}`
  );

  const body = encodeURIComponent(
    `Unable to merge ${source.name} into ${target.name}\n\n${
      window.location.href
    }`
  );

  return `mailto:ben@inreachventures.com?subject=${subject}&body=${body}`;
}

function mergeError(client, roles, source, target) {
  if (!client || !source || !target) {
    return null;
  }

  if (source.id === target.id) {
    return 'An organization cannot be merged into itself';
  }

  const sourceDecision = Organizations.latestFinalOrInvestorDecision(
    source,
    client
  );
  const targetDecision = Organizations.latestFinalOrInvestorDecision(
    target,
    client
  );

  if (
    sourceDecision &&
    sourceDecision.state &&
    sourceDecision.state === 'contact' &&
    targetDecision.state !== 'contact'
  ) {
    return `<p>${source.name} <small>(${
      source.id
    })</small> is "In Progress" and cannot be merged.</p>
    <p>You maybe able to perform this merge if you swap the direction.</p>`;
  }

  if (
    sourceDecision &&
    sourceDecision.state &&
    sourceDecision.state === 'contact' &&
    targetDecision &&
    targetDecision.state &&
    targetDecision.state === 'contact'
  ) {
    if (!'admin' in roles) {
      return `<p>It would be dangerous to merge these two organizations.</p><p><a href="${makeErrorUrl(
        source,
        target
      )}">Share this with Ben</a>.</p>`;
    }
  }

  return null;
}

export default class MergeOrganization extends Component {
  state = {
    showConfirmMergeDialog: false,
    merging: false,
    client: null,
    roles: [],
    loadingSource: false,
    loadingTarget: false,
    source: null,
    target: null,
    targetName: '',
    targetError: null
  };

  static propTypes = {
    handleSuccessfulMerge: PropTypes.func.isRequired
  };

  sourceStore = new OrganizationStore();
  targetStore = new OrganizationStore();

  mergeSourceIntoTarget = () => {
    this.setState(() => ({merging: true, showConfirmMergeDialog: false}));

    this.sourceStore
      .mergeOrganizations(this.state.target.id, this.state.source.id)
      .then(
        (organization) => {
          this.props.handleSuccessfulMerge(organization.id);
        },
        (error) => {
          console.error(error);

          this.setState(() => ({
            merging: false
          }));
        }
      );
  };

  setSourceOrganization = (id) => {
    this.setState(() => ({loadingSource: true, source: null}));
    this.sourceStore.id = id;
    this.sourceStore
      .getModel()
      .then((source) => {
        this.setState(() => ({source}));
      }, console.error)
      .then(() => {
        this.setState(() => ({loadingSource: false}));
      });
  };

  setTargetOrganization = (id) => {
    this.setState(() => ({
      loadingTarget: true,
      target: null,
      targetError: null
    }));

    this.targetStore.id = id;
    this.targetStore
      .getModel()
      .then(
        (target) => {
          this.setState(() => ({
            target
          }));
        },
        (error) => {
          console.error(error);
          this.setState(() => ({targetError: 'Failed to load organization'}));
        }
      )
      .then(() => {
        this.setState(() => ({
          loadingTarget: false
        }));
      });
  };

  componentWillMount() {
    auth.getAuthData().then(({client, roles}) => {
      this.setState({
        userRoles: roles,
        client
      });
    });

    const {source, target} = this.props;

    if (source) {
      this.setSourceOrganization(source);
    }

    if (target) {
      this.setTargetOrganization(target);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {source, target} = nextProps;

    if (source !== this.state.source) {
      this.setSourceOrganization(source);
    }

    if (target) {
      if (target !== this.props.target) {
        this.setTargetOrganization(target);
      }
    } else {
      this.setState(() => ({
        target: null,
        targetError: null
      }));
    }
  }

  render() {
    const mergeErrorMessage = mergeError(
      this.state.client,
      this.state.roles,
      this.state.source,
      this.state.target
    );

    return (
      <div className="MergeOrganizations page-width">
        <Paper zDepth={3}>
          <Toolbar>
            <ToolbarGroup>
              <ToolbarTitle text="Merge Organizations" />
            </ToolbarGroup>
          </Toolbar>

          <If condition={mergeErrorMessage}>
            <div
              className="MergeOrganizations--error"
              dangerouslySetInnerHTML={{
                __html: xss(mergeErrorMessage)
              }}
            />
          </If>

          <Choose>
            <When condition={this.state.merging}>
              <h3>Merging. Please wait.</h3>
              <LinearProgress />
            </When>

            <Otherwise>
              <div className="MergeOrganizations--organizations">
                <div className="MergeOrganizations--organization">
                  <div className="MergeOrganizations--organization-header">
                    <h5>
                      <strong>Merge</strong> this organization
                    </h5>
                  </div>

                  <div className="MergeOrganizations--organization-details">
                    <Choose>
                      <When condition={this.state.source}>
                        <OrganizationSummary organization={this.state.source} />
                      </When>

                      <When condition={this.state.loadingSource}>
                        <LinearProgress />
                      </When>

                      <Otherwise>
                        <p>Unable to load organization.</p>
                      </Otherwise>
                    </Choose>
                  </div>
                </div>

                <If condition={this.state.target}>
                  <MergeArrow />
                </If>

                <div className="MergeOrganizations--organization">
                  <div className="MergeOrganizations--organization-header">
                    <h5>
                      <strong>Keep</strong> this organization
                    </h5>
                  </div>

                  <div className="MergeOrganizations--organization-details">
                    <Choose>
                      <When condition={this.state.target}>
                        <OrganizationSummary organization={this.state.target} />
                      </When>

                      <When condition={this.state.loadingTarget}>
                        <LinearProgress />
                      </When>

                      <Otherwise>
                        <OrganizationPicker>
                          {({loadingSuggestions, organizations}) => (
                            <Choose>
                              <When condition={loadingSuggestions}>
                                <LinearProgress />
                              </When>

                              <Otherwise>
                                <List>
                                  {organizations.map((o) => (
                                    <Link
                                      key={o.id}
                                      className="MergeOrganizations--picker-organization"
                                      to={`/organizations/${
                                        this.state.source.id
                                      }/merge/${o.id}`}
                                    >
                                      <ListItem
                                        primaryText={`${o.name}`}
                                        secondaryText={`id: ${o.id}`}
                                        leftAvatar={
                                          <Avatar src={o.image_url} />
                                        }
                                      />
                                    </Link>
                                  ))}
                                </List>
                              </Otherwise>
                            </Choose>
                          )}
                        </OrganizationPicker>
                      </Otherwise>
                    </Choose>
                  </div>
                </div>
              </div>
            </Otherwise>
          </Choose>

          <Toolbar>
            <ToolbarGroup>
              <Choose>
                <When condition={this.state.source}>
                  <Link to={`/organizations/${this.state.source.id}`}>
                    <RaisedButton
                      label="Cancel"
                      disabled={this.state.merging}
                    />
                  </Link>
                </When>

                <Otherwise>
                  <RaisedButton label="Cancel" disabled={true} />
                </Otherwise>
              </Choose>
            </ToolbarGroup>
            <If
              condition={
                !this.state.merging && this.state.source && this.state.target
              }
            >
              <ToolbarGroup>
                <RaisedButton
                  disabled={mergeErrorMessage != null}
                  label="Merge"
                  onClick={() => {
                    this.setState(() => ({
                      showConfirmMergeDialog: true
                    }));
                  }}
                  primary
                />
                <IconMenu
                  iconButtonElement={
                    <IconButton touch={true}>
                      <ExpandMore />
                    </IconButton>
                  }
                >
                  <Link to={`/organizations/${this.state.source.id}/merge`}>
                    <MenuItem primaryText="Change" leftIcon={<AVReplay />} />
                  </Link>
                  <Link
                    to={`/organizations/${this.state.target.id}/merge/${
                      this.state.source.id
                    }`}
                  >
                    <MenuItem primaryText="Swap" leftIcon={<SwapHoriz />} />
                  </Link>
                </IconMenu>
              </ToolbarGroup>
            </If>
          </Toolbar>
        </Paper>

        <If condition={this.state.source && this.state.target}>
          <Dialog
            actions={[
              <RaisedButton
                key="cancel"
                onClick={() =>
                  this.setState(() => ({
                    showConfirmMergeDialog: false
                  }))
                }
                label="Keep separate"
              />,
              <RaisedButton
                key="merge"
                backgroundColor="tomato"
                onClick={this.mergeSourceIntoTarget}
                label="Merge"
              />
            ]}
            open={this.state.showConfirmMergeDialog}
            title="Are you sure you want to merge these organizations?"
          />
        </If>
      </div>
    );
  }
}
