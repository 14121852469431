import Auth0Lock from 'auth0-lock';
import auth0 from 'auth0-js';

import user from '../api/user';
import Client from '../lib/Client';

import logo from '../../images/InReach_Icono.png';
import config from '../config';

const namespace = 'https://dig.inreachventures.com/metadata';

const lockOptions = {
  // This will leave the app open to CSRF attacks
  // DO NOT ENABLE IN PRODUCTION
  _enableIdPInitiatedLogin: config.auth0.dangerouslyEnableImpersonation,
  auth: {
    autoParseHash: false,
    responseType: 'token',
    redirectUrl: config.web.host + '/callback',
    params: {
      scope: 'openid profile email'
    },
    sso: config.auth0.sso
  },
  languageDictionary: {
    title: 'DIG'
  },
  theme: {
    primaryColor: '#5DAB49',
    logo
  }
};

const lock = new Auth0Lock(
  config.auth0.clientId,
  config.auth0.url,
  lockOptions
);

const webAuth = new auth0.WebAuth({
  clientID: config.auth0.clientId,
  domain: config.auth0.url,
  redirectUri: config.web.host + '/callback',
  responseType: 'token',
  scope: 'openid profile email'
});

let pendingRefresh = null;

let subscriptions = [];

if (typeof lock.on === 'function') {
  lock.on('authenticated', onAuthenticated);
}

function onAuthenticated({accessToken, expiresIn}) {
  return new Promise((resolve, reject) => {
    lock.getUserInfo(accessToken, (error, profile) => {
      const expires = new Date().setSeconds(expiresIn);
      const auth0 = {accessToken, expires, profile};

      localStorage.auth0 = JSON.stringify(auth0);

      subscriptions.forEach((cb) => {
        cb(error, auth0);
      });

      lock.hide();
      resolve(auth0);
    });
  });
}

function subscribe(cb) {
  subscriptions.push(cb);

  return () => subscriptions.splice(subscriptions.indexOf(cb), 1);
}

function parseHash(hash) {
  lock.resumeAuth(hash, (error, authResult) => {
    if (error) {
      console.error(error);
    } else {
      onAuthenticated(authResult);
    }
  });
}

function refresh() {
  if (pendingRefresh) {
    return pendingRefresh;
  }

  // Temporary extended debugging
  console.log('------- Extended Auth debugging -------');
  console.log(
    `Token expires: ${JSON.parse(localStorage.auth0 || '{}').expires ||
      'No expiry'}`
  );
  console.log(`Current date: ${new Date().getTime()}`);

  return (pendingRefresh = new Promise((resolve, reject) => {
    webAuth.checkSession({}, (error, authResult) => {
      if (!!error) {
        console.error(error);
        reject(new Error('Unable to renew session. You must log in again.'));
      } else {
        resolve(onAuthenticated(authResult));
      }
      pendingRefresh = null;
    });
  }));
}

function getAuthData() {
  return new Promise((resolve, reject) => {
    const auth0 = JSON.parse(localStorage.auth0 || '{}');
    const {accessToken, expires} = auth0;

    if (!accessToken || expires < new Date()) {
      return resolve(refresh().then(getAuthData));
    }

    const {profile} = auth0;
    const {
      [namespace]: {
        client,
        clients: {
          [client]: {access_type, roles, inboxes}
        }
      }
    } = profile;

    return resolve({
      token: accessToken,
      accessType: access_type,
      roles,
      inboxes,
      client,
      profile
    });
  }).catch((error) => {
    if (!(error instanceof SyntaxError)) {
      console.error(error);
    }

    return refresh().then(getAuthData);
  });
}

function showLock() {
  lock.show();
}

function getCustomisedFeedParams() {
  return getAuthData().then(
    ({
      profile: {
        [namespace]: {filters}
      }
    }) => filters
  );
}

function updateProfile({user_metadata: {filters, filters_sets}}) {
  try {
    const auth0 = JSON.parse(localStorage.auth0);

    const {
      profile: {[namespace]: metadata}
    } = auth0;

    localStorage.auth0 = JSON.stringify({
      ...auth0,
      profile: {
        ...auth0.profile,
        [namespace]: {
          ...metadata,
          filters,
          filters_sets
        }
      }
    });
  } catch (e) {
    throw new Error('Failed to update profile');
  }
}

function setFilters(filters) {
  return getAuthData()
    .then(({token, client, profile: {[namespace]: {user_id, filters_sets}}}) =>
      user.metadata.update(client, user_id, token, {
        filters,
        filters_sets
      })
    )
    .then(updateProfile);
}

function setFiltersSets(filters_sets) {
  return getAuthData()
    .then(({token, client, profile: {[namespace]: {user_id, filters}}}) =>
      user.metadata.update(client, user_id, token, {
        filters,
        filters_sets
      })
    )
    .then(updateProfile);
}

function getFiltersSets() {
  return getAuthData().then(
    ({
      profile: {
        [namespace]: {filters_sets}
      }
    }) => filters_sets
  );
}

function saveFiltersSet(name, params) {
  return getFiltersSets().then((filtersSets = {}) =>
    setFiltersSets({
      ...filtersSets,
      [name]: params
    })
  );
}

function deleteFiltersSet(filtersSetName) {
  return getFiltersSets()
    .then(
      (filtersSets) =>
        filtersSets && filtersSets[filtersSetName] ? filtersSets : null
    )
    .then((filtersSets) => {
      if (filtersSets) {
        delete filtersSets[filtersSetName];
        return setFiltersSets(filtersSets);
      }
    });
}

function registerUser() {
  return getAuthData().then(
    ({accessType, roles = [], client, profile: {name, email}}) => {
      const role = roles.includes('partner')
        ? accessType === 'full'
          ? 'partner'
          : 'trustedPartner'
        : accessType === 'full'
          ? 'analyst'
          : 'trustedAnalyst';

      if (window.mixpanel) {
        mixpanel.people.set({
          name: name,
          $email: email,
          client: client,
          role
        });

        mixpanel.identify(email);
      }
    }
  );
}

function signOut() {
  localStorage.removeItem('auth0');
  localStorage.removeItem('googleOAuth');

  const qs = new URLSearchParams({returnTo: `${config.web.host}`});
  window.location.href = `https://${config.auth0.url}/v2/logout?${qs}`;
}

export default {
  deleteFiltersSet,
  getAuthData,
  getCustomisedFeedParams,
  getFiltersSets,
  parseHash,
  registerUser,
  saveFiltersSet,
  setFilters,
  showLock,
  signOut,
  subscribe,
  namespace
};
