import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';

import Paper from 'material-ui/Paper';
import {List, ListItem} from 'material-ui/List';

import InboxMenuItem from './InboxMenuItem';

import {reasons} from '../../content/inbox';

export default class InboxMenu extends React.Component {
  static propTypes = {
    filters: PropTypes.arrayOf(PropTypes.string).isRequired,
    experiments: PropTypes.arrayOf(PropTypes.string).isRequired,
    ignoreExperiments: PropTypes.arrayOf(PropTypes.string).isRequired,
    counts: PropTypes.object.isRequired,
    inboxes: PropTypes.array.isRequired
  };

  state = {open: false};

  toggleMenu = () => {
    this.setState(({open}) => ({open: !open}));
  };

  handleClick = () => {
    this.setState({open: false});
  };

  render() {
    const {
      counts,
      filters,
      experiments,
      ignoreExperiments,
      inboxes
    } = this.props;
    const {open} = this.state;

    const current =
      reasons.find(
        (r) =>
          _.isEqual(r.filters, filters) &&
          _.isEqual(r.experiments, experiments) &&
          _.isEqual(r.ignore_experiments, ignoreExperiments)
      ) || {};

    const nestedItems = reasons
      .filter(
        ({reason}) =>
          inboxes.length === 0 ||
          counts[reason] > 0 ||
          inboxes.includes(reason.toLowerCase())
      )
      .map((r) => (
        <InboxMenuItem
          key={r.reason}
          active={r.reason === current.reason}
          count={counts[r.reason]}
          {...r}
          onClick={this.handleClick}
        />
      ));

    return (
      <Paper className="personal-inbox-menu">
        <List>
          <span className="visible-xs visible-sm">
            <ListItem
              open={open}
              autoGenerateNestedIndicator={false}
              primaryText={current.title}
              leftIcon={current.icon}
              rightIcon={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {counts[current.reason]}
                </div>
              }
              nestedItems={nestedItems}
              onClick={this.toggleMenu}
            />
          </span>

          <span className="visible-md visible-lg">{nestedItems}</span>
        </List>
      </Paper>
    );
  }
}
