import auth from './Auth';
import Config from '../config';
import request from 'superagent';

const CACHE = {};

export default class FeedbackStore {
  constructor() {
    this.baseUrl = `${Config.api.host}/feedback`;
  }

  getFeedback() {
    const {feedback: cachedFeedback = null} = CACHE;
    if (cachedFeedback !== null) {
      return Promise.resolve(cachedFeedback);
    }
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(`${this.baseUrl}/categories`)
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .set('Accept', 'application/json')
      )
      .then((response) => {
        CACHE.feedback = response.body;
        return response.body;
      });
  }
}
