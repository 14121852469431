import React from 'react';
import PropTypes from 'prop-types';

import Loading from './loading/Loading';

import TextField from 'material-ui/TextField';

import ActionDone from 'material-ui/svg-icons/action/done';
import ImageEdit from 'material-ui/svg-icons/image/edit';

export default class DecisionStatus extends React.Component {
  static propTypes = {
    organizationDecision: PropTypes.object.isRequired,
    onStatusChange: PropTypes.func.isRequired
  };

  state = {
    editing: false,
    loading: false,
    status: '',
    originalStatus: ''
  };

  componentWillMount() {
    if (this.props.organizationDecision) {
      const status = this.props.organizationDecision.status;
      this.setState({
        status: status,
        originalStatus: status
      });
    }
  }

  enableEditMode = () => {
    this.setState({
      editing: true
    });
  };

  updateStatus = (status) => {
    this.setState({
      status: status
    });
  };

  saveStatus = () => {
    const {status, originalStatus} = this.state;
    if (status !== originalStatus) {
      this.props.onStatusChange(status).then((res) => {
        this.setState({
          loading: false,
          originalStatus: status
        });
      });
      this.setState({
        editing: false,
        loading: true
      });
    } else {
      this.setState({
        editing: false
      });
    }
  };

  render() {
    const {editing, loading, status} = this.state;
    return (
      <div className="edit-status-wrapper">
        <div className="data-header edit-status-header">Status</div>
        <If condition={editing}>
          <div className="edit-status editing">
            <TextField
              multiLine={true}
              name="edit-status"
              value={status}
              hintText={'Write status'}
              onChange={(event) => {
                this.updateStatus(event.target.value);
              }}
              className="edit-status-textbox"
              disabled={false}
              textareaStyle={{
                color: '#7f7f7f'
              }}
            />
            <ActionDone
              onClick={this.saveStatus}
              color="#5DAB49"
              className="edit-status-icon"
            />
          </div>
        </If>
        <If condition={!editing}>
          <div className="edit-status">
            <TextField
              multiLine={true}
              name="edit-status"
              value={status}
              hintText={'No status'}
              className="edit-status-textbox"
              disabled={true}
              textareaStyle={{
                color: '#939598'
              }}
            />
            <Choose>
              <When condition={!loading}>
                <ImageEdit
                  onClick={this.enableEditMode}
                  color="#5DAB49"
                  className="edit-status-icon"
                />
              </When>
              <Otherwise>
                <Loading small={true} dontFill={true} size={24} />
              </Otherwise>
            </Choose>
          </div>
        </If>
      </div>
    );
  }
}
