import request from 'superagent';
import auth from './Auth';
import Config from '../config';

export default class TaskStore {
  getTask(id) {
    return auth
      .getAuthData()
      .then((authData) =>
        request.get(Config.worker.host + '/jobs/' + id).query({
          access_token: authData.token,
          client: authData.client
        })
      )
      .then((response) => response.body || {});
  }

  startTask(id, params = {}) {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .post(Config.worker.host + '/jobs/' + id)
          .set('Content-Type', 'application/json')
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .send(params)
      )
      .then((response) => response.body);
  }

  stopTask(id) {
    return auth
      .getAuthData()
      .then((authData) =>
        request.delete(Config.worker.host + '/jobs/' + id).query({
          access_token: authData.token,
          client: authData.client
        })
      )
      .then((response) => response.body);
  }
}
