import React from 'react';
import PropTypes from 'prop-types';

import TimelineSectionContent from './TimelineSectionContent.js';

export default class TimelineSectionUpcoming extends React.Component {
  static propTypes = {
    upcoming: PropTypes.array.isRequired
  };

  render() {
    const {upcoming} = this.props;
    return (
      <div className="timeline-section upcoming-section">
        <h3>Upcoming</h3>
        <Choose>
          <When condition={upcoming.length === 0}>
            <p className="no-content">No upcoming actions</p>
          </When>
          <Otherwise>
            <TimelineSectionContent
              section="upcoming"
              content={upcoming}
              {...this.props}
            />
          </Otherwise>
        </Choose>
      </div>
    );
  }
}
