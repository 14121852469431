import React from 'react';
import PropTypes from 'prop-types';

import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';

import TimelineEventReconnectBody from './TimelineEventReconnectBody';
import TimelineEventActions from './TimelineEventActions';
import {truncateString} from '../../../../../lib/helpers';

import moment from 'moment';
import xss from 'xss';

export default class TimelineEventReconnect extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    handleReconnectCancel: PropTypes.func.isRequired,
    reconnectActive: PropTypes.bool.isRequired,
    reconnect: PropTypes.object
  };

  maxLabelLength = 90;

  state = {
    actionLoading: false,
    showReconnectCancelConfirmDialog: false
  };

  buildItem = (
    item = this.props.item,
    reconnectActive = this.props.reconnectActive
  ) => {
    const reconnect = reconnectActive ? this.props.reconnect : item.reconnect;
    const canCancel = item.canCancel || false;
    const reason = reconnect.reason || null;
    const labels = this.getReconnectLabels(reconnect.when, reason);
    return {
      label: labels.label,
      subLabel: labels.subLabel,
      actions: this.getReconnectActions(reconnectActive, canCancel),
      reason: reason
    };
  };

  getReconnectLabels = (when, reason) => {
    const label = `${
      when > new Date() ? 'Reconnecting' : 'Reconnected'
    } on <b>${moment(new Date(when)).format('dddd Do MMMM YYYY')}</b>`;
    return {
      label,
      subLabel: reason ? this.getSubLabel(reason, String(label).length) : null
    };
  };

  getSubLabel = (reason, labelLength) => {
    return `- Reason: ${truncateString(
      reason,
      this.maxLabelLength,
      labelLength
    )}`;
  };

  getReconnectActions = (reconnectActive, canCancel) => {
    if (reconnectActive && canCancel) {
      return [
        this.state.actionLoading
          ? {
              text: 'Loading',
              loading: true
            }
          : {
              text: 'Cancel',
              onClick: () => this.setReconnectCancelConfirmDialog(true)
            }
      ];
    }
    return [];
  };

  setReconnectCancelConfirmDialog = (
    show = !this.state.showReconnectCancelConfirmDialog
  ) => {
    this.setState({
      showReconnectCancelConfirmDialog: show
    });
  };

  handleReconnectCancel = () => {
    this.setReconnectCancelConfirmDialog(false);
    this.setState({
      actionLoading: true
    });
    this.props.handleReconnectCancel().then(() => {
      this.setState({
        actionLoading: false
      });
    });
  };

  render() {
    const {showReconnectCancelConfirmDialog} = this.state;
    const {label, subLabel, actions, reason} = this.buildItem();
    return (
      <div className="timeline-event-inner-wrapper">
        <h5
          className="timeline-event-label"
          dangerouslySetInnerHTML={{__html: xss(label)}}
        />
        <If condition={subLabel !== null}>
          <p className="timeline-event-sub-label">{subLabel}</p>
        </If>
        <div className="spacer" />
        <If condition={actions !== null}>
          <TimelineEventActions actions={actions} />
        </If>
        <If condition={reason}>
          <TimelineEventReconnectBody reason={reason} />
        </If>
        <Dialog
          title="Confirm cancel Reconnect"
          actions={[
            <RaisedButton
              className="assign-action"
              label="Cancel"
              style={{
                marginRight: '10px'
              }}
              onClick={() => this.setReconnectCancelConfirmDialog(false)}
            />,
            <RaisedButton
              children={<span style={{color: 'white'}}>CONFIRM</span>}
              className="assign-action"
              backgroundColor="#5DAB49"
              labelStyle={{
                color: 'white'
              }}
              onClick={this.handleReconnectCancel}
            />
          ]}
          modal={false}
          open={showReconnectCancelConfirmDialog}
          onRequestClose={this.hideConfirmChangePrimaryContactDialog}
        >
          Are you sure you want to cancel the reconnect?
        </Dialog>
      </div>
    );
  }
}
