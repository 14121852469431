import PropTypes from 'prop-types';

import React from 'react';
import RefreshIndicator from 'material-ui/RefreshIndicator';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import CircularProgress from 'material-ui/CircularProgress';

class Loading extends React.Component {
  getChildContext() {
    return {
      muiTheme: getMuiTheme()
    };
  }

  render() {
    const getClassName = () => {
      const {fixed, dontFill, align} = this.props;
      let className = 'loading';
      if (fixed) {
        className += ' fixed';
      }
      if (!dontFill) {
        className += ' fill';
      }
      if (align) {
        className += ` ${align}`;
      }
      return className;
    };
    const inReachSecondaryColor = '#337ab7';

    return (
      <div className={getClassName()}>
        <If condition={!this.props.small}>
          <RefreshIndicator
            size={this.props.size || 50}
            loadingColor={inReachSecondaryColor}
            status="loading"
            left={0}
            top={0}
            style={{position: 'relative', margin: 'auto'}}
          />
        </If>
        <If condition={this.props.small}>
          <CircularProgress
            color={inReachSecondaryColor}
            size={this.props.size || 15}
            thickness={2}
          />
        </If>
      </div>
    );
  }
}

Loading.childContextTypes = {
  muiTheme: PropTypes.object.isRequired
};

export default Loading;
