import React from 'react';
import PropTypes from 'prop-types';

import RaisedButton from 'material-ui/RaisedButton';

export default class ScorecardHeader extends React.Component {
  static propTypes = {
    openScorecard: PropTypes.func.isRequired,
    showViewScorecardButton: PropTypes.bool.isRequired,
    newCallClickHandler: PropTypes.func.isRequired,
    lastCall: PropTypes.object.isRequired
  };

  render() {
    const {
      openScorecard,
      showViewScorecardButton,
      newCallClickHandler,
      lastCall
    } = this.props;

    return (
      <div className="scorecard-header-content">
        <div className="spacer" />
        <RaisedButton
          className="scorecard-button new-call"
          label="NEW CALL"
          backgroundColor="#ffffff"
          labelColor="#000000"
          style={{
            marginRight: showViewScorecardButton ? '12px' : '0'
          }}
          onClick={() => newCallClickHandler()}
        />
        <If condition={showViewScorecardButton}>
          <RaisedButton
            className="scorecard-button view-scorecard"
            label="VIEW SCORECARD"
            backgroundColor="#5dab49"
            labelColor="#ffffff"
            onClick={() => openScorecard('view', lastCall.id)}
          />
        </If>
      </div>
    );
  }
}
