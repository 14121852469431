import React from 'react';
import PropTypes from 'prop-types';

import ScorecardCallOrForm from './ScorecardCallOrForm';

export default class ScorecardList extends React.Component {
  static propTypes = {
    calls: PropTypes.array.isRequired,
    forms: PropTypes.array.isRequired,
    deleteCallClick: PropTypes.func.isRequired,
    openScorecard: PropTypes.func.isRequired,
    selectCallType: PropTypes.func.isRequired
  };

  render() {
    const {
      calls = [],
      forms,
      deleteCallClick,
      openScorecard,
      selectCallType
    } = this.props;
    const activeForm = forms.length > 0;
    return (
      <div className="scorecard-list">
        <If condition={calls.length !== 0}>
          {calls.map((call, i) => (
            <div className="scorecard-call-or-form-wrapper" key={call.id}>
              <ScorecardCallOrForm
                type="call"
                call={call}
                deleteCallClick={deleteCallClick}
                openScorecard={openScorecard}
                selectCallType={selectCallType}
              />
            </div>
          ))}
        </If>
        <If condition={activeForm}>
          {forms.map((form, i) => {
            return (
              <div className="scorecard-call-or-form-wrapper" key={form.id}>
                <ScorecardCallOrForm
                  type="form"
                  form={form}
                  openScorecard={openScorecard}
                  selectCallType={selectCallType}
                />
                <If condition={forms.length - 1 !== i}>
                  <div className="scorecard-call-or-form-break no-line short" />
                </If>
              </div>
            );
          })}
        </If>
      </div>
    );
  }
}
