import request from 'superagent';
import auth from './Auth';
import Config from '../config';
import Q from 'q';

export default class ImportStore {
  constructor() {
    this.url = Config.api.host + '/providers/import/entries';
  }

  importCSV(csv) {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .post(this.url)
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .set('Accept', 'application/json')
          .set('Content-type', 'text/csv')
          .send(csv)
      )
      .then(({body: {entries = []} = {}}) => entries);
  }

  importEntry(entry) {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .post(this.url)
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .set('Accept', 'application/json')
          .set('Content-type', 'application/json')
          .send(entry)
      )
      .then((response) => response.body);
  }

  getEntry(entryId) {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(`${this.url}/${entryId}`)
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .set('Accept', 'application/json')
      )
      .then((response) => response.body);
  }

  waitForEntryToHaveOrganizationId(entryId) {
    const checkEntryForOrganizationId = (attempt, defer) => {
      this.getEntry(entryId).then((entry) => {
        if (entry.organization_id) {
          defer.resolve(entry);
        } else if (attempt >= 100) {
          defer.reject(new Error('Failed to get Organization ID'));
        } else {
          setTimeout(() => {
            checkEntryForOrganizationId(attempt + 1, defer);
          }, 800);
        }
      });
    };

    const q = Q.defer();
    checkEntryForOrganizationId(0, q);
    return q.promise;
  }
}
