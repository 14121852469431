import React from 'react';
import PropTypes from 'prop-types';

import TimelineEventInbox from './TimelineEventInbox';
import TimelineEventDecision from './TimelineEventDecision';
import TimelineEventCalendarEvent from './TimelineEventCalendarEvent';
import TimelineEventCommunications from './TimelineEventCommunications';
import TimelineEventCallFeedback from './TimelineEventCallFeedback';
import TimelineEventPreQualification from './TimelineEventPreQualification';
import TimelineEventReconnect from './TimelineEventReconnect';
import TimelineEventTask from './TimelineEventTask';
import TimelineEventMailing from './TimelineEventMailing';
import TimelineEventScorecard from './TimelineEventScorecard';
import TimelineEventAssignCampaign from './TimelineEventAssignCampaign';
import TimelineEventSequenceState from './TimelineEventSequenceState';
import TimelineEventGmailMessage from './TimelineEventGmailMessage';
import TimelineEventSocialMessage from './TimelineEventSocialMessage';
import TimelineEventOrganizationEdit from './TimelineEventOrganizationEdit';

import moment from 'moment';

import {getUserName} from '../../../../../lib/helpers';

import HardwareKeyboardArrowDown from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import HardwareKeyboardArrowUp from 'material-ui/svg-icons/hardware/keyboard-arrow-up';

import ActionAlarm from 'material-ui/svg-icons/action/alarm';
import ActionAssignmentInd from 'material-ui/svg-icons/action/assignment-ind';
import ActionSpeakerNotes from 'material-ui/svg-icons/action/speaker-notes';
import ActionSwapVertical from 'material-ui/svg-icons/action/swap-vert';
import ActionDoneAll from 'material-ui/svg-icons/action/done-all';
import ActionFeedback from 'material-ui/svg-icons/action/feedback';

import CommunicationMail from 'material-ui/svg-icons/communication/mail-outline';
import CommunicationCall from 'material-ui/svg-icons/communication/call';

import ContentMoveToInbox from 'material-ui/svg-icons/content/move-to-inbox';
import ContentUnarchive from 'material-ui/svg-icons/content/unarchive';

import EditorInsertComment from 'material-ui/svg-icons/editor/insert-comment';
import EditorInsertInvitation from 'material-ui/svg-icons/editor/insert-invitation';
import OrganizationEditIcon from 'material-ui/svg-icons/editor/border-color';

import ArrowBack from 'material-ui/svg-icons/navigation/arrow-back';
import ArrowForward from 'material-ui/svg-icons/navigation/arrow-forward';

export default class TimelineEvent extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    reloadOrganization: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    people: PropTypes.array.isRequired,
    section: PropTypes.string.isRequired
  };

  state = {
    expanded: false,
    className: 'timeline-event'
  };

  componentWillMount() {
    const {item, section} = this.props;
    const eventType = this.getEventType(item);
    const expandable = this.getEventExpandable(item, section);
    const expanded = expandable
      ? this.getEventExapanded(eventType, section)
      : false;
    this.setState({
      className: this.buildClassName(expanded, eventType, expandable, section),
      expanded
    });
  }

  buildEvent = (item = this.props.item, section = this.props.section) => ({
    item: item,
    type: this.getEventType(item),
    time: this.getEventTime(item, section),
    expandable: this.getEventExpandable(item, section)
  });

  getEventType = (item) => item.collection || item.type;

  getEventTime = (item, section) => {
    if (section === 'past') {
      switch (item.collection) {
        case 'decisions':
          return moment(item.decision.created_at).format('LT');
        default:
          return moment(
            item.completedDate ||
              item.keen.created_at ||
              new Date(item.keen.timestamp)
          ).format('LT');
      }
    }
    return null;
  };

  getEventExpandable = (item, section) => {
    switch (item.collection) {
      case 'mailings':
      case 'call_feedback':
        return true;
      case 'inboxes':
        const {inbox: {notes, created_at} = {}} = item;
        return (
          !!notes ||
          ((!!created_at || !!item.keen.timestamp) && section !== 'past')
        );
      case 'reconnect':
        return this.props.reconnect
          ? !!this.props.reconnect.reason
          : !!item.reconnect.reason;
      case 'gmail_messages':
        return !!item.message.body;
      case 'communications':
        const {communication: {message: {body} = {}} = {}} = item;
        return !!body;
      default:
        return false;
    }
  };

  getEventExapanded = (eventType, section) =>
    eventType === 'inboxes' && section === 'todo';

  buildClassName = (expanded, type, expandable) => {
    const {section} = this.props;
    const classNames = ['timeline-event', type];
    if (section === 'todo') classNames.push('green-border');
    if (expandable) classNames.push('expandable');
    if (expanded) classNames.push('expanded');
    return classNames.join(' ');
  };

  getTimelineEventIcon = (item, type) => {
    const {section} = this.props;
    switch (type) {
      case 'calendar_event':
        return <EditorInsertInvitation color="#7F7F7F" />;
      case 'call_feedback':
        return <ActionFeedback color="#7F7F7F" />;
      case 'communications':
        return <CommunicationMail color="#7F7F7F" />;
      case 'decisions':
        const previous = this.getPreviousDecision(
          item.decision.created_at,
          item.decisions
        );

        const changed = this.getDecisionChanged(item, previous);
        if (!item || !changed) return;
        switch (changed) {
          case 'assigned_to':
            return <ActionAssignmentInd color="#7F7F7F" />;
          case 'notes':
          case 'status':
            return <ActionSpeakerNotes color="#7F7F7F" />;
          case 'state':
            switch (item.decision.state) {
              case 'never':
                return <div className="icon thumb-down icon-never" />;
              case 'missed_opportunity':
                return <div className="icon rocket icon-missed-opportunity" />;
              case 'not_now':
                return <div className="icon thumb-stop icon-not-now" />;
              case 'contact':
                return <div className="icon thumb-up icon-contact" />;
            }
            break;
          case 'stage':
            return <ActionSwapVertical color="#7F7F7F" />;
        }
        break;
      case 'inboxes':
        const inboxType = 'user_id' in item.inbox ? 'inbox' : 'form';
        return inboxType === 'inbox' ? (
          item.inbox.inbox ? (
            <ContentMoveToInbox color="#337ab7" />
          ) : (
            <ContentUnarchive color="#337ab7" />
          )
        ) : (
          <EditorInsertComment color="#7F7F7F" />
        );
      case 'tasks':
      case 'inreachTask':
        return (
          <ActionDoneAll
            color={section === 'upcoming' ? '#939598' : '#7F7F7F'}
          />
        );
      case 'gmail_messages':
        const {message: {from = []} = {}} = item;
        if (
          item.collection === 'gmail_messages' &&
          !from.includes('inreachventures')
        ) {
          return <ArrowBack color="#5dab47" stroke="#5dab47" strokeWidth="1" />;
        }
        return <ArrowForward color="#7F7F7F" />;
      case 'mailings':
        return <ArrowForward color="#7F7F7F" />;
      case 'pre_qualification':
        return <ActionSwapVertical color="#7F7F7F" />;
      case 'reconnect':
        return <ActionAlarm color="#7F7F7F" />;
      case 'assign_campaign':
        return <ActionAlarm color="#7F7F7F" />;
      case 'sequence_states':
        return <ActionAlarm color="#7F7F7F" />;
      case 'scorecard':
        return <CommunicationCall color="#7F7F7F" />;
      case 'social_messages':
        return (
          <div className="social-link linkedin" style={{fontSize: '24px'}} />
        );
      case 'organizations_edits':
        return <OrganizationEditIcon color="#7F7F7F" />;
      default:
        return null;
    }
  };

  getTimelineEventElement = (item, type) => {
    const {section, organization, reloadOrganization, people} = this.props;
    switch (type) {
      case 'calendar_event':
        return (
          <TimelineEventCalendarEvent item={item} getUserName={getUserName} />
        );
      case 'call_feedback':
        return (
          <TimelineEventCallFeedback item={item} getUserName={getUserName} />
        );
      case 'communications':
        return (
          <TimelineEventCommunications item={item} getUserName={getUserName} />
        );
      case 'decisions':
        const previous = this.getPreviousDecision(
          item.decision.created_at,
          item.decisions
        );
        const changed = this.getDecisionChanged(item, previous);
        if (!item || !changed) return;
        return (
          <TimelineEventDecision
            item={item}
            previous={previous}
            changed={changed}
            getUserName={getUserName}
          />
        );
      case 'inboxes':
        const inboxType = 'user_id' in item.inbox ? 'inbox' : 'form';
        return (
          <TimelineEventInbox
            item={item}
            getUserName={getUserName}
            type={inboxType}
            section={section}
          />
        );
      case 'tasks':
      case 'inreachTask':
        return (
          <TimelineEventTask
            item={item}
            section={section}
            onTaskClick={this.props.onTaskClick}
          />
        );
      case 'gmail_messages':
        return (
          <TimelineEventGmailMessage
            item={item}
            type={type}
            getUserName={getUserName}
          />
        );
      case 'mailings':
        return (
          <TimelineEventMailing
            item={item}
            type={type}
            getUserName={getUserName}
          />
        );
      case 'pre_qualification':
        return (
          <TimelineEventPreQualification
            item={item}
            getUserName={getUserName}
          />
        );
      case 'reconnect':
        return (
          <TimelineEventReconnect
            item={item}
            handleReconnectCancel={this.props.handleReconnectCancel}
            reconnectActive={this.props.reconnectActive}
            reconnect={this.props.reconnect}
          />
        );
      case 'assign_campaign':
        return (
          <TimelineEventAssignCampaign
            item={item}
            handleAssignCampaignCancel={this.props.handleAssignCampaignCancel}
            handleAssignCampaignRetry={this.props.handleAssignCampaignRetry}
          />
        );
      case 'sequence_states':
        return <TimelineEventSequenceState item={item} />;
      case 'scorecard':
        return <TimelineEventScorecard item={item} />;
      case 'social_messages':
        return (
          <TimelineEventSocialMessage
            item={item}
            organization={organization}
            reloadOrganization={reloadOrganization}
            organizationDecision={this.props.organizationDecision}
            handleMakeContactChecklistChange={
              this.props.handleMakeContactChecklistChange
            }
          />
        );
      case 'organizations_edits':
        const organization_edit = !!item.organization_edit
          ? item.organization_edit
          : null;
        const updated_fields = !!organization_edit.updated_fields
          ? organization_edit.updated_fields
          : null;
        if (
          updated_fields.includes('primary_contact_id') ||
          updated_fields.includes('is_complete') ||
          updated_fields.includes('email')
        ) {
          return <TimelineEventOrganizationEdit item={item} people={people} />;
        }
        return null;
      default:
        return null;
    }
  };

  getDecisionChanged = (item, previous) => {
    if (!!!previous) return item.decision.state ? 'state' : 'stage';
    const current = item.decision;
    const stateChanged = current.state !== previous.state;
    const stageChanged = current.stage !== previous.stage;
    if (stateChanged && stageChanged) {
      if (!current.state || !previous.state) {
        return 'stage';
      } else {
        return 'state';
      }
    } else if (stateChanged || stageChanged) {
      if (stateChanged) return 'state';
      if (stageChanged) return 'stage';
    } else {
      if (current.assigned_to !== previous.assigned_to) return 'assigned_to';
      if (current.notes !== previous.notes) return 'notes';
      if (current.status !== previous.status) return 'status';
      if (
        current.source_filters !== previous.source_filters &&
        current.source_filters.id === 'ml_updated'
      )
        return 'state';
    }
    return null;
  };

  getPreviousDecision = (createdAt, previousDecisions) => {
    if (!previousDecisions) return null;
    let sortedPreviousDecisions = previousDecisions.sort(
      (a, b) => a.created_at - b.created_at
    );

    for (const [index, decision] of sortedPreviousDecisions.entries()) {
      if (decision.created_at === createdAt) {
        return previousDecisions.length > 1
          ? index === 0
            ? null
            : previousDecisions[index - 1]
          : null;
      }
      if (index === previousDecisions.length - 1) {
        return previousDecisions[index];
      }
    }
  };

  showIcon = (type, section) =>
    (section !== 'todo' && section !== 'upcoming') ||
    type === 'reconnect' ||
    type === 'social_messages' ||
    type === 'assign_campaign' ||
    'task';

  toggleExpand = (type) => {
    const expanded = !this.state.expanded;
    this.setState({
      className: this.buildClassName(expanded, type, true),
      expanded: expanded
    });
  };

  render() {
    const {item, type, time, expandable} = this.buildEvent();

    const {className, expanded} = this.state;
    const {section} = this.props;
    const TimelineEventElement = this.getTimelineEventElement(item, type);
    if (!TimelineEventElement) return null;
    return (
      <div className="timeline-event-wrapper">
        <If condition={this.showIcon(type, section)}>
          <div className={'timeline-event-icon ' + type}>
            {this.getTimelineEventIcon(item, type)}
          </div>
        </If>
        <div
          className={className}
          onClick={
            !expanded && expandable ? () => this.toggleExpand(type) : undefined
          }
        >
          {TimelineEventElement}
          <If condition={expandable}>
            <Choose>
              <When condition={!expanded}>
                <HardwareKeyboardArrowDown
                  className="timeline-event-toggle-expand"
                  onClick={() => this.toggleExpand(type)}
                  style={{
                    color: '#7f7f7f',
                    cursor: 'pointer'
                  }}
                />
              </When>
              <Otherwise>
                <HardwareKeyboardArrowUp
                  className="timeline-event-toggle-expand"
                  onClick={() => this.toggleExpand(type)}
                  style={{
                    color: '#7f7f7f',
                    cursor: 'pointer'
                  }}
                />
              </Otherwise>
            </Choose>
          </If>
        </div>
        <If condition={section === 'past'}>
          <div className="timeline-event-time">{time}</div>
        </If>
      </div>
    );
  }
}
