import React from 'react';
import PropTypes from 'prop-types';

import xss from 'xss';

import {getUserName, getDateAndTime} from '../../../../lib/helpers';

import DecisionRecommendationDisplay from './../../../components/DecisionRecommendationDisplay';
import DisplayReason from './../../../components/DisplayReason';

export default class ScorecardCallRecommendationDecision extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    content: PropTypes.object.isRequired
  };

  getClassName = (type) =>
    ['scorecard-call-or-form-recommendation-decision', type].join(' ');

  render() {
    const {
      type = '',
      content: {
        [type]: outcome = '',
        user_id: userId = 'roberto@inreachventures.com',
        created_at: createdAt = 0,
        notes = '',
        reasons = {}
      } = {}
    } = this.props;
    const {date, time} = getDateAndTime(createdAt);
    return (
      <div className={this.getClassName(type)}>
        <div className="left">
          <div className="header-row">
            <div className="decision-text">{type}</div>
          </div>
          <div className="content-row">
            <DecisionRecommendationDisplay type={type} outcome={outcome} />
          </div>
        </div>
        <div className="right">
          <div className="header-row">
            <If condition={userId !== ''}>
              <div className="recommendation-decision-user">
                {getUserName(userId)}:
              </div>
            </If>
            <div className="spacer" />
            <If condition={createdAt !== 0}>
              <div className="recommendation-decision-date">
                {`${date} - ${time}`}
              </div>
            </If>
          </div>
          <div className="content-row">
            <DisplayReason reason={notes} structuredReasons={reasons} />
          </div>
        </div>
      </div>
    );
  }
}
