import React from 'react';
import PropTypes from 'prop-types';

import SocialLink from './SocialLink';
import OrganizationMenu from './OrganizationMenu';

import JsonDev from '../JsonDev';

import IconButton from 'material-ui/IconButton';
import ToggleStar from 'material-ui/svg-icons/toggle/star';
import ToggleStarBorder from 'material-ui/svg-icons/toggle/star-border';
import IconMenu from 'material-ui/IconMenu';
import ContentInbox from 'material-ui/svg-icons/content/inbox';
import Subheader from 'material-ui/Subheader';
import MenuItem from 'material-ui/MenuItem';
import Avatar from 'material-ui/Avatar';
import {activeInReachTeam} from '../../lib/helpers';

const buttonStyle = {width: '24px', height: '24px', padding: '0'};
const iconStyle = {fill: '#337ab7'};
const ACTIVE_TEAM = activeInReachTeam();

class Star extends React.Component {
  static propTypes = {
    disabled: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired
  };

  render() {
    const {disabled = false, onClick, active = false} = this.props;
    return (
      <IconButton
        className="inbox-controls-star"
        data-inboxed={`${active}`}
        style={buttonStyle}
        iconStyle={iconStyle}
        onClick={onClick}
        disabled={disabled}
      >
        {active ? <ToggleStar /> : <ToggleStarBorder />}
      </IconButton>
    );
  }
}

class Inbox extends React.Component {
  static propTypes = {
    disabled: PropTypes.bool.isRequired,
    organization: PropTypes.object.isRequired,
    showInboxModal: PropTypes.func.isRequired,
    removeFromInbox: PropTypes.func.isRequired,
    userInboxes: PropTypes.array.isRequired
  };

  handleInboxMenuItemClick = (e, menuItem) => {
    const {
      organization = {},
      showInboxModal,
      removeFromInbox,
      userInboxes = []
    } = this.props;
    const {props: {value: assignedTo} = {}} = menuItem;
    if (userInboxes.includes(assignedTo)) {
      removeFromInbox(organization, assignedTo);
    } else {
      showInboxModal(assignedTo);
    }
  };

  render() {
    const {disabled = true, userInboxes = []} = this.props;
    return (
      <IconMenu
        className="inbox-controls-inbox"
        iconButtonElement={
          <IconButton disabled={disabled} style={buttonStyle}>
            <ContentInbox />
          </IconButton>
        }
        iconStyle={iconStyle}
        anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
        targetOrigin={{horizontal: 'left', vertical: 'top'}}
        useLayerForClickAway={true}
        onItemClick={this.handleInboxMenuItemClick}
      >
        <Subheader>Whose inbox?</Subheader>
        {ACTIVE_TEAM.map(({email, name, imageUrl}) => (
          <MenuItem
            key={email}
            primaryText={name}
            rightIcon={<Avatar src={imageUrl} />}
            value={email}
            checked={userInboxes.includes(email)}
            data-inbox={email}
          />
        ))}
      </IconMenu>
    );
  }
}

export default class TopBarActions extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    email: PropTypes.string.isRequired,
    inboxData: PropTypes.object.isRequired,
    addToInbox: PropTypes.func.isRequired,
    removeFromInbox: PropTypes.func.isRequired,
    showInboxModal: PropTypes.func.isRequired,
    isUpdatingInbox: PropTypes.bool.isRequired,
    userRoles: PropTypes.array.isRequired,
    userHasFullAccess: PropTypes.bool.isRequired,
    decision: PropTypes.object.isRequired,
    handleSendFeedbackMenuItemClick: PropTypes.func.isRequired,
    share: PropTypes.func,
    toggleStageChange: PropTypes.func,
    toggleEditPassCampaign: PropTypes.func.isRequired,
    goToPage: PropTypes.func.isRequired,
    deleteSignals: PropTypes.func,
    toggleManualHelp: PropTypes.func,
    handleTogglePriority: PropTypes.func,
    createQualificationChecklist: PropTypes.func,
    source: PropTypes.string.isRequired
  };

  addToOwnInbox = () => {
    const {organization = {}, email, addToInbox} = this.props;
    addToInbox(organization, email);
  };

  removeFromOwnInbox = () => {
    const {organization = {}, email, removeFromInbox} = this.props;
    removeFromInbox(organization, email);
  };

  showInboxModal = (assignedTo) => {
    const {organization = {}, showInboxModal} = this.props;
    showInboxModal(organization, assignedTo);
  };

  actions = () => {
    const {
      organization = {},
      inboxData: {
        currentUserInbox: {inbox = false} = {},
        userInboxes = []
      } = {},
      removeFromInbox,
      isUpdatingInbox = false,
      userRoles = [],
      source
    } = this.props;
    const {name, homepage_url: website, linkedin, crunchbase} = organization;
    const width = window.innerWidth;
    return (
      <React.Fragment>
        <If
          condition={
            userRoles.includes('admin') &&
            (width >= 768 || source !== 'preview')
          }
        >
          <JsonDev
            organization={organization}
            className="json-icon"
            objectType="organizations"
          />
        </If>
        <If
          condition={
            userRoles.includes('admin') &&
            ((organization.source_refs || {}).OUTREACH || []).length > 0 &&
            (width >= 768 || source !== 'preview')
          }
        >
          <SocialLink
            source="outreach"
            link={`https://app2a.outreach.io/accounts/${
              organization.source_refs.OUTREACH[0]
            }/overview`}
          />
        </If>
        <SocialLink
          source="website"
          link={website}
          additionalClasses={['website']}
        />
        <If condition={width >= 768 || source !== 'preview'}>
          <Choose>
            <When condition={linkedin !== ''}>
              <SocialLink source="linkedin" link={linkedin} />
            </When>
            <Otherwise>
              <SocialLink
                source="linkedin"
                link={`https://www.linkedin.com/search/results/companies?keywords=${encodeURIComponent(
                  name
                )}`}
              />
            </Otherwise>
          </Choose>
        </If>
        <If condition={width >= 768 || source !== 'preview'}>
          <SocialLink source="crunchbase" link={crunchbase} />
        </If>
        <Star
          disabled={isUpdatingInbox}
          onClick={() => {
            inbox ? this.removeFromOwnInbox() : this.addToOwnInbox();
          }}
          active={inbox}
        />
        <If condition={width >= 768 && source !== 'preview'}>
          <Inbox
            disabled={isUpdatingInbox}
            organization={organization}
            showInboxModal={this.showInboxModal}
            removeFromInbox={removeFromInbox}
            userInboxes={userInboxes}
          />
        </If>
      </React.Fragment>
    );
  };

  render() {
    const {
      organization = {},
      userRoles = [],
      userHasFullAccess = false,
      decision = {},
      handleSendFeedbackMenuItemClick,
      share,
      toggleStageChange,
      toggleEditPassCampaign,
      goToPage,
      deleteSignals,
      source,
      toggleManualHelp,
      handleTogglePriority,
      createQualificationChecklist
    } = this.props;
    const actions = this.actions();
    return (
      <div className={['top-bar-actions', source].join(' ')}>
        <If condition={window.innerWidth >= 768 || source === 'preview'}>
          {actions}
        </If>
        <If condition={window.innerWidth >= 768 || source !== 'preview'}>
          <OrganizationMenu
            organization={organization}
            userHasFullAccess={userHasFullAccess}
            decision={decision}
            userRoles={userRoles}
            handleSendFeedbackMenuItemClick={handleSendFeedbackMenuItemClick}
            toggleEditPassCampaign={toggleEditPassCampaign}
            share={share}
            toggleStageChange={toggleStageChange}
            goToPage={goToPage}
            deleteSignals={deleteSignals}
            source={source}
            toggleManualHelp={toggleManualHelp}
            handleTogglePriority={handleTogglePriority}
            createQualificationChecklist={createQualificationChecklist}
            actions={actions}
          />
        </If>
      </div>
    );
  }
}
