import ModelStore from './ModelStore';

var ProducthuntPostStore = function(id) {
  this.id = id;
  this.path = '/providers/producthunt/posts/{id}';

  return this;
};

ProducthuntPostStore.prototype = new ModelStore();

export default ProducthuntPostStore;
