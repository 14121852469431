import React from 'react';
import {withRouter} from 'react-router';
import {Tab, Tabs} from 'material-ui/Tabs';
import CommunicationBusiness from 'material-ui/svg-icons/communication/business';
import ActionAssessment from 'material-ui/svg-icons/action/assessment';
import Assignment from 'material-ui/svg-icons/action/assignment';
import PropTypes from 'prop-types';

class InvestorTabs extends React.Component {
  static propTypes = {
    investor: PropTypes.object.isRequired,
    client: PropTypes.string.isRequired,
    selectedTab: PropTypes.string.isRequired,
    userHasFullAccess: PropTypes.bool.isRequired,
    temporaryShowNote: PropTypes.object,
    updateSelectedTab: PropTypes.func.isRequired
  };

  setSelectedTab = (value) => {
    this.props.updateSelectedTab(value);
    const {id} = this.props.params;
    if (value === 'portfolio') {
      this.props.router.push({
        pathname: `/investors/${id}/${value}`,
        search: `?investor_ids=${id}`
      });
    } else {
      this.props.router.push({
        pathname:
          value === 'profile' ? `/investors/${id}` : `/investors/${id}/${value}`
      });
    }
  };

  render() {
    const {userHasFullAccess, temporaryShowNote, selectedTab} = this.props;
    return (
      <div className="investor-component">
        <Tabs
          onChange={this.setSelectedTab}
          value={this.props.params.tab || 'profile'}
          className={'investor-tabs'}
        >
          <Tab
            label="Profile"
            value="profile"
            icon={window.outerWidth >= 768 ? <CommunicationBusiness /> : null}
            className={
              selectedTab === 'profile'
                ? 'investor-tab selected'
                : 'investor-tab'
            }
          />
          <If condition={userHasFullAccess || !!temporaryShowNote}>
            <Tab
              label="Workflow"
              value="workflow"
              icon={window.outerWidth >= 768 ? <CommunicationBusiness /> : null}
              className={
                selectedTab === 'workflow'
                  ? 'investor-tab selected'
                  : 'investor-tab'
              }
            />
          </If>
          <If condition={userHasFullAccess || !!temporaryShowNote}>
            <Tab
              label="Portfolio"
              value="portfolio"
              icon={window.outerWidth >= 768 ? <ActionAssessment /> : null}
              className={
                selectedTab === 'portfolio'
                  ? 'investor-tab selected'
                  : 'investor-tab'
              }
            />
          </If>
        </Tabs>
      </div>
    );
  }
}

export default withRouter(InvestorTabs);
