import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import Tooltip from '../../Tooltip';
import IndustryIcon from 'material-ui/svg-icons/maps/local-offer';
import _ from 'underscore';
import {capitalizeString} from '../../../lib/helpers';

class IndustriesPreview extends React.Component {
  static propTypes = {
    investor: PropTypes.object.isRequired
  };
  state = {
    showTooltip: false
  };

  hasError = () => {
    return true;
  };

  render() {
    const {
      investor: {
        aggregations,
        aggregations: {most_invested_tags = []} = {}
      } = {}
    } = this.props;
    const {showTooltip} = this.state;
    return (
      <div
        className="data-wrapper geographic-coverage compressed"
        onMouseLeave={() => {
          if (this.hasError()) {
            this.setState({showTooltip: false});
          }
        }}
        onMouseEnter={() => {
          if (this.hasError()) {
            this.setState({showTooltip: true});
          }
        }}
        rel={(el) => (this._parent = el)}
      >
        <Tooltip
          parent={this._parent}
          showTooltip={showTooltip}
          text="Industries (Tags)"
        />
        <div className="data-icon">
          <IndustryIcon className="data-icon-svg" />
        </div>
        <Choose>
          <When
            condition={_.isEmpty(aggregations) || !most_invested_tags.length}
          >
            <div className="data-content text empty">No data</div>
          </When>
          <Otherwise>
            <div className="invesor-profile-row">
              <div className="tags">
                <div className="tags-container">
                  {most_invested_tags.slice(0, 2).map((value, index) => {
                    return (
                      <div
                        className="data-content tag text invesor-profile-div"
                        key={index}
                      >
                        {capitalizeString(value)}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(IndustriesPreview);
