import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../Tooltip';

import {capitalizeString} from '../../lib/helpers';

const extractId = (word) => {
  if (word.substring(0, 1) === '#') {
    let id = '';
    word.split('').forEach((letter) => {
      if (/[a-z_]/.test(letter)) {
        id += letter;
      }
    });
    if (id !== '') return id;
  }
  return null;
};

class StructuredReason extends React.Component {
  static propTypes = {
    structuredReason: PropTypes.object.isRequired
  };

  state = {
    showTooltip: false
  };

  render() {
    const {structuredReason: {title = '', category = ''} = {}} = this.props;
    const {showTooltip = false} = this.state;
    return (
      <span
        className="structured-reason"
        rel={(el) => (this._parent = el)}
        onMouseLeave={() => {
          this.setState({showTooltip: false});
        }}
        onMouseEnter={() => {
          this.setState({showTooltip: true});
        }}
      >
        <Tooltip
          parent={this._parent}
          showTooltip={showTooltip}
          text={capitalizeString(category)}
          customStyles={{
            top: '-28px',
            left: '50%',
            transform: 'translateX(-50%)'
          }}
        />
        {title}
      </span>
    );
  }
}

export default class DisplayReason extends React.Component {
  static propTypes = {
    reason: PropTypes.string.isRequired,
    structuredReasons: PropTypes.object.isRequired
  };

  generateReasonHTML = () => {
    const {reason = '', structuredReasons = {}} = this.props;
    if (reason === '') return <i>No notes recorded</i>;
    const newReason = [];
    reason.split(' ').forEach((word) => {
      const id = extractId(word) || null;
      if (id !== null && typeof structuredReasons[id] !== 'undefined') {
        if (
          newReason.length !== 0 &&
          typeof newReason[newReason.length - 1] !== 'string'
        ) {
          newReason.push(', ');
        }
        newReason.push(
          <StructuredReason
            structuredReason={structuredReasons[id]}
            key={structuredReasons + id}
          />
        );
        if (word.substring(word.length - 1, word.length) === '.') {
          newReason.push('. ');
        }
      } else {
        if (
          newReason.length === 0 ||
          typeof newReason[newReason.length - 1] !== 'string'
        ) {
          if (newReason.length === 0) {
            newReason.push(`${word} `);
          } else {
            newReason.push(` ${word} `);
          }
        } else {
          newReason[newReason.length - 1] += `${word} `;
        }
      }
    });
    return newReason;
  };

  render() {
    return <div className="display-reason">{this.generateReasonHTML()} </div>;
  }
}
