import React from 'react';
import PropTypes from 'prop-types';

import TimelineEventActions from './TimelineEventActions.js';

import moment from 'moment';

export default class TimelineEventCalendarEvent extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    getUserName: PropTypes.func.isRequired
  };

  buildItem = (item = this.props.item) => {
    const calendarEvent = item.calendar_event;
    const actions = this.getCalendarEventActions(calendarEvent);
    const labels = this.getCalendarEventLabels(
      calendarEvent.creator.email,
      calendarEvent.summary,
      calendarEvent.start
    );
    return {
      label: labels.label,
      subLabel: labels.subLabel,
      actions: actions
    };
  };

  getCalendarEventActions = (calendarEvent) => {
    const actions = [];
    if (calendarEvent.html_link)
      actions.push({
        text: 'Event',
        href: calendarEvent.html_link
      });
    return actions.length > 0 ? actions : null;
  };

  getCalendarEventLabels = (creator, summary, start) => {
    const userName = this.props.getUserName(creator) || creator;
    return {
      label: `${userName} created a new calendar event`,
      subLabel: `- ${summary} - ${moment(new Date(start)).format(
        'ddd Do MMM YYYY'
      )}`
    };
  };

  render() {
    const {label, subLabel, actions} = this.buildItem();
    return (
      <div className="timeline-event-inner-wrapper">
        <h5 className="timeline-event-label">{label}</h5>
        <p className="timeline-event-sub-label">{subLabel}</p>
        <div className="spacer" />
        <If condition={actions !== null}>
          <TimelineEventActions actions={actions} />
        </If>
      </div>
    );
  }
}
