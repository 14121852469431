import auth from './Auth';
import Config from '../config';
import request from 'superagent';

export default class JobOpeningStore {
  constructor(ids) {
    const host = Config.api.host;
    this.url = `${host}/job_openings/${ids.join(',')}`;
  }

  jobOpenings() {
    return auth.getAuthData().then(
      (authData) =>
        new Promise((resolve, reject) => {
          request
            .get(this.url)
            .query({
              access_token: authData.token,
              client: authData.client
            })
            .set('Accept', 'application/json')
            .end((error, response) => {
              if (response && response.ok) {
                if (
                  response.body.models &&
                  Array.isArray(response.body.models)
                ) {
                  resolve(response.body.models);
                } else {
                  resolve([response.body]);
                }
              } else {
                reject(error);
              }
            });
        })
    );
  }
}
