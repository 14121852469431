import Config from '../config';
import auth from './Auth';
import request from 'superagent';

const CACHE = {};

export default class InvestorsStore {
  constructor(organizationId) {
    this.url = `${Config.api.host}/organizations/${organizationId}/investors`;
  }

  loadInvestors() {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(this.url)
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .set('Accept', 'application/json')
      )
      .then(({body: {investors = []} = {}}) => {
        investors.forEach((investor) => {
          CACHE[investor.id] = investor;
        });
        return investors;
      });
  }

  getInvestors(ids = []) {
    if (ids.filter((id) => !(id in CACHE)).length === 0) {
      return Promise.resolve(ids.map((id) => CACHE[id]));
    } else {
      return this.loadInvestors();
    }
  }
}
