import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'material-ui/Toggle';

class InReachToggle extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    toggled: PropTypes.bool.isRequired
  };

  handleToggle = (e) => {
    this.props.onToggle(e, !this.props.toggled);
  };

  render() {
    return (
      <Toggle
        label={this.props.label}
        labelStyle={this.props.labelStyle}
        toggled={this.props.toggled}
        thumbSwitchedStyle={{
          backgroundColor: 'rgb(93, 171, 73)'
        }}
        trackSwitchedStyle={{
          backgroundColor: 'rgba(93, 171, 73, 0.6)'
        }}
        style={{
          width: 'auto',
          ...this.props.elementStyle
        }}
        onToggle={this.handleToggle}
      />
    );
  }
}

export default InReachToggle;
