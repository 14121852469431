import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import Ratings from '../../Ratings';

class BasicInfo extends React.Component {
  static propTypes = {
    info: PropTypes.object.isRequired
  };

  render() {
    const {
      info: {image, name, store}
    } = this.props;
    return (
      <div className="basic-info">
        <div className="grid-container basic-info-grid-container">
          <div className="basic-info-grid-item image-grid-item">
            <div
              className="product-image"
              style={{
                backgroundImage: `url('${image}')`
              }}
            />
          </div>
          <div className="basic-info-grid-item name-grid-item">
            <div className="name-container">{`${name} (${
              store === 'IOS' ? 'iOS' : 'Android'
            })`}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(BasicInfo);
