import React from 'react';
import Loading from '../loading/Loading';

import OxyleadsMemberStore from '../../storage/OxyleadsMemberStore';

const IGNORED_KEYS = [
  'id',
  'member_id',
  'person_id',
  'created',
  'last_updated',
  'created_at',
  'updated_at',
  'hash',
  'deleted'
];
class OxyleadsMember extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentWillMount() {
    this.oxyleadsMemberStore = new OxyleadsMemberStore(this.props.id);
    this.oxyleadsMemberStore
      .getModel()
      .then((member) => this.setState({member}))
      .catch((err) =>
        console.log('Problem fetching oxyleads member', this.props.id, err)
      );
  }

  renderValue(value) {
    return <dd>{value}</dd>;
  }

  renderArray(array) {
    return (
      <dd>
        <ul>
          {array.map((value, i) => (
            <li key={i}>{this.renderUnknown(value)}</li>
          ))}
        </ul>
      </dd>
    );
  }

  renderObject(object) {
    return (
      <dl>
        {Object.keys(object)
          .filter((key) => !IGNORED_KEYS.includes(key))
          .map((key) => {
            const value = object[key];
            if (!value) {
              return <span key={key} />;
            }
            return (
              <div key={key}>
                <dt className="title capitalize">{key.split('_').join(' ')}</dt>
                <dd>{this.renderUnknown(value)}</dd>
              </div>
            );
          })}
      </dl>
    );
  }

  renderUnknown(unknown) {
    if (Array.isArray(unknown)) {
      return this.renderArray(unknown);
    }
    if (unknown instanceof Object) {
      return this.renderObject(unknown);
    }
    return this.renderValue(unknown);
  }

  render() {
    const member = this.state.member;
    if (!member) {
      return <Loading />;
    }

    return this.renderObject(member);
  }
}

export default OxyleadsMember;
