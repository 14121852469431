import React from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router';

import PreviewOrganizationAvatar from './PreviewOrganizationAvatar';
import PreviewOrganizationName from './PreviewOrganizationName';
import TopBarActions from '../TopBarActions';
import PreviewOrganizationInboxStatus from './PreviewOrganizationInboxStatus';
import State from '../../State';
import SummarySection from '../profile/summary/SummarySection';
import OrganizationStage from '../../components/OrganizationStage';
import PassCustomizeCampaign from '../../assign/PassCustomizeCampaign';
import SendFeedbackDialog from '../SendFeedbackDialog';

import inboxHelper from '../../../lib/InboxHelper';
import Organizations from '../../../lib/Organizations';

import OrganizationStore from '../../../storage/OrganizationStore';
import InvestorsStore from '../../../storage/InvestorsStore';
import RaisedButton from 'material-ui/RaisedButton';
import {ToolbarGroup} from 'material-ui/Toolbar';
import EvaluationBar from '../../assign/EvaluationBar';

function hasForm(organization) {
  const {source_refs: sourceRefs = {}} = organization;
  return (
    sourceRefs.hasOwnProperty('FUNDING') ||
    sourceRefs.hasOwnProperty('V2FUNDING')
  );
}

class PreviewOrganization extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    client: PropTypes.string.isRequired,
    addToInbox: PropTypes.func.isRequired,
    removeFromInbox: PropTypes.func.isRequired,
    showInboxModal: PropTypes.func.isRequired,
    organizationsStoreParams: PropTypes.object.isRequired,
    filterSource: PropTypes.object.isRequired,
    returnUrl: PropTypes.string.isRequired,
    isUpdatingInbox: PropTypes.bool.isRequired,
    showPredictions: PropTypes.bool,
    activeInboxUser: PropTypes.string.isRequired,
    handleErrorOpen: PropTypes.func.isRequired,
    userHasFullAccess: PropTypes.bool.isRequired,
    userRoles: PropTypes.array.isRequired,
    handleErrorOpenDialog: PropTypes.func.isRequired,
    reloadOrganization: PropTypes.func.isRequired,
    source: PropTypes.string.isRequired,
    feedbackCategories: PropTypes.array.isRequired,
    structuredReasons: PropTypes.object.isRequired,
    handleOrganizationChange: PropTypes.func.isRequired
  };

  state = {
    showSendFeedbackDialog: false,
    feedbackCategories: [],
    isEditingPassCampaign: false,
    investors: [],
    isChangingStage: false,
    investorsLoading: true
  };

  organizationStore = new OrganizationStore(this.props.organization.id);
  investorsStore = new InvestorsStore(this.props.organization.id);

  getPreviewOrganizationClassNames = () => {
    const {source} = this.props;
    const classNames = ['preview-organization'];
    if (source) {
      classNames.push(source);
    }
    return classNames.join(' ');
  };

  getInvestors = () => {
    const {organization: {investor_ids = []} = {}} = this.props;
    this.investorsStore.getInvestors(investor_ids).then((investors) => {
      this.setState({
        investors,
        investorsLoading: false
      });
    });
  };

  handleOrganizationStateChange = (assignCampaign) => {
    const {
      organization,
      handleErrorOpen,
      handleOrganizationChange
    } = this.props;
    const {decision: {state = null} = {}} = assignCampaign;
    if (mixpanel) {
      try {
        mixpanel.track('Evaluated organization', {
          organizationId: organization.id,
          organizationName: organization.name,
          state,
          page: 'list'
        });
        mixpanel.time_event('Evaluated organization');
      } catch (e) {
        console.error('Failure sending event to mixpanel', e);
      }
    }

    return this.organizationStore
      .setDecision(assignCampaign)
      .then(handleOrganizationChange)
      .catch((error) => {
        handleErrorOpen(error.message, organization);
      });
  };

  handleSendFeedbackMenuItemClick = () => {
    this.handleToggleSendFeedbackDialog(true);
  };

  handleToggleSendFeedbackDialog = (
    show = !this.state.showSendFeedbackDialog
  ) => {
    this.setState({
      showSendFeedbackDialog: show
    });
  };

  handleSendFeedbackClick = (categories = [], notes) => {
    const {
      profile: {email}
    } = this.props;
    const feedback = {
      user_id: email
    };
    if (categories.length > 0) {
      feedback.categories = categories;
    }
    if (notes) {
      feedback.notes = notes;
    }
    return this.organizationStore.sendFeedback(feedback);
  };

  goToPage = (page) => {
    const {router, location, organization: {id} = {}} = this.props;
    router.push({
      ...location,
      ...{
        pathname: `/organizations/${id}/${page}`
      }
    });
  };

  toggleEditPassCampaign = (
    isEditingPassCampaign = !this.state.isEditingPassCampaign
  ) => {
    this.setState({isEditingPassCampaign});
  };

  submitPass = (assignCampaign) =>
    this.handleOrganizationStateChange(assignCampaign).then(() =>
      this.toggleEditPassCampaign(false)
    );

  getProductType = (useSnapshot = false) => {
    return Organizations.primaryTrendTypeFromOrganizationOrSnapshot(
      useSnapshot,
      this.props
    );
  };

  getProductRatings = (productType, useSnapshot = false) => {
    return productType === 'app'
      ? this.getPrimaryAppRatings(useSnapshot)
      : {ratings: null, ratingCount: null};
  };

  getPrimaryAppRatings = (useSnapshot = false) => {
    const {
      organization: {products = []} = {},
      snapshot: {organization: {snapshotProducts = []} = {}} = {}
    } = this.props;
    const {rating = 0, rating_count: ratingCount = 0} =
      (!useSnapshot ? products : snapshotProducts).find(
        ({id}) => id === this.getPrimaryAppId(useSnapshot)
      ) || {};
    return {
      rating,
      ratingCount
    };
  };

  getProductAnalytics = (
    type,
    appId = null,
    isPrimaryApp = false,
    useSnapshot = false
  ) => {
    return {
      signals: this.getProductSignals(),
      magnitude: this.getProductTrendData('magnitude', useSnapshot),
      beta: this.getProductTrendData('beta', useSnapshot)
    };
  };

  getProductSignals = () => {
    const {organization = {}, client} = this.props;
    return Organizations.primarySignals(organization, client);
  };

  getPrimaryAppId = (useSnapshot = false) => {
    return Organizations.primaryTrendAppIdFromOrganizationOrSnapshot(
      useSnapshot,
      this.props
    );
  };

  getProductTrendData = (type, useSnapshot = false) => {
    return Organizations.primaryTrendDataFromOrganizationOrSnapshot(
      type,
      useSnapshot,
      this.props
    );
  };

  handleOrganizationChange = (organization) => {
    this.setState({
      organization
    });

    document.title = `${organization.name} | InReach`;
  };

  handleOrganizationStageChange = (stage, notes) => {
    return this.organizationStore
      .updateDecisionStage(stage, notes)
      .then(this.handleOrganizationChange)
      .catch((err) => this.showErrorDialog(err));
  };

  render() {
    const {
      organization = {},
      profile: {email} = {},
      client,
      addToInbox,
      removeFromInbox,
      showInboxModal,
      organizationsStoreParams = {},
      filterSource = {},
      returnUrl,
      isUpdatingInbox = false,
      showPredictions = false,
      activeInboxUser,
      userHasFullAccess = false,
      userRoles = [],
      handleErrorOpenDialog,
      reloadOrganization,
      source,
      feedbackCategories = [],
      structuredReasons = {}
    } = this.props;

    const {
      showSendFeedbackDialog = false,
      isEditingPassCampaign = false,
      investors = [],
      investorsLoading = true
    } = this.state;

    const decision =
      Organizations.getOrganizationState(organization, client) || {};

    const {form: outbound = false} = decision;

    const inboxData = {
      currentUserInbox: inboxHelper.getInbox(organization, client, email),
      userInboxes: inboxHelper
        .getUserInboxes(organization, client, email)
        .filter(({inbox}) => !!inbox)
        .map(({assigned_to}) => assigned_to),
      activeUserInbox: inboxHelper.getInbox(
        organization,
        client,
        activeInboxUser
      ),
      form: {
        hasForm: hasForm(organization),
        inbound: !outbound
      }
    };

    return (
      <div className={this.getPreviewOrganizationClassNames()}>
        <div className="preview-organization--header">
          <div className="preview-organization--header-bar">
            <PreviewOrganizationAvatar organization={organization} />
            <PreviewOrganizationName
              organization={organization}
              organizationsStoreParams={organizationsStoreParams}
              filterSource={filterSource}
              returnUrl={returnUrl}
            />
            <If condition={source === 'incomplete'}>
              <Link
                className="edit-incomplete-button-link"
                to={{
                  pathname: `/organizations/${organization.id}/edit_incomplete`,
                  state: {
                    organizationsStoreParams,
                    returnUrl
                  }
                }}
              >
                <RaisedButton
                  className="edit-incomplete-button"
                  label="EDIT INCOMPLETE"
                  backgroundColor="#5dab49"
                  labelColor="#ffffff"
                />
              </Link>
            </If>
            <TopBarActions
              organization={organization}
              email={email}
              inboxData={inboxData}
              addToInbox={addToInbox}
              removeFromInbox={removeFromInbox}
              showInboxModal={showInboxModal}
              isUpdatingInbox={isUpdatingInbox}
              userRoles={userRoles}
              userHasFullAccess={userHasFullAccess}
              decision={decision}
              handleSendFeedbackMenuItemClick={
                this.handleSendFeedbackMenuItemClick
              }
              toggleEditPassCampaign={this.toggleEditPassCampaign}
              goToPage={this.goToPage}
              source="preview"
            />
          </div>
          <div className="preview-organization--header-bar">
            <Choose>
              <When condition={source === 'inbox'}>
                <PreviewOrganizationInboxStatus data={inboxData} />
              </When>
              <Otherwise>
                <div className="spacer" />
              </Otherwise>
            </Choose>
            <If condition={decision && decision.stage && userHasFullAccess}>
              <div className="stage-reason-container">
                <OrganizationStage
                  decision={decision}
                  showStage={false}
                  compressed={true}
                />
              </div>
            </If>
            <State
              client={client}
              organization={organization}
              showPredictions={showPredictions}
              onFormEvaluationChange={() => {}}
              userHasFullAccess={userHasFullAccess}
              userRoles={userRoles}
              onStateChange={this.handleOrganizationStateChange}
              handleError={handleErrorOpenDialog}
              reloadOrganization={reloadOrganization}
              filterSource={filterSource}
            />
          </div>
          <If condition={userHasFullAccess && decision.state === 'contact'}>
            <EvaluationBar
              organizationDecision={decision}
              onStageChange={this.handleOrganizationStageChange}
              hideLabel={true}
              {...this.props}
            />
          </If>
        </div>

        <div className="previous-organization--content">
          <div className="summary-section">
            <SummarySection
              organization={organization}
              snapshot={{}}
              snapshotLoading={false}
              useSnapshot={false}
              investors={investors}
              investorsLoading={investorsLoading}
              getInvestors={this.getInvestors}
              signals={{}}
              signalsLoading={false}
              fundingRounds={[]}
              fundingRoundsLoading={false}
              investmentValues={Organizations.getInvestmentValues(organization)}
              getProductType={this.getProductType}
              getProductRatings={this.getProductRatings}
              getProductAnalytics={this.getProductAnalytics}
              getProductTrend={() => {}}
              getPrimaryAppId={() => {}}
              compressed={true}
            />
          </div>
        </div>
        <If condition={isEditingPassCampaign}>
          <PassCustomizeCampaign
            organization={organization}
            client={client}
            organizationDecision={decision}
            open={isEditingPassCampaign}
            handleClose={this.toggleEditPassCampaign}
            handleSubmit={this.submitPass}
            recentMessages={{}}
            structuredReasons={structuredReasons}
          />
        </If>
        <SendFeedbackDialog
          onCloseDialog={() => this.handleToggleSendFeedbackDialog(false)}
          show={showSendFeedbackDialog}
          handleSendFeedbackClick={this.handleSendFeedbackClick}
          feedbackCategories={feedbackCategories}
        />
      </div>
    );
  }
}

export default withRouter(PreviewOrganization);
