import React from 'react';
import PropTypes from 'prop-types';

import DisplayRecommendationDecision from './DisplayRecommendationDecision';
import MakeRecommendationDecision from './MakeRecommendationDecision';

export default class ScorecardModalRecommendationsDecision extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    recommendations: PropTypes.array.isRequired,
    decision: PropTypes.object.isRequired,
    hasDecision: PropTypes.bool.isRequired,
    userRoles: PropTypes.array.isRequired,
    currentUser: PropTypes.string.isRequired,
    submitRecommendationOrDecision: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    requiredCallDetailsSelected: PropTypes.bool.isRequired,
    showErrorDialog: PropTypes.func.isRequired,
    setModalRecommendationDecisionSaved: PropTypes.func.isRequired,
    structuredReasons: PropTypes.object.isRequired,
    reloadOrganization: PropTypes.func.isRequired,
    commentOnly: PropTypes.bool.isRequired,
    setCommentOnly: PropTypes.func.isRequired,
    callType: PropTypes.string.isRequired
  };

  hasPreviousRecommendation = () => {
    const {recommendations = [], currentUser} = this.props;
    if (recommendations.length > 1) {
      return true;
    } else if (recommendations.length > 0) {
      return !this.hasUserMadeARecommendation(currentUser);
    }
    return false;
  };

  hasUserMadeARecommendation = (user) => {
    const {recommendations = []} = this.props;
    return !!recommendations.find(
      (recommendation) => recommendation.user_id === user
    );
  };

  render() {
    const {
      organization,
      recommendations,
      decision,
      hasDecision,
      userRoles,
      currentUser,
      submitRecommendationOrDecision,
      type,
      requiredCallDetailsSelected,
      showErrorDialog,
      setModalRecommendationDecisionSaved,
      structuredReasons = {},
      reloadOrganization,
      commentOnly,
      setCommentOnly,
      callType
    } = this.props;
    const hasPreviousRecommendation = this.hasPreviousRecommendation();
    return (
      <div className="recommendations-decision-wrapper">
        <If condition={recommendations.length > 0}>
          {recommendations.map((recommendation) => {
            return (
              <Choose>
                <When
                  condition={
                    type !== 'view' &&
                    recommendation.user_id === currentUser &&
                    !hasDecision
                  }
                >
                  <MakeRecommendationDecision
                    organization={organization}
                    key={recommendation.notes}
                    type="recommendation"
                    user={currentUser}
                    submitRecommendationOrDecision={
                      submitRecommendationOrDecision
                    }
                    content={recommendation}
                    hasPreviousRecommendation={hasPreviousRecommendation}
                    requiredCallDetailsSelected={requiredCallDetailsSelected}
                    showErrorDialog={showErrorDialog}
                    setModalRecommendationDecisionSaved={
                      setModalRecommendationDecisionSaved
                    }
                    structuredReasons={structuredReasons}
                    reloadOrganization={reloadOrganization}
                    commentOnly={commentOnly}
                    setCommentOnly={setCommentOnly}
                    callType={callType}
                  />
                </When>
                <Otherwise>
                  <DisplayRecommendationDecision
                    key={recommendation.notes}
                    type="recommendation"
                    content={recommendation}
                  />
                </Otherwise>
              </Choose>
            );
          })}
        </If>
        <Choose>
          <When condition={hasDecision}>
            <Choose>
              <When
                condition={type !== 'view' && decision.user_id === currentUser}
              >
                <MakeRecommendationDecision
                  organization={organization}
                  type="decision"
                  user={currentUser}
                  submitRecommendationOrDecision={
                    submitRecommendationOrDecision
                  }
                  content={decision}
                  hasPreviousRecommendation={hasPreviousRecommendation}
                  requiredCallDetailsSelected={requiredCallDetailsSelected}
                  showErrorDialog={showErrorDialog}
                  setModalRecommendationDecisionSaved={
                    setModalRecommendationDecisionSaved
                  }
                  structuredReasons={structuredReasons}
                  reloadOrganization={reloadOrganization}
                  commentOnly={commentOnly}
                  setCommentOnly={setCommentOnly}
                  callType={callType}
                />
              </When>
              <Otherwise>
                <DisplayRecommendationDecision
                  type="decision"
                  content={decision}
                />
              </Otherwise>
            </Choose>
          </When>
          <Otherwise>
            <Choose>
              <When condition={userRoles.indexOf('partner') !== -1}>
                <MakeRecommendationDecision
                  organization={organization}
                  type="decision"
                  user={currentUser}
                  submitRecommendationOrDecision={
                    submitRecommendationOrDecision
                  }
                  hasPreviousRecommendation={hasPreviousRecommendation}
                  requiredCallDetailsSelected={requiredCallDetailsSelected}
                  showErrorDialog={showErrorDialog}
                  setModalRecommendationDecisionSaved={
                    setModalRecommendationDecisionSaved
                  }
                  structuredReasons={structuredReasons}
                  reloadOrganization={reloadOrganization}
                  commentOnly={commentOnly}
                  setCommentOnly={setCommentOnly}
                  callType={callType}
                />
              </When>
              <Otherwise>
                <If condition={!this.hasUserMadeARecommendation(currentUser)}>
                  <MakeRecommendationDecision
                    organization={organization}
                    type="recommendation"
                    user={currentUser}
                    submitRecommendationOrDecision={
                      submitRecommendationOrDecision
                    }
                    hasPreviousRecommendation={hasPreviousRecommendation}
                    requiredCallDetailsSelected={requiredCallDetailsSelected}
                    showErrorDialog={showErrorDialog}
                    setModalRecommendationDecisionSaved={
                      setModalRecommendationDecisionSaved
                    }
                    structuredReasons={structuredReasons}
                    reloadOrganization={reloadOrganization}
                    commentOnly={commentOnly}
                    setCommentOnly={setCommentOnly}
                    callType={callType}
                  />
                </If>
              </Otherwise>
            </Choose>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}
