import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import moment from 'moment';

class Released extends React.Component {
  static propTypes = {
    released: PropTypes.number.isRequired
  };

  getReleasedDate = () => {
    const {released} = this.props;
    return `${moment(released).fromNow()} (${new Date(
      released
    ).getFullYear()})`;
  };

  render() {
    const released = this.getReleasedDate();
    return (
      <div className="data-wrapper price">
        <div className="data-header">Launched</div>
        <div className="data-content text">{released}</div>
      </div>
    );
  }
}

export default withRouter(Released);
