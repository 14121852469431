import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';

export default class NameCell extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired
  };

  render() {
    const {
      organization: {id, name},
      organizationsStoreParams,
      filterSource = {},
      returnUrl = '',
      openInNewTab = false
    } = this.props;
    return (
      <Link
        className="name-cell org-name trunc-cell-text"
        target={openInNewTab ? '_blank' : null}
        to={{
          pathname: `/organizations/${id}`,
          state: {organizationsStoreParams, returnUrl, filterSource}
        }}
      >
        {name}
      </Link>
    );
  }
}
