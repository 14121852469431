export default {
  pre_product: {
    name: 'Pre Product'
  },
  pre_product_market_fit: {
    name: 'Pre Product-Market Fit'
  },
  pre_marketing_at_scale: {
    name: 'Pre Marketing-at-Scale'
  },
  later_stages: {
    name: 'Later Stages'
  },
  other: {
    name: 'Other'
  }
};
