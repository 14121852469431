import React from 'react';
import PropTypes from 'prop-types';

import OrganizationStore from '../../storage/OrganizationStore';
import auth from '../../storage/Auth';

import Loading from '../loading/Loading';
import PreviewOrganization from '../organization/preview/PreviewOrganization';

export default class Companies extends React.Component {
  static propTypes = {
    organizationIds: PropTypes.array.isRequired
  };

  state = {
    organizations: null,
    userHasFullAccess: false,
    userRoles: [],
    client: null,
    profile: null
  };

  organizationStore = new OrganizationStore(this.props.organizationIds);

  componentWillMount() {
    this.organizationStore
      .getModels()
      .then((organizations) => {
        this.setState({organizations});
      })
      .catch((err) => console.error(err));
    auth
      .getAuthData()
      .then(({accessType, roles, profile, client}) => {
        this.setState({
          userHasFullAccess: accessType === 'full',
          userRoles: roles,
          profile,
          client
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  handleOrganizationChange = (organization) => {
    const {organizations = []} = this.state;
    const i = organizations.findIndex((o) => o.id === organization.id);
    if (i > -1) {
      organizations[i] = organization;
    } else {
      organizations.push(organization);
    }
    this.setState({organizations});
  };

  render() {
    const {
      organizations,
      client,
      profile,
      userHasFullAccess,
      userRoles
    } = this.state;
    if (organizations === null || client === null) {
      return <Loading />;
    }

    return (
      <div>
        {organizations.map((organization) => {
          return (
            <PreviewOrganization
              key={organization.id}
              organization={organization}
              profile={profile}
              client={client}
              addToInbox={() => {}}
              removeFromInbox={() => {}}
              showInboxModal={() => {}}
              organizationsStoreParams={{}}
              filterSource={{}}
              returnUrl={''}
              isUpdatingInbox={false}
              showPredictions={false}
              activeInboxUser={''}
              handleErrorOpen={(error) => console.error(error)}
              userHasFullAccess={userHasFullAccess}
              userRoles={userRoles}
              handleErrorOpenDialog={(error) => console.error(error)}
              reloadOrganization={() => {}}
              source={'person'}
              feedbackCategories={[]}
              structuredReasons={{}}
              handleOrganizationChange={this.handleOrganizationChange}
            />
          );
        })}
      </div>
    );
  }
}
