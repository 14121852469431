import React from 'react';
import PropTypes from 'prop-types';
import JsonDev from '../JsonDev';
import SocialLink from '../organization/SocialLink';
import InvestorMenu from './InvestorMenu';

class Actions extends React.Component {
  static propTypes = {
    investor: PropTypes.object.isRequired,
    organization: PropTypes.object,
    userRoles: PropTypes.array.isRequired
  };

  render() {
    const {investor, organization = {}, userRoles} = this.props;
    return (
      <React.Fragment>
        <If condition={investor}>
          <If condition={userRoles.includes('admin')}>
            <span className="investor-link top-bar-icons">
              <JsonDev
                investor={investor}
                objectType={'investors'}
                className="json-icon"
              />
            </span>
          </If>

          <If condition={'url' in investor || 'homepage_url' in organization}>
            <span className="investor-link top-bar-icons">
              <SocialLink
                source="website"
                link={investor.url || organization.homepage_url}
              />
            </span>
          </If>

          <If condition={'linkedin' in investor || 'linkedin' in organization}>
            <span className="investor-link top-bar-icons">
              <SocialLink
                source="linkedin"
                link={investor['linkedin'] || organization.linkedin}
              />
            </span>
          </If>
          <If
            condition={
              'crunchbase_url' in investor || 'crunchbase_url' in organization
            }
          >
            <span className="investor-link top-bar-icons">
              <SocialLink
                source="crunchbase"
                link={investor['crunchbase_url'] || organization.crunchbase}
              />
            </span>
          </If>
          <If
            condition={
              !('linkedin' in investor) && !('linkedin' in organization)
            }
          >
            <span className="investor-link top-bar-icons">
              <SocialLink
                source="linkedin"
                link={`https://www.linkedin.com/search/results/people?keywords=${encodeURIComponent(
                  investor.name
                )}`}
              />
            </span>
          </If>
        </If>
      </React.Fragment>
    );
  }
}

export default class TopBarActions extends React.Component {
  static propTypes = {
    investor: PropTypes.object.isRequired,
    organization: PropTypes.object,
    userRoles: PropTypes.array.isRequired,
    source: PropTypes.string.isRequired
  };

  render() {
    const {source} = this.props;
    const actions = <Actions {...this.props} />;
    return (
      <div className={['top-bar-actions', source].join(' ')}>
        <If condition={window.innerWidth >= 768 || source === 'preview'}>
          {actions}
        </If>
        <If condition={window.innerWidth >= 768 || source !== 'preview'}>
          <InvestorMenu actions={actions} {...this.props} />
        </If>
      </div>
    );
  }
}
