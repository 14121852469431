import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import Timeline from 'material-ui/svg-icons/action/timeline';

import Insights from '../Insights';
import TrendSummary from '../TrendSummary';
import Ratings from '../Ratings';
import Analytics from '../Analytics';
import {getDataWrapperClassNames} from '../../../../lib/helpers';
import Tooltip from '../../../Tooltip';

class ProductPerformance extends React.Component {
  static propTypes = {
    product: PropTypes.object.isRequired,
    signalsLoading: PropTypes.bool,
    useSnapshot: PropTypes.bool,
    previous: PropTypes.bool,
    compressed: PropTypes.bool.isRequired
  };

  state = {
    showTooltip: false
  };

  getTooltipText = () => {
    const {compressed = false} = this.props;
    const text = [];
    if (compressed) {
      text.push('Product Performance');
    }
    return text.join('<br/>');
  };

  render() {
    const {
      product: {
        highlights = [],
        risks = [],
        redFlags = [],
        productType,
        ratings = {},
        analytics = {},
        snapshot = {},
        mlUpdates = []
      },
      signalsLoading,
      useSnapshot = false,
      previous = false,
      compressed = false,
      organization
    } = this.props;

    const {showTooltip = false} = this.state;
    return (
      <div
        className="product"
        onMouseLeave={() => {
          if (compressed) {
            this.setState({showTooltip: false});
          }
        }}
        onMouseEnter={() => {
          if (compressed) {
            this.setState({showTooltip: true});
          }
        }}
        ref={(el) => (this._parent = el)}
      >
        <If condition={!compressed}>
          <div className="section-title">{`${
            previous ? 'Previous ' : ''
          }Product${window.innerWidth < 768 ? ' Performance' : ''}`}</div>
        </If>
        <Tooltip
          parent={this._parent}
          showTooltip={showTooltip}
          text={this.getTooltipText()}
          customClasses={['performance-tooltip']}
        />
        <div className="grid-container product-grid-container">
          <div className="product-grid-item highlights-risks-grid-item">
            <div className={getDataWrapperClassNames(compressed)}>
              <div className="data-icon">
                <Timeline className="data-icon-svg" />
              </div>
              <Insights
                highlights={highlights}
                risks={risks}
                redFlags={redFlags}
                mlUpdates={mlUpdates}
                compressed={compressed}
              />
            </div>
          </div>
          <div className="product-grid-item ratings-analytics-grid-item">
            <If condition={productType === 'app'}>
              <Ratings
                ratings={ratings}
                productType={productType}
                compressed={compressed}
              />
            </If>
            <Choose>
              <When condition={compressed && window.innerWidth < 768}>
                <TrendSummary type={productType} trend={analytics} />
              </When>
              <Otherwise>
                <Analytics
                  productType={productType}
                  graphType="spark"
                  analytics={analytics}
                  signalsLoading={signalsLoading}
                  snapshot={snapshot}
                  useSnapshot={useSnapshot}
                  compressed={compressed}
                  organization={organization}
                />
              </Otherwise>
            </Choose>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ProductPerformance);
