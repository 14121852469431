import React from 'react';
import EditOrganizationHeader from '../organization/edit/EditOrganizationHeader';
import {browserHistory} from 'react-router';
import Loading from '../loading/Loading';
import EditPerson from './EditPerson';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import PatchHelper from '../../lib/PatchHelper';
import OxyleadStore from '../../storage/OxyleadStore';

import PersonStore from '../../storage/PersonStore';
import auth from '../../storage/Auth';
import diff from 'deep-diff';

class EditPersonRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialPerson: {},
      person: {},
      isLoading: false,
      isSaving: false,
      saveSuccess: false,
      userHasFullAccess: false,
      errors: {},
      modifiedFields: [],
      showErrorDialog: false,
      errorMessage: null
    };
    this.setField = this.setField.bind(this);

    this.handleCancel = this.handleCancel.bind(this);
    this.handlePersonSave = this.handlePersonSave.bind(this);
    this.updatePersonField = this.updatePersonField.bind(this);
    this.updatePersonFields = this.updatePersonFields.bind(this);
    this.setUpdatedFields = this.setUpdatedFields.bind(this);
    this.handleErrorDialogClose = this.handleErrorDialogClose.bind(this);
    this.mergePerson = this.mergePerson.bind(this);

    const id =
      this.props.params && this.props.params.id
        ? this.props.params.id
        : this.props.personId;
    this.personStore = new PersonStore(id);
    this.oxyleadStore = new OxyleadStore();
  }

  componentWillMount() {
    this.setState({isLoading: true});
    this.personStore
      .getModel()
      .then((person) => {
        this.setState({
          initialPerson: JSON.parse(JSON.stringify(person)),
          person,
          isLoading: false
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          errorMessage: err.toString()
        });
        console.log('Unable to retrieve person', err);
      });

    auth
      .getAuthData()
      .then(({accessType, roles, client}) => {
        this.setState({
          userHasFullAccess: accessType === 'full',
          userRoles: roles,
          client
        });
      })
      .catch((err) => console.error(err));
  }

  setField(e) {
    const person = this.state.person;
    person[e.target.name] = e.target.value;
    this.setState({person: person});
  }

  checkIfErrors(errors) {
    return Object.keys(errors).filter((field) => !!errors[field]).length > 0;
  }

  handleCancel() {
    if (this.props.isInsideDialog) {
      this.props.onCancelEdit();
    } else {
      browserHistory.push(`/people/${this.state.person.id}`);
    }
  }

  handlePersonSave() {
    if (this.state.isSaving || this.state.saveSuccess) return;
    this.setState({
      isSaving: true
    });
    const person = this.state.person;

    this.personStore
      .updateModel(
        person,
        PatchHelper.getPatchDataFromDiffData(
          this.state.initialPerson,
          this.state.person
        )
      )
      .then(({body: updatedPerson}) => {
        this.setState({
          person: updatedPerson,
          initialPerson: updatedPerson,
          isSaving: false,
          saveSuccess: true
        });
        setTimeout(() => {
          this.setState({
            saveSuccess: false,
            modifiedFields: []
          });
          if (this.props.isInsideDialog) {
            this.props.onSavePerson();
          }
        }, 1000);
      })
      .catch((error) => {
        this.setState({
          saveSuccess: false,
          isSaving: false,
          showErrorDialog: true,
          errorMessage: error.toString()
        });
        console.log('err', error);
      });
  }

  updatePersonField(field, value) {
    this.updatePersonFields([
      {
        field: field,
        value: value
      }
    ]);
  }

  updatePersonFields(data) {
    const {person = {}} = this.state;
    this.setUpdatedFields(
      Object.assign(
        {},
        person,
        data.reduce((changes, obj) => {
          changes[obj.field] = obj.value;
          return changes;
        }, {})
      )
    );
  }

  setUpdatedFields(person) {
    const modifiedFields = this.getModifiedFields(
      this.state.initialPerson,
      person
    );
    this.setState({
      modifiedFields,
      person
    });
  }

  getModifiedFields(initialPerson, modifiedPerson) {
    const personDiff = diff(initialPerson, modifiedPerson);

    return personDiff
      ? personDiff.map(
          (diffEntry) => (diffEntry.path ? diffEntry.path[0] : null)
        )
      : [];
  }

  handleErrorDialogClose() {
    this.setState({
      errorMessage: null,
      showErrorDialog: false
    });
  }

  mergePerson(person) {
    const mergedPerson = Object.assign({}, this.state.person, person),
      modifiedFields = this.state.modifiedFields.concat(Object.keys(person));

    this.setState({
      person: mergedPerson,
      modifiedFields
    });
  }

  render() {
    const person = this.state.person,
      client = this.state.client;

    const actions = [
      <FlatButton
        label="Close"
        primary={true}
        onClick={this.handleErrorDialogClose}
      />
    ];

    if (!this.state.person || !client || this.state.isLoading) {
      return <Loading />;
    }

    return (
      <div className="edit-view">
        <EditOrganizationHeader
          isInsideDialog={this.props.isInsideDialog}
          isSaving={this.state.isSaving}
          saveSuccess={this.state.saveSuccess}
          cancel={this.handleCancel}
          confirm={this.handlePersonSave}
          disable={
            this.checkIfErrors(this.state.errors) ||
            this.state.modifiedFields.length === 0 ||
            !this.state.person.name
          }
          title={`Edit ${this.state.person.name}`}
          source="edit-person"
        />
        <EditPerson
          isInsideDialog={this.props.isInsideDialog}
          initialPerson={this.state.initialPerson}
          person={person}
          organizationId={this.props.organizationId}
          modifiedFields={this.state.modifiedFields}
          getPersonFromLinkedinURL={this.oxyleadStore.getPersonFromLinkedinURL}
          updatePersonField={this.updatePersonField}
          updatePersonFields={this.updatePersonFields}
          mergePerson={this.mergePerson}
        />
        <Dialog
          title="Error while adding new person"
          actions={actions}
          modal={false}
          open={this.state.showErrorDialog}
          onRequestClose={this.handleErrorDialogClose}
        >
          {this.state.errorMessage}
        </Dialog>
      </div>
    );
  }
}

export default EditPersonRoute;
