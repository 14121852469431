import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import Description from '../../organization/profile/summary/Description';
import InvestorPreviewSummary from './InvestorPreviewSummary';
import PreviewInvestorHeader from './PreviewInvestorHeader';

class PreviewInvestor extends React.Component {
  static propTypes = {
    investor: PropTypes.object.isRequired,
    returnUrl: PropTypes.string.isRequired,
    userRoles: PropTypes.array.isRequired,
    client: PropTypes.string.isRequired,
    showAsPublicProfile: PropTypes.bool,
    userHasFullAccess: PropTypes.bool.isRequired
  };

  state = {};

  render() {
    const {
      investor = {},
      userRoles,
      client,
      userHasFullAccess,
      returnUrl
    } = this.props;
    const {
      description = '',
      short_description: shortDescription = ''
    } = investor;
    return (
      <React.Fragment>
        <div className="preview-organization--header">
          <PreviewInvestorHeader
            investor={investor}
            userRoles={userRoles}
            client={client}
            returnUrl={returnUrl}
            userHasFullAccess={userHasFullAccess}
          />
        </div>
        <div className="previous-organization--content">
          <div className="summary-section">
            <InvestorPreviewSummary investor={investor} isTop={true} />
            <Description
              description={{description, shortDescription}}
              compressed={true}
            />
            <InvestorPreviewSummary investor={investor} isTop={false} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(PreviewInvestor);
