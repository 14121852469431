import React from 'react';

function getLabel(key) {
  switch (key) {
    case 'investmentType':
      return 'Investment Type';
    case 'investmentStage':
      return 'Investment Stage';
    case 'revenueModels':
      return 'Revenue Models';
    case 'saas':
      return 'SaaS';
    case 'consumer':
      return 'Consumer';
    case 'marketplace':
      return 'Marketplace';
    case 'other':
      return 'Other';
    case 'pre_product':
      return 'Pre Product';
    case 'pre_product_market_fit':
      return 'Pre Product Market Fit';
    case 'pre_marketing_at_scale':
      return 'Pre Marketing at Scale';
    case 'one_off_sales':
      return 'One Off Sales';
    case 'recurring':
      return 'Recurring';
    case 'commission':
      return 'Commission';
    case 'advertising':
      return 'Ads';
    case 'service':
      return 'Service';
    default:
      return key;
  }
}

export function buildClassName(selected, ml, disabled) {
  const classNames = ['call-details-content-multi-selector-value'];
  if (selected) {
    classNames.push('selected');
    if (ml) classNames.push('ml');
  }
  if (disabled) classNames.push('disabled');
  return classNames.join(' ');
}

const investmentTypes = ['saas', 'consumer', 'marketplace', 'other'];
const investmentStages = [
  'pre_product',
  'pre_product_market_fit',
  'pre_marketing_at_scale'
];
const revenueModels = [
  'recurring',
  'one_off_sales',
  'commission',
  'advertising',
  'service',
  'other'
];

function editRevenueModels(
  selectedValues,
  disabled,
  updateInvestmentInfo,
  investmentInfo,
  element
) {
  if (
    !disabled &&
    (!selectedValues.length || !selectedValues.includes(element))
  ) {
    selectedValues.push(element);
    updateInvestmentInfo({[investmentInfo]: selectedValues});
  } else if (!disabled && selectedValues.includes(element)) {
    const filteredValueRevenue = selectedValues.filter(
      (item) => item !== element
    );
    updateInvestmentInfo({[investmentInfo]: filteredValueRevenue});
  }
}

export function BuildRevenueModel(props) {
  const {
    selectedValues = [],
    updateInvestmentInfo,
    disabled,
    investmentInfo
  } = props;
  const values = selectedValues;
  return revenueModels.map((element) => (
    <div
      key={element}
      className={buildClassName(values.includes(element), false, disabled)}
      onClick={() => {
        editRevenueModels(
          values,
          disabled,
          updateInvestmentInfo,
          investmentInfo,
          element
        );
      }}
    >
      {getLabel(element)}
    </div>
  ));
}

export function BuildInvestmentTypeStage(props) {
  const {
    value: {type: valueType = '', stage: valueStage = '', ml = false} = {},
    selectedValue = '',
    updateInvestmentInfo,
    disabled,
    investmentInfo
  } = props;
  const value = valueType || valueStage;
  const investmentInfos =
    investmentInfo === 'investment_stage' ? investmentStages : investmentTypes;
  return investmentInfos.map((element) => (
    <div
      key={element}
      className={buildClassName(selectedValue === element, ml, disabled)}
      onClick={
        (value !== element || ml) && !disabled
          ? () => {
              updateInvestmentInfo({[investmentInfo]: element});
            }
          : null
      }
    >
      {getLabel(element)}
    </div>
  ));
}
