import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import Tooltip from '../../Tooltip';
import WorkflowIcon from 'material-ui/svg-icons/action/work';
import _ from 'underscore';

class WorkflowPreview extends React.Component {
  static propTypes = {
    investor: PropTypes.object.isRequired
  };
  state = {
    showTooltip: false
  };

  hasError = () => {
    return true;
  };

  render() {
    const {
      investor: {
        aggregations,
        aggregations: {
          workflow: {make_contact = 0, unable_to_contact = 0} = {}
        } = {}
      } = {}
    } = this.props;
    const {showTooltip} = this.state;
    return (
      <div
        className="data-wrapper workflow compressed"
        onMouseLeave={() => {
          if (this.hasError()) {
            this.setState({showTooltip: false});
          }
        }}
        onMouseEnter={() => {
          if (this.hasError()) {
            this.setState({showTooltip: true});
          }
        }}
        rel={(el) => (this._parent = el)}
      >
        <div className="data-icon">
          <WorkflowIcon className="data-icon-svg" />
        </div>
        <Tooltip
          parent={this._parent}
          showTooltip={showTooltip}
          text="Workflow"
        />
        <Choose>
          <When condition={_.isEmpty(aggregations)}>
            <div className="data-content text empty">No data</div>
          </When>
          <Otherwise>
            <div className="preview-box">
              <div className="invesor-profile-row">
                <div className="data-content text invesor-profile-div">
                  Make Contact:
                </div>
                <div className="spacer" />
                <div>{make_contact}</div>
              </div>
              <div className="invesor-profile-row">
                <div className="data-content text invesor-profile-div">
                  Unable to Contact:
                </div>
                <div className="spacer" />
                <div>{unable_to_contact}</div>
              </div>
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(WorkflowPreview);
