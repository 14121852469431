import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import CircularProgress from 'material-ui/CircularProgress';
import InReachToggle from './InReachToggle';
import _ from 'underscore';
import Checkbox from 'material-ui/Checkbox';

const dialogStyles = {
  dialogRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 0
  },
  dialogContent: {
    position: 'relative',
    width: '80vw',
    transform: ''
  },
  dialogBody: {
    paddingBottom: 0
  }
};

export default class StateNotesDialog extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    proposedState: PropTypes.string.isRequired
  };

  state = {
    noteText: null,
    notNowText: []
  };

  handleNoteChange = (e, noteText) => {
    this.setState({
      noteText
    });
  };

  handleChange = (value) => (event) => {
    let values = this.state.notNowText;
    if (event.target.checked) {
      values.push(value);
    } else {
      values = this.state.notNowText.filter((v) => v !== value);
    }
    return this.setState({
      notNowText: values
    });
  };

  handleSubmit = () => {
    this.props.handleSubmit(
      !!this.state.notNowText
        ? this.state.notNowText.toString() +
          (!!this.state.noteText ? ' - ' + this.state.noteText : '')
        : this.state.noteText,
      this.props.proposedState
    );
  };

  render() {
    const {proposedState} = this.props;
    const actions = [
      <RaisedButton
        key="cancel"
        className="assign-action-cancel"
        label="Cancel"
        style={{
          marginRight: '10px'
        }}
        onClick={this.props.handleClose}
      />,
      <RaisedButton
        key="submit"
        className="assign-action-submit"
        backgroundColor="#5DAB49"
        labelStyle={{
          color: 'white'
        }}
        onClick={this.handleSubmit}
      >
        {this.props.updating ? (
          <CircularProgress color="white" size={25} />
        ) : (
          <span style={{color: 'white'}}>SUBMIT</span>
        )}
      </RaisedButton>
    ];

    return (
      <Dialog
        title={
          proposedState === 'contact' || proposedState === 'missed_opportunity'
            ? 'Why is this a good company?'
            : 'Why is this not an exceptional company?'
        }
        actions={actions}
        actionsContainerClassName="actions-container"
        modal={false}
        open={this.props.show}
        onRequestClose={this.props.handleClose}
        contentStyle={dialogStyles.dialogContent}
        bodyStyle={dialogStyles.dialogBody}
        style={dialogStyles.dialogRoot}
        repositionOnUpdate={false}
      >
        <div className="state-notes-dialog">
          <If condition={this.props.userRole === 'investor'}>
            <div style={{padding: '5px 0px 5px 0px'}}>
              ⚠️ You are about to make an investor decision.
            </div>
            <If condition={proposedState === 'not_now'}>
              <div>
                <Checkbox
                  label="Old company (6+ years, no recent funding round) with no traction (lower than 1k per week)"
                  onCheck={this.handleChange(
                    'Old company (6+ years, no recent funding round) with no traction (lower than 1k per week)'
                  )}
                  labelStyle={{width: 'auto'}}
                />
                <Checkbox
                  label="Later stage (50+ core employees or more than $6M)"
                  onCheck={this.handleChange(
                    'Later stage (50+ core employees or more than $6M)'
                  )}
                  labelStyle={{width: 'auto'}}
                />
                <Checkbox
                  label="Business judgment"
                  onCheck={this.handleChange('Business judgment')}
                  labelStyle={{width: 'auto'}}
                />
              </div>
            </If>
          </If>

          <TextField
            name="note"
            onChange={this.handleNoteChange}
            autoFocus={true}
            multiLine={true}
            fullWidth={true}
            placeholder={
              proposedState === 'contact' ||
              proposedState === 'missed_opportunity'
                ? 'This company is good because...'
                : 'This is not a good company because...'
            }
          />
        </div>
      </Dialog>
    );
  }
}
