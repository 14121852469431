import React from 'react';
import {withRouter} from 'react-router';
import auth from '../storage/Auth';
import Raven from 'raven-js';

import config from '../config';

class ErrorBoundary extends React.Component {
  state = {hasError: false, errorMessage: null, componentStack: null};

  log = (error, userAgent, location) => {
    const formData = new FormData();
    formData.append('message', error.message);
    formData.append('userAgent', userAgent);
    formData.append('location', this.props.location);

    fetch(`${config.api.host}/logs`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: formData
    });

    if (process.env.NODE_ENV !== 'production') {
      console.error(error);
    }

    if (Raven.isSetup()) {
      Raven.captureException(error);
    }
  };

  componentDidMount() {
    Raven.config(config.sentryDsn, {
      environment: process.env.NODE_ENV
    }).install();

    auth.getAuthData().then(({profile: {email}, client}) => {
      Raven.setUserContext({email, client});
    });
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      errorMessage: error.message,
      componentStack: info.componentStack
    });

    const userAgent = window.navigator.userAgent;

    this.log(error, userAgent, location.href);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <h1>Something went wrong.</h1>
          <h5>{this.state.errorMessage}</h5>
          <p
            style={{
              whiteSpace: 'pre-wrap',
              backgroundColor: '#c0392b',
              padding: '8px',
              borderRadius: '4px',
              color: 'white'
            }}
          >
            {this.state.componentStack}
          </p>
        </div>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
