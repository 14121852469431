import request from 'superagent';
import auth from './Auth';
import Config from '../config';

class DecisionStore {
  constructor(organizationId) {
    this.organizationId = organizationId;
    this.url =
      Config.api.host + '/organizations/' + organizationId + '/decisions';
  }

  decisions() {
    return auth
      .getAuthData()
      .then((authData) =>
        request.get(this.url).query({
          access_token: authData.token,
          client: authData.client
        })
      )
      .then((response) => response.body)
      .then((body) => body.decisions);
  }

  decide(assignCampaign) {
    const {decision} = assignCampaign;
    if (!decision) {
      throw new Error(
        `Assign Campaign contains no decision: ${JSON.stringify(
          assignCampaign
        )}`
      );
    }
    decision.source = decision.source || 'dig';
    return auth
      .getAuthData()
      .then((authData) => {
        return request
          .post(this.url)
          .send(assignCampaign)
          .query({
            access_token: authData.token,
            client: authData.client
          });
      })
      .then((response) => response.body)
      .then((created) => {
        if (typeof ga !== 'undefined') {
          ga('send', {
            hitType: 'event',
            eventCategory: 'Status',
            eventAction: 'set',
            eventLabel: decision.state
          });
        }
        return created;
      });
  }

  updateStage(stage, notes) {
    const patchNodes = [
      {
        op: 'replace',
        path: '/stage',
        value: stage
      }
    ];
    if (notes) {
      patchNodes.push({
        op: 'replace',
        path: '/notes',
        value: notes
      });
    }
    return this.updateDecision(patchNodes);
  }

  updateStop(stop) {
    return this.updateDecision([
      {
        op: 'replace',
        path: '/stop',
        value: stop
      }
    ]);
  }

  updateStatus(status) {
    return this.updateDecision([
      {
        op: 'replace',
        path: '/status',
        value: status
      }
    ]);
  }

  updateFollowUpContact(followUpContact) {
    return this.updateDecision([
      {
        op: 'replace',
        path: '/follow_up_contact',
        value: followUpContact
      }
    ]);
  }

  updateAssignedTo(assignedTo) {
    return this.updateDecision([
      {
        op: 'replace',
        path: '/assigned_to',
        value: assignedTo
      }
    ]);
  }

  updateOrganizer(organizer) {
    return this.updateDecision([
      {
        op: 'replace',
        path: '/organizer',
        value: organizer
      }
    ]);
  }

  updateMakeContactChecklist(makeContactChecklist) {
    return this.updateDecision([
      {
        op: 'replace',
        path: '/make_contact_checklist',
        value: makeContactChecklist
      }
    ]);
  }

  toggleRequiresManualHelp(requiresManualHelp) {
    return this.updateDecision([
      {
        op: 'replace',
        path: '/requires_manual_help',
        value: requiresManualHelp
      },
      {
        op: 'replace',
        path: '/form_sent',
        value: !requiresManualHelp
      }
    ]);
  }

  handlePriorityChange(priority) {
    if (priority) {
      return this.updateDecision([
        {
          op: 'replace',
          path: '/priority',
          value: priority
        }
      ]);
    } else {
      return this.updateDecision([
        {
          op: 'remove',
          path: '/priority'
        }
      ]);
    }
  }

  updateDecision(patches) {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .patch(this.url)
          .send(patches)
          .query({
            access_token: authData.token,
            client: authData.client
          })
      )
      .then((response) => response.body);
  }
}

export default DecisionStore;
