import React from 'react';
import PropTypes from 'prop-types';

export default class CustomModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    fullScreen: PropTypes.bool,
    customStyles: PropTypes.object
  };

  buildClassName = () => {
    const {show, fullScreen} = this.props;
    const classNames = ['custom-modal'];
    if (show) classNames.push('show');
    if (fullScreen) classNames.push('full-screen');
    return classNames.join(' ');
  };

  render() {
    const {children, customStyles} = this.props;
    return (
      <div className={this.buildClassName()} style={customStyles || {}}>
        {children}
      </div>
    );
  }
}
