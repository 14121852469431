import team from '../config/inreach_team';

import moment from 'moment';

export function extractContent(htmlString) {
  const span = document.createElement('span');
  span.innerHTML = htmlString;
  return span.textContent || span.innerText;
}

export function sanitizeEmailHtml(htmlString) {
  return (htmlString || '').replace(/style="[^"]*"/g, '');
}

export function getLinkedInUrl(url) {
  return /^https?:\/\/(?:[a-z]+\.)?linkedin.com\/in\//.test(url)
    ? url
    : url
      ? `https://www.linkedin.com/in/${url}`
      : null;
}

export function truncateString(string, maxLength, additionalLength = 0) {
  return string
    ? additionalLength + string.length > maxLength
      ? `${String(string).substring(0, maxLength - additionalLength)}...`
      : string
    : '';
}

export function sum(x, y) {
  return x + y;
}

export function getUserName(email) {
  if (email === 'ben.thesmith@gmail.com') {
    return 'Roberto';
  }
  const person = team.find((person) => person.email === email);
  return person ? person.name : email;
}

export function activeInReachTeam() {
  return team.filter(({exclude = false}) => !exclude);
}

export function getDateAndTime(timestamp) {
  if (!timestamp) return '-';
  const date = moment(timestamp);
  return {
    date: date.format('dddd Do MMMM YYYY'),
    time: date.format('LT')
  };
}

export function capitalizeString(string = '') {
  return string.length === 0
    ? ''
    : string.length === 1
      ? string.toUpperCase()
      : `${string.charAt(0).toUpperCase()}${string.slice(1).toLowerCase()}`;
}

export function titleCase(str) {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

export function getRoundedUpValue(value) {
  if (value === 0) {
    return '0';
  }
  const string = String(value);
  const length = string.length;
  const multiplier =
    length >= 10 ? 'B' : length >= 7 ? 'M' : length >= 4 ? 'K' : '';
  const moduloLength = length % 3 === 0 ? 3 : length % 3;
  return `${string.substring(0, moduloLength)}${
    length > moduloLength ? '.' : ''
  }${string.substring(moduloLength, moduloLength + 2)}${multiplier}`;
}

export function getDataWrapperClassNames(
  compressed = false,
  additionalClasses = []
) {
  const classNames = ['data-wrapper', ...additionalClasses];
  if (compressed) {
    classNames.push('compressed');
  }
  return classNames.join(' ');
}
