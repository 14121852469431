import React from 'react';
import PropTypes from 'prop-types';
import OrganizationSummary from './OrganizationSummary';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import AutoComplete from 'material-ui/AutoComplete';
import OrganizationSuggestStore from '../../storage/OrganizationsSuggestStore';
import FlatButton from 'material-ui/FlatButton';
import MenuItem from 'material-ui/MenuItem';
import Avatar from 'material-ui/Avatar';
import AddCircleOutline from 'material-ui/svg-icons/content/add-circle';
import OrganizationPicker from '../organization/merge/OrganizationPicker';
import LinearProgress from 'material-ui/LinearProgress';
import {List, ListItem} from 'material-ui/List';
import {Link} from 'react-router';

export default class EditPersonOrganizations extends React.Component {
  static propTypes = {
    onAddOrganization: PropTypes.func.isRequired,
    onRemoveOrganization: PropTypes.func.isRequired,
    organizationsIDs: PropTypes.array.isRequired
  };

  state = {
    openAddDialog: false,
    searchText: '',
    organizations: null,
    newOrganization: null
  };

  handleOpenAddDialog = () => {
    this.setState({
      openAddDialog: true
    });
  };

  handleCloseAddDialog = () => {
    this.setState({
      openAddDialog: false,
      searchText: '',
      organizations: [],
      newOrganization: null
    });
  };

  handleNewRequest = (organization) => {
    this.setState({
      searchText: organization.text,
      newOrganization: organization.id
    });
  };

  render() {
    const actions = [
      <RaisedButton
        key="cancel"
        className="assign-action"
        label="Cancel"
        style={{
          marginRight: '10px'
        }}
        onClick={this.handleCloseAddDialog}
      />,
      <RaisedButton
        key="confirm"
        children={<span style={{color: 'white'}}>CONFIRM</span>}
        className="assign-action add-organization-confirm"
        backgroundColor="#5DAB49"
        disabled={!this.state.newOrganization}
        labelStyle={{
          color: 'white'
        }}
        onClick={() => {
          this.props.onAddOrganization(this.state.newOrganization);
          this.handleCloseAddDialog();
        }}
      />
    ];

    return (
      <div className="edit-person-companies">
        <If condition={this.props.organizationsIDs}>
          {this.props.organizationsIDs.map((organizationId, index) => (
            <OrganizationSummary
              editable={true}
              onRemoveOrganization={this.props.onRemoveOrganization}
              key={organizationId}
              index={index}
              id={organizationId}
            />
          ))}
        </If>
        <FlatButton
          className="add-person-organization"
          label="Add organization"
          onClick={this.handleOpenAddDialog}
          icon={<AddCircleOutline color={'#5DAB49'} />}
        />
        <Dialog
          title="Add organization"
          actions={actions}
          modal={false}
          open={this.state.openAddDialog}
          onRequestClose={this.handleCloseAddDialog}
        >
          <OrganizationPicker>
            {({loadingSuggestions, organizations}) => (
              <Choose>
                <When condition={loadingSuggestions}>
                  <LinearProgress />
                </When>

                <Otherwise>
                  <List>
                    {organizations.map((organization) => (
                      <ListItem
                        key={organization.id}
                        primaryText={`${organization.name}`}
                        secondaryText={`id: ${organization.id}`}
                        leftAvatar={<Avatar src={organization.image_url} />}
                        selected={
                          this.state.newOrganization === organization.id
                        }
                        style={
                          this.state.newOrganization === organization.id
                            ? {backgroundColor: 'grey'}
                            : {}
                        }
                        onClick={() => this.handleNewRequest(organization)}
                      />
                    ))}
                  </List>
                </Otherwise>
              </Choose>
            )}
          </OrganizationPicker>
        </Dialog>
      </div>
    );
  }
}
