export default {
  inboxes: {
    filtersDisplay: 'Inbox'
  },
  decisions: {
    filtersDisplay: 'Decisions'
  },
  sources: {
    filtersDisplay: 'Sources'
  },
  calendar_event: {
    filtersDisplay: 'Calendar Event'
  },
  communications: {
    filtersDisplay: 'Automated emails'
  },
  call_feedback: {
    filtersDisplay: 'Call Feedback'
  },
  pre_qualification: {
    filtersDisplay: 'Pre Qualification'
  },
  reconnect: {
    filtersDisplay: 'Reconnect'
  },
  qualification: {
    filtersDisplay: 'Qualification'
  },
  tasks: {
    filtersDisplay: 'Tasks'
  },
  mailings: {
    filtersDisplay: 'Emails'
  },
  scorecard: {
    filtersDisplay: 'Scorecard'
  },
  sequence_steps: {
    filtersDisplay: 'Sequence steps'
  },
  events: {
    filtersDisplay: 'Events'
  },
  gmail_messages: {
    filtersDisplay: 'Communications'
  },
  emails: {
    filtersDisplay: 'Emails',
    collections: ['communications', 'mailings', 'gmail_messages']
  },
  assign_campaign: {
    filtersDisplay: 'Assign Campaign'
  },
  sequence_states: {
    filtersDisplay: 'Campaign'
  },
  social_messages: {
    filtersDisplay: 'Social Messages'
  },
  organizations_edits: {
    filtersDisplay: 'Organizations Edits'
  }
};
