import ModelStore from './ModelStore';

var TwitterUserStore = function(id) {
  this.id = id;
  this.path = '/providers/twitter/users/{id}';

  return this;
};
TwitterUserStore.prototype = new ModelStore();

export default TwitterUserStore;
