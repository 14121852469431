import React from 'react';
import PropTypes from 'prop-types';

import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';
import Paper from 'material-ui/Paper';
import NavigationCheck from 'material-ui/svg-icons/navigation/check';

export default class EditOrganizationHeader extends React.Component {
  static propTypes = {
    isSaving: PropTypes.bool,
    cancel: PropTypes.func.isRequired,
    confirm: PropTypes.func,
    title: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    isInsideDialog: PropTypes.bool,
    disable: PropTypes.bool,
    saveSuccess: PropTypes.bool
  };

  openProfileInTab = () => {
    window.open(
      window.location.pathname.replace('/edit_incomplete', ''),
      '_blank'
    );
  };

  render() {
    const {
      isSaving,
      cancel,
      confirm,
      title,
      source,
      isInsideDialog,
      disable,
      saveSuccess
    } = this.props;

    return (
      <Paper
        className={`edit-organization-header ${
          isInsideDialog ? 'absolute' : 'fixed'
        }`}
        zDepth={1}
      >
        <div>
          <div className="absolute-center">
            <h4>{title || ''}</h4>
          </div>
          <RaisedButton
            className="assign-action cancel-action"
            label={
              source === 'edit-incomplete' || disable || saveSuccess
                ? 'Back'
                : 'Cancel'
            }
            style={{
              marginRight: '10px'
            }}
            onClick={cancel}
          />
          <Choose>
            <When condition={source === 'edit-incomplete'}>
              <RaisedButton
                className="assign-action cancel-action"
                onClick={this.openProfileInTab}
                label="Open Profile"
              />
            </When>
            <Otherwise>
              <RaisedButton
                className="assign-action save-action"
                backgroundColor="#5DAB49"
                labelStyle={{color: 'white'}}
                disabled={disable && !saveSuccess}
                onClick={confirm}
              >
                {isSaving ? (
                  <CircularProgress color="white" size={25} />
                ) : (
                  <span style={{color: 'white'}}>
                    {saveSuccess ? (
                      <div className="success-action">
                        <NavigationCheck color="white" />
                        SUCCESS!
                      </div>
                    ) : (
                      'SAVE'
                    )}
                  </span>
                )}
              </RaisedButton>
            </Otherwise>
          </Choose>
        </div>
      </Paper>
    );
  }
}
