import React from 'react';
import PropTypes from 'prop-types';

import _ from 'underscore';

import ScorecardModalSectionContentForm from './ScorecardModalSectionContentForm';
import ScorecardModalSectionContentCall from './ScorecardModalSectionContentCall';
import ScorecardCallOrFormHeader from '../ScorecardCallOrFormHeader';

import {getUserName, capitalizeString} from '../../../../lib/helpers';
import titles from '../../../../content/titles';
import callTitles from '../../../../content/callTitles';

export default class ScorecardModalSectionContent extends React.Component {
  static propTypes = {
    content: PropTypes.object.isRequired,
    scorecardType: PropTypes.string.isRequired,
    expandable: PropTypes.bool.isRequired,
    expanded: PropTypes.bool,
    deEmphasised: PropTypes.bool,
    section: PropTypes.string.isRequired,
    sectionIndex: PropTypes.number.isRequired,
    failedToLoadTemplates: PropTypes.bool.isRequired,
    availableTemplates: PropTypes.bool.isRequired,
    handleChangeSection: PropTypes.func.isRequired,
    setScorecardModalEditIndex: PropTypes.func.isRequired,
    selectCallType: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    openScorecardModal: PropTypes.bool.isRequired
  };

  componentWillMount() {
    const {expanded = true} = this.props;
    this.setState({
      expanded
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.expanded, this.props.expanded)) {
      this.setState({
        expanded: this.props.expanded
      });
    }
  }

  buildClassName = () => {
    const {expandable, scorecardType, deEmphasised = false} = this.props;
    const {expanded} = this.state;
    const classNames = ['scorecard-modal-section-content'];
    if (scorecardType !== 'view') classNames.push('editing');
    if (expandable) classNames.push('expandable');
    if (expanded) classNames.push('expanded');
    if (deEmphasised) classNames.push('de-emphasised');
    return classNames.join(' ');
  };

  getFormSubmittedBy = (firstName, lastName, title) => {
    const name = [];
    if (firstName) name.push(firstName);
    if (lastName) name.push(lastName);
    if (title) name.push(`(${titles[title] || title})`);
    return name.length > 0 ? name.join(' ') : null;
  };

  toggleExpanded = (expanded = this.state.expanded) => {
    this.setState({
      expanded: !expanded
    });
  };

  getFormTitle = (status, formType) => {
    const title = ['Form'];
    if (formType) title.unshift(capitalizeString(formType));
    if (status) title.unshift(capitalizeString(status));
    return title.join(' ');
  };

  getCallOrFormTitle = (
    scorecardType,
    contentType,
    status,
    formType,
    templateId,
    callTitle
  ) => {
    if (contentType === 'call') {
      let title = callTitles[templateId] || 'Call';
      if (templateId === 'adhoc') {
        title += ` - ${callTitle}`;
      }
      return title;
    } else {
      return this.getFormTitle(status, formType);
    }
  };

  render() {
    const {
      content: {
        id: callId,
        user = '',
        createdAt = null,
        firstName = '',
        lastName = '',
        title = '',
        email = '',
        notes = {},
        formContent = {},
        type: contentType,
        status: contentStatus,
        formType = '',
        submitted,
        noShow = false,
        relevantQuestions = [],
        allQuestions = [],
        templateId = '',
        callWith = '',
        callTitle = ''
      },
      expandable,
      scorecardType,
      deEmphasised = false,
      section,
      sectionIndex,
      failedToLoadTemplates,
      availableTemplates,
      handleChangeSection,
      setScorecardModalEditIndex,
      selectCallType,
      show,
      openScorecardModal
    } = this.props;

    const {expanded} = this.state;
    const date = createdAt || submitted || '';
    const {
      formatted_content: formattedContent = '',
      structured_content: structuredContent = {}
    } = notes;
    return (
      <div className={this.buildClassName(expanded)}>
        <ScorecardCallOrFormHeader
          contentType={contentType}
          scorecardType={scorecardType}
          user={
            contentType === 'call'
              ? getUserName(user)
              : this.getFormSubmittedBy(firstName, lastName, title) || email
          }
          date={date}
          expandable={expandable}
          expanded={expanded}
          deEmphasised={deEmphasised}
          toggleExpanded={this.toggleExpanded}
          showDate={scorecardType !== 'new' && scorecardType !== 'edit'}
          showTime={contentType === 'call'}
          title={this.getCallOrFormTitle(
            scorecardType,
            contentType,
            contentStatus,
            formType,
            templateId,
            callTitle
          )}
          source={'modal-source'}
          callWith={callWith}
          id={callId}
          setScorecardModalEditIndex={setScorecardModalEditIndex}
          selectCallType={selectCallType}
          templateId={templateId}
          openScorecard={openScorecardModal}
        />
        <Choose>
          <When condition={contentType === 'form'}>
            <ScorecardModalSectionContentForm
              section={section}
              content={formContent}
            />
          </When>
          <Otherwise>
            <ScorecardModalSectionContentCall
              formattedContent={formattedContent}
              structuredContent={structuredContent}
              noShow={noShow}
              scorecardType={scorecardType}
              section={section}
              sectionIndex={sectionIndex}
              relevantQuestions={relevantQuestions}
              allQuestions={allQuestions}
              failedToLoadTemplates={failedToLoadTemplates}
              availableTemplates={availableTemplates}
              handleChangeSection={handleChangeSection}
              expanded={expanded}
              callId={callId}
              selectCallType={selectCallType}
              show={show}
            />
          </Otherwise>
        </Choose>
      </div>
    );
  }
}
