import React from 'react';

import OrganizationsStore from '../storage/OrganizationsStore';

import OrganizationsSearch from './organization/OrganizationsSearch';
import Loading from './loading/Loading';
import InboxMenu from './personalinbox/InboxMenu';
import PersonMenu from './personalinbox/PersonMenu';

import auth from '../storage/Auth';
import inboxHelper from '../lib/InboxHelper';

class InboxRoute extends React.Component {
  state = {client: null, profile: null, inboxes: [], counts: {}};

  componentDidMount() {
    const {
      location: {
        query: {user_inbox: person}
      }
    } = this.props;

    this.loadCounts(person);

    auth
      .getAuthData()
      .then(({profile, client, inboxes = []}) => {
        this.setState({profile, client, inboxes});
      })
      .catch((err) => {
        console.log('Unable to retrieve client', err);
      });
  }

  loadCounts = (email) => {
    OrganizationsStore.getInboxSizePerReason(email ? {assigned_to: email} : {})
      .then((reasonCounts) => {
        this.setState({
          counts: inboxHelper.allReasonCounts(reasonCounts)
        });
      })
      .catch(console.error);
  };

  componentDidUpdate(prevProps) {
    const {
      location: {
        query: {user_inbox: prevPerson}
      }
    } = prevProps;

    const {
      location: {
        query: {user_inbox: person}
      }
    } = this.props;

    if (person !== prevPerson) {
      this.loadCounts(person);
    }
  }

  render() {
    const {profile, client, inboxes, counts} = this.state;

    if (!client || !profile) {
      return <Loading />;
    }

    const {query} = this.props.location;

    const {
      reason = '',
      user_inbox: person = profile.email,
      experiments = '',
      experiments_not = ''
    } = query;

    const filters = reason.split(',').filter(Boolean);
    const experimentsFilters = experiments.split(',').filter(Boolean);
    const experimentsNotFilters = experiments_not.split(',').filter(Boolean);

    return (
      <div className="page-width">
        <div className="row">
          <div className="col-xs-12 col-md-3">
            <PersonMenu person={person} />
            <InboxMenu
              filters={filters}
              experiments={experimentsFilters}
              ignoreExperiments={experimentsNotFilters}
              counts={counts}
              inboxes={inboxes}
            />
          </div>
          <div className="col-xs-12 col-md-9">
            <OrganizationsSearch
              initSearchParams={query}
              client={client}
              location={this.props.location}
              context={this.props.context}
              route={this.props.route}
              useSearch={false}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default InboxRoute;
