import React from 'react';
import {withRouter} from 'react-router';
import _ from 'underscore';
import PropTypes from 'prop-types';
import auth from '../../../storage/Auth';
import SearchParamsHelper from '../../../lib/SearchParamsHelper';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiTheme from '../../../lib/MuiTheme';
import SearchFilters from '../../organization/SearchFilters';
import FilterSummary from '../../organization/FilterSummary';
import QuickAdd from '../../organization/QuickAdd';
import Investors from '../Investors';

const flattenArrayParams = (params = {}) =>
  Object.entries(params).reduce(
    (acc, [k, v]) => ({
      ...acc,
      [k]: Array.isArray(v) ? v.join(',') : v
    }),
    {}
  );

class InvestorsSearch extends React.Component {
  static propTypes = {
    client: PropTypes.string,
    useSearch: PropTypes.bool
  };

  static childContextTypes = {
    muiTheme: PropTypes.object.isRequired
  };

  state = {
    offset: null,
    searchParams: null,
    orgsNumber: 0,
    openAddOrganizationDialog: false,
    userHasFullAccess: null,
    userRoles: [],
    filtersSets: null,
    selectedFiltersSet: null,
    exportingCSV: false,
    csvBlob: null,
    filenameCSV: null,
    limitCSV: null,
    profile: undefined
  };

  componentWillMount() {
    const params = this.urlParams();
    this.setState({
      offset: params.offset,
      searchParams: this.getSearchParams(params)
    });
  }

  componentDidMount() {
    auth
      .getAuthData()
      .then(({profile, accessType, roles}) => {
        this.setState({
          userHasFullAccess: accessType === 'full',
          userRoles: roles,
          profile
        });
      })
      .then(this.updateFiltersSets)
      .catch((err) => {
        console.error(err);
      });

    this.trackTimeToEvaluation();
  }

  componentDidUpdate(prevProps) {
    if (
      !SearchParamsHelper.arePropsEqual(
        prevProps.initSearchParams,
        this.props.initSearchParams
      )
    ) {
      this.handleChange(this.props.initSearchParams);
      this.updateFiltersSets();
      this.trackTimeToEvaluation();
    }
  }

  getSearchParams = (params) =>
    _.isEqual(_.omit(params, 'offset'), {})
      ? this.props.initSearchParams
      : _.omit(params, 'offset');

  trackTimeToEvaluation = () => {
    if (typeof window.mixpanel === 'function') {
      mixpanel.time_event('Evaluated organization');
    }
  };

  getSavedFiltersSets = () => {
    return auth.getFiltersSets().then((filtersSets) => {
      this.setState({
        filtersSets: filtersSets
          ? Object.keys(filtersSets).reduce(
              (filters, filter_set_name) => ({
                ...filters,
                [filter_set_name]: {
                  ...filtersSets[filter_set_name],
                  filter_set_name
                }
              }),
              {}
            )
          : {}
      });
    });
  };

  handleSelectFiltersSet = (name) => {
    if (this.state.filtersSets && this.state.filtersSets[name]) {
      this.setState({
        selectedFiltersSet: name
      });
      this.handleChange(this.state.filtersSets[name]);
    }
  };

  handleDeselectFiltersSet = () => {
    auth.getCustomisedFeedParams().then(this.handleChange);
  };

  handleDeleteFiltersSet = (name) => {
    auth.deleteFiltersSet(name).then(this.updateFiltersSets);
  };

  checkIfFiltersSetsAreSelected = () => {
    if (this.state.filtersSets) {
      const searchParams = _.omit(this.state.searchParams, 'offset'),
        filtersSets = this.state.filtersSets,
        selectedFiltersSetsKeys = Object.keys(filtersSets).filter((filterKey) =>
          _.isEqual(filtersSets[filterKey], searchParams)
        );

      if (selectedFiltersSetsKeys && selectedFiltersSetsKeys.length > 0) {
        this.setState({
          selectedFiltersSet: selectedFiltersSetsKeys[0]
        });
      } else {
        this.setState({
          selectedFiltersSet: null
        });
      }
    }
  };

  updateFiltersSets = () => {
    this.getSavedFiltersSets().then(this.checkIfFiltersSetsAreSelected);
  };

  handleChange = _.debounce((searchParams) => {
    this.setState({
      offset: 0,
      searchParams: searchParams
    });
    this.changeUrl(
      `${this.props.location.pathname}`,
      _.extend(
        {
          offset: 0
        },
        searchParams
      )
    );
  }, 400);

  handleOpenAddOrganizationDialog = () => {
    this.setState({openAddOrganizationDialog: true});
  };

  handleCloseAddOrganizationDialog = () => {
    this.setState({openAddOrganizationDialog: false});
  };

  urlParams = () => {
    const params = this.props.location.query;
    params.offset = parseInt(params.offset || '0');
    return params;
  };

  changeUrl = (url, params, updateURLWithoutRefreshingPage) => {
    const {location} = this.props;
    if (updateURLWithoutRefreshingPage) {
      let url = `${location.pathname}?`,
        query = location.query;
      Object.keys(query).forEach((key, index) => {
        if (key !== 'offset') {
          url += `${index === 0 ? '' : '&'}${key}=${query[key]}`;
        }
      });
      if (params.offset) {
        url += `&offset=${params.offset}`;
      }
      window.history.pushState({}, window.title, url);
    } else if (
      url !== location.pathname ||
      !_.isEqual(params, this.urlParams())
    ) {
      this.props.router.push({
        pathname: url,
        query: params
      });
    }
  };

  updateOrgsNumber = (orgsNumber) => {
    this.setState({
      orgsNumber
    });
  };

  getChildContext = () => {
    return {
      muiTheme: getMuiTheme(MuiTheme)
    };
  };

  exportToCSV = (filename, limit) => {
    this.setState({
      filenameCSV: filename,
      limitCSV: limit,
      exportingCSV: true
    });
  };

  exportCSVCallback = (csvBlob) => {
    if (this.state.exportingCSV) {
      this.setState({exportingCSV: false});

      const hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvBlob);
      hiddenElement.target = '_blank';
      hiddenElement.download = `${this.state.filenameCSV}.csv`;
      hiddenElement.click();
      hiddenElement.remove();
    }
  };

  render() {
    const {
      client = null,
      location = {},
      route,
      source = '',
      useSearch = true
    } = this.props;
    const {
      profile = null,
      searchParams = {},
      filtersSets,
      selectedFiltersSet,
      csvBlob,
      orgsNumber,
      offset,
      userHasFullAccess,
      userRoles,
      openAddOrganizationDialog
    } = this.state;

    if (!client || !profile) {
      return null;
    }

    return (
      <div className="page-width" id="organizations-search">
        <If condition={useSearch}>
          <div className="search">
            <div>
              <SearchFilters
                handleSelectFiltersSet={this.handleSelectFiltersSet}
                handleDeselectFiltersSet={this.handleDeselectFiltersSet}
                handleDeleteFiltersSet={this.handleDeleteFiltersSet}
                searchParams={searchParams}
                filtersSets={filtersSets}
                selectedFiltersSet={selectedFiltersSet}
                updateFiltersSets={this.updateFiltersSets}
                client={client}
                onChange={this.handleChange}
                csvBlob={csvBlob}
                exportToCSV={this.exportToCSV}
                filtersType={'investors'}
              />
            </div>
            <div className="sub-header">
              <FilterSummary
                selectedFiltersSet={selectedFiltersSet}
                handleDeselectFiltersSet={this.handleDeselectFiltersSet}
                params={searchParams}
                orgsNumber={orgsNumber}
                onChange={this.handleChange}
              />
            </div>
          </div>
        </If>
        <Investors
          searchParams={searchParams}
          offset={offset}
          updateOrgsNumber={this.updateOrgsNumber}
          pathname={route.path}
          changeUrl={this.changeUrl}
          userHasFullAccess={userHasFullAccess}
          userRoles={userRoles}
          client={client}
          filterSource={{
            route: route.path,
            params: flattenArrayParams(searchParams)
          }}
          profile={profile}
          source={source}
          {...this.props}
        />
        <QuickAdd
          open={openAddOrganizationDialog}
          handleClose={this.handleCloseAddOrganizationDialog}
          companyName={searchParams.name}
        />
      </div>
    );
  }
}

export default withRouter(InvestorsSearch);
