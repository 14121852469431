export default {
  primary: 1,
  founder: 2,
  ceo: 3,
  cto: 4,
  cfo: 5,
  coo: 6,
  executive: 7,
  employee: 8
};
