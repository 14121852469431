import React from 'react';
import PropTypes from 'prop-types';
import JsonDev from '../../JsonDev';
import SocialLink from '../../organization/SocialLink';
import {Link, withRouter} from 'react-router';
import PreviewInvestorAvatar from './PreviewInvestorAvatar';
import PreviewInvestorName from './PreviewInvestorName';

class PreviewInvestorHeader extends React.Component {
  static propTypes = {
    investor: PropTypes.object.isRequired,
    userRoles: PropTypes.array.isRequired,
    returnUrl: PropTypes.string.isRequired,
    client: PropTypes.string.isRequired,
    showAsPublicProfile: PropTypes.bool,
    userHasFullAccess: PropTypes.bool.isRequired
  };

  state = {};

  topBarIcons = () => {
    const {investor = {}, userRoles} = this.props;
    const {name, url, crunchbase_url, linkedin} = investor;
    return (
      <React.Fragment>
        <div className={'top-bar-actions'}>
          <If condition={investor}>
            <If condition={userRoles.includes('admin')}>
              <JsonDev
                investor={investor}
                className="json-icon"
                objectType="investors"
              />
            </If>
            <If condition={'url' in investor}>
              <span className="investor-link top-bar-icons">
                <SocialLink source="website" link={url} />
              </span>
            </If>

            <Choose>
              <When condition={!!linkedin}>
                <SocialLink source="linkedin" link={linkedin} />
              </When>
              <Otherwise>
                <SocialLink
                  source="linkedin"
                  link={`https://www.linkedin.com/search/results/companies?keywords=${encodeURIComponent(
                    name
                  )}`}
                />
              </Otherwise>
            </Choose>
            <SocialLink source="crunchbase" link={crunchbase_url} />
          </If>
        </div>
      </React.Fragment>
    );
  };

  goToPage = (page) => {
    const {router, location, investor: {id} = {}} = this.props;
    router.push({
      ...location,
      ...{
        pathname: `/organizations/${id}/${page}`
      }
    });
  };

  render() {
    const {
      investor,
      showAsPublicProfile,
      userHasFullAccess,
      returnUrl
    } = this.props;
    return (
      <div className="preview-organization--header-bar">
        <PreviewInvestorAvatar investor={investor} />
        <PreviewInvestorName investor={investor} returnUrl={returnUrl} />
        <div className="investor-links">{this.topBarIcons()}</div>
      </div>
    );
  }
}

export default withRouter(PreviewInvestorHeader);
