import React from 'react';
import PropTypes from 'prop-types';
import {FlatButton, TextField} from 'material-ui';
import AddCircleOutline from 'material-ui/svg-icons/content/add-circle';
import ActionDelete from 'material-ui/svg-icons/action/delete';

export default class EditDomains extends React.Component {
  static propTypes = {
    domains: PropTypes.array,
    handleDomainsChange: PropTypes.func.isRequired
  };

  state = {
    newDomain: ''
  };

  render() {
    const {domains = []} = this.props;

    return (
      <div className="edit-domains">
        {domains.map((domain, i) => (
          <div key={i}>
            <TextField
              value={domain}
              onChange={(event) => {
                domains[i] = event.target.value;
                this.props.handleDomainsChange(domains);
              }}
              name="edit-domain"
            />
            <FlatButton
              className="remove-domain"
              label="Remove"
              labelStyle={{color: '#C30000'}}
              icon={<ActionDelete color={'#C30000'} />}
              onClick={() => {
                domains.splice(i);
                this.props.handleDomainsChange(domains);
              }}
            />
          </div>
        ))}
        <div>
          <TextField
            value={this.state.newDomain}
            onChange={(event) => {
              this.setState({
                newDomain: event.target.value
              });
            }}
            name="add-domain"
          />
          <FlatButton
            label="Add Domain"
            onClick={() => {
              domains.push(this.state.newDomain);
              this.props.handleDomainsChange(domains);
              this.setState({
                newDomain: ''
              });
            }}
            icon={<AddCircleOutline color={'#5DAB49'} />}
          />
        </div>
      </div>
    );
  }
}
