import Config from '../config';
import auth from './Auth';
import request from 'superagent';

export default class FundingTrackerStatsStore {
  constructor() {
    this.baseUrl = `${Config.api.host}/funding_tracker/stats`;
  }

  get() {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(this.baseUrl)
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .set('Accept', 'application/json')
      )
      .then((response) => {
        return response.body;
      });
  }
}
