import React from 'react';
import PropTypes from 'prop-types';

export default class MLExperiments extends React.Component {
  static propTypes = {
    experiments: PropTypes.array.isRequired
  };

  render() {
    const {experiments} = this.props;
    return (
      <div className="ml-experiments">
        <div className="ml-experiments-icon" />
        <div className="ml-experiments-text">
          <ul style={{listStyleType: 'none', padding: '0 0px 0 5px'}}>
            {experiments.map((experiment) => (
              <li>{experiment.replace(/_/g, ' ').toUpperCase()}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}
