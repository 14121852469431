import React from 'react';
import PropTypes from 'prop-types';
import TimelineEventMailingBody from './TimelineEventMailingBody';

import {truncateString} from '../../../../../lib/helpers';

import moment from 'moment';
import xss from 'xss';
import DeliveredIcon from 'material-ui/svg-icons/content/send';
import ViewedIcon from 'material-ui/svg-icons/action/face';
import RepliedIcon from 'material-ui/svg-icons/content/reply';
import Tooltip from '../../../../Tooltip';

export default class TimelineEventGmailMessage extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    getUserName: PropTypes.func.isRequired
  };

  emailDeliveredRef = React.createRef();
  emailReadRef = React.createRef();
  emailRepliedRef = React.createRef();

  maxLabelLength = 120;

  buildItem = (item = this.props.item, type = this.props.type) => {
    const email = item.message;
    const fromPerson = email.from;
    const {label, subLabel} =
      email.body || email.body_text
        ? this.getEmailLabels(
            fromPerson,
            email.recipients,
            email.subject,
            String(email.body || email.snippet).replace(/\r?\n|\r/g, ' ')
          )
        : this.getEventInvitationLabels(fromPerson, email.subject);
    return {
      label: label,
      subLabel: subLabel,
      email: this.getGmailMessageEmailContent(email),
      showBody: typeof (email.body || email.body_text) !== 'undefined'
    };
  };

  getEmailLabels = (emailFrom, recipients = [], subject, body) => {
    const toString = this.getEmailToLabelString(recipients);
    const label = `${this.getPerson(
      emailFrom
    )} > ${toString}: <i>${subject}</i>`;
    const labelLength = label.length;
    return {
      label, //this.getShortLabel(label),
      subLabel: body //this.getSubLabel(body, labelLength)
    };
  };

  getEventInvitationLabels = (fromPerson, subject) => {
    const string = `${fromPerson} ${subject}`;
    return {
      label: truncateString(string, this.maxLabelLength),
      subLabel: null
    };
  };

  getEmailToLabelString = (recipients) => {
    if (recipients.length < 1) {
      return '';
    }
    return recipients.length > 1
      ? this.getPerson(recipients[0]) + '...'
      : this.getPerson(recipients[0]);
  };

  getGmailMessageEmailContent = (email) => {
    return {
      emailFrom: email.from,
      sent: this.reformatDate(email.sent),
      emailTo: this.getEmailToString(email.recipients),
      subject: email.subject,
      body: String(email.body || email.snippet).replace(
        /(?:\r\n|\r|\n)/g,
        '<br>'
      )
    };
  };

  reformatDate = (sent) => {
    return moment(sent).format('dddd, MMMM DD, YYYY LT');
  };

  getEmailToString = (recipients = []) => {
    if (recipients.length < 1) {
      return '';
    }
    return recipients.map((recipient) => recipient).join(', ');
  };

  getPerson = (person) => {
    return person === 'bonanzinga@inreachventures.com'
      ? 'Roberto Bonanzinga'
      : person.indexOf('<') > -1
        ? person.substring(0, person.indexOf('<') - 1)
        : this.props.getUserName(person) || person;
  };

  buildEmailStatus = () => {
    if (!this.props.item) return null;
    const {
      item: {
        merged_email: mergedEmail,
        mailing: {
          open_count: openCount,
          opened_at: openedAt,
          delivered_at: deliveredAt,
          replied_at: repliedAt
        } = {},
        communication: {status: status, sent: sent = []} = {}
      }
    } = this.props;

    const deliveredAtMerged =
      status === 'DELIVERED' && sent.length
        ? (sent.filter((c) => c.status === 'delivered')[0] || {}).sent
        : deliveredAt;

    return (
      <React.Fragment>
        <div ref={this.emailDeliveredRef} className="email-status">
          <DeliveredIcon
            ref
            color={
              mergedEmail
                ? deliveredAtMerged
                  ? '#48a0ff'
                  : '#c7c7c7'
                : '#ffffff'
            }
            stroke={mergedEmail ? 'none' : '#c7c7c7'}
            style={{marginRight: '8px'}}
          />
        </div>
        <Tooltip
          parent={this.emailDeliveredRef.current}
          text={
            mergedEmail
              ? deliveredAtMerged
                ? `Delivered on ${this.reformatDate(deliveredAtMerged)}`
                : "Hasn't been delivered"
              : 'No delivery info from Gmail'
          }
          showOnHover={true}
          position="Top"
          customStyles={{right: 0}}
        />
        {/*<div ref={this.emailReadRef} className="email-status">*/}
        {/*<ViewedIcon*/}
        {/*color={'#ffffff'}*/}
        {/*stroke={'#c7c7c7'}*/}
        {/*style={{marginRight: '8px'}}*/}
        {/*/>*/}
        {/*</div>*/}
        {/*<Tooltip*/}
        {/*parent={this.emailReadRef.current}*/}
        {/*text={`No open info on Gmails`}*/}
        {/*showOnHover={true}*/}
        {/*position="Top"*/}
        {/*customStyles={{right: 0}}*/}
        {/*/>*/}
        <div ref={this.emailRepliedRef} className="email-status">
          <RepliedIcon
            color={
              mergedEmail && !status
                ? repliedAt
                  ? '#48a0ff'
                  : '#c7c7c7'
                : '#ffffff'
            }
            stroke={mergedEmail && !status ? 'none' : '#c7c7c7'}
            style={{marginRight: '8px'}}
          />
        </div>
        <Tooltip
          parent={this.emailRepliedRef.current}
          text={
            mergedEmail && !status
              ? repliedAt
                ? `Replied on ${this.reformatDate(repliedAt)}`
                : "Hasn't replied"
              : !status
                ? 'No reply info from Gmail'
                : `No reply info from the old system`
          }
          showOnHover={true}
          position="Top"
          customStyles={{right: 0}}
        />
      </React.Fragment>
    );
  };

  render() {
    const {label, subLabel, email, showBody} = this.buildItem();
    return (
      <div className="timeline-event-inner-wrapper">
        <div className="timeline-event-email-text">
          <h5
            className="timeline-event-label"
            dangerouslySetInnerHTML={{__html: xss(label)}}
            style={{flexShrink: '0'}}
          />
          <p className="timeline-event-sub-label">{subLabel}</p>
        </div>
        <div className="spacer" />
        {this.buildEmailStatus()}
        <If condition={showBody}>
          <TimelineEventMailingBody email={email} />
        </If>
      </div>
    );
  }
}
