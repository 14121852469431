import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import Ago from '../Ago';
import {TableRow, TableRowColumn} from 'material-ui/Table';
import RunningStatus from './RunningStatus';

class TaskSummary extends Component {
  render() {
    const {id, job_name, next_fire_time} = this.props.task,
      progress = this.props.task.progress || {},
      schedule = this.props.task.schedule || {};

    return (
      <TableRow>
        <TableRowColumn>
          <Link to={`/tasks/${id}`} title={job_name}>
            {job_name}
          </Link>
        </TableRowColumn>
        <TableRowColumn>
          <RunningStatus progress={progress} schedule={schedule} />
        </TableRowColumn>
        <TableRowColumn>{schedule.priority || 3}</TableRowColumn>
        <TableRowColumn>
          <Ago string={progress.started} explain={true} />
        </TableRowColumn>
        <TableRowColumn>
          {progress.progress}
          {progress.previous_progress ? ` (${progress.previous_progress})` : ''}
        </TableRowColumn>
        <TableRowColumn>
          <Ago string={progress.finished} explain={true} />
        </TableRowColumn>
        <TableRowColumn>
          <Ago timestamp={next_fire_time} explain={true} />
        </TableRowColumn>
      </TableRow>
    );
  }
}

TaskSummary.propTypes = {
  task: PropTypes.object.isRequired
};

export default TaskSummary;
