import request from 'superagent';
import auth from './Auth';
import Config from '../config';

export default class TasksStore {
  getTasks() {
    return auth
      .getAuthData()
      .then((authData) =>
        request.get(Config.worker.host + '/jobs').query({
          access_token: authData.token,
          client: authData.client
        })
      )
      .then((response) => response.body || {});
  }

  pauseScheduler() {
    return auth
      .getAuthData()
      .then((authData) =>
        request.delete(Config.worker.host + '/jobs').query({
          access_token: authData.token,
          client: authData.client
        })
      )
      .then((response) => response.body);
  }

  resumeScheduler() {
    return auth
      .getAuthData()
      .then((authData) =>
        request.post(Config.worker.host + '/jobs').query({
          access_token: authData.token,
          client: authData.client
        })
      )
      .then((response) => response.body);
  }
}
