import React from 'react';
import PropTypes from 'prop-types';

import ScorecardCallRecommendationDecision from './ScorecardCallRecommendationDecision';
import ScorecardCallOrFormHeader from '../ScorecardCallOrFormHeader';
import DecisionRecommendationDisplay from '../../../components/DecisionRecommendationDisplay';

import {capitalizeString} from '../../../../lib/helpers';
import titles from '../../../../content/titles';
import callTitles from '../../../../content/callTitles';

export default class ScorecardCallOrForm extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    call: PropTypes.object,
    form: PropTypes.object,
    deleteCallClick: PropTypes.func,
    openScorecard: PropTypes.func.isRequired,
    selectCallType: PropTypes.func.isRequired
  };

  getSubmittedByName = (firstName, lastName, title) => {
    const name = [];
    if (firstName) name.push(firstName);
    if (lastName) name.push(lastName);
    if (title) name.push(`(${titles[title] || title})`);
    return name.length > 0 ? name.join(' ') : null;
  };

  getFormTitle = (status, formType) => {
    const title = ['Form'];
    if (formType !== '') title.unshift(capitalizeString(formType));
    if (status !== '') title.unshift(capitalizeString(status));
    return title.join(' ');
  };

  getTitle(type, templateId, status, formType, callTitle) {
    if (type === 'call') {
      let title = callTitles[templateId] || 'Call';
      if (templateId === 'adhoc') {
        title += ` - ${callTitle}`;
      }
      return title;
    } else {
      return this.getFormTitle(status, formType);
    }
  }

  render() {
    const {
      type = '',
      call: {
        user: conductor = '',
        date: callDate,
        decision: callDecision = {},
        recommendations: callRecommendations = [],
        id: callId = '',
        hasNotes,
        callWith = '',
        callTitle = '',
        noShow,
        templateId = ''
      } = {},
      form: {
        id: formId,
        decision: formDecision,
        formType = '',
        submitted: formSubmitted,
        email: formEmail,
        status = '',
        submittedBy: {
          first_name: firstName = '',
          last_name: lastName = '',
          title: submittedByTitle = ''
        } = {}
      } = {},
      deleteCallClick,
      openScorecard,
      selectCallType
    } = this.props;
    const hasDecision = Object.keys(callDecision).length !== 0,
      hasRecommendation = callRecommendations.length > 0,
      date = callDate || formSubmitted,
      user =
        conductor ||
        this.getSubmittedByName(firstName, lastName, submittedByTitle) ||
        formEmail,
      title = this.getTitle(type, templateId, status, formType, callTitle);

    return (
      <div className="scorecard-call-or-form">
        <ScorecardCallOrFormHeader
          contentType={type}
          user={user}
          callWith={callWith}
          date={date}
          showDate={true}
          showTime={type === 'call'}
          title={title}
          id={callId || formId}
          deleteCallClick={deleteCallClick}
          source={'overview'}
          openScorecard={openScorecard}
          templateId={templateId}
          type={type}
          selectCallType={selectCallType}
        />
        <If condition={type === 'call' || (type === 'form' && formDecision)}>
          <div className="scorecard-call-or-form-body">
            <Choose>
              <When condition={type === 'call'}>
                <Choose>
                  <When condition={noShow}>
                    <div className="scorecard-call-or-form-recommendation-decision call">
                      <div className="scorecard-form-decision-display-wrapper">
                        <DecisionRecommendationDisplay
                          outcome={'no-show'}
                          type="decision"
                        />
                      </div>
                    </div>
                  </When>
                  <Otherwise>
                    <If condition={hasDecision}>
                      <ScorecardCallRecommendationDecision
                        type="decision"
                        content={callDecision}
                      />
                    </If>
                    <If condition={hasRecommendation}>
                      {callRecommendations
                        .sort((a, b) => b.created_at - a.created_at)
                        .map((recommendation) => (
                          <ScorecardCallRecommendationDecision
                            key={recommendation.created_at}
                            type="recommendation"
                            content={recommendation}
                          />
                        ))}
                    </If>
                    <If
                      condition={
                        !hasDecision && !hasRecommendation && !hasNotes
                      }
                    >
                      <div className="scorecard-call-or-form-alert-text required">
                        No notes have been recorded for this call
                      </div>
                    </If>
                    <If
                      condition={!hasDecision && !hasRecommendation && hasNotes}
                    >
                      <div className="scorecard-call-or-form-alert-text required">
                        No recommendation or decision
                      </div>
                    </If>
                  </Otherwise>
                </Choose>
              </When>
              <Otherwise>
                <div className="scorecard-call-or-form-recommendation-decision form">
                  <div className="decision-text">Decision</div>
                  <div className="scorecard-form-decision-display-wrapper">
                    <DecisionRecommendationDisplay
                      outcome={formDecision || 'not-interesting'}
                      type="decision"
                    />
                  </div>
                </div>
              </Otherwise>
            </Choose>
          </div>
        </If>
      </div>
    );
  }
}
