import request from 'superagent';
import Config from '../config';

export default class TemporaryUserHelper {
  static authDataValid(e, s, n, resourceName, resourceId) {
    return request
      .head(`${Config.api.host}/${resourceName}/${resourceId}`)
      .query({
        e,
        s,
        n: !!n
      })
      .then((response) => response && response.ok);
  }
}
