import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import People from 'material-ui/svg-icons/social/people';

import Insights from '../Insights';
import CompressedPeopleInsights from './CompressedPeopleInsights';
import {getDataWrapperClassNames} from '../../../../lib/helpers';
import Tooltip from '../../../Tooltip';

class PeoplePerformance extends React.Component {
  static propTypes = {
    people: PropTypes.object.isRequired,
    useSnapshot: PropTypes.bool,
    previous: PropTypes.bool,
    compressed: PropTypes.bool.isRequired
  };

  state = {
    showTooltip: false
  };

  sortInsights = () => {
    const {
      people: {highlights = [], risks = [], redFlags = []}
    } = this.props;
    const insights = {
      founders: {
        highlights: [],
        risks: [],
        redFlags: []
      },
      team: {
        highlights: [],
        risks: [],
        redFlags: []
      }
    };
    highlights.forEach((highlight) => {
      insights[highlight.source].highlights.push(highlight);
    });
    risks.forEach((risk) => {
      insights[risk.source].risks.push(risk);
    });
    redFlags.forEach((redFlag) => {
      insights[redFlag.source].redFlags.push(redFlag);
    });
    return insights;
  };

  getTooltipText = () => {
    const {compressed = false} = this.props;
    const text = [];
    if (compressed) {
      text.push('People Performance');
    }
    return text.join('<br/>');
  };

  getClassNames = () => {
    const {compressed = false} = this.props;
    const classNames = ['grid-container', 'people-grid-container'];
    if (compressed) {
      classNames.push('compressed');
    }
    return classNames.join(' ');
  };

  render() {
    const {previous = false, compressed = false} = this.props;
    const {showTooltip = false} = this.state;
    const {
      people: {mlUpdates = []}
    } = this.props;
    const {
      founders: {
        highlights: foundersHighlights,
        risks: foundersRisks,
        redFlags: foundersRedFlags
      },
      team: {
        highlights: teamHighlights,
        risks: teamRisks,
        redFlags: teamRedFlags
      }
    } = this.sortInsights();
    return (
      <div
        className="people"
        onMouseLeave={() => {
          if (compressed) {
            this.setState({showTooltip: false});
          }
        }}
        onMouseEnter={() => {
          if (compressed) {
            this.setState({showTooltip: true});
          }
        }}
        ref={(el) => (this._parent = el)}
      >
        <If condition={!compressed}>
          <div className="section-title">{`${
            previous ? 'Previous ' : ''
          }People${window.innerWidth < 768 ? ' Performance' : ''}`}</div>
        </If>
        <Tooltip
          parent={this._parent}
          showTooltip={showTooltip}
          text={this.getTooltipText()}
          customClasses={['performance-tooltip']}
        />
        <div className={this.getClassNames()}>
          <div className="people-grid-item founders-team-highlights-grid-item">
            <div className={getDataWrapperClassNames(compressed)}>
              <div className="data-icon">
                <People className="data-icon-svg" />
              </div>
              <CompressedPeopleInsights
                highlights={[...foundersHighlights, ...teamHighlights]}
                risks={[...foundersRisks, ...teamRisks]}
                redFlags={[...foundersRedFlags, ...teamRedFlags]}
              />
            </div>
          </div>
          <div className="people-grid-item founders-highlights-grid-item">
            <div className={getDataWrapperClassNames(compressed)}>
              <div className="data-header">Founders</div>
              <div className="data-icon">
                <People className="data-icon-svg" />
              </div>
              <Insights
                highlights={foundersHighlights}
                risks={foundersRisks}
                redFlags={foundersRedFlags}
                mlUpdates={mlUpdates}
                hasHeader={true}
                compressed={compressed}
              />
            </div>
          </div>
          <div className="people-grid-item team-highlights-grid-item">
            <div className={getDataWrapperClassNames(compressed)}>
              <div className="data-header">Team</div>
              <Insights
                highlights={teamHighlights}
                risks={teamRisks}
                redFlags={teamRedFlags}
                hasHeader={true}
                compressed={compressed}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PeoplePerformance);
