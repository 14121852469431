import _ from 'underscore';

const qualificationCategoryNames = {
  people: 'People',
  product: 'Product',
  market_strategy: 'Sales and Marketing Strategy',
  market_position: 'Market Position',
  funding: 'Funding'
};

const qualificationProgress = (qualification) => {
  if (!qualification) {
    return 0;
  }
  const allTasks = Object.values(
    _.pick(qualification, Object.keys(qualificationCategoryNames))
  )
    .map((category) => Object.values(category))
    .reduce((a, b) => a.concat(b), []);

  const totalCount = allTasks.length;
  const checkedCount = allTasks.filter((task) => !!task.completed).length;

  if (totalCount === 0) {
    return 0;
  }
  return Math.floor((checkedCount * 100) / totalCount);
};

export {qualificationCategoryNames, qualificationProgress};
