import React from 'react';
import PropTypes from 'prop-types';

import {capitalizeString} from '../../../../lib/helpers';

import CustomModal from '../../../components/CustomModal';
import Card from '../../../card/Card';
import CardHeader from '../../../card/CardHeader';
import CardBody from '../../../card/CardBody';
import ScorecardModalHeader from './ScorecardModalHeader';
import ScorecardModalSectionContents from './ScorecardModalSectionContents';
import ScorecardModalRecommendationsDecision from './ScorecardModalRecommendationsDecision';
import ScorecardModalCallDetails from './ScorecardModalCallDetails';

import scorecardSections from '../../../../content/scorecardSections';
import ConfirmCloseWithoutSavingRecommendationDecisionDialog from '../ConfirmCloseWithoutSavingRecommendationDecisionDialog';
import {
  BuildInvestmentTypeStage,
  BuildRevenueModel
} from '../../../assign/InvestmentTypeStage';
import {Toggle} from 'material-ui';
import callTitles from '../../../../content/callTitles';

export default class ScorecardModal extends React.Component {
  static propTypes = {
    scorecard: PropTypes.object.isRequired,
    sections: PropTypes.object.isRequired,
    organization: PropTypes.object.isRequired,
    organizationName: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    saveStructuredDataField: PropTypes.func.isRequired,
    edit: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    hasConductedCall: PropTypes.bool.isRequired,
    newCall: PropTypes.func.isRequired,
    userRoles: PropTypes.array.isRequired,
    currentUser: PropTypes.string.isRequired,
    submitRecommendationOrDecision: PropTypes.func.isRequired,
    getLatestCall: PropTypes.func.isRequired,
    noShowClick: PropTypes.func.isRequired,
    failedToLoadTemplates: PropTypes.bool.isRequired,
    latestCallNoShow: PropTypes.bool.isRequired,
    hasRecommendationOrDecision: PropTypes.bool.isRequired,
    toggleConfirmNoShowDialog: PropTypes.func.isRequired,
    availableTemplates: PropTypes.bool.isRequired,
    requiredCallDetailsSelected: PropTypes.bool.isRequired,
    defaultOpenId: PropTypes.string.isRequired,
    showErrorDialog: PropTypes.func.isRequired,
    setModalRecommendationDecisionSaved: PropTypes.func.isRequired,
    confirmCloseWithoutSavingRecommendationDecisionDialog:
      PropTypes.bool.isRequired,
    toggleCloseWithoutSavingRecommendationDecisionDialog:
      PropTypes.func.isRequired,
    handleCloseWithoutSavingRecommendationDecision: PropTypes.func.isRequired,
    handleChangeSection: PropTypes.func.isRequired,
    saveFullScorecard: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
    handleCallDetailsChange: PropTypes.func.isRequired,
    handleCallWithChange: PropTypes.func.isRequired,
    handleCallTitleChange: PropTypes.func.isRequired,
    hasMadeChanges: PropTypes.bool.isRequired,
    structuredReasons: PropTypes.object.isRequired,
    reloadOrganization: PropTypes.func.isRequired,
    filesFolder: PropTypes.string,
    commentOnly: PropTypes.bool.isRequired,
    setCommentOnly: PropTypes.func.isRequired,
    scorecardModalEditIndex: PropTypes.string.isRequired,
    setScorecardModalEditIndex: PropTypes.func.isRequired,
    calls: PropTypes.array.isRequired,
    callType: PropTypes.string.isRequired,
    selectCallType: PropTypes.func.isRequired,
    openScorecardModal: PropTypes.func.isRequired,
    emptyScorecardCall: PropTypes.func.isRequired
  };

  state = {
    hasMadeChanges: false
  };

  getTitle = (
    organizationName,
    type,
    calls,
    scorecardModalEditIndex,
    getLatestCall
  ) => {
    let baseString =
      window.innerWidth >= 1024
        ? `${organizationName} - ${capitalizeString(type)} ${
            type === 'new' ? 'call' : 'scorecard'
          }`
        : organizationName;
    if (type === 'edit') {
      const currentCall = getLatestCall(calls, scorecardModalEditIndex);
      baseString += `
        ${callTitles[currentCall.templateId]}
        ${new Date(currentCall.date).toDateString()}
      `;
    }
    return baseString;
  };
  getShouldShowNoShowButton = () => {
    const {
      type = 'view',
      latestCallNoShow = false,
      hasRecommendationOrDecision = false
    } = this.props;
    return type !== 'view' && !latestCallNoShow && !hasRecommendationOrDecision;
  };

  getRecommendationOrDecision = (hasDecision) => {
    const {userRoles} = this.props;
    return hasDecision || userRoles.indexOf('partner') !== -1
      ? 'decision'
      : 'recommendation';
  };

  getRecommendationOrDecisionData = () => {
    const {
      scorecard: {recommendationsDecision: {decision = {}} = {}} = {}
    } = this.props;
    const hasDecision = Object.keys(decision).length !== 0;
    const recommendationOrDecision = this.getRecommendationOrDecision(
      hasDecision
    );
    return {hasDecision, recommendationOrDecision};
  };

  buildClassName = (disabled) => {
    const classNames = ['scorecard-modal-call-details'];
    if (disabled) classNames.push('disabled');
    return classNames.join(' ');
  };

  getShowSection = () => {
    const {sections, scorecard} = this.props;
    let result = {};

    Object.entries(scorecard).map(([section, contents]) => {
      result[section] = false;
    });

    Object.entries(scorecard).map(([section, contents]) => {
      if (
        section !== 'organizationInfo' &&
        section !== 'recommendationsDecision'
      ) {
        contents.map((content) => {
          if (content.templateId && sections[content.templateId]) {
            if (sections[content.templateId].includes(section)) {
              result[section] = true;
            }
          } else if (content.type === 'form') {
            if (sections[content.type].includes(section)) {
              result[section] = true;
            }
          }
        });
      }
    });
    return result;
  };

  render() {
    const {
      scorecard = {},
      sections,
      organization = {},
      organizationName = '',
      show = false,
      close,
      edit,
      type,
      hasConductedCall,
      newCall,
      userRoles,
      currentUser,
      submitRecommendationOrDecision,
      failedToLoadTemplates,
      latestCallNoShow,
      toggleConfirmNoShowDialog,
      availableTemplates,
      requiredCallDetailsSelected,
      defaultOpenId,
      showErrorDialog,
      setModalRecommendationDecisionSaved,
      confirmCloseWithoutSavingRecommendationDecisionDialog,
      toggleCloseWithoutSavingRecommendationDecisionDialog,
      handleCloseWithoutSavingRecommendationDecision,
      handleChangeSection,
      saveFullScorecard,
      saving,
      handleCallDetailsChange,
      handleCallWithChange,
      handleCallTitleChange,
      hasMadeChanges,
      structuredReasons = {},
      reloadOrganization,
      filesFolder,
      commentOnly,
      setCommentOnly,
      scorecardModalEditIndex,
      setScorecardModalEditIndex,
      calls,
      getLatestCall,
      callType,
      selectCallType,
      openScorecardModal,
      emptyScorecardCall
    } = this.props;
    const showSections = this.getShowSection();
    const title = this.getTitle(
      organizationName,
      type || '',
      calls,
      scorecardModalEditIndex,
      getLatestCall
    );
    const showNoShowButton = this.getShouldShowNoShowButton();
    const {
      hasDecision,
      recommendationOrDecision
    } = this.getRecommendationOrDecisionData();
    return (
      <CustomModal
        show={show}
        fullScreen={true}
        customStyles={{backgroundColor: '#e5e5e5'}}
      >
        <div className="scorecard-modal-inner-wrapper">
          <ScorecardModalHeader
            organization={organization}
            title={title}
            close={close}
            edit={setScorecardModalEditIndex}
            save={saveFullScorecard}
            type={type}
            saving={saving}
            hasConductedCall={hasConductedCall}
            newCall={newCall}
            hasMadeChanges={hasMadeChanges}
            latestCallNoShow={latestCallNoShow}
            showNoShowButton={showNoShowButton}
            toggleConfirmNoShowDialog={toggleConfirmNoShowDialog}
            reloadOrganization={reloadOrganization}
            filesFolder={filesFolder}
            calls={calls}
            scorecardModalEditIndex={scorecardModalEditIndex}
            getLatestCall={getLatestCall}
          />
          <div className="scorecard-modal-content-wrapper">
            {Object.entries(scorecard).map(([section, contents]) => {
              return (
                <div className="scorecard-modal-section" key={section}>
                  <Choose>
                    <When condition={scorecardSections.indexOf(section) !== -1}>
                      <If
                        condition={
                          (section !== 'risks' && showSections[section]) ||
                          (section === 'risks' &&
                            hasConductedCall &&
                            showSections[section])
                        }
                      >
                        <Card>
                          <CardHeader title={capitalizeString(section)} />
                          <CardBody>
                            <If
                              condition={
                                section === 'product' && contents.length
                              }
                            >
                              <div
                                className={this.buildClassName(type === 'view')}
                              >
                                <div
                                  className="call-details-row"
                                  id="investmentType"
                                >
                                  <div className="label-column">
                                    Investment Type
                                  </div>
                                  <div className="content-column">
                                    <BuildInvestmentTypeStage
                                      value={contents[0].investmentType}
                                      updateInvestmentInfo={
                                        handleCallDetailsChange
                                      }
                                      selectedValue={
                                        contents[0].investmentType.type
                                      }
                                      disabled={type === 'view'}
                                      investmentInfo="investment_type"
                                    />
                                  </div>
                                </div>
                                <div
                                  className="call-details-row"
                                  id="revenueModels"
                                >
                                  <div className="label-column">
                                    Revenue Models
                                  </div>
                                  <div
                                    className="content-column revenue-models"
                                    style={{marginBottom: '20px'}}
                                  >
                                    <BuildRevenueModel
                                      updateInvestmentInfo={
                                        handleCallDetailsChange
                                      }
                                      selectedValues={
                                        contents[0].revenueModels.model
                                      }
                                      disabled={type === 'view'}
                                      investmentInfo="revenue_models"
                                    />
                                  </div>
                                </div>
                              </div>
                            </If>
                            <ScorecardModalSectionContents
                              contents={contents}
                              sections={sections}
                              type={type}
                              section={section}
                              failedToLoadTemplates={failedToLoadTemplates}
                              availableTemplates={availableTemplates}
                              defaultOpenId={defaultOpenId}
                              handleChangeSection={handleChangeSection}
                              callType={callType}
                              scorecardModalEditIndex={scorecardModalEditIndex}
                              setScorecardModalEditIndex={
                                setScorecardModalEditIndex
                              }
                              selectCallType={selectCallType}
                              show={show}
                              openScorecardModal={openScorecardModal}
                              emptyScorecardCall={emptyScorecardCall}
                            />
                          </CardBody>
                        </Card>
                      </If>
                    </When>
                    <Otherwise>
                      <If condition={hasConductedCall}>
                        <Choose>
                          <When condition={section === 'organizationInfo'}>
                            <Card>
                              <CardHeader title="Call details" />
                              <CardBody>
                                <ScorecardModalCallDetails
                                  details={contents}
                                  disabled={type === 'view'}
                                  type={type}
                                  failedToLoadTemplates={failedToLoadTemplates}
                                  handleCallDetailsChange={
                                    handleCallDetailsChange
                                  }
                                  handleCallWithChange={handleCallWithChange}
                                  handleCallTitleChange={handleCallTitleChange}
                                  organization={organization}
                                  selectCallType={selectCallType}
                                  scorecardModalEditIndex={
                                    scorecardModalEditIndex
                                  }
                                  callType={callType}
                                  emptyScorecardCall={emptyScorecardCall}
                                />
                              </CardBody>
                            </Card>
                          </When>
                          <When
                            condition={
                              section === 'recommendationsDecision' &&
                              !latestCallNoShow
                            }
                          >
                            <Card>
                              <CardHeader
                                title={capitalizeString(
                                  recommendationOrDecision
                                )}
                              />
                              <CardBody>
                                <ScorecardModalRecommendationsDecision
                                  organization={organization}
                                  recommendations={contents.recommendations}
                                  decision={contents.decision}
                                  hasDecision={hasDecision}
                                  userRoles={userRoles}
                                  currentUser={currentUser}
                                  submitRecommendationOrDecision={
                                    submitRecommendationOrDecision
                                  }
                                  type={type}
                                  requiredCallDetailsSelected={
                                    requiredCallDetailsSelected
                                  }
                                  callType={callType}
                                  showErrorDialog={showErrorDialog}
                                  setModalRecommendationDecisionSaved={
                                    setModalRecommendationDecisionSaved
                                  }
                                  structuredReasons={structuredReasons}
                                  reloadOrganization={reloadOrganization}
                                  commentOnly={commentOnly}
                                  setCommentOnly={setCommentOnly}
                                />
                              </CardBody>
                            </Card>
                          </When>
                        </Choose>
                      </If>
                    </Otherwise>
                  </Choose>
                </div>
              );
            })}
          </div>
        </div>
        <ConfirmCloseWithoutSavingRecommendationDecisionDialog
          open={confirmCloseWithoutSavingRecommendationDecisionDialog}
          onCancel={toggleCloseWithoutSavingRecommendationDecisionDialog}
          onConfirm={handleCloseWithoutSavingRecommendationDecision}
          type={recommendationOrDecision}
        />
      </CustomModal>
    );
  }
}
