import React from 'react';
import PropTypes from 'prop-types';

import Avatar from 'material-ui/Avatar';
import Dialog from 'material-ui/Dialog';
import CircularProgress from 'material-ui/CircularProgress';
import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import SelectField from 'material-ui/SelectField';
import TextField from 'material-ui/TextField';

import Organizations from '../../lib/Organizations';
import OrganizationStore from '../../storage/OrganizationStore';

import inreachTeam from '../../config/inreach_team';
import streakStages from '../../config/streak_stages';
import {Checkbox} from 'material-ui';

export default class OverviewEditOrganizationEditOrganization extends React.Component {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    organization: PropTypes.object.isRequired,
    client: PropTypes.string.isRequired
  };

  state = {
    assigned_to: null,
    organizer: null,
    status: '',
    stage: null,
    priority: null
  };

  organizationStore = new OrganizationStore(this.props.organization.id);

  handleSave = () => {
    const {
      organization: {id}
    } = this.props;
    const updates = Object.entries(this.state).map(([field, value]) => ({
      op: 'replace',
      path: `/${field}`,
      value
    }));

    this.setState({isSaving: true}, () => {
      this.organizationStore
        .updateDecision(updates)
        .then((updatedOrganization) => {
          this.setState({isSaving: false}, () => {
            this.props.onSave(updatedOrganization);
          });
        })
        .catch((error) => {
          console.log(error);

          this.setState({isSaving: false});
        });
    });
  };

  handleStatusChange = (e, status) => {
    this.setState({status});
  };

  handleStageChange = (e, i, stage) => {
    this.setState({stage});
  };

  handleAssignedToChange = (e, i, assigned_to) => {
    this.setState({assigned_to});
  };

  handlePriorityChange = (e) => {
    if (e.target.checked) {
      this.setState({priority: 1});
    } else {
      this.setState({priority: null});
    }
  };

  handleOrganizerChange = (e, i, organizer) => {
    this.setState({organizer});
  };

  setOrganizationState = (organization, client) => {
    const state = Organizations.getOrganizationState(organization, client);

    const {
      assigned_to = null,
      organizer = null,
      status = '',
      stage = null,
      priority = null
    } = state;

    this.setState({assigned_to, organizer, status, stage, priority});
  };

  componentDidMount() {
    const {client, organization} = this.props;

    if (!!organization) {
      this.setOrganizationState(organization, client);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {client, organization} = nextProps;

    if (
      organization != null &&
      (!this.props.organization ||
        organization.id !== this.props.organization.id)
    ) {
      this.setOrganizationState(organization, client);
    } else {
      this.setState({
        organization: undefined,
        assigned_to: null,
        organizer: null
      });
    }
  }

  render() {
    const {organization, onCancel} = this.props;

    if (!organization) {
      return null;
    }

    const {
      assigned_to,
      organizer,
      notes,
      status,
      stage,
      isSaving,
      priority
    } = this.state;
    const {id, name} = organization;

    const actions = [
      <RaisedButton
        key="cancel"
        label="cancel"
        disabled={isSaving}
        onClick={onCancel}
      />,
      <RaisedButton
        key="save"
        label={isSaving ? null : 'save'}
        icon={isSaving ? <CircularProgress size={22} /> : null}
        disabled={isSaving}
        onClick={this.handleSave}
        style={{marginLeft: '8px'}}
        primary={true}
      />
    ];

    return (
      <Dialog
        title={`Update ${name}`}
        actions={actions}
        open={true}
        modal={true}
        autoScrollBodyContent={true}
      >
        <TextField
          id={`overview-organization-${id}-status`}
          name={`overview-organization-${id}-status`}
          floatingLabelText="Status"
          floatingLabelFixed={true}
          onChange={this.handleStatusChange}
          value={status}
          fullWidth={true}
        />

        <SelectField
          id={`overview-organization-${id}-stage`}
          name={`overview-organization-${id}-stage`}
          floatingLabelText="Stage"
          floatingLabelFixed={true}
          value={stage}
          onChange={this.handleStageChange}
          fullWidth={true}
        >
          <MenuItem value={null} primaryText="None" />
          {streakStages.filter((s) => !s.disabled).map((s) => (
            <MenuItem key={s.key} value={s.key} primaryText={s.name} />
          ))}
        </SelectField>

        <SelectField
          id={`overview-organization-${id}-assignee`}
          name={`overview-organization-${id}-assignee`}
          floatingLabelText="Assignee"
          floatingLabelFixed={true}
          value={assigned_to}
          onChange={this.handleAssignedToChange}
          fullWidth={true}
        >
          <MenuItem value={null} primaryText="None" />
          {inreachTeam.map((p) => (
            <MenuItem
              className="select-person-menu-item"
              key={p.email}
              value={p.email}
              primaryText={p.name}
              leftIcon={
                <Avatar className="select-person-avatar" src={p.imageUrl} />
              }
            />
          ))}
        </SelectField>

        <SelectField
          id={`overview-organization-${id}-organizer`}
          name={`overview-organization-${id}-organizer`}
          floatingLabelText="Organizer"
          floatingLabelFixed={true}
          value={organizer}
          onChange={this.handleOrganizerChange}
          fullWidth={true}
        >
          <MenuItem value={null} primaryText="None" />
          {inreachTeam.map((p) => (
            <MenuItem
              className="select-person-menu-item"
              key={p.email}
              value={p.email}
              primaryText={p.name}
              leftIcon={
                <Avatar className="select-person-avatar" src={p.imageUrl} />
              }
            />
          ))}
        </SelectField>

        <Checkbox
          label="Urgent"
          checked={!!priority}
          onClick={this.handlePriorityChange}
        />
      </Dialog>
    );
  }
}
