import React from 'react';
import PropTypes from 'prop-types';

import CustomizeCampaign from './CustomizeCampaign';
import AssignCampaigns from '../../lib/AssignCampaigns';

export default class PassCustomizeCampaign extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    client: PropTypes.string.isRequired,
    organizationDecision: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    recentMessages: PropTypes.object.isRequired,
    structuredReasons: PropTypes.object.isRequired
  };

  state = {
    loading: false,
    from: null,
    assignedTo: null,
    organizer: null,
    fallbackToCall: null
  };

  componentWillMount() {
    const {
      organizationDecision: {
        assigned_to: decisionAssignedTo = 'karolina@inreachventures.com',
        organizer: decisionOrganizer = 'marina@inreachventures.com'
      }
    } = this.props;

    this.handlePeopleChange(
      this.defaultFrom(),
      decisionAssignedTo,
      decisionOrganizer
    );
  }

  componentDidUpdate(previousProps) {
    const {
      organizationDecision: {
        assigned_to: previousAssignedTo,
        organizer: previousOrganizer
      }
    } = previousProps;
    const {
      organizationDecision: {
        assigned_to: currentAssignedTo,
        organizer: currentOrganizer
      }
    } = this.props;
    const {from, stateAssignedTo, campaignSelected} = this.state;

    // So the user left the modal and changed the assigned_to / organizer
    if (
      campaignSelected &&
      (previousAssignedTo !== currentAssignedTo ||
        previousOrganizer !== currentOrganizer)
    ) {
      const from =
        previousAssignedTo === stateAssignedTo &&
        from === stateAssignedTo &&
        currentAssignedTo !== stateAssignedTo
          ? currentAssignedTo
          : this.defaultFrom();
      this.handlePeopleChange(from, currentAssignedTo, currentOrganizer);
    }
  }

  defaultFrom = () => {
    const {
      organizationDecision: {
        assigned_to: assignedTo = 'karolina@inreachventures.com',
        state
      }
    } = this.props;
    switch (state) {
      case 'more_info':
      case 'make_contact':
        return process.env.NODE_ENV === 'test'
          ? 'test.partner@inreachventures.com'
          : 'roberto@inreachventures.com';
    }
    return assignedTo;
  };

  handleAssignedToChange = (assignedTo) => {
    const {organizer, from} = this.state;
    this.handlePeopleChange(from, assignedTo, organizer);
  };

  handleOrganizerChange = (organizer) => {
    const {assignedTo, from} = this.state;
    this.handlePeopleChange(from, assignedTo, organizer);
  };

  handleFromChange = (from) => {
    const {assignedTo, organizer} = this.state;
    this.handlePeopleChange(from, assignedTo, organizer);
  };

  handlePeopleChange(from, assignedTo, organizer) {
    this.setState({from, assignedTo, organizer});
  }

  submitCampaign = (campaignSelected, campaignConfiguration) => {
    const {organizationDecision, handleSubmit} = this.props;
    const {from, assignedTo, organizer} = this.state;

    const assignCampaign = AssignCampaigns.processCampaign(
      campaignSelected,
      campaignConfiguration,
      organizationDecision,
      from,
      assignedTo,
      organizer,
      'contact',
      'passed'
    );

    return handleSubmit(assignCampaign);
  };

  render() {
    const {
      organization,
      client,
      open,
      handleClose,
      recentMessages,
      organizationDecision,
      structuredReasons
    } = this.props;
    const {from, assignedTo, organizer} = this.state;

    return (
      <React.Fragment>
        <If condition={open}>
          <CustomizeCampaign
            selectAssigned={true}
            scheduleCall={false}
            organization={organization}
            isEditingCampaign={open}
            campaignSelected={'pass'}
            onDone={handleClose}
            submitCampaign={this.submitCampaign}
            client={client}
            decision={organizationDecision}
            from={from}
            assignedTo={assignedTo}
            organizer={organizer}
            defaultMailingEnabled={false}
            recentMessages={recentMessages}
            onChangeFrom={this.handleFromChange}
            onChangeAssignedTo={this.handleAssignedToChange}
            onChangeOrganizer={this.handleOrganizerChange}
            structuredReasons={structuredReasons}
          />
        </If>
      </React.Fragment>
    );
  }
}
