import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import Locations from '../../organization/profile/summary/Locations';
import InvestmentsPreview from './InvestmentsPreview';
import FundingRoundsPreview from './FundingRoundsPreview';
import LocationsPreview from './LocationsPreview';
import InvestmentTypePreview from './InvestmentTypePreview';
import IndustriesPreview from './IndustriesPreview';
import InvestorInsightsPreview from './InvestorInsightsPreview';
import TopCompaniesPreview from './TopCompaniesPreview';
import InvestorActivityPreview from './InvestorActivityPreview';
import DecisionsPreview from './DecisionsPreview';
import WorkflowPreview from './WorkflowPreview';
import ContactsPreview from './ContactsPreview';

class InvestorPreviewSummary extends React.Component {
  static propTypes = {
    investor: PropTypes.object.isRequired,
    isTop: PropTypes.bool.isRequired
  };

  state = {};

  render() {
    const {investor, isTop} = this.props;
    return (
      <div className="company-info">
        <div className="grid-container investor-info-grid-container">
          <div className="investor-info-grid-item grid-item-1">
            {isTop ? (
              <Locations locations={investor} compressed={true} />
            ) : (
              <InvestorInsightsPreview investor={investor} compressed={true} />
            )}
          </div>
          <div className="investor-info-grid-item border-1-grid-item" />
          <div className="investor-info-grid-item grid-item-2">
            {isTop ? (
              <InvestmentsPreview investor={investor} compressed={true} />
            ) : (
              <TopCompaniesPreview investor={investor} compressed={true} />
            )}
          </div>
          <div className="investor-info-grid-item border-2-grid-item" />
          <div className="investor-info-grid-item grid-item-3">
            {isTop ? (
              <FundingRoundsPreview investor={investor} compressed={true} />
            ) : (
              <InvestorActivityPreview investor={investor} compressed={true} />
            )}
          </div>
          <div className="investor-info-grid-item border-3-grid-item" />
          <div className="investor-info-grid-item grid-item-4">
            {isTop ? (
              <LocationsPreview investor={investor} compressed={true} />
            ) : (
              <DecisionsPreview investor={investor} compressed={true} />
            )}
          </div>
          <div className="investor-info-grid-item border-4-grid-item" />
          <div className="investor-info-grid-item grid-item-5">
            {isTop ? (
              <InvestmentTypePreview investor={investor} compressed={true} />
            ) : (
              <WorkflowPreview investor={investor} compressed={true} />
            )}
          </div>
          <div className="investor-info-grid-item border-5-grid-item" />
          <div className="investor-info-grid-item grid-item-6">
            {isTop ? (
              <IndustriesPreview investor={investor} compressed={true} />
            ) : (
              <ContactsPreview investor={investor} compressed={true} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(InvestorPreviewSummary);
