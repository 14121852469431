import React from 'react';
import PropTypes from 'prop-types';

import {capitalizeString} from '../../../../../lib/helpers';

import Config from '../../../../../config';

import xss from 'xss';

export default class TimelineEventPreQualification extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    getUserName: PropTypes.func.isRequired
  };

  buildItem = (item = this.props.item) => {
    const preQualification = item.pre_qualification;
    return {
      label: this.getPreQualificationLabels(
        preQualification.user_id,
        preQualification.decision
      ),
      subLabel: null,
      actions: this.getPreQualificationActions(preQualification.organization_id)
    };
  };

  getPreQualificationLabels = (userEmail, decision) =>
    `Pre Qualification decision: <b>${capitalizeString(decision)}</b>`;

  getPreQualificationActions = (organization_id) => [
    {
      text: 'Pre Qualification',
      href: `${
        Config.web.host
      }/organizations/${organization_id}/pre_qualification`
    }
  ];

  render() {
    const {label, subLabel, actions} = this.buildItem();
    return (
      <div className="timeline-event-inner-wrapper">
        <h5
          className="timeline-event-label"
          dangerouslySetInnerHTML={{__html: xss(label)}}
        />
        <If condition={subLabel !== null}>
          <p className="timeline-event-sub-label">{subLabel}</p>
        </If>
        <div className="spacer" />
      </div>
    );
  }
}
