import React from 'react';
import {Link, withRouter} from 'react-router';
import PropTypes from 'prop-types';

import RaisedButton from 'material-ui/RaisedButton';

class ViewOnStore extends React.Component {
  static propTypes = {
    details: PropTypes.object.isRequired
  };

  getLink = () => {
    const {
      details: {store, id}
    } = this.props;
    switch (store) {
      case 'IOS':
        return `https://itunes.apple.com/gb/app/id${id}`;
      case 'PLAY':
        return `https://play.google.com/store/apps/details?id=${id}`;
    }
  };

  render() {
    const {
      details: {store}
    } = this.props;
    return (
      <div className="view-on-store-component">
        <Link target="_blank" to={this.getLink()}>
          <RaisedButton
            className="view-on-store-button"
            label={`${window.innerWidth >= 768 ? 'View on ' : ''}${
              store === 'IOS' ? 'AppStore' : 'PlayStore'
            }`}
            backgroundColor="#7f7f7f"
            labelColor="#ffffff"
          />
        </Link>
      </div>
    );
  }
}

export default withRouter(ViewOnStore);
