import React from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router';
import moment from 'moment';

import Avatar from 'material-ui/Avatar';
import MenuItem from 'material-ui/MenuItem';
import {Toolbar, ToolbarGroup, ToolbarTitle} from 'material-ui/Toolbar';
import SelectField from 'material-ui/SelectField';
import IconButton from 'material-ui/IconButton/IconButton';

import NavigationClose from 'material-ui/svg-icons/navigation/close';
import NavigationArrowBack from 'material-ui/svg-icons/navigation/arrow-back';
import PhoneForwarded from 'material-ui/svg-icons/notification/phone-forwarded';
import ActionAssignmentLate from 'material-ui/svg-icons/action/assignment-late';
import ActionAssignmentTurnedIn from 'material-ui/svg-icons/action/assignment-turned-in';
import Build from 'material-ui/svg-icons/action/build';

import SocialLink from '../organization/SocialLink';
import State from '../State';
import JsonDev from '../JsonDev';
import STREAK_STAGES from '../../config/streak_stages';
import StateSummary from '../StateSummary';
import TemporaryUserStore from '../../storage/TemporaryUserStore';
import ShareLinkDialog from './ShareLinkDialog';
import IsNotSpam from '../IsNotSpam';
import AssignedTo from './AssignedTo';
import Organizer from './Organizer';
import InboxControls from './InboxControls';
import DoNotContact from '../DoNotContact';
import OrganizationStage from '../components/OrganizationStage';

import Organizations from '../../lib/Organizations';
import imageUrl from '../../lib/imageUrl';
import CustomizeCampaign from '../assign/CustomizeCampaign';
import StageChange from '../assign/StageChange';
import AssignCampaigns from '../../lib/AssignCampaigns';
import EvaluationBar from '../assign/EvaluationBar';
import PassCustomizeCampaign from '../assign/PassCustomizeCampaign';
import _ from 'underscore';
import TopBarActions from './TopBarActions';
import inboxHelper from '../../lib/InboxHelper';

function hasForm(organization) {
  const {source_refs: sourceRefs = {}} = organization;
  return (
    sourceRefs.hasOwnProperty('FUNDING') ||
    sourceRefs.hasOwnProperty('V2FUNDING')
  );
}

class OrganizationHeader extends React.Component {
  static propTypes = {
    userRoles: PropTypes.array.isRequired,
    toggleManualHelp: PropTypes.func.isRequired,
    handleTogglePriority: PropTypes.func.isRequired,
    organization: PropTypes.object.isRequired,
    client: PropTypes.string.isRequired,
    organizationDecision: PropTypes.object.isRequired,
    showAsPublicProfile: PropTypes.bool,
    handleOrganizationChange: PropTypes.func.isRequired,
    returnUrl: PropTypes.string.isRequired,
    createQualificationChecklist: PropTypes.func.isRequired,
    structuredReasons: PropTypes.object.isRequired,
    handleSendFeedbackMenuItemClick: PropTypes.func.isRequired,
    filterSource: PropTypes.object.isRequired
  };

  state = {
    shareLink: null,
    openShareDialog: false,
    openMergeDialog: false,
    isEditingReconnectCampaign: false,
    isEditingPassCampaign: false,
    isChangingStage: false,
    showStageReason: false
  };

  handleShareDialogOpen = () => {
    this.setState({
      openShareDialog: true
    });
  };

  handleShareDialogClose = () => {
    this.setState({openShareDialog: false});
  };

  handleMergeDialogClose = () => {
    this.setState({openMergeDialog: false});
  };

  share = () => {
    this.createShareLink().then(this.handleShareDialogOpen);
  };

  createShareLink = (includeScorecard) => {
    const temporaryUserStore = new TemporaryUserStore(
      this.props.organization.id
    );
    return temporaryUserStore
      .createShareLink(includeScorecard)
      .then((shareLink) => {
        this.setState({shareLink});
        return shareLink;
      });
  };

  toggleStageChange = (isChangingStage) => {
    this.setState({isChangingStage: isChangingStage});
  };

  toggleEditPassCampaign = (isEditingPassCampaign) => {
    this.setState({isEditingPassCampaign: isEditingPassCampaign});
  };

  submitPass = (assignCampaign) => {
    const {onStateChange} = this.props;
    return onStateChange(assignCampaign).then(() =>
      this.toggleEditPassCampaign(false)
    );
  };

  changeStageAndClose = (stage) => {
    return this.props
      .onStageChange(stage)
      .then(() => this.toggleStageChange(false));
  };

  goToPage = (page) => {
    const {router, location, organization: {id} = {}} = this.props;
    router.push({
      ...location,
      ...{
        pathname: `/organizations/${id}/${page}`
      }
    });
  };

  topBarIcons = () => {
    const {
      organization,
      currentUser,
      addToInbox,
      removeFromInbox,
      showInboxModal,
      inbox,
      userInboxes,
      isUpdatingInbox,
      userRoles
    } = this.props;
    return (
      <React.Fragment>
        <If condition={userRoles.includes('admin')}>
          <If condition={!_.isEmpty(organization)}>
            <span className="organization-link top-bar-icons">
              <JsonDev
                organization={organization}
                objectType={'organizations'}
                className="json-icon"
              />
            </span>
          </If>
        </If>

        <If
          condition={
            userRoles.includes('admin') &&
            ((organization.source_refs || {}).OUTREACH || []).length > 0
          }
        >
          <span className="organization-link top-bar-icons">
            <SocialLink
              source="outreach"
              link={`https://app2a.outreach.io/accounts/${
                organization.source_refs.OUTREACH[0]
              }/overview`}
            />
          </span>
        </If>

        <If condition={organization.homepage_url}>
          <span className="organization-link top-bar-icons">
            <SocialLink source="website" link={organization.homepage_url} />
          </span>
        </If>

        <If condition={organization.linkedin}>
          <span className="organization-link top-bar-icons">
            <SocialLink source="linkedin" link={organization.linkedin} />
          </span>
        </If>
        <If condition={organization.crunchbase}>
          <span className="organization-link top-bar-icons">
            <SocialLink source="crunchbase" link={organization.crunchbase} />
          </span>
        </If>
        <If condition={!organization.linkedin}>
          <span className="organization-link top-bar-icons">
            <SocialLink
              source="linkedin"
              link={`https://www.linkedin.com/search/results/companies?keywords=${encodeURIComponent(
                organization.name
              )}`}
            />
          </span>
        </If>
        <If condition={currentUser}>
          <div className="organization-inbox top-bar-icons">
            <InboxControls
              disabled={isUpdatingInbox}
              currentUser={currentUser}
              addToInbox={addToInbox}
              removeFromInbox={removeFromInbox}
              showInboxModal={showInboxModal}
              inbox={inbox}
              organization={organization}
              userInboxes={userInboxes}
            />
          </div>
        </If>
      </React.Fragment>
    );
  };

  render() {
    const {
      organization,
      client,
      organizationDecision,
      showAsPublicProfile,
      userHasFullAccess,
      deleteSignals,
      userRoles,
      toggleManualHelp,
      handleTogglePriority,
      onAssignedToChange,
      onOrganizerChange,
      onIsNotSpamChange,
      returnUrl,
      recentMessages,
      showErrorDialog,
      handleFormEvaluationChange,
      onStateChange,
      createQualificationChecklist,
      structuredReasons,
      handleSendFeedbackMenuItemClick,
      currentUser,
      addToInbox,
      removeFromInbox,
      showInboxModal,
      isUpdatingInbox
    } = this.props;

    const {
      isChangingStage,
      isEditingPassCampaign,
      showStageReason = false
    } = this.state;

    const decision =
      Organizations.getOrganizationState(organization, client) || {};

    const {form: inbound = false, priority} = decision;

    const inboxData = {
      currentUserInbox: inboxHelper.getInbox(organization, client, currentUser),
      userInboxes: inboxHelper
        .getUserInboxes(organization, client, currentUser)
        .filter(({inbox}) => !!inbox)
        .map(({assigned_to}) => assigned_to),
      activeUserInbox: inboxHelper.getInbox(organization, client, ''),
      form: {
        hasForm: hasForm(organization),
        inbound
      }
    };

    return (
      <React.Fragment>
        <Toolbar className="top-bar-tool-bar">
          <div className="top-bar">
            <ToolbarGroup
              className="top-bar-first-row"
              style={priority === 1 ? {backgroundColor: '#ffdf87'} : {}}
            >
              <div className="organization-title">
                <Link to={returnUrl}>
                  <IconButton
                    tooltip="Back to organizations list"
                    className="organization-back"
                  >
                    <NavigationArrowBack />
                  </IconButton>
                </Link>
                <Avatar
                  className="organization-avatar"
                  src={imageUrl({url: organization.image_url})}
                />
                <ToolbarTitle
                  className="organization-name"
                  text={organization.name}
                />
              </div>
              <TopBarActions
                organization={organization}
                email={currentUser}
                inboxData={inboxData}
                addToInbox={addToInbox}
                removeFromInbox={removeFromInbox}
                showInboxModal={showInboxModal}
                isUpdatingInbox={isUpdatingInbox}
                userRoles={userRoles}
                userHasFullAccess={userHasFullAccess}
                decision={decision}
                handleSendFeedbackMenuItemClick={
                  handleSendFeedbackMenuItemClick
                }
                share={this.share}
                toggleStageChange={this.toggleStageChange}
                toggleEditPassCampaign={this.toggleEditPassCampaign}
                goToPage={this.goToPage}
                deleteSignals={deleteSignals}
                toggleManualHelp={toggleManualHelp}
                handleTogglePriority={handleTogglePriority}
                createQualificationChecklist={createQualificationChecklist}
                source="profile"
              />
            </ToolbarGroup>
            <ToolbarGroup className="top-bar-second-row">
              <div className="top-bar-selectors">
                <If
                  condition={
                    (organizationDecision.assigned_to ||
                      organizationDecision.state === 'contact') &&
                    userHasFullAccess
                  }
                >
                  <AssignedTo
                    assignedTo={organizationDecision.assigned_to}
                    handleAssignedToChange={onAssignedToChange}
                  />
                </If>

                <If
                  condition={
                    organizationDecision.assigned_to && userHasFullAccess
                  }
                >
                  <Organizer
                    organizer={organizationDecision.organizer}
                    onOrganizerChange={onOrganizerChange}
                  />
                </If>
                <div className="spacer top-bar-spacer" />
                <If condition={organizationDecision.stage && userHasFullAccess}>
                  <OrganizationStage
                    decision={organizationDecision}
                    showStage={false}
                    compressed={false}
                  />
                </If>
              </div>
              <div className="organization-status">
                <div style={{marginTop: '4px'}}>
                  <State
                    showIcons={true}
                    state={(organizationDecision || {}).state || 'unseen'}
                    handleError={showErrorDialog}
                    onFormEvaluationChange={handleFormEvaluationChange}
                    handleStateChange={onStateChange}
                    {...this.props}
                  />
                </div>
                <If condition={organizationDecision.fallback_to_call}>
                  <IconButton tooltip="Fallback to Call">
                    <PhoneForwarded color="#337ab7" />
                  </IconButton>
                </If>
                <If condition={organizationDecision.requires_manual_help}>
                  <IconButton tooltip="Requires Manual Help">
                    <Build color="#337ab7" />
                  </IconButton>
                </If>
                <If condition={organizationDecision.state}>
                  <StateSummary
                    state={organizationDecision.state}
                    organization={organization}
                  />
                </If>
              </div>
            </ToolbarGroup>
            <ToolbarGroup className="top-bar-third-row">
              <If condition={organization.is_closed}>
                <IconButton>
                  <NavigationClose />
                </IconButton>
                <ToolbarTitle text="Closed" />
              </If>
              <If condition={!!organization.do_not_contact}>
                <DoNotContact />
              </If>
              <If
                condition={
                  organizationDecision.state !== 'contact' &&
                  organization.combined_classification === 'never' &&
                  !(
                    !!organizationDecision.state && !organization.is_not_spam
                  ) &&
                  userHasFullAccess
                }
              >
                <IsNotSpam
                  isNotSpam={!!organization.is_not_spam}
                  organization_id={organization.id}
                  handleIsNotSpamChange={onIsNotSpamChange}
                />
              </If>
              <div className="spacer" />
              <If condition={this.props.formStatus}>
                <If condition={this.props.formSubmittedDate}>
                  <IconButton tooltip={this.props.formStatus}>
                    {this.props.formStatus === 'Complete' ? (
                      <ActionAssignmentTurnedIn />
                    ) : (
                      <ActionAssignmentLate />
                    )}
                  </IconButton>
                  <ToolbarTitle
                    text={`${moment(
                      this.props.formSubmittedDate
                    ).fromNow()} — ${inbound ? 'Outbound' : 'Inbound'}`}
                    style={{fontSize: '14px', lineHeight: '20px'}}
                  />
                </If>
              </If>
              <If
                condition={
                  this.props.formEntries &&
                  this.props.selectedFormEntry &&
                  this.props.formEntries.length > 1
                }
              >
                <div>
                  <SelectField
                    floatingLabelText="Form entries"
                    selectedMenuItemStyle={{color: '#5DAB49'}}
                    value={this.props.selectedFormEntry}
                    onChange={(event, key, value) =>
                      this.props.handleFormEntryChange(value)
                    }
                  >
                    {this.props.formEntries.map((formEntry) => (
                      <MenuItem
                        key={formEntry}
                        value={formEntry}
                        primaryText={formEntry}
                      />
                    ))}
                  </SelectField>
                </div>
              </If>
            </ToolbarGroup>
            <If condition={userHasFullAccess}>
              <ToolbarGroup className="top-bar-fourth-row">
                <EvaluationBar {...this.props} />
              </ToolbarGroup>
            </If>
          </div>
        </Toolbar>
        <ShareLinkDialog
          link={this.state.shareLink}
          open={this.state.openShareDialog}
          createShareLink={this.createShareLink}
          handleOpen={this.handleShareDialogOpen}
          handleClose={this.handleShareDialogClose}
        />
        <If condition={isChangingStage}>
          <StageChange
            isChangingStage={isChangingStage}
            onDone={this.toggleStageChange}
            stage={organizationDecision.stage}
            reduced={true}
            onStageChange={this.changeStageAndClose}
            organizationDecision={organizationDecision}
          />
        </If>
        <If condition={isEditingPassCampaign}>
          <PassCustomizeCampaign
            organization={organization}
            client={client}
            organizationDecision={organizationDecision}
            open={isEditingPassCampaign}
            handleClose={this.toggleEditPassCampaign}
            handleSubmit={this.submitPass}
            recentMessages={recentMessages}
            structuredReasons={structuredReasons}
          />
        </If>
      </React.Fragment>
    );
  }
}

export default withRouter(OrganizationHeader);
