import React from 'react';
import FilterSummaryTag from './FilterSummaryTag';
import LOCATIONS from '../../content/locations';
import AutoComplete from 'material-ui/AutoComplete';

class CountriesFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countriesInput: undefined,
      suggestedCountries: []
    };

    this.updateCountriesInput = this.updateCountriesInput.bind(this);
    this.addCountry = this.addCountry.bind(this);
    this.deleteCountry = this.deleteCountry.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.initCountries = this.initCountries.bind(this);
    this.handleNewRequest = this.handleNewRequest.bind(this);
  }

  componentDidMount() {
    this.initCountries(this.props.continent);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.continent !== newProps.continent) {
      this.initCountries(newProps.continent);
    }
  }

  initCountries(continent) {
    this.setState({
      suggestedCountries: LOCATIONS.countries[continent]
        ? LOCATIONS.countries[continent]
        : []
    });
  }

  addCountry() {
    if (this.state.countriesInput && this.state.countriesInput.length > 0) {
      const cleanedCountriesInput = this.state.countriesInput.replace(',', ''),
        newCountries = this.props.countries
          ? `${cleanedCountriesInput},${this.props.countries}`
          : cleanedCountriesInput;
      this.props.handleCountriesChange(newCountries);
      this.setState({
        countriesInput: null
      });
    }
  }

  deleteCountry(valueToDelete) {
    const currentParamValues = this.props.countries.split(','),
      indexOfValueToDelete = currentParamValues.indexOf(valueToDelete);
    if (indexOfValueToDelete !== -1) {
      currentParamValues.splice(indexOfValueToDelete, 1);
    }

    this.props.handleCountriesChange(currentParamValues.join(','));
  }

  onKeyPress(event) {
    if (event.charCode === 13) {
      // enter key pressed
      event.preventDefault();
      this.addCountry();
    }
  }

  updateCountriesInput(e, value) {
    if (value.indexOf(',') === -1) {
      this.setState({
        countriesInput: value
      });
    } else {
      this.addCountry();
    }
  }

  handleNewRequest() {
    this.addCountry();
    this.setState({
      countriesInput: ''
    });
  }

  render() {
    const {filterType = 'organization'} = this.props;
    return (
      <div className="countries-filters">
        <div className="add-countries">
          <label> Countries </label>
          <div>
            <AutoComplete
              filter={AutoComplete.fuzzyFilter}
              name="edit-countries"
              hintText={'Type countries here...'}
              onNewRequest={this.handleNewRequest}
              className="input"
              searchText={this.state.countriesInput || ''}
              fullWidth={true}
              onUpdateInput={(value) => this.updateCountriesInput(null, value)}
              dataSource={this.state.suggestedCountries}
              maxSearchResults={5}
            />
          </div>
        </div>
        <If condition={this.props.countries}>
          <div className="countries-tags">
            {this.props.countries.split(',').map((country) => {
              return (
                <FilterSummaryTag
                  key={country}
                  type="location"
                  text={country}
                  handleDelete={() => {
                    this.deleteCountry(country);
                  }}
                />
              );
            })}
          </div>
        </If>
      </div>
    );
  }
}

export default CountriesFilter;
