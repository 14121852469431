import React from 'react';
import Avatar from 'material-ui/Avatar';
import Chip from 'material-ui/Chip';

import {Link} from 'react-router';

class Tags extends React.Component {
  render() {
    if (!this.props.data) {
      return false;
    }

    return (
      <div className="organization-tags">
        {this.props.data.map((tag) => {
          return (
            <Chip key={tag} className="tag">
              <Avatar
                style={{background: 'black'}}
                icon={<div className="tag-icon" />}
              />
              <Link
                style={{color: 'black'}}
                target="_blank"
                to={`/organizations?tag=${tag}`}
              >
                {tag}
              </Link>
            </Chip>
          );
        })}
      </div>
    );
  }
}

export default Tags;
