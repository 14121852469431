import request from 'superagent';
import auth from './Auth';
import Config from '../config';
import Q from 'q';

export default class SignalStore {
  generateParams(
    idToken,
    client,
    to,
    from,
    period,
    signalTypeFilters,
    temporaryExpirationDate,
    temporarySignature,
    temporaryShowNote
  ) {
    const params = {
      period: period === null ? undefined : period,
      from: from === null ? undefined : from,
      to: to === null ? undefined : to,
      signal_type: signalTypeFilters === null ? undefined : signalTypeFilters
    };
    if (temporaryExpirationDate && temporarySignature) {
      params.e = temporaryExpirationDate;
      params.s = temporarySignature;
      params.n = !!temporaryShowNote;
    } else {
      params.access_token = idToken;
      params.client = client;
    }
    return params;
  }

  fetchAllSignals(
    modelType,
    id,
    from,
    to,
    period,
    signalTypeFilters,
    temporaryExpirationDate,
    temporarySignature,
    temporaryShowNote
  ) {
    if (temporaryExpirationDate && temporarySignature) {
      return request
        .get(Config.api.host + '/' + modelType + '/' + id + '/signals/all')
        .query(
          this.generateParams(
            null,
            null,
            to,
            from,
            period,
            signalTypeFilters,
            temporaryExpirationDate,
            temporarySignature,
            temporaryShowNote
          )
        )
        .then((response) => response.body.signals || {});
    }

    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(Config.api.host + '/' + modelType + '/' + id + '/signals/all')
          .query(
            this.generateParams(
              authData.token,
              authData.client,
              to,
              from,
              period,
              signalTypeFilters
            )
          )
      )
      .then((response) => response.body.signals || {});
  }
}
