export default {
  top_tier: {
    max: 100,
    min: 90,
    name: 'Super Interesting',
    color: '#1c1eab'
  },
  very_high: {
    max: 90,
    min: 75,
    name: 'Very Interesting',
    color: '#1e5eab'
  },
  high: {
    max: 75,
    min: 55,
    name: 'Interesting',
    color: '#3bab85'
  },
  normal: {
    max: 55,
    min: 45,
    name: 'Normal',
    color: '#5dab49'
  },
  low: {
    max: 45,
    min: 25,
    name: 'Slightly Interesting',
    color: '#ab5423'
  },
  very_low: {
    max: 25,
    min: 1,
    name: 'Not Interesting',
    color: '#ab1825'
  },
  none: {
    max: 1,
    min: -1,
    name: 'None',
    color: '#000000'
  }
};
