export default [
  {
    key: 'more_info',
    name: 'More Info',
    disabled: false
  },
  {
    key: 'make_contact',
    name: 'Make Contact',
    disabled: false
  },
  {
    key: 'call_meeting',
    name: 'Call-Meeting',
    disabled: false
  },
  {
    key: 'pre_qualification',
    name: 'Pre Qualification',
    disabled: false
  },
  {
    key: 'qualification',
    name: 'Qualification',
    disabled: false
  },
  {
    key: 'termsheet',
    name: 'Termsheet',
    disabled: false
  },
  {
    key: 'closed',
    name: 'Closed',
    disabled: false
  },
  {
    key: 'in_tracking',
    name: 'In Tracking',
    disabled: false
  },
  {
    key: 'passed',
    name: 'Passed',
    disabled: true
  },
  {
    key: 'to_be_passed',
    name: 'To be Passed',
    disabled: true
  },
  {
    key: 'unable_to_contact',
    name: 'Unable to Contact'
  },
  {
    key: 'operational_unable_to_reconnect',
    name: 'Operational Unable to Reconnect',
    disabled: true
  },
  {
    key: 'investor_unable_to_reconnect',
    name: 'Investor Unable to Reconnect',
    disabled: true
  },
  {
    key: 'in_progress',
    name: 'In Progress (Legacy)',
    disabled: true
  }
];
