import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import Tooltip from '../../Tooltip';
import PortfolioLink from '../investor_info/PortfolioLink';
import AttachMoney from 'material-ui/svg-icons/editor/attach-money';
import Ago from '../../Ago';
import _ from 'underscore';
class InvestmentsPreview extends React.Component {
  static propTypes = {
    investor: PropTypes.object.isRequired
  };
  state = {
    showTooltip: false
  };

  hasError = () => {
    return true;
  };

  render() {
    const {
      investor: {
        id: investorId = '',
        aggregations = {},
        aggregations: {
          first_funding_round_announced_on = 0,
          last_funding_round_announced_on = 0,
          workflow: {seen = 0, unseen = 0} = {}
        } = {}
      } = {}
    } = this.props;
    const {showTooltip} = this.state;
    return (
      <div
        className="data-wrapper investments compressed"
        onMouseLeave={() => {
          if (this.hasError()) {
            this.setState({showTooltip: false});
          }
        }}
        onMouseEnter={() => {
          if (this.hasError()) {
            this.setState({showTooltip: true});
          }
        }}
        rel={(el) => (this._parent = el)}
      >
        <Tooltip
          parent={this._parent}
          showTooltip={showTooltip}
          text="Investments"
        />
        <div className="data-icon">
          <AttachMoney className="data-icon-svg" />
        </div>
        <Choose>
          <When
            condition={_.isEmpty(aggregations) || (seen === 0 && unseen === 0)}
          >
            <div className="data-content text empty">No data</div>
          </When>
          <Otherwise>
            <div className="preview-box">
              <div className="invesor-profile-row">
                <div className="data-content text invesor-profile-div">
                  Companies:
                </div>
                <div className="spacer" />
                <PortfolioLink
                  text={seen + unseen}
                  investorId={investorId}
                  filters={{investor_ids: investorId}}
                />
              </div>
              <If condition={first_funding_round_announced_on}>
                <div className="invesor-profile-row">
                  <div className="data-content text invesor-profile-div">
                    1st:
                  </div>
                  <div className="spacer" />
                  <span>
                    <Ago
                      timestamp={new Date(
                        first_funding_round_announced_on
                      ).getTime()}
                    />{' '}
                    ago
                  </span>
                </div>
              </If>
              <If condition={last_funding_round_announced_on}>
                <div className="invesor-profile-row">
                  <div className="data-content text invesor-profile-div">
                    Last:
                  </div>
                  <div className="spacer" />
                  <span>
                    <Ago
                      timestamp={new Date(
                        last_funding_round_announced_on
                      ).getTime()}
                    />{' '}
                    ago
                  </span>
                </div>
              </If>
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(InvestmentsPreview);
