import React from 'react';
import PropTypes from 'prop-types';

import TextField from 'material-ui/TextField';

import SocialLink from '../../organization/SocialLink';

import xss from 'xss';
import _ from 'underscore';
import EditImage from './../../organization/edit/EditImage';

export default class EditBasicInfo extends React.Component {
  static propTypes = {
    investor: PropTypes.object.isRequired,
    updateFields: PropTypes.func.isRequired
  };

  state = {
    error: false
  };

  handleNameChange = (event) => {
    const name = xss(event.target.value);
    if (!name || name.length < 1) {
      this.setState({error: true});
    }
    this.handleChange('name', name);
  };

  handleChange = (field, value) => {
    this.setState({
      [field]: value
    });
    this.props.updateFields([{field: field, value: value}]);
  };

  render() {
    const {
      investor: {name, image_url, url, crunchbase_url, dealroom_url} = {}
    } = this.props;
    const {error = false} = this.state;
    return (
      <div className="edit-basic-info">
        <div className="edit-image">
          <EditImage
            imageSrc={image_url}
            updateImageSrc={(url) => this.handleChange('image_url', url)}
          />
        </div>
        <div className="edit-info">
          <TextField
            className="edit-name edit-organization-input-field medium"
            value={name}
            onChange={this.handleNameChange}
            errorText={error ? 'Required' : false}
            name="edit-name"
          />
          <div className="social-links">
            <If condition={url}>
              <SocialLink source="website" link={url} />
            </If>
            <If condition={crunchbase_url}>
              <SocialLink source="crunchbase" link={crunchbase_url} />
            </If>
            <If condition={dealroom_url}>
              <SocialLink source="dealroom" link={dealroom_url} />
            </If>
            <SocialLink
              source="google"
              link={`https://www.google.com/search?q=${name}`}
            />
          </div>
        </div>
      </div>
    );
  }
}
