import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import _ from 'underscore';
import moment from 'moment';

import ReactHighcharts from 'react-highcharts';

import Loading from '../../loading/Loading';
import Tooltip from '../../Tooltip';

import graphConfig from '../../../content/graphConfig';

import {getDataWrapperClassNames} from '../../../lib/helpers';

import Computer from 'material-ui/svg-icons/hardware/computer';

class Analytics extends React.Component {
  static propTypes = {
    productType: PropTypes.string.isRequired,
    graphType: PropTypes.string.isRequired,
    analytics: PropTypes.object.isRequired,
    signalsLoading: PropTypes.bool.isRequired,
    snapshot: PropTypes.object,
    useSnapshot: PropTypes.bool,
    title: PropTypes.string,
    compressed: PropTypes.bool.isRequired
  };

  static defaultProps = {
    compressed: false
  };

  state = {
    showTooltip: false,
    config: {},
    lineShowing: false
  };

  componentWillMount() {
    this.getConfig();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(prevProps, this.props)) {
      this.getConfig();
    }
  }

  getConfig = () => {
    const {
      productType,
      graphType,
      analytics: {
        signals = [],
        magnitude: {highlighted: magnitudeHighlighted = false} = {},
        beta: {
          highlighted: betaHighlighted = false,
          covidFiltered: covidFiltered = false
        } = {}
      } = {},
      signalsLoading,
      snapshot: {decisionCreatedAt} = {},
      useSnapshot = false,
      compressed = false,
      organization: {ml_updated = []} = {}
    } = this.props;
    const partnerLastScore = ml_updated.filter((score_last_seen) => {
      const {user_id = ''} = score_last_seen;
      return user_id === 'roberto@inreachventures.com';
    });
    const lastSeen = partnerLastScore.length
      ? partnerLastScore[0].last_seen
      : 0;
    const config = JSON.parse(JSON.stringify(graphConfig[graphType]));
    const firstSignalTimestamp = new Date(
      ((signals || [])[0] || {}).timestamp
    ).getTime();
    const lastSignalTimestamp = new Date(
      ((signals || [])[signals.length - 1] || {}).timestamp
    ).getTime();
    const decisionTimestamp = lastSeen || null;
    const data = {
      name: productType === 'web' ? 'Web Visits' : 'App Downloads',
      data: signals.reduce((newAnalytics, {timestamp, value}) => {
        if (timestamp > 0) {
          newAnalytics.push({
            x: new Date(timestamp).getTime(),
            y: Number(value),
            color: '#cccccc'
          });
        }
        return newAnalytics;
      }, []),
      marker: {
        enabled: false
      }
    };
    if (!!covidFiltered) {
      data['zoneAxis'] = 'x';
      data['zones'] = [
        {
          value: new Date(1580515201000).getTime(),
          color: betaHighlighted ? '#5dab49' : '#444444',
          fillColor: betaHighlighted
            ? 'rgba(93, 171, 73, 0.1)'
            : 'rgba(68, 68, 68, 0.1)'
        },
        {
          color: 'rgba(68,68,68,0.5)',
          fillColor: 'rgba(68,68,68,0.05)'
        }
      ];
    } else {
      data['color'] = betaHighlighted ? '#5dab49' : '#444444';
      data['fillColor'] = betaHighlighted
        ? 'rgba(93, 171, 73, 0.1)'
        : 'rgba(68, 68, 68, 0.1)';
    }

    config.series = [data];

    if (graphType === 'spark') {
      config.xAxis.labels.formatter = function() {
        return moment(this.value).format('MMM YY');
      };
      config.yAxis.labels.formatter = function() {
        if (this.isFirst || this.isLast) {
          if (this.value >= 1e6) {
            return `${(this.value / 1000000).toFixed(2)}M`;
          }
          return `${this.value / 1000}${this.value > 0 ? 'k' : ''}`;
        }
      };
      if (firstSignalTimestamp || lastSignalTimestamp) {
        config.xAxis.tickPositions = [];
        if (firstSignalTimestamp) {
          config.xAxis.tickPositions.push(firstSignalTimestamp);
        }
        if (lastSignalTimestamp) {
          config.xAxis.tickPositions.push(lastSignalTimestamp);
        }
      }
      //if (compressed) {
      config.chart.height = 80;
      //}
    } else if (graphType === 'half' || graphType === 'full') {
      config.yAxis.labels.formatter = function() {
        if (this.value >= 1e6) {
          return `${this.value / 1000000}M`;
        } else if (this.value >= 1e3) {
          return `${this.value / 1000}k`;
        } else {
          return this.value;
        }
      };
    }
    if (magnitudeHighlighted) {
      config.yAxis.labels.style.color = '#5dab49';
      config.yAxis.labels.style.fontWeight = 'bold';
    }
    if (
      (useSnapshot && decisionTimestamp) ||
      (decisionTimestamp && decisionTimestamp < lastSignalTimestamp)
    ) {
      config.xAxis.plotLines = [
        {
          color: '#444444',
          width: 2,
          value: decisionTimestamp,
          zIndex: 5
        }
      ];
      //config.xAxis.labels.rotation = -25;
      this.setState({
        lineShowing: true
      });
      if (graphType === 'spark') {
        if (config.xAxis.tickPositions) {
          config.xAxis.tickPositions.push(decisionTimestamp);
        } else {
          config.xAxis.tickPositions = [decisionTimestamp];
        }
      }
      if (decisionTimestamp < firstSignalTimestamp) {
        const oneMonth = 1000 * 60 * 60 * 24 * 30;
        config.xAxis.min = decisionTimestamp - oneMonth;
        config.xAxis.startOnTick = false;
      } else {
        //config.xAxis.tickPositions.push()
      }
    }
    this.setState({
      config
    });
    //return config;
  };

  getTooltipText = () => {
    const {productType, useSnapshot = false, compressed = false} = this.props;
    const {lineShowing = false} = this.state;
    const text = [];
    if (compressed && productType === 'web') {
      text.push('Web Analytics');
    }
    if (useSnapshot && lineShowing) {
      text.push('The black line represents when the last decision was made');
    }
    return text.join('<br/>');
  };

  render() {
    const {
      productType,
      graphType,
      analytics: {signals = []} = {},
      signalsLoading,
      useSnapshot = false,
      title,
      compressed = false
    } = this.props;

    const {showTooltip, config, lineShowing} = this.state;
    return (
      <div
        className={getDataWrapperClassNames(compressed, ['analytics'])}
        onMouseLeave={() => {
          if (
            (useSnapshot && lineShowing) ||
            (compressed && productType === 'web')
          ) {
            this.setState({showTooltip: false});
          }
        }}
        onMouseEnter={() => {
          if (
            (useSnapshot && lineShowing) ||
            (compressed && productType === 'web')
          ) {
            this.setState({showTooltip: true});
          }
        }}
        rel={(el) => (this._parent = el)}
      >
        <If
          condition={
            (useSnapshot && lineShowing) ||
            (compressed && productType === 'web')
          }
        >
          <Tooltip
            parent={this._parent}
            showTooltip={showTooltip}
            text={this.getTooltipText()}
          />
        </If>
        <If condition={title}>
          <div className="data-header">{title}</div>
        </If>
        {/*<If condition={productType === 'web'}>
          <div className="data-icon">
            <Computer className="data-icon-svg" />
          </div>
        </If>*/}
        <Choose>
          <When condition={signalsLoading && signals.length === 0}>
            <div
              className={`data-content component graph ${
                graphType === 'spark' ? 'spark-graph' : 'full-graph'
              }`}
            >
              <Loading small={true} align="left top" />
            </div>
          </When>
          <Otherwise>
            <Choose>
              <When condition={signals.length > 0}>
                <div
                  className={`data-content component graph ${
                    graphType === 'spark' ? 'spark-graph' : 'full-graph'
                  }`}
                >
                  <ReactHighcharts config={config} />
                </div>
              </When>
              <Otherwise>
                <div className="data-content text empty">No data</div>
              </Otherwise>
            </Choose>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(Analytics);
