import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import {titleCase} from '../../../lib/helpers';

export default class PreviewInvestorName extends React.Component {
  static propTypes = {
    investor: PropTypes.object.isRequired,
    returnUrl: PropTypes.string.isRequired
  };

  render() {
    const {
      investor: {name, id, investor_type = []} = {},
      returnUrl
    } = this.props;

    const investorTypeString = !investor_type.length
      ? '(Investor)'
      : `(${investor_type.reduce((investmentTypes, investmentType) => {
          return investmentTypes.concat(
            `${investmentTypes !== '' ? ',' : ''}${titleCase(
              investmentType.replace(/_/g, ' ')
            )}`
          );
        }, '')})`;

    return (
      <React.Fragment>
        <div
          className="preview-organization--header-name"
          style={{display: 'flex'}}
        >
          <Link
            to={{
              pathname: `/investors/${id}`,
              state: {returnUrl}
            }}
          >
            {name}
          </Link>
          <div style={{color: '#5dab49', marginLeft: '8px'}}>
            {investorTypeString}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
