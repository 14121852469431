import React from 'react';
import PropTypes from 'prop-types';

export default class CompressedPeopleInsights extends React.Component {
  static propTypes = {
    highlights: PropTypes.array.isRequired,
    risks: PropTypes.array.isRequired,
    redFlags: PropTypes.array.isRequired
  };

  render() {
    const {highlights = [], risks = [], redFlags = []} = this.props;
    return (
      <Choose>
        <When
          condition={
            highlights.length === 0 &&
            risks.length === 0 &&
            redFlags.length === 0
          }
        >
          <div className="data-content text empty">No insights found</div>
        </When>
        <Otherwise>
          <div className="data-content text">
            {highlights.map(({name}, index) => (
              <React.Fragment key={name + index}>
                <span className="highlighted-text">{name}</span>
                <If
                  condition={
                    index < highlights.length - 1 ||
                    risks.length > 0 ||
                    redFlags.length > 0
                  }
                >
                  ,&nbsp;
                </If>
              </React.Fragment>
            ))}
            {risks.map(({name}, index) => (
              <React.Fragment key={name + index}>
                <span className="risk-text">{name}</span>
                <If condition={index < risks.length - 1 || redFlags.length > 0}>
                  ,&nbsp;
                </If>
              </React.Fragment>
            ))}
            {redFlags.map(({name}, index) => (
              <React.Fragment key={name + index}>
                <span className="red-flag">{name}</span>
                <If condition={index < redFlags.length - 1}>,&nbsp;</If>
              </React.Fragment>
            ))}
          </div>
        </Otherwise>
      </Choose>
    );
  }
}
