import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import Tooltip from '../../Tooltip';
import {buildSelectionLine} from '../InvestorHelper';
import _ from 'underscore';

class InreachDecisions extends React.Component {
  static propTypes = {
    companyInfo: PropTypes.object,
    portfolio: PropTypes.object,
    client: PropTypes.string.isRequired,
    updateSelectedTab: PropTypes.func.isRequired
  };

  state = {
    showTooltip: false,
    showFull: false,
    numberHiddenInfo: 0
  };

  hasError = () => {
    return true;
  };

  getDecisionsOrganization = (portfolioOrganizations) => {
    const {showMaxLength, updateSelectedTab} = this.props;
    const {numberHiddenInfo, showFull} = this.state;
    const DecisionsSizesOrganizations = portfolioOrganizations.reduce(
      (DecisionsOrganizations, investment) => {
        [
          'in_progress',
          'not_now',
          'never',
          'missed_opportunity',
          'unseen'
        ].forEach((category) => {
          const isDecisionsOrganization =
            category === 'never'
              ? this.never(investment)
              : category === 'not_now'
                ? this.notNow(investment)
                : category === 'in_progress'
                  ? this.inProgress(investment)
                  : category === 'missed_opportunity'
                    ? this.missedOpportunity(investment)
                    : this.unseen(investment);
          if (isDecisionsOrganization) {
            DecisionsOrganizations[category]['totalRaised'] += investment[
              'total_raised_usd'
            ]
              ? investment['total_raised_usd']
              : 0;
            DecisionsOrganizations[category]['moneyRaised'] += investment[
              'money_raised_usd'
            ]
              ? investment['money_raised_usd']
              : 0;
            DecisionsOrganizations[category]['count'] += 1;
            DecisionsOrganizations[category]['organizationIds'].push(
              investment['organization_id']
            );
          }
        });

        return DecisionsOrganizations;
      },
      {
        in_progress: {
          totalRaised: 0,
          moneyRaised: 0,
          count: 0,
          organizationIds: []
        },
        not_now: {
          totalRaised: 0,
          moneyRaised: 0,
          count: 0,
          organizationIds: []
        },
        never: {totalRaised: 0, moneyRaised: 0, count: 0, organizationIds: []},
        missed_opportunity: {
          totalRaised: 0,
          moneyRaised: 0,
          count: 0,
          organizationIds: []
        },
        unseen: {totalRaised: 0, moneyRaised: 0, count: 0, organizationIds: []}
      }
    );
    return (
      <React.Fragment key={'rounds'}>
        {Object.entries(DecisionsSizesOrganizations).map(
          ([seriesKey, round]) => {
            return buildSelectionLine(
              round,
              seriesKey,
              'decisions',
              updateSelectedTab,
              DecisionsSizesOrganizations
            );
          }
        )}
      </React.Fragment>
    );
  };

  never = (investment) => {
    const {latest_final_or_investor_decision: decision = {}} = investment;
    const {state = '', activity = {}} = decision;
    return state === 'never';
  };

  notNow = (investment) => {
    const {latest_final_or_investor_decision: decision = {}} = investment;
    const {state = '', activity = {}} = decision;
    return state === 'not_now';
  };
  inProgress = (investment) => {
    const {latest_final_or_investor_decision: decision = {}} = investment;
    const {state = '', activity = {}} = decision;
    return state === 'contact';
  };
  unseen = (investment) => {
    const {latest_final_or_investor_decision: decision = {}} = investment;
    const {state = '', activity = {}} = decision;
    return _.isEmpty(decision) || state === 'unseen';
  };

  missedOpportunity = (investment) => {
    const {latest_final_or_investor_decision: decision = {}} = investment;
    const {state = '', activity = {}} = decision;
    return state === 'missed_opportunity';
  };

  render() {
    const {
      tabsContent: {portfolioAggregation: portfolioAggregation = []} = {},
      portfolioAggregationLoading
    } = this.props;
    const {showTooltip} = this.state;
    return (
      <div
        className="data-wrapper founded"
        onMouseLeave={() => {
          if (this.hasError()) {
            this.setState({showTooltip: false});
          }
        }}
        onMouseEnter={() => {
          if (this.hasError()) {
            this.setState({showTooltip: true});
          }
        }}
        rel={(el) => (this._parent = el)}
      >
        <div className="data-header">InReach Decisions</div>
        <Choose>
          <When condition={portfolioAggregationLoading}>
            <div className="data-content text empty">Loading Data</div>
          </When>
          <When condition={!portfolioAggregation.length}>
            <div className="data-content text empty">No data</div>
          </When>
          <Otherwise>
            <Tooltip parent={this._parent} showTooltip={showTooltip} text="" />
            {this.getDecisionsOrganization(portfolioAggregation)}
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(InreachDecisions);
