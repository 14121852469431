import auth from './Auth';
import Config from '../config';
import request from 'superagent';

export default class OrganizationSnapshotStore {
  constructor(organizationId) {
    this.url = `${
      Config.api.host
    }/organizations/${organizationId}/snapshot/latest_final_decision`;
  }

  getOrganizationSnapshot() {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(this.url)
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .set('Accept', 'application/json')
      )
      .then((response) => response.body)
      .catch((error) => {
        if (error.response && error.response.notFound) {
          return {};
        }
        throw error;
      });
  }
}
