const CAMPAIGN_CONFIGS = {
  schedule_reconnect: {
    type: 'mailing_with_editable_campaign',
    include_reconnect: true,
    template_name: 'schedule_reconnect',
    editable_template_name: 'reconnect',
    sub_campaign: 'reconnect',
    shortLabel: 'Reconnect',
    label: 'Schedule Reconnect',
    emailLabel: 'Reconnect Email'
  },
  schedule_reconnect_operational: {
    type: 'mailing_with_editable_campaign',
    include_reconnect: true,
    template_name: 'schedule_reconnect',
    editable_template_name: 'reconnect_operational',
    sub_campaign: 'reconnect',
    shortLabel: 'Reconnect',
    label: 'Schedule Reconnect',
    emailLabel: 'Reconnect Email'
  },
  reconnect: {
    type: 'mailing_with_editable_campaign',
    include_reconnect: true,
    template_name: 'schedule_reconnect',
    editable_template_name: 'reconnect',
    sub_campaign: 'reconnect',
    shortLabel: 'Reconnect',
    label: 'Schedule Reconnect',
    emailLabel: 'Reconnect Email',
    include_previous_email: true
  },
  reconnect_passed_assignee: {
    type: 'mailing',
    template_name: 'reconnect_pass',
    shortLabel: 'Pass',
    label: 'Pass after Reconnect with Assignee',
    emailLabel: 'Pass Email',
    include_reason: true,
    include_previous_email: true
  },
  reconnect_passed_roberto: {
    type: 'mailing',
    template_name: 'reconnect_pass',
    shortLabel: 'Pass',
    label: 'Pass after Reconnect',
    emailLabel: 'Pass Email',
    include_reason: true,
    include_previous_email: true
  },
  reconnect_make_contact: {
    type: 'mailing',
    template_name: 'reconnect_make_contact',
    include_reason: false,
    emailLabel: 'Make Contact Email',
    shortLabel: 'Make Contact',
    label: 'Make Contact after Reconnect',
    include_previous_email: true
  },
  reconnect_make_contact_with_assignee: {
    type: 'mailing',
    include_reason: false,
    emailLabel: 'Make Contact Email',
    template_name: 'reconnect_make_contact_with_assignee',
    shortLabel: 'Make Contact',
    label: 'Make Contact after Reconnect with Assignee',
    include_previous_email: true
  },
  reconnect_make_contact_with_organizer: {
    type: 'mailing',
    template_name: 'reconnect_make_contact_with_organizer',
    include_reason: false,
    emailLabel: 'Reconnect Make Contact Email',
    shortLabel: 'Reconnect Make Contact',
    label: 'Make Contact after Reconnect with Organizer',
    include_previous_email: true
  },
  reconnect_make_contact_with_assignee_and_organizer: {
    type: 'mailing',
    template_name: 'reconnect_make_contact_with_assignee_and_organizer',
    include_reason: false,
    emailLabel: 'Reconnect Make Contact Email',
    shortLabel: 'Reconnect Make Contact',
    label: 'Make Contact after Reconnect with Assignee and Organizer',
    include_previous_email: true
  },
  send_form: {
    type: 'campaign',
    shortLabel: 'Send Form',
    label: 'Send Form'
  },
  make_contact: {
    type: 'mailing',
    template_name: 'make_contact',
    include_reason: false,
    emailLabel: 'Make Contact Email',
    shortLabel: 'Make Contact',
    label: 'Make Contact'
  },
  make_contact_with_assignee: {
    type: 'mailing',
    template_name: 'make_contact_with_assignee',
    include_reason: false,
    emailLabel: 'Make Contact Email',
    shortLabel: 'Make Contact',
    label: 'Make Contact with Assignee'
  },
  make_contact_with_organizer: {
    type: 'mailing',
    template_name: 'make_contact_with_organizer',
    include_reason: false,
    emailLabel: 'Make Contact Email',
    shortLabel: 'Make Contact',
    label: 'Make Contact with Organizer'
  },
  make_contact_with_assignee_and_organizer: {
    type: 'mailing',
    template_name: 'make_contact_with_assignee_and_organizer',
    include_reason: false,
    emailLabel: 'Make Contact Email',
    shortLabel: 'Make Contact',
    label: 'Make Contact with Assignee and Organizer'
  },
  interesting_make_contact: {
    type: 'mailing',
    template_name: 'interesting_make_contact',
    include_reason: false,
    emailLabel: 'Make Contact Email',
    shortLabel: 'Make Contact',
    label: 'Make Contact after Form'
  },
  interesting_make_contact_with_assignee: {
    type: 'mailing',
    template_name: 'interesting_make_contact_with_assignee',
    include_reason: false,
    emailLabel: 'Make Contact Email',
    shortLabel: 'Make Contact',
    label: 'Make Contact after Form with Assignee'
  },
  interesting_make_contact_with_organizer: {
    type: 'mailing',
    template_name: 'interesting_make_contact_with_organizer',
    include_reason: false,
    emailLabel: 'Make Contact Email',
    shortLabel: 'Make Contact',
    label: 'Make Contact after Form with Organizer'
  },
  interesting_make_contact_with_assignee_and_organizer: {
    type: 'mailing',
    template_name: 'interesting_make_contact_with_assignee_and_organizer',
    include_reason: false,
    emailLabel: 'Make Contact Email',
    shortLabel: 'Make Contact',
    label: 'Make Contact after Form with Assignee and Organizer'
  },
  interesting_partial_make_contact: {
    type: 'mailing',
    template_name: 'interesting_partial_make_contact',
    include_reason: false,
    emailLabel: 'Make Contact Email',
    shortLabel: 'Make Contact',
    label: 'Make Contact after Partial Form'
  },
  interesting_partial_make_contact_with_assignee: {
    type: 'mailing',
    template_name: 'interesting_partial_make_contact_with_assignee',
    include_reason: false,
    emailLabel: 'Make Contact Email',
    shortLabel: 'Make Contact',
    label: 'Make Contact after Partial Form with Assignee'
  },
  interesting_partial_make_contact_with_organizer: {
    type: 'mailing',
    template_name: 'interesting_partial_make_contact_with_organizer',
    include_reason: false,
    emailLabel: 'Make Contact Email',
    shortLabel: 'Make Contact',
    label: 'Make Contact after Partial Form with Organizer'
  },
  interesting_partial_make_contact_with_assignee_and_organizer: {
    type: 'mailing',
    template_name:
      'interesting_partial_make_contact_with_assignee_and_organizer',
    include_reason: false,
    emailLabel: 'Make Contact Email',
    shortLabel: 'Make Contact',
    label: 'Make Contact after Partial Form with Assignee and Organizer'
  },
  schedule_call_by_organizer: {
    type: 'campaign',
    template_name: 'schedule_call_by_organizer',
    emailLabel: 'Schedule Call',
    shortLabel: 'Schedule Call',
    label: 'Schedule call by organizer'
  },
  schedule_call: {
    type: 'campaign',
    template_name: 'schedule_call',
    emailLabel: 'Schedule Call',
    shortLabel: 'Schedule Call',
    label: 'Schedule call'
  },
  email: {
    type: 'mailing',
    shortLabel: 'Email',
    label: 'Email'
  },
  pass_after_form: {
    type: 'mailing',
    template_name: 'pass_after_form',
    include_reason: true,
    emailLabel: 'Pass Email',
    shortLabel: 'Pass',
    label: 'Pass after From'
  },
  pass_after_call: {
    type: 'mailing',
    template_name: 'pass_after_call',
    include_reason: true,
    emailLabel: 'Pass Email',
    shortLabel: 'Pass',
    label: 'Pass after Call'
  },
  pass: {
    type: 'mailing',
    template_name: 'pass',
    include_reason: true,
    emailLabel: 'Pass Email',
    shortLabel: 'Pass',
    label: 'Pass'
  },
  pre_qualification: {
    type: 'mailing',
    shortLabel: 'Pre Qualification',
    template_name: 'pre_qualification',
    label: 'Pre Qualification'
  },
  qualification: {
    type: 'mailing',
    shortLabel: 'Qualification',
    template_name: 'qualification',
    label: 'Qualification'
  },
  complete_form: {
    type: 'campaign',
    shortLabel: 'Complete Form',
    label: 'Complete Form'
  },
  unable_to_contact: {
    type: 'campaign',
    shortLabel: 'Unable to Contact',
    label: 'Unable to Contact'
  },
  backup_message: {
    type: 'campaign',
    shortLabel: 'Backup Message',
    label: 'Backup Message'
  }
};

const MAILINGS = Object.entries(CAMPAIGN_CONFIGS)
  .filter(
    ([key, campaign]) =>
      campaign.type === 'mailing' ||
      campaign.type === 'mailing_with_editable_campaign'
  )
  .map(([key, campaign]) => key);

const CAMPAIGNS = Object.entries(CAMPAIGN_CONFIGS)
  .filter(
    ([key, campaign]) =>
      campaign.type === 'campaign' ||
      campaign.type === 'mailing_with_editable_campaign' ||
      campaign.type === 'editable_campaign'
  )
  .map(([key, campaign]) => key);

const EDITABLE_CAMPAIGN = Object.entries(CAMPAIGN_CONFIGS)
  .filter(
    ([key, campaign]) =>
      campaign.type === 'editable_campaign' ||
      campaign.type === 'mailing_with_editable_campaign'
  )
  .map(([key, campaign]) => key);

module.exports = {
  campaigns: CAMPAIGNS,
  mailings: MAILINGS,
  editableCampaigns: EDITABLE_CAMPAIGN,
  campaignConfigs: CAMPAIGN_CONFIGS
};
