import React from 'react';
import OrganizationsSearch from './organization/OrganizationsSearch';
import Loading from './loading/Loading';
import auth from '../storage/Auth';

import {scorerSortKey} from '../lib/predictions';

class DiscoverRoute extends React.Component {
  state = {client: null};

  componentDidMount() {
    if (window.mixpanel) {
      mixpanel.track('Visited discover list');
    }

    auth
      .getAuthData()
      .then(({client}) => {
        this.setState({client});
      })
      .catch((err) => {
        console.log('Unable to retrieve client', err);
      });
  }

  render() {
    const {client} = this.state;

    if (!client) {
      return <Loading />;
    }

    return (
      <OrganizationsSearch
        initSearchParams={{
          based_in_continent: 'Europe',
          has_predictions: true,
          has_missing_data: false,
          is_closed: false,
          is_zombie: false,
          classification_not: 'never,missed_opportunity',
          user_state_not: 'never',
          state: 'unseen',
          experiments_not: 'automated_outbound_send_form',
          order_by: scorerSortKey
        }}
        client={client}
        location={this.props.location}
        context={this.props.context}
        route={this.props.route}
      />
    );
  }
}

export default DiscoverRoute;
