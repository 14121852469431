import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import Bio from './Bio';
import EducationExperience from './EducationExperience';

class ReducedProfile extends React.Component {
  static propTypes = {
    bio: PropTypes.string.isRequired,
    education: PropTypes.array.isRequired,
    experience: PropTypes.array.isRequired
  };

  render() {
    const {bio = '', education = [], experience = []} = this.props;
    education.length = Math.min(education.length, 1);
    experience.length = Math.min(experience.length, 2);
    return (
      <div className="full-profile-container">
        <div className="section-title">Reduced Profile</div>
        <Bio bio={bio} />
        <EducationExperience content={experience} type="experience" />
        <EducationExperience content={education} type="education" />
      </div>
    );
  }
}

export default withRouter(ReducedProfile);
