import React, {Component} from 'react';
import AggregationsStore from '../../../storage/AggregationsStore';
import MergeOrganization from './MergeOrganization';
import _ from 'underscore';
import {List, ListItem} from 'material-ui/List';

export default class MergeOrganizations extends Component {
  aggregationsStore = new AggregationsStore('organizations');

  state = {
    aggregations: {}
  };

  componentWillMount() {
    this.aggregationsStore
      .aggregations('domains_raw', 10)
      .then((body) => this.setState({aggregations: body.aggregations}));
  }

  onSuccessfulMerge = (domain, id) => {
    this.setState({
      aggregations: _.omit(this.state.aggregations, domain)
    });
  };

  render() {
    const {aggregations} = this.state;

    return (
      <List>
        {Object.entries(aggregations)
          .filter(([domain, organizations]) => {
            return (
              organizations.length > 1 &&
              organizations[0].id &&
              organizations[1].id
            );
          })
          .map(([domain, organizations]) => {
            return (
              <ListItem key={domain}>
                <MergeOrganization
                  source={organizations[0].id}
                  target={organizations[1].id}
                  handleSuccessfulMerge={(id) =>
                    this.onSuccessfulMerge(domain, id)
                  }
                />
              </ListItem>
            );
          })}
      </List>
    );
  }
}
