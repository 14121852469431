import React from 'react';
import {Link, withRouter} from 'react-router';
import PropTypes from 'prop-types';

import Loading from '../../../loading/Loading';

import TechnologyStore from '../../../../storage/TechnologyStore';

class Technology extends React.Component {
  static propTypes = {
    technology: PropTypes.object.isRequired
  };

  render() {
    const {technology: {title} = {}} = this.props;
    return <div className="data-content-list-item text">{title}</div>;
  }
}

class Technologies extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired
  };

  state = {
    highlightedTechnologies: [],
    otherTechnologies: [],
    showOtherTechnologies: false,
    technologiesLoading: true
  };

  componentDidMount() {
    const {organization: {technologies = []} = {}} = this.props;
    new TechnologyStore(technologies)
      .technologies()
      .then((technologies = []) => this.processTechnologies(technologies))
      .catch((err) => this.setState({technologiesLoading: false}));
  }

  processTechnologies = (technologies) => {
    const {highlightedTechnologies, otherTechnologies} = technologies.reduce(
      ({highlightedTechnologies, otherTechnologies}, technology, index) => {
        if (index < 5) {
          highlightedTechnologies.push(technology);
        } else {
          otherTechnologies.push(technology);
        }
        return {
          highlightedTechnologies,
          otherTechnologies
        };
      },
      {highlightedTechnologies: [], otherTechnologies: []}
    );
    this.setState({
      highlightedTechnologies,
      otherTechnologies,
      technologiesLoading: false
    });
  };

  handleToggleOtherTechnologies = () => {
    this.setState({
      showOtherTechnologies: !this.state.showOtherTechnologies
    });
  };

  render() {
    const {
      highlightedTechnologies = [],
      otherTechnologies = [],
      showOtherTechnologies,
      technologiesLoading
    } = this.state;
    return (
      <div className="data-wrapper job-openings">
        <div className="data-header">Technologies</div>
        <Choose>
          <When condition={technologiesLoading}>
            <Loading small={true} align="left" />
          </When>
          <Otherwise>
            <Choose>
              <When
                condition={
                  highlightedTechnologies.length === 0 &&
                  otherTechnologies.length === 0
                }
              >
                <div className="data-content text empty">No Data</div>
              </When>
              <Otherwise>
                <If condition={highlightedTechnologies.length > 0}>
                  <div className="data-content list">
                    {highlightedTechnologies.map((technology) => (
                      <Technology
                        technology={technology}
                        key={technology.title}
                      />
                    ))}
                  </div>
                </If>
                <If condition={otherTechnologies.length > 0}>
                  <If condition={showOtherTechnologies}>
                    <div className="data-content list">
                      {otherTechnologies.map((technology) => (
                        <Technology
                          technology={technology}
                          key={technology.title}
                        />
                      ))}
                    </div>
                  </If>
                  <div
                    className={`data-content text ${
                      showOtherTechnologies ||
                      highlightedTechnologies.length > 0
                        ? 'component'
                        : ''
                    }`}
                  >
                    <Link
                      style={{cursor: 'pointer'}}
                      onClick={this.handleToggleOtherTechnologies}
                    >
                      {showOtherTechnologies ? 'Hide more' : 'Show more'}
                    </Link>
                  </div>
                </If>
              </Otherwise>
            </Choose>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(Technologies);
