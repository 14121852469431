import React from 'react';
import PropTypes from 'prop-types';
import RaisedButton from 'material-ui/RaisedButton';
import Tooltip from '../Tooltip';

export default class RaisedButtonWithIcon extends React.Component {
  static propTypes = {
    iconURL: PropTypes.string,
    icon: PropTypes.string,
    alwaysExpanded: PropTypes.bool,
    tooltip: PropTypes.string,
    iconOnly: PropTypes.bool,
    onClick: PropTypes.func.isRequired
  };

  state = {
    showTooltip: false
  };

  render() {
    const {
        iconURL,
        icon,
        alwaysExpanded = false,
        tooltip,
        iconOnly = false,
        onClick
      } = this.props,
      {showTooltip = false} = this.state;

    return (
      <div ref={(el) => (this._button = el)} style={{position: 'relative'}}>
        <If condition={tooltip}>
          <Tooltip
            parent={this._button}
            showTooltip={showTooltip}
            text={tooltip}
          />
        </If>
        <div
          onMouseLeave={() => {
            this.setState({showTooltip: false});
          }}
          onMouseEnter={() => {
            this.setState({showTooltip: true});
          }}
          className="raised-button-with-icon"
          onClick={onClick}
        >
          <Choose>
            <When condition={iconURL}>
              <img src={iconURL} />
            </When>
            <When condition={icon}>
              <div className={`icon ${icon}`} />
            </When>
          </Choose>
          <Choose>
            <When condition={!iconOnly}>
              <RaisedButton
                className={`button${alwaysExpanded ? '' : ' reduced'}`}
                secondary={true}
                label={this.props.label}
              />
            </When>
          </Choose>
        </div>
      </div>
    );
  }
}
