const countries = {
  'afghanistan, islamic republic of': 'af',
  'ã…land islands': 'ax',
  albania: 'al',
  algeria: 'dz',
  'american samoa': 'as',
  andorra: 'ad',
  angola: 'ao',
  anguilla: 'ai',
  antarctica: 'aq',
  'antigua and barbuda': 'ag',
  argentina: 'ar',
  armenia: 'am',
  aruba: 'aw',
  australia: 'au',
  austria: 'at',
  azerbaijan: 'az',
  bahamas: 'bs',
  bahrain: 'bh',
  bangladesh: 'bd',
  barbados: 'bb',
  belarus: 'by',
  belgium: 'be',
  belize: 'bz',
  benin: 'bj',
  bermuda: 'bm',
  bhutan: 'bt',
  bolivia: 'bo',
  'bosnia and herzegovina': 'ba',
  botswana: 'bw',
  'bouvet island (bouvetoya)': 'bv',
  brazil: 'br',
  'british indian ocean territory (chagos archipelago)': 'io',
  'british virgin islands': 'vg',
  'brunei darussalam': 'bn',
  bulgaria: 'bg',
  'burkina faso': 'bf',
  burundi: 'bi',
  cambodia: 'kh',
  cameroon: 'cm',
  canada: 'ca',
  'cape verde': 'cv',
  'cayman islands': 'ky',
  'central african republic': 'cf',
  chad: 'td',
  chile: 'cl',
  china: 'cn',
  'christmas island': 'cx',
  'cocos (keeling) islands': 'cc',
  colombia: 'co',
  comoros: 'km',
  congo: 'cd',
  'cook islands': 'ck',
  'costa rica': 'cr',
  "cote d'ivoire": 'ci',
  croatia: 'hr',
  cuba: 'cu',
  cyprus: 'cy',
  'czech republic': 'cz',
  denmark: 'dk',
  djibouti: 'dj',
  dominica: 'dm',
  'dominican republic': 'do',
  ecuador: 'ec',
  egypt: 'eg',
  'el salvador': 'sv',
  'equatorial guinea': 'gq',
  eritrea: 'er',
  estonia: 'ee',
  ethiopia: 'et',
  'faroe islands': 'fo',
  'falkland islands (malvinas)': 'fk',
  fiji: 'fj',
  finland: 'fi',
  france: 'fr',
  'french guiana': 'gf',
  'french polynesia': 'pf',
  'french southern territories': 'tf',
  gabon: 'ga',
  gambia: 'gm',
  georgia: 'ge',
  germany: 'de',
  ghana: 'gh',
  gibraltar: 'gi',
  greece: 'gr',
  greenland: 'gl',
  grenada: 'gd',
  guadeloupe: 'gp',
  guam: 'gu',
  guatemala: 'gt',
  guernsey: 'gg',
  guinea: 'gn',
  'guinea-bissau': 'gw',
  guyana: 'gy',
  haiti: 'ht',
  'heard island and mcdonald islands': 'hm',
  'holy see (vatican city state)': 'va',
  honduras: 'hn',
  'hong kong': 'hk',
  hungary: 'hu',
  iceland: 'is',
  india: 'in',
  indonesia: 'id',
  iran: 'ir',
  iraq: 'iq',
  ireland: 'ie',
  'isle of man': 'im',
  israel: 'il',
  italy: 'it',
  jamaica: 'jm',
  japan: 'jp',
  jersey: 'je',
  jordan: 'jo',
  kazakhstan: 'kz',
  kenya: 'ke',
  kiribati: 'ki',
  korea: 'kr',
  kosovo: 'xk',
  kuwait: 'kw',
  'kyrgyz republic': 'kg',
  "lao people's democratic republic": 'la',
  latvia: 'lv',
  lebanon: 'lb',
  lesotho: 'ls',
  liberia: 'lr',
  'libyan arab jamahiriya': 'ly',
  liechtenstein: 'li',
  lithuania: 'lt',
  luxembourg: 'lu',
  'macao, special administrative region of china': 'mo',
  macedonia: 'mk',
  madagascar: 'mg',
  malawi: 'mw',
  malaysia: 'my',
  maldives: 'mv',
  mali: 'ml',
  malta: 'mt',
  'marshall islands': 'mh',
  martinique: 'mq',
  mauritania: 'mr',
  mauritius: 'mu',
  mayotte: 'yt',
  mexico: 'mx',
  micronesia: 'fm',
  moldova: 'md',
  monaco: 'mc',
  mongolia: 'mn',
  montenegro: 'me',
  montserrat: 'ms',
  morocco: 'ma',
  mozambique: 'mz',
  myanmar: 'mm',
  namibia: 'na',
  nauru: 'nr',
  nepal: 'np',
  'netherlands antilles': 'an',
  netherlands: 'nl',
  'new caledonia': 'nc',
  'new zealand': 'nz',
  nicaragua: 'ni',
  niger: 'ne',
  nigeria: 'ng',
  niue: 'nu',
  'norfolk island': 'nf',
  'northern mariana islands': 'mp',
  norway: 'no',
  oman: 'om',
  pakistan: 'pk',
  palau: 'pw',
  'palestinian territory': 'ps',
  panama: 'pa',
  'papua new guinea': 'pg',
  paraguay: 'py',
  peru: 'pe',
  philippines: 'ph',
  'pitcairn islands': 'pn',
  poland: 'pl',
  portugal: 'pt',
  'puerto rico': 'pr',
  qatar: 'qa',
  reunion: 're',
  romania: 'ro',
  russia: 'ru',
  'russian federation': 'ru',
  rwanda: 'rw',
  'saint barthelemy': 'bl',
  'saint helena': 'sh',
  'saint kitts and nevis': 'kn',
  'saint lucia': 'lc',
  'saint martin': 'mf',
  'saint pierre and miquelon': 'pm',
  'saint vincent and the grenadines': 'vc',
  samoa: 'ws',
  'san marino': 'sm',
  'sao tome and principe': 'st',
  'saudi arabia': 'sa',
  senegal: 'sn',
  serbia: 'rs',
  seychelles: 'sc',
  'sierra leone': 'sl',
  singapore: 'sg',
  'slovakia (slovak republic)': 'sk',
  slovenia: 'si',
  'solomon islands': 'sb',
  somalia: 'so',
  'south africa': 'za',
  'south georgia and the south sandwich islands': 'gs',
  spain: 'es',
  'sri lanka': 'lk',
  sudan: 'sd',
  suriname: 'sr',
  'svalbard & jan mayen islands': 'sj',
  swaziland: 'sz',
  sweden: 'se',
  switzerland: 'ch',
  'syrian arab republic': 'sy',
  taiwan: 'tw',
  tajikistan: 'tj',
  tanzania: 'tz',
  thailand: 'th',
  'timor-leste': 'tl',
  togo: 'tg',
  tokelau: 'tk',
  tonga: 'to',
  'trinidad and tobago': 'tt',
  tunisia: 'tn',
  turkey: 'tr',
  turkmenistan: 'tm',
  'turks and caicos islands': 'tc',
  tuvalu: 'tv',
  uganda: 'ug',
  ukraine: 'ua',
  'united arab emirates': 'ae',
  'united kingdom': 'gb',
  'united states': 'us',
  'united states of america': 'us',
  'united states minor outlying islands': 'um',
  'united states virgin islands': 'vi',
  uruguay: 'uy',
  uzbekistan: 'uz',
  vanuatu: 'vu',
  venezuela: 've',
  vietnam: 'vn',
  'wallis and futuna': 'wf',
  'western sahara': 'eh',
  yemen: 'ye',
  zambia: 'zm',
  zimbabwe: 'zw'
};

export default (country) => countries[country.toLowerCase()];
