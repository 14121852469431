import request from 'superagent';
import Config from '../config';

export default class Client {
  static getPromisedRequest(url) {
    return new Promise((resolve, reject) => {
      request.get(url).end((err, res) => {
        if (res && res.ok) {
          resolve(res.body);
        } else if (err) {
          console.error('err', err);
          reject(err);
        }
      });
    });
  }

  static getConfigForClient(client) {
    return Client.getPromisedRequest(Config.clientsConfigURL)
      .then((body) => {
        return body.clients[client];
      })
      .catch((err) => console.error(err));
  }
}
