import React from 'react';
import PropTypes from 'prop-types';

import ContentEditable from 'react-contenteditable';

import {isValid} from '../../../lib/questionInputValidation';

export default class StructuredContentValue extends React.Component {
  static propTypes = {
    editing: PropTypes.bool.isRequired,
    canEdit: PropTypes.bool.isRequired,
    structuredContent: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    change: PropTypes.func,
    confirm: PropTypes.func,
    cancel: PropTypes.func,
    edit: PropTypes.func
  };

  getStatus = () => {
    const {structuredContent: {format = ''} = {}, value = ''} = this.props;
    return value === ''
      ? 'empty'
      : !isValid(format, value)
        ? 'invalid'
        : 'valid';
  };

  getRootClassNames = () => {
    const {editing = false, canEdit = false} = this.props;
    const classNames = ['structured-content-value--root'];
    switch (this.getStatus()) {
      case 'empty':
        classNames.push('structured-content-value--empty');
        break;
      case 'invalid':
        classNames.push('structured-content-value--invalid');
        break;
      case 'valid':
        classNames.push('structured-content-value--valid');
        break;
    }
    if (editing) {
      classNames.push('structured-content-value--editing');
    }
    if (canEdit) {
      classNames.push('structured-content-value--can-edit');
    }
    return classNames.join(' ');
  };

  handleChange = ({target: {value = ''}}) => {
    const {structuredContent: {key = ''} = {}, id, change} = this.props;
    change(id, key, value);
  };

  handleKeyDown = (event) => {
    const {value = '', confirm, cancel} = this.props;
    switch (event.key) {
      case 'Backspace':
        // backspace
        if (value === '') {
          event.preventDefault();
          cancel();
        }
        break;
      case 'Escape':
        event.preventDefault();
        cancel();
        break;
      case 'Enter':
        // enter
        event.preventDefault();
        /*if (value === '') {
          cancel();
        } else {
          confirm();
        }*/
        confirm();
        break;
    }
  };

  handleBlur = () => {
    const {value = '', confirm, cancel} = this.props;
    /*if (value === '') {
      cancel();
    } else {
      confirm();
    }*/
    confirm();
  };

  handlePaste = (event) => {
    event.preventDefault();
    document.execCommand(
      'insertHTML',
      false,
      event.clipboardData.getData('text/plain')
    );
  };

  render() {
    const {
      editing = false,
      canEdit = false,
      structuredContent: {question = '*Question modified*', key} = {},
      id = '',
      value = '',
      edit
    } = this.props;
    return (
      <div className={this.getRootClassNames()} data-structured-id={id}>
        <div className="structured-content-value--content">{question}:</div>
        <Choose>
          <When condition={editing}>
            <ContentEditable
              className="structured-content-value--edit-value"
              id="structured-content-value-input-field"
              type="text"
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
              onBlur={this.handleBlur}
              onPaste={this.handlePaste}
              html={value}
            />
          </When>
          <Otherwise>
            <div
              className="structured-content-value--display-value"
              onClick={() => {
                if (canEdit) {
                  edit(id, key, value);
                }
              }}
            >
              {value}
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}
