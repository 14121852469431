import React, {Component} from 'react';
import Chip from 'material-ui/Chip';
import {
  greenA400,
  red500,
  grey300,
  orange200,
  lightBlueA200
} from 'material-ui/styles/colors';

export default function RunningStatus({progress, schedule}) {
  return (
    <Choose>
      <When condition={progress.stopped}>
        <Chip backgroundColor={red500}>STOPPED</Chip>
      </When>
      <When condition={progress.timestamp && !progress.finished}>
        <Choose>
          <When
            condition={
              Date.parse(progress.timestamp) + 1000 * 60 * 60 < Date.now()
            }
          >
            <Chip backgroundColor={orange200}>STALLED</Chip>
          </When>
          <Otherwise>
            <Chip backgroundColor={greenA400}>RUNNING</Chip>
          </Otherwise>
        </Choose>
      </When>
      <When condition={progress.finished && !progress.timestamp}>
        <Chip backgroundColor={lightBlueA200}>FINISHED</Chip>
      </When>
      <When condition={!!schedule.period_millis}>
        <Chip backgroundColor={lightBlueA200}>SCHEDULED</Chip>
      </When>
      <Otherwise>
        <Chip backgroundColor={grey300}>IDLE</Chip>
      </Otherwise>
    </Choose>
  );
}
