import React from 'react';
import {Link, withRouter} from 'react-router';
import PropTypes from 'prop-types';

import _ from 'underscore';

import placeholder from '../../../../../../images/person-placeholder.jpg';

import {capitalizeString, getLinkedInUrl} from '../../../../../lib/helpers';

import ImageEdit from 'material-ui/svg-icons/image/edit';

import SocialLink from '../../../SocialLink';
import Email from './Email';
import imageUrl from '../../../../../lib/imageUrl';

class BasicInfo extends React.Component {
  static propTypes = {
    info: PropTypes.object.isRequired,
    editPerson: PropTypes.func
  };

  getAvatarClassNames = () => {
    const {
      info: {isPrimaryContact}
    } = this.props;
    const classNames = ['user-avatar'];
    if (isPrimaryContact) {
      classNames.push('primary');
    }
    return classNames.join(' ');
  };

  getRoleClassNames = () => {
    const {
      info: {role = ''}
    } = this.props;
    const classNames = [];
    if (role === '') {
      classNames.push('de-emphasised-text');
    }
    if (role.indexOf('founder') > -1) {
      classNames.push('bold-text');
    }
    return classNames;
  };

  getTitleClassNames = () => {
    const {
      info: {title = ''}
    } = this.props;
    const classNames = [];
    if (title === '') {
      classNames.push('de-emphasised-text');
    }
    if (
      ['founder', 'ceo', 'cto', 'cfo', 'coo', 'cpo'].filter(
        (arrayTitle) => title.toLowerCase().indexOf(arrayTitle) > -1
      ).length > 0
    ) {
      classNames.push('bold-text');
    }
    return classNames;
  };

  render() {
    const {
      info: {
        isPrimaryContact,
        id,
        rank,
        name,
        email,
        imageUrl: imageUrlString,
        linkedInUrl,
        websiteUrl,
        twitterUrl,
        angellistUrl,
        facebookUrl,
        githubUrl,
        role = '',
        title = '',
        isHighlighted
      },
      editPerson
    } = this.props;
    return (
      <div className="basic-info">
        <div
          className={`grid-container basic-info-grid-container${
            isPrimaryContact ? ' primary' : ''
          }`}
        >
          <div className="basic-info-grid-item image-grid-item">
            <div
              className={this.getAvatarClassNames()}
              style={{
                backgroundImage: `url('${imageUrl({
                  url: imageUrlString
                })}'), url('${placeholder}')`
              }}
            />
          </div>
          <div className="basic-info-grid-item name-links-grid-item">
            <div className="top-row">
              <div className="name-container">
                <Link to={`/people/${id}`}>{name}</Link> (
                {/*<If condition={isPrimaryContact}>
                  <span className="highlighted-text">Primary Contact</span>
                  <span>, </span>
                </If>*/}
                <span className={this.getRoleClassNames()}>
                  {role === '' ? 'No Role' : `${capitalizeString(role)}`}
                </span>
                ,
                <span className={this.getTitleClassNames()}>
                  {title === '' ? ' No Title' : ` ${capitalizeString(title)}`}
                </span>
                )
              </div>
              <If condition={editPerson !== null}>
                <Link
                  onClick={() => editPerson(id)}
                  className="edit-person-icon-container"
                >
                  <ImageEdit className="edit-person-icon" color="#5DAB49" />
                </Link>
              </If>
            </div>
            <div className="links-container">
              <SocialLink
                source="linkedin"
                link={getLinkedInUrl(linkedInUrl)}
              />
              <SocialLink source="website" link={websiteUrl} />
              <SocialLink source="twitter" link={twitterUrl} />
              <SocialLink source="angellist" link={angellistUrl} />
              <SocialLink source="facebook" link={facebookUrl} />
              <SocialLink source="github" link={githubUrl} />
            </div>
          </div>
          {/*<div className="basic-info-grid-item email-grid-item">
            <Email email={email} />
          </div>*/}
        </div>
      </div>
    );
  }
}

export default withRouter(BasicInfo);
