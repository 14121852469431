import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import moment from 'moment';

import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from 'material-ui/Table';

import Money from '../../../Money';
import Loading from '../../../loading/Loading';
import {capitalizeString} from '../../../../lib/helpers';

class FundingRoundsTable extends React.Component {
  static propTypes = {
    fundingRounds: PropTypes.array.isRequired,
    fundingRoundsLoading: PropTypes.bool.isRequired,
    investors: PropTypes.array.isRequired,
    investorsLoading: PropTypes.bool.isRequired
  };

  formatInvestors = ({participations = []}) => {
    const {investors} = this.props;
    if (participations.length === 0) {
      return '—';
    }
    return participations
      .sort(function(a, b) {
        return (!!b.is_lead || false) - (!!a.is_lead || false);
      })
      .map(({investor_id, is_lead = false}, index) => {
        const investor =
          investors.find((investor) => investor.id === investor_id) || null;
        if (investor !== null) {
          return (
            <span className={is_lead ? 'lead-investor' : ''} key={investor_id}>
              {!!index && ', '} {investor.name}
            </span>
          );
        }
      });
  };

  formatAnnouncedOn = ({announced_on = null}) => {
    if (announced_on === null) {
      return '—';
    }
    return moment(announced_on).format('dddd Do MMMM YYYY');
  };

  formatFundingType = ({funding_type = null, series = null}) => {
    if (funding_type === null) {
      return '—';
    }
    return funding_type === 'venture' && series !== null
      ? `Series ${series}`
      : funding_type.replace(/_/g, ' ');
  };

  formatMoneyRaisedUsd = ({money_raised_usd = null}) => {
    if (money_raised_usd === null) {
      return '—';
    }
    return <Money money={money_raised_usd} />;
  };

  render() {
    const {
      fundingRounds,
      fundingRoundsLoading,
      investors,
      investorsLoading
    } = this.props;
    return (
      <div className="data-wrapper funding-rounds-table-component">
        <div className="data-header">Funding Rounds</div>
        <Choose>
          <When condition={fundingRoundsLoading || investorsLoading}>
            <Loading small={true} align="left" />
          </When>
          <Otherwise>
            <Choose>
              <When
                condition={!fundingRoundsLoading && fundingRounds.length === 0}
              >
                <div className="data-content text empty">
                  There were no funding rounds recorded
                </div>
              </When>
              <Otherwise>
                <div className="data-content component">
                  <Table
                    className="funding-table funding-rounds-table"
                    selectable={false}
                  >
                    <TableHeader
                      adjustForCheckbox={false}
                      displaySelectAll={false}
                      enableSelectAll={false}
                      className="funding-table-header funding-rounds-table-header"
                    >
                      <TableRow className="funding-table-row funding-rounds-table-row">
                        <TableHeaderColumn className="funding-table-column funding-rounds-table-column">
                          Announced On
                        </TableHeaderColumn>
                        <TableHeaderColumn className="funding-table-column funding-rounds-table-column">
                          Funding Type
                        </TableHeaderColumn>
                        <TableHeaderColumn className="funding-table-column funding-rounds-table-column">
                          Money Raised
                        </TableHeaderColumn>
                        <TableHeaderColumn className="funding-table-column funding-rounds-table-column investors">
                          Investors
                        </TableHeaderColumn>
                      </TableRow>
                    </TableHeader>
                    <TableBody
                      displayRowCheckbox={false}
                      selectable={false}
                      stripedRows={false}
                      showRowHover={false}
                      className="funding-table-body funding-rounds-table-body"
                    >
                      {fundingRounds.map((fundingRound) => (
                        <TableRow
                          key={fundingRound.id}
                          className="funding-table-row funding-rounds-table-row"
                        >
                          <TableRowColumn className="funding-table-column funding-rounds-table-column">
                            {this.formatAnnouncedOn(fundingRound)}
                          </TableRowColumn>
                          <TableRowColumn className="funding-table-column funding-rounds-table-column">
                            {capitalizeString(
                              this.formatFundingType(fundingRound)
                            )}
                          </TableRowColumn>
                          <TableRowColumn className="funding-table-column funding-rounds-table-column">
                            {this.formatMoneyRaisedUsd(fundingRound)}
                          </TableRowColumn>
                          <TableRowColumn className="funding-table-column funding-rounds-table-column investors">
                            {this.formatInvestors(fundingRound)}
                          </TableRowColumn>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </Otherwise>
            </Choose>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(FundingRoundsTable);
