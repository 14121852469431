import React from 'react';
import {Link, withRouter} from 'react-router';
import PropTypes from 'prop-types';
import _ from 'underscore';
import IconButton from 'material-ui/IconButton';
import ActionDelete from 'material-ui/svg-icons/action/delete';
import NavigationClose from 'material-ui/svg-icons/navigation/close';
import AppBar from 'material-ui/AppBar';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import {scorerSortKey} from '../../../lib/predictions';
import Paper from 'material-ui/Paper';
import TextField from 'material-ui/TextField';
import LOCATIONS from '../../../content/locations';
import CountriesFilter from '../../organization/CountriesFilter';
import DatePicker from 'material-ui/DatePicker';
import moment from 'moment';
import MLTagsFilter from '../../organization/MLTagsFilter';
import investmentTypesMap from '../../../content/investmentTypes';
import investorsCategories from '../../../content/investorsCategories';
import investorMLCategories from '../../../content/investorsMLCategories';
import investorScores from '../../../content/investorScores';
import InReachToggle from '../../InReachToggle';

const DEFAULT_FILTER_PARAMS = {
  based_in_continent: 'Europe'
};

function toMoment(epoch) {
  return epoch ? moment(epoch)._d : null;
}

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

class InvestorFilterSet extends React.Component {
  static propTypes = {
    searchParams: PropTypes.object
  };
  state = {};

  render() {
    const {
      searchParams,
      handleClearFilter,
      handleCloseFilter,
      orderByValue,
      mostInvestedTags,
      mostInvestedTypes,
      investorType,
      investorHigherScore,
      investorLowerScore,
      handleShowOnlyGoodInvestor,
      handleShowOnlyTopTier,
      handleMostInvestedTagsChange,
      handleMostInvestedTagsRemove,
      handleMostInvestedTypeChange,
      handleInvestorTypeChange,
      handleInvestorScoreHigherChange,
      handleInvestorScoreLowerChange,
      handleOrderChange,
      handleOrderByChange,
      handleInvestmentsLessThanChange,
      handleInvestmentsGreaterThanChange,
      handleContinentChange,
      handleCountriesChange,
      handleCityChange,
      handleExitsGreaterThanChange,
      handleExitsLessThanChange,
      handleFirstInvestmentAfterChange,
      handleFirstInvestmentBeforeChange,
      handleLastInvestmentAfterChange,
      handleLastInvestmentBeforeChange
    } = this.props;

    return (
      <div className="filter-menu">
        <AppBar
          className="appBar secondary"
          title="Filters"
          iconElementLeft={
            <div>
              <If condition={!_.isEqual(searchParams, DEFAULT_FILTER_PARAMS)}>
                <IconButton
                  iconStyle={{color: 'white'}}
                  onClick={handleClearFilter}
                >
                  <ActionDelete style={{color: 'white'}} />
                </IconButton>
              </If>
              <If condition={_.isEqual(searchParams, DEFAULT_FILTER_PARAMS)}>
                <div />
              </If>
            </div>
          }
          iconElementRight={
            <IconButton className="close-filter" onClick={handleCloseFilter}>
              <NavigationClose />
            </IconButton>
          }
        />
        <div className="filter-container">
          <Paper zDepth={1} className="paper">
            <div className="filter-section">
              <div className="title">
                <div className="sliders" />
                <h5> Ordering </h5>
              </div>
              <div className="filter-data" style={{display: 'flex'}}>
                <SelectField
                  floatingLabelText="Sort by"
                  floatingLabelStyle={{color: '#505050'}}
                  className="select sort-by"
                  value={orderByValue}
                  onChange={handleOrderByChange}
                >
                  <MenuItem
                    value="aggregations.organization_ids_count"
                    primaryText="Number Of Investments"
                  />
                  <MenuItem
                    value="ml_investor_score"
                    primaryText="Investor Score"
                  />
                  <MenuItem
                    value="aggregations.first_funding_round_announced_on"
                    primaryText="First investment date"
                  />
                  <MenuItem
                    value="aggregations.last_funding_round_announced_on"
                    primaryText="Last Investment date"
                  />
                  <MenuItem value="aggregations.exits" primaryText="Exits" />
                  {/*<MenuItem*/}
                  {/*  value="aggregations.organization_ids"*/}
                  {/*  primaryText="Number of Organizations"*/}
                  {/*/>*/}
                </SelectField>
                <div className={'spacer'} />
                <SelectField
                  floatingLabelText="Order"
                  floatingLabelStyle={{color: '#505050'}}
                  className="select"
                  value={searchParams.order || 'desc'}
                  onChange={handleOrderChange}
                >
                  <MenuItem value="desc" primaryText="Descending" />
                  <MenuItem value="asc" primaryText="Ascending" />
                </SelectField>
              </div>
            </div>
          </Paper>
          <Paper zDepth={1} className="paper">
            <div className="filter-section">
              <div className="title">
                <div className="place-icon" />
                <h5> Location </h5>
              </div>
              <div className="filter-data">
                <SelectField
                  floatingLabelText={'Continent'}
                  floatingLabelStyle={{color: '#505050'}}
                  className="select"
                  value={searchParams.based_in_continent || 'any'}
                  onChange={handleContinentChange}
                >
                  <MenuItem
                    value={'any'}
                    primaryText="Any Continent"
                    disabled={true}
                  />
                  {LOCATIONS.continents.map((continent) => {
                    return (
                      <MenuItem
                        key={continent}
                        value={continent}
                        primaryText={continent}
                      />
                    );
                  })}
                </SelectField>
              </div>
              <If condition={searchParams.based_in_continent}>
                <div className="filter-data">
                  <CountriesFilter
                    continent={searchParams.based_in_continent}
                    countries={searchParams.based_in_country}
                    filterType={'investor'}
                    handleCountriesChange={handleCountriesChange}
                  />
                </div>
              </If>
              <If
                condition={
                  searchParams.based_in_country &&
                  searchParams.based_in_country.split(',').length === 1
                }
              >
                <div className="filter-data">
                  <label> City </label>
                  <TextField
                    className="input"
                    id="based_in_city"
                    hintText="Type the city here..."
                    underlineFocusStyle={{borderColor: '#337ab7'}}
                    defaultValue={searchParams.based_in_city}
                    onChange={handleCityChange}
                  />
                </div>
              </If>
            </div>
          </Paper>
          <Paper zDepth={1} className="paper">
            <div className="filter-section">
              <div className="title">
                <div className="money" />
                <h5> Investor </h5>
              </div>
              <div className="filter-data" style={{display: 'flex'}}>
                <label> Show Only Top Tier </label>
                <div className="toggle">
                  <InReachToggle
                    toggled={!!searchParams.top_tier}
                    onToggle={(e, toggled) => {
                      handleShowOnlyTopTier(toggled);
                    }}
                  />
                </div>
                <label> Show Only Good Investors </label>
                <div className="toggle">
                  <InReachToggle
                    toggled={!!searchParams.is_good_investor}
                    onToggle={(e, toggled) => {
                      handleShowOnlyGoodInvestor(toggled);
                    }}
                  />
                </div>
              </div>
              <div className="spacer" />
              <div className="filter-data" style={{display: 'flex'}}>
                <SelectField
                  floatingLabelText="CrunchBase Type"
                  floatingLabelStyle={{color: '#505050'}}
                  className="select"
                  multiple={true}
                  value={investorType || 'any'}
                  onChange={handleInvestorTypeChange}
                >
                  <MenuItem
                    value={'any'}
                    primaryText="Any Investor Type"
                    disabled={true}
                  />
                  {Object.keys(investorsCategories).map((key) => (
                    <MenuItem
                      key={key}
                      insetChildren={true}
                      checked={investorType && investorType.indexOf(key) > -1}
                      value={key}
                      primaryText={investorsCategories[key].name}
                    />
                  ))}
                </SelectField>
              </div>
              <div className="filter-data" style={{display: 'flex'}}>
                <SelectField
                  floatingLabelText="Score >= than"
                  floatingLabelStyle={{color: '#505050'}}
                  className="select"
                  value={investorHigherScore || 'any'}
                  onChange={handleInvestorScoreHigherChange}
                >
                  <MenuItem
                    value={'any'}
                    primaryText="Any Investor Score"
                    disabled={true}
                  />
                  {Object.keys(investorScores).map((key) => (
                    <MenuItem
                      key={key}
                      value={key}
                      primaryText={investorScores[key].name}
                    />
                  ))}
                </SelectField>
                <div className="spacer" />
                <SelectField
                  floatingLabelText="Score <= than"
                  floatingLabelStyle={{color: '#505050'}}
                  className="select"
                  value={investorLowerScore || 'any'}
                  onChange={handleInvestorScoreLowerChange}
                >
                  <MenuItem
                    value={'any'}
                    primaryText="Any Investor Score"
                    disabled={true}
                  />
                  {Object.keys(investorScores).map((key) => (
                    <MenuItem
                      key={key}
                      value={key}
                      primaryText={investorScores[key].name}
                    />
                  ))}
                </SelectField>
              </div>
            </div>
          </Paper>
          <Paper zDepth={1} className="paper">
            <div className="filter-section">
              <div className="title">
                <div className="trends-percentile" />
                <h5> Size </h5>
              </div>
              <div className="filter-data">
                <label> Number Of Companies </label>
                <div>
                  <TextField
                    className="input"
                    hintText="# greater than..."
                    underlineFocusStyle={{borderColor: '#337ab7'}}
                    defaultValue={searchParams.organization_ids_gt}
                    onChange={handleInvestmentsGreaterThanChange}
                  />
                  <TextField
                    className="input"
                    hintText="# less than..."
                    underlineFocusStyle={{borderColor: '#337ab7'}}
                    defaultValue={searchParams.organization_ids_lt}
                    onChange={handleInvestmentsLessThanChange}
                  />
                </div>
                <label> Number Of Exits </label>
                <div>
                  <TextField
                    className="input"
                    hintText="# greater than..."
                    underlineFocusStyle={{borderColor: '#337ab7'}}
                    defaultValue={searchParams.exits_gt}
                    onChange={handleExitsGreaterThanChange}
                  />
                  <TextField
                    className="input"
                    hintText="# less than..."
                    underlineFocusStyle={{borderColor: '#337ab7'}}
                    defaultValue={searchParams.exits_lt}
                    onChange={handleExitsLessThanChange}
                  />
                </div>
              </div>
            </div>
          </Paper>
          <Paper zDepth={1} className="paper">
            <div className="filter-section">
              <div className="title">
                <div className="tag-icon" />
                <h5> Type </h5>
              </div>
              <div className="filter-data">
                <SelectField
                  floatingLabelText="Most Invested Type"
                  floatingLabelStyle={{color: '#505050'}}
                  multiple={true}
                  className="select"
                  value={mostInvestedTypes || 'any'}
                  onChange={handleMostInvestedTypeChange}
                >
                  <MenuItem
                    value={'any'}
                    primaryText="Any Type"
                    disabled={true}
                  />
                  {Object.keys(investmentTypesMap).map((key) => (
                    <MenuItem
                      key={key}
                      insetChildren={true}
                      checked={
                        mostInvestedTypes && mostInvestedTypes.indexOf(key) > -1
                      }
                      value={key}
                      primaryText={investmentTypesMap[key].name}
                    />
                  ))}
                </SelectField>
                <div className="spacer" />
                <MLTagsFilter
                  selected={mostInvestedTags}
                  onSelectTag={handleMostInvestedTagsChange}
                  removeTag={handleMostInvestedTagsRemove}
                />
              </div>
            </div>
          </Paper>

          <Paper zDepth={1} className="paper">
            <div className="filter-section">
              <div className="title">
                <div className="calendar" />
                <h5> Dates </h5>
              </div>
              <div className="filter-data">
                <label> First Investment </label>
                <div className="date-container">
                  <DatePicker
                    className="date-picker"
                    hintText="FROM"
                    value={toMoment(
                      searchParams.first_funding_round_announced_on_gt
                    )}
                    onChange={handleFirstInvestmentAfterChange}
                    autoOk={true}
                  />
                  <If
                    condition={searchParams.first_funding_round_announced_on_gt}
                  >
                    <IconButton
                      onClick={() => {
                        handleFirstInvestmentAfterChange(null, null);
                      }}
                    >
                      <NavigationClose />
                    </IconButton>
                  </If>
                  <DatePicker
                    className="date-picker"
                    hintText="TO"
                    value={toMoment(
                      searchParams.first_funding_round_announced_on_lt
                    )}
                    onChange={handleFirstInvestmentBeforeChange}
                    autoOk={true}
                  />
                  <If
                    condition={searchParams.first_funding_round_announced_on_lt}
                  >
                    <IconButton
                      onClick={() => {
                        handleFirstInvestmentBeforeChange(null, null);
                      }}
                    >
                      <NavigationClose />
                    </IconButton>
                  </If>
                </div>
              </div>
              <div className="filter-data">
                <label> Last Investment </label>
                <div className="date-container">
                  <DatePicker
                    className="date-picker"
                    hintText="FROM"
                    value={toMoment(
                      searchParams.last_funding_round_announced_on_gt
                    )}
                    onChange={handleLastInvestmentAfterChange}
                    autoOk={true}
                  />
                  <If
                    condition={searchParams.last_funding_round_announced_on_gt}
                  >
                    <IconButton
                      onClick={() => {
                        handleLastInvestmentAfterChange(null, null);
                      }}
                    >
                      <NavigationClose />
                    </IconButton>
                  </If>
                  <DatePicker
                    className="date-picker"
                    hintText="TO"
                    value={toMoment(
                      searchParams.last_funding_round_announced_on_lt
                    )}
                    onChange={handleLastInvestmentBeforeChange}
                    autoOk={true}
                  />
                  <If
                    condition={searchParams.last_funding_round_announced_on_lt}
                  >
                    <IconButton
                      onClick={() => {
                        handleLastInvestmentBeforeChange(null, null);
                      }}
                    >
                      <NavigationClose />
                    </IconButton>
                  </If>
                </div>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}

export default withRouter(InvestorFilterSet);
