import React from 'react';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import AutoComplete from 'material-ui/AutoComplete';
import FlatButton from 'material-ui/FlatButton';
import MenuItem from 'material-ui/MenuItem';
import Avatar from 'material-ui/Avatar';
import AddCircleOutline from 'material-ui/svg-icons/content/add-circle';

class AddInfoFromLinkedIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAddDialog: false,
      searchText: '',
      members: [],
      newMember: null
    };

    this.handleOpenAddDialog = this.handleOpenAddDialog.bind(this);
    this.handleCloseAddDialog = this.handleCloseAddDialog.bind(this);
    this.handleNewRequest = this.handleNewRequest.bind(this);
    this.handleUpdateInput = this.handleUpdateInput.bind(this);
  }

  handleOpenAddDialog() {
    this.setState({
      openAddDialog: true
    });
  }

  handleCloseAddDialog() {
    this.setState({
      openAddDialog: false,
      searchText: '',
      members: [],
      newMember: null
    });
  }

  convertMemberToPerson(member) {
    if (!member) {
      return {};
    }
    const convertExperience = (experience) => {
        if (experience) {
          const experienceObject = {};
          experience.forEach((job) => {
            const id = `${job.title}-${job.company_name}`;
            if (!experienceObject[id]) {
              experienceObject[id] = {
                title: job.title,
                start: job.date_from,
                end: job.date_to,
                duration: job.duration,
                company: job.company_name
              };
            }
          });
          return Object.keys(experienceObject).map(
            (jobId) => experienceObject[jobId]
          );
        }
      },
      cleanBio = (bio) => {
        const div = document.createElement('div');
        div.innerHTML = bio;
        return div.textContent || div.innerText || '';
      };

    return {
      experience: convertExperience(member.experience),
      name: member.name,
      image_url: member.logo_url,
      bio: cleanBio(member.summary),
      title: member.title
    };
  }

  handleNewRequest(request) {
    this.setState({
      searchText: request.text,
      newMember: this.convertMemberToPerson(request.data)
    });
  }

  handleUpdateInput(searchText) {
    this.setState({
      searchText
    });

    this.props.getPersonFromLinkedinURL(searchText).then((members) => {
      const dataSource = members.map((member) => {
        const text = `${member.name} - ${member.title}`;
        return {
          text,
          id: member.id,
          data: member,
          value: (
            <MenuItem
              className="member-entry"
              primaryText={text}
              secondaryText={
                <Avatar
                  style={{
                    marginTop: '10px',
                    alignSelf: 'center'
                  }}
                  src={member.logo_url}
                  size={25}
                />
              }
            />
          )
        };
      });

      this.setState({
        members: dataSource
      });
    });
  }

  render() {
    const actions = [
      <RaisedButton
        className="assign-action"
        label="Cancel"
        style={{
          marginRight: '10px'
        }}
        onClick={this.handleCloseAddDialog}
      />,
      <RaisedButton
        children={<span style={{color: 'white'}}>CONFIRM</span>}
        className="assign-action add-info-confirm"
        backgroundColor="#5DAB49"
        disabled={!this.state.newMember}
        labelStyle={{
          color: 'white'
        }}
        onClick={() => {
          this.props.onAddInfo(this.state.newMember);
          this.handleCloseAddDialog();
        }}
      />
    ];

    return (
      <div className="add-info-from-linkedin">
        <FlatButton
          className="add-info"
          label="Add Info from LinkedIn URL"
          onClick={this.handleOpenAddDialog}
          icon={<AddCircleOutline color={'#5DAB49'} />}
        />
        <Dialog
          title="Add Info from LinkedIn URL"
          actions={actions}
          modal={false}
          open={this.state.openAddDialog}
          onRequestClose={this.handleCloseAddDialog}
        >
          <AutoComplete
            fullWidth={true}
            name="autocomplete-info"
            className="autocomplete-info"
            hintText="Type LinkedIn URL"
            searchText={this.state.searchText}
            onUpdateInput={this.handleUpdateInput}
            onNewRequest={this.handleNewRequest}
            filter={AutoComplete.noFilter}
            dataSource={this.state.members}
            openOnFocus={true}
          />
        </Dialog>
      </div>
    );
  }
}

export default AddInfoFromLinkedIn;
