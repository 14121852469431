import {campaignConfigs} from '../config/campaigns';
import Organizations from './Organizations';
import _ from 'underscore';

export default class AssignCampaigns {
  static processError(error) {
    console.log('Campaign error', error);
    const {response: {body = {}} = {}} = error;
    if (body.message) {
      return new Error(`There has been an error: ${body.message}`);
    }
    const {errors: [{title, detail = ''}] = []} = body;

    if (title) {
      return new Error(`
          There has been an error but your request has been saved.
          Please send this organization and a screenshot to Ben\n
          ${title}: ${detail}
      `);
    }
    return error;
  }

  static processHtmlText(text = '') {
    const body = text.replace(new RegExp('<p><br></p>', 'g'), '');
    return body.replace(new RegExp('</p>', 'g'), '</p>');
  }

  static processCampaignWithNoDecision(
    campaignSelected,
    campaignConfiguration,
    reconnectAssignedTo
  ) {
    const {
      mailingSubject,
      mailingBody,
      campaignEnabled = true,
      campaignTo,
      editableCampaignBody,
      editableCampaignSubject,
      templatesChanged,
      when,
      reconnectReason,
      mailingEnabled = true,
      calendlyObject = null,
      createdStructuredReasons = {}
    } = campaignConfiguration;
    const {
      [campaignSelected]: {type = '', template_name = ''} = {}
    } = campaignConfigs;

    const assignCampaign = {};

    if (campaignTo) {
      assignCampaign.primary_contact_id = campaignTo;
    }

    if (campaignEnabled && mailingEnabled && (mailingSubject || mailingBody)) {
      assignCampaign['mailing'] = {
        template_name: template_name,
        subject: mailingSubject,
        body_html: this.processHtmlText(mailingBody || '')
      };
    }
    if (campaignEnabled && type.indexOf('campaign') > -1) {
      assignCampaign.sequence_name = campaignSelected;
    }
    if (templatesChanged) {
      assignCampaign.customized_sequence = templatesChanged;
    }
    if (when && reconnectReason) {
      assignCampaign.reconnect = {
        when: when.getTime(),
        reason: reconnectReason,
        type:
          campaignSelected === 'schedule_reconnect_operational'
            ? 'OPERATIONAL'
            : 'INVESTOR',
        person_id: campaignTo,
        assigned_to: reconnectAssignedTo,
        reasons: createdStructuredReasons
      };
    }
    if (campaignEnabled && editableCampaignBody) {
      assignCampaign.email_body_text = this.processHtmlText(
        editableCampaignBody
      );
    }
    if (campaignEnabled && editableCampaignSubject) {
      assignCampaign.email_subject = editableCampaignSubject;
    }
    if (campaignSelected.includes('schedule_call') && calendlyObject) {
      assignCampaign.schedule_call_times = calendlyObject;
    }

    return assignCampaign;
  }

  static processCampaign(
    campaignSelected,
    campaignConfiguration,
    existingDecision,
    from,
    assignedTo,
    organizer,
    state,
    stage,
    form
  ) {
    const {
      passReason = null,
      createdStructuredReasons = null
    } = campaignConfiguration;
    if (!existingDecision.client) {
      throw new Error('Decision does not have client set');
    }
    if (!existingDecision.organization_id) {
      throw new Error('Decision does not have organization_id set');
    }

    const decision = Object.assign(_.clone(existingDecision), {
      organizer,
      assigned_to: assignedTo
    });
    if (state) {
      decision.state = state;
    }
    if (stage) {
      decision.stage = stage;
    }
    if (passReason) {
      decision.notes = passReason;
    }
    if (createdStructuredReasons !== null) {
      decision.reasons = createdStructuredReasons;
    }
    if (form !== undefined) {
      decision.form = form;
    }

    const assignCampaign = this.processCampaignWithNoDecision(
      campaignSelected,
      campaignConfiguration,
      assignedTo
    );
    assignCampaign.decision = decision;
    if (from && from !== 'none') {
      assignCampaign.authorized_user = {
        user_id: from,
        client: decision.client
      };
    }

    return assignCampaign;
  }
}
