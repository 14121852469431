import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';

import IconMenu from 'material-ui/IconMenu';
import IconButton from 'material-ui/IconButton/IconButton';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import MenuItem from 'material-ui/MenuItem';

const MenuItems = {
  preview: ['edit', 'report'],
  profile: [
    'share',
    'edit',
    'merge',
    'change_stage',
    'pass',
    'delete_signals',
    'requires_manual_help',
    'urgent',
    'report',
    'qualification_checklist'
  ]
};

export default class OrganizationMenu extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    userHasFullAccess: PropTypes.bool.isRequired,
    decision: PropTypes.object.isRequired,
    userRoles: PropTypes.array.isRequired,
    deleteSignals: PropTypes.func,
    toggleManualHelp: PropTypes.func,
    handleTogglePriority: PropTypes.func,
    handleSendFeedbackMenuItemClick: PropTypes.func,
    createQualificationChecklist: PropTypes.func,
    toggleStageChange: PropTypes.func,
    toggleEditPassCampaign: PropTypes.func,
    share: PropTypes.func,
    goToPage: PropTypes.func,
    source: PropTypes.string.isRequired,
    actions: PropTypes.object.isRequired
  };

  shouldShowMenuItem = (item) => {
    const {source} = this.props;
    return MenuItems[source].includes(item);
  };

  render() {
    const {
      organization = {},
      userHasFullAccess = false,
      decision: {
        state = '',
        stage = '',
        requires_manual_help = false,
        priority
      } = {},
      userRoles = [],
      deleteSignals,
      toggleManualHelp,
      handleTogglePriority,
      handleSendFeedbackMenuItemClick,
      createQualificationChecklist,
      toggleStageChange,
      toggleEditPassCampaign,
      share,
      goToPage,
      actions,
      source
    } = this.props;

    return (
      <IconMenu
        className="more-menu"
        iconButtonElement={
          <IconButton>
            <MoreVertIcon />
          </IconButton>
        }
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
        targetOrigin={{horizontal: 'right', vertical: 'top'}}
      >
        <If condition={source === 'profile' && window.innerWidth < 768}>
          <div className="mobile-top-bar-actions">{actions}</div>
        </If>
        <If condition={this.shouldShowMenuItem('share')}>
          <MenuItem primaryText="Share" onClick={share} />
        </If>
        <If condition={this.shouldShowMenuItem('edit')}>
          <Link
            onClick={() => {
              goToPage('edit');
            }}
            style={{
              textDecoration: 'none'
            }}
            className="edit-link"
          >
            <MenuItem primaryText="Edit" />
          </Link>
        </If>
        <If condition={userHasFullAccess && this.shouldShowMenuItem('merge')}>
          <Link
            onClick={() => {
              goToPage('merge');
            }}
            style={{
              textDecoration: 'none'
            }}
            className="merge-link"
          >
            <MenuItem primaryText="Merge" />
          </Link>
        </If>
        <If condition={stage && this.shouldShowMenuItem('change_stage')}>
          <MenuItem
            primaryText="Change Stage"
            className="change-stage"
            onClick={() => toggleStageChange(true)}
          />
        </If>
        <If
          condition={
            state === 'contact' &&
            stage !== 'Passed' &&
            this.shouldShowMenuItem('pass')
          }
        >
          <MenuItem
            primaryText="Pass"
            className="pass-button"
            onClick={() => toggleEditPassCampaign(true)}
          />
        </If>
        <If condition={this.shouldShowMenuItem('delete_signals')}>
          <MenuItem
            primaryText="Delete signals"
            style={{color: 'red'}}
            onClick={deleteSignals}
          />
        </If>
        <If
          condition={
            userRoles.includes('admin') &&
            (requires_manual_help || state === 'contact') &&
            this.shouldShowMenuItem('requires_manual_help')
          }
        >
          <MenuItem
            primaryText="Toggle Requires Manual Help"
            onClick={() => toggleManualHelp(!requires_manual_help)}
          />
        </If>
        <If condition={this.shouldShowMenuItem('urgent')}>
          <MenuItem
            primaryText={
              priority === 1 ? 'Mark as Not Urgent' : 'Mark as Urgent'
            }
            onClick={() => handleTogglePriority(!priority)}
          />
        </If>
        <If condition={this.shouldShowMenuItem('report')}>
          <MenuItem
            primaryText="Report Data Bug"
            onClick={handleSendFeedbackMenuItemClick}
          />
        </If>
        <If
          condition={
            !(organization.source_refs || {}).QUALIFICATION &&
            this.shouldShowMenuItem('qualification_checklist')
          }
        >
          <MenuItem
            primaryText="Create Qualification Checklist"
            onClick={createQualificationChecklist}
          />
        </If>
      </IconMenu>
    );
  }
}
