import React from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import _ from 'underscore';
import moment from 'moment';

import {
  Card,
  CardHeader,
  CardText,
  CircularProgress,
  Toggle
} from 'material-ui';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import Warning from 'material-ui/svg-icons/alert/warning';
import DatePicker from 'material-ui/DatePicker';

import SelectPerson from './SelectPerson';
import ReasonInput from '../components/ReasonInput';

import inreachTeam from '../../config/inreach_team';
import {
  editableCampaigns,
  mailings,
  campaigns,
  campaignConfigs
} from '../../config/campaigns';

import PersonStore from '../../storage/PersonStore';
import CampaignsStore from '../../storage/CampaignsStore';
import SequenceStateStore from '../../storage/SequenceStateStore';
import WorkflowTemplateStore from '../../storage/WorkflowTemplateStore';
import ScorecardStore from '../../storage/ScorecardStore';

import {
  activeInReachTeam,
  extractContent,
  sanitizeEmailHtml
} from '../../lib/helpers';

const momentDurationFormatSetup = require('moment-duration-format');
momentDurationFormatSetup(moment);

const EMAIL_LABELS = {
  1: 'First Step',
  2: 'Second Step',
  3: 'Third Step',
  4: 'Fourth Step'
};

const ACTIVE_TEMPLATE_NUM = (step) => {
  const {sequence_templates: sequenceTemplates = []} = step;
  return sequenceTemplates.findIndex((template) => template.enabled);
};

const ACTIVE_TEMPLATE = (step) => {
  const {sequence_templates: sequenceTemplates = []} = step;
  const activeTemplateNum = ACTIVE_TEMPLATE_NUM(step);
  return activeTemplateNum > -1 ? sequenceTemplates[activeTemplateNum] : null;
};

const STEP_SUBJECT = (step) => {
  const activeTemplate = ACTIVE_TEMPLATE(step);
  return activeTemplate ? activeTemplate.template.subject : null;
};

const FROM_PEOPLE = inreachTeam.filter((person) => person.hasOutreach);
const ACTIVE_TEAM = activeInReachTeam();

function withStats(people, stats) {
  return people.map((person) => {
    const total_assigned = (stats.assigned[person.email] || {}).total;

    return {
      ...person,
      name:
        stats.assigned[person.email] && stats.organizer[person.email]
          ? `${person.name} (${total_assigned})`
          : person.name
    };
  });
}

class CampaignStep extends React.Component {
  static propTypes = {
    step: PropTypes.object.isRequired,
    originatingStep: PropTypes.object,
    campaignSelected: PropTypes.string.isRequired,
    onBodyChange: PropTypes.func.isRequired,
    onSubjectChange: PropTypes.func.isRequired,
    editableCampaignBody: PropTypes.string.isRequired,
    editableCampaignSubject: PropTypes.string.isRequired,
    when: PropTypes.object,
    totalInterval: PropTypes.number.isRequired
  };

  state = {};

  intervalToTime = (interval, startingPoint = 0) => {
    const time = moment.unix(interval + startingPoint + Date.now() / 1000);
    const filteredDuration = time.format('ll');
    return 'Scheduled for ' + filteredDuration;
  };

  render() {
    const {
      step,
      originatingStep,
      campaignSelected,
      onBodyChange,
      onSubjectChange,
      editableCampaignBody,
      editableCampaignSubject,
      when,
      totalInterval = 0
    } = this.props;
    const stepTemplateNum = ACTIVE_TEMPLATE_NUM(step);
    if (stepTemplateNum < 0) {
      return false;
    }
    const stepTemplate = ACTIVE_TEMPLATE(step);

    const emailSubject =
      (stepTemplate.is_reply ? 'Re: ' : '') +
      STEP_SUBJECT(
        stepTemplate.is_reply && originatingStep ? originatingStep : step
      );
    const emailBody = stepTemplate.template.body_html;
    const startingPoint = when ? (when.getTime() - Date.now()) / 1000 : null;

    const campaignConfig = campaignConfigs[campaignSelected];
    const isEditableCampaign =
      editableCampaigns.indexOf(
        campaignConfig.sub_campaign
          ? campaignConfig.sub_campaign
          : campaignSelected
      ) > -1;
    return (
      <React.Fragment>
        <Card
          style={{marginTop: '10px'}}
          initiallyExpanded={step.order === 1 && isEditableCampaign}
        >
          <CardHeader
            title={
              <div className="email-details">
                <div className="email-title">{EMAIL_LABELS[step.order]}</div>
                <If condition={step.interval}>
                  <div className="interval-indicator">
                    {this.intervalToTime(totalInterval, startingPoint)}
                  </div>
                </If>
              </div>
            }
            actAsExpander={true}
            showExpandableButton={true}
          />
          <CardText
            expandable={true}
            style={{marginBottom: '0', paddingTop: '0'}}
          >
            <If condition={isEditableCampaign && step.order === 1}>
              <TextField
                className="edit-email-subject"
                floatingLabelText="Subject"
                floatingLabelFixed={true}
                fullWidth={true}
                name="subject"
                value={editableCampaignSubject || emailSubject}
                disabled={false}
                textareaStyle={{
                  color: 'black'
                }}
                multiLine={true}
                floatingLabelStyle={{
                  color: 'black',
                  fontWeight: 'bold',
                  fontSize: '18px'
                }}
                onChange={(event, emailSubject) => {
                  onSubjectChange(
                    emailSubject,
                    step.order - 1,
                    stepTemplateNum
                  );
                }}
              />
              <div className="edit-body-tag">Body</div>
              <ReactQuill
                theme="snow"
                value={
                  isEditableCampaign && step.order === 1
                    ? editableCampaignBody
                    : emailBody
                }
                className="email-editor"
                onChange={(updatedBody) => {
                  onBodyChange(updatedBody, step.order - 1, stepTemplateNum);
                }}
              />
            </If>

            <If condition={!(isEditableCampaign && step.order === 1)}>
              <div className="disabled-subject">Subject:</div>
              <div>{emailSubject}</div>
              <div className="disabled-body">Body:</div>
              <div
                style={{whiteSpace: 'pre-line'}}
                dangerouslySetInnerHTML={{
                  __html: sanitizeEmailHtml(emailBody)
                }}
              />
            </If>
          </CardText>
        </Card>
      </React.Fragment>
    );
  }
}

class CampaignSteps extends React.Component {
  static propTypes = {
    campaign: PropTypes.object.isRequired,
    campaignSelected: PropTypes.string.isRequired,
    onBodyChange: PropTypes.func.isRequired,
    onSubjectChange: PropTypes.func.isRequired,
    editableCampaignBody: PropTypes.string.isRequired,
    editableCampaignSubject: PropTypes.string.isRequired
  };

  render() {
    const {
      campaign,
      campaignSelected,
      onBodyChange,
      onSubjectChange,
      editableCampaignBody,
      editableCampaignSubject,
      when
    } = this.props;

    let originatingStep = null;
    let totalInterval = 0;
    return (
      <React.Fragment>
        {campaign.sequence_steps.map((step) => {
          const template = ACTIVE_TEMPLATE(step);
          originatingStep =
            !template || template.is_reply ? originatingStep : step;
          totalInterval += step.interval;

          return (
            <CampaignStep
              key={step.id}
              step={step}
              originatingStep={originatingStep}
              campaignSelected={campaignSelected}
              onBodyChange={onBodyChange}
              onSubjectChange={onSubjectChange}
              editableCampaignBody={editableCampaignBody}
              editableCampaignSubject={editableCampaignSubject}
              when={when}
              totalInterval={totalInterval}
            />
          );
        })}
      </React.Fragment>
    );
  }
}

export default class CustomizeCampaign extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    decision: PropTypes.object.isRequired,
    from: PropTypes.string.isRequired,
    assignedTo: PropTypes.string.isRequired,
    organizer: PropTypes.string.isRequired,
    isEditingCampaign: PropTypes.bool.isRequired,
    onDone: PropTypes.func.isRequired,
    client: PropTypes.string.isRequired,
    submitCampaign: PropTypes.func.isRequired,
    campaignSelected: PropTypes.string.isRequired,
    onChangeFrom: PropTypes.func,
    onChangeAssignedTo: PropTypes.func,
    onChangeOrganizer: PropTypes.func,
    defaultMailingEnabled: PropTypes.bool,
    disablePeople: PropTypes.array,
    requireCompleteCampaignTo: PropTypes.bool,
    evaluationStage: PropTypes.object,
    fallbackToCall: PropTypes.bool,
    recentMessages: PropTypes.object,
    structuredReasons: PropTypes.object
  };

  static defaultProps = {
    defaultMailingEnabled: true,
    disablePeople: [],
    requireCompleteCampaignTo: false,
    structuredReasons: {}
  };

  state = {
    existingCampaign: null,
    existingSequenceStates: null,
    campaignTo: null,
    templatesChanged: false,
    callDate: moment()
      .day(8)
      .format('YYYY-MM-DD'),
    callTime: '10:00:00',
    errorPeople: false,
    errorCampaign: false,
    loadingPeople: true,
    loadingCampaign: true,
    submitting: false,
    mailingSubject: '',
    mailingBody: '',
    editableCampaignBody: '',
    editableCampaignSubject: '',
    when: null,
    reconnectReason: '',
    passReason: '',
    stats: {assigned: {}, organizer: {}},
    campaignEnabled: true,
    mailingEnabled: true,
    calendlyObject: '',
    campaignType: null,
    createdStructuredReasons: {},
    latestCallDecisionRecommendation: {},
    scorecardReasonCopied: false,
    errorSubmitting: null
  };

  workflowTemplateStore = new WorkflowTemplateStore();
  campaignStore = new CampaignsStore(this.props.organization.id);
  personStore = new PersonStore(this.props.organization.person_ids);
  sequenceStateStore = new SequenceStateStore(this.props.organization.id);
  scorecardStore = new ScorecardStore(this.props.organization.id);

  componentDidUpdate(prevProps) {
    const {isEditingCampaign, campaignSelected} = this.props;
    if (prevProps.campaignSelected !== campaignSelected) {
      this.updatePeopleAndCampaign();
      this.loadTemplate();
    }
    if (
      prevProps.isEditingCampaign !== isEditingCampaign &&
      isEditingCampaign
    ) {
      this.getCampaignType().then(() => {
        if (
          this.state.campaignType === 'pass' ||
          this.state.campaignType === 'reconnect'
        ) {
          this.getLatestScorecardCallRecommendationDecision();
        }
      });
    }
  }

  componentDidMount() {
    const {campaignSelected} = this.props;
    const {campaignTo, assignedTo, campaign} = this.state;
    if (campaignSelected === 'email') {
      this.setState({
        mailingSubject: 'Getting in touch about {{account.name}}'
      });
    }
    if (campaignSelected && (!campaignTo || !assignedTo || !campaign)) {
      this.updatePeopleAndCampaign();
    }
    this.loadTemplate();
    this.getCampaignType().then(() => {
      if (
        this.state.campaignType === 'pass' ||
        this.state.campaignType === 'reconnect'
      ) {
        this.getLatestScorecardCallRecommendationDecision();
      }
    });
  }

  getCampaignType = () => {
    const {campaignSelected} = this.props;
    return new Promise((resolve) => {
      const campaignType =
        campaignSelected.indexOf('pass') > -1
          ? 'pass'
          : campaignSelected.indexOf('reconnect') > -1
            ? 'reconnect'
            : null;
      this.setState(
        {
          campaignType
        },
        () => {
          resolve();
        }
      );
    });
  };

  getLatestScorecardCallRecommendationDecision = () => {
    this.scorecardStore
      .getLatestCallDecisionRecommendation()
      .then((latestCallDecisionRecommendation) => {
        this.setState({
          latestCallDecisionRecommendation
        });
      });
  };

  addMonths = (months) => {
    const {when} = this.state;
    const initialDate = when ? when : new Date();
    this.setState({
      when: initialDate.setMonth(initialDate.getMonth() + months)
    });
  };

  sortPeople = (people) => {
    const {primary_contact_id: primaryContactId} =
      this.props.organization || null;
    let primaryContactPerson = null;
    const peopleCopy = people.slice(),
      CLevelBucket = [],
      foundersBucket = [],
      teamBucket = [];

    peopleCopy.forEach((person) => {
      const {title = '', role = ''} = person;
      if (
        title.toLowerCase().indexOf('ceo') !== -1 ||
        title.toLowerCase().indexOf('cto ') !== -1
      ) {
        CLevelBucket.push(person);
      } else if (
        role.toLowerCase().indexOf('founder') !== -1 ||
        title.toLowerCase().indexOf('founder') !== -1 ||
        person.id === this.props.primaryContactId
      ) {
        foundersBucket.push(person);
      } else {
        teamBucket.push(person);
      }
      if (person.id === primaryContactId) {
        primaryContactPerson = person;
      }
    });

    return {
      primaryContactPerson,
      CLevelBucket,
      foundersBucket,
      teamBucket
    };
  };

  getOrganizationPeople = (people) => {
    const uniquePeople = [];
    for (let i = 0; i < people.length; i++) {
      let isFirstInstance = true;
      for (let j = 0; j < uniquePeople.length; j++) {
        if (_.isEqual(uniquePeople[j], people[i])) {
          isFirstInstance = false;
        }
      }
      if (isFirstInstance) uniquePeople.push(people[i]);
    }
    return this.sortPeople(uniquePeople);
  };

  updatePeopleAndCampaign = () => {
    const {campaignSelected, defaultMailingEnabled, disablePeople} = this.props;
    const {existingCampaign, existingSequenceStates} = this.state;
    const campaignConfig = campaignConfigs[campaignSelected];

    this.setState({
      loadingCampaign: true,
      loadingPeople: !this.state.organizationPeople || this.state.errorPeople,
      mailingEnabled:
        defaultMailingEnabled !== null ? defaultMailingEnabled : true
    });
    if (!this.state.organizationPeople || this.state.errorPeople) {
      this.personStore
        .getModels(null, null, null, null)
        .then((people) => {
          const peopleBuckets = this.getOrganizationPeople(people);
          const primaryContactId = (peopleBuckets.primaryContactPerson || {})
            .id;
          const campaignTo = disablePeople.includes(primaryContactId)
            ? undefined
            : primaryContactId;

          this.setState({
            campaignTo: campaignTo,
            organizationPeople: peopleBuckets.CLevelBucket.concat(
              peopleBuckets.foundersBucket,
              peopleBuckets.teamBucket
            ),
            loadingPeople: false,
            errorPeople: false
          });
        })
        .catch((error) => {
          this.setState({loadingPeople: false, errorPeople: true});
          console.error('Error while getting people for the campaign: ', error);
        });
    }
    if (campaigns.indexOf(campaignSelected) > -1) {
      this.campaignStore
        .getCampaign(
          campaignConfig.sub_campaign
            ? campaignConfig.sub_campaign
            : campaignSelected
        )
        .then((campaign) => {
          this.setState({
            loadingCampaign: false,
            errorCampaign: false,
            campaign: campaign
          });
        })
        .catch((error) => {
          this.setState({loadingCampaign: false, errorCampaign: true});
          console.error('Error while getting emails for the campaign: ', error);
        });
    } else {
      this.setState({
        loadingCampaign: false,
        errorCampaign: false
      });
    }
    if (existingCampaign === null) {
      this.campaignStore
        .getOrganizationCampaign()
        .then((existingCampaign) => this.setState({existingCampaign}))
        .catch((error) => {
          if (error.status === 404) {
            this.setState({existingCampaign: {}});
          } else {
            console.error(error);
          }
        });
    }
    if (existingSequenceStates === null) {
      this.sequenceStateStore
        .sequenceStates()
        .then((existingSequenceStates) =>
          this.setState({existingSequenceStates})
        )
        .catch(console.error);
    }
  };

  onSelectedFrom = (e, i, from) => {
    from = from !== 'none' ? from : null;
    const {onChangeFrom} = this.props;
    if (onChangeFrom) {
      onChangeFrom(from);
    }
  };

  onSelectedAssignedTo = (e, i, assignedTo) => {
    const {onChangeAssignedTo} = this.props;
    if (onChangeAssignedTo) {
      onChangeAssignedTo(assignedTo);
    }
  };

  onSelectedOrganizer = (e, i, organizer) => {
    const {onChangeOrganizer} = this.props;
    if (onChangeOrganizer) {
      onChangeOrganizer(organizer);
    }
  };

  onSelectedCampaignTo = (e, i, campaignTo) => {
    this.setState({campaignTo: campaignTo});
  };

  onCampaignSubjectChange = (subject, sequenceIndex, templateIndex) => {
    const {campaign: editedCampaign} = this.state;
    const {campaignSelected} = this.props;
    if (
      editableCampaigns.indexOf(campaignSelected) > -1 &&
      sequenceIndex === 0
    ) {
      this.setState({
        templatesChanged: false,
        editableCampaignSubject: subject
      });
    } else {
      if (
        subject !==
        editedCampaign.sequence_steps[sequenceIndex].sequence_templates[
          templateIndex
        ].template.subject
      ) {
        editedCampaign.sequence_steps[sequenceIndex].sequence_templates[
          templateIndex
        ].template.subject = subject;
        this.setState({templatesChanged: true, campaign: editedCampaign});
      }
    }
  };

  onMailingSubjectChange = (subject) => {
    this.setState({
      templatesChanged: false,
      mailingSubject: subject
    });
  };

  onMailingBodyChange = (body) => {
    this.setState({
      templatesChanged: false,
      mailingBody: body
    });
  };

  onCampaignBodyChange = (emailBody, sequenceIndex, templateIndex) => {
    const {campaign: editedCampaign} = this.state;
    const {campaignSelected} = this.props;
    if (
      editableCampaigns.indexOf(campaignSelected) > -1 &&
      sequenceIndex === 0
    ) {
      this.setState({
        templatesChanged: false,
        editableCampaignBody: emailBody
      });
    } else {
      if (
        extractContent(emailBody) !==
        extractContent(
          editedCampaign.sequence_steps[sequenceIndex].sequence_templates[
            templateIndex
          ].template.body_html
        )
      ) {
        editedCampaign.sequence_steps[sequenceIndex].sequence_templates[
          templateIndex
        ].template.body_html = emailBody;
        this.setState({templatesChanged: true, campaign: editedCampaign});
      }
    }
  };

  getPreviousEmail = () => {
    const {
      organization: {inboxes = {}},
      client
    } = this.props;
    const replyEmailBody =
      _.findWhere(inboxes[client], {
        inbox: true,
        reason: 'RECONNECT'
      }) || {};
    return replyEmailBody.notes;
  };

  renderPreviousEmail = (campaignSelected) => {
    const campaignConfig = campaignConfigs[campaignSelected] || {};
    const previousEmail = this.getPreviousEmail();
    const {mailingEnabled} = this.state;
    const email = previousEmail
      ? previousEmail.split(/(.*)wrote:\s*>.*/g)[0]
      : null;
    const emailDetails = email ? email.split('\n')[0] : null;
    const emailBody = email
      ? sanitizeEmailHtml(
          email
            .split('\n')
            .slice(1, -2)
            .join('\n')
        )
      : null;

    return (
      <If condition={previousEmail && campaignConfig.include_previous_email}>
        <Card style={{marginTop: '10px'}} expanded={mailingEnabled}>
          <CardHeader
            title={'Previous Email'}
            actAsExpander={true}
            showExpandableButton={true}
            style={{
              paddingBottom: '0'
            }}
            titleStyle={{
              fontWeight: 'bold',
              fontSize: '18px'
            }}
          />
          <CardText expandable={true}>
            <div> {emailDetails} </div>
            <div
              style={{whiteSpace: 'pre-line'}}
              dangerouslySetInnerHTML={{
                __html: emailBody
              }}
            />
          </CardText>
        </Card>
      </If>
    );
  };

  handleDateChange = (date) => {
    this.setState({when: date});
  };

  undoCampaignCustomization = () => {
    this.updatePeopleAndCampaign();
  };

  loadTemplate = () => {
    const {campaignSelected} = this.props;
    const campaignConfig = campaignConfigs[campaignSelected] || {};
    if (campaignConfig.template_name) {
      this.workflowTemplateStore
        .template(campaignConfig.template_name)
        .then((template) => {
          this.setState({
            mailingSubject: template.subject,
            mailingBody: template.body_html
          });
        })
        .catch(console.error);
    }

    if (campaignConfig.editable_template_name) {
      this.workflowTemplateStore
        .template(campaignConfig.editable_template_name)
        .then((template) => {
          this.setState({
            editableCampaignBody: template.body_html,
            editableCampaignSubject: template.subject
          });
        })
        .catch(console.error);
    }
  };

  contactHasEmail = () => {
    const {organizationPeople = [], campaignTo} = this.state;
    const contact = organizationPeople.find(
      (person) => person.id === campaignTo
    );
    const email = contact ? contact.email : null;
    return !!email;
  };

  handleReconnectReasonChange = (reason) => {
    this.setState({
      reconnectReason: reason
    });
  };

  handlePassReasonChange = (reason) => {
    this.setState({
      passReason: reason
    });
  };

  handleAddStructuredReason = (structuredReason) => {
    this.setState({
      createdStructuredReasons: {
        ...this.state.createdStructuredReasons,
        [structuredReason.id]: structuredReason
      }
    });
  };

  handleDeleteStructuredReason = (reasonId) => {
    const createdStructuredReasonsCopy = Object.assign(
      {},
      this.state.createdStructuredReasons
    );
    delete createdStructuredReasonsCopy[reasonId];
    this.setState({
      createdStructuredReasons: createdStructuredReasonsCopy
    });
  };

  canCopyRecommendationDecision = () => {
    const {
      campaignType,
      latestCallDecisionRecommendation: {decision: {decision = ''} = {}} = {}
    } = this.state;
    return (
      (decision === 'PASS' && campaignType === 'pass') ||
      (decision === 'RECONNECT' && campaignType === 'reconnect')
    );
  };

  handleCopyRecommendationDecision = () => {
    const {
      campaignType,
      latestCallDecisionRecommendation: {
        decision: {
          decision = '',
          notes: decisionNotes = '',
          reasons: decisionStructuredReasons = {}
        } = {},
        recommendations = []
      } = {}
    } = this.state;
    const reason = {
      reasonNote: '',
      reasons: {}
    };
    if (decision === 'PASS' || decision === 'RECONNECT') {
      recommendations.forEach(
        (
          {
            recommendation = '',
            notes: recommendationNotes = '',
            reasons: recommendationStructuredReasons = {}
          },
          index
        ) => {
          if (recommendation === decision) {
            reason.reasonNote +=
              index !== 0
                ? `\n\n${recommendationNotes}`
                : `${recommendationNotes}`;
            reason.reasons = {
              ...reason.reasons,
              ...recommendationStructuredReasons
            };
          }
        }
      );
      if (decisionNotes !== '') {
        reason.reasonNote +=
          reason.reasonNote !== ''
            ? `\n\n${decisionNotes}`
            : `${decisionNotes}`;
        reason.reasons = {
          ...reason.reasons,
          ...decisionStructuredReasons
        };
      }
      this.setState({
        [`${campaignType}Reason`]: reason.reasonNote,
        createdStructuredReasons: reason.reasons,
        scorecardReasonCopied: true
      });
    }
  };

  parseCalendlyObject = (calendlyObject) => {
    const regexDate = /<p>(MON|TUE|WED|THU|FRI|SAT|SUN)<\/p><p>/;
    const regexStripStart = /^<p><br><\/p>/;
    const regexStripEnd = /<p><br><\/p>$/;
    const regexFirstLines = /<p>Please select the time that works best for you.<\/p><p><strong>Event:.+?(?=<\/p>)<\/p>/;
    const regexTimeZone = /, Ireland, Lisbon Time.+?(?=Change<\/a>)Change<\/a>/;
    const regexLastLine = /<p>Powered by.+?(?=Calendly<\/a><\/p>)Calendly<\/a><\/p>/;
    const regexSelectOther = /Need another time\?/;
    const regexParagraphStart = /<p>/g;
    const regexParagraphEnd = /<\/p>/g;
    // const regex
    const parsedCalendly = calendlyObject
      .replace(regexDate, '<p>$1 ')
      .replace(regexStripStart, '')
      .replace(regexStripEnd, '')
      .replace(regexFirstLines, '')
      .replace(regexTimeZone, ' Time')
      .replace(regexLastLine, '')
      .replace(
        regexSelectOther,
        'Please feel free to suggest another date and time if that is more convenient for you.'
      )
      .replace(regexParagraphStart, '<div>')
      .replace(regexParagraphEnd, '</div>');
    this.setState({calendlyObject: parsedCalendly});
  };

  render() {
    const {
      organization,
      isEditingCampaign,
      onDone,
      campaignSelected,
      submitCampaign,
      decision,
      selectAssigned,
      requireCompleteCampaignTo,
      evaluationStage,
      fallbackToCall = false,
      recentMessages,
      from,
      assignedTo,
      organizer,
      structuredReasons = {}
    } = this.props;
    const {
      organizationPeople,
      campaignTo,
      campaign,
      loadingCampaign,
      loadingPeople,
      submitting,
      errorPeople,
      errorCampaign,
      errorSubmitting,
      templatesChanged,
      mailingBody,
      mailingSubject,
      editableCampaignBody,
      editableCampaignSubject,
      reconnectReason,
      passReason,
      when,
      stats,
      campaignEnabled,
      existingCampaign,
      existingSequenceStates,
      mailingEnabled,
      calendlyObject,
      createdStructuredReasons,
      scorecardReasonCopied = false
    } = this.state;

    const campaignConfig = campaignConfigs[campaignSelected] || {};
    const isMailing = mailings.indexOf(campaignSelected) > -1;
    const isCampaign = campaigns.indexOf(campaignSelected) > -1;
    const activeSequenceStates = Object.values(existingSequenceStates || {})
      .flatMap((states) => states)
      .filter((state) => state.state === 'active');
    const {primary_contact_id = 'none'} = organization;

    const actions = submitting
      ? []
      : [
          <RaisedButton
            key="cancel"
            className="edit-email-close"
            value="campaign_cancel"
            backgroundColor={'#f44336'}
            labelColor={'#ffffff'}
            style={{marginRight: '16px'}}
            onClick={() => {
              if (templatesChanged) {
                this.undoCampaignCustomization();
              }
              onDone(false);
            }}
            label={'Close'}
          />,
          <RaisedButton
            key="done"
            className="edit-email-done"
            value="send_campaign"
            primary={true}
            disabled={
              _.isEmpty(campaignConfig) ||
              (isMailing && !mailingBody) ||
              (requireCompleteCampaignTo &&
                !campaignTo &&
                !this.contactHasEmail()) ||
              (campaignConfig.include_reason &&
                (!passReason ||
                  Object.keys(createdStructuredReasons).length === 0)) ||
              (campaignConfig.include_reconnect &&
                (!reconnectReason ||
                  !when ||
                  primary_contact_id === 'none' ||
                  Object.keys(createdStructuredReasons).length === 0)) ||
              !campaignTo
            }
            onClick={() => {
              this.setState({submitting: true});
              submitCampaign(
                campaignSelected,
                _.pick(
                  this.state,
                  'campaign',
                  'campaignTo',
                  'templatesChanged',
                  'organizationPeople',
                  'campaignSelected',
                  'mailingBody',
                  'mailingSubject',
                  'editableCampaignBody',
                  'editableCampaignSubject',
                  'when',
                  'reconnectReason',
                  'passReason',
                  'campaignEnabled',
                  'mailingEnabled',
                  'calendlyObject',
                  'createdStructuredReasons'
                ),
                evaluationStage
              )
                .then(() => {
                  this.setState({submitting: false});
                  onDone(false);
                })
                .catch((error) => {
                  this.setState({submitting: false, errorSubmitting: error});
                  console.error(error);
                });
            }}
            label={
              (isCampaign && campaignEnabled) ||
              (!isCampaign && isMailing && mailingEnabled)
                ? 'Send'
                : 'Save'
            }
          />
        ];

    return (
      <Dialog
        className="customize-campaign-modal"
        title={`Customize Campaign: ${campaignConfig.label}`}
        actions={actions}
        modal={true}
        open={isEditingCampaign}
        repositionOnUpdate={true}
        autoDetectWindowHeight={true}
        autoScrollBodyContent={true}
        contentClassName="customize-campaign-modal-content"
        bodyClassName="customize-campaign-modal-body"
      >
        <Choose>
          <When condition={submitting}>
            <CircularProgress size={24} />
          </When>
          <When condition={_.isEmpty(campaignConfig)}>
            <div className="error-message">
              <Warning color="#FF0000" className="icon" />
              This campaign doesn't exist: {campaignSelected}
            </div>
          </When>
          <When condition={!!organization.do_not_contact}>
            <div className="error-message">
              <Warning color="#FF0000" className="icon" />
              You must not contact this organization
            </div>
          </When>
          <When condition={!!decision.stop}>
            <div className="error-message">
              <Warning color="#FF0000" className="icon" />
              Emails have been disabled. You must re-enable them before
              continuing.
            </div>
          </When>
          <Otherwise>
            <If condition={!!campaignConfig.include_reconnect}>
              <div className="customize-campaign-section">
                <div className="customize-campaign-section-title generic-title">
                  Reconnect reason
                </div>
                <div className="customize-campaign-section-content">
                  <ReasonInput
                    organization={organization}
                    structuredReasons={
                      (structuredReasons['reconnect'] || {}).reasons || []
                    }
                    reason={reconnectReason}
                    handleReasonChange={this.handleReconnectReasonChange}
                    handleAddStructuredReason={this.handleAddStructuredReason}
                    handleDeleteStructuredReason={
                      this.handleDeleteStructuredReason
                    }
                    useStructured={true}
                    source="customize-campaign"
                  />
                  <DatePicker
                    name="reconnect-date"
                    className="reconnect-date"
                    onChange={(e, date) => {
                      this.handleDateChange(date);
                    }}
                    autoOk={this.state.autoOk}
                    floatingLabelText="Reconnect date"
                    minDate={new Date()}
                    formatDate={(date) => {
                      return date
                        .toString()
                        .split(' ')
                        .slice(0, 4)
                        .join(' ');
                    }}
                  />
                  <If condition={!reconnectReason}>
                    <div className="error-message">
                      <Warning color="#FF0000" className="icon" />
                      Reason required
                    </div>
                  </If>
                  <If
                    condition={
                      Object.keys(createdStructuredReasons).length === 0
                    }
                  >
                    <div className="error-message">
                      <Warning color="#FF0000" className="icon" />
                      You must add at least <b>one</b> structured reason. Type #
                      to add a structured reason
                    </div>
                  </If>
                  <If condition={!when}>
                    <div className="error-message">
                      <Warning color="#FF0000" className="icon" />
                      Date required
                    </div>
                  </If>
                </div>
              </div>
            </If>
            <If condition={campaignConfig.include_reason}>
              <div className="customize-campaign-section">
                <div className="customize-campaign-section-title generic-title">
                  Pass Reason
                </div>
                <div className="customize-campaign-section-content">
                  <ReasonInput
                    organization={organization}
                    structuredReasons={
                      (structuredReasons['pass'] || {}).reasons || []
                    }
                    reason={passReason}
                    handleReasonChange={this.handlePassReasonChange}
                    handleAddStructuredReason={this.handleAddStructuredReason}
                    handleDeleteStructuredReason={
                      this.handleDeleteStructuredReason
                    }
                    useStructured={true}
                    source="customize-campaign"
                  />
                  <If condition={passReason === ''}>
                    <div className="error-message">
                      <Warning color="#FF0000" className="icon" />
                      Reason required
                    </div>
                  </If>
                  <If
                    condition={
                      Object.keys(createdStructuredReasons).length === 0
                    }
                  >
                    <div className="error-message">
                      <Warning color="#FF0000" className="icon" />
                      You must add at least <b>one</b> structured reason
                    </div>
                  </If>
                </div>
              </div>
            </If>

            <If
              condition={
                (campaignConfig.include_reconnect ||
                  campaignConfig.include_reason) &&
                !scorecardReasonCopied &&
                this.canCopyRecommendationDecision()
              }
            >
              <RaisedButton
                className="copy-scorecard-reason"
                backgroundColor={'#5dab49'}
                labelColor={'#ffffff'}
                onClick={this.handleCopyRecommendationDecision}
                label={'Copy Scorecard Reason'}
              />
            </If>

            <If condition={loadingPeople}>
              <CircularProgress size={24} />
            </If>

            <If condition={!loadingPeople && !errorPeople}>
              <div className="customize-campaign-section">
                <div className="customize-campaign-section-title generic-title">
                  People
                </div>
                <div className="customize-campaign-section-content">
                  <div>
                    <div className="from-selectors">
                      <SelectPerson
                        label={'From:'}
                        disabled={!selectAssigned}
                        multiple={false}
                        people={FROM_PEOPLE}
                        includeNone={true}
                        selected={
                          FROM_PEOPLE.find((person) => person.email === from)
                            ? from
                            : 'none'
                        }
                        onSelectPerson={this.onSelectedFrom}
                        fullWidth={false}
                        className="campaign-from"
                      />
                      <SelectPerson
                        label={'Assigned To:'}
                        disabled={!selectAssigned}
                        multiple={false}
                        people={withStats(ACTIVE_TEAM, stats)}
                        selected={assignedTo}
                        onSelectPerson={this.onSelectedAssignedTo}
                        fullWidth={false}
                        className="campaign-assigned_to"
                      />
                      <SelectPerson
                        label={'Organizer:'}
                        disabled={!selectAssigned}
                        multiple={false}
                        people={withStats(ACTIVE_TEAM, stats)}
                        selected={organizer}
                        onSelectPerson={this.onSelectedOrganizer}
                        fullWidth={false}
                        className="campaign-organizer"
                      />
                    </div>
                    <div className="to-selector">
                      <SelectPerson
                        label={
                          campaignEnabled ? 'Send To:' : 'Primary Contact:'
                        }
                        multiple={false}
                        people={organizationPeople}
                        selected={campaignTo}
                        onSelectPerson={this.onSelectedCampaignTo}
                        fullWidth={false}
                        withRoles={true}
                        usedKey={'id'}
                        disabledPeopleIds={this.props.disablePeople}
                        className="campaign-to"
                      />
                    </div>
                  </div>
                  <If
                    condition={
                      mailingEnabled ||
                      (campaignEnabled && campaignConfig.type !== 'mailing')
                    }
                  >
                    <Choose>
                      <When
                        condition={
                          recentMessages &&
                          recentMessages.lastEmailFrom &&
                          !recentMessages.matchesPrimary
                        }
                      >
                        <div className="error-message">
                          <Warning color="#FF0000" className="icon" />
                          Last email was received from:{' '}
                          {recentMessages.lastEmailFrom}
                        </div>
                      </When>
                      <When
                        condition={
                          !!campaignConfig.include_reconnect &&
                          primary_contact_id === 'none'
                        }
                      >
                        <div className="error-message">
                          <Warning color="#FF0000" className="icon" />
                          The organization must have a primary contact selected
                          to send a reconnect.
                        </div>
                      </When>
                      <When condition={!campaignTo}>
                        <div className="error-message">
                          <Warning color="#FF0000" className="icon" />
                          Please select a contact. The company will require
                          manual help.
                        </div>
                      </When>
                      <When condition={!this.contactHasEmail()}>
                        <div className="error-message">
                          <Warning color="#FF0000" className="icon" />
                          No email for that contact. The company will need
                          manual help.
                        </div>
                      </When>
                    </Choose>
                  </If>
                </div>
              </div>
            </If>
            <If condition={!!errorPeople}>
              <div className="error-message" style={{marginTop: '8px'}}>
                <Warning color="#FF0000" className="icon" />
                There was an error loading the organization members
              </div>
            </If>
            <If condition={activeSequenceStates.length > 0}>
              <div className="error-message">
                <Warning color="#FF0000" className="icon" />
                This organization is already assigned to a campaign{' '}
                {activeSequenceStates.length} sequence states:{' '}
                {activeSequenceStates
                  .map(({sequence: {key = 'unknown'} = {}}) => key)
                  .join(', ')}
                .<br />
                Please Contact Ben if you need to send the campaign now.
              </div>
            </If>
            <If condition={(existingCampaign || {}).sequence_name}>
              <div className="error-message" style={{marginTop: '8px'}}>
                <Warning color="#FF0000" className="icon" />
                This organization is already waiting to be assigned to campaign:{' '}
                {existingCampaign.sequence_name}, made{' '}
                {moment(new Date(existingCampaign.made_at)).format(
                  'Do MMM YYYY'
                )}
                .<br />
                Please Contact Ben if you need to send the campaign now.
              </div>
            </If>
            <If condition={loadingCampaign}>
              <CircularProgress size={24} />
            </If>
            <div style={{display: 'flex'}}>
              <div className="email-details">
                <div
                  className="email-title"
                  style={{color: 'rgb(93, 171, 73)'}}
                >
                  Campaign:
                </div>
              </div>
              <div className="spacer" />
              <If condition={!isMailing || isCampaign}>
                <Toggle
                  label={
                    campaignEnabled ? 'Send a campaign' : "Don't send campaign"
                  }
                  style={{
                    marginTop: '16px',
                    width: campaignEnabled ? '180px' : '210px'
                  }}
                  onToggle={() => {
                    this.setState({campaignEnabled: !campaignEnabled});
                  }}
                  toggled={campaignEnabled}
                />
              </If>
            </div>
            <If condition={campaignEnabled}>
              <If condition={!!errorCampaign}>
                <div className="error-message">
                  <Warning color="#FF0000" className="icon" />
                  There was an error loading the campaign
                </div>
              </If>
              <If condition={!!errorSubmitting}>
                <div className="error-message">
                  <Warning color="#FF0000" className="icon" />
                  There was an error submitting the campaign {errorSubmitting}
                </div>
              </If>
              <If
                condition={
                  !loadingCampaign && !errorCampaign && campaignEnabled
                }
              >
                <If condition={campaignSelected.includes('schedule_call')}>
                  <div className="edit-body-tag">
                    Paste the Calendly object below
                  </div>
                  <ReactQuill
                    theme="snow"
                    value={calendlyObject}
                    modules={{toolbar: false}}
                    onChange={(calendlyObject) => {
                      this.parseCalendlyObject(calendlyObject);
                    }}
                    placeholder="Paste the Calendly object here..."
                  />
                </If>

                <If condition={isMailing}>
                  <div style={{display: 'flex'}}>
                    {/*<div className="email-details">
                      <div className="email-title">Send an email now</div>
                    </div>*/}
                    <div className="spacer" />
                    <Toggle
                      label={
                        mailingEnabled
                          ? 'Send an email now'
                          : "Don't send an email now"
                      }
                      style={{
                        whiteSpace: 'nowrap',
                        width: 'max-content'
                      }}
                      onToggle={() => {
                        this.setState({mailingEnabled: !mailingEnabled});
                      }}
                      toggled={mailingEnabled}
                    />
                  </div>
                  <If condition={mailingEnabled}>
                    <CardText
                      className="reconnect-card-body"
                      style={{padding: 0}}
                    >
                      <TextField
                        className="edit-email-subject"
                        floatingLabelText="Subject"
                        hintText="Add Subject here"
                        floatingLabelFixed={true}
                        floatingLabelStyle={{
                          color: 'black',
                          fontWeight: 'bold',
                          fontSize: '18px'
                        }}
                        fullWidth={true}
                        name="subject"
                        value={mailingSubject}
                        onChange={(event, emailSubject) => {
                          this.onMailingSubjectChange(emailSubject);
                        }}
                      />
                      <div className="edit-body-tag">Body</div>
                      <ReactQuill
                        theme="snow"
                        value={mailingBody}
                        className="email-editor"
                        onChange={(emailBody) => {
                          this.onMailingBodyChange(emailBody, -1, 0);
                        }}
                      />
                    </CardText>
                  </If>
                  {this.renderPreviousEmail(campaignSelected)}
                </If>
                <If condition={isCampaign && campaign}>
                  <Card style={{padding: 0}}>
                    <CardHeader
                      title="Campaign Emails"
                      titleStyle={{
                        fontWeight: 'bold',
                        fontSize: '18px'
                      }}
                      style={{paddingBottom: '0', marginTop: '10px'}}
                    />
                    <CardText
                      className="reconnect-card-body"
                      style={{paddingTop: '0'}}
                    >
                      <CampaignSteps
                        campaign={campaign}
                        campaignSelected={campaignSelected}
                        onBodyChange={this.onCampaignBodyChange}
                        onSubjectChange={this.onCampaignSubjectChange}
                        editableCampaignBody={editableCampaignBody}
                        editableCampaignSubject={editableCampaignSubject}
                        when={when}
                      />
                    </CardText>
                    <If
                      condition={
                        campaignSelected === 'send_form' &&
                        (fallbackToCall ||
                          (decision && decision.fallback_to_call))
                      }
                    >
                      <div
                        className=""
                        style={{
                          marginLeft: '16px',
                          paddingBottom: '8px',
                          fontWeight: 'bold'
                        }}
                      >
                        After these two emails they will receive a fallback to
                        call email, and then will be moved to Make Contact
                      </div>
                    </If>
                    <If
                      condition={
                        campaignSelected === 'send_form' &&
                        (!fallbackToCall &&
                          !(decision && decision.fallback_to_call))
                      }
                    >
                      <div
                        className=""
                        style={{
                          marginLeft: '16px',
                          paddingBottom: '8px',
                          fontWeight: 'bold'
                        }}
                      >
                        After these two emails they will receive a close loop
                        email, and then will be moved to Unable to Contact
                      </div>
                    </If>
                  </Card>
                </If>
              </If>
            </If>
          </Otherwise>
        </Choose>
      </Dialog>
    );
  }
}
