import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import moment from 'moment';

class LastUpdated extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired
  };

  getFirstSeenString = () => {
    const {organization: {updated_at = 0} = {}} = this.props;
    return `${moment(updated_at).fromNow()} (${new Date(
      updated_at
    ).getFullYear()})`;
  };

  render() {
    const {organization: {updated_at = 0} = {}} = this.props;
    return (
      <div className="data-wrapper last-updated">
        <div className="data-header">Last Updated</div>
        <Choose>
          <When condition={updated_at === 0}>
            <div className="data-content text empty">No data</div>
          </When>
          <Otherwise>
            <div className="data-content text">{this.getFirstSeenString()}</div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(LastUpdated);
