import {TextField} from 'material-ui';
import AddCircle from 'material-ui/svg-icons/content/add-circle';
import React from 'react';
import PropTypes from 'prop-types';

export default class EditSubTask extends React.Component {
  static propTypes = {
    task: PropTypes.object.isRequired,
    subTaskTitle: PropTypes.string.isRequired,
    updateSubTask: PropTypes.func.isRequired,
    subTask: PropTypes.object,
    handleSubmitSubTask: PropTypes.func
  };

  state = {
    subTaskTitle: this.props.subTaskTitle
  };

  handleAddSubTaskSubmit = () => {
    const {task} = this.props;
    const idNum = 'sub_tasks' in task ? task.sub_tasks.length : 0;
    const subTask = {
      id: `${task.id}-${idNum}`,
      taskId: task.id,
      title: this.state.subTaskTitle
    };
    this.setState({
      subTaskTitle: ''
    });
    return subTask;
  };

  handleEditSubTaskSubmit = () => {
    const {subTask, handleSubmitSubTask} = this.props;
    subTask.title = this.state.subTaskTitle;
    this.setState({
      subTaskTitle: ''
    });
    handleSubmitSubTask();
    return subTask;
  };

  handleAddSubTaskChange = (newValue) => {
    this.setState({
      subTaskTitle: newValue
    });
  };

  render() {
    const {updateSubTask, task, subTaskTitle} = this.props;
    const {subTaskTitle: subTaskTitleState} = this.state;
    return (
      <div className="add-subtask">
        <TextField
          hintText="Write Subtask"
          type="text"
          onChange={(event, newValue) => {
            this.handleAddSubTaskChange(newValue);
          }}
          value={subTaskTitleState}
          className={'add-subtask-field-container'}
          onKeyPress={(event) => {
            if (event.key === 'Enter' && subTaskTitleState !== '') {
              updateSubTask(
                task.category,
                task.id,
                subTaskTitle === ''
                  ? this.handleAddSubTaskSubmit()
                  : this.handleEditSubTaskSubmit()
              );
            }
          }}
        />
        <AddCircle
          color={
            subTaskTitleState === '' ? 'rgb(220,220,220)' : 'rgb(93, 171, 73)'
          }
          className="add-button"
          disabled={subTaskTitleState === ''}
          onClick={() => {
            if (subTaskTitleState !== '') {
              updateSubTask(
                task.category,
                task.id,
                subTaskTitle === ''
                  ? this.handleAddSubTaskSubmit()
                  : this.handleEditSubTaskSubmit()
              );
            }
          }}
        />
      </div>
    );
  }
}
