import React from 'react';
import {Link, withRouter} from 'react-router';
import PropTypes from 'prop-types';
import Tooltip from '../../Tooltip';
import {
  buildSelectionLine,
  sortLocations,
  buildInvestmentCategory,
  buildSelectionTag,
  getAverage
} from '../InvestorHelper';

class InvestmentCategory extends React.Component {
  static propTypes = {
    portfolio: PropTypes.object,
    showMaxLength: PropTypes.number.isRequired,
    updateSelectedTab: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired
  };

  state = {
    showTooltip: false,
    showFull: false,
    numberHiddenInfo: 0
  };

  toggleFullProfileClickHandler = () => {
    this.setState({
      showFull: !this.state.showFull
    });
  };
  hasError = () => {
    return true;
  };

  getLocationClassNames = () => {
    const {
      locations: {error}
    } = this.props;
    const classNames = ['data-content-list-item', 'text'];
    if (error) {
      classNames.push('red-flag');
    }
    return classNames.join(' ');
  };

  getInvestmentCategories = (portfolioAggregation, sectionType) => {
    const {showMaxLength, updateSelectedTab} = this.props;
    const {investor: {aggregations: {exits = 0} = {}} = {}} = this.props;
    const {numberHiddenInfo, showFull} = this.state;
    const investmentCategories = portfolioAggregation.reduce(
      (investmentCategoriesList, investment) => {
        return buildInvestmentCategory(
          investment,
          investmentCategoriesList,
          sectionType
        );
      },
      {}
    );
    const newNumberHiddenInfo = investmentCategories
      ? Math.max(Object.keys(investmentCategories).length - showMaxLength, 0)
      : 0;
    if (numberHiddenInfo !== newNumberHiddenInfo) {
      this.setState({numberHiddenInfo: newNumberHiddenInfo});
    }
    let sortedKeys = Object.keys(investmentCategories).sort((a, b) => {
      if (sectionType === 'investmentTypes') {
        if (a === 'other') return 1;
        if (b === 'other') return -1;
      }
      if (sectionType === 'fundingType') {
        if (a === 'seed' && b === 'pre_seed') return 1;
        if (b === 'seed' && a === 'pre_seed') return -1;
        if (a === 'seed' || a === 'pre_seed') return -1;
        if (b === 'seed' || b === 'pre_seed') return 1;
      }
      if (sectionType === 'topInvestment') {
        return (
          investmentCategories[b].totalRaised -
          investmentCategories[a].totalRaised
        );
      }
      if (sectionType === 'investmentYears') {
        return parseInt(b) - parseInt(a);
      }

      return ['investmentTypes', 'locations', 'tags'].includes(sectionType)
        ? investmentCategories[b].organizationIds.length -
            investmentCategories[a].organizationIds.length
        : investmentCategories[b].count - investmentCategories[a].count;
    });
    if (sectionType === 'topInvestment') {
      sortedKeys = sortedKeys.slice(0, 50);
    }
    if (sectionType === 'tags') {
      return (
        <div className="tags">
          <div className="tags-container">
            {sortedKeys
              .slice(
                0,
                showFull ? newNumberHiddenInfo + showMaxLength : showMaxLength
              )
              .map((seriesKey) => {
                return buildSelectionTag(
                  investmentCategories[seriesKey],
                  seriesKey,
                  sectionType,
                  updateSelectedTab
                );
              })}
          </div>
        </div>
      );
    } else {
      return (
        <React.Fragment key={'lines'}>
          <If condition={sectionType === 'investmentYears'}>
            <div style={{fontSize: '12px', display: 'flex'}}>
              <div>Year</div>
              <div className="spacer" />
              <div>New</div>
              <div className="spacer" />
              <div>Follow-on</div>
              <div className="spacer" />
              <div>Total</div>
            </div>
          </If>
          <If condition={sectionType === 'topInvestment'}>
            <div style={{display: 'flex'}}>
              <div>Exits</div>
              <div className="spacer" />
              <div>{exits}</div>
            </div>
          </If>
          {sortedKeys
            .slice(
              0,
              showFull ? newNumberHiddenInfo + showMaxLength : showMaxLength
            )
            .map((seriesKey) => {
              return buildSelectionLine(
                investmentCategories[seriesKey],
                seriesKey,
                sectionType,
                updateSelectedTab,
                investmentCategories
              );
            })}
        </React.Fragment>
      );
    }
  };

  render() {
    const {
      tabsContent: {portfolioAggregation: portfolioAggregation = []} = {},
      showMaxLength,
      locations: {locations = [], error} = {},
      sectionType,
      portfolioAggregationLoading,
      title
    } = this.props;
    const {showTooltip, showFull, numberHiddenInfo} = this.state;
    const bestLocation = locations.length
      ? locations.sort(sortLocations)[0]
      : '';
    return (
      <div
        className="data-wrapper founded"
        onMouseLeave={() => {
          if (this.hasError()) {
            this.setState({showTooltip: false});
          }
        }}
        onMouseEnter={() => {
          if (this.hasError()) {
            this.setState({showTooltip: true});
          }
        }}
        rel={(el) => (this._parent = el)}
      >
        <div className="data-header">{title}</div>
        <Choose>
          <When condition={portfolioAggregationLoading}>
            <div className="data-content text empty">Loading Data</div>
          </When>
          <When condition={!portfolioAggregation.length}>
            <div className="data-content text empty">No data</div>
          </When>
          <Otherwise>
            <Tooltip
              parent={this._parent}
              showTooltip={showTooltip}
              text={''}
            />
            {this.getInvestmentCategories(portfolioAggregation, sectionType)}
          </Otherwise>
        </Choose>
        <If condition={sectionType !== 'investmentTypes'}>
          <div className="person-grid-item toggle-full-profile-grid-item">
            <If condition={numberHiddenInfo > 0}>
              <div
                className={`data-content text ${showFull ? 'component' : ''}`}
              >
                <Link
                  style={{cursor: 'pointer'}}
                  onClick={this.toggleFullProfileClickHandler}
                >
                  {showFull
                    ? `Hide  All`
                    : `Show All (${numberHiddenInfo + showMaxLength})`}
                </Link>
              </div>
            </If>
          </div>
        </If>
      </div>
    );
  }
}

export default withRouter(InvestmentCategory);
