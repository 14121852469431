import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import AccessTime from 'material-ui/svg-icons/device/access-time';

import moment from 'moment';
import Tooltip from '../../../Tooltip';

import {getDataWrapperClassNames} from '../../../../lib/helpers';

class FoundedOn extends React.Component {
  static propTypes = {
    founded: PropTypes.object.isRequired,
    compressed: PropTypes.bool.isRequired
  };

  state = {
    showTooltip: false
  };

  hasError = () => {
    const {founded: {on = 0} = {}} = this.props;
    return new Date(on).getFullYear() < new Date().getFullYear() - 15;
  };

  getTooltipText = () => {
    const {compressed = false} = this.props;
    const text = [];
    if (compressed) {
      text.push('Founded On');
    }
    if (this.hasError()) {
      text.push('The company is older than 15 years');
    }
    return text.join('<br/>');
  };

  getFoundedOnClassNames = () => {
    const {founded: {on = 0, highlighted = false} = {}} = this.props;
    const classNames = ['data-content', 'text'];
    if (this.hasError()) {
      classNames.push('red-flag');
    } else if (highlighted) {
      classNames.push('highlighted-text');
    }
    return classNames.join(' ');
  };

  getFoundedOnString = () => {
    const {founded: {on = 0} = {}} = this.props;
    return `${this.isFirstSeenCloseToFoundedOn(5) ? 'At least ' : ''}${moment(
      on
    ).fromNow()} (${new Date(on).getFullYear()})`;
  };

  isFirstSeenCloseToFoundedOn = (daysDiff) => {
    const {
      founded: {on = 0, firstSeen = 0}
    } = this.props;
    const negativeFoundedDate = new Date(
      new Date(on).setDate(new Date(on).getDate() - daysDiff)
    );
    const positiveFoundedDate = new Date(
      new Date(on).setDate(new Date(on).getDate() + daysDiff)
    );
    const firstSeenDate = new Date(firstSeen);
    return (
      firstSeenDate >= negativeFoundedDate &&
      firstSeenDate <= positiveFoundedDate
    );
  };

  render() {
    const {
      founded: {on = 0, firstSeen = 0} = {},
      compressed = false
    } = this.props;
    const {showTooltip} = this.state;
    return (
      <div
        className={getDataWrapperClassNames(compressed, ['founded'])}
        onMouseLeave={() => {
          if (this.hasError() || compressed) {
            this.setState({showTooltip: false});
          }
        }}
        onMouseEnter={() => {
          if (this.hasError() || compressed) {
            this.setState({showTooltip: true});
          }
        }}
        rel={(el) => (this._parent = el)}
      >
        <div className="data-header">Founded</div>
        <Choose>
          <When condition={on === 0}>
            <div className="data-content text empty">No data</div>
          </When>
          <Otherwise>
            <Tooltip
              parent={this._parent}
              showTooltip={showTooltip}
              text={this.getTooltipText()}
            />
            <div className="data-icon">
              <AccessTime className="data-icon-svg" />
            </div>
            <div className={this.getFoundedOnClassNames()}>
              {this.getFoundedOnString()}
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(FoundedOn);
