import auth from './Auth';
import Config from '../config';
import request from 'superagent';

export default class ReconnectStore {
  constructor(organizationId) {
    this.url = `${Config.api.host}/organizations/${organizationId}/reconnect`;
  }

  reconnect(reconnect) {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .post(this.url)
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .send(reconnect)
          .set('Accept', 'application/json')
      )
      .then((response) => response.body);
  }

  getReconnect() {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(this.url)
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .set('Accept', 'application/json')
      )
      .then((response) => response.body)
      .catch((error) => {
        if (error.response && error.response.notFound) {
          return {};
        }
        throw error;
      });
  }

  removeReconnect() {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .delete(this.url)
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .set('Accept', 'application/json')
      )
      .then((response) => response.body);
  }
}
