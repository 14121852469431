import PropTypes from 'prop-types';
import React from 'react';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';
import CreateOrganizationHelper from '../../../lib/CreateOrganizationHelper';

class EditImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openEditDialog: false,
      errorMessage: null,
      imageSrc: null
    };

    this.createOrganizationHelper = new CreateOrganizationHelper();

    this.handleShowEditDialog = this.handleShowEditDialog.bind(this);
    this.handleCloseEditDialog = this.handleCloseEditDialog.bind(this);
    this.handleImageSrcChange = this.handleImageSrcChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      imageSrc: this.props.imageSrc
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.imageSrc &&
      this.state.imageSrc &&
      this.state.imageSrc !== nextProps.imageSrc
    ) {
      this.setState({
        imageSrc: nextProps.imageSrc
      });
    }
  }

  handleShowEditDialog() {
    this.setState({
      openEditDialog: true
    });
  }

  handleCloseEditDialog() {
    this.setState({
      openEditDialog: false
    });
  }

  handleImageSrcChange(event) {
    const errorMessage = this.createOrganizationHelper.validateField(
      null,
      event.target.value,
      'URL'
    );
    this.setState({
      imageSrc: event.target.value,
      errorMessage
    });
  }

  render() {
    const actions = [
      <RaisedButton
        key="cancel"
        className="assign-action"
        label="Cancel"
        style={{
          marginRight: '10px'
        }}
        onClick={this.handleCloseEditDialog}
      />,
      <RaisedButton
        key="confirm"
        children={<span style={{color: 'white'}}>CONFIRM</span>}
        className="assign-action confirm-edit-image"
        backgroundColor="#5DAB49"
        labelStyle={{
          color: 'white'
        }}
        disabled={!!this.state.errorMessage}
        onClick={() => {
          this.props.updateImageSrc(this.state.imageSrc);
          this.handleCloseEditDialog();
        }}
      />
    ];

    return (
      <div className="edit-image">
        <div className="image-container">
          <img
            src={this.props.imageSrc || 'http://via.placeholder.com/100x100'}
          />
        </div>
        <FlatButton
          className="edit-image-button"
          label="Edit"
          onClick={this.handleShowEditDialog}
        />
        <Dialog
          title="Edit image URL"
          actions={actions}
          modal={false}
          open={this.state.openEditDialog}
          onRequestClose={this.handleCloseEditDialog}
        >
          <TextField
            className="edit-image-url"
            name="edit-image-url"
            floatingLabelText="Image URL"
            value={this.state.imageSrc}
            onChange={this.handleImageSrcChange}
            errorText={this.state.errorMessage}
            fullWidth={true}
          />
        </Dialog>
      </div>
    );
  }
}

EditImage.propTypes = {
  imageSrc: PropTypes.string,
  updateImageSrc: PropTypes.func
};

export default EditImage;
