import React from 'react';
import PropTypes from 'prop-types';
import {FlatButton, TextField} from 'material-ui';
import AddCircleOutline from 'material-ui/svg-icons/content/add-circle';
import ActionDelete from 'material-ui/svg-icons/action/delete';

export default class EditUrls extends React.Component {
  static propTypes = {
    urls: PropTypes.array,
    handleUrlsChange: PropTypes.func.isRequired
  };

  state = {
    newUrl: ''
  };

  render() {
    const {urls = []} = this.props;

    return (
      <div className="edit-urls">
        {urls.map((url, i) => (
          <div key={i}>
            <TextField
              value={url}
              onChange={(event) => {
                urls[i] = event.target.value;
                this.props.handleUrlsChange(urls);
              }}
              name="url"
            />
            <FlatButton
              className="remove-url"
              label="Remove"
              labelStyle={{color: '#C30000'}}
              icon={<ActionDelete color={'#C30000'} />}
              onClick={() => {
                urls.splice(i);
                this.props.handleUrlsChange(urls);
              }}
            />
          </div>
        ))}
        <div>
          <TextField
            value={this.state.newUrl}
            onChange={(event) => {
              this.setState({
                newUrl: event.target.value
              });
            }}
            name="add-url"
          />
          <FlatButton
            label="Add Url"
            onClick={() => {
              urls.push(this.state.newUrl);
              this.props.handleUrlsChange(urls);
              this.setState({
                newUrl: ''
              });
            }}
            icon={<AddCircleOutline color={'#5DAB49'} />}
          />
        </div>
      </div>
    );
  }
}
