import React from 'react';
import PropTypes from 'prop-types';

import TextField from 'material-ui/TextField';

import {
  BuildInvestmentTypeStage,
  buildClassName
} from '../../../assign/InvestmentTypeStage';

import xss from 'xss';
import _ from 'underscore';
import {ActionViewHeadline} from 'material-ui/svg-icons/index.es';
import SelectField from 'material-ui/SelectField';
import {MenuItem} from 'material-ui';
import scorecardSections from '../../../../content/scorecardSections';

function getLabel(key) {
  switch (key) {
    case 'callType':
      return 'Call Type';
    case 'investmentType':
      return 'Investment Type';
    case 'investmentStage':
      return 'Investment Stage';
    case 'callWith':
      return 'Call With';
    case 'callTitle':
      return 'Call Title';
    case 'saas':
      return 'SaaS';
    case 'consumer':
      return 'Consumer Internet';
    case 'marketplace':
      return 'Marketplace';
    case 'other':
      return 'Other';
    case 'pre_product':
      return 'Pre Product';
    case 'pre_product_market_fit':
      return 'Pre Product Market Fit';
    case 'pre_marketing_at_scale':
      return 'Pre Marketing at Scale';
    default:
      return key;
  }
}

class ScorecardModalCallDetailsCallType extends React.Component {
  componentDidMount() {
    const {
      value: {types = [], selected = ''} = {},
      topTypes,
      otherTypes,
      selectCallType,
      updateScorecardCall
    } = this.props;

    [...topTypes, ...otherTypes].map((callType) => {
      if (callType.id === selected) {
        selectCallType(callType.id);
        updateScorecardCall({template_id: callType.id}, false);
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.callType !== this.props.callType) {
      this.render();
    }
  }

  render() {
    const {
      value: {types = [], selected = ''} = {},
      updateScorecardCall,
      disabled,
      callType,
      selectCallType,
      topTypes,
      otherTypes,
      scorecardModalEditIndex,
      emptyScorecardCall
    } = this.props;

    return (
      <div class="content-column">
        <div class="content-row">
          <p class="calls-label">Scorecard calls</p>
          <div class="content-column">
            {topTypes.map((type) => (
              <If condition={type.id !== 'adhoc'}>
                <div
                  key={type.id}
                  className={buildClassName(
                    selected === type.id,
                    false,
                    disabled
                  )}
                  onClick={
                    selected !== type.id && !disabled
                      ? () => {
                          updateScorecardCall({template_id: type.id});
                          selectCallType(type.id);
                        }
                      : null
                  }
                >
                  {type.label}
                </div>
              </If>
            ))}
          </div>
        </div>
        <div class="content-row" style={{padding: '0 25px'}}>
          <p class="calls-label">Non scorecard calls</p>
          <div
            class="content-column"
            style={{overflowX: 'initial', height: '76px'}}
          >
            <SelectField
              disabled={disabled}
              value={callType}
              menuStyle={{
                backgroundColor: '#ffffff',
                height: '70%',
                paddingLeft: '8px'
              }}
              floatingLabelStyle={{paddingLeft: '8px'}}
            >
              {otherTypes
                .sort((a, b) => a.label.localeCompare(b.label))
                .map((otherType) => {
                  return (
                    <MenuItem
                      value={otherType.id}
                      primaryText={otherType.label}
                      onClick={
                        selected !== otherType.id && !disabled
                          ? () => {
                              // emptyScorecardCall(scorecardModalEditIndex, [
                              //   'people',
                              //   'product',
                              //   'market',
                              //   'funding',
                              //   'risks'
                              // ]);
                              updateScorecardCall({template_id: otherType.id});
                              selectCallType(otherType.id);
                            }
                          : null
                      }
                    />
                  );
                })}
            </SelectField>
          </div>
        </div>
        <div class="content-row">
          <div class="content-column" style={{marginTop: '28px'}}>
            {topTypes.map((type) => (
              <If condition={type.id === 'adhoc'}>
                <div
                  key={type.id}
                  className={buildClassName(
                    selected === type.id,
                    false,
                    disabled
                  )}
                  onClick={
                    selected !== type.id && !disabled
                      ? () => {
                          // emptyScorecardCall(scorecardModalEditIndex, [
                          //   'people',
                          //   'product',
                          //   'market',
                          //   'funding',
                          //   'risks'
                          // ]);
                          updateScorecardCall({template_id: type.id});
                          selectCallType(type.id);
                        }
                      : null
                  }
                >
                  {type.label}
                </div>
              </If>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

function ScorecardModalCallDetailsCallWith(props) {
  const {value = '', handleChange, disabled} = props;
  return (
    <TextField
      className="call-details-content-call-with"
      value={value}
      onChange={handleChange}
      name="call-with"
      disabled={disabled}
    />
  );
}

export default class ScorecardModalCallDetails extends React.Component {
  static propTypes = {
    details: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    failedToLoadTemplates: PropTypes.bool.isRequired,
    handleCallDetailsChange: PropTypes.func.isRequired,
    handleCallWithChange: PropTypes.func.isRequired,
    handleCallTitleChange: PropTypes.func.isRequired,
    selectCallType: PropTypes.func.isRequired,
    callType: PropTypes.string.isRequired,
    emptyScorecardCall: PropTypes.func.isRequired,
    scorecardModalEditIndex: PropTypes.func.isRequired
  };

  buildClassName = (disabled) => {
    const classNames = ['scorecard-modal-call-details'];
    if (disabled) classNames.push('disabled');
    return classNames.join(' ');
  };

  splitTypes = (types) => {
    const topTypes = types.filter((type) =>
      [
        'formcall',
        'recommendationcall',
        'prequalificationcall',
        'adhoc'
      ].includes(type.id)
    );

    const otherTypes = types.filter(
      (type) =>
        ![
          'formcall',
          'recommendationcall',
          'prequalificationcall',
          'adhoc'
        ].includes(type.id)
    );

    return {
      topTypes,
      otherTypes
    };
  };

  render() {
    const {
      details,
      disabled,
      failedToLoadTemplates,
      handleCallDetailsChange,
      handleCallWithChange,
      handleCallTitleChange,
      callType,
      selectCallType,
      selectOtherCallType,
      emptyScorecardCall,
      scorecardModalEditIndex
    } = this.props;

    const {topTypes, otherTypes} = this.splitTypes(details['callType'].types);

    switch (this.props.callType) {
      case 'adhoc':
        return (
          <div className={this.buildClassName(disabled)}>
            {Object.entries(details).map(([key, value]) => {
              if (key !== 'callType' || !failedToLoadTemplates) {
                const label = getLabel(key);
                return (
                  <div className="call-details-row" key={key} id={key}>
                    <Choose>
                      <When condition={key === 'callType'}>
                        <If condition={!failedToLoadTemplates}>
                          <div className="label-column">{label}</div>
                          <div className="content-column">
                            <ScorecardModalCallDetailsCallType
                              value={value}
                              updateScorecardCall={handleCallDetailsChange}
                              disabled={disabled}
                              callType={callType}
                              selectCallType={selectCallType}
                              selectOtherCallType={selectOtherCallType}
                              topTypes={topTypes}
                              otherTypes={otherTypes}
                              scorecardModalEditIndex={scorecardModalEditIndex}
                              emptyScorecardCall={emptyScorecardCall}
                            />
                          </div>
                        </If>
                      </When>
                      <When condition={key === 'callWith'}>
                        <div className="label-column">{label}</div>
                        <div className="content-column">
                          <ScorecardModalCallDetailsCallWith
                            value={value}
                            handleChange={handleCallWithChange}
                            disabled={disabled}
                          />
                        </div>
                      </When>
                      <When condition={key === 'callTitle'}>
                        <div className="label-column">{label}</div>
                        <div className="content-column">
                          <TextField
                            value={value}
                            className="input"
                            underlineFocusStyle={{borderColor: '#337ab7'}}
                            onChange={handleCallTitleChange}
                            disabled={disabled}
                          />
                        </div>
                      </When>
                    </Choose>
                  </div>
                );
              }
            })}
          </div>
        );
        break;
      case 'customerreferences':
      case 'productworkshop':
      case 'enterpreneurreferences':
      case 'investorreferences':
      case 'peopleworkshop':
      case 'technologyworkshop':
      case 'commercialworkshop':
        return (
          <div className={this.buildClassName(disabled)}>
            {Object.entries(details).map(([key, value]) => {
              if (key !== 'callType' || !failedToLoadTemplates) {
                const label = getLabel(key);
                return (
                  <div className="call-details-row" key={key} id={key}>
                    <Choose>
                      <When condition={key === 'callType'}>
                        <If condition={!failedToLoadTemplates}>
                          <div className="label-column">{label}</div>
                          <div className="content-column">
                            <ScorecardModalCallDetailsCallType
                              value={value}
                              updateScorecardCall={handleCallDetailsChange}
                              disabled={disabled}
                              callType={callType}
                              selectCallType={selectCallType}
                              selectOtherCallType={selectOtherCallType}
                              topTypes={topTypes}
                              otherTypes={otherTypes}
                              scorecardModalEditIndex={scorecardModalEditIndex}
                              emptyScorecardCall={emptyScorecardCall}
                            />
                          </div>
                        </If>
                      </When>
                      <When condition={key === 'callWith'}>
                        <div className="label-column">{label}</div>
                        <div className="content-column">
                          <ScorecardModalCallDetailsCallWith
                            value={value}
                            handleChange={handleCallWithChange}
                            disabled={disabled}
                          />
                        </div>
                      </When>
                    </Choose>
                  </div>
                );
              }
            })}
          </div>
        );
        break;
      default:
        return (
          <div className={this.buildClassName(disabled)}>
            {Object.entries(details).map(([key, value]) => {
              if (key !== 'callType' || !failedToLoadTemplates) {
                const label = getLabel(key);
                return (
                  <div className="call-details-row" key={key} id={key}>
                    <Choose>
                      <When condition={key === 'callType'}>
                        <If condition={!failedToLoadTemplates}>
                          <div className="label-column">{label}</div>
                          <div className="content-column">
                            <ScorecardModalCallDetailsCallType
                              value={value}
                              updateScorecardCall={handleCallDetailsChange}
                              disabled={disabled}
                              callType={callType}
                              selectCallType={selectCallType}
                              selectOtherCallType={selectOtherCallType}
                              topTypes={topTypes}
                              otherTypes={otherTypes}
                              scorecardModalEditIndex={scorecardModalEditIndex}
                              emptyScorecardCall={emptyScorecardCall}
                            />
                          </div>
                        </If>
                      </When>
                      <When condition={key === 'investmentStage'}>
                        <div className="label-column">{label}</div>
                        <div className="content-column">
                          <BuildInvestmentTypeStage
                            value={value}
                            updateInvestmentInfo={handleCallDetailsChange}
                            selectedValue={value.stage}
                            disabled={disabled}
                            investmentInfo={'investment_stage'}
                          />
                        </div>
                      </When>
                      <When condition={key === 'callWith'}>
                        <div className="label-column">{label}</div>
                        <div className="content-column">
                          <ScorecardModalCallDetailsCallWith
                            value={value}
                            handleChange={handleCallWithChange}
                            disabled={disabled}
                          />
                        </div>
                      </When>
                    </Choose>
                  </div>
                );
              }
            })}
          </div>
        );
        break;
    }
  }
}
