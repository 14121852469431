import React from 'react';
import PropTypes from 'prop-types';
import Loading from '../loading/Loading';
import Contacts from '../organization/workflow/contacts/Contacts';
import OrganizationDetails from '../organization/workflow/organization-details/OrganizationDetails';
import OrganizationStore from '../../storage/OrganizationStore';
import _ from 'underscore';

class WorkflowTab extends React.Component {
  static propTypes = {
    organization: PropTypes.object,
    handleOrganizationChange: PropTypes.func.isRequired,
    tabsContent: PropTypes.object.isRequired
  };

  state = {};

  updateOrganization = (patches) => {
    this.organizationStore = new OrganizationStore(this.props.organization.id);
    this.organizationStore.updateModel(patches).then((result) => {
      this.props.handleOrganizationChange(result);
    });
  };

  render() {
    const {
      portfolioAggregationLoading,
      organization,
      tabsContent: {workflow: people = []} = {}
    } = this.props;
    return (
      <Choose>
        <When condition={portfolioAggregationLoading}>
          <Loading />
        </When>
        <Otherwise>
          <div id="workflow" className="workflow-content-container">
            <div className="first-section-content-container">
              <div className="left-column">
                <If condition={!_.isEmpty(organization)}>
                  <Contacts
                    primaryContactId={organization.primary_contact_id || ''}
                    updateOrganization={this.updateOrganization}
                    people={people}
                    {...this.props}
                  />

                  <OrganizationDetails organization={organization} />
                </If>
              </div>
              <div className="right-column">
                <div
                  style={{
                    color: '#337AB7',
                    fontSize: '30px',
                    textAlign: 'center',
                    marginTop: '200px'
                  }}
                >
                  {' '}
                  New cool features coming soon!
                </div>
              </div>
            </div>
          </div>
        </Otherwise>
      </Choose>
    );
  }
}

export default WorkflowTab;
