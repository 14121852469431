import React from 'react';
import PropTypes from 'prop-types';

import xss from 'xss';

import StructuredTextEditor from '../../../components/structured-text-editor/StructuredTextEditor';

export default class ScorecardModalSectionContentCallInput extends React.Component {
  static propTypes = {
    section: PropTypes.string.isRequired,
    sectionIndex: PropTypes.number.isRequired,
    formattedContent: PropTypes.string.isRequired,
    structuredContent: PropTypes.object.isRequired,
    noShow: PropTypes.bool.isRequired,
    relevantQuestions: PropTypes.array.isRequired,
    allQuestions: PropTypes.array.isRequired,
    handleChangeSection: PropTypes.func.isRequired,
    availableTemplates: PropTypes.bool.isRequired,
    editing: PropTypes.bool.isRequired,
    expanded: PropTypes.bool.isRequired,
    callId: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired
  };

  handleChange = (content) => {
    const {section, handleChangeSection, callId} = this.props;
    return new Promise((resolve) => {
      handleChangeSection(content, section, callId).then(() => {
        resolve();
      });
    });
  };

  render() {
    const {
      section,
      sectionIndex,
      formattedContent = '',
      structuredContent = {},
      noShow,
      relevantQuestions = [],
      allQuestions = [],
      availableTemplates,
      editing,
      expanded,
      callId,
      show
    } = this.props;

    return (
      <StructuredTextEditor
        savedFormattedContent={formattedContent}
        savedStructuredContent={structuredContent}
        noShow={noShow}
        handleChange={this.handleChange}
        relevantQuestions={relevantQuestions}
        allQuestions={allQuestions}
        useStructured={section === 'product'}
        canEdit={editing}
        section={section}
        sectionIndex={sectionIndex}
        expanded={expanded}
        callId={callId}
        show={show}
      />
    );
  }
}
