import PropTypes from 'prop-types';
import React from 'react';
import EmailFindingStore from '../../storage/EmailFindingStore';
import FlatButton from 'material-ui/FlatButton';
import LocationSearching from 'material-ui/svg-icons/device/location-searching';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import AddCircleOutline from 'material-ui/svg-icons/content/add-circle';
import Loading from '../loading/Loading';

class FindEmail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      finding: false
    };

    this.emailFinder = new EmailFindingStore();

    this.findEmail = this.findEmail.bind(this);
    this.handleCloseFoundDialog = this.handleCloseFoundDialog.bind(this);
    this.useEmail = this.useEmail.bind(this);
  }

  handleCloseFoundDialog() {
    this.setState({
      finding: false,
      found: null
    });
  }

  findEmail() {
    this.setState({
      finding: true
    });
    this.emailFinder
      .find(this.props.organizationId, this.props.personId)
      .then((result) => {
        this.setState({
          found: result,
          error: null
        });
      })
      .catch((error) => {
        this.setState({
          error: error,
          found: null
        });
      });
  }

  useEmail() {
    this.props.handleUpdateEmail(this.state.found.email);
    this.handleCloseFoundDialog();
  }

  render() {
    const found = this.state.found;
    const actions = [
      <RaisedButton
        className="assign-action"
        backgroundColor="#5DAB49"
        label="OK"
        style={{
          marginRight: '10px'
        }}
        onClick={this.handleCloseFoundDialog}
      />
    ];

    return (
      <div>
        <FlatButton
          key="find-email"
          className="find-email"
          label="Find"
          onClick={this.findEmail}
          icon={<LocationSearching color={'#5DAB49'} />}
        />
        <Dialog
          title="Find Emails"
          actions={actions}
          modal={false}
          open={this.state.finding}
          onRequestClose={this.handleCloseFoundDialog}
        >
          <If condition={this.state.found}>
            <div className="data-column">
              <label>Email</label>
              <div>
                <span>{found.email}</span>
                <FlatButton
                  className="use-email-button"
                  label="Use email"
                  onClick={this.useEmail}
                  icon={<AddCircleOutline color={'#5DAB49'} />}
                />
              </div>
            </div>
            <div className="data-column">
              <label>Score</label>
              <div>
                <span>{found.score}</span>
              </div>
            </div>
          </If>
          <If condition={this.state.error}>
            <div>
              There was a problem retrieving the emails: {this.state.error}
            </div>
          </If>
          <If condition={!this.state.found && !this.state.error}>
            <Loading />
          </If>
        </Dialog>
      </div>
    );
  }
}

FindEmail.propTypes = {
  organizationId: PropTypes.string.isRequired,
  personId: PropTypes.string.isRequired,
  handleUpdateEmail: PropTypes.func.isRequired
};

export default FindEmail;
