import React from 'react';
import PropTypes from 'prop-types';

import TimelineSectionToDo from './TimelineSectionToDo.js';
import TimelineSectionUpcoming from './TimelineSectionUpcoming.js';
import TimelineSectionPast from './TimelineSectionPast.js';

export default class TimelineBody extends React.Component {
  static propTypes = {
    timeline: PropTypes.object.isRequired
  };

  render() {
    const {timeline} = this.props;
    return (
      <div className="timeline-body">
        <TimelineSectionToDo todo={timeline.todo} {...this.props} />
        <TimelineSectionUpcoming upcoming={timeline.upcoming} {...this.props} />
        <div className="section-break" />
        <TimelineSectionPast past={timeline.past} {...this.props} />
      </div>
    );
  }
}
