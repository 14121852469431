import React from 'react';
import PropTypes from 'prop-types';

import LinearProgress from 'material-ui/LinearProgress';

import {Toolbar, ToolbarGroup} from 'material-ui/Toolbar';

import OverviewStageTable from './OverviewStageTable';

export default class OverviewStage extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    organizations: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    showToolbar: PropTypes.bool.isRequired
  };

  static defaultProps = {
    showToolbar: true,
    organizations: []
  };

  render() {
    const {client, title, organizations, loading, showToolbar} = this.props;

    return (
      <div className="overview-stage">
        <If condition={showToolbar}>
          <Toolbar>
            <ToolbarGroup>{title}</ToolbarGroup>
          </Toolbar>
        </If>
        <Choose>
          <When condition={loading}>
            <LinearProgress />
          </When>

          <When condition={organizations.length === 0}>
            <p className="overview-no-organizations">
              No organizations in this stage
            </p>
          </When>

          <When condition={client != null}>
            <OverviewStageTable {...this.props} />
          </When>
        </Choose>
      </div>
    );
  }
}
