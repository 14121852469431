import React from 'react';
import PropTypes from 'prop-types';

import digStages from '../../../../../config/streak_stages';
import digStates from '../../../../../content/digStates';

import xss from 'xss';

export default class TimelineEventDecision extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    previous: PropTypes.object,
    changed: PropTypes.string.isRequired,
    getUserName: PropTypes.func.isRequired
  };

  buildItem = (
    item = this.props.item,
    previous = this.props.previous,
    changed = this.props.changed
  ) => {
    const userName =
      this.props.getUserName(item.decision.user_id) || item.decision.user_id;
    const userRole = !!item.decision.user_role
      ? item.decision.user_role
      : !!item.decision.is_final
        ? 'partner'
        : 'analyst';
    //const isFinal = item.decision.is_final;
    return !item.decisions || item.decisions.length < 2
      ? this.getDecisionLabels(item.decision, userName, userRole)
      : this.getDecisionsLabels(
          changed,
          this.getChanges(changed, item, previous),
          userName,
          userRole,
          item.decision
        );
  };

  getChanges = (changed, item, previous) => {
    return {
      current:
        changed === 'state'
          ? this.getStateName(item.decision[changed])
          : changed === 'stage'
            ? this.getStageName(item.decision[changed])
            : item.decision[changed],
      previous: previous
        ? changed === 'state'
          ? this.getStateName(previous[changed])
          : changed === 'stage'
            ? this.getStageName(previous[changed])
            : previous[changed]
        : null
    };
  };

  getStateName = (stateKey) => {
    return digStates[stateKey];
  };

  getStageName = (stageKey) => {
    if (!stageKey) {
      return null;
    }
    return digStages.find((stage) => stage.key === stageKey).name || null;
  };

  getDecisionsLabels = (changed, changes, userName, userRole, decision) => {
    switch (changed) {
      case 'assigned_to':
        return this.getAssignedToLabel(userName, changes);
      case 'notes':
        return this.getNotesLabel(changes);
      case 'state':
        return this.getStateLabel(userName, userRole, changes, decision);
      case 'stage':
        return this.getStageLabel(changes, decision);
      case 'status':
        return this.getStatusLabel(changes);
    }
  };

  getDecisionLabels = (decision, userName, userRole = 'Analyst') => {
    const subLabel = decision.notes || null;
    const userRoleCapitalized =
      userRole.charAt(0).toUpperCase() + userRole.slice(1);

    if (decision.state && decision.stage) {
      return {
        label: `${userName} - ${userRoleCapitalized}: <b>${this.getStateName(
          decision.state
        )}</b> (Stage: ${this.getStageName(decision.stage)})`,
        subLabel: subLabel
      };
    } else if (decision.state) {
      return {
        label: `${userName} - ${userRoleCapitalized}: <b>${this.getStateName(
          decision.state
        )}</b>`,
        subLabel: subLabel
      };
    } else {
      return {
        label: `Stage: <b>${this.getStageName(decision.stage)}</b>`,
        subLabel: subLabel
      };
    }
  };

  getAssignedToLabel = (userName, assignedTo) => {
    return {
      label: `Assigned to: <b>${this.props.getUserName(
        assignedTo.current
      )}</b>`,
      subLabel: null
    };
  };

  getNotesLabel = (notes) => {
    return {
      label: notes.current ? `Notes: ${notes.current}` : `Notes updated`,
      subLabel: null
    };
  };

  getStateLabel = (userName, userRole, state, decision) => {
    const postfix = decision.stage
      ? !!decision.source_filters && decision.source_filters.id === 'ml_updated'
        ? ` (Kept: ${this.getStageName(decision.stage)})`
        : ` (${this.getStageName(decision.stage)})`
      : '';
    const userRoleCapitalized =
      userRole.charAt(0).toUpperCase() + userRole.slice(1);

    return {
      label:
        state.current === 'Unseen'
          ? `State Removed: <b>${state.previous}</b> ${postfix}`
          : `${userName} - ${userRoleCapitalized}: <b>${
              state.current
            }</b> ${postfix}`,
      subLabel: decision.notes
    };
  };

  getStageLabel = (stage, decision) => {
    return {
      label:
        stage.previous && stage.current
          ? `Stage: <b>${stage.previous}</b> > <b>${stage.current}</b>`
          : stage.current
            ? `Stage: <b>${stage.current}</b>`
            : `Stage removed: <b>${stage.previous}</b>`,
      subLabel: decision.notes
    };
  };

  getStatusLabel = (status) => {
    return {
      label: `Status: ${status.current}`,
      subLabel: null
    };
  };

  render() {
    const {label, subLabel} = this.buildItem();
    return (
      <div className="timeline-event-inner-wrapper">
        <h5
          className="timeline-event-label"
          dangerouslySetInnerHTML={{__html: xss(label)}}
        />
        <If condition={subLabel !== null}>
          <p className="timeline-event-sub-label">{subLabel}</p>
        </If>
        <div className="spacer" />
      </div>
    );
  }
}
