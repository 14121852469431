import auth from './Auth';
import config from '../config';
import request from 'superagent';

const {
  api: {host}
} = config;

export default class ScorecardStore {
  constructor(id) {
    this.url = `${host}/organizations/${id}/scorecard`;
  }

  getScorecard() {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(this.url)
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .set('Accept', 'application/json')
      )
      .then((response) => response.body);
  }

  getLatestCallDecisionRecommendation() {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .get(`${this.url}/calls/latest/decision_recommendations`)
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .set('Accept', 'application/json')
      )
      .then((response) => response.body);
  }

  createCall(call = {}) {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .post(`${this.url}/calls`)
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .set('Accept', 'application/json')
          .send(call)
      )
      .then((response) => response.body);
  }

  deleteCall(callId) {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .delete(`${this.url}/calls/${callId}`)
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .set('Accept', 'application/json')
      )
      .then((response) => response.body);
  }

  partialUpdateCall(callId, values) {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .patch(`${this.url}/calls/${callId}`)
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .set('Accept', 'application/json')
          .send(values)
      )
      .then((response) => response.body);
  }

  submitCallNoShow = (callId) => {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .post(`${this.url}/calls/${callId}/no_show`)
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .set('Accept', 'application/json')
          .send({})
      )
      .then((response) => response.body);
  };

  updateNotes(callId, notes) {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .put(`${this.url}/calls/${callId}/notes`)
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .set('Accept', 'application/json')
          .send(notes)
      )
      .then((response) => response.body);
  }

  updateNoteSection(callId, section, note) {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .put(`${this.url}/calls/${callId}/notes/${section}`)
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .set('Accept', 'application/json')
          .send(note)
      )
      .then((response) => response.body);
  }

  writeRecommendation(callId, recommendation) {
    if (!recommendation.user_id) {
      throw new Error('Must include user_id in recommendation');
    }
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .put(
            `${this.url}/calls/${callId}/recommendations/${
              recommendation.user_id
            }`
          )
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .set('Accept', 'application/json')
          .send(recommendation)
      )
      .then((response) => response.body);
  }

  writeDecision(callId, decision) {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .put(`${this.url}/calls/${callId}/decision`)
          .query({
            access_token: authData.token,
            client: authData.client
          })
          .set('Accept', 'application/json')
          .send(decision)
      )
      .then((response) => response.body);
  }
}
