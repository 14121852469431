import React from 'react';
import PropTypes from 'prop-types';

import Avatar from 'material-ui/Avatar';
import AutoComplete from 'material-ui/AutoComplete';
import IconButton from 'material-ui/IconButton';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import {Tabs, Tab} from 'material-ui/Tabs';
import {Toolbar, ToolbarGroup, ToolbarTitle} from 'material-ui/Toolbar';

import ActionSearch from 'material-ui/svg-icons/action/search';
import ContentBackspace from 'material-ui/svg-icons/content/backspace';
import ContentClear from 'material-ui/svg-icons/content/clear';
import ArrowForward from 'material-ui/svg-icons/navigation/arrow-forward';
import NavigationExpandMoreIcon from 'material-ui/svg-icons/navigation/expand-more';

import OverviewStage from './OverviewStage';

import auth from '../../storage/Auth';
import Organizations from '../../lib/Organizations';
import streakStages from '../../config/streak_stages';
import inreachTeam from '../../config/inreach_team';

import inreachIcon from '../../../images/InReach_Icono.png';
import {SelectField} from 'material-ui';
import organizationsStore from '../../storage/OrganizationsStore';
import PersonStore from '../../storage/PersonStore';
import _ from 'underscore';
import moment from 'moment';
import {activeInReachTeam} from '../../lib/helpers';

const ACTIVE_TEAM = activeInReachTeam();
const stageMap = streakStages.reduce(
  (acc, {key, name}) => ({...acc, [key]: name}),
  {}
);

const teamMap = inreachTeam.reduce(
  (acc, {email, name}) => ({...acc, [email]: name}),
  {}
);

const sum = (a, b) => a + b;

export default class Overview extends React.Component {
  static propTypes = {
    stages: PropTypes.arrayOf(PropTypes.string).isRequired,
    router: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    nextPathname: PropTypes.string.isRequired,
    organizationStage: PropTypes.func,
    orderBy: PropTypes.string,
    order: PropTypes.string
  };

  static defaultProps = {
    customSearchComponent: <ToolbarGroup />,
    organizationStage: (organization, client, now) => {
      return (
        Organizations.latestFinalOrInvestorDecision(organization, client) || {}
      ).stage;
    },
    orderBy: 'decisions.updated_at',
    order: 'DESC'
  };

  state = {
    client: null,
    email: null,
    organizations: this.props.stages.reduce(
      (acc, stage) => ({...acc, [stage]: []}),
      {}
    ),
    people: {},
    loading: false,
    loadingPeople: false,
    totals: this.props.stages.reduce(
      (acc, stage) => ({...acc, [stage]: 0}),
      {}
    ),
    name: this.props.location.query.name || '',
    error: null
  };

  handleStageChange = (newStage) => {
    const {stage, ...query} = this.props.location.query;

    this.props.router.push({
      pathname: this.props.location.pathname,
      query: newStage == null ? query : {...query, stage: newStage}
    });
  };

  handleAssigneeChange = (newAssignedTo) => {
    const {assigned_to, ...query} = this.props.location.query;

    this.props.router.push({
      pathname: this.props.location.pathname,
      query:
        newAssignedTo == null ? query : {...query, assigned_to: newAssignedTo}
    });
  };

  handleOrganizerChange = (newOrganizer) => {
    const {organizer, ...query} = this.props.location.query;

    this.props.router.push({
      pathname: this.props.location.pathname,
      query: newOrganizer == null ? query : {...query, organizer: newOrganizer}
    });
  };

  handleNameChange = (name) => {
    this.setState({name});
  };

  handleNameKeyPress = (e) => {
    const ENTER_KEY = 13;
    if (e.charCode === ENTER_KEY) {
      this.searchByName();
    }
  };

  handleClearName = () => {
    this.setState({name: ''}, this.searchByName);
  };

  searchByName = () => {
    const {name, ...query} = this.props.location.query;
    const {name: newName} = this.state;

    this.props.router.push({
      pathname: this.props.location.pathname,
      query: newName === '' ? query : {...query, name: newName}
    });
  };

  loadOrganizations = (queryParams) => {
    this.setState({loading: true, loadingPeople: true});
    const query = Object.assign({}, queryParams, {
      stage: this.props.stages.join(','),
      order_by: this.props.orderBy,
      order: this.props.order
    });
    if (query.assignedTo) {
      delete Object.assign(query, {['assigned_to']: query['assignedTo']})[
        'assignedTo'
      ];
    }

    organizationsStore.initialize({
      searchParams: query
    });

    organizationsStore
      .fetchAll()
      .then(() => {
        const organizations = organizationsStore.getAll() || [];
        this.setStateWithUpdatedOrganizations(organizations);
        this.loadPeople(
          organizations
            .filter((organization) => !!organization.primary_contact_id)
            .map((organization) => organization.primary_contact_id)
        );
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          loading: false,
          error: `Failed to load organizations: ${error.message}`
        });
      });
  };

  setStateWithUpdatedOrganizations = () => {
    const organizations = organizationsStore.getAll() || [],
      now = moment();
    const organizationsByStage = organizations
      .map((organization) => {
        const stage = this.props.organizationStage(
          organization,
          this.state.client,
          now
        );
        return {stage, organization};
      })
      .reduce((acc, entry) => {
        if (entry.stage in acc) {
          acc[entry.stage].push(entry.organization);
        } else {
          console.log(
            `Organization: ${entry.organization.id} is in stage: ${entry.stage}`
          );
        }
        return acc;
      }, this.props.stages.reduce((acc, stage) => ({...acc, [stage]: []}), {}));
    const totals = Object.entries(organizationsByStage || {})
      .map(([stage, organizations]) => [stage, organizations.length])
      .reduce((acc, [stage, total]) => {
        acc[stage] = total;
        return acc;
      }, {});
    this.setState({
      organizations: organizationsByStage,
      loading: false,
      totals
    });
  };

  loadPeople = (ids) => {
    this.loadPeopleBatch(ids, [])
      .then((people = []) => {
        this.setState({
          people: people
            .map((person) => [person.id, person])
            .reduce((acc, [id, person]) => {
              acc[id] = person;
              return acc;
            }, {}),
          loadingPeople: false
        });
      })
      .catch(console.error);
  };

  loadPeopleBatch = (ids, people) => {
    if (ids.length < 1) {
      return Promise.resolve(people);
    }
    const page = ids.splice(0, 50);
    return new PersonStore(page)
      .getModels()
      .then((newPeople = []) => people.concat(newPeople))
      .then((allPeople) => this.loadPeopleBatch(ids, allPeople));
  };

  componentDidMount() {
    const {query} = this.props.location;

    if (query.name) {
      this.setState({name: query.name});
    }

    auth
      .getAuthData()
      .then(({client, profile: {email}}) =>
        this.setState({client, email}, () =>
          this.loadOrganizations(this.props.location.query)
        )
      );
  }

  componentWillReceiveProps(nextProps) {
    const nextQuery = _.omit(nextProps.location.query, 'stage');
    const query = _.omit(this.props.location.query, 'stage');

    this.setState({name: nextQuery.name || ''});

    if (!_.isEqual(nextQuery, query)) {
      this.loadOrganizations(nextQuery);
    }
  }

  updateOrganization = () => {
    this.setStateWithUpdatedOrganizations();
  };

  render() {
    const {
      location: {
        query: {
          assigned_to: assignedTo = null,
          organizer = null,
          name = '',
          stage = null
        },
        pathname
      },
      stages,
      nextPathname
    } = this.props;
    const {
      client,
      loading,
      organizations,
      people,
      loadingPeople,
      totals,
      error,
      view
    } = this.state;

    if (!client) {
      return null;
    }

    const count = Object.values(totals).reduce(sum, 0);

    const organizationNames =
      stage != null
        ? organizations[stage].map((o) => o.name)
        : Object.values(organizations).reduce(
            (acc, cur) => [...acc, ...cur.map((o) => o.name)],
            []
          );

    return (
      <div className="overview">
        <Toolbar className="overview-people-selector">
          <ToolbarGroup>
            <ToolbarTitle text="Assignee" />
            <ToolbarTitle
              className="overview-title"
              text={teamMap[assignedTo] || 'Everyone'}
            />
            <IconMenu
              disabled={loading}
              value={assignedTo}
              iconButtonElement={
                <IconButton>
                  <NavigationExpandMoreIcon />
                </IconButton>
              }
            >
              <MenuItem
                primaryText="Everyone"
                value={null}
                leftIcon={<Avatar src={inreachIcon} />}
                onClick={() => this.handleAssigneeChange(null)}
              />
              {ACTIVE_TEAM.map((tm) => (
                <MenuItem
                  key={tm.email}
                  primaryText={tm.name}
                  value={tm.email}
                  leftIcon={<Avatar src={tm.imageUrl} />}
                  onClick={() => this.handleAssigneeChange(tm.email)}
                />
              ))}
            </IconMenu>

            <ToolbarTitle text="Organizer" />
            <ToolbarTitle
              className="overview-title"
              text={teamMap[organizer] || 'Everyone'}
            />

            <IconMenu
              disabled={loading}
              value={organizer}
              iconButtonElement={
                <IconButton>
                  <NavigationExpandMoreIcon />
                </IconButton>
              }
            >
              <MenuItem
                primaryText="Everyone"
                value={null}
                leftIcon={<Avatar src={inreachIcon} />}
                onClick={() => this.handleOrganizerChange(null)}
              />
              {ACTIVE_TEAM.map((tm) => (
                <MenuItem
                  key={tm.email}
                  primaryText={tm.name}
                  value={tm.email}
                  leftIcon={<Avatar src={tm.imageUrl} />}
                  onClick={() => this.handleOrganizerChange(tm.email)}
                />
              ))}
            </IconMenu>
          </ToolbarGroup>
          <ToolbarGroup>
            <AutoComplete
              id="overview-organization-name"
              name="overview-organization-name"
              hintText="Organization name"
              onKeyPress={this.handleNameKeyPress}
              onUpdateInput={this.handleNameChange}
              dataSource={organizationNames}
              searchText={this.state.name}
              filter={AutoComplete.fuzzyFilter}
              onNewRequest={this.searchByName}
              disabled={loading}
            />
            <IconButton
              onClick={this.searchByName}
              disabled={this.state.name === '' || loading}
            >
              <ActionSearch />
            </IconButton>
            <IconButton
              onClick={this.handleClearName}
              disabled={this.state.name === '' || loading}
            >
              <ContentBackspace />
            </IconButton>
          </ToolbarGroup>
          <ToolbarGroup>
            <ToolbarTitle text="Clear all" />
            <IconButton
              onClick={() => {
                this.props.router.replace(pathname);
              }}
            >
              <ContentClear />
            </IconButton>
          </ToolbarGroup>
          <ToolbarGroup>
            <IconButton
              onClick={() => {
                const {stage, ...query} = this.props.location.query;
                this.props.router.push({
                  pathname: nextPathname,
                  query: query
                });
              }}
            >
              <ArrowForward />
            </IconButton>
          </ToolbarGroup>
        </Toolbar>

        <Tabs
          className="overview-stage-buttons"
          inkBarStyle={{
            backgroundColor: '#337ab7'
          }}
          value={stage}
          onChange={this.handleStageChange}
        >
          <Tab value={null} label={`All (${count})`} />
          {stages.map((s) => (
            <Tab
              disabled={loading}
              key={s}
              value={s}
              label={loading ? stageMap[s] : `${stageMap[s]} (${totals[s]})`}
            />
          ))}
        </Tabs>
        <div className="overview-stage-mobile">
          <SelectField
            className="overview-stage-dropdown"
            onChange={(event, index, value) => {
              this.handleStageChange(value);
            }}
            value={stage}
            style={{width: '94%'}}
            labelStyle={{color: '#ffffff', marginLeft: '24px'}}
          >
            <MenuItem value={null} primaryText={`All (${count})`} />
            {stages.map((s) => {
              return (
                <MenuItem
                  key={s}
                  value={s}
                  primaryText={
                    loading ? stageMap[s] : `${stageMap[s]} (${totals[s]})`
                  }
                />
              );
            })}
          </SelectField>
        </div>
        <If condition={error}>
          <div className="overview-error">{error}</div>
        </If>
        <div className="overview-stages">
          <If condition={stage == null}>
            {stages.map((stage) => (
              <OverviewStage
                key={stage}
                client={client}
                title={stageMap[stage]}
                organizations={organizations[stage]}
                people={people}
                loading={loading}
                loadingPeople={loadingPeople}
                view={view}
                updateOrganization={this.updateOrganization}
                {...this.props}
              />
            ))}
          </If>

          <If condition={stage != null}>
            <OverviewStage
              client={client}
              title={stageMap[stage]}
              organizations={organizations[stage]}
              people={people}
              loading={loading}
              loadingPeople={loadingPeople}
              view={view}
              showToolbar={false}
              updateOrganization={this.updateOrganization}
              {...this.props}
            />
          </If>
        </div>
      </div>
    );
  }
}
