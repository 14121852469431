import React from 'react';
import RefreshIndicator from 'material-ui/RefreshIndicator';
import FundingTrackerStatsStore from '../../storage/FundingTrackerStatsStore';
import AppBar from 'material-ui/AppBar';
import Paper from 'material-ui/Paper';
import MarketSharePieChart from './funding_tracker/MarketSharePieChart';

export default class FundingTrackerStats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      updating: false,
      accessType: null,
      client: null,
      validationMarketShare: [],
      coverageMarketShare: []
    };

    this.fundingTrackerStatsStore = new FundingTrackerStatsStore();
  }

  componentDidMount() {
    this.fundingTrackerStatsStore
      .get()
      .then((body) => {
        this.setState({
          loading: false,
          coverageMarketShare: body[0],
          validationMarketShare: body[1]
        });
      })
      .catch((err) => {
        console.error('Error while retrieving data', err);
        this.setState({loading: false});
      });
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="loading-container">
          <RefreshIndicator
            size={50}
            loadingColor={'#337ab7'}
            status="loading"
            left={0}
            top={0}
            style={{position: 'relative', margin: 'auto'}}
          />
        </div>
      );
    }

    return (
      <div id="stats" className="inreach-bg">
        <div className="container">
          <AppBar
            className="appBar"
            title="Funding Tracker"
            style={{backgroundColor: 'white'}}
            titleStyle={{color: '#5DAB49'}}
            iconClassNameLeft="none"
          />
          <div className="container">
            <Paper zDepth={1} className="paper-flex">
              <AppBar
                className="appBar"
                style={{backgroundColor: '#337ab7'}}
                title={'Market Shares'}
                iconClassNameLeft="none"
              />

              <div className={'gauge-container'}>
                <MarketSharePieChart
                  title={'Market Share - Coverage'}
                  subtitle={
                    'Percentage of timely decisions made on companies that raised a relevant early-stage round from top tier investors and peers.'
                  }
                  marketShareStats={this.state.coverageMarketShare}
                  color={'#980000'}
                />
                <MarketSharePieChart
                  title={'Market Share - Validation'}
                  subtitle={
                    'Percentage of timely decisions made on companies that raised a later-stage round (Series A or after) from top tier investors.\n'
                  }
                  marketShareStats={this.state.validationMarketShare}
                  color={'#f1c232'}
                />
              </div>
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}
