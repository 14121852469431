import diff from 'deep-diff';

export default class PatchHelper {
  static getOp(type, value, itemType) {
    switch (type) {
      case 'E':
        return value ? 'replace' : 'remove';
      case 'A':
        return itemType && itemType.kind === 'N'
          ? 'add'
          : value
            ? 'replace'
            : 'remove';
      case 'N':
        return 'replace';
      case 'D':
        return 'remove';
    }
  }

  static getPatchDataFromDiffData(initialData, modifiedData) {
    const diffedData = diff(initialData, modifiedData);
    diffedData.reverse();
    return diffedData.map((fieldDiff) => {
      const value = fieldDiff.item ? fieldDiff.item.rhs : fieldDiff.rhs,
        index = fieldDiff.item && fieldDiff.index ? fieldDiff.index : null,
        rootPath = fieldDiff.path[0],
        joinedPath = `/${fieldDiff.path.join('/')}`,
        path = index !== null ? `${joinedPath}/${index}` : joinedPath,
        initAsArray = !initialData[rootPath]
          ? Array.isArray(modifiedData[rootPath]) && fieldDiff.kind === 'A'
          : false;
      return {
        op: PatchHelper.getOp(fieldDiff.kind, value, fieldDiff.item),
        path,
        value: initAsArray ? [value] : value
      };
    });
  }
}
