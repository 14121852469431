import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import Tooltip from '../../Tooltip';
import DecisionsIcon from 'material-ui/svg-icons/action/thumbs-up-down';
import _ from 'underscore';

class DecisionsPreview extends React.Component {
  static propTypes = {
    investor: PropTypes.object.isRequired
  };
  state = {
    showTooltip: false
  };

  hasError = () => {
    return true;
  };

  render() {
    const {
      investor: {
        aggregations,
        aggregations: {workflow: {seen = 0, unseen = 0} = {}} = {}
      } = {}
    } = this.props;
    const {showTooltip} = this.state;
    return (
      <div
        className="data-wrapper decisions compressed"
        onMouseLeave={() => {
          if (this.hasError()) {
            this.setState({showTooltip: false});
          }
        }}
        onMouseEnter={() => {
          if (this.hasError()) {
            this.setState({showTooltip: true});
          }
        }}
        rel={(el) => (this._parent = el)}
      >
        <div className="data-icon">
          <DecisionsIcon className="data-icon-svg" />
        </div>
        <Tooltip
          parent={this._parent}
          showTooltip={showTooltip}
          text="Decisions"
        />
        <Choose>
          <When
            condition={_.isEmpty(aggregations) || (seen === 0 && unseen === 0)}
          >
            <div className="data-content text empty">No data</div>
          </When>
          <Otherwise>
            <div className="preview-box">
              <div className="invesor-profile-row">
                <div className="data-content text invesor-profile-div">
                  Seen:
                </div>
                <div className="spacer" />
                <div>{seen}</div>
              </div>
              <div className="invesor-profile-row">
                <div className="data-content text invesor-profile-div">
                  Unseen:
                </div>
                <div className="spacer" />
                <div>{unseen}</div>
              </div>
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(DecisionsPreview);
