import React from 'react';
import PropTypes from 'prop-types';

import Autosuggest from 'react-autosuggest';
import getCaretCoordinates from 'textarea-caret';

import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Dialog from 'material-ui/Dialog';

import {capitalizeString} from '../../lib/helpers';

const getSectionSuggestions = ({reasons}) => reasons;

const renderSectionTitle = ({title = null}) => {
  if (title === null) {
    return null;
  }
  return <div className="reason-input-suggestion-section-title">{title}</div>;
};

const getSuggestionValue = ({id}) => id;

const renderSuggestion = ({title}) => (
  <div className="reason-input-suggestion">{title}</div>
);

const generateId = (title) =>
  title
    .trim()
    .toLowerCase()
    .replace(/ /g, '_');

const convertReason = (type, reason) => {
  // The work I started on showing the title
  /*switch (type) {
    case 'display':
      const hashtagIndexes = getCharacterIndexes('#', reason);
      hashtagIndexes.forEach((index) => {
        const structuredReasonId = getStructuredReasonId(index, reason);
        const {title = null} =
          structuredReasons.find(({id}) => id === structuredReasonId) || {};
        if (title !== null) {
          reason = reason.replace(`#${structuredReasonId}`, title);
        }
      });
      break;
    case 'data':
      break;
  }*/
  return reason;
};

const getCharacterIndexes = (character, str) => {
  const indexes = [];
  let i = 0;
  while (i <= str.length - 1) {
    if (str.substring(i, i + 1) === character) {
      indexes.push(i);
    }
    i++;
  }
  return indexes;
};

const getStructuredReasonId = (index, reason) => {
  const structuredReason = reason.substring(index + 1);
  return structuredReason.substring(
    0,
    structuredReason.indexOf(' ') || structuredReason.length
  );
};

export default class ReasonInput extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    structuredReasons: PropTypes.array.isRequired,
    reason: PropTypes.string.isRequired,
    handleReasonChange: PropTypes.func.isRequired,
    handleAddStructuredReason: PropTypes.func.isRequired,
    handleDeleteStructuredReason: PropTypes.func.isRequired,
    useStructured: PropTypes.bool.isRequired,
    source: PropTypes.string.isRequired,
    scorecardReasonType: PropTypes.string,
    commentOnly: PropTypes.bool
  };

  state = {
    structuredReasonActive: false,
    structuredReasonValue: '',
    structuredReasonSuggestions: [],
    showingAllStructuredReasons: false,
    showAddNewStructuredReasonModal: false,
    newStructuredReasonTitle: '',
    newStructuredReasonCategory: ''
  };

  getStructuredReason = (structuredReasonId) => {
    const {structuredReasons} = this.props;
    return structuredReasons.find(
      ({title}) => generateId(title) === structuredReasonId
    );
  };

  getTextFieldElement = () => {
    const {source = 'customize-campaign'} = this.props;
    return document.getElementById(`${source}-reason-input-field`);
  };

  handleReasonChange = (inputType, reason) => {
    const {
      handleReasonChange,
      handleDeleteStructuredReason,
      reason: previousReason,
      useStructured = true
    } = this.props;
    if (
      inputType === 'deleteContentBackward' &&
      this.isWordBeforeCaretStructuredReason(reason)
    ) {
      const structuredReasonIdBeforeCaret = this.getStructuredReasonIdBeforeCaret(
        reason
      );
      handleDeleteStructuredReason(structuredReasonIdBeforeCaret);
      handleReasonChange(
        reason.replace(`#${structuredReasonIdBeforeCaret}`, '')
      );
    } else {
      handleReasonChange(reason);
      if (useStructured) {
        if (reason.substring(reason.length - 1) === '#') {
          this.setStructuredReasonActive(true);
        } else {
          this.setStructuredReasonActive(false);
        }
      }
    }
  };

  isWordBeforeCaretStructuredReason = (reason) => {
    const textField = this.getTextFieldElement();
    let index = textField.selectionStart;
    while (index >= 0) {
      const charBeforeIndex = reason.substring(index - 1, index);
      if (charBeforeIndex === ' ' || charBeforeIndex === '\n') {
        return false;
      } else if (charBeforeIndex === '#') {
        return true;
      }
      index--;
    }
    return false;
  };

  getStructuredReasonIdBeforeCaret = (reason) => {
    const textField = this.getTextFieldElement();
    let index = textField.selectionStart;
    while (index >= 0) {
      const charBeforeIndex = reason.substring(index - 1, index);
      if (charBeforeIndex === '#' || index - 1 === 0) {
        return reason.substring(
          index,
          reason.substring(
            textField.selectionStart - 1,
            textField.selectionStart
          ) === ' '
            ? textField.selectionStart - 1
            : textField.selectionStart
        );
      }
      index--;
    }
  };

  setStructuredReasonActive = (active = !this.state.structuredReasonActive) => {
    this.setState(
      {
        structuredReasonActive: active,
        structuredReasonValue: '',
        structuredReasonSuggestions: [],
        showingAllStructuredReasons: false
      },
      () => {
        if (active) {
          document.getElementById('structured-reason-input-field').focus();
        } else {
          setTimeout(() => {
            this.getTextFieldElement().focus();
          }, 100);
        }
      }
    );
  };

  handleStructuredReasonChange = (value) => {
    // Handles any change to the structured reason textbox
    if (value !== this.state.structuredReasonValue) {
      this.setState({
        structuredReasonValue: value,
        showingAllStructuredReasons: false
      });
    }
  };

  handleStructuredReasonKeyDown = ({nativeEvent: {keyCode}}) => {
    // Handles disabling the structured reason textbox if the user presses backspace when the field is empty
    if (keyCode === 8 && this.state.structuredReasonValue === '') {
      const {reason, handleReasonChange} = this.props;
      handleReasonChange(reason.substring(0, reason.length - 1));
      this.setStructuredReasonActive(false);
    }
  };

  getAutoSuggestPositioning = () => {
    const {source = 'customize-campaign'} = this.props;
    const textField = this.getTextFieldElement();
    let {top, left} = getCaretCoordinates(textField, textField.selectionEnd);
    if (top >= textField.clientHeight - 16) {
      top = textField.clientHeight - 16;
    }
    const topBuffer =
      source === 'customize-campaign' ? 9 : source === 'scorecard' ? -4 : 0;
    const inputWidth = document.getElementById(`${source}-reason-input-field`)
      .offsetWidth;
    if (left + 250 > inputWidth) {
      setTimeout(() => {
        document.getElementById(
          'react-autowhatever-1'
        ).style.left = `${inputWidth - (left + 250)}px`;
      }, 10);
    }
    return {
      left: `${left - 1}px`,
      top: `${top + topBuffer}px`
    };
  };

  handleSuggestionsFetchRequested = ({value}) => {
    this.setState({
      structuredReasonSuggestions: this.getSuggestions(value)
    });
  };

  getSuggestions = (value) => {
    const {
      organization: {investment_type: investmentType = ''},
      structuredReasons = []
    } = this.props;
    const {showingAllStructuredReasons} = this.state;

    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const newStructuredReasons =
      inputLength === 0
        ? []
        : structuredReasons.reduce(
            (
              newStructuredReasons,
              {title, investment_types = [], category}
            ) => {
              if (
                investmentType === '' ||
                investment_types.includes(investmentType)
              ) {
                if (
                  title.toLowerCase().indexOf(inputValue) > -1 ||
                  category.indexOf(inputValue) > -1 ||
                  showingAllStructuredReasons
                ) {
                  const section =
                    newStructuredReasons.find(
                      ({title}) => title === capitalizeString(category)
                    ) || null;
                  if (section !== null) {
                    section.reasons.push({
                      id: generateId(title),
                      title,
                      category
                    });
                  } else {
                    newStructuredReasons.push({
                      title: capitalizeString(category),
                      reasons: [
                        {
                          id: generateId(title),
                          title,
                          category
                        }
                      ]
                    });
                  }
                }
              }
              return newStructuredReasons;
            },
            []
          );
    if (!showingAllStructuredReasons) {
      newStructuredReasons.push({
        reasons: [
          {
            id: 'show_all',
            title: 'Show all structured reasons'
          }
        ]
      });
    }
    newStructuredReasons.push({
      reasons: [
        {
          id: 'add_new',
          title: 'Add new structured reason'
        }
      ]
    });
    return newStructuredReasons;
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      structuredReasonSuggestions: []
    });
  };

  handleSuggestionSelected = (event, {suggestionValue}) => {
    switch (suggestionValue) {
      case 'show_all':
        const {structuredReasonValue} = this.state;
        this.setState(
          {
            showingAllStructuredReasons: true
          },
          () => {
            this.handleSuggestionsFetchRequested({
              value: structuredReasonValue
            });
          }
        );
        break;
      case 'add_new':
        this.toggleShowAddNewStructuredReasonModal(true);
        break;
      default:
        this.handleAddStructuredReasonToReason(suggestionValue);
        break;
    }
  };

  handleAddStructuredReasonToReason = (structuredReason) => {
    const {
      reason = '',
      handleReasonChange,
      handleAddStructuredReason
    } = this.props;
    handleReasonChange(`${reason}${structuredReason} `);
    handleAddStructuredReason({
      ...this.getStructuredReason(structuredReason),
      id: structuredReason,
      is_new: false
    });
    this.setStructuredReasonActive(false);
  };

  toggleShowAddNewStructuredReasonModal = (
    show = !this.state.showAddNewStructuredReasonModal
  ) => {
    this.setState({
      showAddNewStructuredReasonModal: show,
      newStructuredReasonTitle: '',
      newStructuredReasonCategory: ''
    });
  };

  handleAddNewStructuredReasonTitleChange = (title) => {
    this.setState({
      newStructuredReasonTitle: title
    });
  };

  handleAddNewStructuredReasonCategoryChange = (category) => {
    this.setState({
      newStructuredReasonCategory: category
    });
  };

  handleAddNewStructuredReasonCancelClick = () => {
    this.toggleShowAddNewStructuredReasonModal(false);
    document.getElementById('structured-reason-input-field').focus();
  };

  handleAddNewStructuredReasonAddClick = () => {
    const {handleAddStructuredReason} = this.props;
    const {
      newStructuredReasonTitle = '',
      newStructuredReasonCategory = ''
    } = this.state;
    const trimmedTitle = newStructuredReasonTitle.trim();
    const id = generateId(trimmedTitle);
    this.handleAddStructuredReasonToReason(id);
    handleAddStructuredReason({
      title: trimmedTitle,
      category: newStructuredReasonCategory,
      id,
      is_new: true
    });
    this.toggleShowAddNewStructuredReasonModal(false);
  };

  render() {
    const {
      reason,
      useStructured = false,
      source = 'customize-campaign',
      scorecardReasonType = '',
      commentOnly
    } = this.props;
    const {
      structuredReasonActive = false,
      structuredReasonValue = '',
      structuredReasonSuggestions = [],
      showAddNewStructuredReasonModal = false,
      newStructuredReasonTitle = '',
      newStructuredReasonCategory = ''
    } = this.state;
    const textInputPlaceholder = commentOnly
      ? 'Insert your comment'
      : `Why are you ${
          scorecardReasonType === 'recommendation' ? 'recommending' : 'deciding'
        } this?`;
    return (
      <div className={`reason-input ${source}`}>
        <If condition={useStructured && structuredReasonActive}>
          <div
            className="autosuggest-custom-container"
            style={this.getAutoSuggestPositioning()}
          >
            <Autosuggest
              multiSection={true}
              getSectionSuggestions={getSectionSuggestions}
              renderSectionTitle={renderSectionTitle}
              suggestions={structuredReasonSuggestions}
              onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
              onSuggestionSelected={this.handleSuggestionSelected}
              onSuggestionHighlighted={({suggestion}) => {
                this.handleStructuredReasonChange(
                  suggestion !== null ? suggestion.id : ''
                );
              }}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              alwaysRenderSuggestions={true}
              //highlightFirstSuggestion={true}
              inputProps={{
                value: structuredReasonValue,
                onChange: (event, {newValue}) =>
                  this.handleStructuredReasonChange(newValue),
                onKeyDown: this.handleStructuredReasonKeyDown,
                id: 'structured-reason-input-field',
                name: 'structured-reason-input-field'
              }}
              theme={{
                container: 'autosuggest-container',
                containerOpen: 'autosuggest-container-open',
                input: 'autosuggest-input',
                inputOpen: 'autosuggest-input-open',
                inputFocused: 'autosuggest-input-focused',
                suggestionsContainer: 'autosuggest-suggestions-container',
                suggestionsContainerOpen:
                  'autosuggest-suggestions-container-open',
                suggestionsList: 'autosuggest-suggestions-list',
                suggestion: 'autosuggest-suggestion',
                suggestionFirst: 'autosuggest-suggestion-first',
                suggestionHighlighted: 'autosuggest-suggestion-highlighted',
                sectionContainer: 'autosuggest-section-container',
                sectionContainerFirst: 'autosuggest-section-container-first',
                sectionTitle: 'autosuggest-section-title'
              }}
            />
          </div>
        </If>
        <Choose>
          <When condition={source === 'customize-campaign'}>
            <TextField
              hintText="Input reason"
              value={convertReason('display', reason)}
              className="reason-input-field"
              id="customize-campaign-reason-input-field"
              name="reason-input-field"
              onChange={({nativeEvent: {inputType}}, reason) => {
                this.handleReasonChange(
                  inputType,
                  convertReason('data', reason)
                );
              }}
              multiLine={true}
              fullWidth={true}
            />
          </When>
          <When condition={source === 'scorecard'}>
            <textarea
              value={convertReason('display', reason)}
              onChange={({
                nativeEvent: {inputType},
                target: {value: notes = ''}
              }) => {
                this.handleReasonChange(
                  inputType,
                  convertReason('data', notes)
                );
              }}
              className="scorecard-decision-recommendation-notes"
              id="scorecard-reason-input-field"
              placeholder={textInputPlaceholder}
            />
          </When>
        </Choose>
        <Dialog
          className="add-new-structured-reason-modal"
          title={'Add new structured reason'}
          actions={[
            <RaisedButton
              label="Cancel"
              backgroundColor={'#f44336'}
              labelColor={'#ffffff'}
              style={{
                marginRight: '12px'
              }}
              onClick={() => {
                this.handleAddNewStructuredReasonCancelClick();
              }}
            />,
            <RaisedButton
              label="Add"
              disabled={
                newStructuredReasonTitle === '' ||
                newStructuredReasonCategory === ''
              }
              backgroundColor={'#5dab49'}
              labelColor={'#ffffff'}
              onClick={() => {
                this.handleAddNewStructuredReasonAddClick();
              }}
            />
          ]}
          modal={true}
          open={showAddNewStructuredReasonModal}
          repositionOnUpdate={true}
          autoDetectWindowHeight={true}
          autoScrollBodyContent={true}
          contentStyle={{
            width: '98%',
            maxWidth: '420px',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <div className="add-new-structured-reason-section">
            <div className="generic-title">Name of structured reason</div>
            <TextField
              value={newStructuredReasonTitle}
              onChange={(event, title) => {
                this.handleAddNewStructuredReasonTitleChange(title);
              }}
              fullWidth={true}
            />
          </div>
          <div className="add-new-structured-reason-section">
            <div className="generic-title">Category of structured reason</div>
            <SelectField
              value={newStructuredReasonCategory}
              onChange={(event, index, category) => {
                this.handleAddNewStructuredReasonCategoryChange(category);
              }}
              fullWidth={true}
            >
              <MenuItem value="people" primaryText="People" />
              <MenuItem value="product" primaryText="Product" />
              <MenuItem value="market" primaryText="Market" />
              <MenuItem value="funding" primaryText="Funding" />
              <MenuItem value="general" primaryText="General" />
            </SelectField>
          </div>
        </Dialog>
      </div>
    );
  }
}
