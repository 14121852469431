import React, {PropTypes} from 'react';
import TaskStore from '../../storage/TaskStore';
import Loading from '../loading/Loading';
import {Card, CardActions, CardHeader, CardText} from 'material-ui/Card';
import {List, ListItem} from 'material-ui/List';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import Paper from 'material-ui/Paper';
import Divider from 'material-ui/Divider';
import {
  grey50,
  greenA400,
  red500,
  grey300,
  orange300
} from 'material-ui/styles/colors';
import {
  Toolbar,
  ToolbarGroup,
  ToolbarSeparator,
  ToolbarTitle
} from 'material-ui/Toolbar';
import RunningStatus from './RunningStatus';
import moment from 'moment';
import prettyMs from 'pretty-ms';

const parseParams = (params) => {
  if (!params) {
    return {};
  }

  try {
    return JSON.parse(params);
  } catch (e) {
    console.error(e);
    return {};
  }
};

class Task extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      task: null,
      params: null,
      isMounted: false
    };

    this.taskStore = new TaskStore();
  }

  getTask = () => {
    this.setState(() => ({
      loading: true
    }));
    if (!this.state.isMounted) {
      return;
    }
    this.taskStore.getTask(this.props.params.id).then((task) => {
      this.setState(() => ({
        loading: false,
        task
      }));
      if (!this.interval) {
        this.interval = setInterval(this.getTask, 3000);
      }
    }, console.error);
  };

  startJob = () => {
    this.taskStore
      .startTask(this.state.task.id, parseParams(this.state.params))
      .then(this.getTask, console.error);
  };

  restartJob = () => {
    const params = parseParams(this.state.params);
    params.restart = true;

    this.taskStore
      .startTask(this.state.task.id, params)
      .then(this.getTask, console.error);
  };

  stopJob = () => {
    this.taskStore
      .stopTask(this.state.task.job_name)
      .then(this.getTask, console.error);
  };

  handleChangeParameters = (_event, params) => {
    this.setState(() => ({params}));
  };

  componentDidMount() {
    this.setState(() => ({isMounted: true}), this.getTask);
  }

  componentWillUnmount() {
    this.setState(() => ({isMounted: false}));
    clearInterval(this.interval);
  }

  render() {
    const task = this.state.task || {},
      progress = task.progress || {},
      schedule = task.schedule || {},
      started = progress.started ? Date.parse(progress.started) : undefined,
      timestamp = progress.timestamp
        ? Date.parse(progress.timestamp)
        : undefined,
      finished = progress.finished ? Date.parse(progress.finished) : undefined,
      expires = progress.expires ? Date.parse(progress.expires) : undefined;

    return (
      <div className="task">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <Paper zDepth={2}>
                <Choose>
                  <When condition={task.job_name == null}>
                    <Card>
                      <CardHeader title="Loading task..." />
                      <CardText>
                        <Loading />
                      </CardText>
                    </Card>
                  </When>

                  <When condition={task.job_name != null}>
                    <Toolbar>
                      <ToolbarGroup>
                        <ToolbarTitle text={task.job_name} />
                      </ToolbarGroup>
                    </Toolbar>

                    <div className="row">
                      <div className="col-xs-6">
                        <List>
                          <ListItem
                            disabled={true}
                            primaryText="Status"
                            secondaryText={
                              <RunningStatus
                                progress={progress}
                                schedule={schedule}
                              />
                            }
                          />
                          <Divider />
                          <ListItem
                            disabled={true}
                            primaryText="Started"
                            secondaryText={
                              started ? moment(started).fromNow() : 'N/A'
                            }
                          />
                          <Divider />
                          <ListItem
                            disabled={true}
                            primaryText="Timestamp"
                            secondaryText={
                              timestamp ? moment(timestamp).fromNow() : 'N/A'
                            }
                          />
                          <Divider />
                          <ListItem
                            disabled={true}
                            primaryText="Finished"
                            secondaryText={
                              finished ? moment(finished).fromNow() : 'N/A'
                            }
                          />
                          <If condition={started && finished}>
                            <Divider />
                            <ListItem
                              disabled={true}
                              primaryText="Last Run Took"
                              secondaryText={prettyMs(
                                Math.max(finished - started, 1)
                              )}
                            />
                          </If>
                          <If condition={started && timestamp}>
                            <Divider />
                            <ListItem
                              disabled={true}
                              primaryText="Time Elapsed"
                              secondaryText={prettyMs(
                                Math.max(timestamp - started, 1)
                              )}
                            />
                          </If>
                          <Divider />
                          <ListItem
                            disabled={true}
                            primaryText="Next fire"
                            secondaryText={
                              task.next_fire_time
                                ? moment(task.next_fire_time).fromNow()
                                : 'N/A'
                            }
                          />
                          <Divider />
                          <ListItem
                            disabled={true}
                            primaryText="Lock expires"
                            secondaryText={
                              expires ? moment(expires).fromNow() : 'N/A'
                            }
                          />
                          <Divider />
                          <ListItem
                            disabled={true}
                            primaryText="Progress"
                            secondaryText={
                              progress.progress ? progress.progress : 'N/A'
                            }
                          />
                          <Divider />
                          <ListItem
                            disabled={true}
                            primaryText="Previous Progress"
                            secondaryText={
                              progress.previous_progress
                                ? progress.previous_progress
                                : 'N/A'
                            }
                          />
                          <Divider />
                          <ListItem
                            disabled={true}
                            primaryText="Params"
                            secondaryText={
                              progress.params
                                ? JSON.stringify(progress.params)
                                : 'N/A'
                            }
                          />
                          <Divider />
                          <ListItem
                            disabled={true}
                            primaryText="Class name"
                            secondaryText={task.class_name}
                          />
                          <Divider />
                          <ListItem
                            disabled={true}
                            primaryText="Class annotation"
                            secondaryText={task.class_annotation}
                            secondaryTextLines={2}
                          />
                        </List>
                      </div>

                      <div className="col-xs-6">
                        <TextField
                          floatingLabelText="Optional parameters (use JSON)"
                          hintText="{ foo: 1 }"
                          multiLine={true}
                          fullWidth={true}
                          onChange={this.handleChangeParameters}
                        />
                        <RaisedButton
                          label="Start"
                          onClick={this.startJob}
                          primary={true}
                          disabled={timestamp && !finished}
                          backgroundColor={greenA400}
                        />
                        <RaisedButton
                          label="Continue"
                          onClick={this.restartJob}
                          primary={false}
                          disabled={finished}
                          backgroundColor={orange300}
                        />
                      </div>
                    </div>

                    <Toolbar>
                      <ToolbarGroup firstChild={true}>
                        <RaisedButton
                          label="Stop"
                          disabled={progress.stopped === true}
                          onClick={this.stopJob}
                          backgroundColor={red500}
                          labelColor={grey50}
                        />
                      </ToolbarGroup>
                    </Toolbar>
                  </When>
                </Choose>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Task;
