import auth from './Auth';
import Config from '../config';

export default class EmailFindingStore {
  find(organizationId, personId) {
    return auth
      .getAuthData()
      .then((authData) => {
        return fetch(
          `${
            Config.api.host
          }/organizations/${organizationId}/people/${personId}/find_emails?access_token=${
            authData.token
          }&client=${authData.client}`,
          {
            method: 'post'
          }
        );
      })
      .then((response) => response.json());
  }
}
