import auth from './Auth';
import Config from '../config';

export default class EmailValidationStore {
  validate(email) {
    return auth
      .getAuthData()
      .then((authData) => {
        return fetch(
          `${Config.api.host}/email_validations/${email}?access_token=${
            authData.token
          }&client=${authData.client}`,
          {
            method: 'post'
          }
        );
      })
      .then((response) => response.json());
  }
}
