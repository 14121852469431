import request from 'superagent';
import auth from './Auth';
import Config from '../config';

class TemporaryUserStore {
  constructor(organizationId) {
    this.organizationId = organizationId;
    this.url = Config.api.host + '/tokens/organizations/' + organizationId;
  }

  createToken(includeScorecard) {
    return new Promise((resolve, reject) => {
      auth.getAuthData().then((authData) => {
        request
          .post(this.url)
          .query({
            access_token: authData.token,
            client: authData.client,
            include_notes: !!includeScorecard
          })
          .end((error, response) => {
            if (response && response.ok) {
              resolve(response.body);
            } else {
              reject(error);
            }
          });
      });
    });
  }

  createShareLink(includeScorecard) {
    return new Promise((resolve, reject) => {
      this.createToken(includeScorecard)
        .then((tokenResponse) => {
          resolve(
            `${window.location.origin}/organizations/${this.organizationId}?e=${
              tokenResponse.expires
            }&s=${tokenResponse.signature}${
              !!includeScorecard ? '&n=true' : ''
            }`
          );
        })
        .catch(reject);
    });
  }
}

export default TemporaryUserStore;
