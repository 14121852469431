import React from 'react';
import PropTypes from 'prop-types';

import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import ContentClear from 'material-ui/svg-icons/content/clear';

import Autosuggest from 'react-autosuggest';

const dialogStyles = {
  dialogRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 0
  },
  dialogContent: {
    position: 'relative',
    width: '80vw',
    maxWidth: '768px',
    transform: ''
  },
  dialogBody: {
    paddingBottom: 0
  }
};

const getSuggestionValue = ({title}) => title;

const renderSuggestion = ({title}) => (
  <div className="problem-input-suggestion">{title}</div>
);

const doesMatch = (input, {title, category}) =>
  title.toLowerCase().indexOf(input) > -1 || category.indexOf(input) > -1;

const convertProblemToCategory = (problem) =>
  problem.toLowerCase().replace(/ /g, '');

class SelectedProblem extends React.Component {
  static propTypes = {
    problem: PropTypes.object.isRequired,
    handleUnSelectProblem: PropTypes.func.isRequired
  };

  render() {
    const {problem = {}, handleUnSelectProblem} = this.props;

    return (
      <div className="selected-problem">
        {problem.title}
        <ContentClear
          className="clear-icon"
          onClick={() => handleUnSelectProblem(problem)}
        />
      </div>
    );
  }
}

class SelectedProblems extends React.Component {
  static propTypes = {
    selectedProblems: PropTypes.array.isRequired,
    handleUnSelectProblem: PropTypes.func.isRequired
  };

  render() {
    const {selectedProblems = [], handleUnSelectProblem} = this.props;

    return (
      <div className="selected-problems">
        {selectedProblems.map((problem) => (
          <SelectedProblem
            key={problem.category}
            problem={problem}
            handleUnSelectProblem={handleUnSelectProblem}
          />
        ))}
      </div>
    );
  }
}

export default class SendFeedbackDialog extends React.Component {
  static propTypes = {
    onCloseDialog: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    handleSendFeedbackClick: PropTypes.func.isRequired,
    feedbackCategories: PropTypes.array.isRequired
  };

  state = {
    problemSuggestions: [],
    showingAllPossibleProblems: false,
    problemValue: '',
    selectedProblems: [],
    feedbackNotes: '',
    success: null,
    error: null
  };

  handleSuggestionsFetchRequested = ({value, reason = ''}) => {
    if (
      reason === '' ||
      reason === 'input-changed' ||
      reason === 'input-focused' ||
      reason === 'escape-pressed'
    ) {
      this.setState({
        problemSuggestions: this.getSuggestions(value)
      });
    }
  };

  getSuggestions = (value) => {
    const {feedbackCategories = []} = this.props;
    const {showingAllPossibleProblems} = this.state;

    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    const newFeedbackCategories =
      inputLength === 0
        ? []
        : feedbackCategories.reduce((newFeedbackCategories, problem) => {
            if (
              (doesMatch(inputValue, problem) || showingAllPossibleProblems) &&
              !this.problemAlreadyAdded(problem)
            ) {
              newFeedbackCategories.push(problem);
            }
            return newFeedbackCategories;
          }, []);
    if (!showingAllPossibleProblems) {
      newFeedbackCategories.push({
        category: 'show_all',
        title: 'Show all'
      });
    }
    if (
      value !== '' &&
      value !== 'Show all' &&
      newFeedbackCategories.length === 1 &&
      !this.problemAlreadyAdded(value)
    ) {
      newFeedbackCategories.push({
        category: 'add_new_instruction',
        title: (
          <React.Fragment>
            Press <b>Enter</b> to add <i>{value}</i>
          </React.Fragment>
        )
      });
    }
    return newFeedbackCategories;
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      problemSuggestions: []
    });
  };

  problemAlreadyAdded = (problem) => {
    const {selectedProblems = []} = this.state;
    return selectedProblems.find(
      ({category}) =>
        (typeof problem === 'string'
          ? convertProblemToCategory(problem)
          : problem.category) === category
    );
  };

  handleSuggestionSelected = (event, {suggestion}) => {
    const {problemValue} = this.state;
    switch (suggestion.category) {
      case 'show_all':
        this.setState(
          {
            showingAllPossibleProblems: true
          },
          () => {
            this.handleSuggestionsFetchRequested({
              value: problemValue
            });
          }
        );
        break;
      case 'add_new_instruction':
        break;
      default:
        this.handleAddProblem(suggestion);
        break;
    }
  };

  handleAddProblem = (problem) => {
    this.setState({
      selectedProblems: [...this.state.selectedProblems, problem],
      problemValue: '',
      showingAllPossibleProblems: false,
      problemSuggestions: []
    });
  };

  handleUnSelectProblem = ({category}) => {
    this.setState({
      selectedProblems: this.state.selectedProblems.filter(
        ({category: selectedCategory}) => selectedCategory !== category
      )
    });
  };

  handleProblemChange = (value) => {
    // Handles any change to the problem textbox
    if (value !== this.state.problemValue) {
      this.setState({
        problemValue: value,
        showingAllPossibleProblems: false
      });
    }
  };

  handleProblemEnterPress = () => {
    const {problemSuggestions = [], problemValue = ''} = this.state;
    if (
      problemValue !== '' &&
      problemValue !== 'Show all' &&
      problemSuggestions.length === 2 &&
      problemSuggestions[1].category === 'add_new_instruction' &&
      !this.problemAlreadyAdded(problemValue)
    ) {
      this.handleAddProblem({
        title: problemValue,
        category: convertProblemToCategory(problemValue),
        assigned_to: 'ben@inreachventures.com'
      });
    }
  };

  handleFeedbackNotesChange = (notes) => {
    this.setState({
      feedbackNotes: notes
    });
  };

  handleCloseModal = () => {
    this.setState({
      problemSuggestions: [],
      showingAllPossibleProblems: false,
      problemValue: '',
      selectedProblems: [],
      feedbackNotes: '',
      success: null,
      error: null
    });
    this.props.onCloseDialog();
  };

  handleSendFeedbackClick = () => {
    const {handleSendFeedbackClick} = this.props;
    const {selectedProblems = [], feedbackNotes = ''} = this.state;
    if (selectedProblems.length === 0 && feedbackNotes === '') {
      this.setState({
        success: null,
        error: 'Please add a problem or describe the problem you are having'
      });
    } else {
      this.setState({
        success: 'Submitting...',
        error: null
      });
      handleSendFeedbackClick(selectedProblems, feedbackNotes).then(
        () => {
          this.setState(
            {
              success: 'Success!',
              error: null
            },
            () => {
              setTimeout(() => {
                this.handleCloseModal();
              }, 2000);
            }
          );
        },
        () => {
          this.setState({
            success: null,
            error: 'Error. Please send to Sean'
          });
        }
      );
    }
  };

  render() {
    const {show} = this.props;

    const {
      problemSuggestions = [],
      problemValue = '',
      selectedProblems = [],
      feedbackNotes = '',
      success = null,
      error = null
    } = this.state;

    const actions = [
      <RaisedButton
        key="cancel"
        className="send-feedback-close"
        value="cancel"
        backgroundColor={'transparent'}
        labelColor={'#444444'}
        style={{marginRight: '12px'}}
        onClick={this.handleCloseModal}
        label={'Cancel'}
      />,
      <RaisedButton
        key="submit"
        className="send-feedback-submit"
        value="submit"
        backgroundColor={'#5dab49'}
        labelColor={'#ffffff'}
        onClick={this.handleSendFeedbackClick}
        label={'Submit'}
      />
    ];

    return (
      <Dialog
        title="Report Data Bug"
        bodyClassName="send-feedback-dialog-root"
        actionsContainerClassName="send-feedback-actions-container"
        modal={false}
        open={show}
        actions={actions}
        onRequestClose={this.handleCloseModal}
        contentStyle={dialogStyles.dialogContent}
        bodyStyle={dialogStyles.dialogBody}
        style={dialogStyles.dialogRoot}
        repositionOnUpdate={false}
      >
        <div className="send-feedback-problems">
          <div className="data-header">Problems</div>
          <Autosuggest
            suggestions={problemSuggestions}
            onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
            onSuggestionSelected={this.handleSuggestionSelected}
            onSuggestionHighlighted={({suggestion}) => {
              this.handleProblemChange(
                suggestion !== null ? suggestion.title : ''
              );
            }}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            alwaysRenderSuggestions={true}
            //highlightFirstSuggestion={true}
            inputProps={{
              value: problemValue,
              onChange: (event, {newValue}) =>
                this.handleProblemChange(newValue),
              onKeyDown: ({keyCode = null}) => {
                if (keyCode === 13) {
                  this.handleProblemEnterPress();
                }
              },
              id: 'problem-input-field',
              name: 'problem-input-field',
              placeholder: 'Identify the problem(s)...'
            }}
            theme={{
              container: 'autosuggest-container',
              containerOpen: 'autosuggest-container-open',
              input: 'autosuggest-input',
              inputOpen: 'autosuggest-input-open',
              inputFocused: 'autosuggest-input-focused',
              suggestionsContainer: 'autosuggest-suggestions-container',
              suggestionsContainerOpen:
                'autosuggest-suggestions-container-open',
              suggestionsList: 'autosuggest-suggestions-list',
              suggestion: 'autosuggest-suggestion',
              suggestionFirst: 'autosuggest-suggestion-first',
              suggestionHighlighted: 'autosuggest-suggestion-highlighted',
              sectionContainer: 'autosuggest-section-container',
              sectionContainerFirst: 'autosuggest-section-container-first',
              sectionTitle: 'autosuggest-section-title'
            }}
          />
          <SelectedProblems
            selectedProblems={selectedProblems}
            handleUnSelectProblem={this.handleUnSelectProblem}
          />
        </div>
        <div className="send-feedback-notes">
          <div className="data-header">Notes</div>
          <textarea
            value={feedbackNotes}
            onChange={({
              nativeEvent: {inputType},
              target: {value: notes = ''}
            }) => {
              this.handleFeedbackNotesChange(notes);
            }}
            className="send-feedback-notes-textarea"
            id="send-feedback-notes"
            placeholder="Describe the problem(s)..."
          />
        </div>
        <div className="send-feedback-response">
          <Choose>
            <When condition={success !== null}>
              <span className="success-text">{success}</span>
            </When>
            <When condition={error !== null}>
              <span className="error-text">{error}</span>
            </When>
          </Choose>
        </div>
      </Dialog>
    );
  }
}
