import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import ActionLabel from 'material-ui/svg-icons/action/label';

import {capitalizeString} from '../../../../lib/helpers';
import Avatar from 'material-ui/Avatar';
import Chip from 'material-ui/Chip';

function Tag(props) {
  const {tag, confidence, highlighted, icon} = props;

  function getTagClassNames(confidence) {
    const classNames = ['tag'];
    if (confidence >= 0.7) {
      classNames.push('highest');
    } else if (confidence >= 0.5) {
      classNames.push('high');
    }
    if (highlighted) {
      classNames.push('highlighted');
    }
    return classNames.join(' ');
  }

  return (
    <div className={getTagClassNames(confidence)}>
      <span className={icon} style={{paddingRight: ' 5px'}} />
      {capitalizeString(tag)}
    </div>
  );
}

class Tags extends React.Component {
  static propTypes = {
    tags: PropTypes.object.isRequired
  };

  /*getConfidencePercentage = (confidence) => {
    const multipliedConfidenceString = String(confidence * 100);
    return `(${multipliedConfidenceString.substring(
      0,
      multipliedConfidenceString.indexOf('.') > -1
        ? multipliedConfidenceString.indexOf('.')
        : multipliedConfidenceString.length
    )}%)`;
  };

  getIconClasses = (confidence) => {
    const classNames = ['icon', 'tag'];
    if (confidence >= 0.9) {
      classNames.push('high');
    }
    return classNames.join(' ');
  };*/

  render() {
    const {
      tags: {tags, highlighted = false} = {},
      industriesAndTechnologies = {}
    } = this.props;
    if (
      !industriesAndTechnologies['industries'] &&
      !industriesAndTechnologies['technologies']
    ) {
      return null;
    }

    const nIndustries = Object.entries(industriesAndTechnologies['industries'])
      .sort((a, b) => b[1] - a[1])
      .filter(([tag, confidence]) => confidence >= 0.4).length;

    const nTechnologies = Object.entries(
      industriesAndTechnologies['technologies']
    )
      .sort((a, b) => b[1] - a[1])
      .filter(([tag, confidence]) => confidence >= 0.4).length;

    return (
      <div className="tags">
        <div className="tags-container">
          {Object.entries(industriesAndTechnologies['industries'])
            .sort((a, b) => b[1] - a[1])
            .filter(
              ([tag, confidence]) =>
                nIndustries > 0 ? confidence >= 0.4 : confidence > 0
            )
            .map(([tag, confidence]) => {
              return (
                <Tag
                  key={tag}
                  tag={tag}
                  confidence={confidence}
                  highlighted={highlighted}
                  icon={'building'}
                />
              );
            })}
          <If
            condition={
              Object.keys(industriesAndTechnologies['industries']).length > 0
            }
          >
            <span style={{marginLeft: '20px'}}>&nbsp;</span>
          </If>
          {Object.entries(industriesAndTechnologies['technologies'])
            .sort((a, b) => b[1] - a[1])
            .filter(
              ([tag, confidence]) =>
                nTechnologies > 0 ? confidence >= 0.4 : confidence > 0
            )
            .map(([tag, confidence]) => {
              return (
                <Tag
                  key={tag}
                  tag={tag}
                  confidence={confidence}
                  highlighted={highlighted}
                  icon={'cogs'}
                />
              );
            })}
        </div>
      </div>
    );
  }
}

export default withRouter(Tags);
