import React from 'react';
import PropTypes from 'prop-types';

export default class TimelineEventCallFeedbackBody extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired
  };

  render() {
    const {text} = this.props;
    return (
      <div className="timeline-event-body">
        <p>{text}</p>
      </div>
    );
  }
}
