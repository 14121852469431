import React from 'react';
import PropTypes from 'prop-types';
import Paper from 'material-ui/Paper';
import AppBar from 'material-ui/AppBar';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import Divider from 'material-ui/Divider';
import TextField from 'material-ui/TextField';
import {List} from 'material-ui/List';
import LinearProgress from 'material-ui/LinearProgress';
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';

import EditorInsertDriveFile from 'material-ui/svg-icons/editor/insert-drive-file';
import ContentAddBox from 'material-ui/svg-icons/content/add-box';
import ContentCopy from 'material-ui/svg-icons/content/content-copy';

import Card from '../../card/Card';
import CardHeader from '../../card/CardHeader';
import CardBody from '../../card/CardBody';
import Loading from '../../loading/Loading';
import File from './File';

function fileId(s) {
  const docsRe = /https:\/\/docs.google.com\/(?:document|spreadsheets|presentation|drawings)\/d\/([a-zA-Z0-9-_]+)\//;
  const driveRe = /https:\/\/drive.google.com\/file\/d\/([a-zA-Z0-9-_]+)\//;

  if (docsRe.test(s)) {
    const [, id] = s.match(docsRe);
    return id;
  }

  if (driveRe.test(s)) {
    const [, id] = s.match(driveRe);
    return id;
  }

  return null;
}

function fileIds(s) {
  return s
    .split('\n')
    .map(fileId)
    .filter(Boolean);
}

export default class Files extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    files: PropTypes.array.isRequired,
    filesFolder: PropTypes.string,
    createFilesFolder: PropTypes.func.isRequired,
    onFileChange: PropTypes.func.isRequired,
    filesLoading: PropTypes.bool.isRequired,
    isAddingFiles: PropTypes.bool.isRequired,
    addFiles: PropTypes.func.isRequired,
    toggleUploadFilesModal: PropTypes.func.isRequired,
    showUploadFilesModal: PropTypes.bool.isRequired
  };

  state = {
    loadingFiles: false,
    fileIds: [],
    copyOrAdd: 'add'
  };

  onInputChange = (e) => {
    if (e.target.value === '') {
      return;
    }

    this.setState({fileIds: fileIds(e.target.value)});
  };

  handleChangeCopyAdd = (copyOrAdd) => () => {
    this.setState({copyOrAdd});
  };

  render() {
    const {
      organization,
      files,
      filesFolder,
      createFilesFolder,
      onFileChange,
      filesLoading,
      isAddingFiles,
      showUploadFilesModal,
      addFiles,
      toggleUploadFilesModal
    } = this.props;

    return (
      <div className="files">
        <Dialog
          title={`Add files to ${organization.name}-${
            organization.id
          } in Google Drive`}
          open={showUploadFilesModal}
          actions={[
            <FlatButton
              key="cancel"
              label="Cancel"
              onClick={toggleUploadFilesModal}
              disabled={isAddingFiles}
            />
          ]}
        >
          <Choose>
            <When condition={isAddingFiles}>
              <h4>Adding files to Google Drive...</h4>
              <LinearProgress />
            </When>

            <Otherwise>
              <input
                onChange={(e) => onFileChange(e.target.files)}
                multiple
                type="file"
              />
              <Divider style={{marginTop: '16px', marginBottom: '16px'}} />

              <RadioButtonGroup
                name="copyOrAdd"
                valueSelected={this.state.copyOrAdd}
              >
                <RadioButton
                  value="copy"
                  label="Copy"
                  checkedIcon={<ContentCopy />}
                  uncheckedIcon={<ContentCopy />}
                  onClick={this.handleChangeCopyAdd('copy')}
                />
                <RadioButton
                  value="add"
                  label="Add"
                  checkedIcon={<ContentAddBox />}
                  uncheckedIcon={<ContentAddBox />}
                  onClick={this.handleChangeCopyAdd('add')}
                />
              </RadioButtonGroup>

              <If condition={this.state.copyOrAdd === 'copy'}>
                <p>Duplicate the original(s) into this Google Drive folder.</p>
                <p>
                  Edits to the duplicate(s) will not affect the original
                  file(s).
                </p>
              </If>
              <If condition={this.state.copyOrAdd === 'add'}>
                <p>
                  Create a link to the original(s) in this Google Drive folder.
                </p>
                <p>
                  Edits will be reflected in all locations in which a link
                  exists.
                </p>
                <p>
                  Deleting a link does not delete the original file. However, if
                  the original file is deleted so are all of the links to it.
                </p>
              </If>

              <TextField
                floatingLabelText="File URL(s)"
                floatingLabelFixed={true}
                hintText="https://docs.google.com/document/d/18tNd8r5DV0yluCR7tPvkMTsWD_lYcRO7NhpNSDymRr8/edit"
                fullWidth={true}
                multiLine={true}
                onChange={this.onInputChange}
                disabled={isAddingFiles}
              />
              <FlatButton
                label={this.state.copyOrAdd}
                primary={true}
                onClick={() => addFiles(this.state.fileIds)}
                disabled={this.state.fileIds == null || isAddingFiles}
              />
            </Otherwise>
          </Choose>
        </Dialog>

        <Card>
          <CardHeader title="Files">
            <div className="spacer" />
            <Choose>
              <When condition={filesFolder}>
                <a
                  href={`https://drive.google.com/drive/u/1/folders/${filesFolder}`}
                  target="_blank"
                >
                  <FlatButton
                    style={{backgroundColor: '#5dab49', marginTop: '0'}}
                    label="Folder"
                    labelStyle={{
                      color: '#ffffff'
                    }}
                  />
                </a>
                <FlatButton
                  label="Add Files"
                  primary={true}
                  style={{marginLeft: '12px'}}
                  onClick={toggleUploadFilesModal}
                />
              </When>
              <Otherwise>
                <FlatButton
                  label="Create Folder"
                  style={{backgroundColor: '#5dab49', marginTop: '0'}}
                  onClick={createFilesFolder}
                  labelStyle={{
                    color: '#ffffff'
                  }}
                />
              </Otherwise>
            </Choose>
          </CardHeader>
          <CardBody>
            <Choose>
              <When condition={!filesLoading}>
                <List>
                  {files.map((file) => (
                    <File key={file.id} file={file} />
                  ))}
                </List>
              </When>
              <Otherwise>
                <Loading />
              </Otherwise>
            </Choose>
          </CardBody>
        </Card>

        {/*<Paper zDepth={1}>
          <AppBar
            className="appBar light"
            title="Files"
            iconElementLeft={
              <div className="icon-container">
                <EditorInsertDriveFile style={{color: 'white'}} />
              </div>
            }
            iconElementRight={
              <div>
                <Choose>
                  <When condition={this.state.folder}>
                    <a
                      href={`https://drive.google.com/drive/u/1/folders/${
                        this.state.folder
                      }`}
                      target="_blank"
                    >
                      <FlatButton
                        style={{backgroundColor: '#337ab7', marginTop: '0'}}
                        label="Folder"
                      />
                    </a>
                    <FlatButton
                      label="Add Files"
                      primary={true}
                      style={{marginLeft: '12px'}}
                      onClick={() => this.setState({showUploadModal: true})}
                    />
                  </When>
                  <Otherwise>
                    <FlatButton
                      label="Create Folder"
                      style={{backgroundColor: '#337ab7', marginTop: '0'}}
                      onClick={this.createFolder}
                    />
                  </Otherwise>
                </Choose>
              </div>
            }
          />
          <div className="section-container">
            <List>
              {this.state.files.map((file) => (
                <File key={file.id} file={file} />
              ))}
            </List>
          </div>
        </Paper>*/}
      </div>
    );
  }
}
