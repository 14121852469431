import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router';

class NameCell extends React.Component {
  static propTypes = {
    person: PropTypes.object.isRequired
  };

  render() {
    return (
      <Link
        className="person-name trunc-cell-text"
        to={`/people/${this.props.person.id}`}
      >
        {this.props.person.name}
      </Link>
    );
  }
}

export default NameCell;
