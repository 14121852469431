import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import Tooltip from '../../Tooltip';
import ContactIcon from 'material-ui/svg-icons/communication/import-contacts';
class ContactsPreview extends React.Component {
  static propTypes = {
    investor: PropTypes.object.isRequired
  };
  state = {
    showTooltip: false
  };

  hasError = () => {
    return true;
  };

  render() {
    const {investor: {aggregations: {} = {}} = {}} = this.props;
    const {showTooltip} = this.state;
    return (
      <div
        className="data-wrapper investments compressed"
        onMouseLeave={() => {
          if (this.hasError()) {
            this.setState({showTooltip: false});
          }
        }}
        onMouseEnter={() => {
          if (this.hasError()) {
            this.setState({showTooltip: true});
          }
        }}
        rel={(el) => (this._parent = el)}
      >
        <div className="data-icon">
          <ContactIcon className="data-icon-svg" />
        </div>
        <Tooltip
          parent={this._parent}
          showTooltip={showTooltip}
          text="Contacts"
        />
        <Choose>
          <When condition={1 === 3}>
            <div className="data-content text empty">No data</div>
          </When>
          <Otherwise>
            <div className={'data-content text empty'}>{'Coming soon...'}</div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default withRouter(ContactsPreview);
