export default {
  consumer: {
    name: 'Consumer Internet'
  },
  saas: {
    name: 'SaaS'
  },
  marketplace: {
    name: 'Marketplace'
  },
  other: {
    name: 'Other'
  }
};
