import React from 'react';
import StateButton from './StateButton';

export default class StateFilter extends React.Component {
  handleStateChange = (state, currentState, shiftKeyPressed) => {
    this.props.onStateChange({state: state === currentState ? null : state});
  };

  render() {
    const state = this.props.state;

    return (
      <div className="state-group state-filter">
        <StateButton
          value="never"
          label="Never"
          state={state}
          analystFilter={this.props.analystFilter}
          optionalState={this.props.optionalState}
          showUnseen={this.props.showUnseen}
          icon={<div className="icon thumb-down" />}
          handleClick={this.handleStateChange}
        />

        <StateButton
          value="missed_opportunity"
          label="Missed Opportunity"
          state={state}
          analystFilter={this.props.analystFilter}
          optionalState={this.props.optionalState}
          showUnseen={this.props.showUnseen}
          icon={<div className="icon rocket" />}
          handleClick={this.handleStateChange}
        />

        <StateButton
          value="not_now"
          label="Not Now"
          state={state}
          analystFilter={this.props.analystFilter}
          optionalState={this.props.optionalState}
          showUnseen={this.props.showUnseen}
          icon={<div className="icon thumb-stop" />}
          handleClick={this.handleStateChange}
        />

        <StateButton
          value="contact"
          label="In Progress"
          state={state}
          analystFilter={this.props.analystFilter}
          optionalState={this.props.optionalState}
          showUnseen={this.props.showUnseen}
          icon={<div className="icon thumb-up" />}
          handleClick={this.handleStateChange}
        />

        <If condition={this.props.showUnseen}>
          <StateButton
            value={this.props.optionalState || 'unseen'}
            label={this.props.optionalState || 'unseen'}
            icon={<div className="icon unseen" />}
            state={state}
            analystFilter={this.props.analystFilter}
            showUnseen={true}
            optionalState={this.props.optionalState}
            handleClick={this.handleStateChange}
          />
        </If>
      </div>
    );
  }
}
