import React from 'react';

import ErrorBoundary from './ErrorBoundary';
import NavigationBar from './NavigationBar';

export default class AppContainer extends React.Component {
  render() {
    return (
      <ErrorBoundary>
        <div className="app">
          <NavigationBar location={this.props.location} />
          {this.props.children}
        </div>
      </ErrorBoundary>
    );
  }
}
