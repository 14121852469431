import React from 'react';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import FilterSummary from './FilterSummary';
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';
import TextField from 'material-ui/TextField';
import Divider from 'material-ui/Divider';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import CircularProgress from 'material-ui/CircularProgress';
import ActionDone from 'material-ui/svg-icons/action/done';

import auth from '../../storage/Auth';
import _ from 'underscore';

class SaveFiltersSetDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveAs: 'new',
      filtersSetName: '',
      filtersSets: null,
      loading: false,
      updating: false,
      showSuccessMessage: false
    };

    this.handleRadioButtonChange = this.handleRadioButtonChange.bind(this);
    this.handleFiltersSetNameChange = this.handleFiltersSetNameChange.bind(
      this
    );
    this.handleSave = this.handleSave.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.loadSets = this.loadSets.bind(this);
    this.handleShowSuccessMessage = this.handleShowSuccessMessage.bind(this);
    this.cleanState = this.cleanState.bind(this);
  }

  componentDidMount() {
    this.loadSets();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open && this.props.open) {
      this.loadSets();
    }
  }

  handleShowSuccessMessage() {
    return new Promise((resolve) => {
      this.setState({
        showSuccessMessage: true
      });
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  }

  loadSets() {
    this.setState({
      loading: true
    });
    auth.getFiltersSets().then((filtersSets) => {
      this.setState({
        filtersSets: filtersSets ? Object.keys(filtersSets) : null,
        loading: false
      });
    });
  }

  cleanState() {
    this.setState({
      saveAs: 'new',
      filtersSetName: '',
      filtersSets: null,
      loading: false,
      updating: false,
      showSuccessMessage: false
    });
  }

  handleRadioButtonChange(e, value) {
    this.setState({
      saveAs: value,
      filtersSetName: ''
    });
  }

  handleFiltersSetNameChange(event) {
    this.setState({
      filtersSetName: event.target.value
    });
  }

  handleSelectChange(event, index, value) {
    this.setState({filtersSetName: value});
  }

  handleSave() {
    if (this.state.filtersSetName) {
      this.setState({
        updating: true
      });
      auth
        .saveFiltersSet(
          this.state.filtersSetName,
          _.omit(this.props.searchParams, 'offset')
        )
        .then(() => {
          this.setState({
            updating: false
          });
          return this.props.updateFiltersSets();
        })
        .then(this.handleShowSuccessMessage)
        .then(this.props.handleClose)
        .then(this.cleanState)
        .catch((err) => {
          this.setState({
            updating: false
          });
          console.error('Error while saving filters sets', err);
        });
    }
  }

  render() {
    const actions = [
        <RaisedButton
          key="cancel"
          className="assign-action"
          label="Cancel"
          style={{marginRight: '12px'}}
          onClick={this.props.handleClose}
        />,
        <RaisedButton
          key="submit"
          className="assign-action"
          primary={true}
          onClick={this.handleSave}
        >
          {this.state.updating ? (
            <CircularProgress color="white" size={25} />
          ) : this.state.showSuccessMessage ? (
            <div className="success-button">
              <span style={{color: 'white'}}>
                <ActionDone /> SUCCESS!
              </span>
            </div>
          ) : (
            <span style={{color: 'white'}}>SUBMIT</span>
          )}
        </RaisedButton>
      ],
      isFiltersSetsEmpty =
        (!this.state.loading && !this.state.filtersSets) ||
        (!this.state.loading &&
          this.state.filtersSets &&
          Object.keys(this.state.filtersSets).length === 0);

    return (
      <div>
        <Dialog
          title="Save current filters set"
          actionsContainerClassName="actions-container"
          actions={actions}
          modal={false}
          open={this.props.open}
          onRequestClose={this.props.handleClose}
        >
          <div className="save-filters-set-dialog">
            <RadioButtonGroup
              className="radio-button-group"
              name="switchNewVsExisting"
              defaultSelected="new"
              onChange={this.handleRadioButtonChange}
            >
              <RadioButton value="new" label="CREATE NEW FILTER SET" />
              <RadioButton value="existing" label="UPDATE EXISTING" />
            </RadioButtonGroup>
            <If condition={this.state.saveAs === 'new'}>
              <TextField
                value={this.state.filtersSetName}
                onChange={this.handleFiltersSetNameChange}
                hintText="Type filters set name"
              />
            </If>
            <If condition={this.state.saveAs === 'existing'}>
              <If condition={isFiltersSetsEmpty}>
                <p className="empty-sets">
                  No filters sets have been created yet
                </p>
              </If>
              <If condition={!isFiltersSetsEmpty}>
                <SelectField
                  hintText="Select set"
                  value={this.state.filtersSetName}
                  onChange={this.handleSelectChange}
                >
                  {this.state.filtersSets.map((filtersSet) => {
                    return (
                      <MenuItem
                        key={filtersSet}
                        value={filtersSet}
                        primaryText={filtersSet}
                      />
                    );
                  })}
                </SelectField>
              </If>
            </If>
            <div className="filters-summary-container">
              <label> Current selection </label>
              <Divider />
              <br />
              <FilterSummary
                vertical={true}
                disableEditing={true}
                params={this.props.searchParams}
                orgsNumber={0}
              />
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default SaveFiltersSetDialog;
