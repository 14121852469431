import React from 'react';
import PropTypes from 'prop-types';
import Loading from './loading/Loading';

class Index extends React.Component {
  componentWillMount() {
    this.props.router.push({
      pathname: '/organizations'
    });
  }

  render() {
    return <Loading />;
  }
}

export default Index;
