import React from 'react';
import {withRouter, Link} from 'react-router';
import PropTypes from 'prop-types';

import _ from 'underscore';

import Tooltip from '../../../Tooltip';

import AttachMoney from 'material-ui/svg-icons/editor/attach-money';

class CompressedInvestors extends React.Component {
  static propTypes = {
    investors: PropTypes.object.isRequired,
    investorsLoading: PropTypes.bool.isRequired,
    getInvestors: PropTypes.func.isRequired
  };

  state = {
    showTooltip: false
  };

  handleMouseLeave = () => {
    this.setState({
      showTooltip: false
    });
  };

  handleMouseEnter = () => {
    this.props.getInvestors();
    this.setState({
      showTooltip: true
    });
  };

  getTooltipText = () => {
    const {
      investors: {
        investors = [],
        highlightedInvestorNames = [],
        investorIds
      } = {},
      investorsLoading = true
    } = this.props;
    if (investorsLoading) {
      return 'Investors Loading...';
    } else {
      if (_.isEmpty(investors)) {
        return 'No Investors';
      } else {
        return investors
          .sort(
            ({id}) => (investorIds.interestingInvestors.includes(id) ? -1 : 1)
          )
          .map(
            ({name, id}) =>
              investorIds.interestingInvestors.includes(id)
                ? `<span class="highlighted-text">${name}</span>`
                : name
          )
          .join('<br/>');
      }
    }
  };

  getCompressedInvestors = () => {
    const {
      investors: {investorIds: {all = [], interestingInvestors = []} = {}} = {}
    } = this.props;
    if (!_.isEmpty(interestingInvestors)) {
      return (
        <React.Fragment>
          <span
            className={
              !_.isEmpty(interestingInvestors) ? 'highlighted-text' : ''
            }
          >
            {interestingInvestors.length} interesting investor
            <If condition={interestingInvestors.length !== 1}>s</If>
            &nbsp;
          </span>
          out of {all.length}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {all.length} investor
          <If condition={all.length !== 1}>s</If>
        </React.Fragment>
      );
    }
  };

  render() {
    const {showTooltip = false} = this.state;
    return (
      <div
        className="data-wrapper compressed-investors compressed"
        onMouseLeave={this.handleMouseLeave}
        onMouseEnter={this.handleMouseEnter}
        ref={(el) => (this._parent = el)}
      >
        <div className="data-icon">
          <AttachMoney className="data-icon-svg" />
        </div>
        <Tooltip
          parent={this._parent}
          showTooltip={showTooltip}
          text={this.getTooltipText()}
          customClasses={['compressed-investors-tooltip']}
        />
        <div className="data-content text">{this.getCompressedInvestors()}</div>
      </div>
    );
  }
}

export default withRouter(CompressedInvestors);
