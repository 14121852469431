import PropTypes from 'prop-types';
import React from 'react';

import _ from 'underscore';

export default class Tooltip extends React.Component {
  static propTypes = {
    parent: PropTypes.object,
    showTooltip: PropTypes.bool,
    showOnHover: PropTypes.bool,
    bottom: PropTypes.bool,
    customStyles: PropTypes.object,
    text: PropTypes.string,
    customClasses: PropTypes.array
  };

  state = {
    showTooltip: false
  };

  componentDidMount() {
    const {parent = {}, showOnHover = false} = this.props;
    if (!_.isEmpty(parent) && showOnHover) {
      parent.addEventListener('mouseenter', this.showTooltip);
      parent.addEventListener('mouseleave', this.hideTooltip);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {parent = {}, showOnHover = false} = nextProps;
    if (!_.isEmpty(parent) && showOnHover) {
      parent.addEventListener('mouseenter', this.showTooltip);
      parent.addEventListener('mouseleave', this.hideTooltip);
    }
  }

  componentWillUnmount() {
    const {parent = {}} = this.props;
    if (!_.isEmpty(parent)) {
      parent.removeEventListener('mouseenter', this.showTooltip);
      parent.removeEventListener('mouseleave', this.hideTooltip);
    }
  }

  showTooltip = () => {
    this.setState({
      showTooltip: true
    });
  };

  hideTooltip = () => {
    this.setState({
      showTooltip: false
    });
  };

  getNodeWidth = () => {
    const {parent = {}} = this.props;
    if (!_.isEmpty(parent) && this._el) {
      return (this._el.clientWidth - parent.clientWidth) / 2;
    }
    return 0;
  };

  getClassNames = () => {
    const {
      showOnHover = false,
      showTooltip: propsShowTooltip = false,
      position = '',
      customClasses = []
    } = this.props;
    const {showTooltip: stateShowTooltip = false} = this.state;
    const classNames = ['inreach-tooltip'];
    if (position !== '') {
      classNames.push(position);
    }
    if (
      (!showOnHover && !propsShowTooltip) ||
      (showOnHover && !stateShowTooltip)
    ) {
      classNames.push('hide-tooltip');
    }
    return [...classNames, ...customClasses].join(' ');
  };

  render() {
    const {text = '', customStyles = {}, progressBar} = this.props;

    if (text === '') {
      return false;
    }

    const nodeWidth = this.getNodeWidth();

    return (
      <div
        ref={(el) => (this._el = el)}
        className={this.getClassNames()}
        style={
          !_.isEmpty(customStyles)
            ? !!progressBar
              ? {left: `25%`, marginTop: '20px'}
              : {left: `-${nodeWidth}px`}
            : customStyles
        }
      >
        <div
          className="tooltip-text"
          dangerouslySetInnerHTML={{__html: text}}
        />
      </div>
    );
  }
}

Tooltip.propTypes = {
  parent: PropTypes.object,
  showTooltip: PropTypes.bool,
  showOnHover: PropTypes.bool,
  bottom: PropTypes.bool,
  customStyles: PropTypes.object
};
