export default {
  experience: {
    type: 'experience',
    source: 'team',
    insight_id: 1,
    name: 'Good experience',
    notes: 'A team member has a previous experience at a top tech company',
    icon_id: 4
  },
  education: {
    type: 'education',
    source: 'team',
    insight_id: 2,
    name: 'Good education',
    notes: 'A team member studied at a top university',
    icon_id: 2
  },
  serial_entrepreneur: {
    type: 'bio',
    source: 'founders',
    insight_id: 3,
    name: 'Previous exit',
    notes: 'A founder has previously exited a company',
    icon_id: 3
  },
  experienced_founder: {
    type: 'experience',
    source: 'founders',
    insight_id: 4,
    name: 'Previously C-level or founder',
    notes: 'A founder has a previous experience as C-level or founder',
    icon_id: 4
  },
  experienced_founder_bio: {
    type: 'bio',
    source: 'founders',
    insight_id: 4,
    name: 'Serial entrepreneur',
    notes: 'A founder has a previous experience as C-level or founder',
    icon_id: 4
  },
  investors: {
    type: 'investor',
    source: 'investors',
    insight_id: 5,
    name: 'Good Investors',
    notes: 'An investor in this company is a top investor',
    icon_id: 5
  },
  website_not_localized: {
    type: 'website',
    source: 'website',
    insight_id: 6,
    name: 'Website not localised',
    notes: 'The website is not localised in English',
    icon_id: 6
  },
  website_is_localized: {
    type: 'website',
    source: 'website',
    insight_id: 7,
    name: 'Website is localised',
    notes: 'The website is localised in English',
    icon_id: 1
  },
  innovation_score: {
    type: 'innovation',
    source: 'description',
    insight_id: 8,
    name: 'Not using tech',
    notes: "The company doesn't have a software based product",
    icon_id: 6
  },
  is_ico: {
    type: 'ico',
    source: 'company',
    insight_id: 9,
    name: 'ICO',
    notes: 'This is a crypto company and has raised money through an ICO',
    icon_id: 6
  },
  is_zombie: {
    type: 'funding',
    source: 'funding',
    insight_id: 10,
    name: 'Zombie',
    notes:
      'The last round was more than 3 years ago and the traffic is very low',
    icon_id: 7
  }
};
